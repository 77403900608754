<template>
  <div class="app-content content ">
    <loading
    :active.sync="isLoading"
    :can-cancel="true"
    :is-full-page="fullPage"
    color="orange"
    loader="dots"
    :width="100"
    :height="100"
    ></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top ">
          <div class="col-12 new_dash_mar_top10">
            <h3 class="content-header-title float-left mb-2">Micro Task Details</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/workstep'>Workstep</router-link></li>
                <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'TaskList', params: { search: true }}">Micro Task List</router-link></li>
                <li class="breadcrumb-item sitebluecolor cursorpointercls">View Micro Task</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card border-warning filter_card_searchbgst" >
          <div v-bind:class="[{show: isRequestActiveView}, card_content,collapse]">
            <div class="card-header pt-2 pb-1 indent_border_bottom" style="cursor:pointer">
                <div class="container-fluid">
                   
              <div class="row">
                <div class="col-xs-12 col-sm-auto col-md-auto mobile_sm mb-1">
                    <div class=" card-transaction">
                        <div class="card-body pt-0" style="padding-left:0px;padding-bottom: 0px;">
                            <div class="transaction-item">
                                <div class="media d-flex align-items-center">
                                    <div class="avatar bg-light-primary rounded">
                                        <div class="avatar-content">
                                            <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                                        </div>
                                    </div>
                                    <div v-if="issubmicrotask && parentTaskData.length>0" class="media-body">
                                        <small class="transaction-title ml-0 text-primary">
                                            <span @click="redirecttourl('view', parentTaskData[0])"> {{parentTaskData[0].taskcode}} >> {{parentTaskData[0].taskname.substring(0, 100)}}...</span> 
                                        </small>
                                        <h3 class="card-title ml-0 text-primary">MICRO TASK CODE: {{this.view.taskcode}}</h3>
                                    </div>
                                    <div v-else class="media-body">
                                        <h3  class="card-title ml-0 d-inline-flex text-primary">MICRO TASK CODE: {{this.view.taskcode}}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
                <div class="col-xs-12 ml-auto text-right mobile-view-btn-05">
                    <div v-if="loginusername == this.view.createdby || pagefeatures.edittask || loginpersonname === this.view.empname || (loginuscondition == this.taskdefine.allottedto && this.taskTypedesignCreative.includes(editRowData.tasktypemasterid))"  class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-1 mb-1"  @click="openEditModule(backpagedata,0)">
                    <edit-2-icon size="1.5x" class="custom-class avatar-icon mr-50"></edit-2-icon>
                    <span>Edit</span>
                    </div>
                    <div class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-1 mb-1"  @click="openLogModule()">
                        <watch-icon size="1.5x" class="custom-class avatar-icon mr-50"></watch-icon>
                        <span>Log Time</span>
                    </div>
                    <div v-if="pagefeatures.createsubtask && this.issubtask == false && this.editRowData.reviewers == null && (editRowData.projectisactive ==1 && editRowData.projectisapproved ==1)" class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-1 mb-1"  @click="openEditModule(backpagedata, 9)">
                        <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                        <span>Sub Micro Task</span>
                    </div>
                    <div class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-1 mb-1" v-if="pagefeatures.releasenotedetails && this.view.releasenotemasterid && this.view.releasenotemasterid!=null"  @click="redirecttorelasenote()">
                        <span>Release Note Details</span>
                    </div>
                    <div v-if="pagefeatures.microtaskblocker" class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-1 mb-1" @click="openBlockerTaskModule()">
                        <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                        <span>Micro Task Blocker</span>
                    </div>
                    <div v-if="tasklistofbusiness.length || blockertaskslistofbusiness.length" class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-1 mb-1" @click="openSubTaskList()">
                        <file-text-icon size="1.5x" class="custom-class avatar-icon mr-50"></file-text-icon>
                        <span>Sub Micro Task list</span>
                    </div>
                    <div v-if="editRowData.approvalrnid && editRowData.approvalrnid != null && editRowData.approvalrnid != ''" class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-1 mb-1" @click="openRPDetails(editRowData.approvalrnid)">
                        <file-text-icon size="1.5x" class="custom-class avatar-icon mr-50"></file-text-icon>
                        <span>Release Planner Details</span>
                    </div>
                    

                </div>
              </div>
             
            </div>
          </div>
            <div class="row match-height">
              <div class="col-12">
                <div class=" card-transaction">
                    <div class="card-body mobile-padding-15-10">
                        <div class="transaction-item">
                        <div class="media">
                            <div class="avatar bg-light-success rounded">
                                <div class="avatar-content">
                                    <em class="las la-user-edit font-medium-3"></em>
                                </div>
                            </div>
                            <div class="media-body">
                                <h6 class="transaction-title">{{this.view.taskdefinename}}</h6>
                                <small>Created by {{this.view.createdby}}</small>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-lg-3 col-md-6 ">
                <div class="card card-transaction">
                  <div class="card-body mobile-padding-15-10">
                    <div class="transaction-item">
                      <div class="media">
                          <div v-if="this.taskdefine.status=='open'" class="avatar bg-light-warning rounded">
                              <div class="avatar-content">
                                  <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                          </div>
                          <div v-if="this.taskdefine.status=='resolved'" class="avatar bg-light-primary rounded">
                              <div class="avatar-content">
                                   <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                          </div>
                          <div v-if="this.taskdefine.status=='closed'" class="avatar bg-light-success rounded">
                              <div class="avatar-content">
                                   <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                          </div>
                          <div v-if="this.taskdefine.status=='reopen'" class="avatar bg-light-danger rounded">
                              <div class="avatar-content">
                                   <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                          </div>
                          <div v-if="this.taskdefine.status=='review'" class="avatar bg-light-info rounded">
                              <div class="avatar-content">
                                    <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                          </div>
                          <div v-if="this.taskdefine.status=='approved'" class="avatar bg-light-dark rounded">
                              <div class="avatar-content">
                                    <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                          </div>
                          <div v-if="this.taskdefine.status=='assigned'" class="avatar bg-light-primary rounded">
                              <div class="avatar-content">
                                   <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                          </div>
                          <div class="media-body mobile-padding-15-10">
                              <h6 class="transaction-title">Micro Task Status</h6>
                              <small v-if="this.view.status=='open'" class="capitalisetext text-warning" style="font-weight:500">{{this.view.status}}</small>
                              <small v-if="this.view.status=='resolved'" class="capitalisetext text-primary" style="font-weight:500">{{this.view.status}}</small>
                              <small v-if="this.view.status=='closed'" class="capitalisetext text-success" style="font-weight:500">{{this.view.status}}</small>
                              <small v-if="this.view.status=='reopen'" class="capitalisetext text-danger" style="font-weight:500">{{this.view.status}}</small>
                              <small v-if="this.view.status=='review'" class="capitalisetext text-info" style="font-weight:500">{{this.view.status}}</small>
                              <small v-if="this.view.status=='approved'" class="capitalisetext text-dark" style="font-weight:500">{{this.view.status}}</small>
                              <small v-if="this.view.status=='assigned'" class="capitalisetext text-primary" style="font-weight:500">{{this.view.status}}</small>
                          </div>
                      </div>
                  </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="las la-sync-alt font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Micro Task Type</h6>
                          <small>{{this.view.tasktypename}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item" v-if="this.view.tasktypename=='Deployment'">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <em class="lab la-jenkins font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Jenkins Job Name</h6>
                          <small>{{this.view.jenkinsjobname ? this.view.jenkinsjobname:'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item" v-if="this.view.tasktypename=='Deployment'">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="lab la-gitlab font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Gitlab Repo Name</h6>
                          <small>{{this.view.gitlabreponame ? this.view.gitlabreponame:'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-info rounded">
                          <div class="avatar-content">
                            <em class="las la-users font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Micro Task Level</h6>
                          <!-- <small v-htm="this.view.tasklevelname" v-if="this.view.tasklevelname"></small> -->
                          <small class="capitalisetext">{{this.view.tasklevelname ? this.view.tasklevelname: 'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <watch-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></watch-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Micro Task Estimates</h6>
                          <small class="capitalisetext">{{this.view.estimateshours ? this.view.estimateshours: 'NA'}}</small>
                        </div>
                      </div>
                    </div>
                  </div>                                                     
                </div>
              </div>
              <br/>
              <div class="col-xs-12 col-sm-6 col-lg-3 col-md-6 ">
                <div class="card card-transaction">
                  <div class="card-body mobile-padding-15-10">
                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                              <em class="las la-id-card font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Business Task Name</h6>
                            <small class="text" style="cursor:pointer; color:Blue;" @click="redirecttoBTM('view', {moduleid:view.businessid})">{{this.view.btmname}}</small>
                          </div>
                        </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Project Name</h6>
                          <small class="text" style="cursor:pointer; color:Blue;" @click="redirecttoBTM('projectid', {moduleid: view.projectid})">{{this.view.projectname}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-success rounded">
                          <div class="avatar-content">
                            <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Project Module</h6>
                          <small>{{this.view.projectmodulename}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="i_icon_st la-hdd las lab font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Application</h6>
                          <small>{{this.view.applicationname}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-info rounded">
                          <div class="avatar-content">
                            <em class="i_icon_st la-buffer lab font-medium-5" style="font-weight: 500;"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Application Module</h6>
                          <small>{{this.view.applicationmodulename}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="las la-box font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                            <h6 class="transaction-title">Section</h6>
                            <small>{{this.view.sectionname}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-info rounded">
                          <div class="avatar-content">
                            <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Functionality</h6>
                          <small>{{this.view.functionlityname}}</small>
                        </div>
                      </div>
                    </div>
                  </div>                                                                
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-lg-3 col-md-6 ">
                <div class="card card-transaction">
                  <div class="card-body mobile-padding-15-10">
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <em class="las la-user font-medium-3"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Assigned To</h6>
                          <small class="capitalisetext">{{this.view.empname}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            
                            <em class="las la-calendar font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Created Date</h6>
                          <small>{{dateMonthFormat(this.view.createddate)}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <em class="las la-calendar font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Last Modified By</h6>
                          <small>{{this.view.lastmodifiedby}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                        <div class="media">
                            <div class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                                <em class="las la-calendar font-medium-5"></em>
                            </div>
                            </div>
                            <div class="media-body">
                            <h6 class="transaction-title">Last Modified Date</h6>
                            <small>{{dateMonthFormat(this.view.lastmodifieddate)}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-success rounded">
                          <div class="avatar-content">
                           <em class="las la-calendar font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Start Date</h6>
                          <small>{{(this.view.taskstartdate || this.view.taskstartdate !=null ) ? dateMonthFormat(this.view.taskstartdate) : 'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-danger rounded">
                          <div class="avatar-content">
                            <em class="las la-calendar font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">End Date</h6>
                          <small>{{(this.view.taskenddate || this.view.taskenddate !=null ) ? dateMonthFormat(this.view.taskenddate) : 'NA'}}</small>
                          <br/>
                          <small class="capitalisetext text-danger" v-if="this.view.isapproved_ed && this.view.isapproved_ed == 'Pending'"> This End Date is Pending for the approval - <strong> {{dateMonthFormat(this.view.updated_ed) ? dateFormatnew(this.view.updated_ed): 'NA'}} </strong>.</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="las la-calendar font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Assign Date</h6>
                          <small>{{this.view.taskassigneddate ? dateMonthFormat(this.view.taskassigneddate) : 'NA'}}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-lg-3 col-md-6">
                <div class="card card-transaction">
                    <div class="card-body mobile-padding-15-10">
                        <div class="transaction-item">
                            <div class="media">
                            <div class="avatar bg-light-primary rounded">
                                <div class="avatar-content">
                                <em class="las la-calendar font-medium-5"></em>
                                </div>
                            </div>
                            <div class="media-body">
                                <h6 class="transaction-title">Resolved Date</h6>
                                <small>{{dateMonthFormat(this.view.resolveddate) ? dateMonthFormat(this.view.resolveddate):'NA'}}</small>
                            </div>
                            </div>
                        </div>
                         <div class="transaction-item">
                            <div class="media">
                            <div class="avatar bg-light-success rounded">
                                <div class="avatar-content">
                                <em class="las la-calendar font-medium-5"></em>
                                </div>
                            </div>
                            <div class="media-body">
                                <h6 class="transaction-title">Closed Date</h6>
                                <small>{{dateMonthFormat(this.view.closeddate) ? dateMonthFormat(this.view.closeddate):'NA'}}</small>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
                <div class="col-12 card-transaction ">
                    <div class="card-transaction border mx-2 mb-1 rounded">
                        <div class="card-body mobile-padding-15-10 p-1">
                            <div class="transaction-item" style="overflow:auto">
                                <div class="media">
                                    <div class="avatar bg-light-primary rounded">
                                        <div class="avatar-content">
                                            <monitor-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></monitor-icon>
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="transaction-title">Micro Task Description</h6>
                                        <!-- <span class="data_discriptionview_micros" v-html="formatData(this.taskdefine.desc)"></span> -->
                                        <div class=" tast_case_groupingview_readmore mx-0">
                                            <div class="col-md-12 px-0 micro_case_groupingview_readmore">
                                                <div class="custom-container case_bg_17">
                                                    <div v-if="this.taskdefine.desc" v-bind:class="[readmore]" :id="'cycle_readmore5'">
                                                        <span class="fc_m16_btcode_text" v-html="formatData(this.taskdefine.desc)"></span>
                                                        
                                                    </div>
                                                    
                                                    <div class="cycle_viewmoretext" v-if="this.taskdefine.desc && this.taskdefine.desc.length > 50">
                                                        <a class="btn">
                                                        <strong :id="'id5'" @click="idclick5()">
                                                            Read More
                                                        </strong>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div v-if="this.queanslist.length > 0">
                    <div class="card py-2">
                    <div class="container">
                        <fieldset class="progressBarDiv fieldset_border_vi mb-2 business_fieldset_bgfieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                            
                            <legend v-if="editRowData.reviewers == 'engineeringmanager'">Engineering Manager Review Details</legend>
                            <legend v-if="editRowData.reviewers == 'projectmanager'">Project Manager Review Details</legend>
                            <legend v-if="editRowData.reviewers == 'qalead'">QA Lead Review Details</legend>

                                <div class="card-body">
                                    <form>
                                        <div class="row">
                                            <div class="col-md-12 px-0">
                                                <div class="mb-1">
                                                    <div class="table-responsive" style="overflow-x: inherit !important;">
                                                        <table aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                                            <thead class="d-none"> <th scope="col"></th></thead>
                                                            <tbody>
                                                                <tr v-for="(v, i) in  queanslist" >
                                                                    <td class="text-left" style="min-width:350px">
                                                                        {{v.fieldname}}
                                                                    </td>
                                                                    <td class="text-center tast_case_groupingview_readmore">
                                                                        <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                                            <div class="custom-container case_bg_17">
                                                                                <div v-bind:class="[readmore]" :id="'cycle_readmore'+i">
                                                                                    <span class="fc_m16_btcode_text" v-html="formatData(v.fieldvalue)"></span>
                                                                                </div>
                                                                                <div class="cycle_viewmoretext" v-if="v.fieldvalue.length > 150">
                                                                                    <a class="btn">
                                                                                    <strong :id="i" @click="idclick(i)">
                                                                                        Read More
                                                                                    </strong>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                        </fieldset>
                    </div>
                    </div>
                </div>
                </div>
              <div v-if="task.history" class="col-lg-12 col-md-6 col-12" style="display:none;">
                <div class="card-body">
                <h5 class="mb-1">History</h5>
                <div class="row tbl">
                    <div class="table-responsive mb-1">
                    <table class="table table-sm table-bordered" aria-describedby="History">
                        <thead>
                        <tr>
                            <th scope="col">SR#</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Action Date</th>
                            <th scope="col">Assigned BY</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(data,index) in (task.history ? task.history:[])" v-bind:key="index">
                            <td>{{index+1}}</td>
                            <td>{{data.createddate}}</td>
                            <td>{{data.createdby}}</td> -->
                            <td>{{dateMonthFormat(data.lastmodifieddate)}}</td>
                            <td v-if="data.lastmodifiedby">{{data.lastmodifiedby.split("@")[0]}}</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
            </div>
            </div>
          </div>
        </div>

        <section id="card-navigation" class="taskList" v-if="blockertaskslistofbusiness.length || blockersearch == true || tasklistofbusiness.length || tasksearch == true">
        <div class="row match-height">
            <div class="col-md-12">
                <div class="card mb-3 ">
                    <div class="container text-editar_discription_jobs px-2">
                        <ul class="nav nav-pills nav-pill-warning mb-0 mt-1  business_carateria_tabbbing_diss" id="nav-tabs" role="tablist">
                            
                            <li class="nav-item" v-if="blockertaskslistofbusiness.length">
                                <a class="nav-link" v-bind:class="{'active': blockertaskslistofbusiness.length}" id="subtasklist-tab" data-toggle="tab" href="#subtasklist" role="tab" aria-controls="subtasklist" aria-selected="false">Blocker List - {{ b_totalcount }}</a>
                            </li>
                            <li class="nav-item" v-if="blockertaskslistofbusiness.length==0 && blockersearch == true">
                                <a class="nav-link" v-bind:class="{'active': blockertaskslistofbusiness.length==0 }" id="subtasklist-tab" data-toggle="tab" href="#subtasklist" role="tab" aria-controls="subtasklist" aria-selected="false">Blocker List - {{ b_totalcount }}</a>
                            </li>

                            <li class="nav-item" v-if="tasklistofbusiness.length">
                                <a class="nav-link" v-bind:class="{'active': tasklistofbusiness.length && !blockertaskslistofbusiness.length && tasksearch == true}" id="tasklist-tab" data-toggle="tab" href="#tasklist" role="tab" aria-controls="tasklist" aria-selected="true"> Micro Sub Task - {{ t_totalcount }}</a>
                            </li>
                            <li class="nav-item" v-if="tasklistofbusiness.length==0 && tasksearch == true">
                                <a class="nav-link" v-bind:class="{'active': tasklistofbusiness.length==0 }" id="tasklist-tab" data-toggle="tab" href="#tasklist" role="tab" aria-controls="tasklist" aria-selected="true"> Micro Sub Task - {{ t_totalcount }}</a>
                            </li>

                        </ul>
                    </div>
                    <div class="card-body" v-if="blockertaskslistofbusiness.length || blockersearch == true || tasklistofbusiness.length || tasksearch == true">
                        <div class="tab-content" id="myTabContent">
                            
                            <div v-if="(this.blockersearch == true && blockertaskslistofbusiness.length==0) || blockertaskslistofbusiness.length" class="tab-pane fade" v-bind:class="{'show':blockertaskslistofbusiness.length && tasksearch == false && !tasklistofbusiness.length || blockersearch == true, 'active': tasksearch == false && !tasklistofbusiness.length && blockertaskslistofbusiness.length || blockersearch == true}" id="subtasklist" role="tabpanel" aria-labelledby="subtasklist-tab">
                                <div class="row">
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                        <div class="nav-item nav-search mr-50 search_filtter_animation">
                                            <div class="search">
                                                <input type="text" class="search-box" placeholder="Search for the below fields..." v-model="searchBlocker" @input="inputsearchbloker"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive" >
                                    <table class="table table-sm dataTableListing" id="table_businessceo1" aria-describedby="mydesc">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="align-middle">Sr#</th>                             
                                                <th scope="col" class="text no-wrap align-middle d-table_icon_up bussiness" style="width:20%" @click="getsorting('Task Code')">Task Code</th>
                                                <th class="align-middle d-table_icon_up bussiness" style="min-width:150px;" @click="getsorting('Task Name')">Task Name</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:150px;" @click="getsorting('Project Name')">Project Name</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:150px;" @click="getsorting('Related To')">Related To</th>
                                                <th scope="col" class="align-middle">Status</th>
                                                <th scope="col" class="align-middle">Module Type</th>
                                                <th scope="col" class="align-middle">Created By/Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(data3, index) in blockertaskslistofbusiness">
                                                <tr v-bind:key="data3.id">
                                                    <td class="srno">{{ index+1+b_currPageNum*b_pagelimit }}</td>
                                                    <td><span class="font-weight-bold capitalisetext">{{data3.taskcode}}</span></td>
                                                    <td><span class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" @click="redirectToTask('view', data3.blockertaskid)">{{ data3.taskname }}</span></td>
                                                    <td style="text-transform: capitalize;">{{data3.projectname}}</td>
                                                    <td><span class="font-weight-bold capitalisetext">{{data3.relatedto}}</span></td>
                                                    <td style="text-transform: capitalize;">{{data3.status}}</td>
                                                    <td><span class="font-weight-bold capitalisetext">{{data3.moduletype}}</span></td>
                                                    <td class="details_column_table">
                                                        {{data3.createdby.split('@')[0]}}
                                                        <hr class="hr_border-bottom" />
                                                        <span> {{dateMonthFormat(data3.createddate)}} </span>
                                                    </td>
                                                </tr>
                                            </template>
                                            <template v-if="blockertaskslistofbusiness.length == 0">
                                                <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="pageCenter text-center mb-1">
                                        <pagination v-if="b_showtabledata" v-model="b_page" :records="b_totalcount" :per-page="b_pagelimit" @paginate="clickCallback_blockerlist"/>
                                </div>
                            </div>
                            <div v-if="(this.tasksearch == true && tasklistofbusiness.length == 0) || tasklistofbusiness.length" class="tab-pane fade" v-bind:class="{'show':tasklistofbusiness.length && blockersearch == false && !blockertaskslistofbusiness.length || tasksearch == true, 'active': blockersearch == false && !blockertaskslistofbusiness.length && tasklistofbusiness.length || tasksearch == true}" id="tasklist" role="tabpanel" aria-labelledby="tasklist-tab">
                                
                                <div class="row">
                                   
                                
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <div class="nav-item nav-search mr-50 search_filtter_animation">
                                        <div class="search">
                                            <input type="text" class="search-box" placeholder="Search for the below fields..." v-model="searchTerm" @input="inputsearchtask"/>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                
                                <div class="table-responsive">
                                    <table class="table table-sm dataTableListing table-hover table-striped" aria-describedby="mydesc">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="srno">Sr#</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:160px;" @click="getsorting('Micro Task Code')">Micro Task Code</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:160px;" @click="getsorting('BTC Code')">BTC Code</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:170px;" @click="getsorting('Micro Task Name')"> MicroTask Name</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:170px;" @click="getsorting('Parent Task Code')">Parent Task Code</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:90px;" @click="getsorting('Status')">Status</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" @click="getsorting('Type')">Type</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:150px;" @click="getsorting('Project Name')">Project Name</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:130px;" @click="getsorting('Assignee')">Assignee</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:130px;" @click="getsorting('Assignor')">Assignor</th>
                                                <th scope="col" class="align-middle" style="min-width:130px;">Start date</th>
                                                <th scope="col" class="align-middle" style="min-width:130px;">End date</th>
                                                <th scope="col" class="align-middle" style="min-width:130px;">Estimated Duration</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(data, index) in tasklistofbusiness">
                                                <tr v-bind:key="data.taskcode">
                                                    <td class="srno">{{ index+1+t_currPageNum*t_pagelimit }}</td>
                                                    <td><span class="font-weight-bold capitalisetext">{{data.taskcode}}</span></td>
                                                    <td><span class="font-weight-bold capitalisetext">{{data.businesstaskcode}}</span></td>
                                                    <td><span class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" @click="redirecttourl('view', data)">{{ data.taskname }}</span></td>
                                                    <td class="font-weight-bold capitalisetext" v-if="data.parenttaskcode">{{data.parenttaskcode}}</td>
                                                    <td class="font-weight-bold capitalisetext" v-else>-</td>
                                                    <td style="text-transform: capitalize;">{{data.status}}</td>
                                                    <td style="text-transform: capitalize;">{{data.tasktype}}</td>
                                                    <td style="text-transform: capitalize;">{{data.projectname}}</td>
                                                    <td style="text-transform: capitalize;">{{data.assignee}}</td>
                                                    <td style="text-transform: capitalize;">{{formatName(data.assignor)}}</td>
                                                    <td><span class="font-weight-bold capitalisetext">{{dateMonthFormat(data.taskstartdate)}}</span></td>
                                                    <td><span class="font-weight-bold capitalisetext">{{dateMonthFormat(data.taskenddate)}}</span></td>
                                                    <td><span class="font-weight-bold">{{diffBetweenDates(data.taskstartdate, data.taskenddate)}}</span></td>
                                                </tr>
                                            </template>
                                            <template v-if="tasklistofbusiness.length == 0">
                                                <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="pageCenter text-center mb-1">
                                        <pagination v-if="t_showtabledata" v-model="t_page" :records="t_totalcount" :per-page="t_pagelimit" @paginate="clickCallback_tasklists"/>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>

        <div v-if="taskdefine.attachmentview">
        <div class="card">
            <div class="card-header px-2 py-1 border-bottom">
                <h4 class="card-title">Document List</h4>
            </div>
            <div class="card-body pb-0">
                <div class="row">
            <div class="col-md-12">
                <div class="row documentGrid match-height">
                    <div class="col-xl-2 col-md-4 col-sm-6 my-1" v-for="(data,index) in (this.taskdefine.attachmentview ? this.taskdefine.attachmentview.data:[])" v-bind:key="index">
                        <div class="card card-bordered mb-50">
                            <div class="text-center py-2" style="background:#F8F8F8;">
                            <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xls' || 'xlsx'" src="../../../public/images/icons/ic-xls.png" height="35" />
                            <a :href="data.presignurl" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                            <a v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(data.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                            <a v-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" target="_blank" v-on:click="previewpdf(data.presignurl)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop" title="Click to Preview"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                        </div>
                        <a :href="data.presignurl" class="py-50 px-50 border-top " style="font-size:0.85rem; color:#888;"  target="_blank">{{data.info || data.link | basename}}</a>
                    </div>
                    </div>
                </div>
            </div>
        </div>
            </div>
        </div>
        </div>
        <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">              
            <div class="modal-body p-0">
                <button type="button" class="close imageModalClose" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                <img alt="" src="" class="imagepreview"  >
            </div>
            </div>
        </div>
        </div>

        <section id="card-navigation1" class="addBlockerTaskDiv" >
            <div v-bind:class="[{show:disBlockerActive},card_content,collapse]" class="row match-height">
                <div class="col-md-12">
                    <div class="card mb-3 ">
                        <div class="card-body">
                            <h4 class="card-title d-inline-flex text-primary text-primary-dark" >Add Blocker</h4>
                            <form class="form form-vertical update_Log_btTime">
                                <div class="row">
                                   <div class="form-group col-md-6">
                                            <label for="projectlistdd">Select Task</label>
                                            <button type="button" class="btn btn-sm dropdown-toggle hide-arrow p-0" data-toggle="dropdown" data-boundary="window">
                                                <em class="las la-info-circle text-warning font-medium-2"></em>
                                            </button>
                                            <div class="dropdown-menu col-md-3" style="margin-right: 20px">
                                                <a class=" px-1 d-block text-wrap">
                                                    <span>Note* All Task which are exist in selected Project and BTC</span>
                                                </a>                                                
                                            </div>
                                            <treeselect class="projectlistdd capitalisetext" placeholder="Enter min 4 chars to search Blocker Task Name/Code" :clear-on-select="true" v-model="blockertaskdetails.blockertask" :options="tasksblockerlist" :multiple="true" @input="seltBlockerTask" @close="validateBlocekrForm('t9',$event)" v-on:search-change="getBTaskListToTagBlocker"/>
                                            <div class="errorinputmsg" :clear-on-select="true" v-if="this.blockertaskdetails.errors.blockertask">{{ this.blockertaskdetails.errors.blockertask }}</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="Department">Related To</label>
                                        <treeselect placeholder="Select Related To" class="projectmdllistdd capitalisetext" :options="blockertypes" @input="seltaskblockerstatus" v-model="blockertaskdetails.status" @close="validateBlocekrForm('t9',$event)"/>
                                        <div class="errorinputmsg" :clear-on-select="true" v-if="this.blockertaskdetails.errors.status">{{ this.blockertaskdetails.errors.status }}</div>
                                    </div>
                                    
                                    <div class="form-group col-12">
                                        <label class="form-label">Comment</label>
                                        <ckeditor :editor="editor" placeholder="Comment" v-model="blockertaskdetails.comment"  @input="seltaskblockercomments" :config="editorConfig" tag-name="textarea" @blur="validatelog" @close="validateBlocekrForm('t9',$event)"></ckeditor>
                                        <div class="errorinputmsg" :clear-on-select="true" v-if="this.blockertaskdetails.errors.comment">{{ this.blockertaskdetails.errors.comment }}</div>
                                    </div>

                                    <div class="col-12 mg-top1 text-right">
                                        <button type="button" class="btn btn-relief-primary mr-1" @click="saveBlockerTasks()" :disabled="isdisabled">Submit</button>
                                        <button type="reset" class="btn btn-outline-secondary" @click="handleLogCancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="customTimeline" v-for="(data,index) in logtimehistory" v-bind:key="index">
          <div class="card" v-bind:class="{'reopen':data.status=='reopen', 'closed':data.status=='closed', 'open':data.status=='open', 'resolved':data.status=='resolved','review':data.status=='review','approved':data.status=='approved','assigned':data.status=='assigned'}">
            <div class="card-body">
               <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                    <p class="text-muted" style="margin-top:-3px">Updated on: {{dateMonthFormat(data.lastmodifieddate)}} <span class="mx-50">|</span> by <profilepic v-if="data.imagename" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic>
                    <img alt="" v-if="!data.imagename" :src="defaultimagepath"  width="25" height="25" /> <strong class="text-secondary">{{(data.createdby)}}</strong> <span class="text-muted text-nowrap">{{data.designation}}</span>
                    <span class="mx-50">|</span>
                    <span v-bind:class="{'capitalisetext badge badge-pill badge-light-warning mx-50':data.status=='reopen', 'capitalisetext badge badge-pill badge-light-secondary mx-50':data.status=='closed', 'capitalisetext badge badge-pill badge-light-danger mx-50':data.status=='open', 'capitalisetext badge badge-pill badge-light-success mx-50':data.status=='resolved','capitalisetext badge badge-pill badge-light-info mx-50':data.status=='review','capitalisetext badge badge-pill badge-light-primary mx-50':data.status=='approved','capitalisetext badge badge-pill badge-light-primary mx-50':data.status=='assigned'}">{{data.status}}</span>
                    <span class="capitalisetext badge badge-pill badge-light-primary mx-50">{{data.activity}}</span></p>
                </div>

              <div class="d-inline-flex" v-if="data.coments">
                <div for="" class="d-inline-flex mr-50 mb-0">Comment: </div>
                <div class="d-inline-flex">
                  <div v-html="data.coments"></div>
                </div>
                </div>
                    <div class="row">
                    <div class="col-xl-2 col-md-4 col-sm-6 mb-1" v-for="(res,index) in (data.attachment ? data.attachment.data:[])" v-bind:key="index">
                      <div class="card card-bordered mb-0">
                          <div class="text-center py-2" style="background:#F8F8F8;">
                            <img alt="" v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'xls' || 'xlsx'" src="../../../public/images/icons/ic-xls.png" height="35" />
                            <a v-on:click="getLogtimeDownloadLink(res.link)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                            <a alt="" v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(res.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                            </div>
                             <a :href="res.link"  class="py-50 px-50 border-top " style="font-size:0.85rem; color:#888;"  target="_blank">
                              <span v-if="res.info">
                                {{res.info}} -123
                              </span>
                              <span v-else-if="!res.info">
                                {{res.link | basename}}
                              </span>
                              </a>
                             </div>
                        
                      </div>
                      </div>
                      <hr class="mt-0" />

                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                  <div class="media align-items-center">
                    <span class="pr-1 text-muted  d-inline-flex">
                        <user-plus-icon size="1.5x" class="custom-class avatar-icon font-medium-3 mr-50"></user-plus-icon> 
                        Assigned To</span>
                    <div class="avatar">
                      <profilepic v-if="data.assignedtoimagename" v-bind:get-url="'empprofile/'+data.assigntoempcode+'/'+data.assignedtoimagename" :key="`${componentKey + index}`"></profilepic>
                      <img alt="" v-if="!data.assignedtoimagename" :src="defaultimagepath" width="36" height="36" />
                    </div>
                    <div class="media-body ml-50">
                      <h6 class="mb-0 text-nowrap">{{data.assigend}}</h6>
                      <span class="text-muted text-nowrap">{{decryptText(data.assignedtodesignatio)}}</span>
                    </div>
                  </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>

        
        
          <div>&nbsp;</div>
                <div v-bind:class="[{display:dislogActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div class="card-header" style="cursor:pointer">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 d-flex align-items-center">
                                <div class="avatar bg-light-primary rounded d-inline-flex" >
                                    <div class="avatar-content">
                                        <edit-2-icon size="1.5x" class="custom-class avatar-icon "></edit-2-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex text-primary ml-1">Update Log Time</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{show: isLogActive},card_content,collapse]" >
                    <div class="card-body ">
                    <form class="form form-vertical">
                        <div class="row">
                        <div class="form-group col-md-3">
                            <div class="form-group mg-bot0">
                            <label class="form-label" for="spendtime">Spent Time</label>
                            <div id="spendtime">
                                <vue-timepicker type="time"  v-model="logtime.spenttime" format="HH:mm"  @close="validatelog()"></vue-timepicker>
                                <div class="errorinputmsg" v-if="this.errors.spenttime">{{ this.errors.spenttime }}</div>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group mg-bot0">
                            <label class="form-label">Activity</label>
                            <treeselect placeholder="Select Activity"  class="projectmdllistdd capitalisetext" v-model="logtime.activity" :options="logtime.acivitylist" @input="seltasklogactivity" @close="validatelog()"/>
                            <div class="errorinputmsg" v-if="this.errors.activity">{{ this.errors.activity }}</div>
                            </div>
                            
                        </div>
                        <div class="form-group col-md-3">
                            <div class="form-group mg-bot0">
                            <label for="projectmdllistdd">Micro Task Status</label>
                            <input placeholder="Select Micro Task Status" class="form-control capitalisetext" v-model="logtime.status" :options="taskdefine.statuslist" @input="seltasklogstatus" @close="validatelog()" v-if="taskdefine.statuslist.length<=1" disabled/>
                            <treeselect class="projectmdllistdd capitalisetext" v-model="logtime.status" :options="taskdefine.statuslist" @input="seltasklogstatus" @close="validatelog()" v-else/>
                            </div>
                            <div class="errorinputmsg" v-if="this.errors.status">{{ this.errors.status }}</div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group mg-bot0">
                                <label class="form-label">Assigned To</label>
                                <treeselect placeholder="Enter Min 4Char To Search Assignee" class="allotedto capitalisetext" v-model="logtime.allotedto" :options="taskdefine.allottedtolist" @input="selLogAllotedTo" @close="validatelog()" v-on:search-change ="getEmpallocatedtoProject"/>
                            </div>
                            <div class="errorinputmsg" v-if="this.errors.allotedto">{{ this.errors.allotedto }}</div>
                        </div>
                        <div class="form-group col-12">
                            <label class="form-label">Comment </label>
                            <ckeditor :editor="editor" placeholder="Comment" v-model="logtime.comment" @input="seltasklogcomments" :config="editorConfig" tag-name="textarea"/>
                            <div class="errorinputmsg" v-if="this.errors.comment">{{ this.errors.comment }}</div>                                       
                        </div>
                <div class="col-md-12">
                <div class="form-group">                                    
                    <label for="lblattachment">Attachment</label>
                </div>
                    <div class="form-group">
                      <div class="form-group">
                          <label for="attachment" class="btn btn-sm btn-primary mb-75 mr-75">Select image/docs/videos</label>
                        <button class="btn btn-sm btn-primary mb-75 mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                        <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                        <div class="table-responsive" v-if="file.length>0">
                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col">SR#</th>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(image, key) in file" :key="key">
                                        <td>{{ key+1 }}</td>
                                        <td>{{ image.name }}</td>
                                        <td>
                                            <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="taskdefine.filedescp[key]"/>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                      </div>
                    </div>
              </div>
              
              
              <div v-if="this.queanslist.length == 0">
                <div v-if="logtime.status == 'closed' && (editRowData.reviewers == 'engineeringmanager' || editRowData.reviewers == 'projectmanager' || editRowData.reviewers == 'qalead')" v-bind:data="configurationForm = true">
                    <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div class="card-header py-1 px-2" style="background-color: #f1f2f3;">
                  <div v-if="editRowData.reviewers == 'engineeringmanager'">
                    <h3 class="card-title">
                        Configuration Form for Engineering Manager
                    </h3>
                  </div>
                  <div v-if="editRowData.reviewers == 'projectmanager'">
                    <h3 class="card-title">
                        Configuration Form for Project Manager
                    </h3>
                  </div>
                  <div v-if="editRowData.reviewers == 'qalead'">
                    <h3 class="card-title">
                        Configuration Form for QA Lead
                    </h3>
                  </div>
                </div>
                <div>
                    <div class="card-body">
                        <form class="form form-vertical">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6" v-for="(fieldname,i) in this.fieldnamelist" v-bind:key="i">
                                        <div class="form-group mb-1">
                                            <label for="projectlistdd" class="required-field" v-if="fieldname.validationtype=='required'">{{fieldname.label}}:</label>
                                            <label for="projectlistdd" v-else>{{fieldname.label}}:</label>
                                            <span v-if="fieldname.fieldtype=='text'">
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @blur="validatelog($event)"
                                                v-bind:class="{ 
                                                'form-control': true,
                                                '': !validText(fieldsnamevalues[fieldname.id])}"
                                                @close="validatelog($event)"
                                                v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden')" disabled/>
                                                
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @blur="validatelog($event)"
                                                v-bind:class="{ 
                                                'form-control': true,
                                                '': !validText(fieldsnamevalues[fieldname.id])}"
                                                @close="validatelog($event)"
                                                v-else />
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span>
                                                </p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='number'">
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @blur="validatelog($event)"
                                                v-bind:class="{
                                                'form-control': true,
                                                '': !validText2(fieldsnamevalues[fieldname.id])}"
                                                @close="validatelog($event)"
                                                />
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText2(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='drop-down'">
                                                <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="statuslist"/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='multiselect-dropdown'">
                                                <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="statuslist" @input="selectinput($event, i)" :multiple="true" :clear-on-select="true"/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='checkbox'">
                                                <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue" @input="selectinput($event, i)" :multiple="true" :clear-on-select="true"/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-else-if="fieldname.fieldtype=='radio'">
                                                <span v-for="(radiofieldvalues,k) in fieldname.radiooptionalvalue" v-bind:key="k"><br />
                                                    <div class=" form-check form-check-inline mt-0 mb-0">
                                                        <input class="form-check-input" type="radio" v-model="fieldsnamevalues[fieldname.id]" :value="radiofieldvalues.id" > <span class="pl-1" > {{Object.values(radiofieldvalues)[0]}} </span>
                                                    </div>
                                                </span>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-else-if="fieldname.fieldtype=='date'">
                                                <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                                class="startdate capitalisetext" id="EndDate" :minute-step="5" :disabled-date="notBeforeToday"></date-picker>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-else-if="fieldname.fieldtype=='datetime-local'">
                                                <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden')" disabled></vue-timepicker>
                                                <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-else></vue-timepicker>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                </div>

                </div>
              </div>    

                        <div class="col-12 mg-top1 text-right">
                            <button type="button" class="btn btn-relief-primary mr-1" @click="savelogData()" v-bind:disabled="taskdefine.disblesavebtn">Submit</button>
                            <button type="reset" class="btn btn-outline-secondary" @click="handleLogCancel()">Cancel</button>
                        </div>
                          
                        </div>
                    </form>
                    </div>
                </div>
                </div>
                
        <div v-bind:class="[{display:disRequestActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div class="card-header" style="cursor:pointer" v-on:click="myrequestollapse">
              <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="avatar bg-light-primary rounded d-inline-flex" >
                            <div class="avatar-content">
                                <edit-2-icon size="1.5x" class="custom-class avatar-icon"></edit-2-icon>
                            </div>
                        </div>
                        <h4 class="card-title d-inline-flex text-primary ml-1" >{{this.formActionLabel}}</h4>
                    </div>
                </div>
            </div>
          </div>
          <div v-bind:class="[{show: isRequestActive},card_content,collapse]" >
            <div class="card-body">
              <form >
                <div class="row">
                  <div class="col-md-12">
                      <div class="mb-1" v-if="this.editRowData.moduletype !=0">
                          <div class="table-responsive" style="overflow-x: inherit !important;">
                              <table class="table table-sm dataTableListing table-hover table-striped table-bordered" aria-describedby="mydesc">
                                  <thead>
                                      <tr>
                                          <th scope="col" class="text-center">Business Task Name</th>
                                          <th scope="col" class="text-center">Project Name</th>
                                          <th scope="col" class="text-center">Project Module</th>
                                          <th scope="col" class="text-center">Task Level</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td class="text-center">{{this.editRowData.btmname}}</td>
                                          <td class="text-center">{{this.editRowData.projectname}}</td>
                                          <td class="text-center">{{this.editRowData.projectmodulename}}</td>
                                          <td class="text-center">{{this.getmodulename(2)}}</td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-6 col-md-6 col-lg-4">
                      <div class="form-group mg-bot0">
                          <label for="taskdefinename">Micro Task Name</label>
                              <input type="text" id="taskdefinename" class="form-control" name="taskdefinename" placeholder="Micro Task Name" v-model="taskdefine.taskdefinename"
                                v-bind:class="{ 
                                  'form-control': true,
                                  'is-invalid': !validText(taskdefine.taskdefinename) && nameBlured, }"
                              v-on:blur="nameBlured = true"/>
                          <div class="invalid-feedback"> Micro Task Name must be length of 5 chars at least and only letters are allowed</div>
                      </div>
                  </div>
                  <!-- <div v-if="this.taskdefine.isaddsubtask==true" class="col-sm-6 col-md-6 col-lg-4"> -->
                  <div class="col-sm-6 col-md-6 col-lg-4">
                    <div class="form-group mg-bot0">
                        <label for="appmdldesc">Technology Name</label>
                        <input class="form-control appmdldesc" v-model="taskdefine.technology" id="Technology" placeholder="Technology Description "
                        v-bind:class="{ 
                        'form-control': true,
                        '': !validText1(taskdefine.technology),
                        }"/>
                        <div class="errorinputmsg" v-if="!validText1(taskdefine.technology) && this.errors.taskdefine.technology">Technology is required</div>
                    </div>
                </div>
                <!-- <div v-if="this.taskdefine.isaddsubtask==true" class="col-sm-6 col-md-6 col-lg-4"> -->
                  <div class="col-sm-6 col-md-6 col-lg-4" >
                    <div class="form-group ">
                      <label for="appmdldesc">Activity Name</label>
                      <div class="input-group input-group-merge">
                        <treeselect class="capitalisetext" placeholder="Select activity name" v-model="taskdefine.activity" :options="activityhoursopts" @select="selActivityName"  v-on:change="validateForm('t18',$event)"/>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.activity" >{{this.errors.taskdefine.activity}}</div>
                    </div>
                  </div>
                 
                  <div  class="col-sm-6 col-md-6 col-lg-4">
                    <div class="form-group ">
                      <label for="projectmdllistdd">Micro Task Estimates</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Micro Task Estimates" v-model="taskdefine.estimateshours" :options="estimateshours" @select="selestimateshours" v-on:change="validateForm('t18',$event)"/>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.estimateshours">{{ this.errors.taskdefine.estimateshours }}</div>
                    </div>
                  </div>

                  <!-- <div v-if="this.taskdefine.isaddsubtask==true && taskdefine.estimateshours != null" class="col-sm-6 col-md-6 col-lg-4" > -->
                <div v-if="taskdefine.estimateshours != null" class="col-sm-6 col-md-6 col-lg-4" >
                    <div class="form-group ">
                      <label for="tasktype">Estimated Hours</label>
                      <div class="input-group input-group-merge" >
                        <treeselect placeholder="Estimated Hours" :options="ahourslist" @input="selahour" v-model="taskdefine.ahour" v-on:close="validateForm('t18', $event)"/>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.ahour" >{{this.errors.taskdefine.ahour}}</div>
                    </div>
                  </div>
                  <!-- <div v-if="this.taskdefine.isaddsubtask==true && taskdefine.estimateshours != null" class="col-sm-6 col-md-6 col-lg-4" > -->
                    <div v-if="taskdefine.estimateshours != null" class="col-sm-6 col-md-6 col-lg-4" >
                    <div class="form-group ">
                      <label for="tasktype"> Estimated Minutes</label>
                      <div class="input-group input-group-merge" >
                        <treeselect placeholder="Estimated Minutes" :options="aminutelist" @input="selamin" v-model="taskdefine.amin" v-on:close="validateForm('t18', $event)"/>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.amin" >{{this.errors.taskdefine.amin}}</div>
                    </div>
                  </div>
                  <div class="form-group col-sm-6 col-md-6 col-lg-4" v-if="(this.editRowData.moduletype ==0 || this.taskTypedesignCreative.includes(this.taskdefine.type))">
                      <div class="form-group mg-bot0">
                          <label for="taskdefinetaskleveldd">Micro Task Level</label>
                          <treeselect placeholder="Select Micro Task Level" :options="tasklevel" @select="seltaskdftypedata" v-model="taskdefine.level" v-on:close="validateForm('t18',$event)" />
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.level">{{ this.errors.taskdefine.level }}</div>
                  </div>
                  
                  <div class="form-group col-sm-6 col-md-6 col-lg-4" v-if="this.editRowData.moduletype ==0">
                      <div class="form-group mg-bot0">
                          <label for="">Project Name</label>
                          <div class="input-group input-group-merge">
                          <treeselect v-if="(taskdefine.projectid == null || !taskdefine.isredirected) && !taskdefine.isaddsubtask" class="projroleassprojectdd" placeholder="Enter min 4 chars to search project name" v-on:search-change="projectChange" :options="taskdefine.projectlist" @input="selprojdata" v-model="taskdefine.projectid" v-on:close="validateForm('t18',$event)" />
                          <treeselect type="text" disabled v-else :options="taskdefine.projectlist" v-model="taskdefine.projectid"/>
                          </div>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.projectid">{{ this.errors.taskdefine.projectid }}</div>
                  </div>

                  <div class="form-group col-sm-6 col-md-6 col-lg-4">
                      <div class="form-group mg-bot0">
                          <label for="tasktype">Micro Task Type </label>
                          <div class="input-group input-group-merge" v-if="this.editRowData.reviewers!='' && this.taskdefine.isedit">  
                            <treeselect placeholder="Select Micro Task Type" :options="tasktypelist" @select="seldefinetypedata" v-model="taskdefine.type" v-on:close="validateForm('t18',$event)" v-if="this.editRowData.reviewers!='' && this.taskdefine.isedit" :disabled="true"/>

                          </div>
                          <div class="input-group input-group-merge" v-else>  
                            <treeselect placeholder="Select Micro Task Type" :options="tasktypelist" @select="seldefinetypedata" v-model="taskdefine.type" v-on:close="validateForm('t18',$event)" v-if="taskdefine.type==25 || taskdefine.type==26 && issubmicrotask" :disabled="true"/>
                          <treeselect placeholder="Select Micro Task Type" :options="tasktypelist" @select="seldefinetypedata" v-model="taskdefine.type" v-on:close="validateForm('t18',$event)" v-if="taskdefine.type == 25 || taskdefine.type == 26 || this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.isedit" :disabled="true"/>
                          <treeselect placeholder="Select Micro Task Type" :options="tasktypelist" @select="seldefinetypedata" v-model="taskdefine.type" v-on:close="validateForm('t18',$event)" v-else/>
                          </div>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.type">{{ this.errors.taskdefine.type }}</div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-group">
                      <label class="form-label" for="project-name">Micro Task Description </label>
                      <ckeditor :editor="editor" placeholder="Micro Task Description" v-model="taskdefine.desc" :config="editorConfig" tag-name="textarea"
                      v-bind:class="{ 
                              'form-control': true,
                              'is-invalid': !validText(taskdefine.desc) && descBlured, }"
                              v-on:blur="descBlured = true"/>
                      <div class="invalid-feedback">Micro Task description required</div>                                           
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-6 col-md-6 col-lg-4" v-if="this.editRowData.moduletype ==0">
                      <div class="form-group mg-bot0">
                          <label for="businessstaskmaster">Business Task Name</label>
                          <div class="input-group input-group-merge">
                          <treeselect v-if="(taskdefine.businesstype  == null || !taskdefine.isredirected) && !taskdefine.isaddsubtask " placeholder="Select Business Task Name"  :options="taskdefine.businesstasklist" @input="seldefinebusinessdata" v-model="taskdefine.businesstype" v-on:close="validateForm('t18',$event)" />
                          <treeselect v-else disabled :options="taskdefine.businesstasklist" v-model="taskdefine.businesstype"/>
                          </div>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.businesstype">{{ this.errors.taskdefine.businesstype }}</div>
                  </div>
                  <div class="form-group col-sm-6 col-md-6 col-lg-4" v-if="this.editRowData.moduletype == 0 && this.taskdefine.type !='Ask for PMO' && taskdefine.isaddsubtask == true">
                      <div class="form-group mg-bot0">
                          <label for="applicationtxt">Application Name</label>
                          <div class="input-group input-group-merge">
                              <treeselect v-if="(this.selectedLevel >= 3 || this.selectedLevel == 2) && (taskdefine.application == null || !taskdefine.isredirected)"  id="appinp" placeholder="Select Application Name" class="applicationdd"  :options="taskdefine.applicoptions" v-model="taskdefine.application" @input="selappldata" v-on:close="validateForm('t18',$event)" />
                              <treeselect v-else disabled :options="taskdefine.applicoptions" v-model="taskdefine.application" />
                          </div>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.application">{{ this.errors.taskdefine.application }}</div>
                  </div>
                  <div class="form-group col-sm-6 col-md-6 col-lg-4" v-if="this.editRowData.moduletype ==0 && this.taskdefine.type !='Ask for PMO' && taskdefine.isaddsubtask == true">
                      <div class="form-group mg-bot0">
                          <label for="taskdefineprojectmdldd">Project Module</label>
                          <div class="input-group input-group-merge">
                              <treeselect v-if="(this.selectedLevel >= 2 && this.selectedLevel != 3) && (taskdefine.module == null || !taskdefine.isredirected)" id="modinp" placeholder="Select Project Module" class="taskdefineprojectmdldd" :options="taskdefine.projectmdllist" @input="selprojmdldata" v-model="taskdefine.module" v-on:close="validateForm('t18',$event)" />
                              <treeselect v-else disabled :options="taskdefine.projectmdllist" v-model="taskdefine.module"/>
                          </div>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.module">{{ this.errors.taskdefine.module }}</div>
                  </div>
                  <div class="form-group col-sm-6 col-md-6 col-lg-4" v-if="this.editRowData.moduletype ==0 && this.taskdefine.type !='Ask for PMO' && taskdefine.isaddsubtask == true">
                      <div class="form-group mg-bot0">
                          <label for="applicationmodule">Application Module</label>
                          <div class="input-group input-group-merge">
                              <treeselect v-if="this.selectedLevel >= 4 && (taskdefine.appmodule == null || !taskdefine.isredirected)" id="appmodinp" placeholder="Select Application Module Name" class="applicationdd"  v-model="taskdefine.appmodule" :options="taskdefine.appmodulelist" @input="selapplmdldata" v-on:close="validateForm('t18',$event)" />
                              <treeselect v-else disabled v-model="taskdefine.appmodule" :options="taskdefine.appmodulelist"/>
                          </div>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.application">{{ this.errors.taskdefine.application }}</div>
                  </div>
                  <div class="form-group col-sm-6 col-md-6 col-lg-4" v-if="this.editRowData.moduletype ==0 && this.taskdefine.type !='Ask for PMO' && taskdefine.isaddsubtask == true">
                      <div class="form-group mg-bot0">
                          <label for="hostingplaturl">Section Module</label>
                          <div class="input-group input-group-merge">
                              <treeselect v-if="this.selectedLevel >= 5 && (taskdefine.devsection == null || !taskdefine.isredirected)" id="secinp" placeholder="Select Section Module Name" class="applicationdd"  v-model="taskdefine.devsection" :options="taskdefine.sectionlist" @input="tskdfnsectiondata" v-on:close="validateForm('t18',$event)" />
                              <treeselect v-else disabled v-model="taskdefine.devsection" :options="taskdefine.sectionlist"/>
                          </div>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.devsection">{{ this.errors.taskdefine.devsection }}</div>
                  </div>
                  <div class="form-group col-sm-6 col-md-6 col-lg-4" v-if="this.editRowData.moduletype ==0 && this.taskdefine.type !='Ask for PMO' && taskdefine.isaddsubtask == true">
                      <div class="form-group mg-bot0">
                          <label for="">Functionality </label>
                          <div class="input-group input-group-merge">
                              <treeselect v-if="this.selectedLevel >= 6 && (taskdefine.feature == null || !taskdefine.isredirected)" id="funcinp" placeholder="Select Functionality Name" class="applicationdd"  v-model="taskdefine.feature" :options="taskdefine.featurelist" @input="tskdfnfnlitydata" v-on:close="validateForm('t18',$event)" />
                              <treeselect v-else disabled v-model="taskdefine.feature" :options="taskdefine.featurelist"/>
                          </div>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.feature">{{ this.errors.taskdefine.feature }}</div>
                  </div>
                  <div class="form-group col-sm-6 col-md-6 col-lg-4">
                    <div class="form-group mg-bot0">
                      <label for="projectmdllistdd">Micro Task Status</label>
                      <input placeholder="Select Micro Task Status" class="form-control capitalisetext" v-model="taskdefine.status" :options="taskdefine.statuslist" @select="seltaskstatus" @close="validateForm('t18',$event)" v-if="taskdefine.statuslist.length<=1" disabled/>
                      <treeselect class="projectmdllistdd capitalisetext" v-model="taskdefine.status" :options="taskdefine.statuslist" @select="seltaskstatus" @close="validateForm('t18',$event)" v-else/>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.status">{{ this.errors.taskdefine.status }}</div>
                  </div>
                  <div class="form-group col-sm-6 col-md-6 col-lg-4">
                    <div class="form-group mg-bot0">
                      <label for="">Assigned To </label>
                      <div class="input-group input-group-merge">
                        <treeselect placeholder="Select Employee Name" class="applicationdd capitalisetext" v-model="taskdefine.allottedto" :options="taskdefine.allottedtolist" @input="selallottedto" v-on:close="validateForm('t18', $event)" v-if="taskdefine.type==25" :disabled="true"/>
                        <treeselect placeholder="Enter Min 4 Chars To Search Assigned To" class="applicationdd capitalisetext" v-model="taskdefine.allottedto" :options="taskdefine.allottedtolist" @input="selallottedto" v-on:close="validateForm('t18', $event)" v-on:search-change ="getEmpallocatedtoProject" v-else/>
                      </div>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.allottedto">{{ this.errors.taskdefine.allottedto }}</div>
                  </div>
                  <div class="form-group col-sm-6 col-md-6 col-lg-4">
                      <div class="form-group mg-bot0">
                          <label for="">Micro Task Start Date</label>
                          <div class="input-group input-group-merge w-100">
                              
                              <date-picker placeholder="Select Start Date" type="datetime" format="YYYY-MM-DD HH:mm" v-model="taskdefine.taskstartdate"
                               valueType="YYYY-MM-DD HH:mm:ss" class="startdate w-100" v-on:change="validateDate('taskstartdate', $event)"></date-picker>
                          </div>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.taskstartdate">{{ this.errors.taskdefine.taskstartdate }}</div>
                  </div>
                  <div class="form-group col-sm-6 col-md-6 col-lg-4">
                      <div class="form-group mg-bot0">
                          <label for="">Micro Task End Date</label>
                          <div class="input-group input-group-merge w-100">
                              <date-picker placeholder="Select End Date" @change="onchangedate()" type="datetime" format="YYYY-MM-DD HH:mm" v-model="taskdefine.taskenddate"
                               valueType="YYYY-MM-DD HH:mm:ss" class="startdate w-100" v-on:change="validateDate('taskenddate', $event)"></date-picker>
                          </div>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.taskenddate">{{ this.errors.taskdefine.taskenddate }}</div>
                      <div class="errorinputmsg" v-if="this.view.isapproved_ed && this.view.isapproved_ed == 'Pending'">This End Date is Pending for the approval - <strong> {{dateMonthFormat(this.view.updated_ed) ? dateFormatnew(this.view.updated_ed): 'NA'}} </strong></div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4" v-if="(editRowData.taskenddate !=null && taskdefine.taskenddate && taskdefine.taskenddate != dateYMDHMSFormat(editRowData.taskenddate) && taskdefine.isaddsubtask != true)">
                      <label for="">End Date Update Reason</label>
                      <textarea class="form-control" placeholder="Enter the estimate update reason here" v-model="taskdefine.ed_reason"  v-on:close="validateForm('t18', $event)"
                      v-bind:class="{ 
                        'form-control': true,
                        'is-invalid': !validText1(taskdefine.ed_reason), 
                      }"></textarea>
                      <div class="errorinputmsg" v-if="!validText1(taskdefine.ed_reason) && this.errors.taskdefine.ed_reason">{{ this.errors.taskdefine.ed_reason }}</div>  
                  </div>
                  <div class="form-group col-sm-6 col-md-6 col-lg-4" v-if="(!this.taskTypedesignCreative.includes(this.taskdefine.type))">
                      <div class="form-group mg-bot0">
                          <label for="">Task Assign Date Time</label>
                          <div class="input-group input-group-merge w-100">
                              
                              <date-picker v-bind:disabled="taskdefine.isassigneddateSelected" type="datetime" format="YYYY-MM-DD HH:mm" v-model="taskdefine.taskassigneddate" valueType="YYYY-MM-DD HH:mm:ss" class="startdate w-100" v-on:change="validateDate('taskassigneddate', $event)"></date-picker>
                          </div>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.taskassigneddate">{{ this.errors.taskdefine.taskassigneddate }}</div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4">
                    <div class="form-group">
                      <label for="">Subscribers</label>
                      <div class="input-group input-group-merge">
                        <treeselect class="projroleassprojectdd capitalisetext" placeholder="Enter Min 4 Chars To Search Subscribers" :multiple="true" :clear-on-select="true" :options="taskdefine.subscriberslist" v-model="taskdefine.subscribers" @input="selSubscribers" v-on:close="validateForm('t18', $event)" v-on:search-change ="getEmpallocatedtoProject"/>
                      </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.subscribers">{{ this.errors.taskdefine.subscribers }}</div>
                    </div>
                  </div>
                 
                  <div class="col-md-12">
                <div class="form-group">                                    
                    <label for="lblattachment">Attachment</label>
                </div>
                <div class="form-group">
                    <label for="attachment" class="btn btn-sm btn-primary mb-75 mr-75">Select image/docs/videos</label>
                    <button class="btn btn-sm btn-primary mb-75 mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                    <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                    <div class="table-responsive" v-if="taskdefine.attachmentview">
                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                            <thead>
                                <tr>
                                    <th scope="col">SR#</th>
                                    <th scope="col">Document Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, k) in taskdefine.attachmentview.data" :key="k">
                                <td>{{k+1}}</td>
                                <td>
                                    <label>
                                        <div class="click-zoom">
                                        <input type="checkbox" />
                                            <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  :src="data.presignurl"  style="width:50px;height:50px"  />
                                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/pdf.png" style="width:50px;height:50px" />
                                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xlsx'||'csv'||'xls'" src="../../../public/images/icons/xls.png" style="width:50px;height:50px" />
                                            &nbsp;
                                            <a :href="data.presignurl" target="_blank">{{data.link | basename}}</a>
                                        </div>
                                    </label>
                                </td>
                                    <td>{{data.info}}</td>
                                    <td>
                                        <button type="button" class="btn btn-sm danger" @click="removes3file(k,taskdefine.attachmentview.data)">
                                          <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.attachment">{{ this.errors.taskdefine.attachment }}</div>
                        <div class="table-responsive" v-if="file.length>0">
                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col">SR#</th>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                 <tbody>
                                    <tr v-for="(image, key) in file" :key="key">
                                        <td>{{ key+1 }}</td>
                                        <td>{{ image.name }}</td>
                                        <td>
                                            <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="taskdefine.filedescp[key]"/>
                                        </td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                 </tbody>
                            </table>
                        </div>
                </div>
              </div>
                  <div class="col-12 mg-top1 text-right">
                      <button type="button" class="btn btn-relief-primary btn-1 mr-1 "  @click="saveData('t18',$event)" id="submitbtnt18" v-bind:disabled="taskdefine.disblesavebtn">Submit</button>
                      <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                  </div>
                </div>
              </form>
          </div>
        </div>
            </div>
      </section>
    </div>
  </div>
  
</template>
<script>
import axios from 'axios';
import apiUrl from '../../constants';
import moment from 'moment';
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Trash2Icon,SearchIcon,EditIcon,FileTextIcon,DownloadIcon,ZoomInIcon,MonitorIcon, PlusSquareIcon,EyeIcon, Edit2Icon, PlusIcon, MinusSquareIcon, ClipboardIcon, PenToolIcon, PocketIcon, CodepenIcon, UserIcon, CalendarIcon,WatchIcon, PaperclipIcon, SettingsIcon,UserPlusIcon,XIcon,MoreVerticalIcon } from 'vue-feather-icons';
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import profilepic from '../profilepic.vue';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import presignurl from '../getPresignurl.vue';
import commonMethods from '../../utils/commonMethods';

export default {
    name:"TaskList",
    components:{
      VueTimepicker,
      Loading,PlusSquareIcon,DownloadIcon,ZoomInIcon,Trash2Icon,XIcon,
      VueElementLoading,
      WatchIcon,
      vSelect,
      EditIcon,
      EyeIcon,
      Edit2Icon,
      Treeselect,
      DatePicker,
      SearchIcon,
      Pagination,
      PlusIcon,
      CalendarIcon,
      MinusSquareIcon,
      ClipboardIcon,
      UserPlusIcon,
      PenToolIcon, PocketIcon, CodepenIcon, UserIcon, PaperclipIcon, SettingsIcon,MonitorIcon,MoreVerticalIcon,
      ckeditor: CKEditor.component,FileTextIcon,
      profilepic,presignurl
    },
    mixins: [ commonMethods ],

    data() {
      return {
        tasklistofbusiness: [],
        tasksearch: false,
        t_totalcount: 0,
        t_pageCount: 0,
        t_page:1,
        t_pagelimit:apiUrl.LIMIT,
        t_currPageNum: 0,
        t_pageoffset:1,
        t_pagefeatures:[],
        t_showtabledata:true,
        searchTerm: null,
        defaultimagepath:apiUrl.defaultimagepath,
        componentKey:0,
        imgExt:['jpeg','jpg','png'],
        listActive:true,
        isLoading: false,
        fullPage: true,
        listfullPage:true,
        islistLoading:false,
        tasklevelopts: apiUrl.tasklevel,
        isRequestActiveView: true,
        isSearchRequestActive:false,
        disRequestActive: true,
        selectedLevel: 10,
        level: 1,
        logtimehistory:[],
        companymasterlist: [],
        tasktypelist: [],
        typenamelist: [],
        projectlist: [],
        taskassignmentList:[],
        showAddForm:false,
        selected: null,
        readmore:'outer',
        task:{
           history:[],
           project:'',
        },
        countryName: [
            { label: 'select_value', id: 'Select Value' },
            { label: 'Russia', id: 'Russia' },
            { label: 'Canada', id: 'Canada' },
            { label: 'China', id: 'China' },
            { label: 'United States', id: 'United States' },
            { label: 'Brazil', id: 'Brazil' },
            { label: 'Australia', id: 'Australia' },
            { label: 'India', id: 'India' },
        ],
        logtime:{
            acivitylist:apiUrl.acivitylist,
            spenttime:"",
            activity:null,
            status:null,
            comment:null,
            allotedto: null,
            attachmentview:[],
            isTimeLogged: false,
        },
        editor: ClassicEditor,
        editorConfig: {
            toolbar: {
                items: [
                    'heading', '|', 'bold',
                    'italic', '|', 'bulletedList',
                    'numberedList', '|', 'insertTable', '|',
                    'undo', 'redo', '|',
                ],
            },
        },
        tasknameBlured:'',
        seltestcsproject:'',
        project:'',


         dislogActive: true,
            isLogActive: false,
        editRowData:[],
        glbMdl:'',
        glbst:'',
        TimeFormat: apiUrl.TimeFormat,
        glbMdlLbl:'company',
        projectType:[],
        projectdescription:'',
        tasklevel:apiUrl.tasklevel,
        tasktypekeylist:apiUrl.tasktypekeylist,
        tasktype:{
            oldprojectid:null,
            project:null,
            name:null,
            projectid:null,
            projectlist:[{
                id:'',
                label:''
            }],
            desc:null,
            level:null,
            descerror:null,
            levelerror:null,
            type: null,
            key: null,
            redmineid: null,
            btmpriority: null,
            typelist:[
                {id:'Business Task',label:'Business Task'},
                {id:'Tech Task',label:'Tech Task'},
            ],
            keylist:[
                {id:'Key',label:'Key'},
                {id:'Normal',label:'Normal'},
            ],
            referenceslist:[
                {id:'Customer Care',label:'Customer Care'},
                {id:'End Customer',label:'End Customer'},
                {id:'Finance',label:'Finance'},
                {id:'Logistics',label:'Logistics'},
                {id:'Product',label:'Product'},
                {id:'Quality Check',label:'Quality Check'},
                {id:'Technology',label:'Technology'},
                {id:'Warehouse',label:'Warehouse'},
            ],
           
            btmprioritylist: [
                { id: 0, label: '0' },
                { id: 1, label: '1' },
                { id: 2, label: '2' },
                { id: 3, label: '3' },
                { id: 4, label: '4' },
                { id: 5, label: '5' },
                { id: 6, label: '6' },
                { id: 7, label: '7' },
                { id: 8, label: '8' },
                { id: 9, label: '9' },
                { id: 10, label: '10' },
            ],
            references: null,
            bsh: null,
            psh: null,
            priorityerr: null,
            dept: null,
            depterr: null,
            deptlist: [
                { id: 'Sourcing', label: 'Sourcing' },
                { id: 'CC', label: 'CC' },
                { id: 'Marketing', label: 'Marketing' },
                { id: 'Finance', label: 'Finance' },
                { id: 'Purchase', label: 'Purchase' },
            ],
            startdate: null,
            enddate: null,
            tstatus:null
        },
        tasknamemaster:apiUrl.tasknamemaster,
        estimateshours: apiUrl.estimateshours,
        status:apiUrl.status,
        taskdefine:{
            ed_reason: null,
            type:null,
            name:null,
            level:null,
            projectid:null,
            projectlist:[{
                id:'',
                label:''
            }],
            subscriberslist: [],
            subscribers: null,
            projectname:null,
            module:null,
            projectmdllist:[],
            application:null,
            applicoptions:[{
                id:'',
                label:''
            }],
            applicationname:null,
            appmodule:null,
            appmodulelist:[],
        
            applicationmodulename:'',
            devsection:null,
            sectionlist:[{
                id:'',
                label:''
            }],
            feature:null,
            featurelist:[{
                id:'',
                label:''
            }],
            taskstartdate:null,
            taskenddate:null,
            taskassignby:'',
            taskassigneddate:'',
            taskdefinename:'',
            businesstype:null,
            businesstasklist:[{
                id:'',
                label:''
            }],
            projectmodulename:null,
            isedit:true,
            isaddsubtask:true,
            shouldDisable: false,
            allottedto: null,
            allottedtolist: null,
            status: null,
            estimateshours: null,
            desc:null,
            isassigneddateSelected:true,
            attachment:null,
            attachmentview:null,
            attachmentnew:null,
            filedescp:[],
            disblesavebtn:false,
            statuslist: [],//apiUrl.status,
            technology: null,
            activity: null,
            activityhours: null,
            businesstaskcode: null, 
            ahour: null,
            amin: null,
        },
        taskBlured:false,
        valid : false, 
        projvalue:null,
        applvalue:null,
        applicoptions:[ {
            id: '',
            label: ''
        }],
        startdate:null,
        errors: {
            spenttime:"",
            activity:null,
            status:null,
            comment: null,
            taskdefine:{
                ed_reason: null,
                type:null,
                name:null,
                level:null,
                projectid:null,
                module:'',
                application:null,
                appmodule:'',
                devsection:'',
                feature:'',
                taskstartdate:null,
                taskenddate:null,
                taskassignby:'',
                taskassigneddate:'',
                taskdefinename:'',
                businesstype:null,
                allottedto: null,
                status: null,
                desc:null,
                attachment:null,
                attachmentnew:null,
                subscribers: null,
                estimateshours:null,
                technology: null,
                activity: null,
                activityhours: null, 
                ahour: null,
                amin: null,    
            },
        },
        view:{
            taskdefinename: null,
            tasktypename: null,
            tasklevelname: null,
            projectname: null,
            businessname: null,
            applicationname: null,
            projectmodulename: null,
            applicationmodulename: null,
            sectionname: null,
            functionlityname: null,
            createdby: null,
            approvedby: null,
            lastmodifiedby: null,
            lastmodifieddate: null,
            taskcode: null,
            btmname: null,
            empname: null,
            businessid: null,
            projectid:null,
            documentlist: [],
            estimateshours: null,
            gitlabreponame: null,
            jenkinsjobname: null,
            isapproved_ed: null,
            updated_ed: null,
            resolveddate: null,
            closeddate: null,
            releasenotemasterid:null,
            status: null,
        },
        tasklevellist:[ {
            id: '',
            label: ''
        }],
        isAddState:false,
        totalcount: 0,
        pageCount: 0,
        page:1,
        pagelimit:apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset:1,
        pagefeatures:[],
        projectnameist:[],
        loginuscondition:'',
        attachment: [],
        attachmentview: null,
        isDetActive:false,
        isRequestActive:false,
        card_content:'card-content',
        collapse:'collapse',
        card:'card',
        border_warning:'border-warning',
        formActionLabel: 'Create Task',
        nameBlured: false,
        backpagedata: [],
        descBlured:false,
        commentBlured:false,
        loginusername:'',
        tokendata: null,
        file:[],
        imagext: ['jpg', 'jpeg', 'png'],
        taskmodule: 0,
        loginpersonname:null,
        accepttype:apiUrl.uploadfiletype,
        statusconfig :apiUrl.statusconfig,
        validFileExtensions:apiUrl.validFileExtensions,
        orderBy: {
            task: null,
            issue: null,
            bloker: null,
        },
        tasklistoOrderBy : [
            {id: 'status', label:'status'},
            {id: 'project', label:'project'},
            {id: 'assignee', label:'assignee'},
            {id: 'asginor', label:'asignor'}
        ],
        activityhoursopts: apiUrl.activityhoursopts.sort((a, b) => a.label.localeCompare(b.label)),
        ahourslist: [],
        aminutelist: [],

        disBlockerActive: false,
        blockertaskdetails: {
            projectid: null,
            comment: null,
            blockerbtcs: null,
            blockertask: null,
            businesstaskmasterid: null,
            status: null,
            errors: {
                    projectid: null,
                    comment: null,
                    blockertask: null,
                    status: null,
                    btcItemsErr: null,
                    blockerbtcs: null,
                },
        },
        tasktype: {
            projectlist: null   
        },
        tasksblockerlist: null,
        blockertypes: [
                { id: 'Blocks', label: 'Blocks' },
                { id: 'Is Blocked By', label: 'Is Blocked By' },
                { id: 'Clones', label: 'Clones' },
                { id: 'Is Cloned By', label: 'Is Cloned By' },
                { id: 'Duplicates', label: 'Duplicates' },
                { id: 'Is Duplicated By', label: 'Is Duplicated By' },
                { id: 'Relates To', label: 'Relates To' }
            ],
        isdisabled:false,
        blockertaskslistofbusiness: [],
        blockersearch: false,
        b_totalcount: 0,
        b_pageCount: 0,
        b_page:1,
        b_pagelimit: apiUrl.LIMIT,
        b_currPageNum: 0,
        b_pageoffset:1,
        b_pagefeatures:[],
        b_showtabledata:true,
        searchBlocker:null,   
        issubmicrotask: null,
        parentTaskData: [],

        // TimeFormat: apiUrl.TimeFormat,
        //     statuslist: apiUrl.tcscriptstatuslist,
            moduletrackerid: null,
            fieldnamelist: [],
            // isAddState:false,
            // pagefeatures:[],
            // errors:{
            //     // testcycle:{
            //         // cyclename: null,
            //     // }
            // },
            // tokendata:null,
            fieldsnamevalues:{},

            configurationForm: false,

            queanslist: [],
            issubtask: false,
            taskTypedesignCreative: apiUrl.taskTypedesignCreative,
            TaskTypeRestrictforClose:apiUrl.TaskTypeRestrictforClose
      } 
    },    
    created() {
      this.pagefeatures = {}
      let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
            if (element.page === '/tasks/list') {
            this.pagefeatures[element.featurename] = element.featureaccess
            }
        })
    },
    mounted(){
      window.addEventListener('storage', function(event){
          if (event.key == 'logout-mee') { 
              window.location.reload();
          }
      });
      let userdata = window.localStorage.getItem('userdata');
      if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.loginuscondition = userdata.userid
            this.loginusername=userdata.username
            this.loginpersonname=userdata.firstname+' '+userdata.lastname;
            this.tasklevelopts  = apiUrl.tasklevel
            this.taskmodule = window.localStorage.getItem("taskmodule")
            this.getTaskByID(this.$route.query.taskid)
            this.gettaskHistory(this.$route.query.taskid)
            this.getSubscribers(this.$route.query.taskid, 'task')
            this.getApplicationdata('t4','taskdefine-tab')
            this.getApplicationdata('t24','taskdefine-tab')
            this.getApplicationdata('t25','taskdefine-tab')
            this.activityHoursList();
            this.getSubTaskList(this.$route.query.taskid);
            this.getProjectList()
            this.getBlockerListByBusinessTask(this.$route.query.taskid)
            this.getsorting();
            this.getConfigurationFormDetails(this.$route.query.taskid);
      }
    },
    methods:{
        getParentTaskDetails(moduleid) {
            if(moduleid){
                let url = "api/master/taskmaster/getTaskCodeNameByID";
                this.input = {
                    taskid:parseInt(moduleid),
                    useremail: this.undt.username,
                    empid:this.undt.userid,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.parentTaskData = result.data.data;
                    } else {
                        this.parentTaskData = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        }, 
        idclick(indexx){
            if ($("#cycle_readmore"+indexx).hasClass("readmore")) {
                $("#"+indexx).html("Read More");
                $("#cycle_readmore"+indexx).removeClass("readmore");
                this.readmore = 'outer'
            } else {
                $("#"+indexx).html("Read Less");
                $("#cycle_readmore"+indexx).addClass("readmore");
                this.readmore = 'outer readmore'
            }
        },
        idclick5(){
            
            if ($("#cycle_readmore5").hasClass("readmore")) {
                $("#id5").html("Read More");
                $("#cycle_readmore5").removeClass("readmore");
                this.readmore = 'outer'
            } else {
                $("#id5").html("Read Less");
                $("#cycle_readmore5").addClass("readmore");
                this.readmore = 'outer readmore'
            }
        },
        activityHoursList() {
            for(let i=0; i<=23; i++) {
                if(i<=9) {
                let withZeroNo = '0'+i;
                this.ahourslist.push({ id: withZeroNo, label: withZeroNo});
                } else {
                this.ahourslist.push({ id: i.toString(), label: i.toString() });
                }
            }
            for(let m=1; m<=59; m++) {
                if(m<=9) {
                let withZeroNo = '0'+m;
                this.aminutelist.push({ id: withZeroNo, label: withZeroNo});
                } else {
                this.aminutelist.push({ id: m.toString(), label: m.toString() });
                }
            }
        },
        selahour(state, value) {
            this.taskdefine.ahour = null;
            this.taskdefine.ahour = state;
            if (!state && this.isAddState == false) {
                this.errors.taskdefine.ahour = "Estimated hour required";
            } else {
                this.errors.taskdefine.ahour = null;
            }
            },
        selamin(state, value) {
            this.taskdefine.amin = null;
            this.taskdefine.amin = state;
            if (!state && this.isAddState == false) {
                this.errors.taskdefine.amin = "Estimated minute required";
            } else {
                this.errors.taskdefine.amin = null;
            }
        },
        selActivityName(state) {
            if(!state) {
                if(this.taskdefine.type && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.status =='open' && !this.issubmicrotask){
                    this.errors.taskdefine.activity ='';
                }else{
                    this.errors.taskdefine.activity = 'Activity name is required';
                }
            } else {
                this.errors.taskdefine.activity = '';
            }
        },
        inputsearchtask(){
            if (this.searchTerm !== null)
            this.tasksearch = true
            this.getSubTaskList(this.$route.query.taskid)
        },
        openRPDetails(id){
            let routeData = this.$router.resolve({ 
            path: `/releasenote/view?releasenoteid=${id}`})
            window.open(routeData.href, '_blank');
        },
        openSubTaskList(){
            $('#card-navigation').show();
            this.isRequestActive=false;
            this.disBlockerActive = false;
            $('html, body').animate({ scrollTop: $(document).height() }, 1200);
        },
        selectTaskOrderBy(state){
            this.getSubTaskList(this.$route.query.taskid, state)
        },
        formatName(authorstring) {
            let author=authorstring.replace('@firstcry.com','')
            if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
            author = author.replace('.',' ')
            author = author.charAt(0).toUpperCase() + author.slice(1);
            return author
        },
        redirecttourl(btnstate,rowdata){
            if(rowdata){
            rowdata.backbtnstate=btnstate
            if(btnstate=="view"){
                    let routeData = this.$router.resolve({ 
                    path: `/tasks/view/?taskid=${rowdata.id}`,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                    })
                    window.open(routeData.href, '_blank');
                }
            }
        },
        getSubTaskList(id, state){
            this.isLoading = true;
            let url = "api/taskmaster/getSubTaskList";
            this.input = { id ,moduletype:this.taskmodule,empcode: this.undt.userid,useremail: this.undt.username,  orderBy: state};
            if(this.taskmodule == 1) {
                this.input.allottedto = parseInt(this.undt.userid)
                this.input.useremail = this.undt.username
            }
            this.input.limit = apiUrl.LIMIT
            this.input.offset = this.t_pageoffset

            this.input.searchTask
            this.input.searchStatus
            this.input.order = this.order
            this.input.labelname = this.labelname
            if(this.searchTerm) {
                let code = this.searchTerm.split('-')[0]
                if (code == 'TK' || code == 'tk' || code == 'BTC' || code == 'btc'){
                    this.input.searchCode = this.searchTerm.toUpperCase()
                } else {
                    this.input.searchCode = null
                    this.input.searchTask = this.searchTerm
                    this.input.searchTask = this.input.searchTask.toLowerCase()
                }
            }

            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasklistofbusiness = result.data.data.rows
                    this.t_totalcount = result.data.data.count;
                    this.t_pageCount = Math.ceil(this.t_totalcount / this.t_pagelimit)
                } else {
                    this.tasklistofbusiness = []
                    this.t_totalcount = 0
                    this.t_pageCount =0
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        clickCallback_tasklists: function (pageNum) {
            this.t_pageoffset = pageNum;
            this.t_currPageNum = pageNum - 1;

            this.getSubTaskList(this.$route.query.taskid)
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#card-navigation").offset().top -70
                });
            }, 500);
        },
        diffBetweenDates(d2, d1) {
            let date1 = moment(d1)
            let date2 = moment(d2)
            let years = date1.diff(date2, 'year');
            date2.add(years, 'years');

            let months = date1.diff(date2, 'months');
            date2.add(months, 'months');

            let days = date1.diff(date2, 'days');
            date2.add(days, 'days');

            let hours = date1.diff(date2, 'hours');
            date2.add(hours, 'hours');

            let minutes = date1.diff(date2, 'minutes');
            date2.add(minutes, 'minutes');
            let result = ''
            if(years) {
                if(years > 1) {
                    result += `${years} Years `
                } else {
                    result += `${months} Year `
                }
            }
            if(months) {
                if(months > 1) {
                    result += `${months} Months `
                } else {
                    result += `${months} Month `
                }
            }
            if(days) {
                if(days > 1) {
                    result += `${days} Days `
                } else {
                    result += `${days} Day `
                }
            }
            if(hours) {
                if(hours > 1) {
                    result += `${hours} Hours `
                } else {
                    result += `${hours} Hour `
                }
            }

            if(minutes) {
                if(minutes > 1) {
                    result += `${minutes} Minutes`
                } else {
                    result += `${minutes} Minute`
                }
            }
            return result
        },
        onchangedate(){
          if(this.taskdefine.taskenddate == null){
              this.taskdefine.ed_reason = null;
          }
        },
        getmodulename(id){
          let arr = apiUrl.tasklevel;
          let obj = arr.find(o => o.id === id); 
          return obj.text;
        },
        forceRerender() {
            this.componentKey += 1;
        },
        getTaskByID(moduleid){
            this.islistLoading=true
            let url = "api/master/taskmaster/getTaskByID";
            this.input = {
                taskid:moduleid,
                useremail: this.undt.username,
                empid:this.undt.userid,
                empcode: this.undt.userid,
                moduletype: 'taskupdate'
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                this.islistLoading=false
                if (result.data.errorCode == 0) {
                    this.editRowData = result.data.data;
                    this.editRowData=this.editRowData[0];
                    this.issubmicrotask = (this.editRowData.parenttaskid == null) ? false : true;
                    this.openEditModule(this.editRowData,4);
                    if(this.issubmicrotask) {
                        this.getParentTaskDetails(this.editRowData.parenttaskid)
                    }
                    this.getBlockerListByBusinessTask(moduleid);
                } else {
                    this.editRowData = [];
                    this.$router.push({ name: 'TaskList', params: { search: true }})
                    Swal.fire({
                        title: "Failed!",
                        text: "Your are not authorised user to view this tasks",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            }).catch(e => {
            this.displayError(e)
          });
        },
        getSubscribers(moduleid, moduletype){
            this.isLoading = true;
            let url = "api/subscribers/getSubscribersByModuleid";
            this.input = { moduleid, moduletype,useremail: this.undt.username,empcode: this.undt.userid };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(result.data.data && result.data.data.rows){
                        let decryptdepartmenttext = result.data.data.rows.map(items =>{
                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                        if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                        })
                    }
                    this.taskdefine.subscriberslist = result.data.data.rows;
                    this.taskdefine.subscribers = result.data.data.rows.map(d => d.id)
                } else {
                    this.taskdefine.subscribers = null;
                    this.taskdefine.subscriberslist = [];
                }
            }).catch(e => {
            this.displayError(e)
          });
        },
        myrequestollapse() {
          this.disRequestActive=!this.disRequestActive
        },
        handleCancel() {
          $('html, body').animate({ scrollTop: 0 }, 1200);
          this.myrequestollapse()
        },
        handleLogCancel() {
            $('html, body').animate({ scrollTop: 0 }, 1200);
            this.dislogActive=true
            this.isLogActive=false
            this.logtime.comment = ''
        },
        dateFormatterSales: function(dt) {
            let dateoptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric"
            };
            return dt.toLocaleDateString("en-GB", dateoptions);
        },  
        addModule(objThis){
            this.clearerrorForm();
            this.attachment = null;
            this.attachmentview = null;
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            $('.devUniqueForm').find('input[type=text]').val('')
            $(objThis.currentTarget.dataset.target).find('#submitbtn'+inputEle).attr('data-isedit',false)
            $('#glbsearchinpele').val('')
            
             if(inputEle == 't18'){
                this.taskdefine.taskdefinename=''
                this.taskdefine.type=null
                this.taskdefine.name=null
                this.taskdefine.level=null
                this.taskdefine.projectid=null
                this.taskdefine.desc=null
                this.taskdefine.module=null
                this.taskdefine.application=null
                this.taskdefine.appmodule=null
                this.taskdefine.devsection=null
                this.taskdefine.feature=null
                this.taskdefine.taskstartdate=''
                this.taskdefine.taskenddate=''
                this.taskdefine.taskassigneddate=''
            }
            this.isAddState=true
        },
        getTasktypeConfigLists(projectid) {
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: parseInt(projectid.toString()),
                trackername: 'Task Type',
            };
            axios({
                method: "POST",
                url: '/api/businesstask/getTasktypeConfigList',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    let slist = [];
                    result.data.data.rows.forEach((rec) => {
                        if(Object.values(rec.statusconfig).length>0){
                            Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                                if(statusnames.length>0) {
                                    statusnames.forEach(val => {
                                        if(!slist.some(sdata => sdata.id === val)){
                                            slist.push({ id: val, label: val });
                                        }
                                    });
                                }
                            });
                        }
                    })
                    this.sortTypeList(slist, 'alltype')
                } else {
                    this.tasktypelist = [];
                }
            }).catch(e => {
            this.displayError(e)
          });
        },
        sortTypeList(typelist, type){
        let numArrFromTypelist = [];
        typelist.forEach(element => {
            if(element.id != null) {
                numArrFromTypelist.push(element.id);
            }
        });
        if(numArrFromTypelist.length>0) {
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                moduletype: 'task',
                typearr: numArrFromTypelist,
                projecttype: type
            };
            axios({
                method: "POST",
                url: 'api/ticket/sortTypeList',
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktypelist = result.data.data.rows;
                    this.tasktypelist.sort((a, b) => {
                        return a.label.localeCompare(b.label);
                    });
                } else {
                    this.tasktypelist = [];
                }
            }).catch(e => {
            this.displayError(e)
          });
        }
        },
        saveData(mdl,objThis){
          let isValid = true;
           
          isValid = this.validateForm(mdl,objThis);
            if(objThis.logtimeFlag && objThis.logtimeFlag==1){
                isValid =true;
            }
          if (isValid) {
              this.isLoading = true;
              this.clearerrorForm();
              this.input = {
                  createdby: this.undt.username
              }
              let apiURL='api/master/taskmaster/saveTaskMaster'
              this.input.businesstype=this.taskdefine.businesstype
              this.input.taskdefinename=this.taskdefine.taskdefinename
              this.input.type=this.taskdefine.type
              this.input.nameid=1
              this.input.level=this.taskdefine.level
              this.input.projectid=this.taskdefine.projectid
              this.input.desc = this.taskdefine.desc
              this.input.module=this.taskdefine.module
              if(this.taskdefine.application){
                this.input.application=this.taskdefine.application
              }else{
                this.input.application=null
              }
              this.input.appmodule=this.taskdefine.appmodule
              this.input.devsection=this.taskdefine.devsection
              this.input.feature=this.taskdefine.feature
              this.input.taskstartdate=this.taskdefine.taskstartdate
              this.input.taskenddate=this.taskdefine.taskenddate
              this.input.taskassigneddate=this.taskdefine.taskassigneddate ? this.taskdefine.taskassigneddate :null
              this.input.allottedto = this.taskdefine.allottedto
              this.input.status = this.taskdefine.status
              this.input.estimateshours = this.taskdefine.estimateshours
              this.input.attachment=this.taskdefine.attachmentnew
              this.input.subscribers = this.taskdefine.subscribers
              this.input.empid = this.undt.userid
              if(this.taskdefine.isedit){
                // this.taskdefine.isedit=false
                apiURL='api/master/taskmaster/updateTaskMaster'
                this.input.id=this.editRowData.id
                this.input.business_code = this.editRowData.businesstaskcode
                this.input.module_code = this.editRowData.taskcode
                this.input.ed_reason = this.taskdefine.ed_reason;
                if(this.editRowData.taskenddate && this.editRowData.taskenddate!=null){
                    this.input.prev_ed = this.dateYMDHMSFormat(this.editRowData.taskenddate)
                }
                if(this.editRowData.taskenddate!=this.taskdefine.taskenddate){
                    this.input.ed_reason = this.taskdefine.ed_reason
                    this.input.ed_counter = this.editRowData.ed_counter + 1
                }
                if(this.taskdefine.attachmentnew && this.taskdefine.attachment !== null) {
                    this.input.attachmentnew = this.taskdefine.attachmentnew
                    let jsonobj1 = JSON.parse(this.taskdefine.attachmentnew);
                    let jsonobj2 = this.taskdefine.attachment;
                    let arraymerge = [...jsonobj1.data,...jsonobj2.data]
                    this.input.attachment= JSON.stringify({data: arraymerge});
                    this.view.documentlist = arraymerge
                }else if(this.taskdefine.attachment && !this.taskdefine.attachmentnew){
                    this.input.attachment = JSON.stringify(this.taskdefine.attachment)
                }
                this.input.lastmodifiedby=this.undt.username
                this.input.projectname = this.editRowData.projectname
                this.input.isTimeLogged = this.logtime.isTimeLogged
                this.input.moduletype = this.editRowData.moduletype
                delete this.input.createdby;
                if (this.taskdefine.status == 'resolved') {
                    this.input.statusresolveddate = this.editRowData.resolveddate;
                }else if (this.taskdefine.status == 'closed') {
                    this.input.statuscloseddate = this.editRowData.closeddate;
                }

                this.input.previousstatus = this.editRowData.status ? this.editRowData.status :null;
                this.input.technology= this.taskdefine.technology ? this.taskdefine.technology : null;
                this.input.activity= this.taskdefine.activity ? this.taskdefine.activity : null;
                this.input.workstepid = this.editRowData.workstepid ? this.editRowData.workstepid :null;

                if(this.taskdefine.activity && this.taskdefine.activity !=null && this.taskdefine.activityhours) {
                    let elemHours = this.activityhoursopts.filter(activity => activity.id === this.taskdefine.activity)
                    let elemWithoutHours = this.activityhoursopts.filter(activity => activity.id !== this.taskdefine.activity)
                    if(elemHours && elemHours.length) {
                    this.input[elemHours[0].id] = this.taskdefine.activityhours
                    }
                    if(elemWithoutHours.length) {
                    elemWithoutHours.forEach((activity) => this.input[activity.id] = null)
                    }
                }
              }
              if(this.taskdefine.isaddsubtask && !this.logtime.isTimeLogged){
                this.input.isaddsubtask = true;
                apiURL='api/master/taskmaster/saveTaskMaster';
                this.input.parenttaskid = this.editRowData.id;
                this.input.technology=this.taskdefine.technology
                let elemHours = this.activityhoursopts.filter(activity => activity.id === this.taskdefine.activity)
                let elemWithoutHours = this.activityhoursopts.filter(activity => activity.id !== this.taskdefine.activity)
                if(elemHours.length) {
                    this.input[elemHours[0].id] = this.taskdefine.activityhours
                }
                if(elemWithoutHours.length) {
                    elemWithoutHours.forEach((activity) => this.input[activity.id] = null)
                }
                this.input.businesstaskcode =  this.editRowData.businesstaskcode
              }
              this.input.useremail= this.undt.username,
              this.input.empcode= this.undt.userid,
              this.isAddState=false
              axios({
              'method': 'POST',
              'url': apiURL,
              'data': this.input,
              'headers':{'authorization':this.tokendata}
              })
              .then(result => {  
                  this.isLoading = false; 
                  this.file =[]
                  this.taskdefine.filedescp =[]      
                  this.taskdefine.attachmentnew = null    
                  if(result.data.errorCode == 0){
                      Swal.fire({
                          title: "Success!",
                          text: result.data.msg,
                          icon: 'success',
                          customClass: {
                              confirmButton: "btn btn-primary"
                          },
                          buttonsStyling: !1
                      })
                      this.logtime.isTimeLogged = false
                      this.getTaskByID(this.editRowData.moduleid)
                      this.getConfigurationFormDetails(this.$route.query.taskid);
                      this.isRequestActive = false
                      this.disRequestActive=true;
                      $('html, body').animate({ scrollTop: 0 }, 1200);
                      if(this.taskdefine.isaddsubtask && !this.logtime.isTimeLogged) {
                        this.getSubTaskList(this.$route.query.taskid)
                        this.openSubTaskList(this.$route.query.taskid)
                      }
                  }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                      Swal.fire({
                          title: "",
                          text: result.data.msg,
                          icon: "info",
                          customClass: {
                          confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: !1,
                      })
                  }
              }).catch(e => {
                this.displayError(e)
            })
          }
        },
        validateDate(mdl, objThis) {
            var startDate = this.taskdefine.taskstartdate
            var endDate = this.taskdefine.taskenddate
            if(mdl === 'taskenddate') {
                if (endDate && (Date.parse(endDate) < Date.parse(startDate))) {
                    this.errors.taskdefine.taskenddate = "End date should be greater than Start date"
                    this.taskdefine.taskenddate = ''
                } else {
                    this.errors.taskdefine.taskenddate = ''
                }
            } 
            else if(mdl === 'taskstartdate') {
                if (endDate && (Date.parse(endDate) < Date.parse(startDate))) {
                    this.errors.taskdefine.taskstartdate = "Start date should be lesser than End date"
                    this.taskdefine.taskstartdate = ''
                } else {
                    this.errors.taskdefine.taskstartdate = ''
                }
            }
        },
        validateForm(mdl,objThis){
          this.clearerrorForm()
          this.isAddState=false
          var isValid=true;
          this.nameBlured = true;
          this.descBlured = true;
          this.commentBlured = true;
          if(mdl == 't18'){
              if(!this.taskdefine.taskdefinename){
                  this.errors.taskdefine.taskdefinename="Task name required";
                  isValid= false;
              }
              if(!this.taskdefine.type){
                  this.errors.taskdefine.type="Micro Task type required";
                  isValid= false;
              }
              if(!this.taskdefine.level){
                if(this.taskdefine.type && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.status =='open'){
                    this.errors.taskdefine.level = "";
                }else{
                    this.errors.taskdefine.level = "Micro Task level required";
                    isValid= false;
                }
              }
              if(!this.taskdefine.projectid){
                  this.errors.taskdefine.projectid="Project required";
                  isValid= false;
              }
            if (!this.taskdefine.desc) {
            this.errors.taskdefine.desc = "Micro Task Description required";
            isValid = false;
            }
              if(!this.taskdefine.status) {
                this.errors.taskdefine.status = "Task status is required";
                isValid = false;
              }
              if(!this.taskdefine.estimateshours) {
                if(this.taskdefine.type && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.status =='open'){
                    this.errors.taskdefine.estimateshours = "";
                }else{
                    this.errors.taskdefine.estimateshours = 'Micro Task estimates is required';
                    isValid = false;
                }
              }
              if(!this.taskdefine.allottedto) {
                this.errors.taskdefine.allottedto = "Assigned to required";
                isValid = false;
              }
              if(!this.taskdefine.taskstartdate){
                  this.errors.taskdefine.taskstartdate="Start date required";
                  isValid= false;
              }
              if(!this.taskdefine.taskenddate){
                if(this.taskdefine.type && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.status =='open'){
                    this.errors.taskdefine.taskenddate = '';
                }else{
                    this.errors.taskdefine.taskenddate = "End date required";
                    isValid= false;
                }
              }
              if (this.editRowData.taskenddate !=null && this.taskdefine.taskenddate && this.taskdefine.taskenddate != this.dateYMDHMSFormat(this.editRowData.taskenddate) && this.taskdefine.isaddsubtask != true) {
                if(!this.taskdefine.ed_reason || this.taskdefine.ed_reason == null){
                    this.errors.taskdefine.ed_reason = "EndDate Update Reason is required";
                    isValid = false;
                }else{
                    this.errors.taskdefine.ed_reason = null;
                }
              }
              if(!this.taskdefine.taskassigneddate){
                if(this.taskTypedesignCreative.includes(this.taskdefine.type)){
                    this.errors.taskdefine.taskassigneddate ='';
                }else{
                  this.errors.taskdefine.taskassigneddate="Assign date required";
                  isValid= false;
                }
              }

            if((!this.taskdefine.subscribers) || (this.taskdefine.subscribers && this.taskdefine.subscribers.length < 1)) {
                this.errors.taskdefine.subscribers = 'Subscribers are required'
                isValid = false
            } else {
                this.errors.taskdefine.subscribers = ''
            }
            if(!this.taskdefine.businesstype) {
                this.errors.taskdefine.businesstype = "Business task name is required"
                isValid = false
            }else{
                this.errors.taskdefine.businesstype = ''
            }

            if (this.taskdefine.isaddsubtask) {
                this.taskdefine.activityhours = '00:00';
                if(this.taskdefine.ahour != null && this.taskdefine.ahour != '') {
                    this.taskdefine.activityhours = this.taskdefine.ahour+':'+this.taskdefine.amin;
                }
                let hours = this.taskdefine.activityhours
                let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)
                if(typeof hours === 'string' && hours.length === 5) isValidHours = true
                if(isValidHours && hours !== null && typeof hours === 'object') {
                    this.taskdefine.activityhours = `${hours.HH}:${hours.mm}`
                } else {
                    this.errors.taskdefine.activityhours = ''
                }
                if(this.taskdefine.ahour == null || this.taskdefine.ahour == '') {
                    if(this.taskdefine.type && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.status =='open'){
                        this.errors.taskdefine.ahour = "";
                    }else{
                        this.errors.taskdefine.ahour = 'Estimated hours are required'
                        isValid = false;
                    }
                } else {
                    this.errors.taskdefine.ahour = ''
                }
                if(this.taskdefine.amin == null || this.taskdefine.amin == '') {
                    if(this.taskdefine.type && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.status =='open'){
                        this.errors.taskdefine.ahour = "";
                    }else{
                        this.errors.taskdefine.amin = 'Estimated minutes are required';
                        isValid = false;
                    }
                } else {
                    this.errors.taskdefine.amin = ''
                }
                if(!this.taskdefine.activity) {
                    if(this.taskdefine.type && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.status =='open'){
                        this.errors.taskdefine.activity = "";
                    }else{
                        this.errors.taskdefine.activity = 'Activity name is required'
                        isValid = false
                    }
                } else {
                    this.errors.taskdefine.activity = ''
                }
                if(!this.taskdefine.technology){
                    if(this.taskdefine.type && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.status =='open'){
                        this.errors.taskdefine.technology = "";
                    }else{
                        this.errors.taskdefine.technology = "Technology is required";
                        isValid= false;
                    }
                } else {
                    this.errors.taskdefine.technology = null;
                }
            }

            if(!this.taskdefine.isaddsubtask){
                if(!this.taskdefine.activity) {
                    if(this.taskdefine.type && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.status =='open'){
                        this.errors.taskdefine.activity = '';
                    }else{
                        this.errors.taskdefine.activity = 'Activity name is required';
                        isValid = false;
                    }
                } else {
                    this.errors.taskdefine.activity = '';
                }
                if(!this.taskdefine.technology){
                    if(this.taskdefine.type && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.status =='open'){
                        this.errors.taskdefine.technology ='';
                    }else{
                        this.errors.taskdefine.technology = "Technology is required";
                        isValid= false;
                    }
                } else {
                    this.errors.taskdefine.technology = '';
                }
                if(this.taskdefine.estimateshours && (this.taskdefine.ahour == null || this.taskdefine.ahour == '' || this.taskdefine.ahour == ' ' || this.taskdefine.ahour == undefined)) {
                    if(this.taskdefine.type && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.status =='open'){
                        this.errors.taskdefine.ahour ='';
                    }else{
                        this.errors.taskdefine.ahour = 'Estimated hours are required'
                        isValid = false;
                    }
                } else {
                    this.errors.taskdefine.ahour = '';
                }
                if(this.taskdefine.estimateshours && (this.taskdefine.amin == null || this.taskdefine.amin == '' || this.taskdefine.amin == ' ' || this.taskdefine.amin == undefined)) {
                    if(this.taskdefine.type && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.status =='open'){
                        this.errors.taskdefine.amin ='';
                    }else{
                        this.errors.taskdefine.amin = 'Estimated minutes are required'
                        isValid = false;
                    }
                } else {
                    this.errors.taskdefine.amin = '';
                }
                if((this.taskdefine.ahour != null || this.taskdefine.ahour != '' || this.taskdefine.ahour != ' ' || this.taskdefine.ahour != undefined) && (this.taskdefine.amin != null || this.taskdefine.amin != '' || this.taskdefine.amin != ' ' || this.taskdefine.amin != undefined)){
                    this.taskdefine.activityhours = '00:00';
                    if(this.taskdefine.ahour != null && this.taskdefine.ahour != '') {
                        this.taskdefine.activityhours = this.taskdefine.ahour+':'+this.taskdefine.amin;
                    }
                    let hours = this.taskdefine.activityhours
                    let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)
                    if(typeof hours === 'string' && hours.length === 5) isValidHours = true
                    if(isValidHours && hours !== null && typeof hours === 'object') {
                        this.taskdefine.activityhours = `${hours.HH}:${hours.mm}`
                    }
                }
            }
        } 
          return isValid
        },
        onFileChange(e) {
        var validFileExtensions = apiUrl.validFileExtensions
        let selectedFiles = e.target.files;
        for (let i=0; i < selectedFiles.length; i++)
        {
            var blnValid = false;
            for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
                var sCurExtension = validFileExtensions[j];
                if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                    blnValid = true;
                    break;
                }
            }
            
            if (!blnValid) {
                Swal.fire({
                    title: "ERROR",
                    text: "Sorry, Invalid Type of Extension File..!!",
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                return false;
            }
            this.file.push(selectedFiles[i]);
        }
        if(this.file.length>0) {
            this.taskdefine.disblesavebtn=true
        }
         document.getElementById('attachment').value = ""
        },
        removeImage (index) {
            if(this.file.length>0) {
                this.file.splice(index, 1);
                this.taskdefine.filedescp.splice(index, 1);
                var removeimg = JSON.parse(this.taskdefine.attachmentnew)
                if(removeimg){
                var remove = removeimg.data.splice(index,1)
                if(remove.length > 0)
                this.taskdefine.attachmentnew = JSON.stringify({data:removeimg.data})
                else{
                  this.taskdefine.attachmentnew = null
                }
            }
            } 
            if(this.file.length < 1) {
                this.taskdefine.disblesavebtn = false
            }           
        },
        removes3file(key,data) {
            if(data.length>0) {                
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to undo!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        if(this.editRowData.id !== undefined) {
                            let deletefile = data[key];
                            data.splice(key, 1);
                            let att = this.taskdefine.attachment
                            if(att && att.data.length) {
                                att.data.splice(key, 1)
                                this.taskdefine.attachment = att
                            }
                            const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                            let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                            axios({
                            method: "POST",
                            url: 'api/master/taskmaster/s3Deletetask',
                            data: {
                                id:this.editRowData.id,
                                attachment:payload,
                                deletefile: JSON.stringify(deletefile),
                                useremail: this.undt.username,
                                empcode: this.undt.userid, 
                            },
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            if (result.data.status) {
                                Swal.fire(
                                'Deleted!',
                                result.data.msg,
                                'success'
                                )
                            } else {
                            Swal.fire(
                                'Deleted!',
                                result.data.msg,
                                'error'
                                )                        
                            }
                        });
                        } else {
                            Swal.fire(
                                'Taskid!',
                                'Taskid should not blank',
                                'info'
                                )
                        }    
                    }
                })
            }
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        getEmpallocatedtoProjectedit(assignto) {
            if(assignto && assignto!=null){
                axios({
                method: "POST",
                url: "api/listing/getEmpallocatedtoProject",
                data: { useremail: this.undt.username,empcode: this.undt.userid, assignedtoid: assignto},
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.taskdefine.allottedtolist = result.data.data;
                    let decryptdepartmenttext = this.taskdefine.allottedtolist.map(items =>{

                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                        if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                    })
                } else {
                    this.taskdefine.allottedtolist = [];
                }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        getEmpallocatedtoProject(node) {
            if(this.taskdefine.projectid && (node && node.length>3) ){
                axios({
                    method: "POST",
                    url: "api/listing/getEmpallocatedtoProject",
                    data: { projectid: this.taskdefine.projectid,useremail: this.undt.username,empcode: this.undt.userid, assignedto: node.toLowerCase() },
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        if(result.data.data){
                            let decryptdepartmenttext = result.data.data.map(items =>{

                                let tempemplabel1 = items.label;
                                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                                if(tempemplabel2 && tempemplabel2 != null){
                                    items.label = tempemplabel1 +'('+tempemplabel2 +')';
                                }
                                return items;
                            })
                        }
                        this.taskdefine.allottedtolist = result.data.data;
                        this.taskdefine.subscriberslist = result.data.data
                    } else {
                        this.taskdefine.allottedtolist = [];
                        this.taskdefine.subscriberslist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        selSubscribers(state) {
            this.taskdefine.subscribers = null
            this.taskdefine.subscribers = state
            if(!state || (state && state.length < 1)) {
                this.errors.taskdefine.subscribers = 'Subscribers are required'
            } else {
                this.errors.taskdefine.subscribers = ''
            }
        },
        selallottedto(state, value) {
            if(!state) {
                this.errors.taskdefine.allottedto = 'Assigned To required'
            } else {
                this.errors.taskdefine.allottedto = ''
            }
        },
        clearerrorForm(){
          this.errors= {
            taskdefine:{
              type:null,
              name:null,
              level:null,
              projectid:null,
              module:'',
              application:null,
              appmodule:'',
              devsection:'',
              feature:'',
              taskstartdate:null,
              taskenddate:null,
              taskassignby:'',
              taskassigneddate:'',
              taskdefinename:'',
              businesstype:null,
              desc:null,
              ed_reason: null,
            },
          };
        },
        validText : function(inputval) {
            let re = /[A-Za-z0-9].{4,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validText1: function (inputval) {
        let re = /[A-Za-z0-9].{1,}/;
        if (inputval) {
            return re.test(inputval.toLowerCase());
        }
        },
        validRedmineID : function(inputval) {
            var re = /[A-Za-z0-9].{2,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        dateMONFormat(value){
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:mm');
        },
        dateFormat2(value) {
            if (!value) return "";
            return moment(value).format(this.TimeFormat);
        },
        dateYMDHMSFormat(value){
            if (!value) return "";
            return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        dateFormatnew(value) {
            if (!value) return "";
            return moment(String(value)).format('YYYY-MM-DD');
        },
        openEditModule(currRowData, flag){
            if(currRowData && currRowData.length==0){
                currRowData = this.editRowData
            }
            this.attachment = null
            this.attachmentview = null
            this.editRowData = currRowData
            this.logtime.isTimeLogged = false
            this.view.businessid=currRowData.businesstaskmasterid
            this.view.projectid=currRowData.projectid
            this.view.tasktypename = currRowData.tasktypename
            this.view.projectname = currRowData.projectname  
            this.view.applicationname = currRowData.applicationname
            this.view.projectmodulename = currRowData.projectmodulename
            this.view.applicationmodulename = currRowData.applicationmodulename
            this.view.sectionname = currRowData.sectionname
            this.view.functionlityname = currRowData.functionlityname
            this.view.createdby = currRowData.createdby
            this.view.createddate = currRowData.createddate
            this.view.approvedby = currRowData.approvedby
            this.view.taskcode = currRowData.taskcode
            this.view.lastmodifiedby = currRowData.lastmodifiedby
            this.view.lastmodifieddate = currRowData.lastmodifieddate
            this.taskdefine.estimateshours = currRowData.estimateshours
            this.view.btmname = currRowData.btmname
            this.view.documentlist = currRowData.attachment ? currRowData.attachment.data:[]
            this.view.tasklevelname = currRowData.title//this.tasklevelopts[currRowData.tasklevel - 1];
            this.view.empname = currRowData.empname
            this.view.estimateshours = currRowData.estimateshours
            this.view.gitlabreponame = currRowData.gitlabreponame
            this.view.jenkinsjobname = currRowData.jenkinsjobname
            this.view.releasenotemasterid = currRowData.releasenotemasterid
            this.issubtask = currRowData.parenttaskid == null ? false : true;
            this.isshow = currRowData.isshow;
            let filteredEstimatehr = this.estimateshours.filter(item => item.id === this.view.estimateshours)
            if(filteredEstimatehr.length){
                 this.view.estimateshours = filteredEstimatehr[0].label
            }
            this.taskdefine.attachment = currRowData.attachment
            this.view.qacycle = currRowData.qacycle
            if(currRowData.attachment && currRowData.attachment.hasOwnProperty('data')) {
            this.taskdefine.attachmentview =[];
            this.getDownloadLink(currRowData.attachment);
            }
            this.taskdefine.desc=currRowData.taskdescription
            this.taskdefine.isredirected = false
            let inputEle='';
            let btnEle='';
            if(this.glbMdl){
                let arrGlbMdl=this.glbMdl.split('##')
                if(arrGlbMdl.length > 0){
                  if(typeof arrGlbMdl[0] != 'undefined'){
                      inputEle=arrGlbMdl[0];
                  }
                  if(typeof arrGlbMdl[1] != 'undefined'){
                      btnEle=arrGlbMdl[1];
                  }
              }
            }
          if(inputEle){
              $('#modulename'+inputEle).val(currRowData.modulename)
          }
          if(btnEle){
              $('#'+btnEle).find('#submitbtn'+inputEle).addClass(btnEle)
              $('#'+btnEle).find('#submitbtn'+inputEle).attr('data-isedit',true)
          }
          $('#glbsearchinpele').val('')  
          this.taskdefine.businesstype=currRowData.businesstaskmasterid
          this.taskdefine.taskdefinename=currRowData.taskname
          this.view.taskdefinename = currRowData.taskname
          this.taskdefine.type=currRowData.tasktypemasterid
          this.taskdefine.name=currRowData.tasknamemasterid
          this.taskdefine.level=currRowData.tasklevel
          this.taskdefine.projectid=currRowData.projectid
          this.taskdefine.oldprojectid=currRowData.projectid
          this.taskdefine.desc=currRowData.taskdescription
          this.taskdefine.module=currRowData.projectmodule
          this.taskdefine.application=currRowData.application
          this.taskdefine.appmodule=currRowData.applicationmodule
          this.taskdefine.devsection=currRowData.developmentsectionorpart
          this.taskdefine.feature=currRowData.functionalityorfeature
          this.taskdefine.status = currRowData.status
          this.view.status = currRowData.status
          this.taskdefine.allottedto = currRowData.allottedto
        
            this.taskdefine.taskstartdate=this.dateYMDHMSFormat(currRowData.taskstartdate);
            this.taskdefine.taskenddate=this.dateYMDHMSFormat(currRowData.taskenddate);
            this.taskdefine.taskassigneddate= currRowData.taskassigndatetime ? this.dateYMDHMSFormat(currRowData.taskassigndatetime) :null;
            this.taskdefine.technology = currRowData.technology ? currRowData.technology: null; 
            if(currRowData.developmenthours){
                this.taskdefine.activity = 'developmenthours';
                this.taskdefine.ahour = currRowData.developmenthours.split(":")[0];
                this.taskdefine.amin = currRowData.developmenthours.split(":")[1];
            }else if(currRowData.codereviewhours){
                this.taskdefine.activity = 'codereviewhours';
                this.taskdefine.ahour = currRowData.codereviewhours.split(":")[0];
                this.taskdefine.amin = currRowData.codereviewhours.split(":")[1];
            }else if(currRowData.infrasetupanddeploymenthours){
                this.taskdefine.activity = 'infrasetupanddeploymenthours';
                this.taskdefine.ahour = currRowData.infrasetupanddeploymenthours.split(":")[0];
                this.taskdefine.amin = currRowData.infrasetupanddeploymenthours.split(":")[1];
            }else if(currRowData.meetingfeaturesdiscussionhrs){
                this.taskdefine.activity = 'meetingfeaturesdiscussionhrs';
                this.taskdefine.ahour = currRowData.meetingfeaturesdiscussionhrs.split(":")[0];
                this.taskdefine.amin = currRowData.meetingfeaturesdiscussionhrs.split(":")[1];
            }else if(currRowData.qahours){
                this.taskdefine.activity = 'qahours';
                this.taskdefine.ahour = currRowData.qahours.split(":")[0];
                this.taskdefine.amin = currRowData.qahours.split(":")[1];
            }else if(currRowData.supporthrs){
                this.taskdefine.activity = 'supporthrs';
                this.taskdefine.ahour = currRowData.supporthrs.split(":")[0];
                this.taskdefine.amin = currRowData.supporthrs.split(":")[1];
            }else{
                this.taskdefine.activity = null;
            }

          this.view.taskstartdate=currRowData.taskstartdate
          this.view.taskenddate=currRowData.taskenddate
          this.view.isapproved_ed = currRowData.isapproved_ed
          this.view.updated_ed = currRowData.updated_ed
          this.view.taskassigneddate=currRowData.taskassigndatetime
          this.view.resolveddate=currRowData.resolveddate
          this.view.closeddate=currRowData.closeddate
          if(this.taskdefine.projectid && this.taskdefine.projectid !=null){
            this.getProjectDataBYProjectid(this.taskdefine.projectid)
          }
          this.getfiltereddata('tdprojmodule')
          this.getfiltereddata('btprojassign')
          this.getfiltereddata('tdapp')
          this.getfiltereddata('tdappmodule')
          this.getfiltereddata('tdsection')
          this.getfiltereddata('tdappmdl')
        //   this.getEmpallocatedtoProject(currRowData.projectid);
        this.getEmpallocatedtoProjectedit(currRowData.allottedto);
          this.getissueLogTimeHistory(currRowData.id)
          this.getStatusConfigList(currRowData.projectid,this.taskdefine.status)
          this.getTasktypeConfigLists(currRowData.projectid)
           if(this.taskdefine.status =='resolved' || this.taskdefine.status =='closed'){
               this.status = this.status.filter(item => item.id !== 'open' )
               this.status = this.status.filter(item => item.id !== 'review' )
               this.status = this.status.filter(item => item.id !== 'approved' )
           }
          if(flag==0){
              this.dislogActive=true
              this.isLogActive=false
              this.disBlockerActive = false
              this.taskdefine.isedit=true
              this.isRequestActive=true
              this.taskdefine.isaddsubtask = false;
              this.disRequestActive=false
              this.shouldDisable = true
              this.disablefields(currRowData.tasklevel)
              this.formActionLabel = `Edit Task: ${currRowData.taskname}`
              $('html, body').animate({ scrollTop: $(document).height() }, 1200);
          }
          if(flag==9){ 
            this.dislogActive=true
            this.isLogActive=false
            this.taskdefine.isaddsubtask = true;
            this.disBlockerActive = false
            this.taskdefine.isedit=false;
            this.isRequestActive=true
            this.disRequestActive=false
            this.formActionLabel = `Add Sub Micro Task: ${currRowData.taskname}`
            $('html, body').animate({ scrollTop: $(document).height() }, 1200);
            $('#card-navigation').hide();
            this.taskdefine.taskstartdate = this.dateYMDHMSFormat(new Date())
            this.taskdefine.taskassigneddate = this.dateYMDHMSFormat(new Date());
            this.taskdefine.taskenddate = null;
            this.taskdefine.allottedto = null;
            this.taskdefine.estimateshours = null;
            this.taskdefine.ed_reason = null;
            this.editRowData.taskenddate = null;
            this.taskdefine.taskdefinename = null;
            this.taskdefine.status = 'open';
            this.taskdefine.application = null;
            this.taskdefine.module = null;
            this.taskdefine.appmodule = null;
            this.taskdefine.devsection = null;
            this.taskdefine.feature = null;
            this.taskdefine.ahour = null;
            this.taskdefine.amin = null;
            this.disablefields(this.taskdefine.level);
          }
          this.isAddState=true
          
        },
            gettaskHistory(taskid){
      this.isLoading = true;
      let url = "api/master/taskmaster/getTasklogByID";
      this.input = {
         taskid :taskid,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.task.history = result.data.data;
        } else {
          this.task.history=[]
        }
      }).catch(e => {
        this.displayError(e)
    });
    },

        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        projectChange: function (node, instanceId){
            if(node && node.length>3){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    empid: parseInt(this.undt.userid),
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    isfrompage: 'updatepage'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.taskdefine.projectlist = result.data.data;                       
                    } else {
                        this.taskdefine.projectlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
          myrequestcollapse() {
            this.dislogActive=true
            this.isLogActive=false
            this.logtime.spenttime = null
            this.logtime.activity = null
            this.logtime.status = null
            this.logtime.comment = null

            
        },
    redirecttorelasenote(){
        let rdata = this.editRowData;
        let routeData = this.$router.resolve({ 
            path: `/releasenote/view?releasenoteid=${rdata.releasenotemasterid}`,
            params: {
              clickeddata:rdata
            }
          })
        window.open(routeData.href, '_blank');
    },
    openLogModule(){
      this.isRequestActive=false
      this.disRequestActive=true
      this.disBlockerActive = false
      this.logtime.spenttime=null
      this.logtime.spenttime = { "HH": "", "mm": "" }
      this.logtime.activity = null
      this.logtime.comment= ''
      this.logtime.allotedto=this.editRowData.allottedto
      this.logtime.status = this.editRowData.status
      this.getStatusConfigList(this.taskdefine.projectid,this.logtime.status);
      if(this.taskdefine.status =='resolved' || this.taskdefine.status =='closed'){
               this.status = this.status.filter(item => item.id !== 'open' )
               this.status = this.status.filter(item => item.id !== 'review' )
               this.status = this.status.filter(item => item.id !== 'approved' )
           }
      this.dislogActive=false
      this.isLogActive=true
      $('#card-navigation').hide();
      
      $('html, body').animate({ scrollTop: $(document).height() }, 800);
    },
    savelogData(){
        let valid = this.validatelog();
        if(this.taskdefine.taskstartdate == null || this.taskdefine.taskstartdate == '' || this.taskdefine.taskenddate == null || this.taskdefine.taskenddate == ''){
            if(this.taskdefine.taskstartdate && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.logtime.status =='open'){
                valid = true;
            }else{
                Swal.fire({
                    title: "Failed!",
                    text: 'Micro task start and end date is required..!',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                });
                valid = false;
            }
        }
      if(valid){
        this.logtime.isTimeLogged = true
        this.input={
          moduletype:'task',
          modulename:this.editRowData.taskname,
          moduleid:this.editRowData.moduleid,
          projectname:this.editRowData.projectname,
          projectid:this.editRowData.projectid,
          activity:this.logtime.activity,
          assignedto:this.logtime.allotedto,
          status: this.logtime.status,
          module_code : this.editRowData.taskcode,
          business_code: this.editRowData.businesstaskcode,
          coments:this.logtime.comment,
          createdby:this.undt.username,
          attachment:JSON.stringify(this.taskdefine.attachmentnew),
          subscribers: this.taskdefine.subscribers,
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          tasktype: parseInt(this.taskdefine.type)
        }
        if(typeof(this.logtime.spenttime)=='string' && this.logtime.spenttime) {
            this.input.spenttime = this.logtime.spenttime
        }else if(typeof(this.logtime.spenttime)!='string' && this.logtime.spenttime.HH !='' &&  this.logtime.spenttime.mm!='') {
            this.input.spenttime = this.logtime.spenttime
        }
        if(this.taskdefine.attachmentnew) {
          let jsonobj1 = JSON.parse(this.taskdefine.attachmentnew);
          let arraymerge = [...jsonobj1.data]
          this.input.attachment= JSON.stringify({data: arraymerge});
        }

        
        if (this.configurationForm == true ) {
            if (this.queanslist.length == 0) {
            this.input.trackerid = this.moduletrackerid
            this.input.fieldvalues = this.fieldsnamevalues
            this.input.id = this.undt.userid
            this.input.logstatus = this.logtime.status
          }
        }

        this.isLoading=true
        axios({
          method: "POST",
          url: 'api/issue/saveLogTime',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.taskdefine.isedit =true
            this.taskdefine.allottedto = this.logtime.allotedto
            this.taskdefine.status = this.logtime.status
            this.taskdefine.logtimeFlag=1
            this.saveData('t18',this.taskdefine)
            this.dislogActive=true
            this.isLogActive=false
          } else {
            Swal.fire({
              title: "Failed!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          }
        }).catch(e => {
            this.displayError(e)
        });
      }
      

    },
    openBlockerTaskModule() {
        this.disBlockerActive= true
        $('#card-navigation').hide();
        $('html, body').animate({ scrollTop: $(document).height() }, 800);
        this.dislogActive=true
        this.isLogActive=false
        this.taskdefine.isedit=false
        this.isRequestActive=false
        this.taskdefine.isaddsubtask = false;
        this.disRequestActive=true
        this.shouldDisable = false
    },
    validatelog: function(){
      var isValid=true;

        if (this.logtime.status == 'closed') {
          if (this.configurationForm == true ) {
            for (let i=0; i<this.fieldnamelist.length; i++) {
                if (this.fieldnamelist[i].validationtype=='required') {
                if (this.fieldsnamevalues[this.fieldnamelist[i].id] == '' || this.fieldsnamevalues[this.fieldnamelist[i].id] == null || this.fieldsnamevalues[this.fieldnamelist[i].id] == []) {
                        isValid=false;
                        if (this.fieldnamelist[i].fieldtype=='datetime-local' && this.fieldsnamevalues[this.fieldnamelist[i].id] == '') {
                            Swal.fire({
                                title: "Failed",
                                text: this.fieldnamelist[i].label+' '+'is required',
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    }
                }
                 

                    if (this.fieldnamelist[i].fieldtype=='datetime-local') {

                        if (typeof this.fieldsnamevalues[this.fieldnamelist[i].id] == undefined || typeof (this.fieldsnamevalues[this.fieldnamelist[i].id]) == 'undefined') {
                            isValid=false;
                        }else{
                            let hours = this.fieldsnamevalues[this.fieldnamelist[i].id]
                            let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)
                                if (this.fieldsnamevalues[this.fieldnamelist[i].id].HH=='' || this.fieldsnamevalues[this.fieldnamelist[i].id].mm=='') {
                                    Swal.fire({
                                        title: "Failed",
                                        text: this.fieldnamelist[i].label+' '+'required in HH:mm format value',
                                        icon: 'info',
                                        customClass: {
                                            confirmButton: "btn btn-primary"
                                        },
                                        buttonsStyling: !1
                                    })
                                }
                            if(typeof hours === 'string' && hours.length === 5){
                                isValidHours = true
                            }
                            if(isValidHours && hours !== null && typeof hours === 'object') {
                                this.fieldsnamevalues[this.fieldnamelist[i].id] = `${hours.HH}:${hours.mm}`
                            }
                        }
                    }
                }
          }
        }

        let spenttime = this.logtime.spenttime && this.logtime.spenttime.HH && this.logtime.spenttime.mm
        if(!spenttime && typeof(this.logtime.spenttime)=='string')
        {
            let logstime = (this.logtime.spenttime).split(':')
            if(logstime){
                if(logstime[0] && logstime[1]){
                    spenttime = `${logstime[0]}:${logstime[1]}`
                     this.logtime.spenttime = `${logstime[0]}:${logstime[1]}`
                }
            }
        }
        if(spenttime && typeof(this.logtime.spenttime)!='string'){
             this.logtime.spenttime = `${this.logtime.spenttime.HH}:${this.logtime.spenttime.mm}`
        }

      if(this.logtime.activity && spenttime==''){
        this.errors.spenttime="Spent-time Hours & minutes is required";
        isValid= false;
      }else{
        this.errors.spenttime= '';
      }
      if(!this.logtime.activity && spenttime!=''){
        this.errors.activity="Activity is required";
        isValid= false;
      }else{
        this.errors.activity=null;
      }
      if(!this.logtime.status){
        this.errors.status="Micro Task status is required";
        isValid = false;
      }else{
        this.errors.status = null;
      }
      if(!this.logtime.allotedto){
        this.errors.allotedto="Alloted to is required";
        isValid= false;
      }else{
        this.errors.allotedto=null;
      }
      if(!this.logtime.comment){
        this.errors.comment="Comment is required";
        isValid= false;
      }else{
        this.errors.comment=null;
      }
      
      

      return isValid
    },

    notBeforeToday: function (date) {
        return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    validText2 : function(inputval) {
            var re = /[A-Za-z0-9].{1,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },

    seltasklogactivity(state, value) {
        this.logtime.activity = null
        this.logtime.activity = state
        if(!state) {
            
        } else {
            this.errors.activity = null
        }
    },
    seltasklogcomments(state, value) {
        this.logtime.comment = null
        this.logtime.comment = state
        if(!state) {
            this.errors.comment = "Comment is required"
        } else {
            this.errors.comment = null
        }
    },
    seltasklogstatus(state, value) {
        this.logtime.status = null
        this.logtime.status = state
        if (state == 'closed') {
            this.getorientationfeedbackformlabels(this.editRowData.reviewers);
        }

        if(!state) {
            this.errors.status = "Micro Task status is required"
        } else {
            this.errors.status = null
        }
    },
    seltaskstatus(state, value) {
        this.taskdefine.status = null
        this.taskdefine.status = state.id
        if(!state) {
            this.errors.taskdefine.status = "Micro Task status is required"
        } else {
            this.errors.taskdefine.status = null
        }
        if(state && state.id == 'open' && this.taskdefine.type ==  this.taskTypedesignCreative && !this.issubmicrotask){
            this.errors.taskdefine.technology ='';
            this.errors.taskdefine.activity ='';
        }
    },
    selestimateshours(state, value) {
      this.ahourslist = [];
      if(!state) {
        if(this.taskdefine.type && this.taskTypedesignCreative.includes(this.taskdefine.type) && this.taskdefine.status =='open' && !this.issubmicrotask){
          this.errors.taskdefine.estimateshours = '';
        }else{
          this.errors.taskdefine.estimateshours = 'Micro Task estimates is required';
          this.taskdefine.ahour =null;
          this.taskdefine.amin =null;
        }
      } else {
        this.errors.taskdefine.estimateshours = ''
        let hourslimit = 23;
        if (state.id == 'smalltask') {
          hourslimit = 23;
        } else if (state.id == 'minortask') {
          hourslimit = 48;
        } else if (state.id == 'majortask') {
          hourslimit = 144;
        } else if (state.id == 'complextask') {
          hourslimit = 360;
        } else if (state.id == 'highlycomplextask') {
          hourslimit = 750;
        }
        for(let i=0; i<=hourslimit; i++) {
          if(i<=9) {
            let withZeroNo = '0'+i;
            this.ahourslist.push({ id: withZeroNo, label: withZeroNo});
          } else {
            this.ahourslist.push({ id: i.toString(), label: i.toString() });
          }          
        }
        if(state && state.id != this.editRowData.type){
          this.taskdefine.ahour =null;
          this.taskdefine.amin =null;
        }
      }
    },
    


    getConfigurationFormDetails(taskid) {
        this.isLoading = true;
        let url = "api/issue/getConfigurationFormDetails"
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            taskid: taskid,
        };
        axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.queanslist = result.data.data[0]
                for (const config of this.queanslist) {
                    if (config.fieldvalue == "1") {
                        config.fieldvalue = "Yes"
                    } else if (config.fieldvalue == "0") {
                        config.fieldvalue = "No"
                    }
                }
            }else{
                this.queanslist = [];
            }
        }).catch(e => {
            this.displayError(e)
        });
    },
        getProjectDataBYProjectid(projectid){
            this.isLoading = true;
            let url = "api/searching/getAssignedProjectList";
            this.input = {
                useremail: this.undt.username,
                empid :this.loginuscondition,
                projectid :projectid,
                empcode: this.undt.userid,
                isfrompage: 'isprojectid'
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.taskdefine.projectlist = result.data.data;
                } else {
                    this.taskdefine.projectlist = [];
                }
            }).catch(e => {
            this.displayError(e)
            });
        },
        
        btmChange(node, instanceId) {
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/master/commonmaster/getModuleDropdownOption";
                if(!this.taskdefine.projectid) {
                    this.isLoading = false;
                    return
                }
                this.input = {
                    tbl: "t38",
                    projectMasterid: this.taskdefine.projectid,
                    businesstaskname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.taskdefine.businesstasklist = result.data.data;
                    } else {
                        this.taskdefine.businesstasklist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        getBusinessTaskbyProject(){
             this.isLoading = true;
                let url = "api/searching/getBusinessTaskbyProject";
                if(!this.taskdefine.projectid) {
                    this.isLoading = false;
                    return
                }
                this.input = {
                    projectid: this.taskdefine.projectid,
                    empid:this.undt.userid,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.taskdefine.businesstasklist = result.data.data;
                    } else {
                        this.taskdefine.businesstasklist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
        },
    getDownloadLink(data) {
            if(data.data.length>0) {
                let payload = {
                documents:data.data.map(i => {
                    return {...i, useremail: this.undt.username, empcode: this.undt.userid}
                }),
                useremail: this.undt.username,
                empcode: this.undt.userid,

            };
            axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
            })
            .then((result) => {
                if(result.data.status) {
                    this.taskdefine.attachmentview = result.data;
                }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
     getissueLogTimeHistory(taskid ){
      this.isLoading = true;
      let url = "api/master/taskmaster/gettaskeLogTimeById";
      this.input = {
        taskid :taskid ,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.logtimehistory = result.data.data;
          const decryptedDepartmentList = this.logtimehistory.map(item => {
            item.designation = this.decryptText(item.designation);
            return item;
            })
          this.forceRerender()
        } else {
          this.logtimehistory=[]
        }
      }).catch(e => {
        this.displayError(e)
    });
    },
        getApplicationdata(mdl,curracttab){
            this.isLoading=true
            this.pageoffset=1
            
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT
            }
            if(mdl=="t11"){
                this.inputappl.limit=500
            }
            this.inputappl.useremail= this.undt.username,
            this.inputappl.empcode= this.undt.userid
            let apiURL='api/master/commonmaster/list'               
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false          
                if(result.data.errorCode == 0){
                    this.projectType=[];
                    this.projectType=result.data.data.rows
                    let tmpClassNm=''
                        if(mdl == 't25'){
                            if(curracttab == 'taskdefine-tab'){
                                //this.tasktypelist=result.data.data.rows
                                return true
                            } else if(mdl == 't25'){
                            if(curracttab == 'tasktype-tab'){
                                this.task.tasknamelist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskname-tab'){
                                this.taskName.tasknamemaster=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.typenamelist=result.data.data.rows
                                return true
                            }
                        }
                        }else if(mdl == 't17'){
                            if(curracttab == 'taskname-tab'){
                                tmpClassNm='tasknametaskleveldd'
                            }else if(curracttab == 'taskdefine-tab'){
                                this.typenamelist=result.data.data.rows
                                return true                                
                            }
                        }else if(mdl == 't2'){
                            tmpClassNm='devBusinessVerticleData'
                        }else if(mdl == 't23'){
                            if(curracttab == 'tasktype-tab'){
                                this.tasknamelist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.tasktypelist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't24'){
                            if(curracttab == 'tasktype-tab'){
                                this.task.tasklevellist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskname-tab'){
                                this.taskName.tasklevellist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.tasklevel=result.data.data.rows
                                return true
                            }else if(curracttab == 'task'){
                                this.tasklevellist=result.data.data.rows
                                return true
                            }
                        }
                        else if(mdl == 't11'){
                            if(curracttab == 'task'){
                                this.tasktype.projectlist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                
                                return true
                            }                                
                        }
                        else if(mdl == 't21'){
                            if(curracttab == 'taskassignment-tab'){
                                this.assignment.employeeoptions=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.employeeoptions=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't18'){
                            if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.taskdefineList=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskassignment-tab'){
                                this.assignment.tasklist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't19'){
                            if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.taskassignmentList=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't9'){
                            if(curracttab == 'taskdefine-tab'){
                                this.taskdefine.businesstasklist=result.data.data.rows
                                return true
                            }
                        }
                        let payload={
                            'classnm':tmpClassNm
                        }
                        this.fillDropdown(payload);
                }
            })
        },

        clickCallback_blockerlist: function (pageNum) {
            this.b_pageoffset = pageNum;
            this.b_currPageNum = pageNum - 1;

            this.getBlockerListByBusinessTask(this.$route.query.taskid)
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#card-navigation").offset().top -70
                });
            }, 500);
        },
        getProjectList() {
            this.isLoading = true;
            let url = "api/listing/getProjectAllocatedToEmp";
            this.input = {
                empid: parseInt(this.undt.userid),
                useremail: this.undt.username,
                empcode: this.undt.userid
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.projectlist = result.data.data
                } else {
                    this.tasktype.projectlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getBTaskListToTagBlocker(node) {
            if(node != null && node != '' && node.length > 4) {
                this.isLoading = true;
                let url = "api/blocker/getBTaskListToTagBlocker";
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    node: node ? node.toLowerCase() : null,
                    id: parseInt(this.$route.query.taskid),
                    moduletype: 'task',
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.tasksblockerlist = result.data.data.rows.map(item => ({ id: item.id, label: item.name+' - '+item.code, projectid: item.projectid }));
                    } else {
                        this.tasksblockerlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            } else {
                this.tasksblockerlist = [];
            }
        },
        seltaskblockercomments(state) {
            this.blockertaskdetails.comment = null
            this.blockertaskdetails.comment = state
            if(!state) {
                this.blockertaskdetails.errors.comment = "Comment is required"
            } else {
                this.blockertaskdetails.errors.comment = null
            }
        },
        seltBlockerTask(state) {
            this.blockertaskdetails.blockertask = null
            this.blockertaskdetails.blockertask = state
           
            if(!state) {
                this.blockertaskdetails.errors.blockertask = "Blocker Task is required"
                this.blockertaskdetails.projectid = null
            } else {
                this.blockertaskdetails.errors.blockertask = null
                let pid = this.tasksblockerlist.filter(itm => itm.id == state)
                if(pid.length>0) {
                    this.blockertaskdetails.projectid = pid[0].projectid
                }
            }
        },
        seltaskblockerstatus(state) {
            this.blockertaskdetails.status = null
            this.blockertaskdetails.status = state
            if(!state) {
                this.blockertaskdetails.errors.status = "Related to is required"
            } else {
                this.blockertaskdetails.errors.status = null
            }
        },
        clearBlockermodulefields() {
            this.blockertaskdetails.comment = '';
            this.blockertaskdetails.status = null;
            this.blockertaskdetails.moduletype = null;
            this.blockertaskdetails.errors.comment = null;
            this.blockertaskdetails.errors.status = null;
            this.blockertaskdetails.errors.blockertask = null;
            this.blockertaskdetails.blockertask = null;
            this.blockertaskdetails.projectid = null;
            this.tasksblockerlist = [];
        },
        saveBlockerTasks () {
            let valid = this.validateBlocekrForm();
            if(valid){
                this.logtime.isTimeLogged = false;
                this.input = {
                    moduletype:'task',
                    id: parseInt(this.editRowData.moduleid),
                    projectid: parseInt(this.blockertaskdetails.projectid),
                    comment: this.blockertaskdetails.comment,
                    status: this.blockertaskdetails.status,
                    empcode: this.undt.userid,
                    useremail: this.undt.username,
                    blockertasklist: this.blockertaskdetails.blockertask,
                }
                axios({
                    method: "POST",
                    url: 'api/tasks/saveBlockerTasks',
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.disBlockerActive=true
                        this.isLoading = false
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1  
                        })
                        this.openSubTaskList(this.$route.query.taskid)
                        this.clearBlockermodulefields();
                        $('html, body').animate({ scrollTop: 0 }, 1200);
                    } else if(result.data.errorCode == 3) {
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                            }).then(function() {
                                window.location.href = "/#/login";
                            })
                        } else {
                            Swal.fire({
                            title: "Failed!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        validateBlocekrForm () {
            let isValid = true;
           
            if (this.blockertaskdetails.comment == null || this.blockertaskdetails.comment == "") {
                this.blockertaskdetails.errors.comment = "Comment is required."   
                isValid = false;
            }else{
                this.blockertaskdetails.errors.comment = null;
            }
            if (this.blockertaskdetails.status == null || this.blockertaskdetails.status == "") {
                this.blockertaskdetails.errors.status = "Status is required."   
                isValid = false;
            }else{
                this.blockertaskdetails.errors.status = null;
            }

            if (this.blockertaskdetails.blockertask == null || this.blockertaskdetails.blockertask.length == 0) {
                this.blockertaskdetails.errors.blockertask = "Blocker Task is required."   
                isValid = false;
            }else{
                this.blockertaskdetails.errors.blockertask = null;
            }
            return isValid;
        },
        inputsearchbloker(){
            if (this.searchBlocker !== null)
            this.blockersearch = true
            this.getBlockerListByBusinessTask(this.$route.query.taskid)
        },
        getBlockerListByBusinessTask(id,state){
            this.isLoading = true;
            let url = "api/tasks/getBlockerListByTask";
            this.input = { id, empcode: this.undt.userid, useremail: this.undt.username };
            this.input.limit = apiUrl.LIMIT
            this.input.offset = this.b_pageoffset
            
            this.input.relatedto
            this.input.blockercode
            this.input.name
            //this.input.order = this.order
            this.input.labelname = this.labelname
            if (this.searchBlocker) {
                let code = this.searchBlocker.split('-')[0]
                if (code == 'TK' || code == 'tk') {
                        this.input.blockercode = this.searchBlocker.toUpperCase()
                    } else {
                        this.input.blockercode = null
                        this.input.name = this.searchBlocker
                        this.input.name = this.input.name.toLowerCase()
                    }
            }

            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.blockertaskslistofbusiness = result.data.data.rows
                    this.blockersearch = true;
                    this.b_totalcount = result.data.data.count;
                    this.b_pageCount = Math.ceil(this.b_totalcount / this.b_pagelimit)
                } else {
                    this.blockertaskslistofbusiness = []
                    this.b_totalcount = 0
                    this.b_pageCount = 0
                }
            }).catch(e => {
                    this.displayError(e)
                });
        },

        getsorting(labelName) {
    
            let globalthis = this
                let index = $(this).index(),
                rows = [],
                thClass = $(this).hasClass('asc') ? 'desc' : 'asc';
                let listinglbl = '';
                if($(this).closest('table').attr('id')  ==  'table_businessceo1') {
                    listinglbl = 'table_businessceo1';
                }
                 else {
                    listinglbl = 'table_businessceo1';
                }
                $('#'+listinglbl+ ' th').removeClass('asc desc');

                $(this).addClass(thClass);
                globalthis.labelname = labelName;
                
                rows.sort(function (a, b) {

                    let aValue = $(a).find('td').eq(index).text(),
                        bValue = $(b).find('td').eq(index).text();
                        
                    if (!isNaN(aValue) && !isNaN(bValue)) {
                        aValue = Number(aValue)
                        bValue= Number(bValue)
                    }

                    return aValue > bValue
                        ? 1
                        : aValue < bValue
                        ? -1
                        : 0;
                });
                this.ordering = !this.ordering;
                if(this.ordering){
                    globalthis.order='DESC'
                }  else {

                    globalthis.order='ASC'
                }
                if(globalthis.labelname == 'Task Code' || globalthis.labelname == 'Task Name' || globalthis.labelname == 'Project Name' || globalthis.labelname == 'Related To'){
                    globalthis.getBlockerListByBusinessTask(globalthis.$route.query.taskid,globalthis.order)
                } else if (globalthis.labelname == 'Micro Task Code' || globalthis.labelname == 'Micro Task Name' || globalthis.labelname == 'Parent Task Code' || globalthis.labelname == 'Status' || globalthis.labelname == 'Type' || globalthis.labelname == 'Project Name' || globalthis.labelname == 'Assignee' || globalthis.labelname == 'Assignor' || globalthis.labelname == 'BTC Code') {
                    globalthis.getSubTaskList(globalthis.$route.query.taskid,globalthis.order)
                }
                $.each(rows, function (index, row) {
                    $('#'+listinglbl+' tbody').append(row);
                });
        },

        redirectToTask(btnstate,taskid) {
            this.getTaskByID(taskid)
        },

        fillDropdown(data){
            return ;
        },
        selprojdata(state,projvalue){
            if(this.taskdefine.oldprojectid !=state){
                this.taskdefine.module=null
                this.taskdefine.application=null
                this.taskdefine.appmodule=null
                this.taskdefine.devsection=null
                this.taskdefine.feature=null
                this.taskdefine.allottedto = null
                this.taskdefine.subscribers = null
                this.taskdefine.businesstype = null;
            }
            this.taskdefine.projectid=null
            this.taskdefine.projectid=state

            if(!state){
                this.taskdefine.businesstasklist = null
                this.taskdefine.businesstype = null
                this.taskdefine.application=null
                this.taskdefine.applicoptions = null
                this.taskdefine.module=null
                this.taskdefine.projectmdllist = []
                this.taskdefine.appmodule=null
                this.taskdefine.appmodulelist = []
                this.taskdefine.devsection=null
                this.taskdefine.sectionlist = null
                this.taskdefine.feature=null
                this.taskdefine.featurelist = null
                this.errors.taskdefine.projectid='Project required'
                this.taskdefine.allottedto = null
                this.taskdefine.subscribers = null
                this.taskdefine.projectlist = []
            }else{                
                this.errors.taskdefine.projectid=null
                this.getBusinessTaskbyProject()
                this.getfiltereddata('tdapp');
                this.getfiltereddata('tdprojmodule');
                // this.getEmpallocatedtoProject(state);
                this.getStatusConfigList(state,this.taskdefine.status)
                this.getTasktypeConfigLists(state)
                if (state != this.editRowData.projectid) {
                    this.taskdefine.type = null
                }
               
            }
        },
        selprojmdldata(state,projvalue){
            this.taskdefine.module=null
            this.taskdefine.module=state
            if(!state){
                this.taskdefine.appmodule=null
                this.taskdefine.appmodulelist = []
                this.taskdefine.devsection=null
                this.taskdefine.sectionlist = null
                this.taskdefine.feature=null
                this.taskdefine.featurelist = null
                this.taskdefine.appmodule=null
                this.taskdefine.devsection=null
                this.taskdefine.feature=null
            }else{                
                this.errors.taskdefine.module=null
                this.getfiltereddata('tdappmodule');
            }
        },
        selappldata(state,value){
            this.taskdefine.application=null
            this.taskdefine.application=state
            
            if(!state){
                this.taskdefine.module=null
                this.taskdefine.projectmdllist = []
                this.taskdefine.appmodule=null
                this.taskdefine.appmodulelist = []
                this.taskdefine.devsection=null
                this.taskdefine.sectionlist = null
                this.taskdefine.feature=null
                this.taskdefine.featurelist = null
                this.errors.taskdefine.application=null
                this.taskdefine.module=null
                this.taskdefine.appmodule=null
                this.taskdefine.devsection=null
                this.taskdefine.feature=null
            }else{                
                this.errors.taskdefine.application=null
                this.getfiltereddata('tdprojmodule');
            }

        },
        selapplmdldata(state,value){
            this.taskdefine.appmodule=null
            this.taskdefine.appmodule=state
            if(!state){
                 this.taskdefine.devsection=null
                this.taskdefine.sectionlist = null
                this.taskdefine.feature=null
                this.taskdefine.featurelist = null
                this.taskdefine.appmodule=state
                this.errors.taskdefine.appmodule='Application Module required'
                this.taskdefine.devsection=null
                this.taskdefine.feature=null
            }else{                
                this.errors.taskdefine.appmodule=null
                this.getfiltereddata('tdappmdl');
            }
        },
        seltasktypedata(state,value){
            this.task.tasklevel=null
            this.task.tasklevel=state
            if(!state && this.isAddState == false){
                this.errors.task.tasklevel='Task Type level required'
            }else{                
                this.errors.task.tasklevel=null
            }
        },
        seldefinetypedata(state,value){
            this.taskdefine.type=null;
            this.taskdefine.type=state.id;
            if(!state && this.isAddState == false){
                this.errors.taskdefine.type='Micro Task type required'
            }else{                
                this.errors.taskdefine.type=null
            }
            if(state && state.id == this.taskTypedesignCreative && this.taskdefine.status =='open' && !this.issubmicrotask){
                this.errors.taskdefine.technology ='';
                this.errors.taskdefine.activity ='';
            }
        },
        seltasknmtypedata(state,value){
            this.task.tasknames=null
            this.task.tasknames=state
            if(!state && this.isAddState == false){
                this.errors.taskName.tasklevel='Micro Task name level required'
            }else{                
                this.errors.taskName.tasklevel=null
            }
        },
        seltaskdftypedata(state,value){
            this.taskdefine.level=null
            this.taskdefine.level=state.id
            if(!state && this.isAddState == false){
                this.selectedLevel = 10
                this.errors.taskdefine.level='Level required'
            }else{                
                this.errors.taskdefine.level=null
                if(this.shouldDisable || this.taskdefine.isaddsubtask == true) {
                    this.disablefields(state.id);
                }
            }
        },
        
        selallotedto: function(state,value){
            this.taskdefineallotedto=null
            this.taskdefine.allotedto=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.allotedto='Alloted To required'
            }else{ 
                this.errors.taskdefine.allotedto=null      
            }
        },
        disablefields(level) {
            this.selectedLevel = 10
            this.selectedLevel = level
            if(level == 1) {
                this.taskdefine.application = null
                this.taskdefine.module = null 
                this.taskdefine.appmodule = null
                this.taskdefine.devsection = null
                this.taskdefine.feature = null
            } else if(level == 2) {
                this.taskdefine.appmodule = null
                this.taskdefine.devsection = null
                this.taskdefine.feature = null
            } else if(level == 3) {
                this.taskdefine.module = null 
                this.taskdefine.appmodule = null
                this.taskdefine.devsection = null
                this.taskdefine.feature = null
            } else if(level == 4) {
                this.taskdefine.devsection = null
                this.taskdefine.feature = null
            } else if(level == 5) {
                this.taskdefine.feature = null
            }

        },
        seltaskdfnamedata(state,value){
            this.taskdefine.name=null
            this.taskdefine.name=state
            if(!state && this.isAddState == false){
            }else{                
                this.errors.taskdefine.name=null
            }
        },
        selastaskldata(state,value){
            this.assignment.taskid=null
            this.assignment.taskid=state
            if(!state && this.isAddState == false){
                this.errors.assignment.taskid='Employee required'
            }else{                
                this.errors.assignment.taskid=null
                this.getfiltereddata('taemployees')
            }
        },
        selasempldata(state,value){
            this.assignment.empid=null
            this.assignment.empid=state
            this.errors.assignment.empid=null
            if(!state && this.isAddState == false){
                this.errors.assignment.empid='Employee required'
            }else{                
                this.errors.assignment.empid=null
            }
        },
        selastempldata(state,value){
            this.statusassignment.empid=null
            this.statusassignment.empid=state
            this.errors.statusassignment.empid=null
            if(!state && this.isAddState == false){
                this.errors.statusassignment.empid='Employee required'
            }else{                
                this.errors.statusassignment.empid=null
            }
        },
        seltaskasdata(state,value){
            this.statusassignment.taskassignmentid=null
            this.statusassignment.taskassignmentid=state
            if(!state && this.isAddState == false){
                this.errors.statusassignment.taskassignmentid='Task assignment required'
            }else{                
                this.errors.statusassignment.taskassignmentid=null
            }
        },
        seltaskdfndata(state,value){
            this.statusassignment.taskdefineid=null
            this.statusassignment.taskdefineid=state
            if(!state && this.isAddState == false){
            }else{                
                this.errors.statusassignment.taskdefineid=null
                this.getfiltereddata('tasemployees')
            }
        },
        seldefinebusinessdata(state,value){
            this.taskdefine.businesstype=null
            this.taskdefine.businesstype=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.businesstype='Business task name is required'
            }else{                
                this.errors.taskdefine.businesstype=null                
            }
        },
        tskdfnsectiondata(state,value){
            this.taskdefine.devsection=null
            this.taskdefine.devsection=state
            if(!state && this.isAddState == false){
                this.taskdefine.feature=null
                this.taskdefine.featurelist = null
                this.taskdefine.feature=null
            }else{                
                this.errors.taskdefine.devsection=null
                this.getfiltereddata('tdsection');
            }
        },
        tskdfnfnlitydata(state,value){
            this.taskdefine.feature=null
            this.taskdefine.feature=state
            if(!state){
                this.taskdefine.feature=null
            }else{                
               
            }
        },
        redirecttoBTM:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if (btnstate == 'view') {
                this.$router.push({ 
                path: '/businesstask/view?businessid='+rowdata.moduleid,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
                })
            } else if (btnstate == 'projectid') {
                this.$router.push({ 
                    path: '/project/summary?pid='+rowdata.moduleid,
                })
            }
            
        },
        getfiltereddata(tblNm){
            this.isLoading=true
            this.pageoffset=1
            var url = 'api/master/commonmaster/customselect'
            if(tblNm == 'tdprojmodule'){
                url='api/listing/getpromodulebyapplicationid'
                this.input = {
                    projecteid:this.taskdefine.projectid,
                    isfrompage: 'updatepage'
                }
                this.taskdefine.projectmdllist=[]
            }else if(tblNm == 'tdappmodule'){
                url='api/listing/getappmodulenamebypromoduleid'
                this.input = {
                    projectmoduleid:this.taskdefine.module,
                    isfrompage: 'updatepage'
                }
                this.taskdefine.appmodulelist= []
            }else if(tblNm == 'tdapp'){
                url='api/listing/getappnamebyprojecteid'
                this.input = {
                    projecteid:this.taskdefine.projectid
                }
                this.taskdefine.applicoptions=null
            }else if(tblNm == 'tdappmdl'){
                url='api/listing/getsectionamebyappmoduleid'
                this.input = {
                    applicationmoduleid:this.taskdefine.appmodule
                }
                this.taskdefine.sectionlist=null
            }else if(tblNm == 'tdsection'){
                url='api/listing/getfunctionalityamebysectionid'
                this.input = {
                    sectionmasterid:this.taskdefine.devsection
                }
                this.taskdefine.featurelist=null
            }else if(tblNm == 'taemployees'){
                url='api/listing/getemployeesbytaskid'
                this.input = {
                    taskid:this.assignment.taskid,
                    taskfilter:'developer' 
                }
                this.assignment.employeeoptions=null
            }else if(tblNm == 'tasemployees'){
                url='api/listing/getemployeesbytaskid'
                this.input = {
                    taskid:this.statusassignment.taskdefineid,
                    taskfilter:'developer'
                }
                this.statusassignment.employeeoptions=null
            } else if(tblNm == 'btprojassign') {
                url = 'api/master/commonmaster/getModuleDropdownOption'
                this.input = {
                    tbl: "t38",
                    projectMasterid: this.taskdefine.projectid
                }
                this.taskdefine.businesstasklist =null
            }
            this.input.useremail= this.undt.username,
            this.input.empcode= this.undt.userid
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false;  
                if(result.data.errorCode == 0){
                    if(tblNm == 'tdprojmodule'){
                        this.taskdefine.projectmdllist=result.data.data;

                        if(this.taskdefine.projectmdllist && this.taskdefine.projectmdllist.length > 0 && (this.taskdefine.module && this.taskdefine.module !=null)){
                            let checkpromodule = this.taskdefine.projectmdllist.find(item =>item.id === this.taskdefine.module);
                            if(!checkpromodule){
                                this.getprojectmodulesbyid(this.taskdefine.module);
                            }
                        }else if(this.taskdefine.projectmdllist.length ==0 && (this.taskdefine.module && this.taskdefine.module !=null)){
                            this.getprojectmodulesbyid(this.taskdefine.module);
                        }

                    }else if(tblNm == 'tdappmodule'){
                        this.taskdefine.appmodulelist=result.data.data;

                        if(this.taskdefine.appmodulelist && this.taskdefine.appmodulelist.length > 0 && (this.taskdefine.appmodule && this.taskdefine.appmodule !=null)){
                            let checkappmdule = this.taskdefine.appmodulelist.find(item =>item.id === this.taskdefine.appmodule);
                            if(!checkappmdule){
                                this.getappmodulebyid(this.taskdefine.appmodule, this.taskdefine.module);
                            }
                        }else if(this.taskdefine.appmodulelist.length ==0 && (this.taskdefine.appmodule && this.taskdefine.appmodule !=null)){
                            this.getappmodulebyid(this.taskdefine.appmodule, this.taskdefine.module);
                        }

                    }else if(tblNm == 'tdapp'){
                         this.taskdefine.applicoptions=result.data.data
                    }else if(tblNm == 'tdappmdl'){
                        this.taskdefine.sectionlist=result.data.data
                    }else if(tblNm == 'tdsection'){
                        this.taskdefine.featurelist=result.data.data
                    }else if(tblNm == 'taemployees'){
                        this.assignment.employeeoptions=result.data.data
                    }else if(tblNm == 'tasemployees'){
                        this.statusassignment.employeeoptions=result.data.data
                    }else if(tblNm == 'btprojassign') {
                        this.taskdefine.businesstasklist = result.data.data
                    }
                }else{
                    if(tblNm == 'taemployees'){
                        this.assignment.employeeoptions=null
                        this.errors.assignment.empid='employees not found'
                    }else if(tblNm == 'tasemployees'){
                        this.statusassignment.employeeoptions=null
                        this.errors.statusassignment.empid='employees not found'
                    }
                    
                }
            })
        },
        getprojectmodulesbyid(moduleid) {
            let url = 'api/listing/getpromodulebyapplicationid';
            this.input = {
                projectmoduleid: moduleid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                if(this.taskdefine.projectmdllist && this.taskdefine.projectmdllist.length > 0){
                    let checkexistprojectmodules = this.taskdefine.projectmdllist.find(item =>item.id === moduleid);
                    if(!checkexistprojectmodules){
                    this.taskdefine.projectmdllist.push(result.data.data[0]);
                    }
                }else if(this.taskdefine.projectmdllist.length ==0){
                    this.taskdefine.projectmdllist.push(result.data.data[0]);
                }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getappmodulebyid(appmid, pmid) {
            let url = 'api/listing/getappmodulenamebypromoduleid';
            this.input = {
                projectmoduleid: pmid,
                appmoduleid: appmid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                console.log(result.data.data[0]);
                if(this.taskdefine.appmodulelist && this.taskdefine.appmodulelist.length > 0){
                    const checkappmodule = this.taskdefine.appmodulelist.find(item =>item.id === appmid);
                    if(!checkappmodule){
                    this.taskdefine.appmodulelist.push(result.data.data[0]);
                    }
                }else if(this.taskdefine.appmodulelist.length ==0){
                    this.taskdefine.appmodulelist.push(result.data.data[0]);
                }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        selectBtmPriority(state, value) {
            this.tasktype.btmpriority = null
            this.tasktype.btmpriority = state
            if(!state){
                this.tasktype.priorityerr = true
            } else {                
                this.tasktype.priorityerr = false
            }
        },
        selectserPriority(state, value) {
            this.tasktype.key = null
            this.tasktype.key = state

        },
        selecttypePriority(state, value) {
            this.tasktype.type = null
            this.tasktype.type = state

        },
        selectrefPriority(state, value) {
            this.tasktype.references = null
            this.tasktype.references = state
        },
        selecttaskstatus(state, value) {
            this.tasktype.tstatus = null
            this.tasktype.tstatus = state
        },
        selectbtmDept(state, value) {
            this.tasktype.dept = null
            this.tasktype.dept = state
            if(!state){
                this.tasktype.depterr = true
            } else {                
                this.tasktype.depterr = false
            }
        },
        seltaskleveldata(state,value){
            this.tasktype.level=null
            this.tasktype.level=state
            if(!state && this.isAddState == false){
                this.tasktype.levelerror=true
            }else{                
                this.tasktype.levelerror=false
            }
        },
        seltpprojectdata(state,value){
            this.tasktype.projectid=null
            this.tasktype.projectid=state
            if(!state && this.isAddState == false){
                this.errors.tasktype.projectid="Project Required"
            }else{                
                this.errors.tasktype.projectid=null
            }
        },
        selLogAllotedTo(state, value) {
            if(!state) {
                this.errors.allotedto = "Assigned to required"
            } else {
                this.errors.allotedto = null
            }
        },
        showprojectname(prname){
            let responsestr='';
            let prlist=prname.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            
            return responsestr
        },
        onSelect(){
            this.file = e.target.files[0];
        },
getLogtimeDownloadLink(data, curr=null) {      
      if(data) {
        var input =[]
        input.push({link:data,empcode: this.undt.userid,useremail: this.undt.username})
        let payload = {
          documents:input,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
            method: "POST",
            url: '/api/imageupload/getdownloadlink',
            data: payload,
            'headers':{'authorization':this.tokendata}
        })
        .then((result) => {
          if(result.data.status) {
            if(result.data.data[0].presignurl){
              if(curr=='zoom'){
                $('.imagepreview').attr('src', result.data.data[0].presignurl);
                $('#imagemodal').modal('show');  
                return 
              }
              window.location.href=result.data.data[0].presignurl;
            }
          }
        }).catch(e => {
            this.displayError(e)
        });
      }
    },
    getStatusConfigList(pid, status) {
        if(this.statusconfig){
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: pid,
                trackername: 'Task',
                currStatus: status,
            };
            axios({
                method: "POST",
                url: '/api/testcase/getStatusConfigList',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    this.taskdefine.statuslist = result.data.data.rows;
                    if(this.TaskTypeRestrictforClose.length > 0 && this.TaskTypeRestrictforClose.includes(this.view.tasktypename) && this.undt.userid !=this.taskdefine.allottedto){
                      let ostatuslist = this.taskdefine.statuslist.filter(i=> i.id !='closed')
                        this.taskdefine.statuslist = ostatuslist
                    }
                } else {
                    this.taskdefine.statuslist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        }
    },
    saveBusinessDocuments(btmtaskfiles){
      this.input=[]
      if(btmtaskfiles && btmtaskfiles.length) {
        let temparr = []
        for(let i=0; i<btmtaskfiles.length; i++) {
          let attachinfo = btmtaskfiles[i]
          let obj = {
            documentid:null,
            documentname:attachinfo.link,
            title:attachinfo.info,
            desc: attachinfo.info,
            comment: `task doc comment ${i+1}`,
            version:'v1',
            createdby: this.undt.username,
            taskid: this.taskdefine.businesstype,
            projectname: this.view.projectname,
            projectid: parseInt(this.taskdefine.projectid),
            projectmodulename: this.view.projectmodulename,
            projectmoduleid: parseInt(this.taskdefine.module),
            
            businesstaskmoduleassignmentid:null,
            businesstaskmasterid:this.taskdefine.businesstype,
            useremail: this.undt.username,
            empcode: this.undt.userid,
          }
          temparr.push(obj)
        }
        this.input = temparr
        if(this.input.length>0){
          let url = "api/workstep/bulkinsert";
          axios({
            method: "POST",
            url: url,
            data: {document:this.input},
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            if (result.data.errorCode == 0) {     
              /** */                           
            }
          }).catch(e => {
                this.displayError(e)
            });
        } else {
          this.isLoading = false;
        }
      }
    },
    onZoom(e){
      this.getLogtimeDownloadLink(e, 'zoom');
    },
    
    onUpload(){
      if(this.file){
        this.isLoading=true
        if(this.file.length !== 0){
            this.apiURL="api/master/fileupload/uploadBulkFileToS3"
            let formData = new FormData();  
            let maxsize = 0;
            for (let index = 0; index < this.file.length; index++) {
                formData.append("imagefile", this.file[index]);
                maxsize+= this.file[index].size
            }
            formData.append("empcode", this.undt.userid);
            formData.append("useremail", this.undt.username);
            formData.append("moduletype", 'task');
            formData.append("fcount", parseInt(this.file.length));
            
            if (maxsize > 1000 * 1000 * 25) {
                this.isLoading = false;
                Swal.fire({
                    title: "Failed",
                    text: "Your document uploaded above 25mb not allow",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
                this.isLoading=false
                this.taskdefine.disblesavebtn=true
                return false; 
            }
            axios({
                'content-type':'multipart/form-data',
                'method': 'POST',
                'url': this.apiURL,
                'data':formData,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading=false; 
                this.taskdefine.disblesavebtn=false
                if(result.data.status){
                      const bindinfo = result.data.data.map((file, index) => {
                        return {"link":file,"info":this.taskdefine.filedescp[index]}                
                    });
                    let jsonobj = {data:bindinfo}
                    this.taskdefine.attachmentnew = JSON.stringify(jsonobj);
                    if(this.taskmodule == 1) {
                      this.saveBusinessDocuments(bindinfo)
                    }
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                } else {
                    Swal.fire({
                        title: "",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.taskdefine.attachment=null
                }
            }).catch(e => {
                this.displayError(e)
            })
        }else{
              this.isLoading=false; 
            Swal.fire({
                title: "",
                text: 'Select image/docs/videos',
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
            })
            this.taskdefine.attachment=null
        }
      } else {
          this.isLoading=false; 
          Swal.fire({
              title: "",
              text: 'Select image/docs/videos',
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
          })
          this.taskdefine.attachment=null
      }
    },


    getorientationfeedbackformlabels(type) {
        this.isLoading = true
        let url = 'api/feedbackform/getorientationfeedbackformlabels'
        if (type =='engineeringmanager') {
            this.moduletrackerid = 18
        } else if (type == 'projectmanager') {
            this.moduletrackerid = 19
        } else if (type == 'qalead') {
            this.moduletrackerid = 17
        } else {
            this.isLoading = false
            return
        }
        let payload = new Object({
            empcode: this.undt.userid,
            useremail  : this.undt.username,
            moduletracker: this.moduletrackerid
        })
        axios({
            method: 'POST',
            url,
            data: payload,
            headers:{ authorization :this.tokendata }
        }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.fieldnamelist = result.data.data
                    for (let i = 0; i < this.fieldnamelist.length; i++) {
                        if (this.fieldnamelist[i].fieldtype=="checkbox") {
                            let tempdata = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata[j] = {}
                                    tempdata[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue']=tempdata
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="radio") {
                            let tempdata2 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata2[j] = {}
                                    tempdata2[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata2[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['radiooptionalvalue']=tempdata2
                            }
                        }
                    }
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.fieldnamelist = [];
                }
            }).catch(e => {
                this.displayError(e)
            })
        },



        formatData(data) {
            return data
        },

        previewpdf(geturl)
        {
            window.open(geturl, "_blank");
        }
    }  ,
        filters: {
    basename: function (value) {
      if (!value) return ''
      
      return value.substr(value.lastIndexOf("/") + 1)
    }
  },
        
      
}

</script>
<style>

.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
.d-table_icon_up.bussiness{
        cursor: pointer;
    }
    .tast_case_groupingview_readmore{
        text-align: left;
    }
    
</style>
