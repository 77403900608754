<template>
    <img alt="" :src="presignurldata" class="imageclass" />
</template>
<script>
import axios from 'axios'
import apiUrl from "../constants";

import { DownloadIcon} from 'vue-feather-icons'
import commonMethods from '../utils/commonMethods';

export default {
    name: 'articlepic',
    mixins: [ commonMethods ],
    props: ['getUrl'],
    components:{
        DownloadIcon
    },
    data() {
        return {
          presignurldata: apiUrl.defaultimagepath,
        }
    },
    beforeMount() {
        this.getLogtimeDownloadLink()
    },
    methods:{
        getLogtimeDownloadLink() {
            this.tokendata = window.localStorage.getItem('token');
            let userdata = window.localStorage.getItem('userdata');
            userdata = JSON.parse(userdata)
            this.undt = userdata

            let payload = {
                link:this.getUrl,
                empcode: this.undt.userid,
                useremail: this.undt.username
            };
            axios({
                'method': 'POST',
                'url': "/api/imageupload/getProfilePicDownloadLink",
                'data':payload,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                if(result.data.status) {
                    if(result.data.data.length>0) {
                        this.presignurldata =result.data.data
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        }
    }
}
</script>