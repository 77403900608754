<template>
  <div class="app-content content">
    <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
              <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-10 col-md-10 mobile_margin-px-0_00 d-flex align-items-center">
                        <div class="avatar bg-light-secondary rounded d-inline-flex">
                            <div class="avatar-content">
                                <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3">
                                </search-icon>
                            </div>
                        </div>
                         <h4 class="card-title d-inline-flex ml-1">Search Business Task Aging Report</h4>
                    </div>
                    <span class="rotetions_view_arrow ml-auto mx-1"> 
                        <em class="las la-angle-down"></em>
                      </span>
                  </div>
                </div>
              </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    <div class="form-group col-sm-6 col-md-6 col-lg-3 mb-1">
                        <label class="form-label" for="roles">Business Task Tracker</label>
                        <treeselect class="projroleassprojectdd" placeholder="Select Role" :options="managersList" v-model="search.roleId" @input="getBSHlist" v-if="pagefeatures.hodroleprojectaging" :disabled="true"/>
                        <treeselect class="projroleassprojectdd" placeholder="Select Role" :options="managersList" v-model="search.roleId" @input="getBSHlist" v-else/>
                    </div>

                    <div class="form-group col-sm-6 col-md-6 col-lg-3 mb-1">
                        <label class="form-label" for="bsh" v-if="search.roleId=='project'">Project Name</label>
                        <label class="form-label" for="bsh" v-else>Stakeholder Name</label>
                        <treeselect class="projroleassprojectdd" placeholder="Select Project Name " :options="projectopt" v-model="search.project" :multiple="true" :clear-on-select="true" v-if="search.roleId=='project'"/>
                        <treeselect class="projroleassprojectdd" placeholder="Select Stakeholder Name " :options="bshlist" v-model="search.bsh" :multiple="true" :clear-on-select="true" v-else/>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                        <label class="form-label lable-left">Task Severity</label>
                        <treeselect class="projectmdllistdd" placeholder="Select Task Severity" v-model="search.searchTaskseverity" :options="keylist"/>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                        <label class="form-label lable-left">Task Type</label>
                        <treeselect class="projectmdllistdd" placeholder="Select Task Type" v-model="search.searchTasktype" :options="typelist" :multiple="true" :clear-on-select="true"/>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 mb-0 text-right">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getAgingReportRecords(search,1)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
                      <button  type="move" class="btn btn-relief-primary mt-2 " @click="ExportXL()"> <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span> Export</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-warning filter_card_searchbgst">
                   <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="BTAgingSummaryCollapse">
                       <div class="container-fluid">
                        <div class="row mobile_co_mainheader_text">
                            <div class="w-auto px-1 mobile_margin-px-0_00 mobile_w_calc_38">
                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                    <div class="avatar-content">
                                      <em class="las la-clipboard-list font-medium-5"></em>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex ml-1 mobile_width-86">Business Task Aging Report Summary</h4>
                            </div>
                            <span class="rotetions_view_arrow ml-auto mx-1"> 
                                <em class="las la-angle-down"></em>
                            </span>
                        </div>
                        </div>
                    </div>
                    <div v-bind:class="[{show: isBTSummaryActive},card_content,collapse]">
                        <div class="card-body px-sm-mobile-10">
                            <section id="dashboard-ecommerce">
                              <div class="card card-statistics" >
                                <div class="">
                                  <div class="dark_theme_style_table">
                                    <div class="table-responsive ">
                                      <div class="table-responsive_sitcky">
                      <table class="table table-hover table-striped table-bordered table-sm" aria-describedby="Business Task Aging Report">
                          <thead>
                            <tr>
                              <th scope="col" class="title"></th>
                              <th scope="col" class="title">Overall Aging
                                <span class="popover-block-container-custom">
                                  <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#unique-id">
                                        <em class="las la-info-circle text-warning font-medium-5"></em>
                                    </button>
                                </span>
                              </th>
                              <th scope="col" class="title">Product/BA Aging
                                <span class="popover-block-container-custom">
                                    <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#business-agin">
                                          <em class="las la-info-circle text-warning font-medium-5"></em>
                                      </button>
                                  </span>
                              </th>
                              <th scope="col" class="title">PMO Aging
                                <span class="popover-block-container-custom">
                                    <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#pmo-aging">
                                          <em class="las la-info-circle text-warning font-medium-5"></em>
                                      </button>
                                  </span>
                              </th>
                              <th scope="col" class="title">TECH Aging
                                <span class="popover-block-container-custom">
                                  <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#tech-aging">
                                        <em class="las la-info-circle text-warning font-medium-5"></em>
                                    </button>
                                </span>
                              </th>
                              <th scope="col" class="title">QA Aging
                                <span class="popover-block-container-custom">
                                  <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#qa-aging">
                                          <em class="las la-info-circle text-warning font-medium-5"></em>
                                      </button>
                                  </span>
                              </th>
                              <th scope="col" class="title">Design Review Aging
                                <span class="popover-block-container-custom">
                                  <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#qa-aging">
                                          <em class="las la-info-circle text-warning font-medium-5"></em>
                                      </button>
                                  </span>
                              </th>
                              <th scope="col" class="title">Business Stakeholder Aging
                                <span class="popover-block-container-custom">
                                  <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#bs-aging">
                                        <em class="las la-info-circle text-warning font-medium-5"></em>
                                    </button>
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(sumdata, index) in agingReportSummaryList">
                                <tr>
                                    <td class="title font-weight-bolder mb-1 cardcenter"> 0 - 5 Days </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'overallaging',parseInt(sumdata.sumlessthan5overallaging),'0to5')" style="cursor:pointer"> {{sumdata.sumlessthan5overallaging ? sumdata.sumlessthan5overallaging: '0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'businessaging',parseInt(sumdata.sumlt5bsaging),'0to5')" style="cursor:pointer"> {{sumdata.sumlt5bsaging ? sumdata.sumlt5bsaging: '0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'pmoaging',parseInt(sumdata.sumlt5pmoaging),'0to5')" style="cursor:pointer"> {{sumdata.sumlt5pmoaging ? sumdata.sumlt5pmoaging: '0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'techaging',parseInt(sumdata.sumlessthan5techaging),'0to5')" style="cursor:pointer"> {{sumdata.sumlessthan5techaging ? sumdata.sumlessthan5techaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'qaaging',parseInt(sumdata.sumlt5qaaging),'0to5')" style="cursor:pointer"> {{sumdata.sumlt5qaaging ? sumdata.sumlt5qaaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'desingaging',parseInt(sumdata.sumlt5desingaging),'0to5')" style="cursor:pointer"> {{sumdata.sumlt5desingaging ? sumdata.sumlt5desingaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'stkaging',parseInt(sumdata.sumlt5stkaging),'0to5')" style="cursor:pointer"> {{sumdata.sumlt5stkaging ? sumdata.sumlt5stkaging:'0'}} </td>
                                </tr>
                                <tr>
                                    <td class="title font-weight-bolder mb-1 cardcenter"> 6 - 10 Days </td> 
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'overallaging',parseInt(sumdata.sumlessthan10overallaging),'6to10')" style="cursor:pointer"> {{sumdata.sumlessthan10overallaging ? sumdata.sumlessthan10overallaging:'0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'businessaging',parseInt(sumdata.sumlt10bsaging),'6to10')" style="cursor:pointer"> {{sumdata.sumlt10bsaging ? sumdata.sumlt10bsaging:'0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'pmoaging',parseInt(sumdata.sumlt10pmoaging),'6to10')" style="cursor:pointer"> {{sumdata.sumlt10pmoaging ? sumdata.sumlt10pmoaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'techaging',parseInt(sumdata.sumlessthan10techaging),'6to10')" style="cursor:pointer"> {{sumdata.sumlessthan10techaging ? sumdata.sumlessthan10techaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'qaaging',parseInt(sumdata.sumlt10qaaging),'6to10')" style="cursor:pointer"> {{sumdata.sumlt10qaaging ? sumdata.sumlt10qaaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'desingaging',parseInt(sumdata.sumlt10desingaging),'6to10')" style="cursor:pointer"> {{sumdata.sumlt10desingaging ? sumdata.sumlt10desingaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'stkaging',parseInt(sumdata.sumlt10stkaging),'6to10')" style="cursor:pointer"> {{sumdata.sumlt10stkaging ? sumdata.sumlt10stkaging:'0'}} </td>
                                </tr>
                                <tr>
                                    <td class="title font-weight-bolder mb-1 cardcenter"> 11 - 30 Days </td> 
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'overallaging',parseInt(sumdata.sumlessthan30overallaging),'11to30')" style="cursor:pointer"> {{sumdata.sumlessthan30overallaging ? sumdata.sumlessthan30overallaging:'0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'businessaging',parseInt(sumdata.sumlt30bsaging),'11to30')" style="cursor:pointer"> {{sumdata.sumlt30bsaging ? sumdata.sumlt30bsaging:'0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'pmoaging',parseInt(sumdata.sumlt30pmoaging),'11to30')" style="cursor:pointer"> {{sumdata.sumlt30pmoaging ? sumdata.sumlt30pmoaging:'0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'techaging',parseInt(sumdata.sumlessthan30techaging),'11to30')" style="cursor:pointer"> {{sumdata.sumlessthan30techaging ? sumdata.sumlessthan30techaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'qaaging',parseInt(sumdata.sumlt30qaaging),'11to30')" style="cursor:pointer"> {{sumdata.sumlt30qaaging ? sumdata.sumlt30qaaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'desingaging',parseInt(sumdata.sumlt30desingaging),'11to30')" style="cursor:pointer"> {{sumdata.sumlt30desingaging ? sumdata.sumlt30desingaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'stkaging',parseInt(sumdata.sumlt30stkaging),'11to30')" style="cursor:pointer"> {{sumdata.sumlt30stkaging ? sumdata.sumlt30stkaging:'0'}} </td>
                                </tr>
                                <tr>
                                    <td class="title font-weight-bolder mb-1 cardcenter"> 31 - 60 Days</td> 
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'overallaging',parseInt(sumdata.sumgreaterthan30overallaging),'31to60')" style="cursor:pointer"> {{sumdata.sumgreaterthan30overallaging ? sumdata.sumgreaterthan30overallaging:'0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'businessaging',parseInt(sumdata.sumgt30bsaging),'31to60')" style="cursor:pointer"> {{sumdata.sumgt30bsaging ? sumdata.sumgt30bsaging:'0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'pmoaging',parseInt(sumdata.sumgt30pmoaging),'31to60')" style="cursor:pointer"> {{sumdata.sumgt30pmoaging ? sumdata.sumgt30pmoaging:'0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'techaging',parseInt(sumdata.sumgreaterthan30techaging),'31to60')" style="cursor:pointer"> {{sumdata.sumgreaterthan30techaging ? sumdata.sumgreaterthan30techaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'qaaging',parseInt(sumdata.sumgt30qaaging),'31to60')" style="cursor:pointer"> {{sumdata.sumgt30qaaging ? sumdata.sumgt30qaaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'desingaging',parseInt(sumdata.sumgt30desingaging),'31to60')" style="cursor:pointer"> {{sumdata.sumgt30desingaging ? sumdata.sumgt30desingaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'stkaging',parseInt(sumdata.sumgt30stkaging),'31to60')" style="cursor:pointer"> {{sumdata.sumgt30stkaging ? sumdata.sumgt30stkaging:'0'}}</td>
                                </tr>
                                <tr>
                                    <td class="title font-weight-bolder mb-1 cardcenter"> 61 - 90 Days</td> 
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'overallaging',parseInt(sumdata.sumgreaterthan60overallaging),'61to90')" style="cursor:pointer"> {{sumdata.sumgreaterthan60overallaging ? sumdata.sumgreaterthan60overallaging:'0' }}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'businessaging',parseInt(sumdata.sumgt60bsaging),'61to90')" style="cursor:pointer"> {{sumdata.sumgt60bsaging ? sumdata.sumgt60bsaging:'0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'pmoaging',parseInt(sumdata.sumgt60pmoaging),'61to90')" style="cursor:pointer"> {{sumdata.sumgt60pmoaging ? sumdata.sumgt60pmoaging: '0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'techaging',parseInt(sumdata.sumgreaterthan60techaging),'61to90')" style="cursor:pointer"> {{sumdata.sumgreaterthan60techaging ?sumdata.sumgreaterthan60techaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'qaaging',parseInt(sumdata.sumgt60qaaging),'61to90')" style="cursor:pointer"> {{sumdata.sumgt60qaaging ? sumdata.sumgt60qaaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'desingaging',parseInt(sumdata.sumgt60desingaging),'61to90')" style="cursor:pointer"> {{sumdata.sumgt60desingaging ? sumdata.sumgt60desingaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'stkaging',parseInt(sumdata.sumgt60stkaging),'61to90')" style="cursor:pointer"> {{sumdata.sumgt60stkaging ? sumdata.sumgt60stkaging:'0'}} </td>
                                </tr>
                                <tr>
                                    <td class="title font-weight-bolder mb-1 cardcenter"> 91 - 120 Days</td> 
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'overallaging',parseInt(sumdata.sumgreatethan90overallaging),'91to120')" style="cursor:pointer"> {{sumdata.sumgreatethan90overallaging? sumdata.sumgreatethan90overallaging:'0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'businessaging',parseInt(sumdata.sumgt90bsaging),'91to120')" style="cursor:pointer"> {{sumdata.sumgt90bsaging ? sumdata.sumgt90bsaging:'0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'pmoaging',parseInt(sumdata.sumgt90pmoaging),'91to120')" style="cursor:pointer"> {{sumdata.sumgt90pmoaging ? sumdata.sumgt90pmoaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'techaging',parseInt(sumdata.sumgreatethan90techaging),'91to120')" style="cursor:pointer"> {{sumdata.sumgreatethan90techaging ? sumdata.sumgreatethan90techaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'qaaging',parseInt(sumdata.sumgt90qaaging),'91to120')" style="cursor:pointer"> {{sumdata.sumgt90qaaging ? sumdata.sumgt90qaaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'desingaging',parseInt(sumdata.sumgt90desingaging),'91to120')" style="cursor:pointer"> {{sumdata.sumgt90desingaging ? sumdata.sumgt90desingaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'stkaging',parseInt(sumdata.sumgt90stkaging),'91to120')" style="cursor:pointer"> {{sumdata.sumgt90stkaging ? sumdata.sumgt90stkaging:'0'}} </td>
                                </tr>
                                <tr>
                                    <td class="title font-weight-bolder mb-1 cardcenter"> 120 Days Above</td> 
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'overallaging',parseInt(sumdata.sumgreaterthan120overallaging),'120to')" style="cursor:pointer"> {{sumdata.sumgreaterthan120overallaging ? sumdata.sumgreaterthan120overallaging:'0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'businessaging',parseInt(sumdata.sumgt120bsaging),'120to')" style="cursor:pointer"> {{sumdata.sumgt120bsaging ? sumdata.sumgt120bsaging:'0'}}</td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'pmoaging',parseInt(sumdata.sumgt120pmoaging),'120to')" style="cursor:pointer"> {{sumdata.sumgt120pmoaging ? sumdata.sumgt120pmoaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'techaging',parseInt(sumdata.sumgreaterthan120techaging),'120to')" style="cursor:pointer"> {{sumdata.sumgreaterthan120techaging ? sumdata.sumgreaterthan120techaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'qaaging',parseInt(sumdata.sumgt120qaaging),'120to')" style="cursor:pointer"> {{sumdata.sumgt120qaaging ? sumdata.sumgt120qaaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'desingaging',parseInt(sumdata.sumgt120desingaging),'120to')" style="cursor:pointer"> {{sumdata.sumgt120desingaging ? sumdata.sumgt120desingaging:'0'}} </td>
                                    <td class="title font-weight-bold sitebluecolor" @click="SummaryExport(sumdata, 'stkaging',parseInt(sumdata.sumgt120stkaging),'120to')" style="cursor:pointer"> {{sumdata.sumgt120stkaging ? sumdata.sumgt120stkaging:'0'}} </td>
                                </tr>
                            </template>
                          </tbody>
                        </table>
                    </div>
 
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                        </div>
                    </div>
                </div>
            <div class="row" id="agingReportDiv">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                    <h4 class="card-title">
                      Business Task Aging Report
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div class="" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                               <div id="unique-id" style="display:none;">
                                  <div class="popover-body">
                                      <div class="popover_top_heading"> 
                                       <h2> Status Validation :</h2>
                                      <span class="text-card"> All BTC which are not in Live,Completed,OnHold,Discarded Status</span> 
                                      </div>
                                       <div class="popover_top_heading"> 
                                       <h2> Aging Calculation :</h2>
                                      <span class="text-card"> Createddate </span> 
                                      </div>

                                  </div>
                                </div>
                               <div id="business-agin" style="display:none;">
                                  <div class="popover-body">
                                      <div class="popover_top_heading"> 
                                       <h2> Status Validation :</h2>
                                      <span class="text-card"> BTC in Product Pipeline,Product WIP,Product WIP2</span> 
                                      </div>
                                      <div class="popover_top_heading"> 
                                       <h2> Aging Calculation :</h2>
                                      <span class="text-card"> Createddate</span> 
                                      </div>

                                  </div>
                                </div>
                               <div id="tech-aging" style="display:none;">
                                  <div class="popover-body">
                                      <div class="popover_top_heading"> 
                                       <h2> Status Validation :</h2>
                                      <span class="text-card"> All BTC are in Tech Pipeline,Tech WIP,UAT Feedback,Tech Issue Resolution</span> 
                                      
                                      </div>
                                     <div class="popover_top_heading"> 
                                       <h2> Aging Calculation :</h2>
                                      <span class="text-card"> Tech Pipeline Date</span> 
                                      </div>

                                  </div>
                                </div>
                               <div id="qa-aging" style="display:none;">
                                  <div class="popover-body">
                                      <div class="popover_top_heading"> 
                                       <h2> Status Validation :</h2>
                                      <span class="text-card"> BTC in QA Pipeline,QA WIP</span> 
                                      
                                      </div>
                                     <div class="popover_top_heading"> 
                                       <h2> Aging Calculation :</h2>
                                      <span class="text-card"> QAPipelineDate</span> 
                                      </div>

                                  </div>
                                </div>
                                <div id="pmo-aging" style="display:none;">
                                  <div class="popover-body">
                                      <div class="popover_top_heading"> 
                                       <h2> Status Validation :</h2>
                                      <span class="text-card"> Product Completed,Tech & Business Review</span> 
                                      
                                      </div>
                                     <div class="popover_top_heading"> 
                                       <h2> Aging Calculation :</h2>
                                      <span class="text-card"> Product Completed Date</span> 
                                      </div>

                                  </div>
                                </div>
                                <div id="bs-aging" style="display:none;">
                                  <div class="popover-body">
                                      <div class="popover_top_heading"> 
                                       <h2> Status Validation :</h2>
                                      <span class="text-card"> UAT, UAT Sign Off</span> 
                                      
                                      </div>
                                     <div class="popover_top_heading"> 
                                       <h2> Aging Calculation :</h2>
                                      <span class="text-card"> UAT date</span> 
                                      </div>
                                  </div>
                                </div>

                              <div class="table-responsive_sitcky"  v-if="showtabledata">
                              <table class="table table-hover table-striped table-bordered table-sm" aria-describedby="Business Task Aging Report">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="align-middle">{{ (search.roleId=='hod'?'HOD':search.roleId==477?'Project Manager':search.roleId==503?'Program Manager':search.roleId==492?'Engineering Manager':search.roleId=='project'?'Project':search.roleId==131?'Task Manager':search.roleId==513?'Task Owner':'HOD') }}</th>
                                      <th scope="col" class="align-middle">Overall Aging
                                        <span class="popover-block-container-custom">
                                         <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#unique-id">
                                                <em class="las la-info-circle text-warning font-medium-5"></em>
                                            </button>
                                        </span>
                                      </th>
                                      <th scope="col" class="align-middle">PRODUCT/BA AGING
                                         <span class="popover-block-container-custom">
                                         <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#business-agin">
                                                <em class="las la-info-circle text-warning font-medium-5"></em>
                                            </button>
                                        </span>
                                      </th>
                                      <th scope="col" class="align-middle">PMO Aging
                                        <span class="popover-block-container-custom">
                                         <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#pmo-aging">
                                                <em class="las la-info-circle text-warning font-medium-5"></em>
                                            </button>
                                        </span>
                                      </th>
                                      <th scope="col" class="align-middle">TECH Aging
                                        <span class="popover-block-container-custom">
                                         <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#tech-aging">
                                                <em class="las la-info-circle text-warning font-medium-5"></em>
                                            </button>
                                        </span>
                                      </th>
                                      <th scope="col" class="align-middle">QA Aging
                                        <span class="popover-block-container-custom">
                                         <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#qa-aging">
                                                <em class="las la-info-circle text-warning font-medium-5"></em>
                                            </button>
                                        </span>
                                      </th>
                                      <th scope="col" class="align-middle">Design Review Aging
                                        <span class="popover-block-container-custom">
                                         <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#qa-aging">
                                                <em class="las la-info-circle text-warning font-medium-5"></em>
                                            </button>
                                        </span>
                                      </th>
                                      <th scope="col" class="align-middle">Business Stakeholder Aging
                                        <span class="popover-block-container-custom">
                                         <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#bs-aging">
                                                <em class="las la-info-circle text-warning font-medium-5"></em>
                                            </button>
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(ardata, index) in agingReportList">
                                          <tr v-bind:key="ardata.empid+Math.floor(Math.random() * 1000000000)">
                                              <td  :class="'uppercasetext unique'+index+1+currPageNum*pagelimit" style="text-align:center; background-color:#F3F2F7;">
                                                  <strong>{{ardata.businessstackholder}}</strong>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td class="title"> 0 - 5 Days </td> 
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'overallaging',parseInt(ardata.lessthan5overallaging),'0to5')" style="cursor:pointer"> {{ardata.lessthan5overallaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'businessaging',parseInt(ardata.lt5bsaging),'0to5')" style="cursor:pointer"> {{ardata.lt5bsaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'pmoaging',parseInt(ardata.lt5pmoaging),'0to5')" style="cursor:pointer"> {{ardata.lt5pmoaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'techaging',parseInt(ardata.lessthan5techaging),'0to5')" style="cursor:pointer"> {{ardata.lessthan5techaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'qaaging',parseInt(ardata.lt5qaaging),'0to5')" style="cursor:pointer"> {{ardata.lt5qaaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'desingaging',parseInt(ardata.lt5desingaging),'0to5')" style="cursor:pointer"> {{ardata.lt5desingaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'stkaging',parseInt(ardata.lt5stkaging),'0to5')" style="cursor:pointer"> {{ardata.lt5stkaging}} </td>
                                          </tr>
                                          <tr>
                                              <td> 6 - 10 Days </td> 
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'overallaging',parseInt(ardata.lessthan10overallaging),'6to10')" style="cursor:pointer"> {{ardata.lessthan10overallaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'businessaging',parseInt(ardata.lt10bsaging),'6to10')" style="cursor:pointer"> {{ardata.lt10bsaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'pmoaging',parseInt(ardata.lt10pmoaging),'6to10')" style="cursor:pointer"> {{ardata.lt10pmoaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'techaging',parseInt(ardata.lessthan10techaging),'6to10')" style="cursor:pointer"> {{ardata.lessthan10techaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'qaaging',parseInt(ardata.lt10qaaging),'6to10')" style="cursor:pointer"> {{ardata.lt10qaaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'desingaging',parseInt(ardata.lt10desingaging),'6to10')" style="cursor:pointer"> {{ardata.lt10desingaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'stkaging',parseInt(ardata.lt10stkaging),'6to10')" style="cursor:pointer"> {{ardata.lt10stkaging}} </td>
                                          </tr>
                                          <tr>
                                              <td> 11 - 30 Days </td> 
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'overallaging',parseInt(ardata.lessthan30overallaging),'11to30')" style="cursor:pointer"> {{ardata.lessthan30overallaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'businessaging',parseInt(ardata.lt30bsaging),'11to30')" style="cursor:pointer"> {{ardata.lt30bsaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'pmoaging',parseInt(ardata.lt30pmoaging),'11to30')" style="cursor:pointer"> {{ardata.lt30pmoaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'techaging',parseInt(ardata.lessthan30techaging),'11to30')" style="cursor:pointer"> {{ardata.lessthan30techaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'qaaging',parseInt(ardata.lt30qaaging),'11to30')" style="cursor:pointer"> {{ardata.lt30qaaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'desingaging',parseInt(ardata.lt30desingaging),'11to30')" style="cursor:pointer"> {{ardata.lt30desingaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'stkaging',parseInt(ardata.lt30stkaging),'11to30')" style="cursor:pointer"> {{ardata.lt30stkaging}} </td>
                                          </tr>
                                          <tr>
                                              <td> 31 - 60 Days</td> 
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'overallaging',parseInt(ardata.greaterthan30overallaging),'31to60')" style="cursor:pointer"> {{ardata.greaterthan30overallaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'businessaging',parseInt(ardata.gt30bsaging),'31to60')" style="cursor:pointer"> {{ardata.gt30bsaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'pmoaging',parseInt(ardata.gt30pmoaging),'31to60')" style="cursor:pointer"> {{ardata.gt30pmoaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'techaging',parseInt(ardata.greaterthan30techaging),'31to60')" style="cursor:pointer"> {{ardata.greaterthan30techaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'qaaging',parseInt(ardata.gt30qaaging),'31to60')" style="cursor:pointer"> {{ardata.gt30qaaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'desingaging',parseInt(ardata.gt30desingaging),'31to60')" style="cursor:pointer"> {{ardata.gt30desingaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'stkaging',parseInt(ardata.gt30stkaging),'31to60')" style="cursor:pointer"> {{ardata.gt30stkaging}} </td>
                                          </tr>
                                          <tr>
                                              <td> 61 - 90 Days</td> 
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'overallaging',parseInt(ardata.greaterthan60overallaging),'61to90')" style="cursor:pointer"> {{ardata.greaterthan60overallaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'businessaging',parseInt(ardata.gt60bsaging),'61to90')" style="cursor:pointer"> {{ardata.gt60bsaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'pmoaging',parseInt(ardata.gt60pmoaging),'61to90')" style="cursor:pointer"> {{ardata.gt60pmoaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'techaging',parseInt(ardata.greaterthan60techaging),'61to90')" style="cursor:pointer"> {{ardata.greaterthan60techaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'qaaging',parseInt(ardata.gt60qaaging),'61to90')" style="cursor:pointer"> {{ardata.gt60qaaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'desingaging',parseInt(ardata.gt60desingaging),'61to90')" style="cursor:pointer"> {{ardata.gt60desingaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'stkaging',parseInt(ardata.gt60stkaging),'61to90')" style="cursor:pointer"> {{ardata.gt60stkaging}} </td>

                                          </tr>
                                          <tr>
                                              <td> 91 - 120 Days</td> 
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'overallaging',parseInt(ardata.greatethan90overallaging),'91to120')" style="cursor:pointer"> {{ardata.greatethan90overallaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'businessaging',parseInt(ardata.gt90bsaging),'91to120')" style="cursor:pointer"> {{ardata.gt90bsaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'pmoaging',parseInt(ardata.gt90pmoaging),'91to120')" style="cursor:pointer"> {{ardata.gt90pmoaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'techaging',parseInt(ardata.greatethan90techaging),'91to120')" style="cursor:pointer"> {{ardata.greatethan90techaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'qaaging',parseInt(ardata.gt90qaaging),'91to120')" style="cursor:pointer"> {{ardata.gt90qaaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'desingaging',parseInt(ardata.gt90desingaging),'91to120')" style="cursor:pointer"> {{ardata.gt90desingaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'stkaging',parseInt(ardata.gt90stkaging),'91to120')" style="cursor:pointer"> {{ardata.gt90stkaging}} </td>
                                          </tr>

                                          <tr>
                                              <td> 120 Days Above</td> 
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'overallaging',parseInt(ardata.greaterthan120overallaging),'120to')" style="cursor:pointer"> {{ardata.greaterthan120overallaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'businessaging',parseInt(ardata.gt120bsaging),'120to')" style="cursor:pointer"> {{ardata.gt120bsaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'pmoaging',parseInt(ardata.gt120pmoaging),'120to')" style="cursor:pointer"> {{ardata.gt120pmoaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'techaging',parseInt(ardata.greaterthan120techaging),'120to')" style="cursor:pointer"> {{ardata.greaterthan120techaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'qaaging',parseInt(ardata.gt120qaaging),'120to')" style="cursor:pointer"> {{ardata.gt120qaaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'desingaging',parseInt(ardata.gt120desingaging),'120to')" style="cursor:pointer"> {{ardata.gt120desingaging}} </td>
                                              <td class="font-weight-bold sitebluecolor" @click="openModal(ardata, 'stkaging',parseInt(ardata.gt120stkaging),'120to')" style="cursor:pointer"> {{ardata.gt120stkaging}} </td>
                                          </tr>
                                    </template>
                                    <template v-if="agingReportList.length == 0" >
                                          <tr>
                                            <td colspan="14" style="text-align: center;">No record found</td>
                                          </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2">
                        <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade " id="imagemodal" tabindex="2" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-xl modal-dialog-centered " >
                <div class="modal-content">              
                    <div class="modal-body p-0 ">
                      <button type="button" class="modal-video-close-btn modal-video-close-btn2" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                    </div>
                    <div class="modal-body p-2 mt-0">
                        <div class=" row mt-1">
                          <div class="col-8 col-sm-8 col-md-8"> 
                            <span v-if="tasklist.length==0">No record found</span>
                            <p  style="display: inline-block;float: left;font-size: 18px;font-weight: 600;padding-top: 8px;" v-else> {{tasklist.length}} {{" "}} Records Of {{(search.roleId=='hod'?'HOD':search.roleId==477?'Project Manager':search.roleId==503?'Program Manager':search.roleId==492?'Engineering Manager':search.roleId=='project'?'Project':search.roleId==131?'Task Manager':search.roleId==513?'Task Owner':'HOD')}}
                            {{typename=='overallaging'?'Overall Aging':typename=='businessaging'?'Business Aging':typename=='pmoaging'?'PMO Aging':typename=='techaging'?'Tech Aging':typename=='qaaging'?'QA Aging':typename=='stkaging'?'BUSINESS STAKEHOLDER AGING':'Overall Aging'}}</p>
                            <div class="col-sm-2 text-left">
                          </div>
                          </div>
                          <div class="ml-auto mx-1">
                           <button class="btn avatar bg-primary rounded business_mar_view-margin-2 text-white mb-1 ml-2" @click="ExportXL2(tasklist)"> <external-link-icon size="1.5x" class="custom-class font-medium-3 text-white"></external-link-icon> <span> Export</span></button>                          
                          </div>
                        </div>
                      <div class="card border-warning" id="document-list">
                        <div class="card-body p-0 ps ps--active-x">
                          <div class="table-responsive" >
                            <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
                              <thead>
                                <tr>
                                  <th scope="col" class="text-center first-col-sticky" style="min-width:40px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
                                  <th scope="col" class="text-center second-col-sticky" style="max-width: 10%;">BUSINESS TASK DETAILS</th>
                                  <th scope="col" class="text-center">Owner Details</th>
                                  <th scope="col" class="text-center" style="max-width: 20%;">Task Manager Details</th>
                                  <th scope="col" class="text-center" style="max-width: 20%;">Project Manager Details</th>
                                  <th scope="col" class="text-center">QA Details</th>
                                  <th scope="col" class="text-center">Date Details</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="(data,index) in tasklist"  >
                                  <tr v-bind:key="index" >
                                    <td class="text-center text-capitalize first-col-sticky">{{ index+1 }}</td>
                                    <td class="text-normal details_column_table second-col-sticky" style="max-width:20%;">
                                        <span> <strong>Code : </strong> </span>
                                        <span> {{data.businesstaskcode}}</span>
                                        <hr class="hr_border-bottom" />
                                        <div v-if="data.taskkey.toLowerCase()=='key' || data.taskkey.toLowerCase()=='urgent'" class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.taskkey}}</div>
                                        <div v-if="data.taskkey.toLowerCase()=='major'" class="badge badge-pill badge-light-warning mr-50 capitalisetext">{{data.taskkey}}</div>
                                        <div v-if="data.taskkey.toLowerCase()=='minor'" class="badge badge-pill badge-light-primary mr-50 capitalisetext">{{data.taskkey}}</div>
                                        <div v-if="data.taskkey.toLowerCase()=='cosmetic'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.taskkey}}</div>
                                        <div v-if="data.taskkey.toLowerCase()=='normal'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.taskkey}}</div>
                                        <div v-if="(data.isapproved_edd && data.isapproved_edd == 'Pending')" class="badge badge-pill badge-light-warning mr-50 capitalisetext">Pending</div>
                                        <div class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.taskstatus}}</div>
                                        <hr class="hr_border-bottom" />
                                        <span class="capitalisetext">
                                        <span> <strong>Name : </strong> </span>
                                        <span class="font-weight-bold sitebluecolor"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer;" v-html="data.taskname.substring(0,17)+'..'"></span>
                                        <hr class="hr_border-bottom" />
                                        <span> <strong>Project Name : </strong> </span>
                                        <span class="font-weight-bold text-nowrap" href="javascript:void(0);" style="cursor: pointer;" v-html="data.projectname.substring(0,50)+'..'"></span>
                                      </span>
                                    </td>
                                    <td class="details_column_table">
                                      <span class="heading_column_text text-capitalize"> HOD Name	: </span>
                                      {{ data.businessstackholder?data.businessstackholder:'NA' }}
                                      <hr class="hr_border-bottom" />
                                      <span class="heading_column_text text-capitalize"> Task Owner(Lead) : </span>
                                      {{ data.taskowner?data.taskowner:'NA' }}
                                    </td>
                                    <td class="details_column_table">
                                      <span class="heading_column_text text-capitalize"> Task Manager : </span>
                                      {{ data.productstackholder?data.productstackholder:'NA' }}
                                      <hr class="hr_border-bottom" />
                                      <span class="heading_column_text text-capitalize"> Program Manager : </span>
                                      {{ data.programmanager?data.programmanager:'NA' }}
                                    </td>
                                    <td class="details_column_table">
                                      <span class="heading_column_text text-capitalize"> Project Manager : </span>
                                      {{ data.projectmaanager?data.projectmaanager:'NA' }}
                                      <hr class="hr_border-bottom" />
                                      <span class="heading_column_text text-capitalize"> Engineering Manager : </span>
                                      {{ data.engineeringmaanager?data.engineeringmaanager:'NA' }}
                                    </td>
                                    <td class="details_column_table">
                                      <span class="heading_column_text text-capitalize"> QA Manager	: </span>
                                      {{ data.qamanager?data.qamanager:'NA' }}
                                      <hr class="hr_border-bottom" />
                                      <span class="heading_column_text text-capitalize"> QA Lead : </span>
                                      {{ data.qalead?data.qalead:'NA' }}
                                    </td>
                                    <td class="details_column_table">
                                      <span class="heading_column_text text-capitalize"> CREATED BY : </span>
                                      {{data.createdby.split('@')[0].split('.').join(' ')}}
                                      <hr class="hr_border-bottom" />
                                      <span class="heading_column_text">
                                        {{typename=='pmoaging'?'PRODUCT COMPLETED DATE':typename=='techaging'?'TECH PIPELINE DATE':typename=='qaaging'?'QA PIPELINE DATE':typename=='stkaging'?'UAT DATE':'DATE'}} : </span>

                                      <span v-if="typename=='pmoaging'"> {{dateMonthFormat2(data.productcompleteddt)}} </span>
                                      <span v-else-if="typename=='techaging'"> {{dateMonthFormat2(data.techpipelinedate)}} </span>
                                      <span v-else-if="typename=='qaaging'"> {{dateMonthFormat2(data.qapipelinedt)}} </span>
                                      <span v-else-if="typename=='stkaging'"> {{dateMonthFormat2(data.uatdate)}} </span>
                                      <span v-else> NA </span>
                                      <hr class="hr_border-bottom" />
                                      <span class="heading_column_text full_100"> CREATED DATE : </span>
                                      {{dateMonthFormat2(data.createddate)}}
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
.modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
  }
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { EyeIcon,SearchIcon,XIcon,ExternalLinkIcon,DownloadIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';
export default {
  name: "AgingBTReport",
  components: {
    Loading,
    VueElementLoading,DownloadIcon,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
  },
  mixins: [ commonMethods ],

  data() {
    return {
      search:{
          Empname:null,
          searchTaskseverity :null,
          taskseverityopts :null,
          priorityopts :null,
          searchTasktype : ['Business Task'],
          tasktypeopts :null,
          bsh: null,
          roleId: 'hod',
          sheettype : 'realtime',
          project: null
      },
      managersList: [{ 
          id: 'hod',
          label: 'HOD',
        },
        {
          id : 477,
          label: 'Project Manager',
        },
        {
          id : 503,
          label: 'Program Manager',
        },
        {
          id : 492,
          label: 'Engineering Manager',
        },
        {
          id : 'project',
          label: 'Project',
        },
        {
          id : 131,
          label: 'Task Manager',
        },
        {
          id : 513,
          label: 'Task Owner',
        },
        ],
      projectnameList:[],
      createdbyOpts:[],
      typelist:apiUrl.typelist.sort((a, b) => a.label.localeCompare(b.label)),
      keylist:apiUrl.keylist.sort((a, b) => a.label.localeCompare(b.label)),
      isLoading: false,
      fullPage: true,
      islistLoading: false,
      listfullPage: true,
      agingReportList: [],
      agingReportSummaryList: [],
      totalcountList:[],
      stylevariation: apiUrl.stylevariation,
      showprojectdata: [],
      showroledata: [],
      showempdata: [],      
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      bshlist : [],
      projectopt:[],
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      currcalltype: "CAMP",
      allapicallauthkey: "",
      prevtabRowData: [],
      projecttabRowData: [],
      roleassitabRowData: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      activecompanyid: "",
      disablecompanydd: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      currentmodule:'projectmaster',
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      emplist:[],
      tokendata:null,
      speedChartList: [],
      sheettype : 'realtime',
      userrole:null,
      tasklist: [],
      typename: null,
      isBTSummaryActive: false
    };
  },
  created() {
    this.pagefeatures = {}
    if(window.localStorage.getItem('pagefeatures')){
      let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
      pagefeatures1.forEach(element => {
        if (element.page === '/reports/agingreport') {
          this.pagefeatures[element.featurename] = element.featureaccess
        }
      })
    }
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem('token');
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        let rowdata={}
        this.userrole = window.localStorage.getItem("userrole")
        if(this.pagefeatures.hodroleprojectaging){
          this.search.roleId='project'
          rowdata.roleId='project'
        }
        this.getBSHlist(null);
        this.getAgingReportRecords(rowdata,0);
        // this.getAgingReportRecordsSummary(rowdata)
        this.managersList.sort((a, b) => a.label.localeCompare(b.label));
      }
    }
    $("[data-toggle=popover]").popover({
      html: true,
      trigger: "focus",
      content: function () {
        let content = $(this).attr("data-popover-content");
        return $(content).children(".popover-body").html();
      }
    });
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    companyCollapse: function () {
      this.showcompanies = !this.showcompanies;
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    selectproject(state, value) {
      this.pageoffset=1
      this.search.searchProjectname = state;
    }, 
    resetRecords: function () {
      let rowdata={}
      this.search.bsh=null
      this.search.searchTaskseverity=null
      this.search.searchTasktype=null
      this.search.roleId='hod'
      if(this.pagefeatures.hodroleprojectaging){
        this.search.roleId='project'
        rowdata.roleId='project'
      }
      this.search.project = null
      this.getAgingReportRecords(rowdata,0);
      this.search.empname = null;
    },
    BTAgingSummaryCollapse:function(rowdata){
        this.isBTSummaryActive=!this.isBTSummaryActive
        if(this.isBTSummaryActive==true)
        {
          this.getAgingReportRecordsSummary(rowdata)
        }
    },
    getProjectAllocatedToEmp() {
        this.isLoading = true
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers': { 'authorization': this.tokendata }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
              this.projectopt = result.data.data;
          } else {
              this.projectopt = [];
          }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getAgingReportRecords(rowdata,flag) {
      this.islistLoading = true; 
      this.parentmoduledata = rowdata;   
      if(flag==1){
        this.page=1
      }
      let url = "api/master/report/getAgingBTReport";
      this.input = {
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        type: rowdata.searchTasktype?rowdata.searchTasktype: this.search.searchTasktype,
        severity:rowdata.searchTaskseverity?rowdata.searchTaskseverity:null,
        bsh: rowdata.bsh?rowdata.bsh:null,
        tracker: rowdata.roleId?String(rowdata.roleId): "hod",
        estimate: null,
        parr: this.search.project?this.search.project:null,
        empcode: this.undt.userid,
        userrole:this.userrole
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.status == true) {
          this.agingReportList = result.data.data.rows;
          this.totalcount = result.data.data.count
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          // this.getAgingReportRecordsSummary(rowdata)
          if(this.isBTSummaryActive==true){
            this.getAgingReportRecordsSummary(rowdata)
          }
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.agingReportList = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        }
      }).catch(e => {
        this.displayError(e)
      });
    },

    getAgingReportRecordsSummary(rowdata) {
      this.islistLoading = true; 
      this.parentmoduledata = rowdata;   
      let url = "api/master/report/getAgingBTReportSummary";
      this.input = {
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        type: rowdata.searchTasktype?rowdata.searchTasktype: this.search.searchTasktype,
        severity:rowdata.searchTaskseverity?rowdata.searchTaskseverity:null,
        bsh: rowdata.bsh?rowdata.bsh:null,
        tracker: rowdata.roleId?String(rowdata.roleId): "hod",
        estimate: null,
        parr: this.search.project?this.search.project:null,
        empcode: this.undt.userid,
        userrole:this.userrole
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.status == true) {
          this.agingReportSummaryList = result.data.data;
        } else {
          this.agingReportSummaryList = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },

    getBSHlist(dept) {
      if(dept==null || dept=='hod')
      {
          if(dept=='hod'){ dept = null}
          this.search.project = null
          this.search.bsh=null
          axios({
              method: "POST",
              url: "api/listing/getHODlist",
              data: {dept,useremail: this.undt.username,empcode: this.undt.userid},
              headers: { authorization : this.tokendata }
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                  this.bshlist = result.data.data.rows
              } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                  this.bshlist = [];
              }
          }).catch(e => {
        this.displayError(e)
      });
      }else if(dept=='project'){
        this.search.bsh=null
        this.getProjectAllocatedToEmp()
      }
      else{
        this.search.project = null
        this.search.bsh=null
          axios({
            method: "POST",
            url: "api/listing/getStakeHoldersList",
            data: { roleid: dept,useremail: this.undt.username,empcode: this.undt.userid, },
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              
              this.bshlist = result.data.data;
            } else {
              this.bshlist = [];
            }
          }).catch(e => {
        this.displayError(e)
      });
      }
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.getAgingReportRecords(this.parentmoduledata);
      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#agingReportDiv").offset().top -70
            });
        }, 500);

    },
    setSelected(value) {
      this.selected = value;
    },
    dateMonth(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM');
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    openModal(data, type, value, daysfor) {
      if (value==0) {
        return
      }
      this.isLoading = true
      this.typename=type
      let businessstackholders=null
      if (data.businessstackholder == data.businessstackholder.toUpperCase()){
        businessstackholders=data.businessstackholder.toLowerCase()
      }else{
        businessstackholders=data.businessstackholder
      }
      let url = 'api/reports/getagingDelayedBTaskList'
      let payload = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        businessstackholder: businessstackholders,
        typename:type,
        daysfor:daysfor,
        tracker:this.search.roleId?String(this.search.roleId): 'hod',
        tasktype: this.search.searchTasktype?this.search.searchTasktype: null,
        severity:this.search.searchTaskseverity?this.search.searchTaskseverity:null,
        parr: data.project?data.project:null,
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.status) {
          this.tasklist = result.data.data.rows
          this.tasklist.typename=this.typename
          this.tasklist.daysfor=daysfor
          this.tasklist.parr=data.project
          this.tasklist.businessstackholdername=businessstackholders
        }else if(result.data.errorCode == 3){
          Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
              }).then(function() {
                  window.location.href = "/#/login";
              })
        }else {
          this.tasklist = []
        }
      }).catch(e => {
        this.tasklist = []
        Swal.fire({
          title: "",
          text: e,
          icon: "info",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: !1,
        });
      })
      $('#imagemodal').modal('toggle');
    },
    closeModal() {
      $('#imagemodal').modal('toggle');
    },
    displayStrings(givenstring) {
      if(!givenstring) {
        return 'NA'
      }
      if(givenstring.length > 130) {
        return givenstring.substring(0,130) + '...'
      }
      return givenstring
    },
    dateMonthFormat(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY');
    },
    dateMonthFormat2(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    redirecttourl(btnstate,rowdata) {
      rowdata.backbtnstate=btnstate
        let routeData = this.$router.resolve({ 
        path: '/businesstask/view?businessid='+rowdata.businesstaskmasterid,
        params: {
          backbtnstate:btnstate,
          clickeddata:rowdata
        }
      })
      window.open(routeData.href, '_blank');
    },
    ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit:null,
        offset:null,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        type: this.search.searchTasktype?this.search.searchTasktype: null,
        severity:this.search.searchTaskseverity?this.search.searchTaskseverity:null,
        bsh: this.search.bsh?this.search.bsh:null,
        tracker: this.search.roleId?String(this.search.roleId): 'hod',
        estimate: null,
        parr: this.search.project?this.search.project:null,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: `api/master/report/getAgingBTReport`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = ""
          this.download(result.data.data.rows,filename)
        }  else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          }else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        
        if (result.data.status) {
          this.isDetActive = true;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          var ShowLabel = 'exportreport_'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
            if (ShowLabel) {
                let row = ''
                for (let index in arrData[0]) {
                    row += index + ','
                }
              row = row.slice(0, -1)
              CSV = 'HOD,';
              CSV += 'OVERALL AGING,';
              CSV += 'BUSINESS AGING,';
              CSV += 'PMO AGING,';
              CSV += 'TECH AGING,';
              CSV += 'QA AGING,';
              CSV += 'DESIGN REVIEW AGING,';
              CSV += 'BUSINESS STAKEHOLDER AGING,';
              CSV += '\r\n';
            }
            var row = ''
              for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1
                let businessstackholder = index.hasOwnProperty('businessstackholder') ? index.businessstackholder : '';
                let lessthan5overallaging = index.hasOwnProperty('lessthan5overallaging') ? index.lessthan5overallaging : '';
                let lessthan5techaging = index.hasOwnProperty('lessthan5techaging') ? index.lessthan5techaging : '';
                let lessthan10overallaging = index.hasOwnProperty('lessthan10overallaging') ? index.lessthan10overallaging : '';
                let lessthan10techaging = index.hasOwnProperty('lessthan10techaging') ? index.lessthan10techaging : '';
                let lessthan30overallaging = index.hasOwnProperty('lessthan30overallaging') ? index.lessthan30overallaging : '';
                let lessthan30techaging = index.hasOwnProperty('lessthan30techaging') ? index.lessthan30techaging : '';
                let greaterthan30overallaging = index.hasOwnProperty('greaterthan30overallaging') ? index.greaterthan30overallaging : '';
                let greaterthan30techaging = index.hasOwnProperty('greaterthan30techaging') ? index.greaterthan30techaging : '';
                let greaterthan60overallaging = index.hasOwnProperty('greaterthan60overallaging') ? index.greaterthan60overallaging : '';
                let greaterthan60techaging = index.hasOwnProperty('greaterthan60techaging') ? index.greaterthan60techaging : '';
                let greatethan90overallaging = index.hasOwnProperty('greatethan90overallaging') ? index.greatethan90overallaging : '';
                let greatethan90techaging = index.hasOwnProperty('greatethan90techaging') ? index.greatethan90techaging : '';
                let greaterthan120overallaging = index.hasOwnProperty('greaterthan120overallaging') ? index.greaterthan120overallaging : '';
                let greaterthan120techaging = index.hasOwnProperty('greaterthan120techaging') ? index.greaterthan120techaging : '';

                let lt5bsaging = index.hasOwnProperty('lt5bsaging') ? index.lt5bsaging : '';
                let lt10bsaging = index.hasOwnProperty('lt10bsaging') ? index.lt10bsaging : '';
                let lt30bsaging = index.hasOwnProperty('lt30bsaging') ? index.lt30bsaging : '';
                let gt30bsaging = index.hasOwnProperty('gt30bsaging') ? index.gt30bsaging : '';
                let gt60bsaging = index.hasOwnProperty('gt60bsaging') ? index.gt60bsaging : '';
                let gt90bsaging = index.hasOwnProperty('gt90bsaging') ? index.gt90bsaging : '';
                let gt120bsaging = index.hasOwnProperty('gt120bsaging') ? index.gt120bsaging : '';

                let lt5qaaging = index.hasOwnProperty('lt5qaaging') ? index.lt5qaaging : '';
                let lt10qaaging = index.hasOwnProperty('lt10qaaging') ? index.lt10qaaging : '';
                let lt30qaaging = index.hasOwnProperty('lt30qaaging') ? index.lt30qaaging : '';
                let gt30qaaging = index.hasOwnProperty('gt30qaaging') ? index.gt30qaaging : '';
                let gt60qaaging = index.hasOwnProperty('gt60qaaging') ? index.gt60qaaging : '';
                let gt90qaaging = index.hasOwnProperty('gt90qaaging') ? index.gt90qaaging : '';
                let gt120qaaging = index.hasOwnProperty('gt120qaaging') ? index.gt120qaaging : '';

                let lt5pmoaging = index.hasOwnProperty('lt5pmoaging') ? index.lt5pmoaging : '';
                let lt10pmoaging = index.hasOwnProperty('lt10pmoaging') ? index.lt10pmoaging : '';
                let lt30pmoaging = index.hasOwnProperty('lt30pmoaging') ? index.lt30pmoaging : '';
                let gt30pmoaging = index.hasOwnProperty('gt30pmoaging') ? index.gt30pmoaging : '';
                let gt60pmoaging = index.hasOwnProperty('gt60pmoaging') ? index.gt60pmoaging : '';
                let gt90pmoaging = index.hasOwnProperty('gt90pmoaging') ? index.gt90pmoaging : '';
                let gt120pmoaging = index.hasOwnProperty('gt120pmoaging') ? index.gt120pmoaging : '';

                let lt5stkaging = index.hasOwnProperty('lt5stkaging') ? index.lt5stkaging : '';
                let lt10stkaging = index.hasOwnProperty('lt10stkaging') ? index.lt10stkaging : '';
                let lt30stkaging = index.hasOwnProperty('lt30stkaging') ? index.lt30stkaging : '';
                let gt30stkaging = index.hasOwnProperty('gt30stkaging') ? index.gt30stkaging : '';
                let gt60stkaging = index.hasOwnProperty('gt60stkaging') ? index.gt60stkaging : '';
                let gt90stkaging = index.hasOwnProperty('gt90stkaging') ? index.gt90stkaging : '';
                let gt120stkaging = index.hasOwnProperty('gt120stkaging') ? index.gt120stkaging : '';

                let lt5desingaging = index.hasOwnProperty('lt5desingaging') ? index.lt5desingaging : '';
                let lt10desingaging = index.hasOwnProperty('lt10desingaging') ? index.lt10desingaging : '';
                let lt30desingaging = index.hasOwnProperty('lt30desingaging') ? index.lt30desingaging : '';
                let gt30desingaging = index.hasOwnProperty('gt30desingaging') ? index.gt30desingaging : '';
                let gt60desingaging = index.hasOwnProperty('gt60desingaging') ? index.gt60desingaging : ''; 
                let gt90desingaging = index.hasOwnProperty('gt90desingaging') ? index.gt90desingaging : '';
                let gt120desingaging = index.hasOwnProperty('gt120desingaging') ? index.gt120desingaging : '';

                                
                row += '"' + businessstackholder + '",',
                row += '\r\n'
                row += '"0 to 5 Days",',
                row += '"' + lessthan5overallaging + '",',
                row += '"' + lt5bsaging + '",',
                row += '"' + lt5pmoaging + '",',
                row += '"' + lessthan5techaging + '",',
                row += '"' + lt5qaaging + '",',
                row += '"' + lt5desingaging + '",',
                row += '"' + lt5stkaging + '",',
                row += '\r\n'
                
                row += '"6 - 10 Days",',
                row += '"' + lessthan10overallaging + '",',
                row += '"' + lt10bsaging + '",',
                row += '"' + lt10pmoaging + '",',
                row += '"' + lessthan10techaging + '",',
                row += '"' + lt10qaaging + '",',
                row += '"' + lt10desingaging + '",',
                row += '"' + lt10stkaging + '",',
                row += '\r\n'
                
                row += '"11 - 30 Days",',
                row += '"' + lessthan30overallaging + '",',
                row += '"' + lt30bsaging + '",',
                row += '"' + lt30pmoaging + '",',
                row += '"' + lessthan30techaging + '",',
                row += '"' + lt30qaaging + '",',
                row += '"' + lt30desingaging + '",',
                row += '"' + lt30stkaging + '",',
                row += '\r\n'
                
                row += '"31 - 60 Days",',
                row += '"' + greaterthan30overallaging + '",',
                row += '"' + gt30bsaging + '",',
                row += '"' + gt30pmoaging + '",',
                row += '"' + greaterthan30techaging + '",',
                row += '"' + gt30qaaging + '",',
                row += '"' + gt30desingaging + '",',
                row += '"' + gt30stkaging + '",',
                row += '\r\n'

                row += '"61 - 90 Days",',
                row += '"' + greaterthan60overallaging + '",',
                row += '"' + gt60bsaging + '",',
                row += '"' + gt60pmoaging + '",',
                row += '"' + greaterthan60techaging + '",',
                row += '"' + gt60qaaging + '",',
                row += '"' + gt60desingaging + '",',
                row += '"' + gt60stkaging + '",',
                row += '\r\n'

                row += '"91 - 120 Days",',
                row += '"' + greatethan90overallaging + '",',
                row += '"' + gt90bsaging + '",',
                row += '"' + gt90pmoaging + '",',
                row += '"' + greatethan90techaging + '",',
                row += '"' + gt90qaaging + '",',
                row += '"' + gt90desingaging + '",',
                row += '"' + gt90stkaging + '",',
                row += '\r\n'

                row += '"120 Days Above",',
                row += '"' + greaterthan120overallaging + '",',
                row += '"' + gt120bsaging + '",',
                row += '"' + gt120pmoaging + '",',
                row += '"' + greaterthan120techaging + '",',
                row += '"' + gt120qaaging + '",',
                row += '"' + gt120desingaging + '",',
                row += '"' + gt120stkaging + '",',
                row += '\r\n'
            
              }
            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              return;
            }
            var fileName = 'Aging_Report_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            var link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
              title: "Success!",
              text: "Export Report successful",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });  
        } 
    },
    export (data, filename, mime) {
          var data
          let blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          })
          download(blob, filename, mime)
        },
        ExportXL2(taskdatas){
            this.isLoading = true;
            this.exportxlsdata2 = [];
            this.exporterr2 = "";
            this.input = {
                useremail:this.undt.username,
                empcode: this.undt.userid,
                businessstackholder: taskdatas.businessstackholdername,
                typename:taskdatas.typename,
                daysfor:taskdatas.daysfor,
                tracker:this.search.roleId?String(this.search.roleId): 'hod',
                tasktype: this.search.searchTasktype?this.search.searchTasktype: null,
                severity:this.search.searchTaskseverity?this.search.searchTaskseverity:null,
                parr: taskdatas.parr?parseInt(taskdatas.parr):null,
            };
            axios({
                method: "POST",
                url: `api/reports/getagingDelayedBTaskList`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata2 = result.data.data.rows;
                    var filename = taskdatas.businessstackholdername+taskdatas.daysfor+taskdatas.typename+'report';
                    this.download2(result.data.data.rows,filename)
                } else if(result.data.errorCode == 3) {
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                        }).then(function() {
                        window.location.href = "/#/login";
                        })
                } else {
                    Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    });
                }
            }).catch(e => {
            this.displayError(e)
          })
        },
        download2(readExportdata,filename) {
          if (readExportdata.length > 0) {
            var ShowLabel = filename
            var JSONData = readExportdata
            var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            var CSV = ''
            if (ShowLabel) {
            let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
              row = row.slice(0, -1)
              CSV = 'Sr#,Project Name,Business Task Code,Business Task Name,HOD Name,Engineering Manager,Project Manager,Program Manager,Task Manager,Task Owner (Lead),QA Manager,QA Lead,Planner Name,Status,Created By,Created Date,Product Completed Date,Tech Pipeline Date,QA Pipeline Date,UAT Date' + '\r\n'
            }
            var row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let businesstaskcode = index.hasOwnProperty('businesstaskcode') ? index.businesstaskcode ==null || index.businesstaskcode.toLowerCase() =='na' ? '' : index.businesstaskcode : '';
            let taskname = index.hasOwnProperty('taskname') ? index.taskname ==null || index.taskname.toLowerCase() =='na' ? '' : index.taskname : '';
            let businessstackholder = index.hasOwnProperty('businessstackholder') ? index.businessstackholder ==null || index.businessstackholder.toLowerCase() =='na' ? '' : index.businessstackholder : 'NA';
            let productstackholder = index.hasOwnProperty('productstackholder') ? index.productstackholder ==null || index.productstackholder.toLowerCase() =='na' ? 'NA' : index.productstackholder : 'NA';
            let taskowner = index.hasOwnProperty('taskowner') ? index.taskowner ==null || index.taskowner.toLowerCase() =='na' ? 'NA' : index.taskowner : 'NA';
            let projectmaanager = index.hasOwnProperty('projectmaanager') ? index.projectmaanager ==null || index.projectmaanager.toLowerCase() =='na' ? 'NA' : index.projectmaanager : 'NA';
            let programmanager = index.hasOwnProperty('programmanager') ? index.programmanager ==null || index.programmanager.toLowerCase() =='na' ? 'NA' : index.programmanager : 'NA';
            let engineeringmaanager = index.hasOwnProperty('engineeringmaanager') ? index.engineeringmaanager ==null || index.engineeringmaanager.toLowerCase() =='na' ? 'NA' : index.engineeringmaanager : 'NA';
            let qamanager = index.hasOwnProperty('qamanager') ? index.qamanager ==null || index.qamanager.toLowerCase() =='na' ? 'NA' : index.qamanager : 'NA';
            let qalead = index.hasOwnProperty('qalead') ? index.qalead ==null || index.qalead.toLowerCase() =='na' ? 'NA' : index.qalead : 'NA';
            let taskstatus = index.hasOwnProperty('taskstatus') ? index.taskstatus ==null || index.taskstatus.toLowerCase() =='na' ? '' : index.taskstatus : '';
            let createdby = index.hasOwnProperty('createdby') ? index.createdby : '';
            let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
            createddate = this.dateMonthFormat(createddate);
            let productcompleteddt = index.hasOwnProperty('productcompleteddt') ? index.productcompleteddt : '';
            productcompleteddt = productcompleteddt?this.dateMonthFormat(productcompleteddt):'NA';
            let techpipelinedate = index.hasOwnProperty('techpipelinedate') ? index.techpipelinedate : '';
            techpipelinedate = techpipelinedate?this.dateMonthFormat(techpipelinedate):'NA';
            let qapipelinedt = index.hasOwnProperty('qapipelinedt') ? index.qapipelinedt : '';
            qapipelinedt = qapipelinedt?this.dateMonthFormat(qapipelinedt):'NA';
            let uatdate = index.hasOwnProperty('uatdate') ? index.uatdate : '';
            uatdate = uatdate?this.dateMonthFormat(uatdate):'NA';
            let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname.toLowerCase() =='na' ? '' : index.projectname : '';
            let plannertitle = index.hasOwnProperty('plannertitle') ? index.plannertitle ==null ? 'NA' : index.plannertitle : 'NA';

              row += '"' + srno + '",',
              row += '"' + projectname + '",',
              row += '"' + businesstaskcode + '",',
              row += '"' + taskname + '",',
              row += '"' + businessstackholder + '",',
              row += '"' + engineeringmaanager + '",',
              row += '"' + projectmaanager + '",',
              row += '"' + programmanager + '",',
              row += '"' + productstackholder + '",',
              row += '"' + taskowner + '",',
              row += '"' + qamanager + '",',
              row += '"' + qalead + '",',
              row += (plannertitle==null || plannertitle=="") ? "NA," : '"' + plannertitle + '",'
              row += '"' + taskstatus + '",',
              row += '"' + createdby + '",',
              row += '"' + createddate + '",',
              row += '"' + productcompleteddt + '",',
              row += '"' + techpipelinedate + '",',
              row += '"' + qapipelinedt + '",',
              row += '"' + uatdate + '",',
              row += '\r\n'
          }
          CSV += row
          if (CSV === '') {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
            return;
          }
          var fileName = filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
          var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
          var link = document.createElement('a')
          link.href = uri
          link.style = 'visibility:hidden'
          link.download = fileName + '.csv'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          Swal.fire({
            title: "Success!",
            text: "Export Report Successful",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          } else {
              Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }
        },

        SummaryExport(data,type,value,daysfor){
          if(value ==0){
            return
          }
            this.isLoading = true;
            this.exportxlsdata2 = [];
            this.exporterr2 = "";
            this.input = {
                useremail:this.undt.username,
                empcode: this.undt.userid,
                // businessstackholder: this.search.bsh?String(this.search.bsh):null,
                businessstackholder: this.search.bsh,
                typename:type,
                daysfor:daysfor,
                tracker:this.search.roleId?String(this.search.roleId): 'hod',
                tasktype: this.search.searchTasktype?this.search.searchTasktype: null,
                severity:this.search.searchTaskseverity?this.search.searchTaskseverity:null,
                parr: this.search.project,
            };
            axios({
                method: "POST",
                url: `api/reports/geSummaryReportforExport`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata2 = result.data.data.rows;
                    var filename = daysfor+type+'report';
                    this.Summarydownload(result.data.data.rows,filename)
                } else if(result.data.errorCode == 3) {
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                        }).then(function() {
                        window.location.href = "/#/login";
                        })
                } else {
                    Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    });
                }
            }).catch(e => {
            this.displayError(e)
          })
        },
        Summarydownload(readExportdata,filename) {
          if (readExportdata.length > 0) {
            var ShowLabel = filename
            var JSONData = readExportdata
            var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            var CSV = ''
            if (ShowLabel) {
            let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
              row = row.slice(0, -1)
              CSV = 'Sr#,Project Name,Business Task Code,Business Task Name,HOD Name,Engineering Manager,Project Manager,Program Manager,Task Manager,Task Owner (Lead),QA Manager,QA Lead,Status,Created By,Created Date,Product Completed Date,Tech Pipeline Date,QA Pipeline Date,UAT Date' + '\r\n'
            }
            var row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let businesstaskcode = index.hasOwnProperty('businesstaskcode') ? index.businesstaskcode ==null || index.businesstaskcode.toLowerCase() =='na' ? '' : index.businesstaskcode : '';
            let taskname = index.hasOwnProperty('taskname') ? index.taskname ==null || index.taskname.toLowerCase() =='na' ? '' : index.taskname : '';
            let businessstackholder = index.hasOwnProperty('businessstackholder') ? index.businessstackholder ==null || index.businessstackholder.toLowerCase() =='na' ? '' : index.businessstackholder : 'NA';
            let productstackholder = index.hasOwnProperty('productstackholder') ? index.productstackholder ==null || index.productstackholder.toLowerCase() =='na' ? 'NA' : index.productstackholder : 'NA';
            let taskowner = index.hasOwnProperty('taskowner') ? index.taskowner ==null || index.taskowner.toLowerCase() =='na' ? 'NA' : index.taskowner : 'NA';
            let projectmaanager = index.hasOwnProperty('projectmaanager') ? index.projectmaanager ==null || index.projectmaanager.toLowerCase() =='na' ? 'NA' : index.projectmaanager : 'NA';
            let programmanager = index.hasOwnProperty('programmanager') ? index.programmanager ==null || index.programmanager.toLowerCase() =='na' ? 'NA' : index.programmanager : 'NA';
            let engineeringmaanager = index.hasOwnProperty('engineeringmaanager') ? index.engineeringmaanager ==null || index.engineeringmaanager.toLowerCase() =='na' ? 'NA' : index.engineeringmaanager : 'NA';
            let qamanager = index.hasOwnProperty('qamanager') ? index.qamanager ==null || index.qamanager.toLowerCase() =='na' ? 'NA' : index.qamanager : 'NA';
            let qalead = index.hasOwnProperty('qalead') ? index.qalead ==null || index.qalead.toLowerCase() =='na' ? 'NA' : index.qalead : 'NA';
            let taskstatus = index.hasOwnProperty('taskstatus') ? index.taskstatus ==null || index.taskstatus.toLowerCase() =='na' ? '' : index.taskstatus : '';
            let createdby = index.hasOwnProperty('createdby') ? index.createdby : '';
            let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
            createddate = this.dateMonthFormat(createddate);
            let productcompleteddt = index.hasOwnProperty('productcompleteddt') ? index.productcompleteddt : '';
            productcompleteddt = productcompleteddt?this.dateMonthFormat(productcompleteddt):'NA';
            let techpipelinedate = index.hasOwnProperty('techpipelinedate') ? index.techpipelinedate : '';
            techpipelinedate = techpipelinedate?this.dateMonthFormat(techpipelinedate):'NA';
            let qapipelinedt = index.hasOwnProperty('qapipelinedt') ? index.qapipelinedt : '';
            qapipelinedt = qapipelinedt?this.dateMonthFormat(qapipelinedt):'NA';
            let uatdate = index.hasOwnProperty('uatdate') ? index.uatdate : '';
            uatdate = uatdate?this.dateMonthFormat(uatdate):'NA';
            let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname.toLowerCase() =='na' ? '' : index.projectname : '';
              row += '"' + srno + '",',
              row += '"' + projectname + '",',
              row += '"' + businesstaskcode + '",',
              row += '"' + taskname + '",',
              row += '"' + businessstackholder + '",',
              row += '"' + engineeringmaanager + '",',
              row += '"' + projectmaanager + '",',
              row += '"' + programmanager + '",',
              row += '"' + productstackholder + '",',
              row += '"' + taskowner + '",',
              row += '"' + qamanager + '",',
              row += '"' + qalead + '",',
              row += '"' + taskstatus + '",',
              row += '"' + createdby + '",',
              row += '"' + createddate + '",',
              row += '"' + productcompleteddt + '",',
              row += '"' + techpipelinedate + '",',
              row += '"' + qapipelinedt + '",',
              row += '"' + uatdate + '",',
              row += '\r\n'
          }
          CSV += row
          if (CSV === '') {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
            return;
          }
          var fileName = filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
          var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
          var link = document.createElement('a')
          link.href = uri
          link.style = 'visibility:hidden'
          link.download = fileName + '.csv'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          Swal.fire({
            title: "Success!",
            text: "Export Report Successful",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          } else {
              Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }
        },

      
      },
    };
</script>
