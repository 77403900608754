<template>
    <span>
        <loading :active.sync="isExpLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <button type="move" class="btn btn-relief-primary mt-2"  @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export Milestone Report</span></button> 
    </span>  
</template>

<script>
import axios from 'axios'
import VueElementLoading from 'vue-element-loading';
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import profilepic from '../profilepic.vue';
import moment from 'moment';
import { array } from 'joi';
import { ExternalLinkIcon } from "vue-feather-icons";

import commonMethods from '../../utils/commonMethods';
export default {
    name: 'MilestoneBtcReport',
    mixins: [ commonMethods ],

    components: {
        VueElementLoading,Loading,profilepic,ExternalLinkIcon
    },
    data() {
        return {
            formstaskstatus: [],
            isExpLoading: false,
            fullPage: true,
        }
    },
    props: {
        search : array
    },
    mounted(){
        window.addEventListener('storage', function(event){
          if (event.key == 'logout-mee') { 
              window.location.reload();
          }
        });
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.userrole = window.localStorage.getItem("userrole")
            this.loginusername=userdata.username
            this.loginpersonname=userdata.firstname+' '+userdata.lastname;
            this.loginuscondition = userdata.userid
            this.pagefeatures = {}
            this.taskmodule = window.localStorage.getItem("taskmodule")
            let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
                if (element.page === '/businesstask/list') {
                    this.pagefeatures[element.featurename] = element.featureaccess
                }
            })
            this.getBtcStatusList();
        }
    },
    methods: {
        getBtcStatusList() {
            let url = "api/btc/getBtcStatusList";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.formstaskstatus = result.data.data.rows;
                } else {
                    this.formstaskstatus = [];
                }
            }).catch(e => {
            this.displayError(e)
            });     
        },
        ExportXL() {
            this.isExpLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input.empcode = this.undt.userid
            this.input.useremail= this.undt.username
            this.input.searchBusinesstaskId = this.search.searchBusinesstaskname
            this.input.searchBusinesstaskcode = this.search.searchBusinesstaskcode
            this.input.projectarr = this.search.projectid
            this.input.searchProjectstatus = this.search.searchProjectstatus
            this.input.proman = this.search.proman?this.search.proman: null
            this.input.progman = this.search.progman?this.search.progman: null
            this.input.engman = this.search.engman?this.search.engman: null
            this.input.taskmngr = this.search.taskmngr?this.search.taskmngr:null
            this.input.taskownername = this.search.taskownername?this.search.taskownername: null
            this.input.qamanager = this.search.qamanager?this.search.qamanager:null
            this.input.qalead = this.search.qalead?this.search.qalead:null

            axios({
                method: "POST",
                url: `api/master/businesstask/getMilestoneReportData`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isExpLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows.reduce((group, product) => {
                      const { businesstaskmasterid } = product;
                      group[businesstaskmasterid] = group[businesstaskmasterid] ?? [];
                      group[businesstaskmasterid].push(product);
                      return group;
                    }, {});
                    let filename = "";
                    this.download(this.exportxlsdata,filename)
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
            }).catch(e => {
            this.displayError(e)
            });
        },
        dateMONFormat(value){
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        },
        download(readExportdata,filename) {
            if (Object.values(readExportdata).length > 0) {
                var ShowLabel = 'MilestoneReport_'+filename
                var JSONData = Object.values(readExportdata)
                var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
                var CSV = ''
               
                if (ShowLabel) {
                    let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }

                    this.formstaskstatus = this.formstaskstatus.filter((obj) => 
                        obj.id != 'OnHold' && obj.id != 'Discarded' && obj.id != 'Completed'
                    );

                    row = row.slice(0, -1)
                    CSV += `SR No,Business Task,Business Task Code,Project Name,Program Manager,Project Manager,Engineering Manger,QA Manager,QA Lead,Task Manager,Task Owner,`
                    this.formstaskstatus.forEach(element => {
                      CSV += `${element.id}, , ,`
                    });
                    CSV += '\r\n';
                    CSV += `, , , , , , , , , , ,`
                    this.formstaskstatus.forEach(element => {
                      CSV += `Micro Task Start Date,Micro Task End Date,Micro Task Closed Date,`
                    });
                    CSV += '\r\n';
                }
                var row = ''
                let newArr = [];
                arrData.forEach(element => {
                    newArr[element[0].businesstaskmasterid] = [];
                        this.formstaskstatus.forEach(status => {
                        let found = element.find(obj => obj.modulestatus == status.id);
                        if(found) {
                            newArr[element[0].businesstaskmasterid].push({
                                status: status.id,
                                sdate: (found.taskstartdate == null || found.taskstartdate == '')? 'NA' : this.dateMONFormat(found.taskstartdate),
                                edate: (found.taskenddate == null || found.taskenddate == '')? 'NA' : this.dateMONFormat(found.taskenddate),
                                cdate: (found.closeddate == null || found.closeddate == '')? 'NA' : this.dateMONFormat(found.closeddate),
                                btcid: found.businesstaskmasterid,
                                btc: found.btcname,
                                businesstaskcode: found.businesstaskcode,
                                projectname: found.projectname,
                                programmanager: found.programmanager,
                                projectmaanager: found.projectmaanager,
                                engineeringmaanager: found.engineeringmaanager,
                                qamanager: found.qamanager,
                                qalead: found.qalead,
                                productstackholder: found.productstackholder,
                                taskowner: found.taskowner,
                                taskname:found.taskname,
                            });
                        } else {
                            newArr[element[0].businesstaskmasterid].push({
                                status: status.id,
                                sdate: 'NA',
                                edate: 'NA',
                                cdate:'NA',
                                btcid: element[0].businesstaskmasterid,
                                btc: element[0].btcname,
                                businesstaskcode: element[0].businesstaskcode,
                                projectname: element[0].projectname,
                                programmanager: element[0].programmanager,
                                projectmaanager: element[0].projectmaanager,
                                engineeringmaanager: element[0].engineeringmaanager,
                                qamanager: element[0].qamanager,
                                qalead: element[0].qalead,
                                productstackholder: element[0].productstackholder,
                                taskowner: element[0].taskowner,
                                taskname: 'NA',
                            });
                        }
                    });
                });
                let i = 0
                if(newArr.length>0) {
                    newArr.forEach((index) => {
                        let srno = i+1
                        row += '"' + srno + '",',
                        row += '"' + index[0].btc +'",',
                        row += '"' + index[0].businesstaskcode +'",',
                        row += '"' + index[0].projectname +'",',
                        row += '"' + index[0].programmanager +'",',
                        row += '"' + index[0].projectmaanager +'",',
                        row += '"' + index[0].engineeringmaanager +'",',
                        row += '"' + index[0].qamanager +'",',
                        row += '"' + index[0].qalead +'",',
                        row += '"' + index[0].productstackholder +'",',
                        row += '"' + index[0].taskowner +'",',
                        index.forEach(ie => {
                            row += '"' + ie.sdate + '",',
                            row += '"' + ie.edate + '",',
                            row += '"' + ie.cdate + '",'
                        });
                        row += '\r\n'
                        i++;
                    });
                }
                CSV += row
                if (CSV === '') {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                    return;
                }
                var fileName = 'MilestoneReport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                var link = document.createElement('a')
                link.href = uri
                link.style = 'visibility:hidden'
                link.download = fileName + '.csv'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                Swal.fire({
                    title: "Success!",
                    text: "Export report successful",
                    icon: "success",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            }
        },
        export (data, filename, mime) {
            var data
            let blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
        },
    },
}
</script>