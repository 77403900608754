<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 pt-0">
          <div class="container-fluid px-0" >
            <div class="row breadcrumbs-top">
              <div class="col-md-9">
                <h3 class="content-header-title float-left mb-0">Performance Management</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/performance/list'>Performance Management</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning">
          <div class="card-header py-1">
            <div class="container-fluid">
              <div class="row flex--items">
                <div class="p-1 example-square bg-light_bgkara-texttop shadow-1-strong rounded"><h5 class=" mb-0" style="font-size:16px;">Tracker <em class="las la-angle-double-right"></em> KRA mapping</h5></div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 mobile-padding-0-2">
                <div class="form form-vertical">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="projectlistdd">Tracker</label>
                      <treeselect placeholder="Select tracker" class="projectlistdd" :multiple="true"  :clear-on-select="true" v-model="performance.tracker" :options="performance.trackerlist" @input="selTracker"/>
                      <div class="errorinputmsg" v-if="this.errors.performance.tracker">{{ this.errors.performance.tracker }}</div>
                    </div>
                  </div>
                  <div class="row py-2">
                      <div class="col-md-6 kra_overflow_scrolls">
                        <table  class="append_divperformance " aria-describedby="mydesc">
                          <thead class="d-none"><th scope="col"></th></thead>
                            <thead>
                              <tr>
                              <td class="top_td_background"><strong>Choose KRA 
                              <em class="las la-plus-circle text-success"
                              id="commonaddmodule"
                              data-toggle="modal"
                              aria-haspopup="true"
                              aria-expanded="false"
                              :data-target="currentmodule"
                              @click="addModule($event)"
                              type="button"
                              data-backdrop="static"
                              data-keyboard="false"
                              ></em></strong>
                              </td>
                              <div class="canvasoverflowscrool">
                                <template v-for="(datas, indexs) in kralist"
                                  class="form-check form-check-inline mr-2 mt-1 mb-1">
                                  <span v-bind:key="indexs">
                                    <td>
                                      <input type="checkbox" class="productfilter ml-1"
                                      name="productfilter" v-bind:id="'checkallusers'+datas.kraid" value=""
                                      style=" margin: 10px 0px 15px;" v-on:change="onfilterchange(datas.kraid, datas.kra, $event)" v-if="datas.ischecked == 1" checked > 

                                      <input type="checkbox" class="productfilter ml-1"
                                      name="productfilter" v-bind:id="'checkallusers'+datas.kraid" value=""
                                      style=" margin: 10px 0px 15px;" v-on:change="onfilterchange(datas.kraid, datas.kra, $event)" v-else > {{ datas.kra }}
                                    </td>
                                  </span>
                                </template>
                              </div>
                            </tr>
                          </thead>
                        </table>
                        <div class="errorinputmsg" v-if="this.errors.performance.kraid">{{ this.errors.performance.kraid }}</div>
                    </div>
                    <div class="px-2 col-md-6">
                      <table   borderd=1 id="idwaightages" class=" second_performance_table" aria-describedby="mydesc">
                        <thead class="d-none"><th scope="col"></th></thead>
                        <tr class="top_td_background">
                          <td ><strong class="top_td_background_second">KRA</strong></td>
                          <td><strong class="top_td_background_second">Weightages</strong></td>
                        </tr>
                        <template  v-for="(data, index) in kraarray">
                          <tr v-bind:key="index" v-bind:id="'checkallusers'+data[1]">
                            <td class="text-left second-col-sticky" >
                              <span class="f?ont-weight-bold ">{{data[0]}}</span>
                            </td> 
                            <td class="text-left second-col-sticky">
                              <span style="display: inline-flex;">
                              <input
                                style="border: 1px solid #d8d6de !important; width:50%; "
                                type="number"
                                maxlength = "2"
                                class="form-control"
                                name=""
                                placeholder="Weightage 0 - 99 %"
                                @keydown="onKeyDown"
                                @input="normalizeWeightage"
                                @wheel="$event.target.blur()"
                                v-model="data[2]"
                              />
                              <em class="las la-times weightage_delete" @click="onclickchange(data,index, $event)"></em></span>
                            </td>
                          </tr> 
                        </template>
                      </table>
                    </div>
                  </div>
                  <div class="flex--items mt-1">
                    <button type="button" class="btn btn-relief-primary" style="padding: 10px 32px" v-on:click.stop.prevent="saveData($event)">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show"
      id="projectmaster" 
    >
      <div class="modal-dialog sidebar-lg">
        <div class="modal-content p-0">
          <button
            id="closebtnt11"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style="color: #ffff !important"
          >
            ×
          </button>
          <div
            class="modal-header mb-1"
            style="background-color: #1f77b4 !important"
          >
            <h5 class="modal-title" style="color: #ffff !important">
              <plus-square-icon
                size="1.5x"
                class="custom-class"
              ></plus-square-icon>
              <span class="align-middle ml-50"
                >Add KRA</span
              >
            </h5>
          </div>
          <div class="modal-body flex-grow-1 devUniqueForm">
            <form class="form form-vertical">
              <div class="row">
                  <div class="col-12">
                    <div class="form-group " style="margin-bottom:5px;">
                      <label for="modulenamet11">KRA Title :</label>
                      <div class="input-group input-group-merge">
                        <input
                          style="border: 1px solid #d8d6de !important"
                          type="text"
                          class="form-control"
                          name=""
                          placeholder="Enter KRA title"
                          v-model="addkra.kraname"
                          v-bind:class="{'form-control': true,'': !validText(addkra.kraname), }"
                        />
                        <div class="errorinputmsg" v-if="!validText(addkra.kraname) && kranameBlured">
                          KRA title required
                        </div>
                      </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                      <label for="projectdescription"
                        >KRA Description</label
                      >
                      <textarea
                        class="form-control"
                        id="projectdescription"
                        name=""
                        rows="3"
                        placeholder="Enter KRA Description"
                        v-model="addkra.kradesc"
                        v-bind:class="{
                          'form-control': true,
                          '': !validText(addkra.kradesc),
                        }"
                      ></textarea>
                      <div class="errorinputmsg" v-if="!validText(addkra.kradesc) && kradescBlured" >
                        KRA description required
                      </div>
                    </div>
                  </div>
                <div class="col-12">
                  <button type="button" class="btn btn-relief-primary mr-1 " id="submitbtnt9" data-dismiss="modal" v-on:click.stop.prevent="savewizard('t9',$event)">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueElementLoading from 'vue-element-loading';
import Treeselect from '@riophae/vue-treeselect'
import { PlusCircleIcon, PlusSquareIcon, SearchIcon } from "vue-feather-icons";
import Loading from 'vue-loading-overlay';
import commonMethods from '../../utils/commonMethods';
import 'vue-loading-overlay/dist/vue-loading.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'PerformanceUpdate',
  components: {
    VueElementLoading,
    Treeselect,
    Loading,
    PlusCircleIcon, PlusSquareIcon, SearchIcon
  },
  mixins: [ commonMethods ],
  data() {
    return {
      editRowData: [],
      performance: {
        trackerid: [],
        kraid: null,
        kpiid: null,
        designationlist: [],
        kralist: [],
        kpilist: [],
        weightags: null,
        createdby: null,
        currtrackerid: null,
        tracker:[]
      },
      addkra: {
        kraname: null,
        kradesc: null
      },
      errors: {
        performance: {
          trackerid: null,
          kraid: null,
          kpiid: null,
          weightags: null,
          tracker: null
        },
        addkra: {
          kraname: null,
          kradesc: null
        }
      },
      isLoading: false,
      kralist: [],
      kraarray: [],
      mykraid: null,
      mykra: null,
      isAddState: false,
      currentmodule:'projectmaster',
      glbMdlLblval : "KRA",
      glbMdl: "",
      kranameBlured: false,
      kradescBlured: false,
      weightags: null,
      newarr:'',
      newarr2:'',
      searchalldata: null,
      weightags: '',
    }
  },
  created() {},
  mounted() {
    let userdata = window.localStorage.getItem('userdata');
    if(userdata) {
      userdata = JSON.parse(userdata)
      this.undt = userdata
      this.tokendata = window.localStorage.getItem('token');
      this.getPerformanceById(this.$route.query.trackerid)
      this.getKRAList()
      this.getkradatalist()
      this.getTrackerLists()
    }
  },
  methods: {
    onKeyDown(event) {
      if (event.key === '-' || event.key === 'ArrowDown' || event.key === 'PageDown') {
        event.preventDefault();
      }
    },
    normalizeWeightage() {
      this.weightags = this.weightags.replace(/-/g, '');
    },
    getkradatalist() {
      let setmoduleform = "projectmaster";
      this.currentmodule=`#`+setmoduleform
      let glbMdlLblval = "KRA";
      let tblNm = "t11";
      this.glbMdl = tblNm + "##" + setmoduleform;
      this.glbst = tblNm;
      if (setmoduleform) {
        this.glbMdlLbl = glbMdlLblval;
      }
      axios({
        'method': 'POST',
        'url': 'api/master/performance/getkradatalist',
        'data': {
          useremail: this.undt.username,
          kraname: this.searchalldata,
          empcode: this.undt.userid
        },
        'headers': { 'authorization': this.tokendata }
      }).then(result => {
        this.isLoading = false
        if (result.data.errorCode == 0) {
          this.kralist = result.data.data

          for (var k=0; k < this.kralist.length; k++){
              var skraid = this.kralist[k].kraid;
            for (var e=0;e < this.editRowData.length; e++){
              var ekraid = this.editRowData[e].kraid;

              if(skraid == ekraid){
                this.kralist[k].ischecked = 1;
              } 
            }
          }
        } else {
          this.kralist = []
        }
      }).catch(e => {
         this.displayError(e)
      });
    },
    getTrackerLists() {
      let url = 'api/master/performance/getTrackerlist'
       axios({
        method: 'POST',
        url,
        data:{useremail: this.undt.username,empcode: this.undt.userid},
        headers:{ authorization :this.tokendata }
      }).then(result => {
        if(result.data.errorCode == 0) {
          this.performance.trackerlist = result.data.data.rows
        } else {
          this.performance.trackerlist = []
        }
      }).catch(e => {
         this.displayError(e)
      });
    },
    getPerformanceById(trackerid) {
      this.performance.currtrackerid = trackerid
      let url = 'api/master/performance/getPerformanceDataById'
       axios({
        method: 'POST',
        url,
        data: {trackerid,useremail: this.undt.username,empcode: this.undt.userid},
        headers:{ authorization :this.tokendata }
      }).then(result => {
        if(result.data.errorCode == 0) {
          this.editRowData = result.data.data.rows
          this.openEditModule(this.editRowData,0)
        } else {
          this.performance.trackerlist = []
        }
      }).catch(e => {
         this.displayError(e)
      });
    },
    openEditModule(data, flag) {
      this.editRowData = data
      this.performance.createdby = data[0].createdby
      this.performance.tracker = [data[0].trackerid]
      for (let k = 0; k < data.length; k++) {
        this.mykra = data[k].kra,
        this.mykraid = data[k].kraid,
        this.weightags =  data[k].weightags;
        this.kraarray.push([this.mykra,this.mykraid,this.weightags])
      }
    },
    getKRAList() {
      let url = 'api/master/performance/getKRAList'
       axios({
        method: 'POST',
        url,
        data:{useremail: this.undt.username,empcode: this.undt.userid},
        headers:{ authorization :this.tokendata }
      }).then(result => {
        if(result.data.errorCode == 0) {
          this.performance.kralist = result.data.data.rows
        } else {
          this.performance.kralist = []
        }
      }).catch(e => {
         this.displayError(e)
      });
    },
    clearFields() {
      this.performance.tracker = null
      this.performance.kraid = null
      this.performance.kpiid = null
    },
    validateForm() {
      let isValid = true
      if(!this.performance.tracker || (this.performance.tracker && this.performance.tracker.length < 1)) {
        this.errors.performance.tracker = 'tracker is required'
        isValid = false
      } else {
        this.errors.performance.tracker = ''
      }
      if (this.kraarray.length == 0 ) {
        this.errors.performance.kraid = 'KRA is required'
        isValid = false
      }
      return isValid
    },
    onfilterchange(kraid, kra, event){        
      if (event.target.checked == true) {
        this.mykraid = kraid;
        this.mykra = kra;
        this.kraarray.push([this.mykra,this.mykraid])
        this.errors.performance.kraid = ''
      }else{
        this.mykraid = null;
        this.mykra = null;
        let id  = "checkallusers"+kraid;     

        const objWithIdIndex = this.kraarray.findIndex((obj) => obj[1] == kraid);
        if (objWithIdIndex > -1) {
          this.kraarray.splice(objWithIdIndex, 1);

          $("#"+id).prop("checked", false);
        }
      }
    },
    onclickchange(data,event){
      
      let id  = "checkallusers"+data[1];

      const objWithIdIndex = this.kraarray.findIndex((obj) => obj[1] === data[1]);
      if (objWithIdIndex > -1) {
        this.kraarray.splice(objWithIdIndex, 1);
        
        $("#"+id).prop("checked", false);
      }
    },
    addModule(objThis) {
      this.addkra.kraname = null;
      this.addkra.kradesc = null;
      this.kranameBlured = null;
      this.kradescBlured = null;
    },
    validText: function (inputval) {
      var re = /[!-A-Za-z0-9-_-|-~-].{0,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    saveData() {
      this.newarr = Array.from(this.kraarray, function (item) {
        return { id: item[1], name: item[0], wt: item[2] ? item[2]:0};
      })
      let isValid = this.validateForm()
      if(isValid) {
        this.isLoading = true
        let url = 'api/master/performance/update'
        let payload = {
          currtrackerid: this.performance.currtrackerid,
          trackerid: this.performance.tracker.map(i => parseInt(i)),
          kraarray: this.newarr,
          createdby: this.performance.createdby,
          lastmodifiedby: this.undt.username,
          useremail: this.undt.username,
          empcode: this.undt.userid
        }
        axios({
          method: 'POST',
          url,
          data: payload,
          headers: { authorization :this.tokendata }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: 'success',
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
            this.$router.push('/performance/list')
          } else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
            Swal.fire({
              title: "Failed",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          }
        }).catch(e => {
          this.displayError(e)
        })
      }
    },
    validateForms(mdl, objThis) {
      this.isAddState = false;
      var isValid = true;
      this.kranameBlured = true;
      this.kradescBlured = true
      return isValid;
    },
    savewizard: function(mdl,objThis){ 
      this.valid = this.validateForms(mdl,objThis);
        if (this.valid) {
          this.isLoading = true;
            let tblNm='t11'
            if(mdl) tblNm=mdl
            this.input = {
              createdby: this.undt.username,
              kraname: this.addkra.kraname,
              kradesc: this.addkra.kradesc,
              useremail: this.undt.username,
              empcode: this.undt.userid
            }
            let apiURL='api/master/performance/savekra'
            this.isAddState=false
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;     
                if(result.data.errorCode == 0){
                  let rowdata = {}
                  this.getkradatalist(rowdata);
                    $(".close").click();
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }else {
                    Swal.fire({
                        title: "Failed",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            }).catch(e => {
          this.displayError(e)
        })
        }
    },
    selDesignation(state) {
      this.performance.designationid = null
      this.performance.designationid = state
      if(!state || (state && state.length < 1)) {
        this.errors.performance.designationid = 'Designation is required'
      } else {
        this.errors.performance.designationid = ''
      }
    },
    selKRA(state) {
      this.performance.kraid = null
      this.performance.kraid = state
      if(!state || (state && state.length < 1)) {
        this.errors.performance.kraid = 'KRA is required'
      } else {
        this.errors.performance.kraid = ''
      }
    },
    selTracker(state) {
      this.performance.tracker = null
      this.performance.tracker = state
      if(!state || (state && state.length < 1)) {
        this.errors.performance.tracker = 'Tracker is required'
      } else {
        this.errors.performance.tracker = ''
      }
    },
  }
}
</script>

<style>
.top_td_background{
  height: 44px;
  line-height: 35px;
  position: sticky;
  top: 0px;
  z-index: 9;
  background: #fff;
  border-top: 1px solid #eee;
}
.top_td_background_second{
   height: 29px;
    line-height: 30px;
    display: inline-block;
}
.append_divperformance tr td input{
  cursor:pointer;
}
.second_performance_table .second-col-sticky .weightage_delete{
  cursor:pointer;
}
.right_top_right{
width: 50%!important;
    float: right;
    display: inline-flex;
    left: 0px;
    top: unset!important;
    align-items: center;
}
.right_top_right input{
 top:0px!important;
}
.right_top_right svg{
 margin-right: 10px;
}


</style>