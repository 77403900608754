<template>
    <div>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
    <div class="row">  
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div class="form-group mb-2">
            <label for="allotedto">Project Name</label>
            <input type="text" class="form-control capitalisetext" placeholder="Enter project Name"  v-model="projectName" disabled/>
        </div>
      </div>    
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <div class="form-group mb-2">
          <label for="allotedto">Copy From Project</label>
          <treeselect class="allotedto capitalisetext" placeholder="Select project name"  v-model="clone.projectid"  :options="clone.projectlist" @select="selissuecsproject"  @close="validateForm($event)"/>   
          <div class="errorinputmsg" v-if="this.errors.clone.projectid">{{ this.errors.clone.projectid }}</div> 
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 cloning_checkbo_style">
        <div class="form-group mb-2">
            <div class="custom-control custom-control-primary custom-checkbox mb-1">
                <input type="checkbox" class="custom-control-input input-filter" id="role" v-model="clone.roleid"/>
                <label class="custom-control-label" for="role">Role</label>
            </div>
        </div>
    </div>
    <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 cloning_checkbo_style">
        <div class="form-group mb-2">
            <div class="custom-control custom-control-primary custom-checkbox mb-1">
                <input type="checkbox" class="custom-control-input input-filter" id="roleusr" v-model="clone.roleuser"/>
                <label class="custom-control-label" for="roleusr">Role With User</label>
            </div>
        </div>
    </div> -->
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 cloning_checkbo_style">
        <div class="form-group mb-2">
            <div class="custom-control custom-control-primary custom-checkbox mb-1">
                <input type="checkbox" class="custom-control-input input-filter" id="form" v-model="clone.formconfig"/>
                <label class="custom-control-label" for="form">Form Configuration</label>
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 cloning_checkbo_style">
        <div class="form-group mb-2">
            <div class="custom-control custom-control-primary custom-checkbox mb-1">
                <input type="checkbox" class="custom-control-input input-filter" id="field" v-model="clone.fieldconfig"/>
                <label class="custom-control-label" for="field">Field Configuration</label>
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 cloning_checkbo_style">
        <div class="form-group mb-2">
            <div class="custom-control custom-control-primary custom-checkbox mb-1">
                <input type="checkbox" class="custom-control-input input-filter" id="sla" v-model="clone.slaconfig"  />
                <label class="custom-control-label" for="sla">SLA Configuration</label>
            </div>
        </div>
    </div>
    <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 cloning_checkbo_style">
        <div class="form-group mb-2">
            <div class="custom-control custom-control-primary custom-checkbox mb-1">
                <input type="checkbox" class="custom-control-input input-filter" id="btc" v-model="clone.btctask" />
                <label class="custom-control-label" for="btc">BTC Task Configuration</label>
            </div>
        </div>
    </div> -->
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 cloning_checkbo_style">
        <div class="form-group mb-2">
            <div class="custom-control custom-control-primary custom-checkbox mb-1">
                <input type="checkbox" class="custom-control-input input-filter" id="roastr" v-model="clone.roaster" />
                <label class="custom-control-label" for="roastr">Roaster Configuration</label>
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 cloning_checkbo_style">
        <div class="form-group mb-2">
            <div class="custom-control custom-control-primary custom-checkbox mb-1">
                <input type="checkbox" class="custom-control-input input-filter" id="esc" v-model="clone.escalation" />
                <label class="custom-control-label" for="esc">Escalation Configuration</label>
            </div>
        </div>
    </div>
</div>
    <div class="col-12 text-left mt-1 px-0">
    <div class="form-group mb-2">
        <button type="button" class="btn btn-relief-primary " @click="savewizard($event)">Submit</button> 
        <button type="button" class="btn btn-relief-secondary ml-1" @click="resetForm()"> Clear </button>
    </div>
    </div>   
    
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import apiUrl from '../../constants';
  import VueElementLoading from 'vue-element-loading';
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css'
  import profilepic from '../profilepic.vue';
  import moment from 'moment';
  import commonMethods from '../../utils/commonMethods';
  import {PlusIcon,PlusCircleIcon,PlusSquareIcon} from "vue-feather-icons";
  
  export default {
      name: 'projectclone',
      mixins: [ commonMethods ],
  
      components: {
          VueElementLoading,Loading,profilepic,
          PlusIcon,
          PlusSquareIcon,
          PlusCircleIcon,
          Treeselect,
          props: {
            projectName: String 
          },
          

      },
      data() {
          return {
            isLoading: false,
            fullPage: true,
             projectName: '',
              tasksrows: [],
              completedTaskCount: [],
              issuesrows: [],
              completedIssuesCount: [],
              formstaskstatus: [],
              dateDetailsREs: [],
              //selectedRoles: [],
              clone:{
                projectid: null,
                projectlist: [],
                project: null,
                projectlist1: [],
                roleid: false,
                rolename: null,
                roleuser:false,
                formconfig:false,
                roaster:false,
                rolelist: [],
                userlist:[],
                rolecount:null,
                formconfiglist:[],
                fieldconfiglist:[],
                slalist:[],
                TaskConfiglist:[],
                roastrlist:[],
                escalation:false,
                },
                errors: {
                  clone: {
                    projectid: null,
                  },
              },
          }
      },
      mounted(){
          window.addEventListener('storage', function(event){
            if (event.key == 'logout-mee') { 
                window.location.reload();
            }
          });
          this.projectName = localStorage.getItem('clone_projectname');
          this.projectid = localStorage.getItem('clone_projectid')
          let userdata = window.localStorage.getItem('userdata');
          if(userdata){
              userdata = JSON.parse(userdata)
              this.undt=userdata
              this.tokendata = window.localStorage.getItem('token');
              this.userrole = window.localStorage.getItem("userrole")
              this.loginusername=userdata.username
              this.loginpersonname=userdata.firstname+' '+userdata.lastname;
              this.loginuscondition = userdata.userid
              this.getProjectAllocatedToEmp();

          }
      },
      methods: {
      getProjectAllocatedToEmp() {
      this.isLoading = true;
      let url = "api/listing/getProjectAllocatedToEmp";
      this.input = {
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        empcode: this.undt.userid,
        copyproject: this.projectid,
        isfrompage:'createpage'
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.clone.projectlist = result.data.data;
        } else {
          this.clone.projectlist = [];
        }
      });
    },
    selissuecsproject: function (state, value) {
      this.clone.projectid = null;
      this.clone.projectid = state.id;
       if (!state && !state.id) {
        this.errors.clone.projectid = "Project is required";
       } else {
        this.errors.clone.projectid ='';
      }
    },
      resetForm() {
        this.clone.projectid = null
        this.clone.roleid = false
        this.clone.roleuser = false;
        this.clone.fieldconfig = false;
        this.clone.formconfig = false;
        this.clone.slaconfig = false;
        this.clone.btctask = false;
        this.clone.roaster = false;
        this.clone.escalation = false;
       },
       validateForm: function (mdl, objThis) {
       this.clearerrorForm();
       this.isAddState = false;
        var isValid = true;
        if (!this.clone.projectid) {
          this.errors.clone.projectid = "Copy From Project is required";
          isValid = false;
        } else {
          this.errors.clone.projectid = null;
        }
         return isValid;
       },
        clearerrorForm: function () {
          this.errors.clone.projectid = null;
        },
       savewizard: function (mdl, objThis) {
        this.valid = this.validateForm(mdl, objThis);
        if (this.valid) {
          this.isLoading = true;
          this.input = {
            createdby: this.undt.username,
            useremail: this.undt.username,
            empcode: this.undt.userid,
          };
          let apiURL = "api/project/saveclone";

          if(this.clone.roleuser == true){
          this.input.roleclone = 'Roleuser';
          }
          if(this.clone.roleid == true){
          this.input.roleidclone = 'Roleid';
          }
          if(this.clone.formconfig == true){
          this.input.formclone = 'formconfig';
          }
          if(this.clone.roaster == true){
          this.input.roasterclone = 'roasterconfig';
          }
          if(this.clone.slaconfig == true){
          this.input.slaclone = 'slaconfig';
          }
          if(this.clone.btctask == true){
          this.input.btctaskclone = 'btcconfig';
          }
          if(this.clone.fieldconfig == true){
          this.input.fieldclone = 'fieldconfig';
          }
          if(this.clone.escalation == true){
          this.input.escclone = 'escconfig';
          }
          this.input.copyproject = this.clone.projectid;
          this.input.project = this.projectid;
          
          axios({
            method: "POST",
            url: apiURL,
            data: this.input,
            headers: { authorization: this.tokendata },
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.file = [];
              this.clone.filedescp = [];
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              this.myrequestcollapse();
              $(".close").click();
            }else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
              Swal.fire({
                title: "Failed",
                text: result.data.msg,
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            }
              }).catch(e => {
            this.displayError(e)
          });
           }
          },
          myrequestcollapse() {
            
            this.clone.projectid = null;
            this.clone.roleid = null;
            this.clone.roleuser = null;
            this.clone.fieldconfig = null;
            this.clone.formconfig = null;
            this.clone.slaconfig = null;
            this.clone.btctask = null;
            this.clone.roaster = null;
            this.clone.escalation = null;
            //this.clearerrorForm();
          },
      },
  }
  </script>