<template>
  <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left col-md-12 col-12 mb-1">
          <div class="row breadcrumbs-top">
            <div class="col-12 px-0">
              <h3 class="content-header-title float-left mb-0">Application Environment Details</h3>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'appenvironmentList', params: { search: true }}">Application Environment List</router-link></li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">View App Env</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div v-bind:class="[{show: isRequestActive}, card_content,collapse]">
            <div class="card-header py-1 pt-2 pb-1 indent_border_bottom" style="cursor:pointer">
              <div class="container-fluid">
                <div class="row">
                  <div class="px-2 w-auto mobile-px-0">
                    <div class="avatar bg-light-primary rounded d-inline-flex">
                      <div class="avatar-content">
                        <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                      </div>
                    </div>
                    <h3 class="card-title ml-2 d-inline-flex text-primary text-primary-dark">{{`${this.editRowData.applicationname} `}} Details</h3>
                  </div>
                  <div class="ml-auto px-1 text-right">
                    <div v-if="loginusername == this.editRowData.createdby" @click="openEditModule(backpagedata,0)" class="btn btn-sm btn-outline-primary waves-effect waves-effect ">
                      <edit-2-icon size="1.5x" class="custom-class avatar-icon mr-50"></edit-2-icon>
                      <span>Edit</span>
                    </div>

                  </div>
                </div>
              </div>
          </div>
            <div class="row match-height card_transction_veiws">
              <div class="col-12">
                <div class=" card-transaction">
                    <div class="card-body mobile-padding-15-10" style="margin:0px 0px;">
                        <div class="transaction-item">
                        <div class="media">
                            <div class="avatar bg-light-success rounded">
                                <div class="avatar-content">
                                   
                                    <em class="las la-user-edit font-medium-3"></em>
                                </div>
                            </div>
                            <div class="media-body ">
                                <h6 class="transaction-title">{{`${this.appenv.type} - ${this.editRowData.applicationname}`}}</h6>
                                <small>Created by {{this.editRowData.createdby}}</small>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
              </div>
              <br/>
              
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction breakallurls_urls">
                  <div class="card-body mobile-padding-15-10 pt-md-1">
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <em class="lab la-canadian-maple-leaf font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Environment Type</h6>
                          <small class="capitalisetext">{{this.appenv.type}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="las la-server font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Hosting Platform</h6>
                          <small>{{this.appenv.host}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-success rounded">
                          <div class="avatar-content">
                           
                            <em class="las la-cloud-moon font-medium-3"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Host Path</h6>
                          <small>{{this.appenv.hostpath}}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction breakallurls_urls">
                  <div class="card-body mobile-padding-15-10 pt-md-1">
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="i_icon_st la-codepen lab font-medium-5" style="font-weight: 500;"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Project</h6>
                          <small class="cursorpointercls sitebluecolor" @click="redirecttourl('projectId',editRowData.projectid)">{{this.editRowData.projectname}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-success rounded">
                            <div class="avatar-content">
                              <em class="i_icon_st la-hdd  las font-medium-5" ></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Application</h6>
                            <small>{{this.editRowData.applicationname}}</small>
                          </div>
                        </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <em class="las la-server font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Hosting URL</h6>
                          <small>{{this.appenv.hosturl}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="las la-city font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Data Center Name</h6>
                          <small>{{this.appenv.datacentername}}</small>
                        </div>
                      </div>
                    </div>
                  </div>                                                                
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction breakallurls_urls">
                  <div class="card-body mobile-padding-15-10 pt-md-1">
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Created Date</h6>
                          <small>{{dateFormat2(this.editRowData.createddate)}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Last Modified By</h6>
                          <small>{{this.editRowData.lastmodifiedby}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Last Modified Date</h6>
                          <small>{{dateFormat2(this.editRowData.lastmodifieddate)}}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" style="margin-top:-45px;">
                <div class=" card-transaction breakallurls_urls">
                  <div class="card-body mobile-padding-15-10">
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-secondary rounded">
                          <div class="avatar-content">
                            <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Host Details</h6>
                          <span v-html="this.appenv.hostdetails"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-bind:class="[{display:disRequestActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div class="card-header" style="cursor:pointer" v-on:click="myrequestollapse">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-9">
                  <div class="avatar bg-light-primary rounded d-inline-flex mr-2" >
                    <div class="avatar-content">
                        <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                    </div>
                  </div>
                  <h4 class="card-title text-primary d-inline-flex">{{this.formActionLabel}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div v-bind:class="[{show: isRequestActive},card_content,collapse]" >
              <div class="card-body">
                <form class="form form-vertical">
                  <div class="row">
                    <div class="col-md-6 mb-1">
                      <div class="form-group">
                        <label for="projectmasterdd">Project Name</label>
                        <treeselect v-on:search-change="projectChangesearch" v-model="appenv.project" :options="appenv.projectlist" @input="selenvproj" placeholder="Enter min 4 chars to search project name" @close="validateForm('t13',$event)" />
                        <div class="errorinputmsg" v-if="this.errors.appenv.project">{{ this.errors.appenv.project }}</div>
                      </div>
                    </div>
                    <div class="col-md-6 mb-1">
                      <div class="form-group">
                        <label for="applicationmasterdd">Application Name</label>
                        <treeselect v-model="appenv.application" :options="appenv.applicationlist" @input="selenvappl" @close="validateForm('t13',$event)" />
                        <div class="errorinputmsg" v-if="this.errors.appenv.application">{{ this.errors.appenv.application }}</div>
                      </div>
                    </div>
                    <div class="col-md-6 mb-1">
                        <div class="form-group">
                            <label for="environmenttypedd">Environment Type</label>
                            <select class="form-control environmenttypedd" id="environmenttypedd" v-model="appenv.type" v-bind:class="{'form-control':true, '' : !valid2Text(appenv.type) && appenvtypeBlured}" v-on:blur="appenvtypeBlured = true">
                                <option value="">Select Environment Type</option>
                                <option value="Local">Local</option>
                                <option value="Stage">Stage</option>
                                <option value="Preproduction">Preproduction</option>
                                <option value="Live/Production">Live/Production</option>
                            </select>
                            <div class="errorinputmsg" v-if="!valid2Text(appenv.type) && appenvtypeBlured">Enviornment type is required</div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-1">
                        <div class="form-group">
                            <label for="">Data Center Name</label>
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><codesandbox-icon size="1.5x" class="custom-class"></codesandbox-icon></span>
                                </div>
                                <input type="text" id="" class="form-control" name="" placeholder="Data Center Name"  v-model="appenv.name" v-bind:class="{'form-control':true, '' : !validText(appenv.name) && appenvnameBlured}" v-on:blur="appenvnameBlured = true"/>
                            </div>
                                <div class="errorinputmsg" v-if="!validText(appenv.name) && appenvnameBlured">Data center name must be  greater than 5 char, and you must enter a letters</div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-1">
                        <div class="form-group">
                            <label for="hostingplatform">Hosting Platform</label>
                              <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><cloud-icon size="1.5x" class="custom-class"></cloud-icon></span>
                                </div>
                                <input type="text" id="hostingplatform" class="form-control" name="" placeholder="Hosting Platform"  v-model="appenv.host" v-bind:class="{'form-control':true, '' : !validText(appenv.host) && appenvhostBlured}" v-on:blur="appenvhostBlured = true"/>
                                <div class="errorinputmsg" v-if="!validText(appenv.host) && appenvhostBlured">Hosting platform must be  greater than 5 char, and you must enter a letters</div>
                              </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-1">
                        <div class="form-group">
                            <label for="hostingplatpath">Hosting Path</label>
                              <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <link-2-icon size="1.5x" class="custom-class"></link-2-icon></span>
                                </div>
                                <input type="text" id="hostingplatpath" class="form-control" name="" placeholder="Hosting Path"  v-model="appenv.hostpath" v-bind:class="{'form-control':true, '' : !validText(appenv.hostpath) && appenvhostpathBlured}" v-on:blur="appenvhostpathBlured = true"/>
                                <div class="errorinputmsg" v-if="!validText(appenv.hostpath) && appenvhostpathBlured">Hosting path must be  greater than 5 char, and you must enter a letters</div>
                          </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-1">
                        <div class="form-group">
                            <label for="hostingplatdetails">Hosting Details</label>
                              <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><box-icon size="1.5x" class="custom-class"></box-icon></span>
                                </div>
                                <input type="text" id="hostingplatdetails" class="form-control" name="" placeholder="Hosting Details" v-model="appenv.hostdetails" v-bind:class="{'form-control':true, '' : !validText(appenv.hostdetails) && appenvhostdetailspathBlured}" v-on:blur="appenvhostdetailspathBlured = true"/>
                                <div class="errorinputmsg" v-if="!validText(appenv.hostdetails) && appenvhostdetailspathBlured">Hosting details must be  greater than 5 char, and you must enter a letters</div>
                              </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-1">
                        <div class="form-group">
                            <label for="hostingplaturl">Hosting Url</label>
                              <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><chrome-icon size="1.5x" class="custom-class"></chrome-icon></span>
                                </div>
                                <input type="text" id="hostingplaturl" class="form-control" name="" placeholder="Hosting Url" v-model="appenv.hosturl" v-bind:class="{'form-control':true, '' : !validText(appenv.hosturl) && appenvhosturlBlured}" v-on:blur="appenvhosturlBlured = true"/>
                                <div class="errorinputmsg" v-if="!validText(appenv.hosturl) && appenvhosturlBlured">Hosting url must be  greater than 5 char, and you must enter a letters</div>
                              </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-1 text-right">
                        <button type="button" class="btn btn-relief-primary mr-1"  v-on:click.stop.prevent="saveData()" id="submitbtnt13">Submit</button>
                        <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import { PackageIcon, Edit2Icon, PenToolIcon, PocketIcon, UserIcon, CalendarIcon, EditIcon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon } from 'vue-feather-icons'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import commonMethods from '../../utils/commonMethods';

export default {
    name:'Masters',
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        Edit2Icon,
        PenToolIcon,
        PocketIcon,
        UserIcon,
        CalendarIcon,
    },
    mixins: [ commonMethods ],

    data() {
        return {
            card_content:'card-content',
            collapse:'collapse',
            isedit: false,
            card:'card',
            border_warning:'border-warning',
            formActionLabel: '',
            disRequestActive: true,
            isDetActive:false,
            isRequestActive:true,
            backpagedata: [],
            isLoading: false,
            fullPage: true,
            editRowData:[],
            glbMdl:'',
            glbst:'',
            glbMdlLbl:'company',
            projectType:[],
            appenv:{
                name:'',
                host:'',
                hostpath:'',
                hostdetails:'',
                hosturl:'',
                type:'',
                application:null,
                applicationlist:[{
                    id:'',
                    label:''
                }],
                project:null,
                projectlist:[{
                    id:'',
                    label:''
                }],
                datacentername:''

            },
            applinameBlured:false,
            appenvhosturlBlured:false,
            appenvhostdetailspathBlured:false,
            appenvhostpathBlured:false,
            appenvhostBlured:false,
            appenvnameBlured:false,
            appenvtypeBlured:false,
            errors: {
                appenv:{
                    name:null,
                    host:null,
                    hostpath:null,
                    hostdetails:null,
                    hosturl:null,
                    type:null,
                    application:null,
                    project:null
                },
            },
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            pageoffset:1,
            pagefeatures:[],
            loginusername: '',
            tokendata:null,
        }
    },
    created() {
      this.pagefeatures = {}
      let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
      pagefeatures1.forEach(element => {
        if (element.page === '/assignments') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
      })
    },
    mounted(){
      let userdata = window.localStorage.getItem('userdata');
        if(userdata){
          userdata = JSON.parse(userdata)
          this.undt=userdata
          this.tokendata = window.localStorage.getItem('token');
          this.loginusername = userdata.username
          this.getAppEnvById(this.$route.query.appenvid)
        }
    },
    methods:{
      getAppEnvById(id){
        this.isLoading = true;
        let url = "api/appenv/getAppEnvById";
        this.input = {
            id,
            useremail: this.undt.username,
            empid: parseInt(this.undt.userid),
            empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.editRowData = result.data.data[0];
              this.openEditModule(this.editRowData,4)
            } else if(result.data.errorCode == 3){
              Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
                })
            }
             else {
                this.$router.push('/appenvironment/list')
                Swal.fire({
                    title: "Failed!",
                    text: "Your are not authorised user to update this issue",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
            } 
          }).catch(e => {
          this.displayError(e)
        });
      },
      myrequestollapse:function(){
        this.disRequestActive=!this.disRequestActive
      },
      handleCancel() {
        this.myrequestollapse()
        $('html, body').animate({ scrollTop: 0 }, 1200); 
      },
        validText : function(inputval) {
            let re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        valid2Text : function(inputval) {
            let re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validate : function(){
          this.valid = false
          this.appenvhosturlBlured=true
          this.appenvhostdetailspathBlured=true
          this.appenvhostpathBlured=true
          this.appenvhostBlured=true
          this.appenvnameBlured=true
          this.appenvtypeBlured=true

          if((this.validText(this.appenv.name) && this.validText(this.appenv.host) && this.validText(this.appenv.hostpath) && this.validText(this.appenv.hostdetails)  && this.validText(this.appenv.hosturl) && this.valid2Text(this.appenv.type) )){
            this.valid = true;
          }
          return this.valid
        },
        getProjectDataBYProjectid(projectid){
          this.isLoading = true;
          let url = "api/searching/getAssignedProjectList";
          this.input = {
            useremail: this.undt.username,
            empid :this.undt.userid,
            projectid,
            empcode: this.undt.userid,
            isfrompage: 'isprojectid'
          };
          axios({
            method: "POST",
            url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.appenv.projectlist = result.data.data;
            } else {
              this.appenv.projectlist = [];
            }
          }).catch(e => {
            this.displayError(e)
          });
        },
        openEditModule: function (currRowData, flag){
          currRowData = this.editRowData 
          this.editRowData = currRowData;
          this.appenv.name=currRowData.datacentername
          this.appenv.application = currRowData.applicationid
          this.appenv.project = currRowData.projectid
          this.getProjectDataBYProjectid(currRowData.projectid)
          this.appenv.type=currRowData.environmenttype
          this.appenv.host=currRowData.hostingplatform
          this.appenv.hostpath=currRowData.hostingpath
          this.appenv.hostdetails=currRowData.hostingdetails
          this.appenv.hosturl=currRowData.hostingurl
          this.appenv.datacentername = currRowData.datacentername
          if(flag==0){
            this.isRequestActive=true
            this.disRequestActive=false
            this.formActionLabel = `Edit Application Environment: ${currRowData.datacentername}`
            $('html, body').animate({ scrollTop: $(document).height() }, 800);
          }
        },
        saveData(){           
          this.valid = this.validate() && this.validateForm();
          if (this.valid) {
            this.isLoading = true
              let tblNm= 't13'
              this.input = {
                tbl: tblNm,
                section: 'assignment',
                createdby: this.undt.username,
                empcode: this.undt.userid,
                useremail: this.undt.username
              }
              if(this.appenv.application){
                this.input.name=this.appenv.name
              }
              if(this.appenv.application){
                  this.input.applicationmasterdd=this.appenv.application
              }
              if(this.appenv.project){
                  this.input.projectmasterdd=this.appenv.project
              }
              if(document.getElementById('environmenttypedd').value){
                  this.input.environmenttypedd=document.getElementById('environmenttypedd').value
              }
              if(document.getElementById('hostingplatform').value){
                  this.input.hostingplatform=document.getElementById('hostingplatform').value
              }
              if(document.getElementById('hostingplatpath').value){
                  this.input.hostingplatpath=document.getElementById('hostingplatpath').value
              }
              if(document.getElementById('hostingplatdetails').value){
                  this.input.hostingplatdetails=document.getElementById('hostingplatdetails').value
              }
              if(document.getElementById('hostingplaturl').value){
                  this.input.hostingplaturl=document.getElementById('hostingplaturl').value
              }
              let apiURL='api/master/commonmaster/update'
              this.input.id=this.editRowData.applcationenvironmentdetailsid
              this.input.lastmodifiedby=this.undt.username
              delete this.input.createdby;
              axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
              })
              .then(result => {
                if(result.data.errorCode == 0){
                  this.isLoading = false
                  $('html, body').animate({ scrollTop: 0 }, 1200);
                  Swal.fire({
                    title: "Success!",
                    text: result.data.msg,
                    icon: 'success',
                    customClass: {
                      confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                  })
                  this.myrequestollapse()
                  this.getAppEnvById(this.editRowData.applcationenvironmentdetailsid)
                } else if(result.data.errorCode == 3){
                  Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                  }).then(function() {
                    window.location.href = "/#/login";
                  })
                }
              }).catch(e => {
              this.displayError(e)
            })
          }
        },
        validateForm(){
          this.clearerrorForm();
          this.isAddState=false
          let isValid=true;
          if(!this.appenv.application){
            this.errors.appenv.application="Application name is required";
            isValid= false;
          }
          if(!this.appenv.project){
            this.errors.appenv.project="Project name is required";
            isValid= false;
          }
          return isValid
        },
        validEmail: function (email) {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm(){
          this.errors.appenv.name = null
          this.errors.appenv.host = null
          this.errors.appenv.hostpath = null
          this.errors.appenv.hostdetails = null
          this.errors.appenv.hosturl = null
          this.errors.appenv.type = null
          this.errors.appenv.application = null
          this.errors.appenv.project = null
        },
        dateFormat2(value) {
          if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        },
        dateFormat(value) {
          if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY');
        },
        getApplicationData(mdl) {
            this.isLoading = true
            let url = "api/listing/getappnamebyprojecteid";
            if(mdl == 't12') {
                this.input = {
                    projecteid: this.appenv.project,
                    empcode: this.undt.userid,
                    useremail: this.undt.username
                };
            }
            axios({
                method: "POST",
                url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then(result => {
                this.isLoading = false
                if (result.data.errorCode == 0) {
                    this.appenv.applicationlist = result.data.data;
                }
            }).catch(e => {
            this.displayError(e)
          })
        },
        projectChangesearch: function (node, instanceId) {
          if (node && node.length > 3) {
              this.isLoading = true;
              let url = "api/listing/getProjectAllocatedToEmp";
              this.input = {
                  empid: parseInt(this.undt.userid),
                  projectname: node.toLowerCase(),
                  useremail: this.undt.username,
                  empcode: this.undt.userid,
                  isfrompage: 'updatepage'
              };
              axios({
                  method: "POST",
                  url: url,
                  data: this.input,
                  'headers':{'authorization':this.tokendata}
              }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                      this.appenv.projectlist = result.data.data;
                  } else {
                      this.appenv.projectlist = [];
                  }
              }).catch(e => {
            this.displayError(e)
          });
          }
        },
        selenvappl(state,value){
            this.appenv.application=null
            this.appenv.application=state
            if(!state){
                this.errors.appenv.application='Application name is required'
            }else{                
                this.errors.appenv.application=null
            }
        },
        selenvproj(state,value){
            this.appenv.project=null
            this.appenv.project=state
            if(!state){
                this.errors.appenv.project='Project name is required'
                this.appenv.application = null
                this.appenv.applicationlist = null
            }else{                                
                this.getApplicationData('t12')
            }
        },
        redirecttourl (btnstate, projectID) {
          if (btnstate == 'projectId') {
              this.$router.push({
              path: '/project/summary?pid='+projectID,
            })
          }
        }
    }
}
</script>