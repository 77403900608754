<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;margin-bottom: 1rem !important;">
          <div class="card-header" style="cursor: pointer" v-on:click="mysearchrequestollapse">
            <div class="container-fluid" >
            <div class="row fc_my-05">
              <div class="w-auto mobile-padding-0-2" >
                <div class="avatar bg-light-secondary rounded d-inline-flex">
                      <div class="avatar-content">
                        <em class="las la-chart-bar custom-class font-medium-5"></em>
                       
                      </div>
                    </div>
                    <h4 class="card-title d-inline-flex ml-1 ">Project LOC Statistics</h4>
                  </div>
            </div>
            </div>
          </div>
          <div v-bind:class="[{ show: isSearchRequestActive },card_content,collapse,]">
            <div class="card-body p-0">
              <div class="row m-1">
                 <div class="col-sm-12 col-md-12 col-lg-12 rounded canvasjs_bgviews">
                  <div class=" pt-1 pb-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                      <div class="text-align-right w-100 text-center chart_heading_my_activity">
                          <input type="text-left" class="form-control startdate border-0 shadow-none bg-transparent pr-0 chart_input_my_activity"/>{{dateMonthFormat(dayfrom)}}   To   {{dateMonthFormat(dayto)}}
                      </div>
                  </div>
                  <div v-if="chartdetails.length ==0 && isresponce" colspan="14" style="text-align:center;">No LOC added on last 7days</div>
                  <div class="card-body cropside px-0 pt-0 pb-1">

                    <div class="canvasoverflowscrool">
                      
                      <div id="chartdiv" style="width: 100%;"></div>

                      <div class="area" >
                          <ul class="circles">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                          </ul>
                      </div >
                    </div>
                  </div>
                 </div>
              </div>
            </div>
          </div>
        </div>
      <section id="card-navigation">
        <div class="file-manager-content-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card mb-3" id="myActivityReport">
                  <div class="card-header p-2">
                    <h4 class="card-title">Spent Time : Last 7 Days</h4>
                    <h4 class="card-title">Today's Spent Time : {{todayspenttime}} Hrs</h4>
                    <h4 class="card-title">Total Spent Time : {{totalspenttime}} Hrs</h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div class="" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="" id="table-small">
                          <div class="card card-company-table">
                            <div class="card-body p-0">
                              <div class="table-responsive"  v-if="showtabledata">
                                <table
                                  class=" table table-hover table-striped dataTable  table-sm  dataTableListing all_table_stickys" aria-describedby="mydesc">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="srno text-center first-col-sticky py-1" style="width:45px">Sr#</th>
                                      <th scope="col" class="text-left second-col-sticky align-middle" style="width: 160px;">Project Name</th>
                                      <th scope="col" class="text-center  align-middle">LogTimeDate</th>
                                      <th scope="col" class="text-left align-middle" style="width: 140px;">Activity</th>
                                      <th scope="col" class="text-left align-middle">Hours</th>
                                      <th scope="col" class="text-left align-middle" style="width: 210px;">Module Details</th>
                                      <th scope="col" class="text-left align-middle">Business Details</th>
                                      <th scope="col" class="text-left align-middle" style="width: 200px;">Comment</th>
                                      <th scope="col" class="text-center align-middle">Reporting Manager</th>
                                      <th scope="col" class="text-center">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(data, index) in companymasterlist">
                                      <tr v-bind:key="index +Math.floor(Math.random() * 1000000000)">
                                      <td class="srno first-col-sticky">
                                      {{
                                      index + 1 + currPageNum * pagelimit
                                      }}
                                      </td>
                                      <td :class="'text-left capitalisetext second-col-sticky unique' +index +1 +currPageNum * pagelimit" >
                                      {{ data.projectname ? data.projectname : 'NA' }}
                                      </td>
                                    <td class="capitalisetext align-middle ">
                                    {{
                                    data.logdate ? dateFormat( data.logdate): ""
                                    }}
                                    </td>
                                      <td class="align-middle capitalisetext">
                                      {{ data.activity }}
                                      </td>
                                      <td class="align-middle capitalisetext" v-if="data.moduletype=='leave' && data.activitystatus=='approved' ">
                                       {{
                                      data.spenttime ? leavespenttime(data.logdate,data.spenttime).slice(0, 5): "NA"
                                      }}
                                      </td>
                                      <td class="align-middle capitalisetext" v-else>{{ data.spenttime ? data.spenttime.slice(0, 5): "NA" }}</td>
                                      <td>
                                      <span v-if="data.modulename && data.modulename.length>=150">
                                         {{ data.modulename.substring(0,150)+".." }}</span>
                                      <span v-else> {{ data.modulename }}</span>
                                      <hr class="hr_border-bottom">
                                      <span class="heading_column_text"> Type : </span>
                                      <span class="text-dotted font-weight-normal mb-25">{{ data.moduletype ? data.moduletype :"NA" }}</span>
                                      <hr class="hr_border-bottom">
                                      <span class="heading_column_text"> Code : </span>
                                      <span class="text-dotted font-weight-normal mb-25">{{ data.code ? data.code :"NA" }}</span>
                                      </td>
                                      <td class="details_column_table">
                                      <span class="heading_column_text"> Task Code: </span>
                                      <span class="text-dotted font-weight-normal mb-25"> {{ data.business_code }} </span>
                                      <hr class="hr_border-bottom">
                                      <span class="heading_column_text"> Task Name : </span>
                                      <span v-if="data.taskname && data.taskname.length>=50" v-html="data.taskname.substring(0,50)+'..'" ></span>
                                      <span v-else v-html="data.taskname"></span>
                                      </td>
                                      <!-- <td class="text-wrap">
                                      <span v-if="data.coments &&data.coments.length >= 150" v-html="data.coments.substring(0, 150) +'..'"></span>
                                      <span v-else v-html="data.coments"></span>
                                        </td> -->
                                      <td class="text-left tast_case_groupingview_readmore my_activity_readmorelist">
                                           
                                            <div class="col-md-12 mt-0 px-0 tast_case_groupingview_readmore my_activity_readmorelist">
                                                <div class="custom-container case_bg_17">
                                                    <div class="outer" :id="'cycle_readmore2'+index">
                                                        <span class="fc_m16_btcode_text" v-if="data.coments" v-html="formatData(data.coments)"></span>
                                                        
                                                        <span class="fc_m16_btcode_text" v-else v-html="data.coments"></span>
                                                        
                                                    </div>
                                                    <div class="cycle_viewmoretext" v-if="data.coments && data.coments.length > 150">
                                                        <a class="btn">
                                                        <strong :id="'id2'+index" @click="myactivitycomment(index)">
                                                Read More
                                            </strong>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                      <td class="capitalisetext">{{ data.reportingmanager ? data.reportingmanager : 'NA' }}</td>
                                      <td class="capitalisetext">{{ data.activitystatus }}</td>
                                      </tr>
                                    </template>
                                    <template v-if="companymasterlist.length == 0" >
                                      <tr>
                                        <td colspan="14"  style="text-align: center;">
                                          No record found
                                        </td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter text-center pt-2">
                        <pagination v-if="showtabledata" v-model="page"  :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.card-body.cropside{
  position: relative;
}
.canvasjs_bgviews{
  background: #f8f8f8;
}
.title {
  text-align: center;
}
input.mx-input::-webkit-input-placeholder {
  color: #b9b9c3;
  font-size: 14px;
}
input:-moz-placeholder {
  color: #b9b9c3;
  font-size: 14px;
}
input.mx-input {
  color: #000 !important;
}
.cropside{
  overflow: hidden;
}

.canvasoverflowscrool{
  width: 100%;
  display: block;
  /*overflow-x: auto;*/
}
.chart_heading_my_activity{
  font-weight: 600;
}
.chart_input_my_activity{
  display: none!important;
  visibility: visible;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { EyeIcon,SearchIcon,XIcon,ExternalLinkIcon,TrendingUpIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../utils/commonMethods';

export default {
  name: "MyActivityReport",
  mixins: [ commonMethods ],
  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
    DatePicker,TrendingUpIcon
  },
  data() {
    return {
      projectnameList: [],
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      showprojectdata: [],
      showroledata: [],
      showempdata: [],
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      totalspenttime:0,
      todayspenttime:0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      parentmoduledata: null,
      showfilterboxsection: false,
      isSearchRequestActive: true,
      card_content: "card-content",
      collapse: "collapse",
      addformbtntxt: "Next",
      tokendata: null,
      mngrlist: [],
      chartdetails: [],
      dayfrom : new Date(),
      dayto: new Date(), 
      isresponce:false,  
      ocompanymasterlist: [],

    };
  },
  mounted() {
    // let permission = this.$Service.verifyAccess();
    // if(permission){
    var userdata = window.localStorage.getItem("userdata");
    this.tokendata = window.localStorage.getItem("token");
    if (userdata) {
      userdata = JSON.parse(userdata);
      this.undt = userdata;
      this.getBusinessTaskReport();
      // this.getchartdetails();
      this.showLoadingOverlay();
    }
    // }
  },
  methods: {
    showLoadingOverlay() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 5000); // Set the duration of the loading overlay in milliseconds (e.g., 3000 ms = 3 seconds)
    },
    notBeforeToday: function (date) {
      this.search.taskassigneddateto = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.taskassigneddatefrom).format(
        this.TimeFormat
      );
      const beforeToday = new Date(dayBefore);
      beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; // Assume < 25 May Not Selected
    },
    myactivitycomment(index){
            
            if ($("#cycle_readmore2"+index).hasClass("readmore")) {
                $("#id2"+index).html("Read More");
                $("#cycle_readmore2"+index).removeClass("readmore");
            } else {
                $("#id2"+index).html("Read Less");
                $("#cycle_readmore2"+index).addClass("readmore");
            }
        },
    mysearchrequestollapse: function () {
      this.isSearchRequestActive = !this.isSearchRequestActive;
    },
    getBusinessTaskReport(flag = false, ...search) {
      this.isLoading = true;
      if (flag) {
        this.pageoffset = 1;
        this.companymasterlist = [];
        this.totalcount = 0;
        this.pageCount = 0;
        this.page = 1;
        this.totalspenttime=0;
        this.todayspenttime=0;
      }
      //this.page= 1
      let url = "api/reports/empactivityreport";
      this.input = {
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        mangerid: null,
        empcode: this.undt.userid,
      };
      var currdate = new Date();
      var previousdate = new Date();
      var todaysdt = new Date();
      var todaysdt2 = new Date();
      previousdate.setDate(previousdate.getDate() -7)
      currdate.setDate(currdate.getDate() +1)
      todaysdt.setDate(todaysdt.getDate() +0)
      this.input.projectid =  null;
      this.input.empid =  [this.undt.userid];
      this.input.datefrom =   moment(String(previousdate)).format('YYYY-MM-DD');
      this.input.dateto = moment(String(currdate)).format('YYYY-MM-DD');
      this.input.todaysdate = moment(String(todaysdt)).format('YYYY-MM-DD 00:00:00');
      this.input.todaysdate2 = moment(String(todaysdt2)).format('YYYY-MM-DD HH:mm:ss');
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.companymasterlist = result.data.data.rows;
          this.ocompanymasterlist = result.data.data.orows ? result.data.data.orows : []
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          // this.totalspenttime= result.data.data.totalspenttime.hours+":"+result.data.data.totalspenttime.minutes
        //    if((Object.keys(result.data.data.totalspenttime).length)!=0){
        //   let min = result.data.data.totalspenttime.minutes;
        //   if (typeof min !== "undefined") {
        //       this.totalspenttime = result.data.data.totalspenttime.hours+":"+min;
        //   }else{
        //       this.totalspenttime = result.data.data.totalspenttime.hours;
        //   }
        // }else{
        //   this.totalspenttime  = '0'
        // }


        if((Object.keys(result.data.data.totalspenttime).length)!=0){
        
        let min1 = result.data.data.totalspenttime.minutes;
        let hr = result.data.data.totalspenttime.hours

        if(typeof hr== "undefined"){
          this.totalspenttime = result.data.data.totalspenttime.hours='00'+":"+min1;
        }
        else if(typeof min1 !== "undefined"){
          this.totalspenttime = result.data.data.totalspenttime.hours+":"+min1;
        }else{
            this.totalspenttime = result.data.data.totalspenttime.hours;
        }
      }else{
        this.totalspenttime = '0'
      }
        
           if((Object.keys(result.data.data.todayspenttime).length)!=0){
        
          let min1 = result.data.data.todayspenttime.minutes;
          let hr = result.data.data.todayspenttime.hours

          if(typeof hr== "undefined"){
            this.todayspenttime = result.data.data.todayspenttime.hours='00'+":"+min1;
          }
          else if(typeof min1 !== "undefined"){
            this.todayspenttime = result.data.data.todayspenttime.hours+":"+min1;
          }else{
              this.todayspenttime = result.data.data.todayspenttime.hours;
          }
        }else{
          this.todayspenttime = '0'
        }
        this.getchartdetails()
        }
         else {
          this.companymasterlist = [];
          this.ocompanymasterlist = [];
          this.totalcount = 0;
          this.pageCount = 0;
          this.totalspenttime=0;
          this.todayspenttime=0;
          this.getchartdetails()
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getchartdetails(){
      this.isLoading = true;
      var currdate = new Date();
      var previousdate = new Date();
      previousdate.setDate(previousdate.getDate() -7)
      currdate.setDate(currdate.getDate() +1)
      this.dayfrom = moment(String(previousdate)).format('YYYY-MM-DD'),   //'2022-09-01'
      this.dayto =  moment(String(currdate)).format('YYYY-MM-DD'),       //'2022-10-01'
      this.input={
          authoremail: this.undt.emailaddress,
          datefrom: this.dayfrom, 
          dateto: this.dayto,
          useremail: this.undt.username,
          empcode: this.undt.userid,
      }
      axios({
          method: "POST",
          url: "api/reports/getchartdetails",
          data: this.input,
          'headers':{'authorization':this.tokendata}
      }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.chartdetails = result.data.data;
            this.isSearchRequestActive = true;
            //this.getchartshownew();
            this.newChartt(result.data.data);
          } else {
            result.data = [];
            this.isresponce = true;
            this.isSearchRequestActive = false;
            this.chartdetails = result.data;
          }
      }).catch(e => {
        this.displayError(e)
      });
    }, 
    formatData(data) {
            return data
        },
    newChartt1(data) {
        var repolist = [];
        var seriesslist = null;
        let addedseriesslist = [];
        let removedseriesslist = [];
        let addedPointsArr=[];
        let removedPointsArr=[];
        data.forEach((datalist, index) => {
          const { length } = data;
          const id = length + 1;
          const found = repolist.some(el => el.id == datalist.name);
          let isvisible = false;
          let isscale = {
                          syncWith: 'stacked'
                        };
          if(index==0){ isvisible = true ; isscale = {}; }
          addedPointsArr.push({ x: datalist.comitdate, y: parseInt(datalist.locadded) });
          removedPointsArr.push({ x: datalist.comitdate, y: parseInt(datalist.locdeleted) });
          if (!found){
              repolist.push({ id: datalist.name, scale_type: 'stacked', visible: isvisible, scales: isscale, fontSize:12 });
              addedseriesslist.push({
                  name: datalist.name,
                  yAxis: datalist.name,
                  points: addedPointsArr,
              });
              removedseriesslist.push({
                  name: datalist.name,
                  yAxis: datalist.name,
                  points: removedPointsArr,
              });
              seriesslist = addedseriesslist.concat(removedseriesslist)
          } 
        });

        console.log(repolist)
        console.log(seriesslist)
        var chart = JSC.chart('chartDiv1', {
        debug: true,
        type: 'column',
        title_label_text: 'Project / Repository Overview',
        xAxis_label_text: 'Dates',
        yAxis: repolist, /*[
           {
            id: 'stacked',
            scale_type: 'stacked',
            label_text: '',
            orientation: 'opposite',
           
          },
          {
            id: 'stacked2',
            scale_type: 'stacked',
            label_text: '',
            orientation: 'opposite',
            visible:false,
            scale: {
              syncWith: 'stacked'
            }
          },
        ],*/
        series: seriesslist /* [
          {
            "name": "FC-SMART-API",
            "yAxis": "stacked",
            "points": [{
              "x": "2022-09-02",
              "y": 8
            }, {
              "x": "2022-09-15",
              "y": 2216
            }, {
              "x": "2022-09-16",
              "y": 8
            }, {
              "x": "2022-09-20",
              "y": 96
            }, {
              "x": "2022-09-22",
              "y": 400
            }, {
              "x": "2022-09-27",
              "y": 36
            }, {
              "x": "2022-09-28",
              "y": 26
            }, {
              "x": "2022-09-28",
              "y": 344
            }]
          }, {
            "name": "FC-SMART-API",
            "yAxis": "stacked2",
            "points": [{
              "x": "2022-09-02",
              "y": 0
            }, {
              "x": "2022-09-15",
              "y": 0
            }, {
              "x": "2022-09-16",
              "y": 8
            }, {
              "x": "2022-09-20",
              "y": 48
            }, {
              "x": "2022-09-22",
              "y": 32
            }, {
              "x": "2022-09-27",
              "y": 16
            }, {
              "x": "2022-09-28",
              "y": 23
            }, {
              "x": "2022-09-28",
              "y": 48
            }]
          }, {
            "name": "IND-FC-SMART-SCH",
            "yAxis": "stacked",
            "points": [{
              "x": "2022-09-02",
              "y": 8
            }, {
              "x": "2022-09-15",
              "y": 2216
            }, {
              "x": "2022-09-16",
              "y": 8
            }, {
              "x": "2022-09-20",
              "y": 96
            }, {
              "x": "2022-09-22",
              "y": 400
            }, {
              "x": "2022-09-27",
              "y": 36
            }, {
              "x": "2022-09-28",
              "y": 26
            }, {
              "x": "2022-09-28",
              "y": 344
            }]
          }, {
            "name": "IND-FC-SMART-SCH",
            "yAxis": "stacked2",
            "points": [{
              "x": "2022-09-02",
              "y": 0
            }, {
              "x": "2022-09-15",
              "y": 0
            }, {
              "x": "2022-09-16",
              "y": 8
            }, {
              "x": "2022-09-20",
              "y": 48
            }, {
              "x": "2022-09-22",
              "y": 32
            }, {
              "x": "2022-09-27",
              "y": 16
            }, {
              "x": "2022-09-28",
              "y": 23
            }, {
              "x": "2022-09-28",
              "y": 48
            }]
          }
        ]*/
      });
    },
    newChartt(dbdata) {
        var repolist = [];
        var dateslist = [];
        var seriesslist = [];
        dbdata.forEach((datalist, index) => {
          const { length } = dbdata;
          const id = length + 1;
          const foundreponame = repolist.some(el => el.id == datalist.name);
          if (!foundreponame) repolist.push({ id: datalist.name });

          let a = datalist['name'];
          let b = datalist.name+'R';
          var index = seriesslist.findIndex(x => x.dates == datalist['comitdate']);
          const found = seriesslist.some(el => el.dates == datalist['comitdate']);
          if (found && index != -1)  { 
            seriesslist[index][a] = parseInt(datalist.locadded);
            seriesslist[index][b] = parseInt(datalist.locdeleted);
          } else {
            seriesslist.push({
              "dates": datalist.comitdate,
              [a]: parseInt(datalist.locadded),
              [b]: parseInt(datalist.locdeleted),
              //"names": datalist.name,
            }) 
          }
        });
        // console.log(seriesslist)
        // console.log(dbdata)

        am5.ready(function() {
        var root = am5.Root.new("chartdiv");
        root._logo.dispose(); // hide the logo

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
          am5themes_Animated.new(root)
        ]);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          // wheelY: "zoomX",
          layout: root.verticalLayout,
          
        }));

        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        var legend = chart.children.push(am5.Legend.new(root, {
          centerX: am5.p53,
          x: am5.p53,
          
        }));

        var data = seriesslist; /* [
            {
                "dates": "2022-09-02",
                "FC-SMART-API": 8,
                "FC-SMART-APIR": 0,
                "names": "FC-SMART-API"
            },
            {
                "dates": "2022-09-15",
                "FC-SMART-API": 2216,
                "FC-SMART-APIR": 0,
                "names": "FC-SMART-API"
            },
            {
                "dates": "2022-09-16",
                "FC-SMART-API": 8,
                "FC-SMART-APIR": 8,
                "names": "FC-SMART-API"
            },
            {
                "dates": "2022-09-20",
                "FC-SMART-API": 96,
                "FC-SMART-APIR": 48,
                "names": "FC-SMART-API"
            },
            {
                "dates": "2022-09-22",
                "IND-FC-SMART-SCH": 300,
                "IND-FC-SMART-SCHR": 200,
                "FC-SMART-API": 500,
                "FC-SMART-APIR": 200,
                "names": "FC-SMART-API"
            },
            {
                "dates": "2022-09-27",
                "FC-SMART-API": 36,
                "FC-SMART-APIR": 16,
                "names": "FC-SMART-API"
            },
            {
                "dates": "2022-09-28",
                "IND-FC-SMART-SCH": 26,
                "IND-FC-SMART-SCHR": 23,
                "FC-SMART-API": 344,
                "FC-SMART-APIR": 48,
                "names": "FC-SMART-API"
            }
        ];*/

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
          categoryField: "dates",
          renderer: am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
          }),
          tooltip: am5.Tooltip.new(root, {
            
          })
        }));


        xAxis.data.setAll(data);

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
          min: 0,
          renderer: am5xy.AxisRendererY.new(root, {})
        }));

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function makeSeries(name, fieldName, stacked) {
          var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            stacked: stacked,
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: fieldName,
            categoryXField: "dates"
            
          }));

          series.columns.template.setAll({
            tooltipText: "{name}, {categoryX}:{valueY}",
            width: am5.percent(90),
            width: 50,
            cornerRadiusTL: 2,
            cornerRadiusTR: 2,
            tooltipY: am5.percent(10)
          });
          series.data.setAll(data);

          // Make stuff animate on load
          // https://www.amcharts.com/docs/v5/concepts/animations/
          series.appear();

          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationY: 0.5,
              sprite: am5.Label.new(root, {
                text: "{valueY}",
                fill: root.interfaceColors.get("alternativeText"),
                centerY: am5.percent(50),
                centerX: am5.percent(50),
                fontSize: 15,
                populateText: true
              })
            });
          });
          legend.data.push(series);
        }

        repolist.forEach((repo, i) => {
          makeSeries(repo.id+' - ADDED', repo.id, false);
          makeSeries(repo.id+' - REMOVED', repo.id+'R', true);
        });

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);

        }); // end am5.ready()

    },
    getchartshownew(){
      var ctx = $('.my-chartjs');
      let xdateValuesArray = [];
      let yValuesArray = [];
      let finalData = [];
      let RepoArray = [];
      let maxCount = 0;
      let CountStep = 0;
      let colorArray = ['pink','skyblue','lightgreen','orange','red','green','blue','yellow','indigo', 'violet']
      let labelColor = ['#6e6b7b','#da28c8','#1525b0'];

        RepoArray = this.chartdetails.map(function(value) {
          return value.name;
        });
        RepoArray =  RepoArray.filter((item, i, ar) => ar.indexOf(item) === i)      

      for(let i=0; i< RepoArray.length; i++){
        let temp = {
          label:RepoArray[i],
          backgroundColor:colorArray[i],
          data:[],
          dates:[],
          barThickness: 50,
        }
         finalData.push(temp);
      } 

      for(let c = 0; c < this.chartdetails.length; c++){

         for(let f = 0; f < finalData.length; f++){

            if(this.chartdetails[c]['name'] === finalData[f]['label']){
           
              if (finalData[f]['dates'].includes(this.chartdetails[c]['comitdate'])) {

                var dateindex = finalData[f]['dates'].indexOf(this.chartdetails[c]['comitdate']);
                 //Addition Logic
                
                finalData[f]['data'][dateindex] = parseInt(finalData[f]['data'][dateindex]) + parseInt(this.chartdetails[c]['locadded']);
              }else{
                //Data push
                finalData[f]['data'].push(parseInt(this.chartdetails[c]['locadded']));
                finalData[f]['dates'].push(this.chartdetails[c]['comitdate']);
              }
            }else{
                if (finalData[f]['dates'].includes(this.chartdetails[c]['comitdate'])) {

                  var dateindex = finalData[f]['dates'].indexOf(this.chartdetails[c]['comitdate']);
                  //Addition Logic
                  finalData[f]['data'][dateindex] = parseInt(finalData[f]['data'][dateindex]) + 0 ; 

                }else{
                  //Data push
                  finalData[f]['data'].push(0);
                  finalData[f]['dates'].push(this.chartdetails[c]['comitdate']);
                }
            }
          }
       }
       //Data For X-Axis Start
      if (this.chartdetails.length > 0) {
        for(let i=0; i<this.chartdetails.length; i++){
          if(!xdateValuesArray.includes(this.dateMonthFormat(this.chartdetails[i].comitdate))){

             xdateValuesArray.push(this.dateMonthFormat(this.chartdetails[i].comitdate));
          }
          yValuesArray.push(parseInt(this.chartdetails[i].locadded));
        }
      }
      maxCount = yValuesArray.reduce((a, b) => Math.max(a, b), -Infinity);
      maxCount = Math.ceil(maxCount/500) * 500
      CountStep = (maxCount/100)*10;  

      var data = {
        labels: xdateValuesArray,
        datasets:finalData ,
      };
      //Data For X-Axis End
      //Dra Graph Start
      var myBarChart = new Chart(ctx, {

        type: 'bar',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          barValueSpacing: 10,
          scales: {
            yAxes: [{
              ticks: {
                stepSize: CountStep,
                  min: 0,
                  max: maxCount,
                  fontColor: labelColor
              }
            }]
          }
        }
      });
      //Dra Graph End
    },
    resetRecords: function () {
      this.search.searchProjectname = null;
      this.search.searchTaskStatus = null;
      this.search.allotedto = null;
      this.search.taskassigneddateto = null;
      this.search.taskassigneddatefrom = null;
      this.search.mngrname = null;
      let rowdata = {};
      this.getBusinessTaskReport(rowdata);
      localStorage.removeItem("searchItems");
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.parentmoduledata = {};
        this.parentmoduledata.isPaginationreq = true;
        this.getBusinessTaskReport(false, this.parentmoduledata);
      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#myActivityReport").offset().top -70
            });
        }, 500);
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format("DD-MM-YYYY");
    },
    dateMONFormat(value) {
      if (!value) return "";
      return moment(String(value)).format("DD-MM-YYYY");
    },
    dateMonthFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD-MM-YYYY');
    },
    leavespenttime(logdate,leavetypetime){

let dlogdate = moment(String(logdate)).format("YYYY-MM-DD")

let workspentresult = this.ocompanymasterlist.filter(leaveObj => moment(String(leaveObj.logdate)).format("YYYY-MM-DD") === dlogdate && leaveObj.moduletype!='leave');

let leavespentresult = this.ocompanymasterlist.filter(leaveObj => moment(String(leaveObj.logdate)).format("YYYY-MM-DD") === dlogdate && leaveObj.moduletype=='leave');

let totalworktime = workspentresult.reduce((totaltime,workentry)=>{
return this.addTimes(totaltime,workentry.spenttime)
},'00:00:00')

let totalleavetime = leavespentresult.reduce((totaltime,workentry)=>{
return this.addTimes(totaltime,workentry.spenttime)
},'00:00:00')

if((this.timeToMilliseconds(totalworktime)) >= (this.timeToMilliseconds(totalleavetime))){
return '00:00';
}

 //halfday
if(leavetypetime =='04:00:00'){
 
if((this.timeToMilliseconds(totalworktime)) <= this.timeToMilliseconds('04:00:00') &&  this.timeToMilliseconds(totalleavetime) == this.timeToMilliseconds('04:00:00')){
  let leavetm = this.subtractTimes('04:00:00',totalworktime)
    return leavetm;
}
if((this.timeToMilliseconds(totalworktime)) > this.timeToMilliseconds('08:00:00')){
    return '00:00';
}

if((this.timeToMilliseconds(totalworktime)) <= this.timeToMilliseconds('04:00:00') &&  this.timeToMilliseconds(totalleavetime) > this.timeToMilliseconds('04:00:00')){
   let leavetm = this.subtractTimes('08:00:00',totalworktime)
 let dydate = this.divideTime(leavetm,2)
       return dydate;
     
}


if((this.timeToMilliseconds(totalworktime)) > this.timeToMilliseconds('04:00:00') && (this.timeToMilliseconds(totalworktime)) <= this.timeToMilliseconds('08:00:00')){
  let leavetm = this.subtractTimes('08:00:00',totalworktime)
        return leavetm;
}
}

 else if(leavetypetime =='08:00:00'){
 
if((this.timeToMilliseconds(totalworktime)) <= this.timeToMilliseconds('08:00:00')){
  let leavetm = this.subtractTimes('08:00:00',totalworktime)
    return leavetm;
}
if((this.timeToMilliseconds(totalworktime)) > this.timeToMilliseconds('08:00:00')){
    return '00:00';
} 

}else{

  return totalleavetime
}

 },

addTimes(time1, time2) {
const [hours1, minutes1, seconds1] = time1.split(":").map(Number);
const [hours2, minutes2, seconds2] = time2.split(":").map(Number);

let totalSeconds = seconds1 + seconds2;
let totalMinutes = minutes1 + minutes2;
let totalHours = hours1 + hours2;

if (totalSeconds >= 60) {
totalSeconds -= 60;
totalMinutes++;
}

if (totalMinutes >= 60) {
totalMinutes -= 60;
totalHours++;
}

const formattedHours = String(totalHours).padStart(2, "0");
const formattedMinutes = String(totalMinutes).padStart(2, "0");
const formattedSeconds = String(totalSeconds).padStart(2, "0");

return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
},
timeToMilliseconds(time) {
const [hours, minutes, seconds] = time.split(":");
return (+hours * 3600 + +minutes * 60 + +seconds) * 1000;
},
subtractTimes(time1, time2) {
const [hours1, minutes1, seconds1] = time1.split(':').map(Number);
const [hours2, minutes2, seconds2] = time2.split(':').map(Number);

let totalSeconds = seconds1 - seconds2;
let totalMinutes = minutes1 - minutes2;
let totalHours = hours1 - hours2;

if (totalSeconds < 0) {
totalSeconds += 60;
totalMinutes--;
}

if (totalMinutes < 0) {
totalMinutes += 60;
totalHours--;
}

const formattedHours = String(totalHours).padStart(2, '0');
const formattedMinutes = String(totalMinutes).padStart(2, '0');
const formattedSeconds = String(totalSeconds).padStart(2, '0');

return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
},
divideTime(timeString, divisor) {
  var timeComponents = timeString.split(':');
  
   var hours = parseInt(timeComponents[0]);
  var minutes = parseInt(timeComponents[1]);
  var seconds = parseInt(timeComponents[2]);
  
   var totalSeconds = hours * 3600 + minutes * 60 + seconds;
  
   var dividedSeconds = Math.floor(totalSeconds / divisor);
  
   var updatedHours = Math.floor(dividedSeconds / 3600);
  var updatedMinutes = Math.floor((dividedSeconds % 3600) / 60);
  var updatedSeconds = dividedSeconds % 60;
  
   var updatedTime = updatedHours.toString().padStart(2, '0') + ':' +
                    updatedMinutes.toString().padStart(2, '0') + ':' +
                    updatedSeconds.toString().padStart(2, '0');
  
  return updatedTime;
},
  },
};
</script>