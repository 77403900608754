<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="modern-horizontal-wizard">
          <div class="row breadcrumbs-top">
            <div class="col-12 d-flex">
              <h3 class="content-header-title float-left mb-1">Appraisal Cycle Master</h3>
              <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item sitebluecolor cursorpointercls">Appraisal Cycle List</li>
                  </ol>
              </div>
            </div>
          </div>
          <div class="add_apprisalcycle_view" >
            <div>
              <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchrequest">
                    <div class="container-fluid" >
                        <div class="row" >
                            <div class="w-auto px-1 mobile-padding-0-2">
                              <div class="avatar bg-light-secondary rounded d-inline-flex">
                                  <div class="avatar-content">
                                      <search-icon
                                          size="1.5x"
                                          class="custom-class avatar-icon font-medium-3"
                                      ></search-icon>
                                  </div>
                              </div>
                            <h4 class="card-title d-inline-flex ml-1">Search Appraisal Cycle List</h4>
                            </div>
                            <div class="ml-auto d-flex">
                                <div v-if="pagefeatures.createcycle" class="px-1 text-right mobile-padding-0-2" >
                                    <div class="input-group-append float-right">
                                        <router-link to="/appraisalcyclemaster/create"><span class="btn btn-sm btn-relief-success" ><span class="mobile_hide_bt">Create Appraisal Cycle </span> <plus-square-icon size="1.5x"></plus-square-icon> </span></router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{show: searchFilter},card_content,collapse]">
                  <div class="card-body pt-1">
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                          <label class="form-label lable-left">Name of the Appraisal</label>
                          <input type="text" class="form-control" placeholder="Enter Name of the Appraisal"  v-model="search.appraisalName"/>
                      </div>
                      <div class="col-md-6 col-md-6 col-lg-4 mb-1">
                        <div class="date_picker_foundeds">
                            <label for="">Appraisal Cycle Date From</label>
                            <div class="input-group input-group-merge">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> 
                                <user-icon size="1.5x" class="custom-class avatar-icon"></user-icon></span>
                            </div>
                            <date-picker placeholder="Select Date From" v-model="search.appraisalCyclePeriodFrom" :disabled-date="notBeforeToday" valueType="format" class="Startdate"  @input="selectedstartdate"></date-picker>
                           <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search appraisal cycle.</div>                 
                            </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-md-6 col-lg-4 mb-0">
                          <div class="date_picker_foundeds">
                              <label for="">Appraisal Cycle Date To</label>
                              <div class="input-group input-group-merge">
                              <div class="input-group-prepend">
                                  <span class="input-group-text"><user-icon size="1.5x" class="custom-class avatar-icon"></user-icon></span>
                              </div>
                              <date-picker placeholder="Select Date To" v-model="search.appraisalCyclePeriodTo" valueType="format" class="enddate" :default-value="new Date()" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                              </div>
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-12 col-lg-12 mb-0  mobile-px-0 text-right">
                          <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="getAppraisalCycleMasterList(search)"> 
                          <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                          <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                          <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                  <div class="card border-warning" id="domain-list">
                  <div class="card-body p-0 ps ps--active-x">
                    <div class="table-responsive" >
                      <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
                        <thead>
                          <tr>
                            <th scope="col" class="text-center first-col-sticky" style="min-width:45px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
                            <th scope="col" class="text-center second-col-sticky">Appraisal Name</th>
                            <th scope="col" class="text-center">Appraisal cycle Period From</th>
                            <th scope="col" class="text-center">Appraisal cycle Period To</th>
                            <th scope="col" class="text-center">Description</th>
                            <th scope="col" class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                            <template v-for="(data,index) in masterList"  >
                                
                            <tr v-if="!data.edit" v-bind:key="index" >
                              <td class="text-center first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                              <td class="text-center capitalisetext second-col-sticky">{{ data.name }}</td>
                              <td class="text-center capitalisetext">{{ data.apprisalcycleperiod_from }}</td>
                              <td class="text-center capitalisetext">{{ data.apprisalcycleperiod_to }}</td>
                              <td class="text-center text-capitalize">
                                <div>
                                  <span v-if="data.description && data.description.length >= 100" v-html="data.description.substring(0,100)+'...'"></span>
                                  <span v-else v-html="data.description"></span>
                                  <span v-if ="!data.description">NA</span>
                                </div>
                              </td>
                              <td class="centeralign">
                                <div class="dropdown">
                                  <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                    <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                  </button>
                                  <div class="dropdown-menu">
                                    <a v-if="pagefeatures.createcycle" class="dropdown-item devEditEle" @click="activateEditRow(data,index)" style="cursor: pointer;" href="javascript:void(0);">
                                      <div class="d-flex align-items-center sitebluecolor">
                                        <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                        <span class="text-body font-weight-bold sitebluecolor" >Edit</span>
                                      </div>
                                    </a>
                                    <a v-if="pagefeatures.addemployee" class="dropdown-item devEditEle"  @click="redirecttourl('add_appraisal_applicable_emps', data)"  style="cursor: pointer;" href="javascript:void(0);">
                                      <div class="d-flex align-items-center sitebluecolor"> 
                                          <plus-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></plus-icon>
                                          <span class="text-body font-weight-bold">Add Employees</span>
                                      </div>
                                    </a>
                                    <a v-if="pagefeatures.addtracker && data.totalemployees > 0"  class="dropdown-item devEditEle"  @click="redirecttourl('add_appraisal_trackers', data)"  style="cursor: pointer;" href="javascript:void(0);">
                                      <div class="d-flex align-items-center sitebluecolor"> 
                                          <plus-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></plus-icon>
                                          <span class="text-body font-weight-bold">Add Trackers</span>
                                      </div>
                                    </a>
                                    <a v-if="pagefeatures.sendemails && data.totalempreviewer > 0" class="dropdown-item devEditEle"  @click="redirecttourl('send_appraisal_mails', data)"  style="cursor: pointer;" href="javascript:void(0);">
                                      <div class="d-flex align-items-center sitebluecolor"> 
                                          <mail-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></mail-icon>
                                          <span class="text-body font-weight-bold">Send Emails</span>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr v-else v-bind:key="index">
                              <td class="text-center first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>

                              <td class="text-center first-col-sticky">
                                <input type="text" class="form-control" placeholder="Enter name" v-model="data.name"  />
                                <div class="errorinputmsg" v-if="errors.name != null">{{ errors.name }}</div>
                              </td>
                              <td class="box-inc second-col-sticky">
                                <date-picker class="startdate" placeholder="To" valueType="format" :default-value="new Date()" v-model="data.apprisalcycleperiod_from" :disabled-date="updateDateFrom" ></date-picker>
                               <div class="errorinputmsg" v-if="errors.dateFrom != null">{{ errors.dateFrom }}</div> 
                              </td>
                              <td class="box-inc second-col-sticky">
                                <date-picker class="startdate" placeholder="To" valueType="format" :default-value="new Date()" v-model="data.apprisalcycleperiod_to" :disabled-date="updateDateFrom"></date-picker>
                                <div class="errorinputmsg" v-if="errors.dateTo != null">{{ errors.dateTo }}</div>
                                
                              </td>
                              <td class="box-inc ">
                                <input type="text" class="form-control" placeholder="Enter description" v-model="data.description"/>
                              </td>
                              <td class="centeralign flex--items">
                                <button type="submit" class="btn btn-relief-primary mr-1" style="padding-left: 1rem; padding-right:1rem" v-on:click.stop.prevent="saveUpdateData(data)"><check-circle-icon size="1.5x" class="custom-class"></check-circle-icon></button>
                                <button type="submit" class="btn btn-relief-info" style="padding-left: 1rem; padding-right:1rem;" v-on:click.stop.prevent="cancelUpdate(index)"><x-circle-icon size="1.5x" class="custom-class"></x-circle-icon></button>
                              </td>
                            </tr>
                          </template>
                          <template v-if="masterList.length==0">
                            <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <div class="pageCenter text-center mt-2">
                      <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                    </div>
                  </div>
                </div>             
              
            </div>
          </div>
        </section>
      </div>
    </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {Trash2Icon,PlusSquareIcon, PlusIcon, MinusSquareIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, XIcon, UserIcon, MoreVerticalIcon, Edit2Icon, MailIcon } from "vue-feather-icons";
import apiUrl from '../../constants';
import commonMethods from '../../utils/commonMethods';
import Pagination from 'vue-pagination-2'
import moment from 'moment'
export default {
  name: "ListingAppraisalCycle",
  components: {
    Loading,
    VueElementLoading,Trash2Icon,PlusSquareIcon, MinusSquareIcon,
    vSelect,
    Treeselect,
    DatePicker,
    ckeditor: CKEditor.component,
    Pagination,
    EditIcon,
    CheckCircleIcon,
    XCircleIcon,
    SearchIcon,
    XIcon,
    UserIcon,
    MoreVerticalIcon,
    Edit2Icon,
    PlusIcon,
    MailIcon,
  },
  mixins: [ commonMethods ],
  data() {
    return {
      isLoading: false,
      fullPage: true,
      appraisalName: "",
      appraisalCyclePeriodFrom: null,
      appraisalCyclePeriodTo: null,
      description: null,
      editor: ClassicEditor,

      masterList:[],
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,

      searchFilter: false,
      collapse:'collapse',
      card_content:'card-content',
      search :{
        appraisalName: null,
        appraisalCyclePeriodFrom: null,
        appraisalCyclePeriodTo: null
      },
      isBothDateSelected:false,
      editdateFrom: null,
      editdateTo: null,
  
      errors: {
        dateFrom:null,
        dateTo:null,
        name:null
      }
      
    };
  },
  created() {
    this.pagefeatures = {};
    let pagefeatures1 = JSON.parse(
      window.atob(window.localStorage.getItem("pagefeatures"))
    );
    pagefeatures1.forEach((element) => {
      if (element.page === "/appraisalcyclemaster") {
        this.pagefeatures[element.featurename] = element.featureaccess;
      }
    });
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem("userdata");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.tokendata = window.localStorage.getItem('token');
        this.loginuscondition = userdata.userid;
        if(this.$route.params && this.$route.params.search) {
          let search = JSON.parse(localStorage.getItem('appmastersearchItems'));
          if(search) {
            this.getSearchItems(search);
          }else{
            localStorage.removeItem('appmastersearchItems');
            this.getAppraisalCycleMasterList();
          }
        } else {
          localStorage.removeItem('appmastersearchItems');
          this.getAppraisalCycleMasterList();
        }
      }
    }
  },
  methods: {
    restrictSpecialCharsName(inputval,e) {
        if (/[^A-Za-z0-9\s]/.test(this.inputval)) {
          this.invalidInput = true;
        } else {
          this.invalidInput = false;
        }
        inputval.replace(/[^A-Za-z0-9\s]/g, "");
      },
    getSearchItems(search){
      this.searchFilter = true;
      this.search.appraisalName = search.appraisalName;
      this.search.appraisalCyclePeriodFrom = search.appraisalCyclePeriodFrom;
      this.search.appraisalCyclePeriodTo = search.appraisalCyclePeriodTo;
      this.getAppraisalCycleMasterList(search);
    },
    redirecttourl(btnstate,rowdata) {
      rowdata.backbtnstate=btnstate
      if(btnstate === "add_appraisal_applicable_emps") {
        this.$router.push({
          path: `/appraisalcyclemaster/addApplicableEmployees?id=${rowdata.id}`,
          params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
          }
        })
      } else if(btnstate === "add_appraisal_trackers") {
        this.$router.push({
          path: `/appraisalcyclemaster/addAppraisalTrackers?id=${rowdata.id}`,
          params: {
            backbtnstate: btnstate,
            clickeddata: rowdata
          }
        })
      } else if(btnstate === "send_appraisal_mails") {
        this.$router.push({
          path: `/appraisalcyclemaster/appraisalApplicableEmployees?id=${rowdata.id}`,
        })
      }
    },
    getAppraisalCycleMasterList(search){
      if(search){
        localStorage.setItem('appmastersearchItems', JSON.stringify(search));
      }
      this.isLoading = true
      this.input = {
        useremail  : this.undt.username,
        empcode: this.undt.userid,
        limit: apiUrl.LIMIT,
        offset: this.pageoffset,
      }
      if (search) {
        this.input.appraisalName = this.search.appraisalName
        this.input.appraisalCyclePeriodFrom = this.search.appraisalCyclePeriodFrom
        this.input.appraisalCyclePeriodTo = this.search.appraisalCyclePeriodTo
      }
      let url = 'api/master/getAppraisalCycleMasterList'
      axios({
        method: 'POST',
        url,
        data: this.input,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.masterList = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        } else {
          this.masterList=[];
          this.totalcount = 0;
          this.pageCount = 0;
        }
      }).catch(e => {
                    this.displayError(e)
      });
    },
    clickCallback: function(pageNum) {
     this.pageoffset=pageNum
     this.currPageNum = pageNum - 1
     this.getAppraisalCycleMasterList();
     setTimeout(function(){
     }, 500);
   },
   activateEditRow:function(data,idx){
              this.isLoading = true
         data.edit = true
         this.editdateFrom = data.apprisalcycleperiod_from
         this.editdateTo = data.apprisalcycleperiod_to
        //  this.updateselectedDate('',data)
               this.isLoading = false
   },
   cancelUpdate(idx) {
     this.masterList = this.masterList.map((data, i) => {
       if(i === idx) {
         return { ...this.masterList[idx], edit: false }
       }
       return data
     })
     this.getAppraisalCycleMasterList(this.search);
   },
   saveUpdateData(data) {
    let isValid = true
      if (data.apprisalcycleperiod_to == null) {
        this.errors.dateTo = 'Date to is required'
        isValid = false
      } else {
        this.errors.dateTo = null
      }
      if (data.apprisalcycleperiod_from == null) {
        this.errors.dateFrom = 'Date From is required'
        isValid = false
      } else {
        this.errors.dateFrom = null
      }
      if(!data.name) {
        this.errors.name = 'Appraisal name is required'
        isValid = false
      } else {
        if (/[^A-Za-z0-9\s]/.test(data.name)) {
          this.invalidInput = true;
          isValid = false
          this.errors.name = 'Appraisal name not allowed special chars'
        } else {
          this.invalidInput = false;
          this.errors.name = ''
        }
      
      }

    if (isValid){
     this.input = {
      useremail: this.undt.username,
              empcode: parseInt(this.undt.userid),
      appraisalName: data.name,
      appraisalCyclePeriodFrom : data.apprisalcycleperiod_from,
      appraisalCyclePeriodTo: data.apprisalcycleperiod_to,
      description: data.description,
      id : parseInt(data.id)
    }
    
    let apiURL='api/master/updateAppraisalCycleMaster'  
    axios({
      'method': 'POST',
      'url': apiURL,
      'data': this.input,
      'headers':{'authorization':this.tokendata}
    })
    .then(result =>{
        this.isLoading = false;
        if(result.data.errorCode == 0){
        Swal.fire({
            title: "Success!",
            text: result.data.msg,
            icon: 'success',
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: !1
        })
        this.getAppraisalCycleMasterList(this.search)
        }else if(result.data.errorCode == 3){
            Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                    buttonsStyling: !1,
            }).then(function() {
                window.location.href = "/#/login";
            })
        } else {
            Swal.fire({
                title: "Failed",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
            })
        }
     }).catch(e => {
          this.displayError(e)
      })
    }
   },
   searchrequest(){
    this.searchFilter = !this.searchFilter
   },
   resetRecords() {
    this.search.appraisalName = null;
    this.search.appraisalCyclePeriodFrom = null;
    this.search.appraisalCyclePeriodTo = null;
    localStorage.removeItem('appmastersearchItems');
    this.getAppraisalCycleMasterList();
   },
   notBeforeToday: function (date) {
      this.search.appraisalCyclePeriodTo = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.startdatefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
              beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },

    updateDateFrom(date){
      let dayBefore = moment(this.editdateFrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
              beforeToday.setHours(0, 0, 0, 0);
      return date <= beforeToday;
    },

    selectedstartdate(){
      if((this.search.appraisalCyclePeriodFrom && this.search.appraisalCyclePeriodTo) || (!this.search.appraisalCyclePeriodFrom && !this.search.appraisalCyclePeriodTo)){
          this.isBothDateSelected = false
      }else if((this.search.appraisalCyclePeriodFrom && !this.search.appraisalCyclePeriodTo) ||( !this.search.appraisalCyclePeriodFrom && this.search.appraisalCyclePeriodTo)){
          this.isBothDateSelected = true
      }
    },
    selectedenddate(){
      if((this.search.appraisalCyclePeriodFrom && this.search.appraisalCyclePeriodTo) || (!this.search.appraisalCyclePeriodFrom && !this.search.appraisalCyclePeriodTo)){
          this.isBothDateSelected = false
      }else if((this.search.appraisalCyclePeriodFrom && !this.search.appraisalCyclePeriodTo) ||( !this.search.appraisalCyclePeriodFrom && this.search.appraisalCyclePeriodTo)){
          this.isBothDateSelected = true
      }
    },
  },
};
</script>
<style>
  .displayblk {
    display: block !important;
  }
  .createbtn {
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
  }
  input.mx-input::-webkit-input-placeholder {
  color: #b9b9c3;
  font-size: 14px;
  }
  input:-moz-placeholder {
  color: #b9b9c3;
  font-size: 14px;
  }
  input.mx-input{
    color: #000 !important
  }
  </style>
