<template>
  <div class="container">
    <div class="col-md-12 px-0">
        <fieldset class="progressBarDiv fieldset_border_vi mb-2 mx-1 business_fieldset_bgfieldset_border_vi mb-2  business_fieldset_bg">
            <legend>Business Tasks Progress Bar</legend>
            <div class="row">
                <div class="col-md-12">
                    <div class="card-body" style="padding:0px;">
                        <div class="table-responsive" >
                            <table class="table table-hover project_name_member_text_01 proj_member_text_013 all_table_stickys" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th class="first-col-sticky"><b>BT Progress</b></th>
                                        <th class="blinkers_subbtc" v-for="status in this.formstaskstatus">
                                            <span class="text-color blink-hard" v-if="(tstatus &&tstatus == status.label)"><b>{{ status.label }}</b></span>
                                            <span v-else><b>{{ status.label }}</b></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                        <tr>
                                            <td class="first-col-sticky"><b>No. Of Tasks and Issues</b></td>
                                            <td v-for="(scount, i) in tasksrows[0]" :key="'task'+i">
                                                <p v-if="(scount || issuesrows[0][i])"> {{ additionOfTaskAndIssue(parseInt(scount), parseInt(issuesrows[0][i])) }}</p>
                                                <p v-else>0 %</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="first-col-sticky">
                                                <b>% Complete</b>
                                            </td>
                                            <td v-for="(sccount, k) in completedTaskCount[0]" :key="'issue'+k">
                                                <p v-if="sccount !=0 && tasksrows[0][k] != 0"> {{ Math.round(( additionOfTaskAndIssue(parseInt(sccount), parseInt(completedIssuesCount[0][k])) / additionOfTaskAndIssue(parseInt(tasksrows[0][k]), parseInt(issuesrows[0][k])) * 100) * 100) / 100 }} %</p>
                                                <p v-else>0 %</p>
                                            </td>
                                        </tr>
                                        
                                        <tr v-if="this.flagg==1">
                                            <td class="first-col-sticky"><b>Micro Task Start Date</b></td>
                                            <td v-for="status in this.formstaskstatus">
                                                <b v-if="dateDetailsREs.length>0"> {{ dateDetailsREs.filter(i => i.modulestatus == status.id).length>0 ? (dateDetailsREs.filter(i => i.modulestatus == status.id)[0].taskstartdate!=null) ? dateFormatnew(dateDetailsREs.filter(i => i.modulestatus == status.id)[0].taskstartdate) : 'NA' : 'NA' }} </b>
                                                <b v-else>NA</b>
                                            </td>
                                        </tr>
                                        <tr v-if="this.flagg==1">
                                            <td class="first-col-sticky"><b>Micro Task End Date</b></td>
                                            <td v-for="status in this.formstaskstatus">
                                                <b v-if="dateDetailsREs.length>0"> {{ dateDetailsREs.filter(i => i.modulestatus == status.id).length>0 ? (dateDetailsREs.filter(i => i.modulestatus == status.id)[0].taskenddate!=null) ? dateFormatnew(dateDetailsREs.filter(i => i.modulestatus == status.id)[0].taskenddate) : 'NA' : 'NA' }} </b>
                                                <b v-else>NA</b>
                                            </td>
                                        </tr>
                                        <tr v-if="this.flagg==1">
                                            <td class="first-col-sticky"><b>Micro Task Closed Date</b></td>
                                            <td v-for="status in this.formstaskstatus">
                                                <b v-if="dateDetailsREs.length>0"> {{ dateDetailsREs.filter(i => i.modulestatus == status.id).length>0 ? (dateDetailsREs.filter(i => i.modulestatus == status.id)[0].closeddate!=null) ? dateFormatnew(dateDetailsREs.filter(i => i.modulestatus == status.id)[0].closeddate) : 'NA' : 'NA' }} </b>
                                                <b v-else>NA</b>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
            </div>
            </div>
        </fieldset>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import profilepic from '../profilepic.vue';
import moment from 'moment';
import commonMethods from '../../utils/commonMethods';


export default {
    name: 'BTCProgressBar',
    mixins: [ commonMethods ],

    components: {
        VueElementLoading,Loading,profilepic
    },
    data() {
        return {
            tasksrows: [],
            completedTaskCount: [],
            issuesrows: [],
            completedIssuesCount: [],
            formstaskstatus: [],
            dateDetailsREs: [],
        }
    },
    props: {
        bid: Array,
        tstatus: String,
        flagg: Number,
    },
    mounted(){
        window.addEventListener('storage', function(event){
          if (event.key == 'logout-mee') { 
              window.location.reload();
          }
        });
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.userrole = window.localStorage.getItem("userrole")
            this.loginusername=userdata.username
            this.loginpersonname=userdata.firstname+' '+userdata.lastname;
            this.loginuscondition = userdata.userid
            this.pagefeatures = {}
            this.taskmodule = window.localStorage.getItem("taskmodule")
            let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
                if (element.page === '/businesstask/list') {
                    this.pagefeatures[element.featurename] = element.featureaccess
                }
            })
            this.getBTCTaskAndIssueCounts(this.bid)
            this.getBtcStatusList();
        }
    },
    methods: {
        getBtcStatusList() {
            let url = "api/btc/getBtcStatusList";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.formstaskstatus = result.data.data.rows;
                } else {
                    this.formstaskstatus = [];
                }
            }).catch(e => {
              this.displayError(e)
            });     
        },
        additionOfTaskAndIssue(n1, n2) {
            if(n1 != null && n2 != null) {
                return n1+n2;
            } else {
                return 0;
            }
        },
        getBTCTaskAndIssueCounts(id) {
            this.isLoading = true
            let url = "api/bt/getBTCTaskAndIssueCounts";
            this.input = {
                id: id,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                issubtask: this.flagg==1?true:false,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasksrows = result.data.data.tasksrows;
                    this.completedTaskCount = result.data.data.completedTasksCount;
                    this.issuesrows = result.data.data.issuesrows;
                    this.completedIssuesCount = result.data.data.completedIssuesCount;
                    this.dateDetailsREs = result.data.data.dateDetailsREs;
                } else {
                    this.tasksrows = [];
                    this.completedTaskCount = [];
                    this.issuesrows = [];
                    this.completedIssuesCount = [];
                    this.dateDetailsREs = [];
                }
            }).catch(e => {
          this.displayError(e)
        });
        },
        dateFormatnew(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        },
    },
}
</script>