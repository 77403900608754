<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-1 pad_top_mar_bot-5">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h3 class="content-header-title float-left mb-0">EM Release Note Report</h3>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/reports/releasenoteemreport'>EM Release Note Report</router-link></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-10 col-md-9 mobile_margin-px-0_00 d-flex align-items-center">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1 mobile_headercommon_width">Search Release Note Details</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                      <em class="las la-angle-down"></em>
                    </span>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" :multiple="true" :clear-on-select="true" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left"> Approval Manager</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select Approval Manager Name" :multiple="true" :clear-on-select="true" v-model="search.mngrname" :options="bshlist" v-if="pagefeatures.empreportingmanager" :disabled="true"/>
                      <treeselect class="allotedto capitalisetext" placeholder="Select Approval Manager Name" :multiple="true" :clear-on-select="true" v-model="search.mngrname" :options="bshlist" v-else/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <div class="">
                      <label for="projectmdllistdd">Status</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" v-model="search.status" :multiple="true" :options="releasenotetatus"/>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-12 mb-0 mt-2 text-right">
                      <button type="submit" class="btn btn-relief-primary mt-0 mr-1" @click="getreleasenotelist(search,1)"><search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-0 mr-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                      <button type="move" class="btn btn-relief-primary mt-0 mr-0" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="reportTopScroll">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                     <h4 class="card-title text-left">
                      Approval Manager Wise - Release Note List
                      </h4>
                  </div>
                  <div class="">
                    <div class="" id="myTabContent">
                      <div
                        class=""
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="" id="table-small">
                          <div class="">
                            <div class="card-datatable">
                              <div
                                class="table-responsive_sitcky"
                                v-if="showtabledata"
                              >
                                <table class="table table-hover table-striped table-sm dataTableListing all_table_stickys" aria-describedby="mydesc">
                                  <thead>
                                    <tr>                                 
                                      <th scope="col" class="align-middle first-col-sticky" >Approval Email</th>
                                    <th scope="col" v-for="(val, key) in releasenotetatus" class="align-middle">{{val.label}}</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template
                                      v-for="(spcdata, index) in companymasterlist"
                                    >
                                    <tr v-bind:key="spcdata.empid+Math.floor(Math.random() * 1000000000)">
                                      <td style="text-align:left;  background-color:#F3F2F7;" class="first-col-sticky releasenotereportbgs01"><strong class="hod_table_pl-1">{{index}}</strong></td>
                                      <td><strong class="hod_table_pl-1 font-weight:bold px-0">{{ GetTotalCount(spcdata,'pending') }} </strong></td>
                                      <td><strong class="hod_table_pl-1 font-weight:bold px-0"> {{ GetTotalCount(spcdata,'approved') }} </strong></td>
                                      <td><strong class="hod_table_pl-1 font-weight:bold px-0">{{ GetTotalCount(spcdata,'pending_for_deployement') }}</strong> </td>
                                      <td><strong class="hod_table_pl-1 font-weight:bold px-0">{{ GetTotalCount(spcdata,'deployed') }}</strong> </td>
                                      </tr>
                                      <tr v-for="(v,i) in spcdata" v-bind:key="spcdata.empid+Math.floor(Math.random() * 1000000000)">  

                                        <td class="title font-weight:normal" style="text-align:left;">
                                              <span class="cursorpointercls sitebluecolor" @click="redirecttourl('projectId',v)">{{v.projectname}}</span>
                                          </td>
                                          <td class="font-weight:normal">
                                         
                                          <span class="font-weight:normal">{{v.pending? v.pending :0 }}</span>
                                          </td>
                                          <td>
                                          <span class="font-weight:normal">{{v.approved? v.approved :0 }}</span>
                                          </td>
                                          <td>
                                          <span class="font-weight:normal">{{v.pending_for_deployement? v.pending_for_deployement :0 }}</span>
                                          </td>
                                          <td class="font-weight:normal">
                                          <span class="font-weight:normal">{{v.deployed? v.deployed :0 }}</span>
                                          </td>
                                       </tr>
                                    </template>
                                    <template
                                      v-if="companymasterlist.length == 0 && isresponce"
                                    >
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2">
                        <pagination
                          v-if="showtabledata"
                          v-model="page"
                          :records="totalcount"
                          :per-page="pagelimit"
                          @paginate="clickCallback"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { SearchIcon,EyeIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "ReleasenoteEMReport",
  mixins: [ commonMethods ],

  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
  },
  data() {
    return {
      search: {
        searchProjectname:null,
        status:null,
        empname:null,
        mngrname:null
      },
      emplist:[],
      mngrlist:[],
      projectnameList:[],
      isLoading: false,
      fullPage: true,
      listfullPage:true,
      islistLoading:false,
      TimeFormat: apiUrl.TimeFormat,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      showtabledata: true,
      parentmoduledata: null,
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      companymasterlist:[],
      tokendata:null,
      searchOffset: 1,
      searchSession: false,
      isresponce:false,
      bshlist : [],
      releasenotetatus:apiUrl.releasenotestatus.sort((a, b) => a.label.localeCompare(b.label)),
      islistLoading:false
    };
  },
  mounted() {
   let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem('token');
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        let rowdata={}
        if(this.pagefeatures.empreportingmanager){
          this.search.mngrname = [parseInt(this.undt.userid)]
          rowdata.mngrname = [parseInt(this.undt.userid)]
        }
        this.getreleasenotelist(rowdata);
        this.getAllocatedProjectList()
        // this.getEManagerList(rowdata);
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    getEManagerList(proid) {
      this.pageoffset=1
       axios({
      method: "POST",
      url: "api/releasenote/getEMforreleasenote",
      data: { roleid: ["492"],projectidarr:[proid],useremail: this.undt.username,empcode: this.undt.userid },
      'headers':{'authorization':this.tokendata}
      }).then((result) => {
      this.isLoading = false;
      if (result.data.errorCode == 0) {
       this.bshlist = result.data.data.rows;
      } else {
      this.bshlist = [];
      }
      }).catch(e => {
        this.displayError(e)
      });
    },

    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
 
    selectproject(state, value) {
      this.pageoffset=1
      this.search.searchProjectname = state;
      if (!state) {
          this.search.approvedby = null
          this.approvalmanager = []
      }else{
          this.getEManagerList(this.search.searchProjectname);
      }
    }, 
    getAllocatedProjectList: function (){
      this.pageoffset=1
      this.isLoading = true;
      let url = "api/listing/getProjectAllocatedToEmp";
      this.input = {
        projectname:null,
        useremail: this.undt.username,
        empid: this.undt.userid,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectnameList = result.data.data;
        } else {
          this.projectnameList = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    projectChange: function (node, instanceId){
      if(node && node.length>1){
        this.pageoffset=1
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          isfrompage: "listingpage"
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
      
    },
    resetRecords: function () {
      let rowdata={}
      this.search.searchProjectname = null;
      this.bshlist = [];
      this.search.createdby = null;
      this.search.companyname = null;
      this.search.businessname = null;
      this.search.empname=null
      this.search.status=null;
      if(!this.pagefeatures.empreportingmanager){
        this.search.mngrname=null
      }else{
        rowdata.mngrname = [parseInt(this.undt.userid)]
      }
      this.searchSession = false
      this.searchOffset = 1
      this.getreleasenotelist(rowdata);
    },
    getreleasenotelist(rowdata,flag) {
      this.islistLoading = true; 
      this.parentmoduledata = rowdata;
      if(flag==1){
        this.pageoffset=1
        this.page=1
      }

      this.input = {
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        empid: this.undt.userid,
        empcode: this.undt.userid,
      };

      this.input.project = this.search.searchProjectname ? this.search.searchProjectname : null 
      this.input.status = this.search.status ? this.search.status : null
      this.input.approvedby = (this.search.mngrname) ? (this.search.mngrname) : null 
 
      if(rowdata.hasOwnProperty('mngrname') && rowdata.mngrname) {
        this.searchSession = true
        this.input.offset = this.searchOffset
      } else {
        this.searchSession = false
        this.searchOffset = 1
      }
      let url = "api/reports/getReleasenoteEMList";      
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true 
          const groupByCategory = result.data.data.rows.reduce((group, product) => {
                const { apprvedbyemail } = product;
                group[apprvedbyemail] = group[apprvedbyemail] ?? [];
                group[apprvedbyemail].push(product);
                return group;
              }, {});

          this.companymasterlist = groupByCategory;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Report data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      if(this.searchSession) {
        this.searchOffset = pageNum;
      }
      this.currPageNum = pageNum - 1;
      this.getreleasenotelist(this.parentmoduledata);
    },
    GetTotalCount(countdata,parameter){
      let total = [];
      Object.entries(countdata).forEach(([key, val]) => {
      if(parameter=='pending'){
        total.push(parseInt(val.pending))
      }  
      if(parameter=='approved'){
        total.push(parseInt(val.approved))
      }
      if(parameter=='pending_for_deployement'){
        total.push(parseInt(val.pending_for_deployement))
      }
      if(parameter=='deployed'){
        total.push(parseInt(val.deployed))
      }
      });
      return total.reduce(function(total, num){ return total + num }, 0);
    },
    ExportXL() {
      this.islistLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        offset: null,
        limit: null,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        empcode: this.undt.userid,
        project : this.search.searchProjectname ? this.search.searchProjectname : null ,
        status : this.search.status ? this.search.status : null,
        approvedby : (this.search.mngrname) ? (this.search.mngrname) : null ,
      };
      axios({
        method: "POST",
        url: "api/reports/getReleasenoteEMList",
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          let filename = ""
          this.downloadSP(result.data.data.rows,filename)
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
     }).catch(e => {
        this.displayError(e)
      });
    },
    downloadSP(readExportdata,filename) {
      if (readExportdata.length > 0) {
        let ShowLabel = 'exportreport_'+filename
        let JSONData = readExportdata
        let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        let CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            let statustypes = this.releasenotetatus;
            CSV += 'Approval Email,';
            CSV += 'Project Name,';

            for (let rs=0; rs<statustypes.length; rs++) {
                CSV += `${statustypes[rs].label},`;
            }
            CSV += '\r\n';
          }
            var row = ''

              for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1
                let apprvedbyemail = index.hasOwnProperty('apprvedbyemail') ? index.apprvedbyemail : '';
                let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
                let deployed = index.hasOwnProperty('deployed') ? index.deployed : 0;
                let pending_for_deployement = index.hasOwnProperty('pending_for_deployement') ? index.pending_for_deployement : 0;
                let approved = index.hasOwnProperty('approved') ? index.approved : 0;
                let pending = index.hasOwnProperty('pending') ? index.pending : 0;


              
                row += '"' + apprvedbyemail + '",',
                row += '"' + projectname + '",',
                row += '"' + pending + '",',
                row += '"' + approved + '",',
                row += '"' + pending_for_deployement + '",',
                row += '"' + deployed + '",',

                row += '\r\n'
                
              }
            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              return;
            }
            let fileName = 'EM_wise_Release_note'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            let link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
              title: "Success!",
              text: "Export Report successful",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
      } else {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });  
      }
    },
    redirecttourl: function (btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      if (btnstate == 'projectId') {
        this.$router.push({
          path: '/project/summary?pid='+rowdata.projectid
        })
      }
    },
  }
};
</script>
