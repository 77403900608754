<template>
   <div class="app-content content ">
    <vue-element-loading
      :active="isLoading"
      spinner="bar-fade-scale"
      color="#4F46E5"
      size="25"
      :text="isUploading ? 'Please wait. File Uploading is in progress!' : 'Please wait'"
    />
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 pt-0">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left px-0">
                <h3 class="content-header-title float-left mb-0">Appraisal Cycle</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                      <router-link :to="{ name: 'ListingAppraisalCycle', params: { search: true }}">Appraisal Cycle Listing</router-link>
                    </li>
                    <li class="breadcrumb-item sitebluecolor cursorpointercls">Add Appraisal Trackers</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body p-0">
            <form class="validate-form">
              <div class="row">
                <div class="col-12 mobile-px-0">
                  <div class="card-body p-0 ps ps--active-x">
                    <div class="capitalisetext px-2 py-2"><b>Appraisal Cycle Name: </b>
                      <vue-element-loading
                        :active="isDetailsLoading"
                        spinner="bar-fade-scale"
                        color="#4F46E5"
                          size="25"
                      />
                      <span>{{appraisal.name}}</span>
                    </div>
                    <div class="card border-warning mx-1" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important; flex-direction: row;">
                      <div class="form-group col-md-4" style="margin-top:25px">
                        <label for="attachment" class="btn btn-relief-success mb-0">Choose File</label>
                        <input  type="file" id="attachment" hidden  ref="file" accept=".xlsx, .xls, .csv" @change="onSelectDoc($event)"/>
                        <button v-if="pagefeatures.emptrackerupload" class="btn btn-relief-info ml-1 uploadbutton" v-on:click.stop.prevent="saveData($event)" :disabled="!appraisal.bulkupload.isValidated">
                          <vue-element-loading
                            :active="isUploading"
                            spinner="bar-fade-scale"
                            color="#4F46E5"
                            size="25"
                          />
                          <upload-icon size="1.5x" class="custom-class avatar-icon font-medium-2 mr-50"></upload-icon> 
                          <span>Upload</span>
                        </button>
                        <button class="btn btn-outline-dark clearButton ml-1" v-on:click.stop.prevent="resetTemplateFileUploadData($event)" :disabled="!appraisal.bulkupload.isValidated"><x-circle-icon size="1.5x" class="custom-class avatar-icon font-medium-2 mr-50"></x-circle-icon> Clear</button>
                        <div style="font-weight: 600;" class="mt-2">{{appraisal.bulkupload.fileName ? appraisal.bulkupload.fileName : 'No file selected'}}</div>
                        <div style="font-weight: 500; color: green" class="mt-2">Only .xls, .xlsx, .csv files are allowed.</div>
                      </div>
                      <div class="col-md-4" style="margin-top: 25px;">
                        <div class="row" style="align-items: center; justify-content: space-evenly; font-weight: bold">
                          <div>Success: <span style="color: green">{{this.appraisal.bulkupload.success}}</span></div>
                          <div>Failed: <span style="color: red">{{this.appraisal.bulkupload.failed}}</span></div>
                          <a v-on:click="showUploadResponse(appraisal.bulkupload)" href="javascript:void(0);" class="btn btn-icon btn-icon btn-flat-secondary waves-effect" >
                            <div class="d-flex align-items-center"> 
                                <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                <span class="text-body font-weight-bold">View Response</span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="col-md-4" style="margin-top:25px;">
                        <div class="input-group-append float-right">
                          <a v-if="pagefeatures.appraisalemp" @click="getAllAppraisalApplicableEmployees()" target="_blank" class="btn btn-icon btn-icon btn-flat-secondary waves-effect">
                            <div class="d-flex align-items-center" style="color:#00cfe8"> 
                              <user-icon size="1.5x" class="custom-class mr-50"></user-icon>
                              <span>Appraisal Emps</span>
                            </div>
                          </a>
                          <a @click="redirectToTrackerList()" target="_blank" class="btn btn-icon btn-icon btn-flat-secondary waves-effect">
                            <div class="d-flex align-items-center" style="color:#00cfe8"> 
                              <external-link-icon size="1.5x" class="custom-class mr-50"></external-link-icon>
                              <span>Trackers</span>
                            </div>
                          </a>
                          <a @click="getDownloadedLink('templates/appraisal_trackers_employees_template.xlsx')" target="_blank" class="btn btn-icon btn-icon btn-flat-secondary waves-effect">
                            <div class="d-flex align-items-center" style="color:#00cfe8"> 
                              <file-icon size="1.5x" class="custom-class mr-50"></file-icon>
                              <span>Template</span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="card border-warning mx-1" id="document-list">
                      <div class="card-body p-0 ps ps--active-x">
                        <div class="table-responsive" >
                          <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
                            <thead>
                              <tr>
                                <th scope="col" class="text-center first-col-sticky" style="min-width:45px;padding-right: 5px;padding-left: 5px;">SR. NO.</th>
                                <th scope="col" class="text-center second-col-sticky">File Name</th>
                                <th scope="col" class="text-center">Created By</th>
                                <th scope="col" class="text-center">Created Date</th>
                                <th scope="col" class="text-center">Download</th>
                                <th scope="col" class="text-center">Success</th>
                                <th scope="col" class="text-center">Failed</th>
                                <th scope="col" class="text-center">Response</th>
                              </tr>
                            </thead>
                            <tbody>
                              <vue-element-loading
                                :active="isTemplateFileListLoading"
                                spinner="bar-fade-scale"
                                color="#4F46E5"
                                  size="25"
                              />
                              <template v-for="(data,index) in appraisal.bulkupload.documentList"  >
                                <tr v-bind:key="index" >
                                  <td class="text-center first-col-sticky">{{ index + 1 + appraisal.bulkupload.currPageNum * pagelimit }}</td>
                                  <td class="text-center second-col-sticky">{{data.file_name}}</td>
                                  <td class="text-center">{{convertEmailIdToName(data.createdby)}}</td>
                                  <td class="text-center">{{formatTimeStampToMonth(data.createddate)}}</td>
                                  <td class="text-center">
                                    <a @click="getDownloadedLink(data.file_key)" target="_blank" class="btn btn-icon btn-icon btn-flat-secondary waves-effect">
                                      <div class="d-flex align-items-center sitebluecolor"> 
                                        <download-icon size="1.5x" class="custom-class" style="color:#00cfe8" ></download-icon>
                                      </div>
                                    </a>
                                  </td>
                                  <td class="text-center">{{ data.success }}</td>
                                  <td class="text-center">{{ data.failed }}</td>
                                  <td class="text-center">
                                    <a v-on:click="showfilelistpop(data.response)" href="javascript:void(0);" class="btn btn-icon btn-icon btn-flat-secondary waves-effect" >
                                      <div class="d-flex align-items-center"> 
                                          <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                      </div>
                                    </a>
                                  </td>
                                </tr>
                              </template>
                              <template v-if="appraisal.bulkupload.documentList.length==0">
                                <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                        <div class="pageCenter text-center mt-4">
                          <pagination v-model="appraisal.bulkupload.page" :records="appraisal.bulkupload.totalcount" :per-page="pagelimit" @paginate="clickCallbackBulkUploadPage"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <div class="modal fade" id="technologypopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
              <h4 class="modal-title">Reponse</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <code v-if="appraisal.bulkupload.popresponse && this.appraisal.bulkupload.popdata" style="padding:0"><pre style="padding:1rem" v-html="appraisal.bulkupload.popresponse"></pre></code>
              <code v-else-if="appraisal.bulkupload.responseMessage" style="padding:0"><pre style="padding:1rem" v-html="appraisal.bulkupload.responseMessage"></pre></code>
            </div>    
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueElementLoading from 'vue-element-loading';
import Treeselect from '@riophae/vue-treeselect'
import Loading from 'vue-loading-overlay';
import DatePicker from "vue2-datepicker";
import Pagination from 'vue-pagination-2'
import 'vue-loading-overlay/dist/vue-loading.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import "vue2-datepicker/index.css";
import { read, utils } from "xlsx";
import { PlusSquareIcon, MinusSquareIcon, XCircleIcon, PlusIcon, StarIcon, MinusIcon, UploadIcon, DownloadIcon, FileIcon, EyeIcon, ExternalLinkIcon, UserIcon  } from 'vue-feather-icons'
import apiUrl from "../../constants";
import commonMethods from '../../utils/commonMethods';
import schema from "../../utils/schema"
import moment from 'moment';

export default {
  name: 'createAppraisalTrackers',
  mixins: [ commonMethods ],
  components: {
    VueElementLoading,
    Treeselect,
    DatePicker,
    Pagination,
    Loading,
    PlusIcon,
    MinusIcon,
    StarIcon,
    XCircleIcon,
    PlusSquareIcon,
    MinusSquareIcon,
    UploadIcon,
    DownloadIcon,
    FileIcon,
    EyeIcon,
    ExternalLinkIcon,
    UserIcon,
  },
  data() {
    return {
      appraisal: {
        id: null,
        name: null,
        formData: {
          emailid: null,
          empid: null,
          deptid: null
        },
        bulkupload: {
          documentList: [],
          supportedExtensions: ['xls', 'xlsx', 'csv'],
          file: null,
          fileName: null,
          payload: null,
          isValidated: false,
          success: 0,
          failed: 0,
          updated: 0,
          totalcount: 0,
          pageCount: 0,
          page:1,
          currPageNum: 0,
          pageoffset:1,
          responseMessage: "No response was recorded or it was cleared earlier!",
          responseData: null,
          popresponse: null,
          popdata: null
        }
      },
      isLoading: false,
      isDetailsLoading: false,
      isTemplateFileListLoading: false,
      isUploading: false,
      card_content:'card-content',
      collapse:'collapse',
      tab_pane:'tab-pane', 
      fade:'fade',
      pagelimit: apiUrl.LIMIT,
      TimeFormat: apiUrl.TimeFormat,
      tokendata: null,
      undt: null
    }
  },
  mounted() {
    let userdata = window.localStorage.getItem('userdata');
    if(userdata) {
      userdata = JSON.parse(userdata)
      this.undt = userdata
      this.tokendata = window.localStorage.getItem('token');
      this.appraisal.id = this.$route.query.id
      this.getAppraisalDetailsById(this.appraisal.id)
      this.getTemplateFiles()
    }
  },
  created() {
    this.pagefeatures = {};
    let pagefeatures1 = JSON.parse(
      window.atob(window.localStorage.getItem("pagefeatures"))
    );
    pagefeatures1.forEach((element) => {
      if (element.page === "/appraisalcyclemaster") {
        this.pagefeatures[element.featurename] = element.featureaccess;
      }
    });
  },
  methods: {
    showUploadResponse(res) {
      this.appraisal.bulkupload.popresponse = null
      $('#technologypopup').modal('show');
    },
    showfilelistpop(response){
      $('#technologypopup').modal('show');
      if (response == null || response == '') {
        this.appraisal.bulkupload.popresponse = 'No response was recorded or it was cleared earlier!'
      } else {
        this.appraisal.bulkupload.popresponse = response.message
        this.appraisal.bulkupload.popdata = response.data
      }
    },
    getAppraisalDetailsById(id) {
      this.isDetailsLoading = true
      let method = 'POST'
      let url = `api/master/getAppraisalCycleMasterList`
      let payload = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        id: this.appraisal.id
      }
      axios({
        method,
        url,
        data: payload,
        headers: {
          authorization: this.tokendata,
        }
      })
      .then(result => {
        this.isDetailsLoading = false
        if(result.data.status) {
          this.appraisal.name = result.data.data.rows[0].name
        } else {
          let mesage = result.data.mesage ? result.data.mesage : result.data.msg ? result.data.msg : 'Something went wrong.'
          this.sweetAlertSnackbar(mesage + ' Please try again.', 'error')
        }
      })
      .catch(e => {
        this.isDetailsLoading = false
        this.displayError(e)
      })
    },
    getAllAppraisalApplicableEmployees() {
      this.isLoading = true
      let method = 'GET'
      let url = `api/master/${this.appraisal.id}/getApplicableAppraisalEmployees`
      axios({
        method,
        url,
        headers: {
          authorization: this.tokendata,
          useremail: this.undt.username,
          empcode: this.undt.userid
        }
      })
      .then(result => {
        this.isLoading = false
        if(result.data.status) {
          this.exportData(result.data.data.rows)
        } else {
          let mesage = result.data.mesage ? result.data.mesage : result.data.msg ? result.data.msg : 'Something went wrong.'
          this.sweetAlertSnackbar(mesage + ' Maybe add trying employees through action provided in listing')
        }
      })
      .catch(e => {
        this.isLoading = false
        this.displayError(e)
      })
    },
    exportData(readExportdata) {
      if(readExportdata.length > 0) {
        let JSONData = readExportdata
        let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        let row = ''
        let CSV = 'Sr#,Appraisal Cycle ID,Appraisal Cycle Name,Email ID,Deaprtment,Reviewer 1,Reviewer 2,Reviewer 3, Reviewer 4,Reviewer 5' + '\r\n'
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let name = index.name ? (index.name?index.name.replace(/"/g, '""'):'')  : ''
          let emailid = index.emailid ? this.decryptText(index.emailid) : ''
          let revieweremailid = index.revieweremailid ? index.revieweremailid : ''
          // let department = index.department ? index.department : ''
          let department = index.department ? this.decryptText(index.department) : ''


          let reviewers = revieweremailid.split(';')

          row += '"' + srno + '",'
          row += '"' + this.appraisal.id + '",',
          row += '"' + name + '",'
          row += '"' + emailid + '",'
          row += '"' + department + '",'
          for(let reviewer of reviewers) {
            row += '"' + reviewer + '",'
          }
          row += '"' + '' + '",'
          row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          this.sweetAlertSnackbar('Data not found', 'error')
          return;
        }
        let fileName = `appraisal_(${this.appraisal.name})_applicable_employees_` + moment().format('DD_MM_YY_HH_mm_ss')
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        let link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.sweetAlertSnackbar(`Appraisal employees downloaded successfully [Total ${readExportdata.length}]`)
      } else {
        this.sweetAlertSnackbar(`No data to download. Maybe add trying employees through action provided in listing`)  
      }
    },
    resetTemplateFileUploadData() {
      this.appraisal.bulkupload.file = null
      this.appraisal.bulkupload.fileName = null
      this.appraisal.bulkupload.payload = null
      this.appraisal.bulkupload.isValidated = false
      this.appraisal.bulkupload.success = 0
      this.appraisal.bulkupload.failed = 0
      this.appraisal.bulkupload.updated = 0
      this.appraisal.bulkupload.responseMessage = 'No response was recorded or it was cleared earlier!'
    },
    onSelectDoc(event) {
      let currFile = event.target.files[0]
      this.appraisal.bulkupload.file = currFile
      let fileName = currFile.name
      this.appraisal.bulkupload.fileName = fileName
      $('input[type="file"]').val("");
      let ext = fileName.split('.').pop().toLowerCase()
      if(!this.appraisal.bulkupload.supportedExtensions.includes(ext)) {
        this.sweetAlertSnackbar(`code: BAD_DATA, mesage: Invalid Extension`, 'error')
        return
      }

      const callSnackbarDisplay = (error, type) => this.sweetAlertSnackbar(error, type)
      const assignBulkUploadPayload = (payload) => this.appraisal.bulkupload.payload = payload
      const assignIsValidated = (val) => {
        this.appraisal.bulkupload.isValidated = val
        if(!val) {
          this.resetTemplateFileUploadData()
        }
      }

      if (currFile.size > 1024 * 1024 * 25) {
        this.sweetAlertSnackbar("Document of size above 25MB is not allowed", 'error')
        return
      }
      var reader = new FileReader();
      reader.readAsArrayBuffer(currFile);
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = read(data, {type: 'array'});
        let sheetName = workbook.SheetNames[0]
        /* DO SOMETHING WITH workbook HERE */
        let worksheet = workbook.Sheets[sheetName];
        let attrData = utils.sheet_to_json(worksheet);
        const { value, error } = schema.createAppraisalTrackersByEmployees.validate(attrData, schema.validationOptions);
        if(error) {
          let message = 'ValidationError: Some fields were invalidated. Please fill the data properly and choose the file again.'
          callSnackbarDisplay(error, 'error')
          assignIsValidated(false)
        } else {
          callSnackbarDisplay('Fields Validated, Click Upload button to save data', 'success')
          let payload = value.map(item => {
            return {
              emailid: item['Email ID'].trim(),
              reviewerEmailId: item['Reviewer Email ID'],
              trackerid: item['Tracker ID'],
            }
          })
          assignBulkUploadPayload(payload)
          assignIsValidated(true)
        }
      };
    },
    async saveData() {
      this.isLoading = true
      let method = 'POST'
      let url = `api/appraisal/addAppraisalApplicableEmployeeTrackersByCycleId/${this.appraisal.id}`
      let payload = this.appraisal.bulkupload.payload
      axios({
        method,
        url,
        data: payload,
        headers: {
          authorization: this.tokendata,
          useremail: this.undt.username,
          empcode: this.undt.userid
        }
      })
      .then(result => {
        this.appraisal.bulkupload.success = result.data.data.success
        this.appraisal.bulkupload.failed = result.data.data.failed
        this.appraisal.bulkupload.updated = result.data.data.updated
        this.appraisal.bulkupload.responseMessage = result.data.message
        this.appraisal.bulkupload.responseData = result.data
        if(result.data.status) {
          this.sweetAlertSnackbar(result.data.message + ' [ UPLOADING FILE. Please wait... ]', 'success')
          this.UploadFiles()
        } else {
          this.sweetAlertSnackbar(result.data.message, 'error')
        }
      })
      .catch(e => {
        let response = e ? e.response : e
        if(response && response.data && response.data.data) {
          this.appraisal.bulkupload.success = response.data.data.success
          this.appraisal.bulkupload.failed = response.data.data.failed
          this.appraisal.bulkupload.responseMessage = response.data.msg
          this.appraisal.bulkupload.file = null
          this.appraisal.bulkupload.fileName = null
        }
        this.displayError(e)
      })
    },
    UploadFiles() {
      this.isUploading = true
      let formData = new FormData();
      formData.append("file", this.appraisal.bulkupload.file);
      formData.append("success",this.appraisal.bulkupload.success);
      formData.append("failed",this.appraisal.bulkupload.failed)
      formData.append("response" ,JSON.stringify(this.appraisal.bulkupload.responseData))
      formData.append("folder", 'appraisal_trackers_employees')
      formData.append("table", 'file_master')
      formData.append("id", this.appraisal.id)
      let url = "api/files/s3/uploadSingleFile"
      axios({
        "content-type": "multipart/form-data",
        method: "POST",
        url: url,
        data: formData,
        headers: {
          authorization: this.tokendata,
          useremail: this.undt.username,
          empcode: this.undt.userid
        }
      })
      .then(result => {
        this.isUploading = false
        this.isLoading = false
        this.appraisal.bulkupload.file = null
        this.appraisal.bulkupload.fileName = null
        this.appraisal.bulkupload.payload = null
        this.getTemplateFiles()
        if(result.data.status) {
          this.sweetAlertSnackbar(result.data.message, 'success')
        } else {
          this.sweetAlertSnackbar(result.data.message, 'error')
        }
      })
      .catch(e => {
        this.isUploading = false
        this.appraisal.bulkupload.file = null
        this.appraisal.bulkupload.fileName = null
        this.appraisal.bulkupload.payload = null
        this.displayError(e)
      })
    },
    clickCallbackBulkUploadPage(pageNum) {
      this.appraisal.bulkupload.pageoffset = pageNum
      this.appraisal.bulkupload.currPageNum = pageNum - 1
      this.getTemplateFiles();
      setTimeout(function(){
        $('html, body').animate({
          scrollTop: $("#document-list").offset().top -70
        });
      }, 500);
    },
    getTemplateFiles() {
      this.isTemplateFileListLoading = true
      let url = `api/master/template/${this.appraisal.id}/files?fileIdentifier=appraisal_trackers_employees`
      let payload = {
        limit: apiUrl.LIMIT,
        offset: this.appraisal.bulkupload.pageoffset,
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers: {
          authorization: this.tokendata,
          useremail: this.undt.username,
          empcode: this.undt.userid
        }
      }).then(result => {
        this.isTemplateFileListLoading = false
        if(result.data.status) {
          this.appraisal.bulkupload.documentList = result.data.data.rows
          this.appraisal.bulkupload.totalcount = result.data.data.count
          this.appraisal.bulkupload.pageCount = Math.ceil(this.appraisal.bulkupload.totalcount / this.pagelimit)
        } else {
          this.sweetAlertSnackbar(result.data.message, 'error')
          this.appraisal.masterList = []
          this.appraisal.bulkupload.totalcount = 0
          this.appraisal.bulkupload.pageCount = 0
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    redirectToTrackerList() {
      let routeData = this.$router.resolve({ 
        path: '/trackerlisting',
      })
      window.open(routeData.href, '_blank');
    },
  }
}
</script>