<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
               <div class="card" >
                   <div class="container">
                        <div class="row quiz_exam_container pl-1 pr-0">
                           <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 quiz_exam_padding_left_0"> 
                              <div class="top-headercard"> 
                                <h3> {{cname}} : {{ tname}}</h3> 
                                 <div class="quiz_time_view"> 
                                     <div class="quiz_time_view_inline d-flex px-1">
                                       
                                       <div class="time_text pull-left">
                                         <input type="hidden" id="set-time" v-bind:value="testDurationTimer" />
                                          <div id="countdown">
                                            <div class="divider-text pull-left">
                                              <em data-feather="clock"></em>
                                           </div>
                                            <div id="tiles" class="color-full"> </div>
                                          </div>
       
                                        </div>
                                     </div>
                                     <div class="quiz_time_view_inline d-flex">
                                       <div class="max-divider-text pull-left pr-1">
                                         <strong> Max Time : {{testDurationTimer}} M</strong>
                                       </div>
                                     </div>

                                   </div>
                                <div class="dropdown-divider w-100"></div>
                              </div>
                              <div class="quiz_custom_box px-1 pt-1" v-for="(que, row) in listData">
                                 <div class="quiz_questions_title mb-1">
                                   <div class="question_no mr-1"> Q{{pageNumber+1}} </div>

                                   <div class="question_title"> {{que.questiontitle}} </div>
                                      <div class="row mt-1 image_view_questions">
                                      <div v-for="(qidata, key) in attachmentqueview[0]" :key="key" class="media col-xs-2 col-sm-2 col-md-2 col-lg-4 mb-2" v-if="attachmentqueview.length>0">
                                      <div class="border right-view-container">
                                        <img alt="" v-if="imgExt.includes(qidata.link.substring(qidata.link.lastIndexOf('.')+1))"  :src="qidata.presignurl"   />
                                      </div>
                                      </div>
                                    </div>
                                 </div>

                                <div class="media_quiz_custom_box"> 
                                    <div class="media-body">
                                        <div class="view-container exam_viewimages mt-3">
                                          <div class="row mx-0 transaction-item ">
                                              <div class=" col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                                  <div class="demo-inline-spacing mb-1">
                                                    <div class="options_images_text_view m-0 mr-1">
                                                      <div class="aphabetical_charater"> A </div>
                                                    </div>
                                                    <div class="form-check form-check-block m-0 pl-0">
                                                       <input class="form-check-input" type="radio" v-model="que.qid" name="inlineRadioOptions" id="inlineRadio1" @change="onChangeQuestion($event)" value="A" />
                                                     <label class="form-check-label" for="inlineRadio1">{{que.optiona}}</label>
                                                    </div>
                                                 </div>
                                                  
                                                  <div v-for="(dataa, ka) in attachmentviewa" class="border right-view-container" v-if="(attachmentviewa)">
                                                    <img alt="" v-if="imgExt.includes(dataa[0].link.substring(dataa[0].link.lastIndexOf('.')+1))" :src="dataa[0].presignurl" class="desktop_view_show" />
                                                  </div>
                                              </div>

                                              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                                  <div class="demo-inline-spacing mb-1">
                                                    <div class="options_images_text_view m-0 mr-1">
                                                      <div class="aphabetical_charater"> B </div>
                                                    </div>
                                                    <div class="form-check form-check-block m-0 pl-0">
                                                        <input class="form-check-input" type="radio" v-model="que.qid" name="inlineRadioOptions" id="inlineRadio2" @change="onChangeQuestion($event)" value="B" />
                                                        <label class="form-check-label" for="inlineRadio2">{{que.optionb}}</label>
                                                    </div>
                                                 </div>
                                                  <div v-for="(datab, kb) in attachmentviewb" class="border right-view-container" v-if="(attachmentviewb)">
                                                    <img alt="" v-if="imgExt.includes(datab[0].link.substring(datab[0].link.lastIndexOf('.')+1))" :src="datab[0].presignurl" class="desktop_view_show" />
                                                  </div>
                                              </div>

                                              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2">
                                                   <div class="demo-inline-spacing mb-1">
                                                    <div class="options_images_text_view m-0 mr-1">
                                                      <div class="aphabetical_charater"> C </div>
                                                    </div>
                                                    <div class="form-check form-check-block m-0 pl-0">
                                                        <input class="form-check-input" type="radio" v-model="que.qid" name="inlineRadioOptions" id="inlineRadio3" @change="onChangeQuestion($event)" value="C" />
                                                        <label class="form-check-label" for="inlineRadio3">{{que.optionc}}</label>
                                                    </div>
                                                 </div>
                                                  <div v-for="(datac, kc) in attachmentviewc" class="border right-view-container" v-if="(attachmentviewc)">
                                                    <img alt="" v-if="imgExt.includes(datac[0].link.substring(datac[0].link.lastIndexOf('.')+1))" :src="datac[0].presignurl" class="desktop_view_show" />
                                                  </div>
                                              </div>
                                              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" v-if="(attachmentviewd)">
                                                  <div class="demo-inline-spacing mb-1">
                                                    <div class="options_images_text_view m-0 mr-1">
                                                      <div class="aphabetical_charater"> D </div>
                                                    </div>
                                                    <div class="form-check form-check-block m-0 pl-0">
                                                        <input class="form-check-input" type="radio" v-model="que.qid" name="inlineRadioOptions" id="inlineRadio4" @change="onChangeQuestion($event)" value="D" />
                                                        <label class="form-check-label" for="inlineRadio4">{{que.optiond}}</label>
                                                    </div>
                                                 </div>
                                                  <div v-for="(datad, kd) in attachmentviewd" class="border right-view-container"  v-if="(attachmentviewd)">
                                                    <img alt="" v-if="imgExt.includes(datad[0].link.substring(datad[0].link.lastIndexOf('.')+1))" :src="datad[0].presignurl" class="desktop_view_show" />
                                                  </div>
                                              </div>
                                            </div>
                            
                                        </div>
                                    </div>
                                   </div> 
                               </div>
                              <div class="dropdown-divider"></div>
                               <div class="quiz_footer_view right_bottom_view_quiz d-flex mb-1">
                                   <button :disabled="pageNumber==0" @click="prevPage" type="button" id="" class="btn btn-relief-primary btn-1 mr-1 "> <em data-feather='chevron-left'></em>Previous</button>
                                   <button @click="submitTest()" type="button" class="btn btn-custom-primary btn-1  ">Submit Test</button>
                                   <button :disabled="pageNumber >= courseQueListCount -1" @click="nextPage" type="button" id="" class="btn btn-relief-primary btn-1 mr-1">Next <em data-feather='chevron-right'></em> </button>
                               </div>
                            </div>
                           <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 quiz_right_side_bg"> 
                             <div class="top-headercard"> 
                                 <div class="header_left_que_no">
                                    <h3 class="left_text_quiz"> QUESTIONS </h3> 
                                    <div class="ml-auto px-0 d-inline-block"> 
                                         <span><strong> Total: {{courseQueListCount}}</strong> </span>
                                    </div>
                                 </div>
                                <div class="dropdown-divider"></div>
                                <div class="question_status">
                                   <div class="q-block_quiz" v-for="(no,ind) in courseQueList"> 
                                      <!--- Current Page --->
                                      <span @click="clickable((ind+1))" class="q-block q-no M14_42 quest_status_current" v-if="(no.queTestStatus=='attempted')"> {{ind+1}} </span>
                                      <!--- Skipped Page --->
                                      <span @click="clickable((ind+1))" class="q-block q-no M14_42 quest_status_skip" v-if="(no.queTestStatus=='skipped')"> {{ind+1}} </span>
                                      <!--- Remaining Page --->
                                      <span @click="clickable((ind+1))" v-if="(no.queTestStatus=='notAttempted')" class="q-block q-no M14_42"> {{ind+1}} </span>
                                   </div>
                                </div>
                              </div>
                              <div class="dropdown-divider"></div>
                              <div class="status_desc">
                                 <div class="attempted_status"> 
                                    <span class="status_box"> </span>
                                    <span class="status_box_text"> Attempted</span>
                                 </div> 
                                 <div class="nonattempted_status"> 
                                    <span class="status_box"> </span>
                                    <span class="status_box_text"> Non Attempted</span>
                                 </div> 
                                 <div class="skiped_status"> 
                                    <span class="status_box"> </span>
                                    <span class="status_box_text"> Skipped</span>
                                 </div> 
                              </div>
                            </div>
                        </div>
                   </div>
               </div>
        </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { SearchIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';
import Treeselect from '@riophae/vue-treeselect'

export default {
  name: 'Assessmentstart',
  components: {
    VueElementLoading,
    Loading,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Treeselect,
  },
  mixins: [ commonMethods ],
  data() {
    return {
      imgExt:['jpeg','jpg','png'],
      projectname: null,
      search: {
      },
      courseQueList: null,
      courseQueListCount: null,
      isLoading: false,
      undt: null,
      tokendata: null,
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      projectIdd: null,
      pageNumber: 0, // default to page 0
      listData: [],
      testDurationTimer: 0,
      givenQueAns: [],
      skippedQueList: [],
      optionVal: null,
      tempVAr: [],
      cname: null,
      tname: null,
      attachmentviewa: [],
      attachmentviewb: [],
      attachmentviewc: [],
      attachmentviewd: [],
      attachmentqueview: [],
      getCourseId:null,
      resultassessmentid: null,
      periodicid: null,
    }
  },
  mounted() {
    let userdata = window.localStorage.getItem('userdata');
    if(userdata){
      userdata = JSON.parse(userdata)
      this.undt = userdata
      this.tokendata = window.localStorage.getItem('token');
      let testid = this.$route.query.testid;
      this.getCourseId = this.$route.query.courseid;
      this.periodicid = this.$route.query.periodicid;
      this.resultassessmentid = this.$route.query.resultid;
      this.deletedPrevTestRecords(this.getCourseId, testid)
      this.getQueListByCourse(this.getCourseId, testid)
      this.getCountdown();
    }
  },
  methods: { 
      getDownloadLinka(data) {
        if(data.data.length>0) {
            let payload = {
            documents:data.data,
            useremail: this.undt.username,
            empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: '/api/imageupload/getdownloadlink',
                data: payload,
                'headers':{'authorization':this.tokendata}
            })
            .then((result) => {
            if(result.data.status) {
              this.attachmentviewa.push(result.data.data);
            }
            }).catch(e => {
          this.displayError(e)
        });
        }
      },
      getDownloadLinkb(data) {
        if(data.data.length>0) {
            let payload = {
            documents:data.data,
            useremail: this.undt.username,
            empcode: this.undt.userid
            };
            axios({
                method: "POST",
                url: '/api/imageupload/getdownloadlink',
                data: payload,
                'headers':{'authorization':this.tokendata}
            })
            .then((result) => {
            if(result.data.status) {
              this.attachmentviewb.push(result.data.data);
            }
            }).catch(e => {
          this.displayError(e)
        });
        }
      },
      getDownloadLinkc(data) {
        if(data.data.length>0) {
            let payload = {
            documents:data.data,
            useremail: this.undt.username,
            empcode: this.undt.userid
            };
            axios({
                method: "POST",
                url: '/api/imageupload/getdownloadlink',
                data: payload,
                'headers':{'authorization':this.tokendata}
            })
            .then((result) => {
            if(result.data.status) {
              this.attachmentviewc.push(result.data.data);
            }
            }).catch(e => {
          this.displayError(e)
        });
        }
      },
      getDownloadLinkd(data) {
        if(data.data.length>0) {
            let payload = {
            documents:data.data,
            useremail: this.undt.username,
            empcode: this.undt.userid
            };
            axios({
                method: "POST",
                url: '/api/imageupload/getdownloadlink',
                data: payload,
                'headers':{'authorization':this.tokendata}
            })
            .then((result) => {
            if(result.data.status) {
              this.attachmentviewd.push(result.data.data);
            }
            }).catch(e => {
          this.displayError(e)
        });
        }
      },
      getDownloadQueLink(data) {
        if(data.data.length>0) {
          let payload = {
          documents:data.data,
          useremail: this.undt.username,
          empcode: this.undt.userid
          };
          axios({
                method: "POST",
                url: '/api/imageupload/getdownloadlink',
                data: payload,
                'headers':{'authorization':this.tokendata}
            })
          .then((result) => {
          if(result.data.status) {
            this.attachmentqueview.push(result.data.data);
          }
          }).catch(e => {
          this.displayError(e)
        });
        }
      },
      onChangeQuestion(event) {
        this.optionVal=event.target.value?event.target.value:null; 
        let que = this.listData[0];
        this.saveTest('prev', event.target.value, que);
      },
      clickable(no) {
        this.pageNumber = no-1;
        this.listData = this.courseQueList.slice(no-1, no);
        this.paginatedData()
      },
      convertTimeIntoMins(time){
        var a = time.split(':');
        this.testDurationTimer = (+a[0]) * 60 + (+a[1]);
        localStorage.setItem('target_date', new Date().getTime() + ((this.testDurationTimer * 60 ) * 1000));
        localStorage.setItem('assessmentTime', this.testDurationTimer);
      },
      getCountdown(){
        let minutes = localStorage.getItem('assessmentTime');
        let target_date = localStorage.getItem('target_date');
        let time_limit = ((minutes * 60 ) * 1000);
        let  hours,  seconds; 
        // find the amount of "seconds" between now and target
        var current_date = new Date().getTime();
        var seconds_left = (target_date - current_date) / 1000;
        if ( seconds_left >= 0 ) {
          if ( (seconds_left * 1000 ) < ( time_limit / 2 ) )  {
            $( '#tiles' ).removeClass('color-full');
            $( '#tiles' ).addClass('color-half');
          } 
          if ( (seconds_left * 1000 ) < ( time_limit / 4 ) )  {
            $( '#tiles' ).removeClass('color-half');
            $( '#tiles' ).addClass('color-empty');
          }
          
          seconds_left = seconds_left % 86400;
            
          hours = this.pad( parseInt(seconds_left / 3600) );
          seconds_left = seconds_left % 3600;
              
          minutes = this.pad( parseInt(seconds_left / 60) );
          seconds = this.pad( parseInt( seconds_left % 60 ) );

          // format countdown string + set tag value
          $("#tiles").html("<span>" + hours + ":</span><span>" + minutes + ":</span><span>" + seconds + "</span>"); 
        }
      },     
      pad(n) {
        return (n < 10 ? '0' : '') + n;
      },
      paginatedData() {
          this.attachmentviewa = [];
          this.attachmentviewb = [];
          this.attachmentviewc = [];
          this.attachmentviewd = [];
          this.attachmentqueview = [];
          const start = this.pageNumber * 1, end = start + 1;
          this.listData = this.courseQueList.slice(start, end);
          //console.log(this.listData)
          if(this.listData[0].optionaimg != null){
            this.getDownloadLinka(this.listData[0].optionaimg)
          }
          if(this.listData[0].optionbimg != null){
            this.getDownloadLinkb(this.listData[0].optionbimg)
          }
          if(this.listData[0].optioncimg != null){
            this.getDownloadLinkc(this.listData[0].optioncimg)
          }
          if(this.listData[0].optiondimg != null){
            this.getDownloadLinkd(this.listData[0].optiondimg)
          }
          if(this.listData[0].questionimages != null){
            this.getDownloadQueLink(this.listData[0].questionimages)
          }
      },
      getTestResul(cid, testid) {
        let url = "api/assessment/getTestResul";
        this.statusinput = {
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          cid: parseInt(cid),
          testid: parseInt(testid),
          empcode: this.undt.userid,
          periodicid: parseInt(this.periodicid),
        };
        if(this.resultassessmentid != null){
            this.statusinput.empassessmentresultid = parseInt(this.resultassessmentid)
        }
        axios({
          method: "POST",
          url: url,
          data: this.statusinput,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
            if (result.data.errorCode == 0) {
                localStorage.removeItem('assessmentTime');
                localStorage.removeItem('target_date');
                localStorage.removeItem('localcourse');
                Swal.fire({
                    title: "Thank You..!",
                    text: "Test has been saved successfully.",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
                this.$router.push({
                  reload: true,
                  path:'/training/view?courseid='+this.$route.query.courseid,
                })
            } else if(result.data.errorCode == 3){
            Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            }
        }).catch(e => {
          this.displayError(e)
        });
      },
      submitTest(){
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success ',
            cancelButton: 'btn btn-danger mx-1'
          },
          buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
          title: 'Are you sure?',
          text: "You won't be able to continue ...!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            let prevValue = $('input[name="inlineRadioOptions"]:checked').val();
            let que = this.listData[0];
            this.saveTestOnSubmit('next', prevValue, que);
          } else if (
            result.dismiss === Swal.DismissReason.cancel
          ) {
              swalWithBootstrapButtons.fire(
                'Okay',
                'You can continue now..!',
              )
          }
        }).catch(e => {
          this.displayError(e)
        })
      },
      nextPage(){
        let que = this.listData[0];
        let selAns = this.optionVal;
        let prevValue = $('input[name="inlineRadioOptions"]:checked').val();
        if(this.optionVal==null)
        {
          let clist = this.courseQueList;
          this.courseQueList.forEach(function(element, index) {
            if(que.questionid == element.questionid) {
              if(clist[index].queTestStatus != 'attempted') {
                clist[index]['queTestStatus'] = 'skipped';
              }
            }
          });
        }else{
          let clist = this.courseQueList;
          this.courseQueList.forEach(function(element, index) {
            if(que.questionid == element.questionid) {
              clist[index]['ans'] = selAns;
            }
          });
        }
        this.saveTest('next', prevValue, que);
        this.pageNumber++;
        this.paginatedData()
        this.optionVal = null;
      },
      saveTestOnSubmit(btnType, val, que) {
        if(val){
          let url = 'api/assessment/saveTest';
          let payload = {
              courseid: que.courseid,
              periodicid: parseInt(this.periodicid),
              questionid: parseInt(que.questionid),
              answergiven: val?val:null,  //prev
              //correctanswer: que.answer,
              createdby:this.undt.username,
              //ismatch: (val == que.answer)?1:0,
              empid: this.undt.userid,
              testid: this.$route.query.testid,
              useremail: this.undt.username,
              empcode: this.undt.userid
          }
          axios({
            method: 'POST',
            url,
            data: payload,
            headers: { authorization :this.tokendata }
          }).then(result => {
            if (result.data.errorCode == 0) {
              let clist = this.courseQueList;
              clist.forEach(function(element, index) {
                if(que.questionid == element.questionid) {
                    if(result.data.data.answergiven == null){
                      clist[index]['queTestStatus'] = 'skipped';
                    } else {
                      clist[index]['queTestStatus'] = 'attempted';
                    }
                }
              });

              //generate the test result
              this.getTestResul(this.$route.query.courseid, this.$route.query.testid);
            } else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            }
          }).catch(e => {
          this.displayError(e)
        })
        }else{
          this.getTestResul(this.$route.query.courseid, this.$route.query.testid);
        }
      },
      saveTest(btnType, val, que) {
          let url = 'api/assessment/saveTest';
          let payload = {
              courseid: que.courseid,
              periodicid: parseInt(this.periodicid),
              //empcode: this.undt.userid,
              questionid: parseInt(que.questionid),
              answergiven: val?val:null,  //prev
              //correctanswer: que.answer,
              createdby:this.undt.username,
              //ismatch: (val == que.answer)?1:0,
              empid: this.undt.userid,
              testid: this.$route.query.testid,
              useremail: this.undt.username,
              empcode: this.undt.userid
          }
          axios({
            method: 'POST',
            url,
            data: payload,
            headers: { authorization :this.tokendata }
          }).then(result => {
            if (result.data.errorCode == 0) {
              let clist = this.courseQueList;
              clist.forEach(function(element, index) {
                if(que.questionid == element.questionid) {
                    if(result.data.data.answergiven == null){
                      clist[index]['queTestStatus'] = 'skipped';
                    } else {
                      clist[index]['queTestStatus'] = 'attempted';
                    }
                }
              });
            } else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            }
          }).catch(e => {
          this.displayError(e)
        })
      },
      prevPage(){
          let clist = this.courseQueList;
          let que = this.listData[0];
          clist.forEach(function(element, index) {
            if(que.questionid == element.questionid) {
              if(element.queTestStatus != 'attempted'){
                clist[index]['queTestStatus'] = 'skipped';
              }
            }
          });
          let prevValue = $('input[name="inlineRadioOptions"]:checked').val();
          this.saveTest('prev', prevValue, que);
          this.pageNumber--;
          this.paginatedData()
      },
      deletedPrevTestRecords(cid, test_id){
        let url = "api/assessment/deletedPrevTestRecords";
        this.statusinput = {
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          cid: parseInt(cid),
          test_id: parseInt(test_id),
          empcode: this.undt.userid
        };
        axios({
          method: "POST",
          url: url,
          data: this.statusinput,
          'headers':{'authorization':this.tokendata}
        })
      },
      getQueListByCourse: function(cid, test_id) {
        this.isLoading = true;
        let url = "api/assessment/getQueListByCourse";
        this.statusinput = {
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          cid: parseInt(cid),
          test_id: parseInt(test_id),
          empcode: this.undt.userid
        };
        axios({
          method: "POST",
          url: url,
          data: this.statusinput,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            var list = result.data.data.rows;
            list.forEach(function(element, index) {
                element['queTestStatus'] = 'notAttempted';
            });
            this.courseQueList = list;
            this.courseQueListCount = result.data.data.count;
            this.paginatedData()
            if(result.data.data.rows[0])
            {
              this.convertTimeIntoMins(result.data.data.rows[0].examduration)
              this.cname = result.data.data.rows[0].cname;
              this.tname = result.data.data.rows[0].assessmenttitle;
            }
          } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
            this.courseQueList = null;
            this.projectname = null;
            this.$router.push({
                path:'/training/view?courseid='+this.$route.query.courseid,
                reload: true,
            })
            Swal.fire({
                title: "Failed!",
                text: "No Quetion Found for this Course",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            })
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      fetchGivenTestDetails: function(cid) {
        let url = "api/assessment/fetchGivenTestDetails";
        this.statusinput = {
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          empcode: this.undt.userid,
          cid: parseInt(cid),
        };
        axios({
          method: "POST",
          url: url,
          data: this.statusinput,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          if (result.data.errorCode == 0) {
            this.givenQueAns = result.data.data.rows;
            this.paginatedData()
            this.getDifference(this.courseQueList, this.givenQueAns)
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getDifference: function(arr1, arr2) {
        let array1 = Array.from(arr1, function (item) {
            return { qid: parseInt(item.questionid) }
        });

        let array2 = Array.from(arr2, function (item) {
            return { qid: item.questionid }
        });

        let skippedQueList = array1.filter(({ qid: id1 }) => !array2.some(({ qid: id2 }) => id2 === id1));

        this.courseQueList.forEach(function(element, index) {
           skippedQueList.forEach(function(ele, i) {   
           });
        });
      },
  },
  created() {
    window.setInterval(function () {
      let localcourse = window.localStorage.getItem('localcourse');
      let minutes = localStorage.getItem('assessmentTime');
      let target_date = localStorage.getItem('target_date');
      let time_limit = ((minutes * 60 ) * 1000);
      let  hours, seconds; 
      // find the amount of "seconds" between now and target
      var current_date = new Date().getTime();
      var seconds_left = (target_date - current_date) / 1000;
      if ( seconds_left >= 0 ) {
        if ( (seconds_left * 1000 ) < ( time_limit / 2 ) )  {
          $( '#tiles' ).removeClass('color-full');
          $( '#tiles' ).addClass('color-half');

          } 
          if ( (seconds_left * 1000 ) < ( time_limit / 4 ) )  {
            $( '#tiles' ).removeClass('color-half');
            $( '#tiles' ).addClass('color-empty');
          }
        
        seconds_left = seconds_left % 86400;
        // days = (days < 10 ? '0' : '') + days

        hours = (( parseInt(seconds_left / 3600) )<10?'0':'') + parseInt(seconds_left / 3600);
        seconds_left = seconds_left % 3600;
        
        minutes = (( parseInt(seconds_left / 60) ) <10?'0':'') + parseInt(seconds_left / 60);

        seconds = (( parseInt( seconds_left % 60 ) ) <10?'0':'') + parseInt( seconds_left % 60 );

        // format countdown string + set tag value
        $("#tiles").html("<span>" + hours + ":</span><span>" + minutes + ":</span><span>" + seconds + "</span>"); 

        if(hours === '00' && minutes === '00' && seconds === '00') {
          Swal.fire({
              title: "Thank You..!",
              text: "Time is over",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          })
          localStorage.removeItem('assessmentTime');
          localStorage.removeItem('target_date');
          localStorage.removeItem('localcourse');
         // let burl = 'http://localhost:8080';
          let burl = 'https://smartworks.firstcry.tech';
          if(axios.defaults.lms && axios.defaults.lms == true){
            burl = 'https://smartassist.firstcry.tech';
          }
          let urrl = burl+'/#/training/view?courseid='+localcourse;
          location.href=urrl;
        }
      }
    }, 1000);
  },
}

</script>
