<template>
    <div>
        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :is-full-page="fullPage"
          color="orange"
          loader="dots"
          :width="64"
          :height="64"
        ></loading>
        
        <div class="app-content content devcontent ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper">
                <div class="content-body">
                   <div class="row">
                        <div class="col-12 mobile_view_padd-05">
                            <div class="card border-warning filter_card_searchbgst">
                                <div class="card-header filter_rotation_onlick" v-on:click="mysearchrequestollapse" style="cursor:pointer;">
                                    <div class="container-fluid" >
                                        <div class="row" >
                                            <div class="w-auto px-1 mobile-padding-0-2">
                                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                    <div class="avatar-content">
                                                        <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                                    </div>
                                                </div>
                                                <h4 class="card-title d-inline-flex ml-1">Search {{label}}</h4>
                                            </div>
                                            

                                            <div class="ml-auto d-flex">
                                                
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body card_padding_10">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4 col-xs-12 mb-1">
                                                <label class="form-label">Request ID</label>
                                                <input class="form-control" placeholder="Enter Request ID" type="text" v-model.trim="search.requestid"/>
                                            </div>
                                            <div class="col-md-4 col-sm-4 col-xs-12 mb-1">
                                                <label class="form-label">Emp ID</label>
                                                <input class="form-control" placeholder="Enter Emp ID" type="text" v-model.trim="search.empcode"/>
                                            </div>
                                         
                                            <div class="col-md-4 col-sm-4 col-xs-12 common_trs_buttons_res">
                                                <button type="submit" class="btn btn-primary mt-2 mr-1" @click="gettotalTicketcount()" >Search</button>
                                                <button type="reset" class="btn btn-outline-secondary mt-2" v-on:click="mysearchrequestollapse" @click="resetRecords()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card border-warning filter_card_searchbgst">
                                <div class="card-header filter_rotation_onlick" style="cursor:pointer;" v-on:click="mydetailsollapse">
                                    <div class="container-fluid" >
                                        <div class="row" >
                                                <div class="w-auto px-1 mobile-padding-0-2">
                                                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                        <div class="avatar-content">
                                                        <clipboard-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></clipboard-icon>
                                                    </div>
                                                </div>
                                            <h4 class="card-title d-inline-flex ml-1">{{label}} Details</h4>
                                            </div>
                                           

                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isDetActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card border-0 " id="domain-list">
                                        <div class="card-body p-0 ps ps--active-x">
                                            <div class="table-responsive datatableview">
                                                <table aria-describedby="mydesc" class="table dataTableListing table-hover all_table_stickys">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Request ID</th>
                                                            <th class="text-left" scope="col" style="min-width:150px;">Requester Name</th>
                                                            <th class="text-left" scope="col" style="min-width:150px;">From City</th>
                                                            <th class="text-left" scope="col">To City</th>
                                                            <th scope="col" class="text-left" style="max-width:90px;">Transfer Mode</th>
                                                            <th scope="col" class="table_th_date_st text-left">Visit Name</th>
                                                            <th scope="col" class="text-left">Locations Visiting</th>
                                                            <th class="text-left" scope="col">Purpose</th>
                                                            <th scope="col" class="table_th_date_st">Start Date</th>
                                                            <th scope="col" class="table_th_date_st">End Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        
                                                        <tr v-for="(data,index) in requestList" v-bind:key="index">
                                                            <td data-column="Request ID">{{data.ticketid}}</td>
                                                            <td data-column="Requester Name" class="text-left">{{data.employname}}</td>
                                                            <td data-column="From City" class="text-left">{{data.fromcityname}}</td>
                                                            <td  data-column="To City" class="text-left">{{data.cityname}}</td>
                                                            <td  data-column="Transfer Mode" class="text-left">{{data.transportmode}}</td>
                                                            <td  data-column="Visit Name" class="text-left">{{data.visitname}}</td>
                                                            <td  data-column="Locations Visiting" class="text-left">{{data.visitlocation}}</td>
                                                            <!-- <td>{{data.purpose}}</td> -->
    
                                                            <!--  -->
                                                            <td data-column="Purpose" class="tast_case_groupingview_ hod-readmore">
                                                            
                                                            <div class="col-md-12 mt-0 px-0 tast_case_groupingview_readmore">
                                                                <div class="custom-container case_bg_17">
                                                                    <div class="outer" :id="'cycle_readmore'+index">
                                                                        <span class="fc_m16_btcode_text"  v-html="formatData(data.purpose)"></span>
                                                                    </div>
                                                                    <div class="cycle_viewmoretext" v-if="data.purpose && data.purpose.trim().length > 40">
                                                                        <a class="btn">
                                                                        <strong :id="index" @click="idclick(index)">
                                                                            Read More
                                                                        </strong>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                        </td>
                                                            <!--  -->
                                                            <td data-column="Start Date">{{data.startdate}}</td>
                                                            <td data-column="End Date" >{{data.enddate}}</td>                                                 
                                                        </tr>
                                                        
                                                        <tr v-if="requestList.length ==0">
                                                            <td class="py-1" colspan="10">No Record Found</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                                
                                        </div>
                                    </div>
                                      <pagination class="pageCenter text-center mt-2" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import DatePicker from 'vue2-datepicker';
    import datetime from 'vuejs-datetimepicker';
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import 'vue2-datepicker/index.css';
    import {PlusIcon, PocketIcon,CodepenIcon,AtSignIcon,MailIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,
    SearchIcon,PlusCircleIcon,ClipboardIcon,UserIcon,MapPinIcon,InfoIcon,MoreVerticalIcon,EditIcon,EyeIcon } from 'vue-feather-icons'
    // Import component
    import Loading from 'vue-loading-overlay';
     // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Pagination from 'vue-pagination-2';
    import apiUrl from '../../../constants';
    export default({
        name:'TravelList',
        
        data() {
            return {
                tokendata:null,
                backpagedata:null,
                ticketapprovelist:[],
                firstStepActive:true,
                secondStepActive:false,
                thirdStepActive:false,
                FirstActive:true,
                firstBlock:true,
                secondActive:false,
                secondBlock:false,
                thirdActive:false,
                thirdBlock:false,
                content:'content',
                step:'step',
                dstepper_block:'dstepper-block',
                ticketdetails:[],
                isLoading: false,
                fullPage: true,
                isAddState:false,
                un:'',
                username:'',
                empid:'',
               totalcount: 0,
               pageCount: 0,
               page:1,
               pagelimit:apiUrl.LIMIT,
               currPageNum: 0,
               pageoffset:1,
               
                search:{
                    requestid:'',
                    status:'',
                    empcode:'',
                },
                emptypeBlured:false,
                pagefeatures:[],
                requestList:[],
                isDetActive:true,
                isRequestActive:false,
                isSearchRequestActive:false,
                card_content:'card-content',
                collapse:'collapse',
                isActiveEndDate:false,
                addmorecity:true,
                returnbooking:true,
                showmanager:true,
                citymasterList:[],
                isActiveEndDate2:false,
                transportList:[],
                managerid:'',
                visitname:'',
                visitlocation:'',
                purpose:'',
                startdate:'',
                enddate:'',
                label: this.$route.query.lablename || ''
            }  
        } ,
       
    
        components: {
            PocketIcon,CodepenIcon,AtSignIcon,MailIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,
            EditIcon,
            EyeIcon,
            MoreVerticalIcon,
            Loading,
            Treeselect,
            DatePicker,
            PlusIcon,
            Pagination,
            SearchIcon,
            PlusCircleIcon,
            ClipboardIcon,
            UserIcon,
            MapPinIcon,
            InfoIcon,
            datetime
        },
        created() {
           
        },
        mounted(){
            let userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.tokendata = window.localStorage.getItem("token");
                this.username = userdata.username
                this.empid = userdata.userid
                this.gettotalTicketcount()
            }
            if (feather) {
                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        },
        
        methods: {
            resetRecords: function () {
                this.search.requestid = null;
                this.search.empcode = null;
                localStorage.removeItem('searchItems')
                this.gettotalTicketcount();
            },
            clickCallback: function(pageNum) {
                this.pageoffset=pageNum
                this.currPageNum = pageNum - 1
                this.gettotalTicketcount()
            },
            selcitydata3(state,value){
                this.request.fromcity3=null
                this.request.fromcity3=state
            },
            seltocitydata3(state,value){
                this.request.tocity3=null
                this.request.tocity3=state
            },
            seltransportdata3(state,value){
                this.request.transport3=null
                this.request.transport3=state
            },
            selcitydata2(state,value){
                this.request.fromcity2=null
                this.request.fromcity2=state
            },
            seltocitydata2(state,value){
                this.request.tocity2=null
                this.request.tocity2=state
            },
            seltransportdata2(state,value){
                this.request.transport2=null
                this.request.transport2=state
            },
            selcitydata(state,value){
                this.request.fromcity=null
                this.request.fromcity=state
            },
            seltocitydata(state,value){
                this.request.tocity=null
                this.request.tocity=state
            },
            seltransportdata(state,value){
                this.request.transport=null
                this.request.transport=state
            },
            gettotalTicketcount(){
                this.apiURL="api/reimbursement/gettotalTicketcount"
                this.input={
                    offset:this.pageoffset,
                    limit: apiUrl.LIMIT,
                    useremail: this.username
                }
                if(this.$route.query.level) 
                this.input.level = this.$route.query.level
    
                if(this.search.requestid) 
                this.input.ticketid = this.search.requestid
    
        
                if(this.$route.query.status !=null) 
                this.input.status = parseInt(this.$route.query.status) 
    
                if(this.empid) 
                this.input.empcode = this.empid
    
                this.isLoading= true;
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {             
                    if(result.data.status){
                        this.isLoading= false;
                        this.requestList = result.data.data.rows;
                        this.totalcount = result.data.data.count
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }else{
                        this.requestList = []
                        this.isLoading= false;
                        this.totalcount = 0
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }
                })
            },
            displaymgr(){
                this.showmanager = !this.showmanager
            },
            showreturnbooking(flag){
                if(flag==1){
                    this.returnbooking= false
                }else{
                    this.returnbooking= true
                }
            },
            showmorevisit(){
                this.addmorecity = !this.addmorecity
            },
            showdate(flag){
                if(flag==1){
                    this.isActiveEndDate= false
                }else{
                    this.isActiveEndDate= true
                }
                
            },
            showdate2(flag){
                if(flag==1){
                    this.isActiveEndDate2= false
                }else{
                    this.isActiveEndDate2= true
                }
                
            },
            idclick(indexx){
                if ($("#cycle_readmore"+indexx).hasClass("readmore")) {
                    $("#"+indexx).html("Read More");
                    $("#cycle_readmore"+indexx).removeClass("readmore");
                } else {
                    $("#"+indexx).html("Read Less");
                    $("#cycle_readmore"+indexx).addClass("readmore");
                }
            },
            formatData(data) {
                console.log("data",data,data.length);
                return data
            },
    
            validText : function(inputval) {
                let re = /[A-Za-z0-9].{5,}/;
                if(inputval){
                    return re.test(inputval.toLowerCase());
                }
            },
            mydetailsollapse:function(){
                this.isDetActive=!this.isDetActive
            },
            myrequestollapse:function(){
                this.isRequestActive=!this.isRequestActive
            },
            mysearchrequestollapse:function(){
                this.isSearchRequestActive=!this.isSearchRequestActive
            }
        }
    
    })
    </script>
    <style scoped>
    .display{
        display: none;
    }
    .cycle_viewmoretext a{
      text-align: left;
    }
    .cycle_viewmoretext a strong{
            color: blue;
    }
    </style>
    