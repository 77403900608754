<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="modern-horizontal-wizard">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h3 class="content-header-title float-left mb-1">MOM</h3>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">Update MOM</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
            <div style="margin-top:10px;">
              <div class="card-body">
                <form class="form form-vertical">
                  <div class="row">
                      <div class="col-md-4 mb-1">
                          <div class="form-group">
                          <label for="projectmasterdd">Project Name</label>
                          <treeselect  v-if="mom.projectid == null" placeholder="Enter min 4chars to search project name" v-on:search-change="projectChange" v-model="mom.projectid"  :options="mom.projectlist" @input="selprojdata" />
                          <treeselect type="text" v-else :options="mom.projectlist" v-model="mom.projectid" @input="selprojdata" />
                          </div>
                      </div>
                      <div class="col-md-4 mb-1">
                          <div class="form-group">
                          <label for="projectmasterdd">Project Module</label>
                          <treeselect  placeholder="Select project module" v-model="mom.projectmoduleid" :options="mom.projectmodulelist" @input="selprojmoduledata" />
                          </div>
                      </div>
                      <div class="col-md-4 mb-1">
                          <div class="form-group ">
                              <label for="location">Location</label>
                              <input type="text" id="Enter location" class="form-control" name="location" placeholder="Enter location" v-model="mom.location"  v-bind:class="{'form-control': true}"/>
                          </div>
                      </div>
                      <div class="col-sm-12">
                      <div class="form-group">
                          <div class="form-group">
                              <label class="form-label" for="project-name">Meeting Objective</label>
                              <ckeditor :editor="editor" placeholder="Enter Meeting Objective" v-model="mom.objective" :config="editorConfig" tag-name="textarea" v-bind:class="{  'form-control': true }"
                              />
                              <div class="invalid-feedback">Meeting Objective required</div>                                           
                          </div>
                      </div>
                      </div>
                      <div class="col-md-4 mb-1">
                          <div class="form-group">
                          <label for="projectmasterdd">Meeting Facilitator</label>
                          <treeselect  class="facilitator capitalisetext" placeholder="Select Meeting Facilitator" v-model="mom.facilitator" :options="mom.facilitatorlist" @input="selfacilitatorto"  />
                          </div>
                      </div>
                      <div class="col-md-4 mb-1">
                          <div class="form-group">
                          <label for="projectmasterdd">Business Analyst</label>
                          <treeselect  placeholder="Select Business Analyst" v-model="mom.businesanayst" :options="mom.businesanalystlist" @input="selbusinesanayst"  />
                          </div>
                      </div>
                     
                      <div class="form-group mg-bot0 col-md-4">
                          <label for="">Date of Meeting</label>
                          <div class="input-group input-group-merge">
                              <date-picker v-model="mom.meetingdate" valueType="format" class="startdate w-100" placeholder="Select Start Date" 
                              ></date-picker>
                          </div>
                      </div>

                      <div class="form-group col-md-4">
                          <div class="form-group mg-bot0">
                          <label class="form-label" for="spendtime">Start Time</label>
                          <div id="spendtime">
                              <vue-timepicker type="time" format="HH:mm" v-model="mom.starttime"></vue-timepicker>
                          </div>
                          </div>
                      </div>
                      <div class="form-group col-md-4">
                          <div class="form-group mg-bot0">
                          <label class="form-label" for="spendtime">End Time</label>
                          <div id="spendtime">
                              <vue-timepicker type="time" v-model="mom.endtime" format="HH:mm"></vue-timepicker>
                          </div>
                          </div>
                      </div>  
                  </div>
                <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                  <legend class="test_casebhg_fieldset_bg"> 
                      <span class="leg_mobile">Meeting Agenda</span> 
                  </legend>
                    <div class="card mb-0">
                      <div class="card-body p-0">
                        <form class="validate-form">
                          <div class="row mx-0">
                            <div class="col-12 mobile-px-0 px-0">
                              <template v-for="(m1,index) in mom.formdata">
                                <div v-bind:key="index" class="card border-warning mb-1">
                                  <div v-bind:class="[{show: m1},card_content,collapse]">
                                    <div class="card-body">
                                      <form>
                                        <div class="row">
                                          <div class="form-group col-md-3">
                                            <label for="topic">Topic</label>
                                            <input type="text" id="topic" class="form-control" placeholder="Enter topic" v-model="mom.formdata[index].topic" />
                                          </div>
                                          <div class="form-group col-md-3">
                                            <label for="owner">Owner</label>
                                            <treeselect placeholder="Select owner" class="projectlistdd" v-model="mom.formdata[index].owner" :options="mom.createdbylist" />
                                          </div>
                                          <div class="form-group col-md-3">
                                            <div class="form-group mg-bot0">
                                              <label class="form-label" for="spendtime">Time</label>
                                              <div id="spendtime">
                                                  <vue-timepicker type="time" format="HH:mm" v-model="mom.formdata[index].time"></vue-timepicker>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group col-md-3 viewsfont-medium-3">
                                            <label class="form-label w-100" for="spendtime">&nbsp;</label>
                                            <div class="avatar bg-success rounded ml-1">
                                                <div class="avatar-content " @click="addAgendaForm()" style="cursor: pointer;">
                                                  <em class="las la-plus-square"></em>
                                                </div>
                                            </div>
                                            <div class="avatar bg-danger rounded ml-1">
                                                <div class="avatar-content" @click="deleteField(index)" style="cursor: pointer;">
                                                  <em class="las la-minus-square"></em>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </form>
                      </div>  
                    </div>
                </fieldset>
                <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                  <legend class="test_casebhg_fieldset_bg"> 
                      <span class="leg_mobile">Attendees</span> 
                  </legend>
                    <div class="card mb-0">
                      <div class="card-body p-1">
                        <form class="validate-form">
                          <div class="row">
                            <div class="col-12 mobile-px-0 px-0">
                              <template v-for="(m2, index) in mom.formdata1">
                                <div v-bind:key="index" class="card border-warning mb-1">
                                  <div v-bind:class="[{show: m2},card_content,collapse]">
                                    <div class="card-body p-1">
                                      <form>
                                        <div class="row">
                                          <div class="form-group col-md-3">
                                            <label for="owner">User</label>
                                            <treeselect placeholder="Select user" class="projectlistdd" v-model="mom.formdata1[index].user" :options="mom.createdbylist" @input="selectuser"/>
                                          </div>
                                          <div class="form-group col-md-3">
                                            <label for="owner">Department</label>
                                            <treeselect placeholder="Select department" class="projectlistdd" v-model="mom.formdata1[index].dept" :options="mom.deptlist"/>
                                          </div>
                                          <div class="form-group col-md-3">
                                            <label for="owner">Email</label>
                                            <treeselect placeholder="Select email" class="projectlistdd" v-model="mom.formdata1[index].email" :options="mom.emailids"/>
                                          </div>
                                          <div class="form-group col-md-3 viewsfont-medium-3">
                                            <label class="form-label w-100" for="spendtime">&nbsp;</label>
                                            <div class="avatar bg-success rounded ml-1">
                                                <div class="avatar-content " @click="addAttendeesForm()" style="cursor: pointer;">
                                                  <em class="las la-plus-square"></em>
                                                </div>
                                            </div>
                                            <div class="avatar bg-danger rounded ml-1">
                                                <div class="avatar-content" @click="deleteField1(index)" style="cursor: pointer;">
                                                  <em class="las la-minus-square"></em>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </form>
                      </div>  
                    </div>
                </fieldset>
                <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                  <legend class="test_casebhg_fieldset_bg"> 
                      <span class="leg_mobile">Absentees</span> 
                  </legend>
                    <div class="card mb-0">
                      <div class="card-body p-0">
                        <form class="validate-form">
                          <div class="row mx-0">
                            <div class="col-12 mobile-px-0 px-0">
                              <template v-for="(m3, index) in mom.formdata2">
                                <div v-bind:key="index" class="card border-warning mb-1">
                                  <div v-bind:class="[{show: m3},card_content,collapse]">
                                    <div class="card-body p-1">
                                      <form>
                                        <div class="row">
                                          <div class="form-group col-md-3">
                                            <label for="owner">User</label>
                                            <treeselect placeholder="Select user" class="projectlistdd" v-model="mom.formdata2[index].user" :options="mom.createdbylist"/>
                                          </div>
                                          <div class="form-group col-md-3">
                                            <label for="owner">Department</label>
                                            <treeselect placeholder="Select department" class="projectlistdd" v-model="mom.formdata2[index].dept" :options="mom.deptlist"/>
                                          </div>
                                          <div class="form-group col-md-3">
                                            <label for="owner">Email</label>
                                            <treeselect placeholder="Select email" class="projectlistdd" v-model="mom.formdata2[index].email" :options="mom.emailids"/>
                                          </div>
                                          <div class="form-group col-md-3 viewsfont-medium-3 viewsfont-medium-3">
                                            <label class="form-label w-100" for="spendtime">&nbsp;</label>
                                            <div class="avatar bg-success rounded ml-1">
                                                <div class="avatar-content " @click="addAbsendeesForm()" style="cursor: pointer;">
                                                  <em class="las la-plus-square"></em>
                                                </div>
                                            </div>
                                            <div class="avatar bg-danger rounded ml-1">
                                                <div class="avatar-content" @click="deleteField2(index)" style="cursor: pointer;">
                                                  <em class="las la-minus-square"></em>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </form>
                      </div>  
                    </div>
                </fieldset>
                <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                  <legend class="test_casebhg_fieldset_bg"> 
                      <span class="leg_mobile">Pre-work Preparation</span> 
                  </legend>
                    <div class="card mb-0">
                      <div class="card-body p-0">
                        <form class="validate-form">
                          <div class="row mx-0">
                            <div class="col-12 mobile-px-0 px-0">
                              <template v-for="(m4, index) in mom.formdata3">
                                <div v-bind:key="index" class="card border-warning mb-1">
                                  <div v-bind:class="[{show: m4},card_content,collapse]">
                                    <div class="card-body p-1">
                                      <form>
                                        <div class="row">
                                          <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="form-group">
                                                    <label class="form-label" for="project-name">Description</label>
                                                    <ckeditor :editor="editor" placeholder="Enter description" v-model="mom.formdata3[index].workdescription"  :config="editorConfig" tag-name="textarea" v-bind:class="{  'form-control': true }"
                                                    />
                                                    <div class="invalid-feedback">description required</div>                                           
                                                </div>
                                            </div>
                                          </div>
                                          <div class="form-group col-md-3">
                                            <label for="owner">Prepared by</label>
                                            <treeselect placeholder="Select prepared by" class="projectlistdd" v-model="mom.formdata3[index].preparedby" :options="mom.createdbylist"/>
                                          </div>
                                          <div class="form-group col-md-3">
                                            <label class="form-label w-100" for="spendtime">&nbsp;</label>
                                            <div class="avatar bg-success rounded ml-1">
                                                <div class="avatar-content " @click="addPreworkForm()" style="cursor: pointer;">
                                                    <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                                </div>
                                            </div>
                                            <div class="avatar bg-danger rounded ml-1">
                                                <div class="avatar-content" @click="deleteField3(index)" style="cursor: pointer;">
                                                    <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </form>
                      </div>  
                    </div>
                </fieldset>
                <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                  <legend class="test_casebhg_fieldset_bg"> 
                      <span class="leg_mobile">Solutions, Notes, Decisions, Issues, Change Requests</span> 
                  </legend>
                    <div class="card mb-0">
                      <div class="card-body p-0">
                        <form class="validate-form">
                          <div class="row mx-0">
                            <div class="col-12 mobile-px-0 px-0">
                              <template v-for="(m5, index) in mom.formdata4">
                                <div v-bind:key="index" class="card border-warning mb-1">
                                  <div v-bind:class="[{show: m5},card_content,collapse]">
                                    <div class="card-body p-1">
                                      <form>
                                        <div class="row">
                                          <div class="form-group col-md-3">
                                            <label for="topic">Topic</label>
                                            <input type="text" class="form-control" name="topic" placeholder="Enter topic" v-model="mom.formdata4[index].topic" />
                                          </div>
                                          <div class="form-group col-md-3">
                                            <label for="owner">Owner</label>
                                            <treeselect placeholder="Select owner" class="projectlistdd" v-model="mom.formdata4[index].owner" :options="mom.createdbylist"/>
                                          </div>
                                          <div class="form-group col-md-3">
                                            <div class="form-group mg-bot0">
                                              <label class="form-label" for="spendtime">Time</label>
                                              <div id="spendtime">
                                                  <vue-timepicker type="time" format="HH:mm" v-model="mom.formdata4[index].time"></vue-timepicker>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group col-md-3">
                                            <label class="form-label w-100" for="spendtime">&nbsp;</label>
                                            <div class="avatar bg-success rounded ml-1">
                                                <div class="avatar-content " @click="addSolutionForm()" style="cursor: pointer;">
                                                    <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                                </div>
                                            </div>
                                            <div class="avatar bg-danger rounded ml-1">
                                                <div class="avatar-content" @click="deleteField4(index)" style="cursor: pointer;">
                                                    <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                </div>
                                            </div>
                                          </div>
                                        </div>

                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </form>
                      </div>  
                    </div>
                </fieldset>
                <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                  <legend class="test_casebhg_fieldset_bg"> 
                      <span class="leg_mobile">Action Items</span> 
                  </legend>
                    <div class="card mb-0">
                      <div class="card-body p-0">
                        <form class="validate-form">
                          <div class="row mx-0">
                            <div class="col-12 mobile-px-0 px-0">
                              <template v-for="(m6, index) in mom.formdata5">
                                <div v-bind:key="index" class="card border-warning mb-1">
                                  <div v-bind:class="[{show: m6},card_content,collapse]">
                                    <div class="card-body p-1">
                                      <form>
                                        <div class="row">
                                          <div class="form-group col-md-3">
                                            <label for="topic">Action</label>
                                            <input type="text" class="form-control" name="topic" placeholder="Enter action" v-model="mom.formdata5[index].action" />
                                          </div>
                                          <div class="form-group col-md-3">
                                            <label for="owner">Owner</label>
                                            <treeselect placeholder="Select owner" class="projectlistdd" v-model="mom.formdata5[index].owner" :options="mom.createdbylist"/>
                                          </div>
                                          <div class="form-group col-md-3">
                                            <label for="owner">Status</label>
                                            <treeselect placeholder="Select status" class="projectlistdd" v-model="mom.formdata5[index].status" :options="mom.statuslist"/>
                                          </div>
                                          <div class="form-group col-md-3">
                                              <div class="form-group datePicker">
                                              <label for="" class="d-block">Due Date</label>
                                                  <date-picker placeholder="Select Due date" v-model="mom.formdata5[index].duedate" type="datetime"  format="YYYY-MM-DD HH:mm" valueType="format" class="startdate" id="StartDate"></date-picker>
                                              </div>
                                          </div>
                                          <div class="form-group col-md-12 text-right">
                                            <div class="avatar bg-success rounded ml-1">
                                                <div class="avatar-content " @click="addActionForm()" style="cursor: pointer;">
                                                    <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                                </div>
                                            </div>
                                            <div class="avatar bg-danger rounded ml-1">
                                                <div class="avatar-content" @click="deleteField5(index)" style="cursor: pointer;">
                                                    <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                </div>
                                            </div>
                                          </div>
                                        </div>

                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </form>
                      </div>  
                    </div>
                </fieldset>
                  <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                    <legend class="test_casebhg_fieldset_bg"> 
                        <span class="leg_mobile">Next Meeting</span> 
                    </legend>
                      <div class="card mb-0">
                        <div class="card-body p-0">
                          <form class="validate-form">
                            <div class="row mx-0">
                              <div class="col-12 mobile-px-0 px-0">
                                <template v-for="(m7, index) in mom.formdata6">
                                  <div v-bind:key="index" class="card border-0 mb-0">
                                    <div v-bind:class="[{show: m7},card_content,collapse]">
                                      <div class="card-body p-0">
                                        <form>
                                          <div class="row">
                                            <div class="col-md-4 mb-1">
                                                <div class="form-group datePicker">
                                                <label for="" class="d-block">Date</label>
                                                    <date-picker placeholder="Select date" v-model="m7.date" type="datetime"  format="YYYY-MM-DD HH:mm" valueType="format" class="startdate" id="StartDate"></date-picker>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <div class="form-group mg-bot0">
                                                <label class="form-label" for="spendtime">Time</label>
                                                <div id="spendtime">
                                                    <vue-timepicker type="time" format="HH:mm" v-model="m7.time"></vue-timepicker>
                                                </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-1">
                                                <div class="form-group ">
                                                    <label for="location">Location</label>
                                                    <input type="text" id="Enter location" class="form-control" name="location" v-model="m7.location" placeholder="Enter location"  v-bind:class="{'form-control': true}"/>
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                            <div class="form-group">
                                                <div class="form-group">
                                                    <label class="form-label" for="project-name">Meeting Objective</label>
                                                    <ckeditor :editor="editor" placeholder="Enter Meeting Objective" v-model="m7.objective" :config="editorConfig" tag-name="textarea" v-bind:class="{  'form-control': true }"
                                                    />
                                                    <div class="invalid-feedback">Meeting Objective required</div>                                           
                                                </div>
                                            </div>
                                            </div>
                                          </div>
  
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </form>
                        </div>  
                      </div>
                  </fieldset>
  
                    <div class="row">
                      <div class="col-md-12 mb-1 text-right">
                          <button type="button" class="btn btn-relief-primary mr-1"  v-on:click.stop.prevent="updateData()" id="submitbtnt13">Update</button>
                          <button type="reset" class="btn btn-outline-secondary" v-on:click="handleCancel()">Cancel</button>
                      </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios'
  import apiUrl from '../../constants';
  import VueElementLoading from 'vue-element-loading';
  import Loading from 'vue-loading-overlay';
  import Pagination from 'vue-pagination-2'
  import 'vue-loading-overlay/dist/vue-loading.css'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import { PlusCircleIcon, EditIcon, PlusSquareIcon,MinusIcon,PlusIcon,MinusSquareIcon } from "vue-feather-icons";
  import Treeselect from '@riophae/vue-treeselect'
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
  import CKEditor from '@ckeditor/ckeditor5-vue2';
  import VueTimepicker from 'vue2-timepicker'
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import moment from 'moment'
  import commonMethods from '../../utils/commonMethods';

import index from 'watch-size';
  
  export default {
      name:'momupdate',
      mixins: [ commonMethods ],
      components: {
          VueElementLoading,
          Loading,
          PlusCircleIcon,
          EditIcon,
          PlusSquareIcon,
          Pagination,
          Treeselect,
          DatePicker,
          MinusIcon,
          PlusIcon,
          MinusSquareIcon,
          ckeditor: CKEditor.component,VueTimepicker
      },
      data(){
          return{
              isLoading: false,
              pagefeatures:[],
              undt:null,
              tokendata:null,
              fullPage: true,
              editor: ClassicEditor,
              editorConfig: {
                  toolbar: {
                      items: [
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          '|',
                          'bulletedList',
                          'numberedList',
                          '|',
                          'insertTable',
                          '|',
                          'undo',
                          'redo', '|',
                      ],
                  },
              },
              mom:{
                  projectid: null,
                  projectlist:[],
                  projectmoduleid: null,
                  projectmodulelist: [],
                  facilitator:null,
                  facilitatorlist: [],
                  businesanayst: null,
                  businesanalystlist: [],
                  createdby: null,
                  createdbylist: [],
                  emailids:[],
                  deptlist:apiUrl.deptlist,
                  statuslist:apiUrl.status,
                  status: null,
                  meetingdate: null,
                  starttime:"",
                  endtime: "",
                  formdata: [
                    {
                      topic: null,
                      owner: null,
                      time: "",
                      createdbylist:[],
                      showform: true,
                    }
                  ],
                  formdata1: [
                    {
                      user: null,
                      dept: null,
                      deptlist:apiUrl.deptlist,
                      email: null,
                      showform: true,
                    }
                  ],
                  formdata2: [
                    {
                      user: null,
                      dept: null,
                      deptlist:apiUrl.deptlist,
                      email: null,
                      showform: true,
                    }
                  ],
                  formdata3: [
                    {
                      workdescription: '',
                      preparedby: null,
                      showform: true,
                    }
                  ],
                  formdata4: [
                    {
                      topic: null,
                      owner: null,
                      time: "",
                      showform: true,
                    }
                  ],
                  formdata5: [
                    {
                      action: '',
                      owner: null,
                      status: null,
                      statuslist:apiUrl.status,
                      duedate: null,
                      showform: true,
                    }
                  ],
                  formdata6: [
                    {
                      date: '',
                      time: "",
                      location: null,
                      objective: null,
                      showform: true,
                    }
                  ],
              },
              card_content:'card-content',
              collapse:'collapse',
  
          }
      },
      created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
          if (element.page === '/fc_mom/momcreate') {
            this.pagefeatures[element.featurename] = element.featureaccess
          }
        })
      },
      mounted() {
          // let permission = this.$Service.verifyAccess();
          // if(permission){
              let userdata = window.localStorage.getItem('userdata');
              if(userdata) {
                  userdata = JSON.parse(userdata)
                  this.undt = userdata
                  this.loginuscondition = userdata.userid;
                  this.loginusername = userdata.username;
                  this.tokendata = window.localStorage.getItem('token');                  
                  // this.getUsersEmailData()
              }
          // }
          this.getfcmombyplannerid(this.$route.params.plannermasterid,this.$route.params.id)
      },
  methods: {
      getProjectAllocatedToEmp(pid) {
        if(pid && pid.length > 0){
          this.isLoading = true
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
            projectidarr: pid,
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode : this.undt.userid,
            isfrompage: 'isprojectid'
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers': { 'authorization': this.tokendata }
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.mom.projectlist = result.data.data;
            } else {
              this.mom.projectlist = [];
            }
          }).catch(e => {
            this.displayError(e)
          });
        }
      },
      projectChange: function (node, instanceId) {
        if (node && node.length > 3) {
          this.isLoading = true;
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
            empid: parseInt(this.undt.userid),
            projectname: node.toLowerCase(),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            isfrompage: 'updatepage'
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.mom.projectlist = result.data.data;
            } else {
              this.mom.projectlist = [];
            }
          }).catch(e => {
            this.displayError(e)
          });
        }
      },
      getProjectmodulebyProject(projectid) {
        axios({
          method: "POST",
          url: "api/master/fcmom/getprojectmodulebyprojectid",
          data: { projectid:projectid,empcode: this.undt.userid,
          useremail: this.undt.username, isfrompage: 'updatepage'},
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.mom.projectmodulelist = result.data.data;

              if(this.mom.projectmodulelist && this.mom.projectmodulelist.length > 0 && (this.mom.projectmoduleid && this.mom.projectmoduleid !=null)){
                const checkpromodules = this.mom.projectmodulelist.some(item =>item.id === this.mom.projectmoduleid);
                if(!checkpromodules){
                  this.getProjectmodulebyid(this.mom.projectmoduleid);
                }
              }else if(this.mom.projectmodulelist.length ==0 && (this.mom.projectmoduleid && this.mom.projectmoduleid !=null)){
                this.getProjectmodulebyid(this.mom.projectmoduleid);
              }
            } else {
              this.mom.projectmodulelist = []
            }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getProjectmodulebyid(moduleid) {
        axios({
          method: "POST",
          url: "api/master/fcmom/getprojectmodulebyprojectid",
          data: { projectmoduleid: moduleid,empcode: this.undt.userid,
          useremail: this.undt.username},
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if(this.mom.projectmodulelist && this.mom.projectmodulelist.length > 0 && (this.mom.projectmoduleid && this.mom.projectmoduleid !=null)){
            const checkpromodule = this.mom.projectmodulelist.some(item =>item.id === this.mom.projectmoduleid);
            if(!checkpromodule){
              this.mom.projectmodulelist.push(result.data.data[0]);
            }
          }else if(this.mom.projectmodulelist.length ==0 && (this.mom.projectmoduleid && this.mom.projectmoduleid !=null)){
            if(result.data.data && result.data.data[0].length > 0){
              this.mom.projectmodulelist.push(result.data.data[0]);
            }
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      selprojdata(state, projvalue) {
        this.mom.projectid = null;
        this.mom.projectid = state;
        if (!state) {
          this.mom.projectlist = [];
        } else {
          this.getEmpallocatedtoProject(state)
          this.getProjectmodulebyProject(state)
          // this.getUsersEmailData(state)
          this.getEmpbyProjectforMOM(state)
        }
      },
      selprojmoduledata(state) {
        this.mom.projectmoduleid = null,
        this.mom.projectmoduleid = state
        // if(!state) {
        //     this.errors.tasktype.projectmoduleid = 'Project Module is required'
        // } else {
        //     this.errors.tasktype.projectmoduleid = ''
        // }
      },
      getEmpallocatedtoProject(projectid) {
        axios({
          method: "POST",
          url: "api/listing/getEmpallocatedtoProject",
          data: { projectid: projectid,useremail: this.undt.username,empcode: this.undt.userid, },
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            let decryptdepartmenttext = result.data.data.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
            this.mom.facilitatorlist = result.data.data;
            this.mom.businesanalystlist = result.data.data;
          } else {
            this.mom.facilitatorlist = [];
            this.mom.businesanalystlist = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getEmpbyProjectforMOM(projectid) {
        axios({
          method: "POST",
          url: "api/master/fcmom/getEmpbyProjectMOM",
          data: { projectid: projectid,useremail: this.undt.username,empcode: this.undt.userid, },
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.mom.createdbylist = result.data.data 
          } else {
            this.mom.createdbylist = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      selfacilitatorto(state, value) {
        this.mom.facilitator = null,
        this.mom.facilitator = state
        // if(!state) {
        //   this.errors.mom.facilitator = 'Meeting facilitator is required'
        // } else {
        //   this.errors.mom.facilitator = ''
        // }
      },
      selbusinesanayst(state, value) {
        this.mom.businesanayst = null,
        this.mom.businesanayst = state
        // if(!state) {
        //   this.errors.mom.facilitator = 'Meeting facilitator is required'
        // } else {
        //   this.errors.mom.facilitator = ''
        // }
      },
      selowner(state, value) {
        this.mom.owner = null,
        this.mom.owner = state
        // if(!state) {
        //   this.errors.mom.facilitator = 'Meeting facilitator is required'
        // } else {
        //   this.errors.mom.facilitator = ''
        // }
      },
      addAgendaForm() {
        this.mom.formdata.push({
          topic: null,
          owner: null,
          time: null,
          showform: true,
        })
      },
      addAttendeesForm() {
        this.mom.formdata1.push({
          user: null,
          dept: null,
          email: null,
          phone: null,
          showform: true,
        })
      },
      addAbsendeesForm() {
        this.mom.formdata2.push({
          user: null,
          dept: null,
          email: null,
          phone: null,
          showform: true,
        })
      },
      addPreworkForm() {
        this.mom.formdata3.push({
          workdescription: '',
          preparedby: null,
          showform: true,
        })
      },
      addSolutionForm() {
        this.mom.formdata4.push({
          topic: null,
          owner: null,
          time: null,
          showform: true,
        })
      },
      addActionForm() {
        this.mom.formdata5.push({
          action: '',
          owner: null,
          status: null,
          duedate: null,
          showform: true,
        })
      },
      deleteField(index) {
        this.mom.formdata.splice(index, 1)
      },
      deleteField1(index) {
        this.mom.formdata1.splice(index, 1)
      },
      deleteField2(index) {
        this.mom.formdata2.splice(index, 1)
      },
      deleteField3(index) {
        this.mom.formdata3.splice(index, 1)
      },
      deleteField4(index) {
        this.mom.formdata4.splice(index, 1)
      },
      deleteField5(index) {
        this.mom.formdata5.splice(index, 1)
      },
  
      handleCollapse(idx) {
        this.mom.formdata[idx].showform = !this.mom.formdata[idx].showform
      },
  
      // getUsersEmailData(projectid) {
      //   axios({
      //     method: "POST",
      //     url: "api/master/fcmom/getEmpEmailbyProject",
      //     data: {projectid: projectid,
      //       useremail: this.undt.username,
      //       empcode: this.undt.userid, },
      //     'headers':{'authorization':this.tokendata}
      //   }).then((result) => {
      //     this.isLoading = false;
      //     if (result.data.errorCode == 0) {
      //       this.mom.emailids = result.data.data;
      //     } else {
      //       this.mom.emailids = [];
      //     }
      //   });
      // },

      dateFormat(value) {
          if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY');
      },
      getfcmombyplannerid(plannermasterid,id){
            this.isLoading = true;
            let url = "api/master/fcmom/getfcmombyplannerid";
            this.input = {
              plannermasterid:plannermasterid,
              id: parseInt(id),
              useremail: this.undt.username,
              empcode: this.undt.userid,
            };
            axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                this.editRowData = result.data.data;
                this.openEditModule(result.data.data[0],0)
                } else if (result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
            }).catch(e => {
            this.displayError(e)
          });
        },
        openEditModule: function (currRowData, index) {
          this.editRowData = currRowData;
          this.mom.projectid = currRowData.projectid;
          this.mom.projectmoduleid = currRowData.projectmoduelid;
          this.mom.location = currRowData.location;
          this.mom.objective = currRowData.description;
          this.mom.facilitator = currRowData.facilitator;
          this.mom.businesanayst = currRowData.ba;
          // this.mom.createdby = currRowData.createdby;
          this.mom.meetingdate = currRowData.dateofmeeting,
          this.mom.starttime = currRowData.starttime,
          this.mom.endtime = currRowData.endtime
          if(this.mom.projectmoduleid && this.mom.projectmoduleid !=null){
            this.getProjectmodulebyid(this.mom.projectmoduleid);
          }

          this.mom.formdata = [];
          if(currRowData.agenda.data.length > 0){
            for(let i=0; i < currRowData.agenda.data.length; i++) {
              let pushObj = {
                topic : currRowData.agenda.data[i].topic,
                owner : currRowData.agenda.data[i].owner,
                time : currRowData.agenda.data[i].time,
                index:i
              }
              this.mom.formdata.push(pushObj);
            }
          }
          if(this.mom.projectid && this.mom.projectid !=null){
            this.getProjectAllocatedToEmp(this.mom.projectid ? [this.mom.projectid] :[])
          }
          this.mom.formdata1 = [];
          if(currRowData.attendees.data.length > 0){
            for(let i=0; i < currRowData.attendees.data.length; i++) {
              let pushObj1 = {
                dept : currRowData.attendees.data[i].dept,
                user : currRowData.attendees.data[i].user,
                email : currRowData.attendees.data[i].email,
                index:i
              }
              this.mom.formdata1.push(pushObj1);
            }
          }

          this.mom.formdata2 = [];
          if(currRowData.absentees.data.length > 0){
            for(let i=0; i < currRowData.absentees.data.length; i++) {
              let pushObj1 = {
                dept : currRowData.absentees.data[i].dept,
                user : currRowData.absentees.data[i].user,
                email : currRowData.absentees.data[i].email,
                index:i
              }
              this.mom.formdata2.push(pushObj1);
            }
          }

          this.mom.formdata3 = [];
          if(currRowData.prework.data.length > 0){
            for(let i=0; i < currRowData.prework.data.length; i++) {
              let pushObj = {
                preparedby : currRowData.prework.data[i].preparedby,
                workdescription : currRowData.prework.data[i].workdescription,
                index:i
              }
              this.mom.formdata3.push(pushObj);
            }
          }

          this.mom.formdata4 = [];
          if(currRowData.solutions.data.length > 0){
            for(let i=0; i < currRowData.solutions.data.length; i++) {
              let pushObj = {
                time : currRowData.solutions.data[i].time,
                owner : currRowData.solutions.data[i].owner,
                topic : currRowData.solutions.data[i].topic,
                index:i
              }
              this.mom.formdata4.push(pushObj);
            }
          }

          this.mom.formdata5 = [];
          if(currRowData.actionitems.data.length > 0){
            for(let i=0; i < currRowData.actionitems.data.length; i++) {
              let pushObj = {
                owner : currRowData.actionitems.data[i].owner,
                action : currRowData.actionitems.data[i].action,
                status : currRowData.actionitems.data[i].status,
                duedate : currRowData.actionitems.data[i].duedate,
                index:i
              }
              this.mom.formdata5.push(pushObj);
            }
          }

          this.mom.formdata6 = [];
          if(currRowData.nextmeeting.data.length > 0){
            for(let i=0; i < currRowData.nextmeeting.data.length; i++) {
              let pushObj = {
                date : currRowData.nextmeeting.data[i].date,
                time : currRowData.nextmeeting.data[i].time,
                location : currRowData.nextmeeting.data[i].location,
                objective : currRowData.nextmeeting.data[i].objective,
                index:i
              }
              this.mom.formdata6.push(pushObj);
            }
          }

          this.mom.isactive = currRowData.isactive;
          // this.getfcmombyplannerid(currRowData.plannermasterid);
        },

        updateData(){           
          // this.valid = this.validate() && this.validateForm();
          // if (this.valid) {
              this.input = {
                // createdby: this.undt.username,
                empcode: this.undt.userid,
                useremail: this.undt.username,
                // lastmodifiedby: this.undt.username                
              }
              if(this.mom.projectid){
                this.input.projectid=this.mom.projectid
              }
              if(this.mom.projectmoduleid){
                  this.input.projectmoduleid=this.mom.projectmoduleid
              }
              if(this.mom.location){
                  this.input.location=this.mom.location
              }
              if(this.mom.objective){
                  this.input.objective=this.mom.objective
              }
              if(this.mom.facilitator){
                  this.input.facilitator=this.mom.facilitator
              }
              if(this.mom.businesanayst){
                  this.input.businesanayst=this.mom.businesanayst
              }
              if(this.mom.meetingdate){
                  this.input.meetingdate=this.mom.meetingdate
              }
              if(this.mom.starttime){
                  this.input.starttime=this.mom.starttime
              }
              if(this.mom.endtime){
                  this.input.endtime=this.mom.endtime
              }

            let agenda = []
            let attendees = []
            let absendees = []
            let prework = []
            let solution = []
            let actionitems = []
            let nextmeeting = []
              if(this.mom.formdata && this.mom.formdata.length >=1){
              for(let index in this.mom.formdata){
                agenda.push({
                  "topic": this.mom.formdata[index].topic,
                  "owner": this.mom.formdata[index].owner,
                  "time": this.mom.formdata[index].time,
                })
              }
            }
            this.input.agendarray = {data:agenda }

            if(this.mom.formdata1 && this.mom.formdata1.length >=1){
              for(let index in this.mom.formdata1){
                attendees.push({
                  "user": this.mom.formdata1[index].user,
                  "dept": this.mom.formdata1[index].dept,
                  "email": this.mom.formdata1[index].email,
                })
              }
            }
            this.input.attendeesarray = {data:attendees }

            if(this.mom.formdata2 && this.mom.formdata2.length >=1){
              for(let index in this.mom.formdata2){
                absendees.push({
                  "user": this.mom.formdata2[index].user,
                  "dept": this.mom.formdata2[index].dept,
                  "email": this.mom.formdata2[index].email,
                })
              }
            }
            this.input.absendeesarray = {data:absendees }

            if(this.mom.formdata3 && this.mom.formdata3.length >=1){
              for(let index in this.mom.formdata3){
                prework.push({
                  "workdescription": this.mom.formdata3[index].workdescription,
                  "preparedby": this.mom.formdata3[index].preparedby,
                })
              }
            }
            this.input.preworkarray = {data:prework }

            if(this.mom.formdata4 && this.mom.formdata4.length >=1){
              for(let index in this.mom.formdata4){
                solution.push({
                  "topic": this.mom.formdata4[index].topic,
                  "owner": this.mom.formdata4[index].owner,
                  "time": this.mom.formdata4[index].time,
                })
              }
            }
            this.input.solutionarray = {data:solution }

            if(this.mom.formdata5 && this.mom.formdata5.length >=1){
              for(let index in this.mom.formdata5){
                actionitems.push({
                  "action": this.mom.formdata5[index].action,
                  "owner": this.mom.formdata5[index].owner,
                  "status": this.mom.formdata5[index].status,
                  "duedate": this.mom.formdata5[index].duedate,
                })
              }
            }
            this.input.actionitemsarray = {data:actionitems }

            if(this.mom.formdata6 && this.mom.formdata6.length >=1){
              for(let index in this.mom.formdata6){
                nextmeeting.push({
                  "date": this.mom.formdata6[index].date,
                  "time": this.mom.formdata6[index].time,
                  "location": this.mom.formdata6[index].location,
                  "objective": this.mom.formdata6[index].objective,
                })
              }
            }
            this.input.nextmeetingarray = {data:nextmeeting }
              let apiURL='api/master/fcmom/updatefcmom'
              // this.input.plannermasteridid=this.editRowData.plannermasterid
              // this.input.plannermasterid=1
              this.input.plannermasterid=this.$route.params.plannermasterid
              this.input.id=this.$route.params.id

              this.input.lastmodifiedby=this.undt.username

              // delete this.input.createdby;
              axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
              })
              .then(result => {             
                if(result.data.errorCode == 0){
                  Swal.fire({
                    title: "Success!",
                    text: result.data.msg,
                    icon: 'success',
                    customClass: {
                      confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                  })
                  // this.cleardata()
                  this.$router.push(`/planner/viewplan?plannermasterid=${this.$route.params.plannermasterid}`)
                }
                 else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
              }).catch(e => {
              this.displayError(e)
            })
          //}
        },
        cleardata(){
          this.mom.projectid = null;
          this.mom.projectmoduleid = null;
          this.mom.location = '';
          this.mom.objective = '';
          this.mom.facilitator = null;
          this.mom.businesanayst = null;
          this.mom.meetingdate = null;
          this.mom.formdata = {};
          this.mom.formdata1 = {};
          this.mom.formdata2 = {};
          this.mom.formdata3 = {};
          this.mom.formdata4 = {};
          this.mom.formdata5 = {};
      },
      handleCancel() {
        this.$router.push(`/planner/viewplan?plannermasterid=${this.$route.params.plannermasterid}`)
      },
      selectuser(state){
       
       axios({
         method: "POST",
         url: "api/master/fcmom/getEmpEmailbyProject",
         data: {
           empid: parseInt(state) ,
           empidabse:  parseInt(state),
           useremail: this.undt.username,
           empcode: this.undt.userid,
           name: state },
         'headers':{'authorization':this.tokendata}
       }).then((result) => {
         this.isLoading = false;
         if (result.data.errorCode == 0) {
           this.mom.emailids = result.data.data;
         } else {
           this.mom.emailids = [];
         }
       }).catch(e => {
        this.displayError(e)
      });
       if (this.mom.formdata1.length >= 1) {
          for (let i=0 ; i < this.mom.formdata1.length; i++) {  
              if (this.mom.formdata1[i].user == state) {
                this.mom.formdata1[i].email = null
              }
            }
          }
          if (this.mom.formdata2.length >= 1) {
          for (let i=0 ; i < this.mom.formdata2.length; i++) {  
              if (this.mom.formdata2[i].user == state) {
                this.mom.formdata2[i].email = null
              }
            }
          }
     }
    }
  }
  </script>