<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="modern-horizontal-wizard">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h3 class="content-header-title float-left mb-2">Issue Management</h3>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'IssueList', params: { search: true }}">Issues List</router-link></li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">Update Issue</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
        <div v-bind:class="[{show: isRequestActive},card_content,collapse]" style="margin-top:10px;">
          <div class="card-body">
            <form class="form form-vertical">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group ">
                    <label for="projectlistdd">Issue Name</label>
                    <input type="text" id="issuename" class="form-control" name="" placeholder="Issue Name" v-model="issue.issuename" @blur="validateForm('t42',$event)"/>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.issue.issuename">{{ this.errors.issue.issuename }}</div>
                </div>
                <div class="col-md-6">
                  <div class="form-group ">
                    <label for="projectmdllistdd">Project Name</label>
                    <treeselect class="projectmdllistdd capitalisetext" v-model="issue.project" :options="issue.projectlist" @input="selissuecsproject" @close="nullifybusinessfields()"/>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.issue.project">{{ this.errors.issue.project }}</div>
                </div>
                <div class="col-md-6" v-if="!this.isThisBusinessRole">
                  <div class="form-group ">
                    <label for="projectmdllistdd">Functionality</label>
                    <treeselect class="projectmdllistdd capitalisetext" v-model="issue.functionality" :options="issue.functionalitylist" @input="selissuecsfunctionality" @close="validateForm('t42',$event)"/>
                  </div>
                </div>
                <div class="col-md-6" v-if="!this.isThisBusinessRole">
                  <div class="form-group ">
                    <label for="projectmdllistdd">Micro Task Name</label>
                    <treeselect class="projectmdllistdd capitalisetext" v-model="issue.task" :options="issue.tasklist" @close="validateForm('t42',$event)"/>
                  </div>
                </div>
                <div class="col-md-6" v-if="!this.isThisBusinessRole">
                      <div class="form-group mb-1">
                        <label for="projectmdllistdd">Business Task Code</label>
                        <treeselect class="capitalisetext" placeholder="Enter Business Task Code" :disabled="isBtmFieldDisabled" v-model="issue.businesstaskcode" :options="issue.businesscodelist" @input="selbtmcode" @close="validateForm('t47',$event)"/>
                      <div class="errorinputmsg" v-if="(this.errors.issue.businesstaskcode) && !isBtmFieldDisabled">{{ this.errors.issue.businesstaskcode }}</div>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="!this.isThisBusinessRole">
                      <div class="form-group mb-1">
                        <label for="projectmdllistdd">Business Task Name</label>
                        <treeselect class="capitalisetext" placeholder="Enter Business Task Name" :disabled="isBtmFieldDisable" v-model="issue.businesstaskid" :options="issue.businesstasklist"  @input="selbtmid" @close="validateForm('t47',$event)"/>
                        <div class="errorinputmsg" v-if="(this.errors.issue.businesstaskid) && !isBtmFieldDisable">{{ this.errors.issue.businesstaskid }}</div>
                      </div>
                    </div>
                <div class="col-md-6">
                  <div class="form-group ">
                    <label for="projectmdllistdd">Issue Type</label>
                    <treeselect class="projectmdllistdd capitalisetext" v-model="issue.type" :options="issue.typelist" @input="selissuecstype" @close="validateForm('t42',$event)"/>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.issue.type">{{ this.errors.issue.type }}</div>
                </div>
                <div class="col-md-6">
                  <div class="form-group ">
                    <label for="projectmdllistdd">Issue Severity</label>
                    <treeselect class="projectmdllistdd capitalisetext" v-model="issue.sevairty" :options="issue.sevairtylist" @input="selissuecssevairty" @close="validateForm('t42',$event)"/>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.issue.sevairty">{{ this.errors.issue.sevairty }}</div>
                </div>
                <div class="col-md-6">
                  <div class="form-group ">
                    <label for="projectmdllistdd">Issue Priority</label>
                    <input v-if="issue.moduletype=='ticket'" type="text" class="form-control" name="" v-model="issue.priority" disabled/>
  
                    <treeselect v-else class="projectmdllistdd capitalisetext" v-model="issue.priority" :options="issue.prioritylist" @input="selissuecspriority" @close="validateForm('t42',$event)"/>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.issue.priority">{{ this.errors.issue.priority }}</div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label for="allotedto">Assigned To</label>
                    <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4 Chars To Search" v-model="issue.allotedto" :options="issue.allotedtolist" @input="selallotedto" @close="validateForm('t42',$event)" v-on:search-change="getEmpallocatedtoProject"/>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.issue.allotedto">{{ this.errors.issue.allotedto }}</div>
                </div>
                <div class="col-md-3">
              <div class="form-group">
                  <label for="">Subscribers</label>
                  <div class="input-group input-group-merge">
                  <treeselect class="projroleassprojectdd capitalisetext" placeholder="Enter Min 4 Chars To Search" :multiple="true" :clear-on-select="true" :options="issue.subscriberslist" v-model="issue.subscribers" @input="selSubscribers" v-on:close="validateForm('t18', $event)" v-on:search-change="getEmpallocatedtoProject"/>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.issue.subscribers">{{ this.errors.issue.subscribers }}</div>
                  </div>
              </div>
                <div class="form-group col-12">
                  <label class="form-label" for="projectmdllistdd">Issue Scenario</label>
                  <ckeditor :editor="editor"  placeholder="Issue Scenario" v-model="issue.scenario" :config="editorConfig" tag-name="textarea" @blur="validateForm('t42',$event)"></ckeditor>
                  <div class="errorinputmsg" v-if="this.errors.issue.scenario">{{ this.errors.issue.scenario }}</div>
                </div>
     
                <div class="col-md-6">
                  <div class="form-group ">
                    <label for="reasonofissue">Reason of Issue</label>
                    <ckeditor :editor="editor" id="reasonofissue" class="form-control" name="" placeholder="Reason of Issue" v-model="issue.reasonofissue"
                    :config="editorConfig" tag-name="textarea" @blur="validateForm('t42',$event)"/>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.issue.reasonofissue">{{ this.errors.issue.reasonofissue }}</div>
                </div>
                <div class="col-md-6">
                  <div class="form-group ">
                    <label for="impactofissue">Impact of Issue</label>
                    <ckeditor :editor="editor" id="impactofissue" class="form-control" name="" placeholder="Impact of Issue" v-model="issue.impactofissue" :config="editorConfig" tag-name="textarea" @blur="validateForm('t42',$event)"/>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.issue.impactofissue">{{ this.errors.issue.impactofissue }}</div>
                </div>
                <div class="col-md-6">
                  <div class="form-group ">
                    <label for="projectmdllistdd">Issue Status</label>
                    <input class="form-control capitalisetext" v-model="issue.status" :options="issue.statuslist" @input="selissuecsstatus" @close="validateForm('t42',$event)" v-if="issue.statuslist.length<=1" disabled/>
                    <treeselect class="projectmdllistdd capitalisetext" v-model="issue.status" :options="issue.statuslist" @input="selissuecsstatus" @close="validateForm('t42',$event)" v-else/>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.issue.status">{{ this.errors.issue.status }}</div>
                </div>
                <div class="col-md-6" v-if="editRowData.vendorcode">
                  <div class="form-group">
                    <label for="duedate">Due Date</label><br/>
                    <date-picker placeholder="Select Due Date" v-model="issue.duedate" valueType="format" class="startdate" :disabled-date="notBeforeToday" v-on:pick="validateForm('t42',$event)"></date-picker>
                  </div>
                    <div class="errorinputmsg" v-if="this.errors.issue.duedate">{{ this.errors.issue.duedate }}</div>
                </div>
              </div>
            </form>
          <div class="col-md-12 px-0">
              <div class="form-group">
                  <label for="lblattachment" class="d-block">Attachment</label>
                  <label for="attachment" class="btn btn-sm btn-relief-secondary mb-0 mr-75">Select image/docs/videos</label>
                  
                  <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                  <div class="table-responsive my-75" v-if="issue.attachmentview">
                      <table class="table table-sm table-bordered" aria-describedby="mydesc">
                          <thead>
                              <tr>
                                  <th scope="col">SR#</th>
                                  <th scope="col">Document Name</th>
                                  <th scope="col">Description</th>
                                  <th scope="col">Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="(data, k) in issue.attachmentview.data" :key="k">
                                  <td>{{k+1}}</td>
                                  <td>
                                      <label>
                                  <div class="click-zoom">
                                      <input type="checkbox" />
                                      <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  :src="data.presignurl"  style="width:50px;height:50px"  />
                                      <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                                      <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/pdf.png" style="width:50px;height:50px" />
                                      <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xlsx'||'csv'||'xls'" src="../../../public/images/icons/xls.png" style="width:50px;height:50px" />
                                      &nbsp;
                                      <a :href="data.presignurl" target="_blank">{{data.link | basename}}</a>
                                      </div>
                              </label>
                                  </td>
                                  <td>{{data.info}}</td>
                                  <td>
                                      <button type="button" class="btn btn-sm danger" @click="removes3file(k,issue.attachmentview.data)">
                                          <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                      </button>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.issue.attachment">{{ this.errors.issue.attachment }}</div>
                      <div class="table-responsive my-75" v-if="file.length>0">
                          <table class="table table-sm table-bordered" aria-describedby="mydesc">
                              <thead>
                                  <tr>
                                      <th scope="col">SR#</th>
                                      <th scope="col">Document Name</th>
                                      <th scope="col">Description</th>
                                      <th scope="col">Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(image, key) in file" :key="key">
                                      <td>{{ key+1 }}</td>
                                      <td>{{ image.name }}</td>
                                      <td>
                                          <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="issue.filedescp[key]"/>
                                      </td>
                                      <td>
                                          &nbsp;&nbsp;
                                          <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                              <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                          </button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <button class="btn btn-sm btn-relief-secondary mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                  </div>
              </div>
              <div class="col-12 mg-top1 text-right">
                  <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard('t42',$event)" id="submitbtnt42" v-bind:disabled="issue.disblesavebtn" >Submit</button>
                  <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <style>
  .card .card-header{
      padding: 0.8rem;
  }
  .createbtnparentdiv{
      padding-right: 0% !important;
  }
  .displayblk{
      display:block !important;
  }
  .createbtn{
      border-color: #053e52 !important;
      color: #fff !important;
      background-color: #008000 !important;
      padding: 0.386rem 0.5rem !important;
  }
  
  </style>
  <script>
  import axios from 'axios'
  import apiUrl from '../../constants';
  import moment from 'moment'
  import VueElementLoading from 'vue-element-loading';
  import vSelect from 'vue-select'
  import { PlusSquareIcon, ClipboardIcon, Trash2Icon,DownloadIcon,PlusIcon,Edit2Icon,MoreVerticalIcon,PackageIcon,Link2Icon,BoxIcon,ChromeIcon,CloudIcon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon } from 'vue-feather-icons'
  import Treeselect from '@riophae/vue-treeselect';
  import '@riophae/vue-treeselect/dist/vue-treeselect.css';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Pagination from 'vue-pagination-2';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
  import CKEditor from '@ckeditor/ckeditor5-vue2';
  import commonMethods from '../../utils/commonMethods';

  export default {
      name:'IssueCreate',
      mixins: [ commonMethods ],
      components:{
          Loading,
          VueElementLoading,
          vSelect,
          EditIcon,
          EyeIcon,
          Treeselect,
          SearchIcon,
          GridIcon,
          CodesandboxIcon,
          CloudIcon,
          ChromeIcon,
          BoxIcon,
          Link2Icon,
          PackageIcon,
          Pagination,
          MoreVerticalIcon,
          PlusIcon,
          DownloadIcon,
          Edit2Icon,
          PlusSquareIcon,
          ClipboardIcon,
          ckeditor: CKEditor.component,
          Trash2Icon,
          DatePicker,
      },    
      data() {
          return {
              isThisBusinessRole: false,
              roleid: 0,
              imgExt:['jpeg','jpg','png'],
              loginusername:'',
              loginuscondition: '',
              isLoading: false,
              fullPage: true,
              stylevariation:apiUrl.stylevariation,
              companymasterlist: [],
              editRowData:[],
              glbMdl:'',
              glbMdlLbl:'issue',
              TimeFormat: apiUrl.TimeFormat,
              issue:{
                  issuename:null,
                  project:null,
                  subscriberslist: [],
                  subscribers: null,
                  projectlist:null,
                  functionality:null,
                  functionalitylist:null,
                  type:null,
                  typelist:null,
                  scenario:null,
                  scenariolist:null,
                  attachment:null,
                  attachmentview:null,
                  attachmentnew:null,
                  sevairty:null,
                  sevairtylist:null,
                  priority:null,
                  businesstaskid:null,
                  businesstaskcode:null,
                  businesstasklist : [],
                  businesscodelist : [],
                  duedate:null,
                  prioritylist:[{
                      id:'low',
                      label:'Low'
                  },{
                      id:'medium',
                      label:'Medium'
                  },{
                      id:'high',
                      label:'High'
                  }],
                  allotedto:null,
                  allotedtolist:[],
                  reasonofissue:null,
                  impactofissue:null,
                  status:null,
                  statuslist: [],
                  iseditstate:false,
                  disblesavebtn:false,
                  task: null,
                  tasklist: [],
                  filedescp:[],
                  moduletype:null 
              },
              file:[],
              isAddState:false,
              totalcount: 0,
              pageCount: 0,
              page:1,
              pagelimit:apiUrl.LIMIT,
              currPageNum: 0,
              pageoffset:1,
              pagefeatures:[],
              showtabledata:true,
              showproject:false,
              modulelist:[],
              isSearchRequestActive:false,
              card_content:'card-content',
              collapse:'collapse',
              errors:{
                  issue:{
                      issuename:null,
                      duedate:null,
                      project:null,
                      functionality:null,
                      type:null,
                      scenario:null,
                      attachment:null,
                      sevairty:null,
                      priority:null,
                      allotedto:null,
                      reasonofissue:null,
                      impactofissue:null,
                      status:null,
                      task: null,
                      subscribers: null,
                      businesstaskid:null,
                      businesstaskcode:null
                  }
              },
              isDetActive:true,
              isRequestActive:true,
              isedit: false,
              card:'card',
              border_warning:'border-warning',
              editor: ClassicEditor,
              editorConfig: {
                  toolbar: {
                      items: [
                          'heading', '|', 'bold',
                          'italic', '|', 'bulletedList',
                          'numberedList', '|', 'insertTable', '|',
                          'undo', 'redo', '|',
                      ],
                  },
              },
              search:{
                  searchprojectname :null,
                  functionality: null,
                  task:null,
                  issuename:null,
                  type:null,
                  sevairty:null,
                  priority:null,
                  status:null,
                  allotedto:null,
                  projectnameList: [],
                  functionalitylist: [],
                  allotedtolist: [],
                  tasklist: [],
              },
              projectnameList:[],
              issuenameList:[],
              view:{
                projectname: null,
                functionlityname: null,
                typename: null,
                sevairtyname: null
              },
              s3bucket: apiUrl.s3url ,
              issuenameBlured:false,
              scenarioBlured:false,
              tokendata: null,
              isBtmFieldDisabled: false,
              isBtmFieldDisable: false,
              accepttype:apiUrl.uploadfiletype,
              validFileExtensions:apiUrl.validFileExtensions
          }
      },
      created() {
          this.pagefeatures = {}
          let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
          pagefeatures1.forEach(element => {
          if (element.page === '/assignments') {
              this.pagefeatures[element.featurename] = element.featureaccess
          }
          })
      },
      mounted(){
          let userdata = window.localStorage.getItem('userdata');
          if(userdata){
              userdata = JSON.parse(userdata)
              this.undt=userdata
              this.tokendata = window.localStorage.getItem('token');
              this.loginusername= userdata.userid
              this.loginuscondition = userdata.userid
              this.roleid = this.undt.roleid
              this.getProjectAllocatedToEmp()
              this.getIssueById(this.$route.query.issueid)
              if(this.roleid && parseInt(this.roleid) === 520) {
                  this.isThisBusinessRole = true
              }
              if(!this.isThisBusinessRole) {
                  this.getApplicationdata('t43','issue')
              }
              this.getApplicationdata('t44','issue')
              this.getSubscribers(this.$route.query.issueid, 'issue')
              this.getbusinesstaskname(this.issue.project, 'issue')
              this.getbusinesstaskcode(this.issue.project, 'issue')
          }
      },
      methods:{
        notBeforeToday: function (date) {
            this.issue.duedate = "";
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        getIssueById(issueid){
          this.isLoading = true;
          let url = "api/issue/getIssueById";
          this.input = {
              issueid:issueid,
              useremail: this.undt.username,
              empid: parseInt(this.undt.userid),
              empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                this.editRowData = result.data.data;
                this.editRowData=this.editRowData[0]
                this.openEditModule(this.editRowData,0)
              } else {
                  this.$router.push({ name: 'IssueList', params: { search: true }})
                  Swal.fire({
                      title: "Failed!",
                      text: "Your are not authorised user to update this issue",
                      icon: "info",
                      customClass: {
                      confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                  })
              }
            }).catch(e => {
        this.displayError(e)
        })
          },
          getSubscribers(moduleid, moduletype){
              this.isLoading = true;
              let url = "api/subscribers/getSubscribersByModuleid";
              this.input = { moduleid, moduletype,useremail: this.undt.username,empcode: this.undt.userid };
              axios({
                  method: "POST",
                  url: url,
                  data: this.input,
                  'headers':{'authorization':this.tokendata}
              }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                    if(result.data.data && result.data.data.rows){
                      let decryptdepartmenttext = result.data.data.rows.map(items =>{
                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                        if(tempemplabel2 && tempemplabel2 != null){
                          items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                        })
                    }
                    this.issue.subscriberslist = result.data.data.rows;
                   this.issue.subscribers = result.data.data.rows.map(d => d.id)
                  } else {
                    this.issue.subscribers = null;
                    this.issue.subscriberslist = [];
                  }
              }).catch(e => {
        this.displayError(e)
        })
          },
          getEmpallocatedtoProject(node){
            if(this.issue.project && (node && node.length > 3)){
                this.input = { projectid:this.issue.project,useremail: this.undt.username,empcode: this.undt.userid, asigneename:node.toLowerCase() }
                if(this.isThisBusinessRole) {
                    this.input.roleid = 484
                }
                axios({
                'method': 'POST',
                'url': 'api/listing/getEmpallocatedtoProject',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => { 
                    this.isLoading= false;         
                    if(result.data.errorCode == 0){
                        let decryptdepartmenttext = result.data.data.map(items =>{

                            let tempemplabel1 = items.label;
                            let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                            if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                            }
                            return items;
                        })
                        this.issue.allotedtolist=result.data.data;
                        this.issue.subscriberslist = result.data.data;
                    }else{
                        this.issue.allotedtolist = [];
                        this.issue.subscriberslist = [];
                    }
                }).catch(e => {
        this.displayError(e)
        })
            }
          },
          getEmployeeDetailsbyID(empid){
            if(this.issue.project && empid){
                axios({
                'method': 'POST',
                'url': 'api/listing/getEmpallocatedtoProject',
                'data': {assignedtoid: empid,projectid: this.issue.project,useremail: this.undt.username,empcode: this.undt.userid,},
                'headers':{'authorization':this.tokendata}
                })
                .then(result => { 
                    this.isLoading= false;         
                    if(result.data.errorCode == 0){
                        this.issue.allotedtolist=result.data.data;
                        let decryptdepartmenttext = this.issue.allotedtolist.map(items =>{

                            let tempemplabel1 = items.label;
                            let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                            if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                            }
                            return items;
                        })
                    }else{
                        this.issue.allotedtolist=[];
                    }
                }).catch(e => {
        this.displayError(e)
        })
            }
          },
          getbusinesstaskname(){
          this.inputappl = {
              projectid: this.issue.project,
              empid:this.undt.userid,
              useremail: this.undt.username,
              empcode: this.undt.userid,
              moduletype: 'issue'
          }
          axios({
          'method': 'POST',
          'url': 'api/searching/getBusinessTaskbyProject',
          'data': this.inputappl,
          'headers':{'authorization':this.tokendata}
          })
          .then(result => { 
              this.isLoading= false;         
              if(result.data.errorCode == 0){
                  this.issue.businesstasklist=result.data.data;
              }else{
                  this.issue.businesstasklist=[];
              }
          }).catch(e => {
        this.displayError(e)
        })
      },
      getbusinesstaskcode(){
          this.inputappl = {
              projectid: this.issue.project,
              empid:this.undt.userid,
              useremail: this.undt.username,
              empcode: this.undt.userid,
              moduletype: 'issue'
          }
          axios({
          'method': 'POST',
          'url': 'api/searching/getBusinessTaskCode',
          'data': this.inputappl,
          'headers':{'authorization':this.tokendata}
          })
          .then(result => { 
              this.isLoading= false;         
              if(result.data.errorCode == 0){
                  this.issue.businesscodelist=result.data.data
              }else{
                  this.issue.businesscodelist=[]
              }
          }).catch(e => {
        this.displayError(e)
        })
      },
      onFileChange(e) {
        var validFileExtensions = apiUrl.validFileExtensions
        let selectedFiles = e.target.files;
        for (let i=0; i < selectedFiles.length; i++)
        {
            var blnValid = false;
            for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
              var sCurExtension = validFileExtensions[j];
              if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                  blnValid = true;
                  break;
              }
            }
            
            if (!blnValid) {
              Swal.fire({
                    title: "ERROR",
                    text: "Sorry, Invalid Type of Extension File..!!",
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
              })
              return false;
            }
            this.file.push(selectedFiles[i]);
        }
        if(this.file.length>0) {
            this.issue.disblesavebtn=true
        }
        document.getElementById('attachment').value = ""
      },
          removeImage (index) {
              if(this.file.length>0) {
                  this.file.splice(index, 1);
                  this.issue.filedescp.splice(index, 1);
                  var removeimg = JSON.parse(this.issue.attachmentnew)
                  if(removeimg){
                      var remove = removeimg.data.splice(index,1)
                      if(remove.length > 0)
                      this.issue.attachmentnew = JSON.stringify({data:removeimg.data})
                      else {
                      this.issue.attachmentnew = null
                    }
                  }
              }   
              if(this.file.length < 1) {
                  this.issue.disblesavebtn = false
              }      
          },
          removes3file(key,data) {
              if(data.length>0) {                
                  Swal.fire({
                      title: 'Are you sure?',
                      text: "You won't be able to undo!",
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!'
                      }).then((result) => {
                      if (result.isConfirmed) {
                          if(this.editRowData.issueid !== undefined) {
                              let deletefile = data[key];
                              data.splice(key, 1);
                              const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                              let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                              axios({
                              method: "POST",
                              url: 'api/issue/s3deleteissue',
                              data: {
                                  issueid:this.editRowData.issueid,
                                  attachment:payload,
                                  deletefile: JSON.stringify(deletefile) ,
                                  useremail: this.undt.username,
                                  empcode: this.undt.userid,
                              },
                              'headers':{'authorization':this.tokendata}
                          }).then((result) => {
                              if (result.data.status) {
                                  Swal.fire(
                                  'Deleted!',
                                  result.data.msg,
                                  'success'
                                  )
                              } else {
                              Swal.fire(
                                  'Deleted!',
                                  result.data.msg,
                                  'error'
                                  )                        
                              }
                          });
                          } else {
                              Swal.fire(
                                  'IssueId!',
                                  'Issueid should not blank',
                                  'info'
                                  )
                          }    
                      }
                  }).catch(e => {
        this.displayError(e)
        })
              }
          },
          mydetailscollapse() {
              this.isDetActive = true
          },
          myrequestcollapse() {
              this.isRequestActive = !this.isRequestActive
              this.isedit = false
              this.issue.iseditstate = false
              this.issue.issuename = null
              this.issue.project = null
              this.issue.functionality = null
              this.issue.type = null
              this.issue.scenario = ''
              this.issue.sevairty = null
              this.issue.priority = null
              this.issue.allotedto = null
              this.issue.reasonofissue = null
              this.issue.impactofissue = null
              this.issue.status = null
              this.issue.duedate = null
          },
          mysearchrequestollapse:function(){
              this.isSearchRequestActive=!this.isSearchRequestActive
          },
          handleCancel() {
             this.$router.push({ name: 'IssueList', params: { search: true }})     
          },
          redirecttourl:function(btnstate,rowdata){
              rowdata.backbtnstate=btnstate
              this.$router.push({ 
                  name: 'SectionModule',
                  path: '/sectionmodule',
                  params: {
                      backbtnstate:btnstate,
                      clickeddata:rowdata
                  }
              })
          },
          clickCallback: function(pageNum) {
              this.pageoffset=pageNum
              this.currPageNum = pageNum - 1
              let arrGlbMdl=this.glbMdl.split('##')
              let inputEle='';
              let btnEle='';
              if(arrGlbMdl.length > 0){
                  if(typeof arrGlbMdl[0] != 'undefined'){
                      inputEle=arrGlbMdl[0];
                  }
                  if(typeof arrGlbMdl[1] != 'undefined'){
                      btnEle=arrGlbMdl[1];
                  }
              }
              this.getissuelist()
          },
          savewizard: function(mdl,objThis){           
              this.valid = this.validateForm(mdl,objThis);
              if (this.valid) {
                  this.isLoading = true;
                  let tblNm='t42'
                  if(mdl) tblNm=mdl
                  this.input = {
                      tbl: tblNm,
                      section: 'issue',
                      createdby: this.undt.username,
                      empid: this.undt.userid,
                      useremail: this.undt.username,
                      empcode: this.undt.userid,
                  }
                  let apiURL='api/issue/create'
                  this.input.issuename=this.issue.issuename
                  this.input.project=this.issue.project
                  this.input.functionality=this.issue.functionality
                  this.input.task = this.issue.task
                  this.input.type=this.issue.type
                  this.input.scenario=this.issue.scenario
                  this.input.attachment=this.issue.attachmentnew
                  this.input.sevairty=this.issue.sevairty
                  this.input.priority=this.issue.priority
                  this.input.allotedto=this.issue.allotedto
                  this.input.reasonofissue=this.issue.reasonofissue
                  this.input.impactofissue=this.issue.impactofissue
                  this.input.status=this.issue.status
                  this.input.duedate = this.issue.duedate
                  this.input.subscribers = this.issue.subscribers
                  if(this.issue.businesstaskid){
                      let code = this.issue.businesscodelist.filter(i => i.businesstaskmasterid == this.issue.businesstaskid)
                      this.input.businesstaskcode = (code[0].id)
                      this.input.businesstaskid=this.issue.businesstaskid
                  }
                  if(this.issue.businesstaskcode){
                      let code = this.issue.businesstasklist.filter(i => i.businesstaskcode == this.issue.businesstaskcode)
                      this.input.businesstaskid = (code[0].id)
                      this.input.businesstaskcode=this.issue.businesstaskcode
                  }
                  this.input.moduletype='issue'
                  this.input.module_code = this.editRowData.issuecode
                  if(this.issue.iseditstate == true){
                      apiURL='api/master/commonmaster/update'
                      if(tblNm == 't42'){
                          this.input.issueid=this.editRowData.issueid
                          this.input.useremail= this.undt.username,
                          this.input.empcode= this.undt.userid,
                          apiURL='api/issue/update'
                      }
                      if(this.issue.attachmentnew && this.issue.attachment !== null) {
                        let jsonobj1 = JSON.parse(this.issue.attachmentnew);
                        let jsonobj2 = this.issue.attachment;
                        let arraymerge = [...jsonobj1.data,...jsonobj2.data]
                        this.input.attachment= JSON.stringify({data: arraymerge});
                      }
                      this.input.lastmodifiedby=this.undt.username
                      delete this.input.createdby;
                  }
                  if(this.isedit) {
                      this.isedit = false
                      apiURL='api/master/commonmaster/update'
                      if(tblNm == 't42'){
                          this.input.issueid=this.editRowData.issueid
                          apiURL='api/issue/update'
                      }
                      this.input.lastmodifiedby=this.undt.username
                      delete this.input.createdby;
                  }
                  this.input.useremail= this.undt.username,
                  this.input.empcode= this.undt.userid,
                  this.isAddState=false
                  axios({
                  'method': 'POST',
                  'url': apiURL,
                  'data': this.input,
                  'headers':{'authorization':this.tokendata}
                  })
                  .then(result => {    
                      this.isLoading = false;   
                      if(result.data.errorCode == 0){
                        this.isLoading=false;
                        this.file =[]
                          this.issue.filedescp =[]
                        Swal.fire({
                          title: "Success!",
                          text: result.data.msg,
                          icon: 'success',
                          customClass: {
                              confirmButton: "btn btn-primary"
                          },
                          buttonsStyling: !1
                        })
                        this.$router.push({ name: 'IssueList', params: { search: true }})
                      } else if(result.data.errorCode == 3){
                          Swal.fire({
                          title: "Session Expired...!!",
                          text: result.data.msg,
                          icon: 'info',
                          customClass: {
                              confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: !1,
                          }).then(function() {
                          window.location.href = "/#/login";
                          })
                      } else {
                          Swal.fire({
                              title: "",
                              text: result.data.msg,
                              icon: "info",
                              customClass: {
                              confirmButton: "btn btn-primary",
                              },
                              buttonsStyling: !1,
                          })
                      }
                  }).catch(e => {
        this.displayError(e)
        })
              }
          },
          projectChange: function (node, instanceId){
              if(node && node.length>1){
                  this.isLoading = true;
                  let url = "api/listing/getProjectAllocatedToEmp";
                  this.input = {
                  projectname:node.toLowerCase(),
                  useremail: this.undt.username,
                  empid: this.undt.userid,
                  empcode: this.undt.userid,
                  moduletype: 'issue'
                  };
                  axios({
                  method: "POST",
                  url: url,
                  data: this.input,
                  'headers':{'authorization':this.tokendata}
                  }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                      this.projectnameList = result.data.data;
                  } else {
                      this.projectnameList = [];
                  }
                  }).catch(e => {
        this.displayError(e)
        })
              } 
          },
          projectChangeSearch: function (node, instanceId){
              if(node && node.length>1){
                  this.isLoading = true;
                  let url = "api/listing/getProjectAllocatedToEmp";
                  this.input = {
                      projectname:node.toLowerCase(),
                      useremail: this.undt.username,
                      empid: this.undt.userid,
                      empcode: this.undt.userid,
                      moduletype: 'issue'
                  };
                  axios({
                      method: "POST",
                      url: url,
                      data: this.input,
                      'headers':{'authorization':this.tokendata}
                  }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                      this.search.projectnameList = result.data.data;
                  } else {
                      this.search.projectnameList = [];
                  }
                  }).catch(e => {
        this.displayError(e)
        })
              } 
          },
          issueChange: function (node, instanceId){
              if(node && node.length>1){
                  this.isLoading = true;
                  let url = "api/searching/getissuesnamelist";
                  this.input = {
                  issuename:node.toLowerCase(),
                  useremail: this.undt.username,
                  empcode: this.undt.userid,
                  };
                  axios({
                  method: "POST",
                  url: url,
                  data: this.input,
                  'headers':{'authorization':this.tokendata}
                  }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                      this.issuenameList = result.data.data;
                  } else {
                      this.issuenameList = [];
                  }
                  }).catch(e => {
        this.displayError(e)
        })
              } 
          },
  
          resetRecords: function() {
              this.search.searchprojectname = null;
              this.search.functionality = null;
              this.search.task= null;
              this.search.issuename= null;
              this.search.type = null;
              this.search.sevairty = null;
              this.search.priority = null;
              this.search.status = null;
              this.search.allotedto = null;
              this.getissuelist();
          },
          validText : function(inputval) {
              var re = /[A-Za-z0-9].{4,}/;
              if(inputval){
                  return re.test(inputval.toLowerCase());
              }
          },
          getissuelist: function(){
              let setmoduleform='issuemaster'
              let glbMdlLblval='Issue Master'
              this.companymasterlist = []
              let tblNm='t42'
              this.glbMdl=tblNm+'##'+setmoduleform
              this.glbst=tblNm
              if(setmoduleform){
                  this.glbMdlLbl = glbMdlLblval;
              }
              var apiURL = 'api/issue/getissuelist';
              this.input = {
                  empid:this.undt.userid,
                  useremail: this.undt.username,
                  offset:this.pageoffset,
                  limit:apiUrl.LIMIT,
                  projectname: this.search.searchprojectname,
                  functionality: this.search.functionality,
                  taskid: this.search.task,
                  issuename: this.search.issuename,
                  typename:this.search.type,
                  sevairty: this.search.sevairty,
                  priority: this.search.priority,
                  status: this.search.status,
                  allotedto: this.search.allotedto,
                  empcode: this.undt.userid,
              }
              
              axios({
              'method': 'POST',
              'url': apiURL,
              'data': this.input,
              'headers':{'authorization':this.tokendata}
              })
              .then(result => { 
                  this.isLoading= false;         
                  this.showproject=true;   
                  if(result.data.errorCode == 0){
                      this.isDetActive=true;
                      this.companymasterlist=result.data.data.rows;
                      this.totalcount = result.data.data.count
                      this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                  } else if(result.data.errorCode == 3){
                      Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                      }).then(function() {
                      window.location.href = "/#/login";
                      })
                  }else{
                      this.isDetActive=true;               
                      this.companymasterlist=[];
                      this.totalcount = 0
                      this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                  }
              }).catch(e => {
        this.displayError(e)
        })
          },
          selbtmid(state, value) {
          if(!state) {
              this.errors.issue.businesstaskid = "Business task name required"
              this.issue.businesstaskcode = null
              this.isBtmFieldDisabled = false
          } else {
              this.errors.issue.businesstaskid = ""
              if(this.issue.businesstaskid){
                  let code = this.issue.businesscodelist.filter(i => i.businesstaskmasterid == this.issue.businesstaskid)
                  if(code.length>0){
                  this.issue.businesstaskcode = code[0].id
                  }
                  this.isBtmFieldDisable = true
                  this.isBtmFieldDisabled = false
              }
          }
          },
          selbtmcode(state, value) {
              if(!state) {
                  this.errors.issue.businesstaskcode = "Business task code required"
                  this.issue.businesstaskid = null
                  this.isBtmFieldDisable = false
              } else {
                  this.errors.issue.businesstaskcode = ""
                  if(this.issue.businesstaskcode){
                  let code = this.issue.businesstasklist.filter(i => i.businesstaskcode == this.issue.businesstaskcode)
                  if(code.length>0){
                  this.issue.businesstaskid = code[0].id
                  }
                  this.isBtmFieldDisable = false
                  this.isBtmFieldDisabled = true
              }
              }
          },
          validateForm: function(mdl,objThis){
              this.isAddState=false
              var isValid=true;
              if(mdl=="t42"){
                  if(!this.issue.issuename){
                      this.errors.issue.issuename="Issue name required";
                      isValid= false;
                  }
                  if(!this.issue.project){
                      this.errors.issue.project="Project required";
                      isValid= false;
                  } else {
                      this.errors.issue.project = null;
                  }
                  if(!this.issue.businesstaskid && !this.issue.businesstaskcode && (this.issue.type != 9 && this.issue.type != 18)){
                      this.errors.issue.businesstaskid="Business task name is required";
                      isValid= false;
                  } else {
                      this.errors.issue.businesstaskid = null;
                  }
                  if(!this.issue.businesstaskcode && !this.issue.businesstaskid && (this.issue.type != 9 && this.issue.type != 18)){
                      this.errors.issue.businesstaskcode="Business task code is required";
                      isValid= false;
                  }else {
                      this.errors.issue.businesstaskcode = null;
                  }
                  if(!this.issue.type){
                      this.errors.issue.type="Issue type required";
                      isValid= false;
                  }
                  if(!this.issue.scenario){
                      this.errors.issue.scenario="Issue scenario required";
                      isValid= false;
                  } else {
                      this.errors.issue.scenario="";
                  }
                  if(!this.issue.allotedto){
                      this.errors.issue.allotedto="Assigned to required";
                      isValid= false;
                  } else {
                      this.errors.issue.allotedto = "";
                  }
                  if(!this.issue.sevairty){
                      this.errors.issue.sevairty="Issue severity required";
                      isValid= false;
                  }
                  if(!this.issue.priority){
                      this.errors.issue.priority="Issue priority required";
                      isValid= false;
                  }
                  if(!this.issue.duedate && this.editRowData.vendorcode){
                      this.errors.issue.duedate="Due Date required";
                      isValid= false;
                  }else{
                      this.errors.issue.duedate = null
                  }
                  if(!this.issue.status){
                      this.errors.issue.status="Issue status required";
                      isValid= false;
                  }
                  if((!this.issue.subscribers) || (this.issue.subscribers && this.issue.subscribers.length < 1)) {
                      this.errors.issue.subscribers = 'Subscribers are required'
                      isValid = false
                  } else {
                      this.errors.issue.subscribers = ''
                  }
              }
              return isValid
          },
          sortTypeList(typelist, type){
            let numArrFromTypelist = [];
            typelist.forEach(element => {
                if(element.id != null) {
                    numArrFromTypelist.push(element.id);
                }
            });
            if(numArrFromTypelist.length>0) {
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    moduletype: 'issue',
                    typearr: numArrFromTypelist,
                    projecttype: type
                };
                axios({
                    method: "POST",
                    url: 'api/ticket/sortTypeList',
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.issue.typelist = result.data.data.rows;
                        this.issue.typelist.sort((a, b) => {
                      return a.label.localeCompare(b.label);
                  });
                    } else {
                        this.issue.typelist = [];
                    }
                }).catch(e => {
        this.displayError(e)
        })
            }
        },
          getTasktypeConfigLists(projectid) {
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: parseInt(projectid.toString()),
                trackername: 'Issue Type',
            };
            axios({
                method: "POST",
                url: '/api/businesstask/getTasktypeConfigList',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    let slist = [];
                    result.data.data.rows.forEach((rec) => {
                        if(Object.values(rec.statusconfig).length>0){
                            Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                                if(statusnames.length>0) {
                                    statusnames.forEach(val => {
                                        if(!slist.some(sdata => sdata.id === val)){
                                            slist.push({ id: val, label: val });
                                        }
                                    });
                                }
                            });
                        }
                    })
                    this.sortTypeList(slist, 'alltype')
                } else {
                    this.issue.typelist = [];
                }
            }).catch(e => {
        this.displayError(e)
        })
          },
          openEditModule: function(currRowData, flag){
              this.editRowData = currRowData
              this.view.projectname = currRowData.projectname
              this.view.functionlityname = currRowData.functionlityname
              this.view.typename = currRowData.typename
              this.view.sevairtyname = currRowData.sevairtyname
              this.view.issuecode = currRowData.issuecode
              this.issue.issuename = currRowData.issuename
              this.issue.project = currRowData.project
              this.issue.functionality = currRowData.functionality
              this.issue.task = currRowData.taskid
              this.issue.type = currRowData.issuetype
              this.issue.scenario = currRowData.scenario
              this.issue.attachment = currRowData.attachment
              this.issue.businesstaskid = currRowData.businesstaskid
              if(this.issue.businesstaskid){
              this.isBtmFieldDisabled = false
                  if(this.issue.businesstaskcode){
                      this.isBtmFieldDisable = true
                  }
              }
              this.issue.businesstaskcode = currRowData.businesstaskcode
              if(this.issue.businesstaskcode){
              this.isBtmFieldDisable = false
                  if(this.issue.businesstaskid){
                      this.isBtmFieldDisabled = true
                  }
              }
              if(currRowData.attachment && currRowData.attachment.hasOwnProperty('data')) {
                  this.issue.attachmentview =[];
                  this.getDownloadLink(currRowData.attachment);
              }
              this.issue.sevairty = currRowData.sevairty
              this.issue.duedate  = currRowData.duedate
              this.issue.priority = currRowData.priority
              this.issue.allotedto = currRowData.allotedto;
              this.getEmployeeDetailsbyID(currRowData.allotedto);
              this.issue.reasonofissue = currRowData.reasonofissue
              this.issue.impactofissue = currRowData.impactofissue
              this.issue.status = currRowData.status
              let currStatus = currRowData.status ? currRowData.status : 'open';
              this.getStatusConfigList(this.issue.project, currStatus);
              this.issue.iseditstate = true
              this.issue.moduletype = currRowData.moduletype
              this.isAddState=true
              if(flag==0){
                  this.isedit=true
                  this.isRequestActive=true
              }
              if(this.issue.projectlist && this.issue.projectlist.length > 0 && (this.issue.project && this.issue.project !=null)){
                const checkexist = this.issue.projectlist.some(item =>item.id === this.issue.project);
                if(!checkexist){
                    this.getProjectnamebyid(this.issue.project);
                }
            }else if(this.issue.projectlist.length ==0 && (this.issue.project && this.issue.project !=null)){
                this.getProjectnamebyid(this.issue.project);
            }
              this.getTasktypeConfigLists(this.issue.project);
          },
          dateFormat(value) {
              if (!value) return "";
              return moment(String(value)).format('DD-MM-YYYY');
          },
          getDownloadLink(data) {
              if(data.data.length>0) {
                  let payload = {
                  useremail: this.undt.username,
                  empcode: this.undt.userid,
                  documents:data.data.map(i => {
                      return {...i, useremail: this.undt.username, empcode: this.undt.userid}
                  }),
              };
              axios({
                  method: "POST",
                  url: '/api/imageupload/getdownloadlink',
                  data: payload,
                  'headers':{'authorization':this.tokendata}
              })
              .then((result) => {
                  if(result.data.status) {
                      this.issue.attachmentview = result.data;
                  }
                  }).catch(e => {
        this.displayError(e)
        })
              }
          },
          addmodule: function(){
              this.issue.issuename = null
              this.issue.project = null
              this.issue.functionality = null
              this.issue.type = null
              this.issue.scenario = ''
              this.issue.attachment = null
              this.issue.attachmentview = null
              this.issue.sevairty = null
              this.issue.priority = null
              this.issue.attachmentnew= null
              this.issue.allotedto = null
              this.issue.reasonofissue = null
              this.issue.impactofissue = null
              this.issue.status = null
              this.issue.iseditstate = false
              this.issue.disblesavebtn=true
              this.issue.duedate = null
          },
          nullifybusinessfields: function(){
              this.issue.businesstaskid = null
              this.issue.businesstaskcode = null
          },
          selissuecsproject: function(state,value){
              this.issue.project=null
              this.issue.project=state
              this.issue.subscribers = null
              if(!state){
                  this.issue.functionality = null
                  this.issue.task = null
                  this.issue.tasklist = null
                  this.issue.allotedto = null
                  this.issue.allotedtolist = []
                  this.issue.subscribers = null
                  this.errors.issue.project='Project required'
                  this.issue.functionalitylist=null
                  this.issue.businesstaskid = null
                  this.issue.businesstaskcode = null
                  this.issue.statuslist = [];
              }else{
                  this.errors.issue.project=null
                //   this.getEmpallocatedtoProject(state, 'issue')
                  this.getApplicationdata('t37','issue')
                  this.getbusinesstaskname(state, 'issue')
                  this.getbusinesstaskcode(state, 'issue')
                  let currStatus = this.issue.status ? this.issue.status : 'open';
                  this.getStatusConfigList(state, currStatus);
                  this.getTasktypeConfigLists(state);
                  if (state != this.editRowData.projectid) {
                    this.issue.type = null     
                  }
              }
          },
          getStatusConfigList(pid, status) {
              let payload = {
                  empcode: this.undt.userid,
                  useremail: this.undt.username,
                  projectid: pid,
                  trackername: 'Issue',
                  currStatus: status,
              };
              axios({
                  method: "POST",
                  url: '/api/testcase/getStatusConfigList',
                  data: payload,
                  'headers':{'authorization':this.tokendata}
              }).then((result) => {
                  if(result.data.errorCode == 0){
                      this.issue.statuslist = result.data.data.rows;
                  } else {
                      this.issue.statuslist = [];
                  }
              }).catch(e => {
        this.displayError(e)
        })
          },
          selSubscribers(state) {
              this.issue.subscribers = null
              this.issue.subscribers = state
              if(!state || (state && state.length < 1)) {
                  this.errors.issue.subscribers = 'Subscribers are required'
              } else {
                  this.errors.issue.subscribers = ''
              }
          },
          selissuecsfunctionality: function(state,value){
              this.issue.functionality=null
              this.issue.functionality=state
  
              if(!state){
                  this.issue.task = null
                  this.issue.tasklist = null
              }else{                
                  this.getApplicationdata('t18', 'issue')
              }
          },
          selissuecsprojectSearch: function(state,value){
              this.search.searchprojectname=null
              this.search.searchprojectname=state
              if(!state){
                  this.search.functionality = null 
                  this.search.task = null
                  this.search.allotedto = null
              }else{
                //   this.getEmpallocatedtoProject(state, 'issueSearch')
                  this.getApplicationdata('t37','issueSearch')
              }
          },
          selissuecsfunctionalitySearch: function(state,value){
              this.search.functionality=null
              this.search.functionality=state
              if(!state){
                  this.search.task = null
                  this.search.tasklist = null
              }else{                
                  this.getApplicationdata('t18', 'issueSearch')
              }
          },
          selissuecstype: function(state,value){
              this.issue.type=null
              this.issue.type=state
              if(state == 9 || state == 18){
                  this.errors.issue.businesstaskcode = null
                  this.errors.issue.businesstaskid = null
                  this.errors.issue.type=null
              }else{
                  if(!state && this.isAddState == false){
                      this.errors.issue.type='Type required'
                  }else{                
                      this.errors.issue.type=null
                  }
              }    
          },
          selissuecsscenario: function(state,value){
              this.issue.scenario=''
              this.issue.scenario=state
              if(!state && this.isAddState == false){
                  this.errors.issue.scenario='Scenario required'
              }else{                
                  this.errors.issue.scenario=''
              }
          },
          selissuecssevairty: function(state,value){
              this.issue.sevairty=null
              this.issue.sevairty=state
              if(!state && this.isAddState == false){
                  this.errors.issue.sevairty='Sevairty required'
              }else{                
                  this.errors.issue.sevairty=null
              }
          },
          selissuecspriority: function(state,value){
              this.issue.priority=null
              this.issue.priority=state
              if(!state && this.isAddState == false){
                  this.errors.issue.priority='Priority required'
              }else{                
                  this.errors.issue.priority=null
              }
          },
          selissuecsstatus: function(state,value){
              this.issue.status=null
              this.issue.status=state
              if(!state && this.isAddState == false){
                  this.errors.issue.status='Status required'
              }else{                
                  this.errors.issue.status=null
              }
          },
          selallotedto: function(state,value){
              this.issue.allotedto=null
              this.issue.allotedto=state
              if(!state && this.isAddState == false){
                  this.errors.issue.allotedto='Assigned To required'
              }else{                
                  this.errors.issue.allotedto=null
              }
          },
          getProjectAllocatedToEmp() {
              this.isLoading = true
              let url = "api/listing/getProjectAllocatedToEmp";
              this.input = {
                  empid: parseInt(this.undt.userid),
                  useremail: this.undt.username,
                  empcode: this.undt.userid,
                  moduletype: 'issue',
                  isfrompage:'updatepage'
              };
              axios({
                  method: "POST",
                  url: url,
                  data: this.input,
                  'headers': { 'authorization': this.tokendata }
              }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                      this.issue.projectlist = result.data.data;
                      if(this.issue.projectlist && this.issue.projectlist.length > 0 && (this.issue.project && this.issue.project !=null)){
                        const checkexistproject = this.issue.projectlist.some(item =>item.id === this.issue.project);
                        if(!checkexistproject){
                            this.getProjectnamebyid(this.issue.project);
                        }
                    }else if(this.issue.projectlist.length ==0 && (this.issue.project && this.issue.project !=null)){
                        this.getProjectnamebyid(this.issue.project);
                    }
                  } else {
                      this.issue.projectlist = [];
                  }
              }).catch(e => {
                this.displayError(e)
            })
          },

        getProjectnamebyid(pid){
            this.isLoading = true;
            this.apiURL="api/searching/getAssignedProjectList"
            this.input={
                useremail: this.undt.username,
                empid :this.undt.userid,
                empcode: this.undt.userid,
                projectid: pid,
                isfrompage: 'isprojectid'
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading = false;         
                if(result.data.errorCode == 0){
                    if(result.data.data && result.data.data.length > 0){
                        this.issue.projectlist.push(result.data.data[0]);
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
          
          getApplicationdata: function(mdl,curracttab){            
              this.pageoffset=1
              this.inputappl = {
                  tbl:mdl,
                  offset:this.pageoffset,
                  limit:apiUrl.LIMIT,
                  useremail: this.undt.username,
                  empcode: this.undt.userid,
              }
              let apiURL='api/master/commonmaster/list'
              if(mdl == 't11'){
                  apiURL='api/listing/getprojectlist'
                  this.inputappl = {
                      tbl:mdl
                  }
              }else if(mdl == 't37' && curracttab == 'issue'){
                  this.issue.functionality = null
                  this.issue.functionalitylist = []
                  this.inputappl = {};
                  apiURL = "api/listing/getfunctionalitybyprojectid";
                  this.inputappl.projectid = this.issue.project
              }else if(mdl == 't37' && curracttab == 'issueSearch'){
                  this.inputappl = {};
                  apiURL = "api/listing/getfunctionalitybyprojectid";
                  this.inputappl.projectid = this.search.searchprojectname
              }else if(mdl == 't43'){
                  apiURL='api/master/commonmaster/customselect'
                  this.inputappl.isSearch= false
                  this.inputappl.section= "issue"
                  this.inputappl.isFilterSelect= 1
                  this.inputappl.isFilterPayload= {}
              }else if(mdl == 't44'){
                  apiURL='api/master/commonmaster/customselect'
                  this.inputappl.isSearch= false
                  this.inputappl.section= "issue"
                  this.inputappl.isFilterSelect= 1
                  this.inputappl.isFilterPayload= {}
              }else if(mdl == 't26'){
                  apiURL='api/master/commonmaster/getModuleDropdownOption'
                  this.inputappl = {
                      tbl: "t26"
                  }
              } else if (mdl == 't18' && curracttab == 'issue') {
                  this.issue.task = null
                  this.issue.tasklist = []
                  apiURL = 'api/master/commonmaster/getModuleDropdownOption'
                  this.inputappl = {
                      tbl: mdl,
                      useremail: this.undt.username,
                      functionalityid: this.issue.functionality
                  }
              } else if (mdl == 't18' && curracttab == 'issueSearch') {
                  apiURL = 'api/master/commonmaster/getModuleDropdownOption'
                  this.inputappl = {
                      tbl: mdl,
                      useremail: this.undt.username,
                      functionalityid: this.search.functionality
                  }
              }
              this.inputappl.useremail= this.undt.username
              this.inputappl.empcode= this.undt.userid
              axios({
              'method': 'POST',
              'url': apiURL,
              'data': this.inputappl,
              'headers':{'authorization':this.tokendata}
              })
              .then(result => {             
                  if(result.data.errorCode == 0){
                      if(mdl == 't11'){
                          if(curracttab == 'issue'){
                              return true
                          }
                      }else if(mdl == 't37' && curracttab == 'issue'){
                          this.issue.functionalitylist=result.data.data
                          this.issue.functionalitylist.sort((a, b) => {
                            return a.label.localeCompare(b.label);
                            });
                          if(this.issue.iseditstate) {
                              this.issue.functionality = this.editRowData.functionality    
                          } else {
                              this.issue.functionality = null
                              this.issue.task = null
                          }
                          return true
                      }else if(mdl == 't37' && curracttab == 'issueSearch'){
                          this.search.functionalitylist=result.data.data
                          this.search.functionalitylist.sort((a, b) => {
                            return a.label.localeCompare(b.label);
                            });
                          return true
                      }else if(mdl == 't43'){
                          if(curracttab == 'issue'){
                              //this.issue.typelist=result.data.data.rows
                              return true
                          }
                      }else if(mdl == 't44'){
                          if(curracttab == 'issue'){
                              this.issue.sevairtylist=result.data.data.rows
                              return true
                          }
                      }else if(mdl == 't26'){
                          if(curracttab == 'issue'){
                              return true
                          }
                      } else if(mdl == 't18' && curracttab == 'issue') {
                          this.issue.tasklist = result.data.data
                          if(this.issue.iseditstate) {
                              this.issue.task = this.editRowData.taskid
                          } else {
                              this.issue.task = null
                          }
                          return true
                      } else if(mdl == 't18' && curracttab == 'issueSearch') {
                          this.search.tasklist = result.data.data
                          return true
                      }
                  }
                  else if(result.data.errorCode == 3){
                      Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                      }).then(function() {
                          window.location.href = "/#/login";
                      })
                  } else {
                      if(mdl == 't37' && curracttab == 'issue'){
                          this.issue.functionalitylist=[]
                          return true
                      } else if(mdl == 't18' && curracttab == 'issue'){
                          this.issue.tasklist = []
                          return true
                      }
                  }
              }).catch(e => {
        this.displayError(e)
        })
          },
          onSelect: function(e){
              this.file = e.target.files[0];
          },
          onUpload(){
            if(this.file){
              this.isLoading=true
              if(this.file.length !== 0){
                  let arrGlbMdl=this.glbMdl.split('##')
                  let inputEle='';
                  let btnEle='';
                  if(arrGlbMdl.length > 0){
                      if(typeof arrGlbMdl[0] != 'undefined'){
                          inputEle=arrGlbMdl[0];
                      }
                      if(typeof arrGlbMdl[1] != 'undefined'){
                          btnEle=arrGlbMdl[1];
                      }
                  }
                  if(inputEle == 't42'){
                      btnEle='issuemgt'
                  }
                  this.apiURL="api/master/fileupload/uploadBulkFileToS3"
                  let formData = new FormData();                
                  let maxsize = 0;
                  for (let index = 0; index < this.file.length; index++) {
                      formData.append("imagefile", this.file[index]);
                      maxsize+= this.file[index].size
                  }
  
                  formData.append("empcode", this.undt.userid);
                  formData.append("useremail",this.undt.username);
                  formData.append("moduletype", 'issuemgt');
                  formData.append("fcount", parseInt(this.file.length));
                                    
                  if (maxsize > 1000 * 1000 * 25) {
                      this.isLoading = false;
                      Swal.fire({
                          title: "Failed",
                          text: "Your document uploaded above 25mb not allow",
                          icon: "info",
                          customClass: {
                          confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: !1,
                      })
                      this.isLoading=false
                     this.issue.disblesavebtn=true
                      return false; 
                  }
                  axios({
                      'content-type':'multipart/form-data',
                      'method': 'POST',
                      'url': this.apiURL,
                      'data':formData,
                      'headers':{'authorization':this.tokendata}
                  })
                  .then(result => { 
                      this.isLoading=false; 
                      this.issue.disblesavebtn=false           
                      if(result.data.status){
                          const bindinfo = result.data.data.map((file, index) => {
                              return {"link":file,"info":this.issue.filedescp[index]}                
                          });
                          let jsonobj = {data:bindinfo}
                          this.issue.attachmentnew = JSON.stringify(jsonobj);
                          Swal.fire({
                              title: "Success!",
                              text: result.data.msg,
                              icon: 'success',
                              customClass: {
                                  confirmButton: "btn btn-primary"
                              },
                              buttonsStyling: !1
                          })
                      } else {
                          Swal.fire({
                              title: "",
                              text: result.data.msg,
                              icon: 'info',
                              customClass: {
                                  confirmButton: "btn btn-primary"
                              },
                              buttonsStyling: !1
                          })
                          this.issue.attachment=null
                      }
                  }).catch(e => {
        this.displayError(e)
        })
              }else{
                    this.isLoading=false; 
                  Swal.fire({
                      title: "",
                      text: 'Select image/docs/videos',
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                  })
                  this.issue.attachment=null
              }
            } else {
                this.isLoading=false; 
                Swal.fire({
                    title: "",
                    text: 'Select image/docs/videos',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                this.issue.attachment=null
            }
          }
      },
      filters: {
        basename: function (value) {
            if (!value) return ''
            return value.substr(value.lastIndexOf("/") + 1)
        }
      }
  }
  </script>
  