<template>
    <div class="app-content content ">
        <loading  :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper mt-1">
            <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5">
                <div class="row breadcrumbs-top">
                    <div class="col-9 px-0">
                        <h4 class="content-header-title float-left mb-0" style="padding: 5px 5px 0px 0px;">Test Cycle Set</h4>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">Test Execution</li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">Test Cycle Set's</li>
                            </ol>
                        </div>
                    </div>
                    <div class="col-3 ml-auto px-0 text-right mobile_margin-px-0_00" >
                        <div class="input-group-append float-right" v-if="pagefeatures.createcycleset">
                            <router-link to="/testexecution/testcyclesetadd" >
                                <button id="commonaddmodule" class="btn btn-sm btn-relief-success " data-toggle="modal" aria-haspopup="true" aria-expanded="false" type="button" data-backdrop="static" data-keyboard="false"> 
                                
                                <span class="mobile_hide_create_text">Create Test Cycle Set</span> <plus-circle-icon size="1.5x" class="custom-class mr-1"></plus-circle-icon></button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                    <div class="container-fluid">
                        <div class="row row fc_my-05">
                            <div class="w-auto px-1 mobile-padding-0-2">
                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                    <div class="avatar-content">
                                        <search-icon
                                            size="1.5x"
                                            class="custom-class avatar-icon font-medium-3"
                                        ></search-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex ml-1">Search Test Cycle Set</h4>
                            </div>
                            <span class="rotetions_view_arrow ml-auto mx-1"> 
                                <em class="las la-angle-down"></em>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
            <div class="card-body pt-1">
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                        <label class="form-label lable-left">Test Cycle Code</label>
                            <input type="text" class="form-control" placeholder="Enter Test Cycle Code" v-model="search.testCycleCode" />
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Test Cycle Name</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter Min 5 Char Of Test Cycle Name" :multiple="true" v-model="search.testCycleName" v-on:search-change="gettestcyclenamelist" :options="search.testcyclenamelist" :clear-on-select="true"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Business Task Code </label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter Min 8 Char Of Business Task Code" :multiple="true" v-model="search.businesscode" @input="clearCodeList" v-on:search-change="getbtccodelist" :options="search.btccodelist" :clear-on-select="true"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Business Task Name</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter Min 5 Char Of Business Task Name" :multiple="true" v-model="search.tcname"  @input="clearCodeList" v-on:search-change="getTCGlist" :options="search.tcnamelist" :clear-on-select="true"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Status</label>
                    <treeselect class="projectmdllistdd" placeholder="Select Status" :options="status" v-model="search.statuses" :multiple="true" :clear-on-select="true"/>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1 text-right">
                        <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getlist(search,1)"> 
                        <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                        
                        <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">

                        <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                        <button type="move" class="btn btn-relief-primary mt-2"  @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                    </div>
                </div>
            </div>
        </div>
            </div>
            <section id="card-navigation">
                <div class="file-manager-content-body">
                    <div class="drives">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="row" id="proModuleList">
                                            <div class="col-12">
                                                <div class="card">
                                                    <div class="table-responsive datatableview">
                                                        <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" class="align-middle srno first-col-sticky" style="min-width:45px;">Sr#</th>
                                                                    <th scope="col" class="align-middle second-col-sticky" style="min-width: 200px;">Test Cycle Details </th>
                                                                    <th scope="col" class="align-middle" style="min-width: 260px;">Business Task Details</th>
                                                                    <th scope="col" class="align-middle text-left" style="min-width: 180px;">Group Name</th>
                                                                    <th scope="col" class="align-middle" style="min-width: 240px;"> Date Details</th>
                                                                    <th scope="col" class="align-middle" style="min-width: 100px;">Status</th>
                                                                    <th scope="col" class="align-middle">Created Details</th>
                                                                    <th scope="col" class="align-middle" style="min-width: 110px;">Total Scripts</th>
                                                                    <th scope="col" class="align-middle clsaction">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <template v-for="(data,index) in cyclelist" >        
                                                                    <tr v-bind:key="index">
                                                                        <td data-column="Sr#" class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                        <td data-column="Test Cycle Details" class="second-col-sticky">
                                                                                <span class="release_hr_tag_span"> Code :</span>
                                                                                <span class="font-weight-bold sitebluecolor"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer;">{{data.cyclesetcode}}</span>
                                                                                <hr class="release_hr_tag_tab"/>
                                                                                <span class="release_hr_tag_span"> Name :</span>
                                                                                {{data.cyclesetname}}
                                                                        </td>
                                                                        <td data-column="Business Task Details">
                                                                                <span class="release_hr_tag_span"> Code :</span>
                                                                                <span class="font-weight-bold sitebluecolor"  @click="redirecttourl('Bview',data)" href="javascript:void(0);" style="cursor: pointer;">{{data.businesstaskcode}}</span>
                                                                                <hr class="release_hr_tag_tab"/>
                                                                                <span class="release_hr_tag_span"> Name :</span>
                                                                                {{data.taskname}}
                                                                        </td>
                                                                        
                                                                        <td data-column="Group Name" class="test_list_caseulli">
                                                                            <span class="font-weight-bold" v-if="data.mappingname" v-html="showmappingname(data.mappingname)"></span>
                                                                            <span class="font-weight-bold" v-else>NA</span>
                                                                        </td>
                                                                        <td data-column="Date Details">
                                                                            <span class="release_hr_tag_span"> Start Date :</span>
                                                                            {{dateMonthFormat(data.startdate)}}
                                                                            <hr class="release_hr_tag_tab"/>
                                                                            <span class="release_hr_tag_span"> End Date :</span>
                                                                            {{dateMonthFormat(data.enddate)}}
                                                                        </td>
                                                                        <td data-column="Status">{{data.status}}</td>
                                                                        <td data-column="Created Details">
                                                                            <span class="font-weight-bold capitalisetext">
                                                                                <span class="release_hr_tag_span"> Created by :</span>
                                                                                {{data.createdby}}</span>
                                                                                <hr class="release_hr_tag_tab"/>
                                                                                <span class="release_hr_tag_span"> Created Date :</span>
                                                                                {{dateMonthFormat(data.createddate)}}
                                                                        </td>

                                                                        <td data-column="Total Scripts" class="text-center">
                                                                                {{data.nooftestcase}}
                                                                        </td>


                                                                        <td data-column="Actions" class="clsaction" >
                                                                            <div class="dropdown">
                                                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                    <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                </button>
                                                                                <div class="dropdown-menu">
                                                                                    <a class="dropdown-item devEditEle" v-if="data.status == 'Created' || data.status == 'Review In Progress' || data.status == 'Reviewed' || data.status == 'Product Declined'" @click="redirecttourl('edit', data)" style="cursor: pointer;" href="javascript:void(0);">
                                                                                        <div class="d-flex align-items-center">
                                                                                            <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                            <span>Edit</span>
                                                                                        </div>
                                                                                    </a>
                                                                                    <a class="dropdown-item devEditEle" v-else-if="pagefeatures.approvecycleset && (data.finishdate !=null && data.rundate!=null )" @click="redirecttourl('edit', data)" style="cursor: pointer;" href="javascript:void(0);">
                                                                                        <div class="d-flex align-items-center">
                                                                                            <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                            <span>Edit</span>
                                                                                        </div>
                                                                                    </a>
                                                                                    <a @click="redirecttourl('view',data)" href="javascript:void(0);" class="dropdown-item devEditEle">
                                                                                    <div class="d-flex align-items-center"> 
                                                                                        <eye-icon size="1.5x" class="custom-class mr-50" ></eye-icon>
                                                                                        <span>View Details</span>
                                                                                    </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-if="cyclelist.length==0">
                                                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pageCenter text-center">
                                    <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Pagination from 'vue-pagination-2'
import { PlusCircleIcon,PlusSquareIcon,Edit2Icon,MoreVerticalIcon,EyeIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
import { SearchIcon } from "vue-feather-icons";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import commonMethods from '../../utils/commonMethods';

export default {
    name:'TestCycleSetList',
    mixins: [ commonMethods ],
    components:{
        Loading,
        VueElementLoading,
        Pagination,Edit2Icon,
        PlusCircleIcon,PlusSquareIcon,MoreVerticalIcon,EyeIcon,
        SearchIcon,XIcon,ExternalLinkIcon,
        Treeselect
    },
    data(){
        return{
            card_content: "card-content",
            collapse: "collapse",
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            cyclelist:[],
            isSearchRequestActive:false,
            tcnamelist:[],
            tcname:null,
            btccodelist:[],
            businesscode:null,
            testCycleName:null,
            testCycleCodeList: [],
            testCycleCode:null,
            incidentcodelist:null,
            testcyclenamelist:[],
            search:{
                empname: null,
                results: null,
                emplist: [],
                resultlist: [],
                empcode: null,
                testCycleCode:  null,
                testCycleName: null,
                businesscode: null,
                tcname: null,
                statuses: null,
                btccodelist: [],
                tcnamelist:[],
                testcyclenamelist:[],
            },
            loginuseremail: '',
            pagefeatures:[],
            statuses:null,
            status:apiUrl.testcyclestatuslist.sort((a, b) => a.label.localeCompare(b.label)),
            projectid: null
        }
    },
    created() {
        this.pagefeatures = {};
        var pagefeatures1 = JSON.parse(
          window.atob(window.localStorage.getItem("pagefeatures"))
        );
        pagefeatures1.forEach((element) => {
          if (element.page === "/testexecution/testcycleset") {
            this.pagefeatures[element.featurename] = element.featureaccess;
          }
        });
    },
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.loginuseremail = userdata.emailaddress;
                this.undt=userdata
                if(this.$route.params && this.$route.params.fromrediret =='projectsummary'){
                    this.search.statuses = this.$route.params.clickeddata ? [this.$route.params.clickeddata] : [];
                    this.projectid = this.$route.params.pid;
                    this.isSearchRequestActive = true;
                    this.datefrom = this.$route.params.startdatefrom;
                    this.dateto = this.$route.params.startdateto;
                    this.getlist(this.search,1);
                }else if(this.$route.params.search){
                    let search = JSON.parse(localStorage.getItem('cyclesetsearchItems'));
                    if(search){
                        this.getSearchItems(search);
                    }else{
                        localStorage.removeItem('cyclesetsearchItems');
                        this.getlist();
                    }
                }else{
                    localStorage.removeItem('cyclesetsearchItems');
                    this.getlist();
                }
            }
        }
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        getSearchItems(search) {
            this.isSearchRequestActive = true;
            this.gettestcyclenamelists(search.testCycleName);
            this.search.testCycleCode = search.testCycleCode;
            this.search.testcyclenamelist = JSON.parse(localStorage.getItem('testcyclenamelist'));
            this.search.testCycleName = search.testCycleName;
            this.search.btccodelist = JSON.parse(localStorage.getItem('cyclesetbtccodelist'));
            this.search.businesscode = search.businesscode;
            this.search.tcnamelist = JSON.parse(localStorage.getItem('cyclesettcnamelist'));
            this.search.tcname = search.tcname;
            this.search.statuses = search.statuses;
            this.getbtccodelistbyid(this.search.businesscode);
            this.getTCGlistbyid(this.search.tcname);
            this.getlist(this.search)
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getlist();
            setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#myTabContent").offset().top -70
            });
        }, 500);
            
        },
        resetRecords: function() {
            this.search.empcode=null
            this.search.results=null
            this.isBothDateSelected = false
            this.search.testCycleCode=null
            this.search.testCycleName=null
            this.search.businesscode=null
            this.search.tcname=null
            this.search.statuses=null;
            this.projectid = null;
            this.datefrom = null;
            this.dateto = null;
            localStorage.removeItem('cyclesetsearchItems');
            localStorage.removeItem('cyclesetbtccodelist');
            localStorage.removeItem('cyclesettcnamelist');
            localStorage.removeItem('testcyclenamelist');
            this.page=1
            this.getlist();
        },
        clearCodeList(state) {
            if(!state) {
                this.search.businesscode = null
                this.search.tcname = null
            }
        },
        getlist(objThis,flag){
            if(objThis) {
                localStorage.setItem('cyclesetsearchItems', JSON.stringify(objThis))
            }
            this.isLoading = true
            let search = JSON.parse(localStorage.getItem('cyclesetsearchItems'))
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                limit: apiUrl.LIMIT,
                empcode: this.undt.userid,
            }
            if (search) {
                this.input.testCycleCode = this.search.testCycleCode ? this.search.testCycleCode :null;
                this.input.testCycleName = this.search.testCycleName ? this.search.testCycleName :null;
                this.input.businesscode = this.search.businesscode ? this.search.businesscode :null;
                this.input.tcname = this.search.tcname ? this.search.tcname :null;
                this.input.status = this.search.statuses ? this.search.statuses :null;
                this.input.projectid = this.projectid ? this.projectid :null;
            }
            if(this.datefrom && this.dateto){
                this.input.datefrom = this.datefrom ? this.datefrom :null;
                this.input.dateto = this.dateto ? this.dateto :null;
            }
            if(flag == 1){
                this.input.offset = 1;
                this.currPageNum = 0;
            }else{
                this.input.offset = this.pageoffset;
            }
            axios({
                    'method': 'POST',
                    'url': 'api/testcycle/gettestcyclelisting',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false             
                    if(result.data.errorCode == 0){
                        this.cyclelist = result.data.data.rows
                        this.totalcount = result.data.data.count;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        this.cyclelist=[]
                        this.totalcount = 0;
                        this.pageCount =0;
                    }
                }).catch(e => {
    this.displayError(e)
    })

        },
        getTCGlist(node){
            if (node.length>4) {
                this.input={
                    useremail  : this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    taskname: node.toUpperCase(),
                }
                axios({
                    'method': 'POST',
                    'url': 'api/testcycle/getbtcnamelist',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        this.search.tcnamelist = result.data.data.rows;
                        localStorage.setItem('cyclesettcnamelist', JSON.stringify(this.search.tcnamelist));
                    } else {
                        this.search.tcnamelist = [];
                        localStorage.removeItem('cyclesettcnamelist');
                    }
                }).catch(e => {
    this.displayError(e)
    })
            }
        },
        getTCGlistbyid(btcode){
            if (btcode && btcode.length>0) {
                this.input={
                    useremail  : this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    btcode: btcode,
                }
                axios({
                    'method': 'POST',
                    'url': 'api/testcycle/getbtcnamelist',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        this.search.tcnamelist = result.data.data.rows;
                    } else {
                        this.search.tcnamelist = [];
                    }
                }).catch(e => {
    this.displayError(e)
    })
            }
        },
        getbtccodelist(node){
            if (node.length>7) {
                this.input={
                    useremail  : this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    taskid: node.toUpperCase(),
                }
                axios({
                    'method': 'POST',
                    'url': 'api/testcycle/getsearchbtccodelist',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    if(result.data.errorCode == 0){
                        this.search.btccodelist = result.data.data.rows;
                        localStorage.setItem('cyclesetbtccodelist', JSON.stringify(this.search.btccodelist));
                    } else {
                        this.search.btccodelist = [];
                        localStorage.removeItem('cyclesetbtccodelist');
                    }
                }).catch(e => {
    this.displayError(e)
    })
            }
        },
        getbtccodelistbyid(btcode){
            if (btcode && btcode.length>0) {
                this.input={
                    useremail  : this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    btcode: btcode,
                }
                axios({
                    'method': 'POST',
                    'url': 'api/testcycle/getsearchbtccodelist',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    if(result.data.errorCode == 0){
                        this.search.btccodelist = result.data.data.rows;
                    } else {
                        this.search.btccodelist = [];
                    }
                }).catch(e => {
    this.displayError(e)
    })
            }
        },
        gettestcyclenamelist(node) {
            if (node && node.length>4) {
                this.input={
                    useremail  : this.undt.username,
                    empcode: this.undt.userid,
                    cyclename: node.toLowerCase(),
                }
                axios({
                    'method': 'POST',
                    'url': 'api/testcycle/gettestcyclename',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    if(result.data.errorCode == 0){
                        this.search.testcyclenamelist = result.data.data.rows;
                        localStorage.setItem('testcyclenamelist', JSON.stringify(this.search.testcyclenamelist));
                    } else {
                        this.search.testcyclenamelist = [];
                        localStorage.removeItem('testcyclenamelist');
                    }
                }).catch(e => {
    this.displayError(e)
    })
            }
        },
        gettestcyclenamelists(cyclenamelist) {
            if (cyclenamelist && cyclenamelist.length>0) {
                this.input={
                    useremail  : this.undt.username,
                    empcode: this.undt.userid,
                    cyclenamelist: cyclenamelist,
                }
                axios({
                    'method': 'POST',
                    'url': 'api/testcycle/gettestcyclename',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    if(result.data.errorCode == 0){
                        this.search.testcyclenamelist = result.data.data.rows;
                    } else {
                        this.search.testcyclenamelist = [];
                    }
                }).catch(e => {
    this.displayError(e)
    })
            }
        },
        gettestresultlist(objThis){
            if(objThis) {
                localStorage.setItem('cyclesetsearchItems', JSON.stringify(objThis))
            }
            this.isLoading = true;
            let search = JSON.parse(localStorage.getItem('cyclesetsearchItems'))
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
            }
            if(search){
                this.input.testCycleCode = this.search.testCycleCode
                this.input.testCycleName = this.search.testCycleName
                this.input.businesscode = this.search.businesscode
                this.input.tcname = this.search.tcname
            } 
            axios({
                'method': 'POST',
                'url': 'api/testcycle/gettestresultlist',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            }).then(result => {             
                    if(result.data.errorCode == 0){
                        this.cyclelist = result.data.data
                        this.totalcount = result.data.data.count;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        this.cyclelist=[]
                        this.totalcount = 0;
                        this.pageCount =0;
                    }
                }).catch(e => {
    this.displayError(e)
    })
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(btnstate=="edit"){
                this.$router.push({ 
                path: `/testexecution/testcyclesetedit?cyclesetid=${rowdata.cyclesetid}`,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
                })
            }
            if(btnstate=="view"){
                this.$router.push({ 
                path: `/testexecution/testcycleview?cyclesetid=${rowdata.cyclesetid}`,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
                })
            }
            if(btnstate=="Bview"){
                this.$router.push({ 
                path: '/businesstask/view?businessid='+rowdata.businesstaskmasterid,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
                })
            }
        },
        showmappingname(mappingname){
            let responsestr='';
            let prlist=mappingname.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            return responsestr
        },
        ExportXL(){
            this.isLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input = {
                limit: null,
                offset: null,
                useremail:this.undt.username,
                empcode: this.undt.userid,
                empid: this.undt.userid,
            };
            let search = JSON.parse(localStorage.getItem('cyclesetsearchItems'))
            if (search) {
                this.input.testCycleCode = this.search.testCycleCode
                this.input.testCycleName = this.search.testCycleName
                this.input.businesscode = this.search.businesscode
                this.input.tcname = this.search.tcname  
                this.input.status = this.search.statuses  
            }
            axios({
                method: "POST",
                url: `api/testcycle/gettestcyclelisting`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    var filename = "";
                    this.download(result.data.data.rows,filename)
                } else if(result.data.errorCode == 3) {
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                        }).then(function() {
                        window.location.href = "/#/login";
                        })
                } else {
                    Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    });
                }
            }).catch(e => {
    this.displayError(e)
    })
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
                var ShowLabel = 'Testcyclesets'+filename
                var JSONData = readExportdata
                var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
                var CSV = ''
                if (ShowLabel) {
                let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    row = row.slice(0, -1)
                    CSV = 'Sr#,Test Cycle Code,Test Cycle Name,Business Task Code,Business Task Name,Group Name,Start Date,End Date,Status,Created By,Created Date,Review In Progress Date,Review Date,Product Declined,Status Comment, Comment By,Total Scripts' + '\r\n'
                }
                var row = ''
                for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let cyclesetcode = index.hasOwnProperty('cyclesetcode') ? index.cyclesetcode == null || index.cyclesetcode.toLowerCase() == 'na' ? '' : index.cyclesetcode : '';
          let cyclesetname = index.hasOwnProperty('cyclesetname') ? index.cyclesetname ==null || index.cyclesetname.toLowerCase() =='na' ? '' : index.cyclesetname : '';
          let businesstaskcode = index.hasOwnProperty('businesstaskcode') ? index.businesstaskcode ==null || index.businesstaskcode.toLowerCase() =='na' ? '' : index.businesstaskcode : '';
          let taskname = index.hasOwnProperty('taskname') ? index.taskname ==null || index.taskname.toLowerCase() =='na' ? '' : index.taskname : '';
          let mappingname = index.hasOwnProperty('mappingname') ? index.mappingname ==null || index.mappingname.toLowerCase() =='na' ? '' : index.mappingname : '';
          let startdate = index.hasOwnProperty('startdate') ? index.startdate : '';
          startdate = this.dateMonthFormat(startdate);
          let enddate = index.hasOwnProperty('enddate') ? index.enddate : '';
          enddate = this.dateMonthFormat(enddate);
          let status = index.hasOwnProperty('status') ? index.status ==null || index.status.toLowerCase() =='na' ? '' : index.status : '';
          let createdby = index.hasOwnProperty('createdby') ? index.createdby : '';
          let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
          createddate = this.dateMonthFormat(createddate);
          let reviewinprogdate = index.hasOwnProperty('reviewinprogdate') ? index.reviewinprogdate : '';
          reviewinprogdate = this.dateMonthFormat(reviewinprogdate) ? this.dateMonthFormat(reviewinprogdate):'NA';
          let revieweddate = index.hasOwnProperty('revieweddate') ? index.revieweddate : '';
          revieweddate = this.dateMonthFormat(revieweddate) ? this.dateMonthFormat(revieweddate):'NA';
          let approveddate = index.hasOwnProperty('approveddate') ? index.approveddate : '';
          approveddate = this.dateMonthFormat(approveddate) ? this.dateMonthFormat(approveddate):'NA';
          let rundate = index.hasOwnProperty('rundate') ? index.rundate : '';
          rundate = this.dateMonthFormat(rundate) ? this.dateMonthFormat(rundate):'NA';
          let finishdate = index.hasOwnProperty('finishdate') ? index.finishdate : '';
          finishdate = this.dateMonthFormat(finishdate) ?this.dateMonthFormat(finishdate):'NA';
          let nooftestcase = index.hasOwnProperty('nooftestcase') ? index.nooftestcase : '0';
          let productpendingreviewdate = index.hasOwnProperty('productpendingreviewdate') ? index.productpendingreviewdate : '';
          productpendingreviewdate = this.dateMonthFormat(productpendingreviewdate) ? this.dateMonthFormat(productpendingreviewdate):'NA';
          let productreviewdate = index.hasOwnProperty('productreviewdate') ? index.productreviewdate : '';
          productreviewdate = this.dateMonthFormat(productreviewdate) ? this.dateMonthFormat(productreviewdate):'NA';
          let productapprovedate = index.hasOwnProperty('productapprovedate') ? index.productapprovedate : '';
          productapprovedate = this.dateMonthFormat(productapprovedate) ? this.dateMonthFormat(productapprovedate):'NA';
          let productdeclineddate = index.hasOwnProperty('productdeclineddate') ? index.productdeclineddate : '';
          productdeclineddate = this.dateMonthFormat(productdeclineddate) ? this.dateMonthFormat(productdeclineddate):'NA';
          let statuscomment = index.hasOwnProperty('statuscomment') ? index.statuscomment : '';
          statuscomment=statuscomment?statuscomment.replace(/<\/?[^>]+(>|$)/g, ""):'NA'
          let commentedby = index.hasOwnProperty('commentedby') ? index.commentedby : '';
          commentedby=commentedby?commentedby:'NA'

            row += '"' + srno + '",',
            row += '"' + cyclesetcode + '",',
            row += '"' + cyclesetname + '",',
            row += '"' + businesstaskcode + '",',
            row += '"' + taskname + '",',
            row += '"' + mappingname + '",',
            row += '"' + startdate + '",',
            row += '"' + enddate + '",',
            row += '"' + status + '",',
            row += '"' + createdby + '",',
            row += '"' + createddate + '",',
            row += '"' + reviewinprogdate + '",',
            row += '"' + revieweddate + '",',
            row += '"' + productdeclineddate + '",',
            row += '"' + statuscomment + '",',
            row += '"' + commentedby + '",',
            row += '"' + nooftestcase + '",',
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'Testcyclesets_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report Successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
        } else {
            Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
          confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
        }
        }, 
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        export (data, filename, mime) {
            var data
            let blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
        }
    }
}
</script>