<template>
    <div>
        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :is-full-page="fullPage"
          color="orange"
          loader="dots"
          :width="64"
          :height="64"
        ></loading>
        <div class="app-content content devcontent ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper">
                <div class="content-header row">
                    <div class="content-header-left col-md-9 col-12 mb-1">
                        <div class="row breadcrumbs-top">
                            <div class="col-12 mobile_view_padd-05">
                                <h3 class="content-header-title float-left mb-0">Reports</h3>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item"><router-link to='/trs/mydashboard'>My Dashboard</router-link></li>
                                        <li class="breadcrumb-item"><router-link to='/trs/reports'>Reports</router-link></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-body">
                   <div class="row">
                        <div class="col-12 mobile_view_padd-05">
                            <div class="card border-warning filter_card_searchbgst">
                                <div class="card-header filter_rotation_onlick" v-on:click="mysearchrequestollapse" style="cursor:pointer;">
                                    <div class="container-fluid" >
                                        <div class="row fc_my-05" >
                                            <div class="w-auto px-1 mobile-px-0">
                                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                        <div class="avatar-content">
                                                            <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                                        </div>
                                                    </div>
                                                    <h4 class="card-title d-inline-flex ml-1">Search Report Request</h4>
                                               
                                            </div>
                                            
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body card_padding_10">
                                        <div class="row">
                                            <div class="form-group col-12 col-sm-6 col-md-3 col-lg-3 mb-1">
                                                  <label class="form-label" for="vertical-modern-landmark">From Date</label>
                                                <div class="input-group input-group-merge">
                                                <date-picker placeholder="Select From Date" v-model="search.fromdate" :disabled-date="notBeforeToday" valueType="format" class="Startdate" @input="selectedfromdate"></date-picker>
                                                <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>                        
                            
                                                </div>
                                            </div>
                                            <div class="form-group col-12 col-sm-6 col-md-3 col-lg-3 mb-1">
                                                <label class="form-label" for="vertical-modern-landmark">To Date</label>
                                                <div class="input-group input-group-merge">
                                                    
                                                 <date-picker placeholder="Select To Date" v-model="search.todate" valueType="format" :default-value="currentdate" :disabled-date="disabledBefore" @input="selectedtodate"></date-picker>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-3 mb-1">
                                                <label class="form-label">Status</label>
                                                <treeselect placeholder="Select Ticket Status" v-model="search.status"  :options="statuslist" @input="selectstatus" />
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-1">
                                                <label class="form-label">Request ID</label>
                                                <input class="form-control" type="text" placeholder="Enter Request ID" v-model="search.requestid"/>
                                            </div>
                                            <div class="col-sm-6 col-md-12 col-lg-12 mb-1 text-right">
                                                <button type="submit" class="btn btn-primary mt-2" :disabled="isBothDateSelected" @click="getReportlist(search,1)" >
                                                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                                                <button type="button" class="btn btn-relief-primary mt-2 mx-1" @click="ExportXL()">
                                                    <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>                
                                                <button type="reset" class="btn btn-outline-secondary mt-2" v-on:click="mysearchrequestollapse" @click="resetRecords()">
                                                    <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card border-warning filter_card_searchbgst ">
                                <div class="card-header filter_rotation_onlick" style="cursor:pointer;" v-on:click="mydetailsollapse">
                                    <div class="container-fluid" >
                                        <div class="row fc_my-05" >
                                            <div class="w-auto px-1 mobile-px-0">
                                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                        <div class="avatar-content">
                                                    <clipboard-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></clipboard-icon>
                                                </div>
                                            </div>
                                            <h4 class="card-title d-inline-flex ml-1">Report Details</h4>
                                            </div>
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isDetActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body px-0 pt-0">
                                        <div class="row mx-0">
                                            <div class="table-responsive" v-if="showtabledata">
                                                <table aria-describedby="mydesc" class="table table-sm dataTableListing table-striped theade_padding_y10">
                                                    <thead>
                                                        <tr>
                                                            <th class="py-1" scope="col">Request ID</th>
                                                            <th scope="col">Travel Name</th>
                                                            <th class="text-left" scope="col" style="width:180px;">From City</th>
                                                            <th class="text-left" scope="col" style="width:180px;">To City</th>
                                                            <th scope="col" style="width:120px;" class="text-left">Visit Name</th>
                                                            <th scope="col" class="text-left">Purpose</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col" style="min-width:100px;">Start Date</th>
                                                            <th scope="col" style="min-width:100px;">End Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(data,index) in requestList" v-bind:key="index">
                                                            <td>{{data.ticketid}}</td>
                                                            <td>{{data.employname}}</td>
                                                            <td class="text-left">{{data.fromcityname}}</td>
                                                            <td class="text-left">{{data.cityname}}</td>
                                                            <td class="text-left">{{data.visitname}}</td>
                                                            <td class="text-left">{{data.purpose}}</td>
                                                            <td>{{data.status}}</td>
                                                            <td>{{data.startdate}}</td>
                                                            <td>{{data.enddate}}</td>
                                                        </tr>
                                                         <tr v-if="requestList.length ==0" >
                                                            <td colspan="8">No Record Found</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pageCenter pt-0 text-center">
                                    <pagination class="pull-right" v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import moment from 'moment'
    import { PlusIcon,PocketIcon,CodepenIcon,AtSignIcon,MailIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,CheckSquareIcon,
    MessageCircleIcon,XSquareIcon,
    SearchIcon,PlusCircleIcon,ClipboardIcon,UserIcon,MapPinIcon,InfoIcon,MoreVerticalIcon,EditIcon,EyeIcon,UploadIcon,PowerIcon,PlusSquareIcon,MinusSquareIcon,DownloadIcon,XIcon,ExternalLinkIcon } from 'vue-feather-icons'
    // Import component
    import Loading from 'vue-loading-overlay';
    import DatePicker from 'vue2-datepicker';
     // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Pagination from "vue-pagination-2";
    import apiUrl from "../../../constants";
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    
    export default({
        name:'ReportRequest',
        
        data() {
            return {
                isBothDateSelected:false,
                ticketdetails:[],
                isLoading: false,
                fullPage: true,
                search:{
                    status:null,
                    fromdate:'',
                    todate:'',
                    requestid:''
                },
                errors:{
                    fromdate:'',
                    todate:'',
                    status:''
                },
                statuslist:[{
                    id:'0',
                    label:'Pending'
                    },{
                        id:'2',
                        label:'Approved'
                    },{
                        id:'3',
                        label:'Feedback'
                    },{
                        id:'4',
                        label:'Reject'
                    },{
                        id:'5',
                        label:'Admin Pending'
                    },{
                        id:'6',
                        label:'Closed'
                    },
                    {
                        id:'7',
                        label:'Revoked'
                    },
                    {
                        id:'8',
                        label:'Cancellation'
                    },
                ],
                currentdate: moment(new Date()).format('YYYY-MM-DD'),
                emptypeBlured:false,
                pagefeatures:[],
                requestList:[],
                isDetActive:true,
                isRequestActive:false,
                isSearchRequestActive:false,
                card_content:'card-content',
                collapse:'collapse',
                isActiveEndDate:false,
                showmanager:true,
                transportList:[],
                 totalcount: 0,
                pageCount: 0,
                page: 1,
                pagelimit: apiUrl.LIMIT,
                currPageNum: 0,
                pageoffset: 1,
                showtabledata:true,
                tokendata:null,
            }  
        } ,
        components: {
            PocketIcon,CodepenIcon,AtSignIcon,MailIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,
            EditIcon,CheckSquareIcon,MessageCircleIcon,XSquareIcon,
            EyeIcon,
            MoreVerticalIcon,
            Loading,
            PlusIcon,
            SearchIcon,
            PlusCircleIcon,
            ClipboardIcon,
            UserIcon,
            MapPinIcon,
            InfoIcon,
            UploadIcon,
            PowerIcon,
            PlusSquareIcon,
            MinusSquareIcon,
            DownloadIcon,
            DatePicker,
            Pagination,
            Treeselect,
            XIcon,
            ExternalLinkIcon
        },
        created() {
            this.pagefeatures = {}
            let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
            if (element.page === '/travelrequest') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
            })
        },
        mounted(){
            let userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.tokendata = window.localStorage.getItem("token");
                this.username = userdata.username
                this.empid = userdata.userid
                this.loguser = userdata.firstname+' '+userdata.lastname
                this.getReportlist(null,0)
            }
            if (feather) {
                feather.replace({
                    width: 14,
                    height: 14
                });
            }
            
        },
        
        methods:{
             clickCallback: function(pageNum) {
                this.pageoffset=pageNum
                this.currPageNum = pageNum - 1
                let payload= null
                let isSearch = this.search.fromdate || this.search.todate || this.search.status || this.search.requestid
                if(isSearch){
                    payload = {
                        fromdate:this.search.fromdate,
                        todate:this.search.todate,
                        status:this.search.status,
                        requestid: this.search.requestid
                    }
                }
                this.getReportlist(payload,0)
            },
            resetRecords: function () {
                this.search.fromdate = null;
                this.search.todate = null;
                this.search.status = null;
                this.search.requestid = '';
                this.isBothDateSelected = false
                localStorage.removeItem('searchItems')
                this.getReportlist(null,0);
            },
            notBeforeToday: function (date) {
                this.search.todate = "";
                return date > new Date(new Date().setHours(0, 0, 0, 0));
            },
            disabledBefore(date) {
                let dayBefore = moment(this.search.fromdate).format(this.TimeFormat);
                const beforeToday = new Date(dayBefore);
                        beforeToday.setHours(0, 0, 0, 0);
                return date < beforeToday; // Assume < 25 May Not Selected
            },
            dateFormat(date) {
                if (!date) return '';
                return moment(date).format(this.TimeFormat);
            },
            onCancel() {
                console.log('User cancelled the loader.')
            },
            selectedfromdate(){
                if((this.search.fromdate && this.search.todate) || (!this.search.fromdate && !this.search.todate)){
                    this.isBothDateSelected = false
                }else if((this.search.fromdate && !this.search.todate) ||( !this.search.fromdate && this.search.todate)){
                    this.isBothDateSelected = true
                }
            },
            selectedtodate(state){
                if((this.search.fromdate && this.search.todate) || (!this.search.fromdate && !this.search.todate)){
                    this.isBothDateSelected = false
                }else if((this.search.fromdate && !this.search.todate) ||( !this.search.fromdate && this.search.todate)){
                    this.isBothDateSelected = true
                }
                if(state && !this.search.status){
                    this.errors.status = "Status is required"
                }
            },
            selectstatus(state, value) {
                this.search.status = null
                this.search.status = state
                if(!state) {
                    this.errors.status = "Status is required"
                } else {
                    this.errors.status = null
                }
            },
            validText : function(inputval) {
                let re = /[A-Za-z0-9].{5,}/;
                if(inputval){
                    return re.test(inputval.toLowerCase());
                }
            },
            displaymgr(){
                this.showmanager = !this.showmanager
            },
            
            mydetailsollapse:function(){
                this.isDetActive=!this.isDetActive
            },
            mysearchrequestollapse:function(){
                this.isSearchRequestActive=!this.isSearchRequestActive
            },
            getReportlist(search,flag){
                if(flag ==1) {
                    if(
                        (
                            this.search.status == null && 
                            this.search.fromdate == '' &&
                            this.search.todate == '' &&
                            this.search.requestid == ''
                        ) || 
                        (
                            this.search.status == null &&
                            this.search.fromdate == null &&
                            this.search.todate == null &&
                            this.search.requestid == null
                        )
                    ) {
                    Swal.fire({
                        title: "",
                        text: "Please select at least one filter",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                    return false;
                }
                if(this.isBothDateSelected ) {
                     Swal.fire({
                        title: "",
                        text: "Both dates should be selected",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                    return false;
                }
    
                if(this.search.fromdate && this.search.todate) {
                    let days = moment(this.search.todate,"YYYY-MM-DD").diff(moment(this.search.fromdate,"YYYY-MM-DD"), 'days');
                    if(days>60) {
                        Swal.fire({
                        title: "",
                        text: "Date should be select within 60 days",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                    return false;
                    }
                    
                }  
                }
    
                this.requestList=[]
                this.isLoading = true;
                // this.pageoffset = 1
                let apiURL='api/reimbursement/getTravelTicketReports'     
                this.input={
                    offset:this.pageoffset,
                    limit: apiUrl.LIMIT,
                    fromdate:this.search.fromdate,
                    todate:this.search.todate,
                    status:this.search.status,
                    requestid:this.search.requestid,
                    empcode:this.empid,
                    useremail: this.username
                }  
                let assignedate ='';
                if(this.search.todate){
                    assignedate = new Date(this.search.todate)
                    let newdate = assignedate.setDate(assignedate.getDate() + 1)
                    newdate = moment(newdate).format('YYYY-MM-DD');
                    this.input.todate = this.search.todate?newdate:null
                    
                }  
                if (search){
                    this.input.status =search.status
                    this.input.fromdate = this.search.fromdate?this.search.fromdate:null
                    
                    this.input.requestid = this.search.requestid?this.search.requestid:null   
                }             
    
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {   
                    this.isLoading = false;          
                    if(result.data.status){
                        this.isDetActive=true;
                         this.requestList = result.data.data.responsedata;
                            this.totalcount = result.data.data.count
                            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                            if(flag == 2) {
                                this.downloadReport(this.requestList)
                            }
                    }else{
                        this.requestList = [];
                        this.isLoading= false;
                        this.totalcount = 0;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }
                })
            },
            ExportXL() {
                if(
                        (
                            this.search.status == null && 
                            this.search.fromdate == '' &&
                            this.search.todate == '' &&
                            this.search.requestid == ''
                        ) || 
                        (
                            this.search.status == null &&
                            this.search.fromdate == null &&
                            this.search.todate == null &&
                            this.search.requestid == null
                        )
                    ) {
                    Swal.fire({
                        title: "",
                        text: "Please select at least one filter",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                    return false;
                }
                if(this.isBothDateSelected ) {
                     Swal.fire({
                        title: "",
                        text: "Both dates should be selected",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                    return false;
                }
    
                if(this.search.fromdate && this.search.todate) {
                    let days = moment(this.search.todate,"YYYY-MM-DD").diff(moment(this.search.fromdate,"YYYY-MM-DD"), 'days');
                    if(days>60) {
                        Swal.fire({
                        title: "",
                        text: "Date should be select within 60 days",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                    return false;
                    }
                    
                }  
                this.isLoading = true;
                this.exportxlsdata = [];
                this.exporterr = "";
                this.input = {
                    limit:null,
                    offset:null,
                    empcode: this.empid,
                    useremail: this.username
                }
                let assignedate =''
                   if(this.search.todate){
                     assignedate =new Date(this.search.todate)
                    let newdate = assignedate.setDate(assignedate.getDate() + 1)
                    newdate = moment(newdate).format('YYYY-MM-DD');
                    this.input.todate = this.search.todate?newdate:null
                    }
    
                    this.input.status = this.search.status
                    this.input.fromdate = this.search.fromdate?this.search.fromdate:null
                    
                    this.input.requestid = this.search.requestid?this.search.requestid:null  
    
                axios({
                    method: "POST",
                    url: `api/reimbursement/getTravelTicketReports`,
                    data: this.input,
                    headers: { authorization: this.tokendata },
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.exportxlsdata = result.data.data.responsedata;
                        let filename = "";
                        this.download(result.data.data.responsedata,filename)
                        let pay1 ={
                             offset:this.pageoffset,
                            limit: apiUrl.LIMIT,
                            fromdate:this.search.fromdate,
                            todate:this.search.todate,
                            status:this.search.status,
                            empcode:this.empid,
                            useremail: this.username
                        }
                        this.getReportlist(pay1,0);
                    } else {
                        Swal.fire({
                            title: "",
                            text: "Data not found",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        });
                    }
                    
                    if (result.data.status) {
                        this.isDetActive = true;
                        this.requestList = result.data.data.responsedata;
                        this.totalcount = result.data.data.count;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                    } else {
                        this.requestList = [];
                        this.totalcount=0;
                    }
                });
            },
            dateMONFormat(value){
                if (value) {
                    return moment(String(value)).format('DD-MM-YYYY HH:MM:SS');
                } else{
                    return "";
                }
            },
    
            download(readExportdata,filename) {
                if (readExportdata.length > 0) {
                let ShowLabel = 'ticketdetalsreport'
                let JSONData = readExportdata
                let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
                let CSV = ''
                if (ShowLabel) {
                    let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    //row = row.slice(0, -1)
                    CSV = 'REQUEST ID,EMPLOYEE ID,DESIGNATION,TRAVEL NAME,FROM CITY,TO CITY,VISIT NAME,VISIT LOCATION,PURPOSE,START DATE,END DATE,TRANSPORT MODE,DEPARTMENT,CHECKIN,CHECKOUT,NUMBER OF NIGHTS,L1APPROVAL,BOOKEDBY,GROUP TRAVELLER NAME,INSTRUCTION,PREFERED DATE OF TRAVEL,APPOINTMENT DATE / REPORTING DATE,REQUEST DATE,APPROVE DATE,CLOSE DATE,STATUS' + '\r\n'
                }
                let row = ''
                for (const index of arrData) {
                
                let ticketid = index.ticketid ? index.ticketid : '';
                let employname = index.employname ? index.employname : '';
                let fromcityname = index.fromcityname ? index.fromcityname : '';
                let cityname = index.cityname ? index.cityname : '';
                let visitname = index.visitname ? index.visitname : '';
                let visitlocation = index.visitlocation ? index.visitlocation : '';
                let purpose = index.purpose ? index.purpose : '';
                let startdate = index.startdate ? index.startdate : '';
                let enddate = index.enddate ? index.enddate : '';
                let empid = index.empid ? index.empid : '';
                let designation = index.designation ? index.designation : '';
                let transportmode = index.transportmode ? index.transportmode : '';
                let noofnight = index.noofnight ? index.noofnight : 0;
                let department = index.department ? index.department : '';
                let checkindate = index.checkindate ? index.checkindate : '';
                let checkoutdate = index.checkoutdate ? index.checkoutdate : '';
                let l1_approval = index.l1_approval ? index.l1_approval : '';
                let bookedby = index.bookedby ? index.bookedby:'';
                let grpTravellerName = index.empname ? index.empname:'';
                let instruction = index.instruction ? index.instruction:'';
                let traveldate = index.traveldate ? index.traveldate:'';
                let appointmentdate = index.appointmentdate ? index.appointmentdate:'';
                let requestdate = index.requestdate ? index.requestdate:'';
                let approvemanagerdate = index.approvemanagerdate ? index.approvemanagerdate:'';
                let adminclosedate = index.adminclosedate ? index.adminclosedate:'';
                 let status = index.status ? index.status:'';
                
                row += '"' + ticketid + '","' + empid + '","' + designation + '","' + employname + '","' + fromcityname + '","' + cityname + '","' + visitname + '","' + visitlocation + '","' + purpose + '","' + startdate + '","' + enddate + '","' + transportmode + '","' + department + '","' + checkindate + '","' + checkoutdate + '","' + noofnight + '","' + l1_approval + '","' + bookedby + '","' + grpTravellerName + '","' + instruction + '","' + traveldate + '","' + appointmentdate + '","' + requestdate + '","' + approvemanagerdate + '","' + adminclosedate + '","' + status + '"\r\n'
            }
            CSV += row
                if (CSV === '') {
                Swal.fire({
                    title: "Error!",
                    text: 'Invalid data',
                    icon: 'error',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                return;
                }
                let fileName = 'ticketdetailsreport_'+moment().format('DD_MM_YY_HH_mm_ss')
                let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                let link = document.createElement('a')
                link.href = uri
                link.style = 'visibility:hidden'
                link.download = fileName + '.csv'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                Swal.fire({
                    title: "Success!",
                    text: "Export Report successful",
                    icon: "success",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            } else {
                  Swal.fire({
                    title: "Error!",
                    text: 'No Records Found',
                    icon: 'error',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            
            }
            }
        }
    })
    </script>
    <style scoped>
    .display{
        display: none;
    }
    .mx-datepicker{
        width:100%!important;
    }
    </style>
    