<template>
  <div class="app-content content ">
    <loading
    :active.sync="isLoading"
    :can-cancel="false"
    :is-full-page="fullPage"
    color="orange"
    loader="dots"
    :width="100"
    :height="100"
    ></loading>
    <loading
    :active.sync="isLoadingBTCDATA"
    :can-cancel="false"
    :is-full-page="fullPage"
    color="orange"
    loader="dots"
    :width="100"
    :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h3 class="content-header-title float-left mb-1">Release Planner Details</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'ListReleaseNote', params: { search: true }}">Release Planner List</router-link></li>
                <li class="breadcrumb-item sitebluecolor cursorpointercls">View Release Planner</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div v-bind:class="[{show: isRequestActiveView}, card_content,collapse]">
            <div class="card-header py-1 pt-2" style="cursor:pointer">
                <div class="container-fluid">
              <div class="row">
                <div class="col-auto mobile-padding-0-2 d-flex align-items-center">
                    <div class="avatar bg-light-primary rounded">
                    <div class="avatar-content">
                      <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                    </div>
                  </div>
                  <h3 class="card-title ml-2 d-inline-flex text-primary">Release Note CODE: {{view.releasenotecode}}</h3>
                </div>
                <div class="ml-auto text-right">
                    <span v-if="(isoldreleasenote == 1)">
                        <div class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" v-if="(view.releasestatus == 'Pending' && view.approvedby === loginuscondition)" v-on:click="reqToApprovedRNote(view)" href="javascript:void(0);">
                          <span>Approve</span>
                        </div>
                    </span>
                    <span v-else>
                        <div class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" v-if="((loginuserapprovaldetails && loginuserapprovaldetails.status && loginuserapprovaldetails.status == 'Pending') && (view.approvedby == loginuscondition || editRowData.projectmanager == loginuscondition))" v-on:click="reqToApprovedRNote(view)" href="javascript:void(0);">
                          <span>Approve</span>
                        </div>
                    </span>
                    <div class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" v-if="(view.releasestatus == 'Approved' && (view.createdby == loginusername || view.approvedby == loginuscondition )&& view.assignstatus == null)" v-on:click="reqToAssignedToRNote(view)" href="javascript:void(0);">
                        <span>Assign To Devops</span>
                    </div>
                    <div class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" v-if="(view.assignto == loginuscondition && view.assignstatus == 'Pending for Deployement')" v-on:click="reqToDeployedRNote(view)" href="javascript:void(0);">
                        <span>Deploy Release Note</span>
                    </div>
                    <div class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" v-if="(view.taskmasterid && view.taskmasterid!=null)" v-on:click="redirecttoTask(view)" href="javascript:void(0);">
                        <span>Task Details</span>
                    </div>
                </div>
              </div>
            </div>
          </div>
            <div class="row match-height">
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                  <div class="card-body mobile-padding-15-10">
                    <div class="transaction-item">
                      <div class="media">
                          <div v-if="view.releasestatus=='To Do'" class="avatar bg-light-success rounded">
                              <div class="avatar-content">
                                  <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                          </div>
                          <div v-if="view.releasestatus=='In Progress'" class="avatar bg-light-warning rounded">
                              <div class="avatar-content">
                                  <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                          </div>
                          <div v-if="view.releasestatus=='Pending'" class="avatar bg-light-warning rounded">
                              <div class="avatar-content">
                                  <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                          </div>
                          <div v-if="view.releasestatus=='Approved'" class="avatar bg-light-success rounded">
                              <div class="avatar-content">
                                    <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                          </div>
                          <div v-if="view.releasestatus=='Rejected'" class="avatar bg-light-danger rounded">
                              <div class="avatar-content">
                                   <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                          </div>
                          <div v-if="view.releasestatus=='Deployed'" class="avatar bg-light-success rounded">
                              <div class="avatar-content">
                                   <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                          </div>
                          <div class="media-body mobile-padding-15-10">
                              <h6 class="transaction-title">Release Note Status</h6>
                              <small v-if="(view.releasestatus=='To Do' && view.assignstatus == null)" class="capitalisetext text-warning" style="font-weight:500">{{view.releasestatus}}</small>
                              <small v-if="(view.releasestatus=='In Progress' && view.assignstatus == null)" class="capitalisetext text-warning" style="font-weight:500">{{view.releasestatus}}</small>
                              <small v-if="(view.releasestatus=='Done' && view.assignstatus == null)" class="capitalisetext text-warning" style="font-weight:500">{{view.releasestatus}}</small>
                              <small v-if="(view.releasestatus=='Pending' && view.assignstatus == null)" class="capitalisetext text-warning" style="font-weight:500">{{view.releasestatus}}</small>
                              <small v-if="(view.releasestatus=='Approved' && view.assignstatus == null)" class="capitalisetext text-success" style="font-weight:500">{{view.releasestatus}}</small>
                              <small v-if="(view.releasestatus=='Rejected' && view.assignstatus == null)" class="capitalisetext text-danger" style="font-weight:500">{{view.releasestatus}}</small>
                              <small v-if="(view.assignstatus=='Deployed' && view.releasestatus=='Approved')" class="capitalisetext text-success" style="font-weight:500">{{view.assignstatus}}</small>
                              <small v-else class="capitalisetext text-warning" style="font-weight:500">{{view.assignstatus}}</small>
                          </div>
                      </div>
                  </div>
                
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-success rounded">
                          <div class="avatar-content">
                            <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Version</h6>
                          <small>{{view.version}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="i_icon_st la-hdd las lab font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Application Name</h6>
                          <small v-if="view.applicationname">
                          <span v-html="showprojectname(view.applicationname)"></span>
                          </small>
                          <small v-else>NA</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <em class="lab la-jenkins font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Jenkins Job Name</h6>
                          <small v-if="view.jobname != null || '' ">
                          <span v-html="showprojectname(view.jobname)"></span>
                          </small>
                          <small v-else>NA</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="lab la-gitlab font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Gitlab Repo Name</h6>
                          <small v-if="view.reponame != null || ''">
                          <span v-html="showprojectname(view.reponame)"></span>
                          </small>
                          <small v-else>NA</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-success rounded">
                          <div class="avatar-content">
                           <em class="las la-user-friends font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Subscriber role's</h6>
                            <div v-for="(datas,indexs) in this.subscriberrolename" v-bind:key="indexs">
                              {{indexs+1}}. {{datas}}
                            </div>
                        </div>
                      </div>
                    </div>
                  
                  </div>                                                     
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                  <div class="card-body mobile-padding-15-10">
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Project Name</h6>
                           <small>{{view.project ? view.project:'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="las la-user-edit font-medium-3"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Engineering Manager</h6>
                          <small>{{(view.engmanname) ? capitalletterfun(view.engmanname):'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="las la-user-edit font-medium-3"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Project Manager</h6>
                          <small>{{(view.promanname) ? capitalletterfun(view.promanname):'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="las la-user-edit font-medium-3"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">QA Lead</h6>
                          <small>{{(view.qaleadname) ? capitalletterfun(view.qaleadname):'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="las la-user-edit font-medium-3"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Business Analyst</h6>
                          <small>{{(view.baname && view.baname != null && view.baname != '' && view.baname != ' ') ? capitalletterfun(view.baname):'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="las la-user-edit font-medium-3"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Assign To Devops</h6>
                          <small>{{(view.assigntoemail) ? view.assigntoemail:'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                           <em class="las la-calendar font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Approved Date</h6>
                          <small>{{dateMonthFormat(view.approveddate) ? dateMonthFormat(view.approveddate):'NA'}}</small>
                        </div>
                      </div>
                    </div>
        
                  </div>                                                                
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                  <div class="card-body mobile-padding-15-10">
                    
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            
                            <em class="las la-calendar font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Created By</h6>
                          <small>{{view.createdby}}</small>
                        </div>
                      </div>
                    </div>

                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            
                            <em class="las la-calendar font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Created Date</h6>
                          <small>{{dateMonthFormat(view.createddate) ? dateMonthFormat(view.createddate):'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                                <div class="avatar-content">
                                    <em class="las la-user-edit font-medium-3"></em>
                                </div>
                            </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Last Modified By</h6>
                          <small>{{view.lastmodifiedby ? view.lastmodifiedby.split('@')[0]: view.createdby}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                        <div class="media">
                            <div class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                                <em class="las la-calendar font-medium-5"></em>
                            </div>
                            </div>
                            <div class="media-body">
                            <h6 class="transaction-title">Last Modified Date</h6>
                            <small>{{dateMonthFormat(view.lastmodifieddate) ? dateMonthFormat(view.lastmodifieddate): dateMonthFormat(view.createddate)}}</small>
                            </div>
                        </div>
                    </div>
                   
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-success rounded">
                          <div class="avatar-content">
                            <em class="las la-user font-medium-3"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Deployed By</h6>
                          <small>{{view.releasedby ? view.releasedby:'NA'}}</small>
                        </div>
                      </div>
                    </div>

                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-success rounded">
                          <div class="avatar-content">
                            <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Start Date</h6>
                          <small>{{(view.startdate && view.startdate!=null)?dateMonthFormat(view.startdate): 'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="las la-user-edit font-medium-3"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">End Date</h6>
                          <small>{{(view.enddate && view.enddate != null) ? dateMonthFormat(view.enddate):'NA'}}</small>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div v-if="view.comment != null || ''" class="col-12">
                  <div class="card-transaction">
                      <div class="card-body mobile-padding-15-10">
                          <div class="transaction-item" style="overflow:auto">
                              <div class="media">
                                  <div class="avatar bg-light-primary rounded">
                                      <div class="avatar-content">
                                          <monitor-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></monitor-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Approved Comment</h6>
                                      <!-- <span v-html="showprojectname(view.comment)"></span> -->
                                      <div class="col-md-12 px-0  micro_case_groupingview_readmore mb-1">
                                            <div class="custom-container case_bg_17">
                                                <div v-if="view.comment" v-bind:class="[readmore]" :id="'cycle_readmore5'">
                                                    <span class="fc_m16_btcode_text" v-html="formatData(view.comment)"></span>
                                                    
                                                </div>
                                                
                                                <div class="cycle_viewmoretext" v-if="view.comment && view.comment.length > 50">
                                                    <a class="btn">
                                                    <strong :id="'id5'" @click="idclick5(indexx)">
                                            Read More
                                        </strong>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                  
                                  
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div  v-if="view.assigntocomment != null || ''" class="col-12">
                  <div class="card-transaction">
                      <div class="card-body mobile-padding-15-10">
                          <div class="transaction-item" style="overflow:auto">
                              <div class="media">
                                  <div class="avatar bg-light-primary rounded">
                                      <div class="avatar-content">
                                          <monitor-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></monitor-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Assign To Comment</h6>
                                      <span v-html="showprojectname(view.assigntocomment)"></span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div  v-if="view.deployementcomment != null || ''" class="col-12">
                  <div class="card-transaction">
                      <div class="card-body mobile-padding-15-10">
                          <div class="transaction-item" style="overflow:auto">
                              <div class="media">
                                  <div class="avatar bg-light-primary rounded">
                                      <div class="avatar-content">
                                          <monitor-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></monitor-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Deployement Comment</h6>
                                      <span v-html="showprojectname(view.deployementcomment)"></span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
             
               <div v-if="featureslist.length>0" class="container pt-1 ">
                  <div class="col-sm-12 mb-1 " >
                      <div class="table-responsive datatableview">
                          <table aria-describedby="relesenotetbl" id="relesenotetbl" class="table table-sm dataTableListing table-hover table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col" class="text-center">Module Name</th>
                                  <th scope="col" class="text-center" style="min-width:180px">Business Task Code</th>
                                  <th scope="col" class="text-center">Task Name</th>
                                  <th scope="col" class="text-center">Task Type</th>
                                  <th scope="col" class="text-center" style="min-width:150px">Task Code(s)</th>
                                  <th scope="col" class="text-center">QA Check SmartWorks ID(s)</th>
                                  <th scope="col" class="text-center" style="min-width:150px">Cycleset Code(s)</th>
                                  <th scope="col" class="text-center">Security Code Review Check</th>
                                  <th scope="col" class="text-center">Sonar Cube SmartWorks ID(s)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(bttlist, index) in featureslist" v-bind:key="index">
                                  <td data-column="Module Name" class="text-center font-weight-bold" v-if="bttlist.modulename != null || ''"><span v-html="showprojectname(bttlist.modulename)"></span></td>
                                  <td data-column="Module Name" class="text-center" v-else>
                                    NA
                                  </td>
                                  <td data-column="Business Task Code" class="text-center">
                                      {{bttlist.businesstaskcode}}
                                  </td>
                                  <td  data-column="Task Name" class="text-center">
                                      {{bttlist.btcname}}
                                  </td>
                                  <td data-column="Task Type"  class="text-center">
                                      {{bttlist.tasktype}}
                                  </td>
                                  <td data-column="Task Code(s)"  class="text-center font-weight-bold"><span v-html="showprojectname(bttlist.taskcode)"></span></td>
                                  <td data-column="QA Check SmartWorks ID(s)"  class="text-center font-weight-bold" v-if="bttlist.issuecode"><span v-html="showprojectname(bttlist.issuecode)"></span></td>
                                  <td data-column="QA Check SmartWorks ID(s)"  class="text-center font-weight-bold" v-else>NA</td>
                                  <td data-column="Cycleset Code(s)"  class="text-center font-weight-bold" v-if="bttlist.cyclesetcode"><span v-html="showprojectname(bttlist.cyclesetcode)"></span></td>
                                  <td data-column="Cycleset Code(s)"  class="text-center font-weight-bold" v-else>NA</td>
                                  <td data-column="Security Code Review Check"  class="text-center" v-if="bttlist.security_code_review_check == 1">
                                    Yes
                                  </td>
                                  <td data-column="Security Code Review Check"  class="text-center" v-else>
                                    NO
                                  </td>
                                  <td data-column="Sonar Cube SmartWorks ID(s)"  class="text-center" v-if="bttlist.sonarcube_check == 1">
                                    Yes
                                  </td>
                                  <td data-column="Sonar Cube SmartWorks ID(s)"  class="text-center" v-else>
                                    No
                                  </td>
                                </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>

            </div>
          </div>
        </div>

        <div class="tab-content mb-2 d-inline-block w-100" >
          <div role="tabpanel" class="tab-pane active" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
              <div class="collapse-margin collapse-icon mt-1" id="faq-approvaldetails">
                <div v-if="isoldreleasenote == 0 && roleapprovaldetails.length>0" class="card card resolved rounded">
                      <div class="card-header" id="approvaldetailsrn" data-toggle="collapse" role="button" data-target="#approval-details" aria-expanded="true" aria-controls="approval-details">
                          <span class="lead collapse-title">RELEASE PLANNER APPROVAL DETAILS</span>
                      </div>

                      <div id="approval-details" class="collapse show" aria-labelledby="approvaldetailsrn" data-parent="#faq-approvaldetails">
                          <div class="card-body">
                            <div class="mb-1 px-0" >
                                <div class="table-responsive datatableview" style="overflow-x: inherit !important;">
                                    <table aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                        <thead class="d-none"> 
                                          
                                                <th>Sr. No.</th>
                                                <th>Approval Status</th>
                                                <th>Role Name</th>
                                                <th>User Name</th>
                                                <th>Approval Comment</th>
                                            
                                        </thead>
                                        <tbody>
                                            <tr v-for="(adata, i) in roleapprovaldetails">
                                                <td data-column="Sr. No.">{{ i+1 }}</td>
                                                <td data-column="Approval Status">{{ adata.status }}</td>
                                                <td data-column="Role Name">{{ adata.userrolename }}</td>
                                                <td data-column="User Name" v-if="adata.userroleid == PMroleid">
                                                  <small>{{(view.promanname) ? capitalletterfun(view.promanname):'NA'}}</small>
                                                </td>
                                                <td data-column="User Name" v-else-if="adata.userroleid == EMroleid">
                                                  <small>{{(view.engmanname) ? capitalletterfun(view.engmanname):'NA'}}</small>
                                                </td>
                                                <td data-column="Approval Comment">{{ adata.comment }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                            <div v-if="approvaltaskdetails.length>0" class="mb-1 px-0">
                              <span><b>APPROVAL TASK DETAILS</b></span>
                                <div class="table-responsive" style="overflow-x: inherit !important;">
                                    <table aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                        <thead class="d-none"> <th scope="col"></th></thead>
                                        <tbody>
                                            <tr>
                                                  <td>SR. NO.</td>
                                                  <td>TASK CODE</td>
                                                  <td>TASK NAME</td>
                                                  <td>STATUS</td>
                                                  <td>ALLOTTED TO</td>
                                                  <td>START DATE</td>
                                                  <td>END DATE</td>
                                            </tr>
                                            <tr v-for="(tdata, i) in approvaltaskdetails">
                                                <td>{{ i+1 }}</td>
                                                <td class="font-weight capitalisetext cursorpointercls sitebluecolor" style="cursor: pointer" @click="redirecttourl('view',tdata)" href="javascript:void(0);">{{ tdata.taskcode }}</td>
                                                <td>{{ tdata.taskname }}</td>
                                                <td>{{ tdata.status }}</td>
                                                <td>
                                                  <small>{{(tdata.empname) ? capitalletterfun(tdata.empname):'NA'}}</small>
                                                </td>
                                                <td>{{ dateMonthFormat(tdata.taskstartdate) }}</td>
                                                <td>{{ dateMonthFormat(tdata.taskenddate) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                          </div>
                      </div>
                </div>
                  <div class="card card resolved rounded">
                      <div class="card-header" id="paymentOne" data-toggle="collapse" role="button" data-target="#faq-payment-one" aria-expanded="false" aria-controls="faq-payment-one">
                          <span class="lead collapse-title">IMPACT & RECOVERY</span>
                      </div>

                      <div id="faq-payment-one" class="collapse" aria-labelledby="paymentOne" data-parent="#faq-payment-one">
                          <div class="card-body">
                            <div class="mb-1 px-0" >
                                <div class="table-responsive" style="overflow-x: inherit !important;">
                                    <table aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                        <thead class="d-none"> <th scope="col"></th></thead>
                                        <tbody>
                                          <tr>
                                            <td class="text-left">
                                              Has this been tested in a lower environment?
                                            </td>
                                            <td class="text-center" v-if="view.lower_environment == 1">
                                              Yes
                                            </td>
                                            <td class="text-center" v-else>
                                              NO
                                            </td>
                                          </tr>
                                          <tr>
                                            <td class="text-left">
                                              Has the roll-back been tested & documented?
                                            </td>
                                            <td class="text-center" v-if="view.tested_documented == 1">
                                              Yes
                                            </td>
                                            <td class="text-center" v-else>
                                              NO
                                            </td>
                                          </tr>
                                          <tr>
                                            <td class="text-left">
                                              Has Backward ?
                                            </td>
                                            <td class="text-center" v-if="view.backward_compatibility == 1">
                                              Yes
                                            </td>
                                            <td class="text-center" v-else>
                                              NO
                                            </td>
                                          </tr> 
                                          <tr>
                                            <td class="text-left">
                                              If deployment fails, how much time is required to bring it back to steady state and what steps will be taken?
                                            </td>
                                            <td class="text-center" v-if="view.if_deployment_fails">
                                              {{view.if_deployment_fails}}
                                            </td>
                                            <td class="text-center" v-else>
                                              NA
                                            </td>
                                          </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="card card open_primary">
                      <div class="card-header" id="paymentTwo" data-toggle="collapse" role="button" data-target="#faq-payment-two" aria-expanded="true" aria-controls="faq-payment-two">
                          <span class="lead collapse-title">DEPENDENCY</span>
                      </div>
                      <div id="faq-payment-two" class="collapse show" aria-labelledby="paymentTwo" data-parent="#faq-payment-two">
                          <div class="card-body">
                            <div class="mb-1 px-0" >
                      <div class="table-responsive" style="overflow-x: inherit !important;">
                          <table aria-describedby="rndependancy" id="rndependancy" class="table table-sm dataTableListing table-hover table-striped table-bordered">
                            <thead class="d-none"> <th scope="col"></th></thead>
                              <tbody>
                                <tr>
                                  <td class="text-left">
                                    Does this change have any dependency on other systems / services? If yes, which are those?
                                  </td>
                                  <td class="text-center" v-if="view.systems_services == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    Is this change reviewed and signed-off by dependent systems?
                                  </td>
                                  <td class="text-center" v-if="view.dependent_systems == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    Teams involved to execute the change?
                                  </td>
                                  <td class="text-center" v-if="view.teams_involved == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr> 
                                <tr>
                                  <td class="text-left">
                                    Are all stakeholders notified and Smart work Ticket opened to execute the change?
                                  </td>
                                  <td class="text-center" v-if="view.stakeholders_notified == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                          </div>
                      </div>
                  </div>
                  <div class="card open_oranges ">
                      <div class="card-header" id="paymentThree" data-toggle="collapse" role="button" data-target="#faq-payment-three" aria-expanded="false" aria-controls="faq-payment-three">
                          <span class="lead collapse-title">DB DEPLOYMENT</span>
                      </div>
                      <div id="faq-payment-three" class="collapse" aria-labelledby="paymentThree" data-parent="#faq-payment-three">
                          <div class="card-body">
                            <div class="mb-1 px-0" >
                      <div class="table-responsive" style="overflow-x: inherit !important;">
                          <table aria-describedby="deprn" id="deprn" class="table table-sm dataTableListing table-hover table-striped table-bordered">
                            <thead class="d-none"> <th scope="col"></th></thead>
                              <tbody>
                                <tr>
                                  <td class="text-left">
                                    Scripts Deployment in
                                  </td>
                                  <td class="text-center" v-if="view.scripts_deployment == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    Are you adding a new table?
                                  </td>
                                  <td class="text-center" v-if="view.adding_new_table == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    Adding a new column? If Yes then specify columns:
                                  </td>
                                  <td class="text-center" v-if="view.adding_new_column == 1">
                                    {{view.new_column_specify ? view.new_column_specify:'NA'}}
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr> 
                                <tr>
                                  <td class="text-left">
                                    Adding any index?
                                  </td>
                                  <td class="text-center" v-if="view.adding_any_index == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    Adding any new constraint to the running process/flow?
                                  </td>
                                  <td class="text-center" v-if="view.adding_new_constraint == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    Scripts Attached
                                  </td>
                                  <td class="text-center" v-if="view.scripts_attached == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    Rollback Scripts Attached
                                  </td>
                                  <td class="text-center" v-if="view.rollback_scripts_attached == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    DB Scripts
                                  </td>
                                  <td class="text-center" v-if="view.db_scripts == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    Archival process is required (New table)
                                  </td>
                                  <td class="text-center" v-if="view.archival_process == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    Downtime Required
                                  </td>
                                  <td class="text-center" v-if="view.downtime_required == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    Briefly explain the scripts that need to be executed by DBA. Please specify Smartwork Ticket id which are part of the change.
                                  </td>
                                  <td class="text-center" v-if="view.briefly_explain">
                                    {{view.briefly_explain}}
                                  </td>
                                  <td class="text-center" v-else>
                                    NA
                                  </td>
                                </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                          </div>
                      </div>
                  </div>
                  <div class="card card open_primary">
                      <div class="card-header" id="paymentTwo" data-toggle="collapse" role="button" data-target="#faq-payment-forth" aria-expanded="true" aria-controls="faq-payment-forth">
                          <span class="lead collapse-title">QC DEPENDENCY</span>
                      </div>
                      <div id="faq-payment-forth" class="collapse show" aria-labelledby="paymentTwo" data-parent="#faq-payment-forth">
                          <div class="card-body">
                            <div class="mb-1 px-0" >
                      <div class="table-responsive" style="overflow-x: inherit !important;">
                          <table aria-describedby="rndependancy" id="rndependancy" class="table table-sm dataTableListing table-hover table-striped table-bordered">
                            <thead class="d-none"> <th scope="col"></th></thead>
                              <tbody>
                                <tr>
                                  <td class="text-left">
                                    Is release unit tested ?
                                  </td>
                                  <td class="text-center" v-if="view.isunit_tested == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    Is test data has been created and share with QA team ?
                                  </td>
                                  <td class="text-center" v-if="view.isdata_shared_qateam == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else>
                                    NO
                                  </td>
                                </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                          </div>
                      </div>
                  </div>
                  <div class="card open_oranges ">
                    <div class="card-header" id="paymentfour" data-toggle="collapse" role="button" data-target="#faq-payment-four" aria-expanded="false" aria-controls="faq-payment-four">
                      <span class="lead collapse-title">Job Alert</span>
                    </div>
                    <div id="faq-payment-four" class="collapse" aria-labelledby="paymentfour" data-parent="#faq-payment-four">
                      <div class="card-body">
                        <div class="mb-1 px-0" >
                          <div class="table-responsive" style="overflow-x: inherit !important;">
                            <table aria-describedby="deprn" id="deprn" class="table table-sm dataTableListing table-hover table-striped table-bordered">
                              <thead class="d-none"> <th scope="col"></th></thead>
                              <tbody>
                                <tr>
                                  <td class="text-left">
                                    Are there Jobs in this release ?
                                  </td>
                                  <td class="text-center" v-if="view.isjobrelease == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else-if="view.isjobrelease == 0">
                                    NO
                                  </td>
                                  <td class="text-center" v-else>
                                    NA
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    Are job email error alert implemented ?
                                  </td>
                                  <td class="text-center" v-if="view.isjobmail == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else-if="view.isjobmail == 0">
                                    NO
                                  </td>
                                  <td class="text-center" v-else>
                                    NA
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-left">
                                    Are job call error alert implemented ?
                                  </td>
                                  <td class="text-center" v-if="view.jobcallalert == 1">
                                    Yes
                                  </td>
                                  <td class="text-center" v-else-if="view.jobcallalert == 0">
                                    NO
                                  </td>
                                  <td class="text-center" v-else>
                                    NA
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
      
      <div class="modal fade" id="assignedToRNotePopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div class="modal-content">
                  <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                  <h4 class="modal-title">Approve Release Note Request</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closed()">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  </div>
                  <div class="modal-body sm-overflow_scroll_div">
                  <div class="row">
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Code</label>
                          <input class="form-control" type="text" v-bind:value="approvalAssignTo.rnotecode" disabled/>
                      </div>
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Version</label>
                          <input class="form-control" type="text" v-bind:value="approvalAssignTo.rnversion" disabled/>
                      </div>
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Created By</label>
                          <input class="form-control" type="text" v-bind:value="approvalAssignTo.createdby" disabled/>
                      </div>
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Created Date</label>
                          <input class="form-control" type="text" v-bind:value="dateFormat(approvalAssignTo.createddate)" disabled/>
                      </div>
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Approved By</label>
                          <input class="form-control" type="text" v-bind:value="approvalAssignTo.approvedby" disabled/>
                      </div>
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Approved Date</label>
                          <input class="form-control" type="text" v-bind:value="dateFormat(approvalAssignTo.approveddate)" disabled/>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                          <label class="form-label lable-left">Assign To Devops</label>
                          <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Assign To" v-model="approvalAssignTo.assignto" :options="infrateamnamelist"/>
                          <div class="errorinputmsg" v-if="approvalAssignTo.assigntoErr">Assign To Devops is required.</div> 
                      </div>
                      <div class="form-group col-md-12">
                          <label class="form-label">Comment</label>
                          <textarea class="form-control" type="text" v-model="approvalAssignTo.comment" placeholder="Write comment here..."></textarea>
                          <div class="errorinputmsg" v-if="approvalAssignTo.commentErr">Comment is required.</div> 
                      </div>
                      <div class="form-group col-md-2">
                          <button type="button" id="approvebt" class="btn btn-primary" v-on:click="approvedRN(approvalAssignTo, 'Pending for Deployement')">Submit</button>
                      </div>
                  </div>
                  </div>    
              </div>
          </div>
      </div>
      <div class="modal fade" id="deployedToRNotePopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div class="modal-content">
                  <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                  <h4 class="modal-title">Deploy Release Note</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closed()">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  </div>
                  <div class="modal-body sm-overflow_scroll_div">
                  <div class="row">
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Code</label>
                          <input class="form-control" type="text" v-bind:value="deployingRNData.rnotecode" disabled/>
                      </div>
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Version</label>
                          <input class="form-control" type="text" v-bind:value="deployingRNData.rnversion" disabled/>
                      </div>
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Created By</label>
                          <input class="form-control" type="text" v-bind:value="deployingRNData.createdby" disabled/>
                      </div>
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Created Date</label>
                          <input class="form-control" type="text" v-bind:value="dateFormat(deployingRNData.createddate)" disabled/>
                      </div>
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Approved By</label>
                          <input class="form-control" type="text" v-bind:value="deployingRNData.approvedby" disabled/>
                      </div>
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Approved Date</label>
                          <input class="form-control" type="text" v-bind:value="dateFormat(deployingRNData.approveddate)" disabled/>
                      </div>
                      <div class="form-group col-md-12">
                          <label class="form-label">Comment</label>
                          <textarea class="form-control" type="text" v-model="deployingRNData.comment" placeholder="Write comment here..."></textarea>
                          <div class="errorinputmsg" v-if="deployingRNData.commentErr">Comment is required.</div> 
                      </div>
                      <div class="form-group col-md-2">
                          <button type="button" id="approvebt" class="btn btn-primary" v-on:click="approvedRN(deployingRNData, 'Deployed')">Submit</button>
                      </div>
                  </div>
                  </div>    
              </div>
          </div>
      </div>
      
      <div class="modal fade" id="approvedRNRequest" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div class="modal-content">
                  <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                  <h4 class="modal-title">Approve Release Note Request</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closed()">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  </div>
                  <div class="modal-body sm-overflow_scroll_div">
                  <div class="row">
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Code</label>
                          <input class="form-control" type="text" v-bind:value="approavalData.rnotecode" disabled/>
                      </div>
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Version</label>
                          <input class="form-control" type="text" v-bind:value="approavalData.rnversion" disabled/>
                      </div>
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Created By</label>
                          <input class="form-control" type="text" v-bind:value="approavalData.createdby" disabled/>
                      </div>
                      <div class="form-group col-md-6">
                          <label class="form-label">Release Note Created Date</label>
                          <input class="form-control" type="text" v-bind:value="dateFormat(approavalData.createddate)" disabled/>
                      </div>
                      <div class="form-group col-md-12">
                          <label class="form-label">Comment</label>
                          <textarea class="form-control" type="text" v-model="approavalData.comment" placeholder="Write comment here..."></textarea>
                          <div class="errorinputmsg" v-if="approavalData.commentErr">Comment is required.</div> 
                      </div>
                      <div class="form-group col-md-2">
                          <button type="button" id="approvebt" class="btn btn-primary" v-on:click="approvedRN(approavalData, 'Approved')">Approved</button>
                      </div>
                  </div>
                  </div>    
              </div>
          </div>
      </div>

    </section>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import apiUrl from '../../constants';
import moment from "moment";
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Trash2Icon,EditIcon, DownloadIcon, ZoomInIcon, MonitorIcon, PlusSquareIcon,EyeIcon, Edit2Icon, PlusIcon, MinusSquareIcon, ClipboardIcon, PenToolIcon, PocketIcon, CodepenIcon, UserIcon, CalendarIcon,WatchIcon, PaperclipIcon, SettingsIcon, UserPlusIcon, XIcon } from 'vue-feather-icons';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import profilepic from '../profilepic.vue';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import commonMethods from '../../utils/commonMethods';

export default {
    name:"ViewReleaseNote",
    components:{
      VueTimepicker,
      Loading,PlusSquareIcon,DownloadIcon,ZoomInIcon,Trash2Icon,XIcon,
      VueElementLoading,
      WatchIcon,
      vSelect,
      EditIcon,
      EyeIcon,
      Edit2Icon,
      Treeselect,
      DatePicker,
      PlusIcon,
      CalendarIcon,
      MinusSquareIcon,
      ClipboardIcon,
      UserPlusIcon,
      PenToolIcon, PocketIcon, CodepenIcon, UserIcon, PaperclipIcon, SettingsIcon,MonitorIcon,
      ckeditor: CKEditor.component,
      profilepic
    },
    mixins: [ commonMethods ],
    data() {
      return {
        isoldreleasenote: null,
        isLoading: false,
        isLoadingBTCDATA: false,
        fullPage: true,
        isRequestActiveView: true,
        editRowData:[],
        PMroleid: apiUrl.PMroleid,
        EMroleid: apiUrl.EMroleid,
        TimeFormat: apiUrl.TimeFormat,
        view:{
            releasenotemasterid: null,
            project: null, 
            version: null, 
            startdate: null,
            enddate: null,
            approvedby: null, 
            engmanname: null,
            promanname: null,
            qaleadname: null,
            baname: null,
            reponame: null, 
            jobname: null, 
            createdby: null, 
            createddate: null, 
            empid: null, 
            releasenotecode: null, 
            releasedate: null, 
            releasestatus: null, 
            lastmodifiedby: null, 
            lastmodifieddate: null,
            briefly_explain: null,
            approveddate: null,
            releasedby: null,
            deployedby: null,
            lower_environment: null,
            tested_documented: null,
            backward_compatibility: null,
            if_deployment_fails: null,
            systems_services: null,
            dependent_systems: null,
            teams_involved: null,
            stakeholders_notified:null,
            scripts_deployment: null,
            adding_new_table: null,
            new_column_specify: null,
            adding_new_column: null,
            adding_any_index: null,
            adding_new_constraint: null,
            scripts_attached: null,
            rollback_scripts_attached: null,
            db_scripts: null,
            archival_process: null,
            downtime_required: null,
            apprvedbyemail: null,
            assigntoemail: null,
            assignstatus: null,
            assignto: null,
            comment: null, 
            assigntocomment: null, 
            deployementcomment: null,
            taskmasterid: null,
            applicationname: null,
            isunit_tested: null,
            isdata_shared_qateam: null,
            isjobrelease: null,
            isjobmail: null,
            jobcallalert: null
        },
        isRequestActive:false,
        card_content:'card-content',
        collapse:'collapse',
        card:'card',
        border_warning:'border-warning',
        loginusername:'',
        tokendata: null,
        loginpersonname:null,
        featureslist: [],
        readmore:'outer',
        approavalData : {
            rnotecode : null,
            rnversion: null,
            createdby: null,
            createddate: null,
            rid: null,
            comment: null,
            commentErr: null,
        },
        approvalAssignTo: {
          assignto: null,
          approvedby: null,
          approveddate: null,
          rnotecode : null,
          rnversion: null,
          createdby: null,
          createddate: null,
          rid: null,
          comment: null,
          commentErr: null,
          assigntoErr: null,
          releasenotecode: null,
        },
        deployingRNData: {
          approvedby: null,
          approveddate: null,
          rnotecode : null,
          rnversion: null,
          createdby: null,
          createddate: null,
          rid: null,
          comment: null,
          commentErr: null,
        },
        infrateamnamelist: [],
        loginuscondition: null,
        subscriber: null,
        subscriberrolename: null,
        requesterArr : [],
        roleapprovaldetails: [],
        loginuserapprovaldetails: [],
        approvaltaskdetails: [],
      }
    },    
    mounted(){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata){
          userdata = JSON.parse(userdata)
          this.undt=userdata
          this.tokendata = window.localStorage.getItem('token');
          this.loginuscondition = userdata.userid
          this.loginusername = userdata.username
          this.loginpersonname=userdata.firstname+' '+userdata.lastname;
          this.getReleaseNoteByViewId(this.$route.query.releasenoteid);
      }
    },
    methods:{
        redirecttourl:function(btnstate,rowdata){
          if(rowdata){
            rowdata.backbtnstate=btnstate
            if(btnstate=="view"){
              let routeData = this.$router.resolve({ 
                path: `/tasks/view/?taskid=${rowdata.taskmasterid}`,
                params: {
                  backbtnstate:btnstate,
                  clickeddata:rowdata
                }
              })
              window.open(routeData.href, '_blank');
            }
          }
        },
        getEngManByPro(proid, roleid) {
            axios({
                  method: "POST",
                  url: "api/releasenote/getEMforreleasenote",
                  headers: { authorization : this.tokendata },
                  data:{roleid:roleid, projectidarr:[proid], useremail: this.undt.username,empcode: this.undt.userid}
              }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                      this.infrateamnamelist = result.data.data.rows
                  }else{
                      this.infrateamnamelist = [];
                  }
              }).catch(e => {
            this.displayError(e)
          });
        },
        idclick5(){
            
            if ($("#cycle_readmore5").hasClass("readmore")) {
                $("#id5").html("Read More");
                $("#cycle_readmore5").removeClass("readmore");
                this.readmore = 'outer'
            } else {
                $("#id5").html("Read Less");
                $("#cycle_readmore5").addClass("readmore");
                this.readmore = 'outer readmore'
            }
        },
        reqToAssignedToRNote(rdata){
          this.approvalAssignTo.rnotecode = rdata.releasenotecode;
          this.approvalAssignTo.rnversion = rdata.version;
          this.approvalAssignTo.createdby = rdata.createdby;
          this.approvalAssignTo.createddate = rdata.createddate;
          this.approvalAssignTo.rid = this.$route.query.releasenoteid;
          this.approvalAssignTo.approvedby = rdata.apprvedbyemail;
          this.approvalAssignTo.approveddate = rdata.approveddate;
          this.approvalAssignTo.releasenotecode = rdata.releasenotecode;
          this.approvalAssignTo.projectid = rdata.projectid;
          this.approvalAssignTo.version = rdata.version;
          $('#assignedToRNotePopup').modal('show');
        },
        reqToApprovedRNote(rdata) {
            this.approavalData.rnotecode = rdata.releasenotecode;
            this.approavalData.rnversion = rdata.version;
            this.approavalData.createdby = rdata.createdby;
            this.approavalData.createddate = rdata.createddate;
            this.approavalData.projectid = rdata.projectid;
            this.approavalData.rid = this.$route.query.releasenoteid;
            $('#approvedRNRequest').modal('show');
        },
        reqToDeployedRNote(rdata) {
          this.deployingRNData.rnotecode = rdata.releasenotecode;
          this.deployingRNData.rnversion = rdata.version;
          this.deployingRNData.createdby = rdata.createdby;
          this.deployingRNData.createddate = rdata.createddate;
          this.deployingRNData.rid = this.$route.query.releasenoteid;
          this.deployingRNData.approvedby = rdata.apprvedbyemail;
          this.deployingRNData.projectid = rdata.projectid;
          this.deployingRNData.approveddate = rdata.approveddate;
          this.deployingRNData.releasenotecode = rdata.releasenotecode;
          this.deployingRNData.projectid = rdata.projectid;
          this.deployingRNData.version = rdata.version;
          this.deployingRNData.taskmasterid = rdata.taskmasterid;
          $('#deployedToRNotePopup').modal('show');
        },
        redirecttoTask(rdata){
          let routeData = this.$router.resolve({ 
            path: `/tasks/view/?taskid=${rdata.taskmasterid}`,
            params: {
              clickeddata:rdata
            }
          })
          window.open(routeData.href, '_blank');
        },
        closed(){
            $('#approvedRNRequest').modal('hide');
            this.approavalData.rnotecode = null;
            this.approavalData.rnversion = null;
            this.approavalData.createdby = null;
            this.approavalData.createddate = null;
            this.approavalData.rid = null;
            this.approavalData.comment = null;
            this.approavalData.commentErr = null;
            this.approvalAssignTo = {
              assignto: null,
              approvedby: null,
              approveddate: null,
              rnotecode : null,
              rnversion: null,
              createdby: null,
              createddate: null,
              rid: null,
              comment: null,
              commentErr: null,
              assigntoErr: null,
            }
            this.deployingRNData = {
              approvedby: null,
              approveddate: null,
              rnotecode : null,
              rnversion: null,
              createdby: null,
              createddate: null,
              rid: null,
              comment: null,
              commentErr: null,
            }
        },
        approvedRN(approveddata, status) {
            let isvalid = true;
            if(approveddata.comment == '' || approveddata.comment == null && status == 'Approved'){
                this.approavalData.commentErr = 'Comment is required.';
                isvalid = false;
            }else{
                this.approavalData.commentErr = null;
            }
            if(approveddata.comment == '' || approveddata.comment == null && status == 'Pending for Deployement'){
                this.approvalAssignTo.commentErr = 'Comment is required.';
                isvalid = false;
            }else{
                this.approvalAssignTo.commentErr = null;
            }
            if(approveddata.comment == '' || approveddata.comment == null && status == 'Deployed'){
                this.deployingRNData.commentErr = 'Comment is required.';
                isvalid = false;
            }else{
                this.deployingRNData.commentErr = null;
            }
            if(approveddata.assignto == null && status == 'Pending for Deployement'){
                this.approvalAssignTo.assigntoErr = 'Assign To Devops is required.';
                isvalid = false;
            }else{
                this.approvalAssignTo.assigntoErr= null;
            }
            let payload = null;
            if(status == 'Pending for Deployement'){
              payload = { rid: parseInt(approveddata.rid), assignto: approveddata.assignto ,comment: approveddata.comment, status: status, empcode: this.undt.userid, isoldreleasenote:this.isoldreleasenote, useremail: this.undt.username, projectid: approveddata.projectid, releasenotecode: approveddata.releasenotecode, version: approveddata.version, businesstasmasterkid: (this.featureslist.length>0)? this.featureslist[0].businesstasmasterkid: null, createdemaildata:this.view ,subscriber:this.subscriber,createdid:this.editRowData.empid };
            }else{
              payload = { rid: parseInt(approveddata.rid), comment: approveddata.comment, status: status, empcode: this.undt.userid, isoldreleasenote:this.isoldreleasenote, useremail: this.undt.username, projectid: approveddata.projectid, createdemaildata:this.view, businesstasmasterkid: (this.featureslist.length>0)? this.featureslist[0].businesstasmasterkid: null, releasenotecode: approveddata.releasenotecode, version: approveddata.version, taskmasterid: approveddata.taskmasterid, subscriber:this.subscriber, createdid:this.editRowData.empid };
            }

            if(status == 'Deployed') {
              payload.requesterarr = this.requesterArr;
            }
            if(isvalid){
                axios({
                    method: "POST",
                    url: "api/releasenote/approvedRNRequest",
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                      if(status == 'Pending for Deployement'){
                        $('#assignedToRNotePopup').modal('hide');
                        this.approvalAssignTo = {};
                      }else if(status == 'Deployed'){
                        $('#deployedToRNotePopup').modal('hide');
                        this.deployingRNData = {};
                      }else{
                        $('#approvedRNRequest').modal('hide');
                        this.approavalData = {};
                      }
                      Swal.fire({
                          title: "Success!",
                          text: result.data.msg,
                          icon: 'success',
                          customClass: {
                              confirmButton: "btn btn-primary"
                          },
                          buttonsStyling: !1
                      })
                      this.getReleaseNoteByViewId(approveddata.rid);
                    }
                     else if(result.data.errorCode == 3){
                    Swal.fire({
                          title: "Session Expired...!!",
                          text: result.data.msg,
                          icon: 'info',
                          customClass: {
                              confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: !1,
                      }).then(function() {
                        window.location.href = "/#/login";
                    })
                  } 
                }).catch(e => {
          this.displayError(e)
        });
            }
        },
        getReleaseNoteByViewId(id){
            this.isLoadingBTCDATA = true;
            let url = "api/releasenote/getReleaseNoteByViewId";
            this.input = {
                releasenotemasterid: id,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoadingBTCDATA = false;
                if (result.data.errorCode == 0) {
                  this.editRowData = result.data.data.rows;
                  this.editRowData = this.editRowData[0];
                  this.isoldreleasenote = this.editRowData.isoldreleasenote;
                  this.getbyidtabledata(this.$route.query.releasenoteid);
                  this.openEditModule(this.editRowData);
                } else {
                  this.editRowData = [];
                  this.$router.push({ name: 'ListReleaseNote', params: { search: true }});
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        findroles(id) {
          this.input = {
                releasenotemasterid: id,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                moduletype:'releasenote',
                projectid: parseInt(this.editRowData.projectid)
            };
            axios({
                method: "POST",
                url: "api/releasenote/findroles",
                headers: { authorization : this.tokendata },
                data: this.input
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                  this.subscriber = Array.from(result.data.data, function (item) {
                    if (item.roleid !== null) {
                        return (item.roleid)
                    }else{

                    }
                  });
                  this.subscriberrolename = Array.from(result.data.data, function (item) {
                    if (item.roleid !== null) {
                        return (item.rolename)
                    }else{

                    }
                  });
                }else{
                    this.subscriber = null;
                    this.subscriberrolename = null;
                }
            });
        },
        openEditModule(currRowData){
            this.view.releasenotecode=currRowData.releasenotecode;
            this.view.createdby = currRowData.createdby;
            this.view.version = currRowData.version;
            this.view.startdate = currRowData.startdate;
            this.view.enddate = currRowData.enddate;
            this.view.jobname=currRowData.jobname;
            this.view.reponame=currRowData.reponame;
            this.view.briefly_explain=currRowData.briefly_explain;
            this.view.project=currRowData.projectname;
            this.view.projectid=currRowData.projectid;
            this.view.releasedby=currRowData.releasedby;
            this.view.releasedate=currRowData.releasedate;
            this.view.approvedby=currRowData.approvedby;
            this.view.engmanname=currRowData.engmanname;
            this.view.promanname=currRowData.promanname;
            this.view.qaleadname=currRowData.qaleadname;
            this.view.baname=currRowData.baname;
            this.view.approveddate=currRowData.approveddate;
            this.view.deployedby=currRowData.deployedby;
            this.view.apprvedbyemail = currRowData.apprvedbyemail;
            this.view.assigntoemail = currRowData.assigntoemail;
            this.view.assignto = currRowData.assignto;
            this.view.assignstatus = currRowData.assignstatus;
            this.view.comment = currRowData.comment; 
            this.view.assigntocomment = currRowData.assigntocomment; 
            this.view.deployementcomment = currRowData.deployementcomment;
            this.view.releasedby = currRowData.releasedby;
            this.view.createddate=currRowData.createddate;
            this.view.lastmodifiedby=currRowData.lastmodifiedby;
            this.view.lastmodifieddate=currRowData.lastmodifieddate;
            this.view.releasestatus=currRowData.releasestatus;
            this.view.lower_environment=currRowData.lower_environment;
            this.view.tested_documented=currRowData.tested_documented;
            this.view.backward_compatibility=currRowData.backward_compatibility;
            this.view.if_deployment_fails=currRowData.if_deployment_fails;
            this.view.systems_services=currRowData.systems_services;
            this.view.dependent_systems=currRowData.dependent_systems;
            this.view.teams_involved=currRowData.teams_involved;
            this.view.stakeholders_notified=currRowData.stakeholders_notified;
            this.view.scripts_deployment=currRowData.scripts_deployment;
            this.view.adding_new_table=currRowData.adding_new_table;
            this.view.new_column_specify=currRowData.new_column_specify;
            this.view.adding_new_column=currRowData.adding_new_column;
            this.view.adding_any_index=currRowData.adding_any_index;
            this.view.adding_new_constraint=currRowData.adding_new_constraint;
            this.view.scripts_attached=currRowData.scripts_attached;
            this.view.rollback_scripts_attached=currRowData.rollback_scripts_attached;
            this.view.db_scripts=currRowData.db_scripts;
            this.view.archival_process=currRowData.archival_process;
            this.view.isunit_tested=currRowData.isunit_tested;
            this.view.isdata_shared_qateam=currRowData.isdata_shared_qateam;
            this.view.downtime_required=currRowData.downtime_required;
            this.view.taskmasterid = currRowData.taskmasterid;
            this.getEngManByPro(currRowData.projectid, [475, 491]);
            this.findroles(currRowData.releasenotemasterid);
            this.view.applicationname = currRowData.applicationname;
            this.view.isjobrelease = currRowData.isjobrelease;
            this.view.isjobmail = currRowData.isjobmail;
            this.view.jobcallalert = currRowData.jobcallalert;
            if(currRowData.isoldreleasenote == 0) {
              this.getApprovalDetails(currRowData);
            }
        },
        getApprovalDetails(currRowData) {
            let roleidd = null;
            if(this.editRowData.approvedby == this.loginuscondition) {
              roleidd = this.EMroleid;
            } else if (this.editRowData.projectmanager == this.loginuscondition) {
              roleidd = this.PMroleid;
            }

            this.input = {
                releasenotemasterid: currRowData.releasenotemasterid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: "api/releasenote/getApprovalDetails",
                headers: { authorization : this.tokendata },
                data: this.input
            }).then((result) => {
                if (result.data.errorCode == 0) {
                    this.roleapprovaldetails = result.data.data.rows;
                    this.loginuserapprovaldetails = result.data.data.rows.filter((obj) => 
                      obj.userroleid == roleidd
                    )[0];
                    this.approvaltaskdetails = result.data.data.taskdetails;
                }else{
                    this.roleapprovaldetails = [];
                    this.loginuserapprovaldetails = [];
                    this.approvaltaskdetails = [];
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getbyidtabledata(id) {
          this.input = {
                releasenotemasterid: id,
                useremail: this.undt.username,
                empcode: this.undt.userid
            };
            axios({
                method: "POST",
                url: "api/releasenote/getbyidtabledata",
                headers: { authorization : this.tokendata },
                data: this.input
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.featureslist = result.data.data;
                    result.data.data.forEach(element => {
                      if(element.requester && element.requester != null) {
                        this.requesterArr.push(element.requester)
                      }
                    });
                }else{
                    this.featureslist = [];
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        capitalletterfun(value) {
          if (!value) return "NA";
          const arr = value.split(" ");
          for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
          }
          return arr.join(" ");
        },
        showprojectname(code){
        let responsestrs='';
        let prlists=code.split(',')
        if(prlists.length > 1){
            for(let i=0;i<prlists.length;i++){
                responsestrs +=(i+1)+'. '+prlists[i]+' <br/>'
            }
        }else{
            for(let i=0;i<prlists.length;i++){
                responsestrs +=prlists[i]
            }
        }
        return responsestrs
        },
        myrequestollapse() {
          this.disRequestActive=!this.disRequestActive
        },
        handleCancel() {
          $('html, body').animate({ scrollTop: 0 }, 1200);
          this.myrequestollapse()
        },
        handleLogCancel() {
            $('html, body').animate({ scrollTop: 0 }, 1200);
            this.dislogActive=true
            this.isLogActive=false
            this.logtime.comment = ''
        },
        dateMONFormat(value){
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:mm');
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        formatData(data) {
            return data
        },
    },
    filters: {
    basename: function (value) {
      if (!value) return ''
      return value.substr(value.lastIndexOf("/") + 1)
    }
  },  
}
</script>
<style>

.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
