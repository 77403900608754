<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-10 mobile_margin-px-0_00">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1">Search User Activity Details</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                        <em class="las la-angle-down"></em>
                      </span>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                        <div class="">
                            <label for="allotedto">User Name</label>
                            <treeselect class="allotedto capitalisetext" placeholder="Select User Name" v-model="search.allotedto" :options="search.allotedtolist" @input="selassignedto"/>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <div class="">
                        <label for="">From Date</label>
                        <div class="input-group input-group-merge">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><user-icon size="1.5x" class="custom-class avatar-icon"></user-icon></span>
                          </div>
                          <date-picker placeholder="Select From Date" v-model="search.taskassigneddatefrom" valueType="format" class="Startdate" ></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <div class="">
                        <label for="">To Date</label>
                        <div class="input-group input-group-merge">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><user-icon size="1.5x" class="custom-class avatar-icon"></user-icon></span>
                          </div>
                          <date-picker placeholder="Select To Date" v-model="search.taskassigneddateto" valueType="format" class="enddate" :default-value="new Date()" :disabled-date="disabledBefore"></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-1 text-right">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getUserActivityReport(true,search)"><search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                      <button type="move" class="btn btn-relief-primary mt-2 " @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="reportTopScroll">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                    <h4 class="card-title">
                      User Activity Report
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div
                        class=""
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              <div class="table-responsive_sitcky"  v-if="showtabledata">
                                <table class="table table-hover table-striped dataTable table-sm dataTableListing" aria-describedby="User Activity Report">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="srno align-middle">Sr#</th>
                                      <th scope="col" class="align-middle">Emp Name</th>
                                      <th scope="col" class="align-middle">Emp ID</th>
                                      <th scope="col" class="align-middle">Date</th>
                                      <th scope="col" class="align-middle">Application Name</th>
                                       <th scope="col" class="align-left">Title</th>
                                      <th scope="col" class="align-middle">Time Spend</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(data, index) in companymasterlist">
                                      <tr v-bind:key="index+Math.floor(Math.random() * 1000000000)"> 
                                        <td class="srno">{{ index+1+currPageNum*pagelimit }}</td>                                       
                                        <td :class="' unique'+index+1+currPageNum*pagelimit">
                                          {{data.empname}}
                                        </td>
                                        <td class="">
                                            {{data.empid}}
                                        </td>
                                         <td class="capitalisetext">
                                          {{data.activitytime?dateMONFormat(data.activitytime):''}}
                                        </td>
                                        
                                        <td class="capitalisetext">
                                          {{data.client ? data.client:'NA'}}
                                        </td>
                                        <td class="capitalisetext">
                                          {{data.title ? data.title:'NA'}}
                                        </td>
                                        <td class="capitalisetext">
                                          {{data.totalduration ? data.totalduration:'NA'}}
                                        </td>
                                      </tr>
                                    </template>
                                    <template v-if="companymasterlist.length == 0 && isresponce">
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2">
                      <pagination
                        v-if="showtabledata"
                        v-model="page"
                        :records="totalcount"
                        :per-page="pagelimit"
                        @paginate="clickCallback"
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
input.mx-input::-webkit-input-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input:-moz-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input.mx-input{
  color: #000 !important
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { SearchIcon,EyeIcon,XIcon,ExternalLinkIcon,UserIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "UserActivityReport",
  mixins: [ commonMethods ],

  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
    UserIcon,
    DatePicker,
  },
  data() {
    return {
      projectnameList:[],
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      showprojectdata: [],
      showroledata: [],
      showempdata: [],      
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      search:{
          searchProjectname :null,
          searchTaskStatus:null,
          allotedto:null,
          allotedtolist:[],
          taskassigneddateto:null,
          taskassigneddatefrom:null,
          mngrname:null
          
      },
      tokendata:null,
      mngrlist:[],
      isresponce:false,
    };
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem("token");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.getUserActivityReport()
        this.getEmpListByProjectAssigned(parseInt(this.undt.userid))
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    disabledBefore(date) {
      let dayBefore = moment(this.search.taskassigneddateto).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
            beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    
    getUserActivityReport(flag = false,...search){
      if(flag) {
        this.pageoffset = 1
        this.companymasterlist = []
        this.totalcount = 0;
        this.pageCount = 0;
        this.page= 1;
      } 
       
        this.isLoading = true;
        let url = "api/userActivity/getActivityDetails";
        this.input = {
            offset: this.pageoffset,
            limit: apiUrl.LIMIT,
           
        };
        let assignedate =""
        if(this.search.taskassigneddateto){
          assignedate = new Date(this.search.taskassigneddateto)
          assignedate.setDate(assignedate.getDate() + 1)
          moment(String(assignedate)).format('YYYY-MM-DD');
        }
        var datefrom = new Date()
        var dateto = new Date()
        if(!this.search.taskassigneddatefrom && !this.search.taskassigneddateto){
          dateto.setDate(dateto.getDate())
          dateto = moment(String(dateto)).format('YYYY-MM-DD 23:59:59');

          datefrom.setDate(datefrom.getDate() -1)
          datefrom =moment(String(datefrom)).format('YYYY-MM-DD');
        }
        this.input.empid = this.search.allotedto?this.search.allotedto:""
        this.input.datefrom = this.search.taskassigneddatefrom?this.search.taskassigneddatefrom:datefrom
        this.input.dateto = this.search.taskassigneddateto?assignedate:dateto
        this.input.useremail = this.undt.username,
        this.input.empcode = this.undt.userid
        
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
          this.companymasterlist = result.data.data.responsedata;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = 0;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    
    resetRecords: function () {
        this.search.searchProjectname = null;
        this.search.searchTaskStatus = null;
        this.search.allotedto = null;
        this.search.taskassigneddateto = null;
        this.search.taskassigneddatefrom = null;
        this.search.mngrname=null;
        let rowdata={}
        this.getUserActivityReport(rowdata);
        localStorage.removeItem('searchItems')
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.parentmoduledata={}
        this.parentmoduledata.isPaginationreq=true
        this.getUserActivityReport(false,this.parentmoduledata);
      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#reportTopScroll").offset().top -70
            });
        }, 500);
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateMONFormat(value){
        if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
    }, 
    getEmpListByProjectAssigned: function (empid) {
      this.pageoffset=1
      axios({
        'method': 'POST',
        'url': 'api/listing/getAllEmpDetailsWithEMPID',
        'data': {
          empid:null,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        },
         'headers':{'authorization':this.tokendata}
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.search.allotedtolist = result.data.data
        } else {
          this.search.allotedtolist = null
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    selassignedto(state, value) {
      if(!state) {
        this.search.allotedto=null
      } else {
        this.search.allotedto=state
      }
    },

    ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input.limit=null;
      this.input.offset=null;
      let assignedate =""
      if(this.search.taskassigneddateto){
        assignedate = new Date(this.search.taskassigneddateto)
        assignedate.setDate(assignedate.getDate() + 1)
        assignedate = moment(String(assignedate)).format('YYYY-MM-DD');
      }
      var datefrom = new Date()
      var dateto = new Date()
      if(!this.search.taskassigneddatefrom && !this.search.taskassigneddateto){
        dateto.setDate(dateto.getDate())
        dateto = moment(String(dateto)).format('YYYY-MM-DD');

        datefrom.setDate(datefrom.getDate() -1)
        datefrom =moment(String(datefrom)).format('YYYY-MM-DD');
      }
      this.input.empid = this.search.allotedto?this.search.allotedto:""
      this.input.datefrom = this.search.taskassigneddatefrom?this.search.taskassigneddatefrom:datefrom
      this.input.dateto = this.search.taskassigneddateto?assignedate:dateto
      this.input.useremail = this.undt.username,
      this.input.empcode= this.undt.userid,
      axios({
        method: "POST",
        url: `api/userActivity/getActivityDetails`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
            this.exportxlsdata = result.data.data.responsedata;
            var filename = "";
            this.download(this.exportxlsdata,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          var ShowLabel = 'EmployeeActivityReport'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
            if (ShowLabel) {
                let row = ''
                for (let index in arrData[0]) {
                    row += index + ','
                }
                row = row.slice(0, -1)
                CSV = 'Sr#,EMP NAME	,EMP ID	,DATE,APPLICATION NAME,TITLE,TIME SPEND' + '\r\n'
            }
            var row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let empname = index.hasOwnProperty('empname') ? index.empname ==null || index.empname == 'NA' ? '' : index.empname: '';
            let activitytime = index.hasOwnProperty('activitytime') ? index.activitytime == null || index.activitytime == 'NA' ? '' : index.activitytime : '';
            let empid = index.hasOwnProperty('empid') ? index.empid == null || index.empid == 'NA' ? '' : index.empid : '';
            let client = index.hasOwnProperty('client') ? index.client == null?'' :index.client : '';
            let title = index.hasOwnProperty('title') ? index.title == null?'' :index.title.replace(/"/g, '""') : '';
            let totalduration = index.hasOwnProperty('totalduration') ? index.totalduration == null || index.totalduration == 'NA' ? '' : index.totalduration : '';
            
            activitytime = this.dateMONFormat(activitytime)
                row += '"' + srno + '",',
                row += '"' + empname + '",',
                row += '"' + empid + '",',
                row += '"' + activitytime + '",',
                row += '"' + client + '",',
                row += '"' + title + '",',
                row += '"' + totalduration + '",',
                row += '\r\n'
            }
            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            return;
            }
            var fileName = 'ActivityReport'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            var link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
                title: "Success!",
                text: "Export Report successful",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });    
        }
    },
    export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
  },
};
</script>
