<template>
    <div class="app-content content">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section class="pb-1" id="card-navigation modern-horizontal-wizard">
                <div class="content-header-left mb-1 pt-0 ">
                    <div class="container-fluid">
                        <div class="row breadcrumbs-top">
                            <div class="col-md-9 content-header-left px-0 ">
                                <h3 class="content-header-title float-left mb-0">My Approval</h3>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item sitebluecolor cursorpointercls"><router-link
                                                to='/myapproval'>MyApproval List</router-link></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-warning filter_card_searchbgst">
                    <div class="card-header filter_rotation_onlick" style="cursor:pointer"
                        v-on:click="mysearchrequestollapse">
                        <div class="container-fluid">
                            <div class="row">
                                <div class=" w-auto px-1 mobile-px-0">
                                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                                        <div class="avatar-content">
                                            <search-icon size="1.5x"
                                                class="custom-class avatar-icon font-medium-3"></search-icon>
                                        </div>
                                    </div>
                                    <h4 class="card-title d-inline-flex ml-1">Search MyApproval Details</h4>
                                </div>
                                <div class="ml-auto d-flex">
                                    <span class="rotetions_view_arrow ml-auto mx-1">
                                        <em class="las la-angle-down"></em>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="card-body" v-bind:class="[{ show: isSearchRequestActive }, card_content, collapse]">
                            <div class="card-body p-0">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 mb-1">
                                        <label>Stockist Name</label>
                                        <treeselect v-model="search.selectedStock" :options="stockOptions" :multiple="true"
                                            placeholder="Select Stockist Name" />
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 mb-1">
                                        <label>User Name</label>
                                        <treeselect v-model="search.selectedUser" :options="userOptions" :multiple="true"
                                            placeholder="Select User Name" />
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-4 text-right">
                                    <button type="submit" class="btn btn-primary mt-2 mr-1">
                                        <search-icon size="1.5x" class="custom-class " style="margin-right:5px;"></search-icon>
                                        <span>Search</span></button>
                                    <button type="reset" class="btn btn-outline-secondary mt-2 " @click="resetRecords()">
                                        <x-icon size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="row">
                <div class="col-md-12">
                  <div class="">
                    <div class=" ">
                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                          <div class="row" id="table-small">
                            <div class="col-12">
                              <div class="card">
                                <div class="table-responsive datatableview">
                                  <table class="table table-sm dataTableListing table-hover table-striped 
                                  all_table_stickys" aria-describedby="mydesc">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col"
                                                                    class="align-middle py-1 first-col-sticky text-left" ><input type="checkbox" class="mr-1" v-model="selectAll" @change="toggleAll">
                                                                       SR#</th>
                                                                        <th scope="col" class="align-middle second-col-sticky" style="min-width:110px;">
                                                                        Stockist Name</th>
                                                                    <th scope="col" class="align-middle" >
                                                                        User Name</th>
                                                                    <th scope="col" class="align-middle">
                                                                        Is Approved</th>
                                                                    <th scope="col" class="align-middle" >
                                                                        Approved By</th>
                                                                    <th scope="col" class="align-middle">
                                                                        Approved Date</th>
                                                                       
                                                                                                                        </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="record in records" :key="record.id">
                                                                    <td data-column="SR#" class="first-col-sticky centeralign text-left py-1"><input
                                                                            type="checkbox" class="mr-1" v-model="record.isSelected"> {{ record.userId }}
                                                                    </td>

                                                                   
                                                                    <td data-column="Stockist Name"
                                                                        class="align-middle second-col-sticky">
                                                                        {{ record.stockName }}</td>

                                                                        <td data-column="User Name"
                                                                        class="align-middle ">
                                                                        {{ record.userName }}</td>
                                                                    <td data-column="Is Approved"
                                                                        class="align-middle">
                                                                        {{ record.isapproved }}</td>
                                                                    <td data-column="Approved By"
                                                                        class="align-middle">
                                                                        {{ record.approvedBy }}</td>
                                                                    <td data-column="Approved Date"
                                                                        class="align-middle">
                                                                        {{ record.approvedDate }}</td>
                                                                        
                
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    </div>
</template>

<script>
import { ExternalLinkIcon, BellIcon, InfoIcon, BookIcon, LinkIcon, MessageSquareIcon, EditIcon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon, ListIcon, PlusCircleIcon, XIcon } from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
    name: 'MyApproval',
    components: {
        Treeselect,
        SearchIcon,
        MoreVerticalIcon,
        Edit2Icon,
        XIcon,
        EyeIcon,
    },
    data() {
        return {
            card:"card", 
            selectAll: false, 
            selectedStock: null,
            selectedUser: null,
            isSearchRequestActive: false,
            card_content: 'card-content',
            collapse: 'collapse',
            search:{
                selectedStock: null,
            selectedUser: null,
            },
            stockOptions: [
                { id: 1, label: 'HDFC' },
                { id: 2, label: 'SBI' },
                { id: 3, label: 'IDBI' },
            ],
            userOptions: [
                { id: 1, label: 'Chinmay Patil' },
                { id: 2, label: 'Mandar Bangar' },
                { id: 3, label: 'Swanand Sawdekar' },
            ],
            records: [
                { id: 1, userName: 'Chinmay Patil', userId: 1, stockName: 'HDFC', isapproved: 'yes', approvedBy: 'Hanumant Galgate', approvedDate:'10 Dec 2023'},
                { id: 2, userName: 'Aditya Chavan', userId: 2, stockName: 'SBI', isapproved: 'yes', approvedBy: 'Kalyani Mahajan', approvedDate:'10 Dec 2023'},
                { id: 3, userName: 'Sagar Kadam', userId: 3, stockName: 'IDBI', isapproved: 'yes', approvedBy: 'Hanumant Galgate', approvedDate:'10 Dec 2023'},
            ],
        };
    },
    mounted(){
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
      });
    },
    methods: {

        mysearchrequestollapse: function () {
            this.isSearchRequestActive = !this.isSearchRequestActive
        },
        resetRecords: function () {
            this.search.selectedStock=null,
            this.search.selectedUser=null
        },
        toggleAll() {
        this.records.forEach(record => {
            record.isSelected = this.selectAll;
        });
    },
    },
};
</script>

<style></style>





