<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-2">Test Case Details</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'tlist', params: { search: true }}">Test Case List</router-link></li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls" >View Test Case</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div v-bind:class="[{show: listActive},card_content,collapse]">
                        <div class="card-header py-1" style="cursor:pointer">
                            <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-8 d-flex align-items-center">
                                    <div class="avatar bg-light-primary rounded">
                                        <div class="avatar-content">
                                            <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                                        </div>
                                    </div>
                                    
                                    <h3 class="card-title ml-2 d-inline-flex text-primary text-primary-dark" >Test Case Code: {{this.testcase.testcasecode}}</h3>
                                </div>
                                <div class="col-md-4 text-right">
                                
                                <div class="btn btn-sm btn-outline-primary waves-effect waves-effect"  @click="openLogModule()">
                                    <watch-icon size="1.5x" class="custom-class avatar-icon  mr-50"></watch-icon>
                                    <span>Log Time</span>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                       <div class="row match-height">
                           <div class="col-12">
                               <div class=" card-transaction">
                                    <div style="flex: 1 1 auto; min-height: 1px;margin-top: 10px; margin-left: 20px">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <pen-tool-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pen-tool-icon>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">{{this.testcase.testcasename}}</h6>
                                                <small>Created by {{this.testcase.createdby}}</small>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                               </div>
                           </div>
                            <div class="col-lg-4 col-md-6 col-12 ">
                                <div class="card card-transaction">
                                    <div class="card-body">
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div v-if="this.testcase.priority=='high'" class="avatar bg-light-danger rounded">
                                                    <div class="avatar-content">
                                                        <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                                                    </div>
                                                </div>
                                                <div v-if="this.testcase.priority=='low'" class="avatar bg-light-info rounded">
                                                    <div class="avatar-content">
                                                        <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                                                    </div>
                                                </div>
                                                <div v-if="this.testcase.priority=='medium'" class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Test Case Priority</h6>
                                                    <small v-if="this.testcase.priority=='high'" class="capitalisetext text-danger" style="font-weight:500">{{this.testcase.priority}}</small>
                                                    <small v-if="this.testcase.priority=='low'" class="capitalisetext text-info" style="font-weight:500">{{this.testcase.priority}}</small>
                                                    <small v-if="this.testcase.priority=='medium'" class="capitalisetext text-warning" style="font-weight:500">{{this.testcase.priority}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div v-if="this.view.testcasesevairtyname=='Critical'" class="avatar bg-light-danger rounded">
                                                <div class="avatar-content">
                                                    <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                                                </div>
                                                </div>
                                                <div v-if="this.view.testcasesevairtyname=='Major'" class="avatar bg-light-warning rounded">
                                                <div class="avatar-content">
                                                    <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                                                </div>
                                                </div>
                                                <div v-if="this.view.testcasesevairtyname=='Minor'" class="avatar bg-light-primary rounded">
                                                <div class="avatar-content">
                                                    <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                                                </div>
                                                </div>
                                                <div v-if="this.view.testcasesevairtyname=='Cosmetic'" class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                                                </div>
                                                </div>
                                                <div v-if="this.view.testcasesevairtyname=='Moderate'" class="avatar bg-light-info rounded">
                                                <div class="avatar-content">
                                                    <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                                                </div>
                                                </div>
                                                <div class="media-body">
                                                <h6 class="transaction-title">Test Case Severity</h6>
                                                <small v-if="this.view.testcasesevairtyname=='Critical'" class="text-danger" style="font-weight:500">{{this.view.testcasesevairtyname}}</small>
                                                <small v-if="this.view.testcasesevairtyname=='Major'" class="text-warning" style="font-weight:500">{{this.view.testcasesevairtyname}}</small>
                                                <small v-if="this.view.testcasesevairtyname=='Minor'" class="text-primary" style="font-weight:500">{{this.view.testcasesevairtyname}}</small>
                                                <small v-if="this.view.testcasesevairtyname=='Cosmetic'" class="text-success" style="font-weight:500">{{this.view.testcasesevairtyname}}</small>
                                                <small v-if="this.view.testcasesevairtyname=='Moderate'" class="text-info" style="font-weight:500">{{this.view.testcasesevairtyname}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <codesandbox-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codesandbox-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Test Case Type</h6>
                                                    <small class="text-warning" style="font-weight:500">{{this.view.testcasetypename}}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                                          
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12 ">
                                <div class="card card-transaction">
                                    <div class="card-body">
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Project Name</h6>
                                                    <small class="text" style="cursor:pointer; color:Blue;" @click="redirecttourl('view', view.projectid)">{{this.view.projectname ? this.view.projectname :'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-danger rounded">
                                                    <div class="avatar-content">
                                                        <monitor-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></monitor-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Task Name </h6>
                                                    <small>{{this.view.taskname ? this.view.taskname: 'NA' }}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-info rounded">
                                                    <div class="avatar-content">
                                                        <codesandbox-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></codesandbox-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Functionality</h6>
                                                    <small>{{this.view.functionlityname}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item" v-if="this.groupnamelist">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Script Presented Group</h6>
                                                    
                                                    <small v-if="testcase.groupmappingcode.length>0">
                                                        <small v-for="(datas, index) in testcase.groupmappingcode" v-bind:key="index">
                                                            <small class="sitebluecolor" style="cursor: pointer;"
                                                            href="javascript:void(0);" v-html="(index+1)+'.'+datas +`&nbsp;`" @click="redirecttourl('groupview',index)"></small><br/>
                                                        </small>
                                                    </small>
                                                    <small class="" v-else>NA</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                                                                              
                                </div>
                             </div>
                              <div class="col-lg-4 col-md-6 col-12">
                                <div class="card card-transaction">
                                    <div class="card-body">
                                        <div class="transaction-item">
                                             <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                                                    </div>
                                                </div>
                                             <div class="media-body">
                                                <h6 class="transaction-title">Created By</h6>
                                                <small>{{this.testcase.createdby}}</small>
                                             </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-success rounded">
                                                    <div class="avatar-content">
                                                        <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Created Date</h6>
                                                    <small>{{dateMonthFormat(this.testcase.createddate)}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Last Modified By</h6>
                                                    <small>{{this.testcase.lastmodifiedby}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Last Modified Date</h6>
                                                    <small>{{dateMonthFormat(this.testcase.lastmodifieddate)}}</small>
                                                </div>
                                            </div>    
                                        </div>
                                    </div>                                                          
                                </div>
                            </div>
                        </div>
                        <div class="container">
                        <div class="row mx-0 textcasueview_bg_color">
                          <div class="col-md-6" v-for="(scriptdata, i) in getTCScriptListview">
                            <fieldset class="fieldset_border_vi mb-2 mx-0 textcasueview_fieldset_bg">
                            <legend> <span class="leg_mobile"> {{ scriptdata.country }} <em class="las la-angle-double-right"></em></span> <span class="leg_mobile"> {{ scriptdata.enviornment }} <em class="las la-angle-double-right"></em></span> <span class="leg_language"> {{ scriptdata.language }} <em class="las la-angle-double-right"></em> </span> <span class="leg_automation"> {{ scriptdata.scripttype }} <em class="las la-angle-double-right"></em></span> <span class="leg_automation"> {{ scriptdata.status }}</span></legend>
                                <div class="col-md-12">
                                    <div class="group_master_main_script"> 
                                        <span class="fc_m16"> Script Code :</span> 
                                        <span class="fc_m16_btcode_text"> {{ scriptdata.tcscriptcode }} </span>
                                    </div>
                                    <div class="group_master_main_script">
                                        <small class="fc_m16" v-if="scriptdata.scripttype != 'Manual'">Test Case Script :</small>
                                        <small class="fc_m16" v-else>Test Case Steps :</small>
                                        <span class="transaction-title" v-html="formatData(scriptdata.scriptsteps)"></span>
                                    </div>
                                </div>
                            </fieldset>
                          </div>
                        </div>
                        </div>
                    </div>
                </div>


            
                <div v-if="testcase.attachmentview">
                    <div class="card">
                        <div class="card-header px-2 py-1 border-bottom">
                            <h4 class="card-title">Document List</h4>
                        </div>
                        <div class="card-body pt-1 pb-0">
                        <div class="row">
                        <div class="col-md-12">
                            <div class="row documentGrid match-height">
                                <div class="col-xl-2 col-md-4 col-sm-6 mb-1" v-for="(data,index) in (this.testcase.attachmentview ? this.testcase.attachmentview.data:[])" v-bind:key="index">
                                    <div class="card card-bordered mb-50">
                                        <div class="text-center py-2" style="background:#F8F8F8;">
                                            <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xls' || 'xlsx'" src="../../../public/images/icons/ic-xls.png" height="35" />
                                            <a :href="data.presignurl" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                            <a v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(data.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                                            <a v-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" target="_blank" v-on:click="previewpdf(data.presignurl)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop" title="Click to Preview"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                                        </div>
                                        <a :href="data.presignurl" class="py-50 px-50 border-top " style="font-size:0.85rem; color:#888;"  target="_blank">{{data.link | basename}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
                
                <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content">              
                        <div class="modal-body p-0">
                          <button type="button" class="close imageModalClose" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                          <img alt="" src="" class="imagepreview"  >
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="customTimeline" v-for="(data,index) in logtimehistory" v-bind:key="index">
                <div class="card" v-bind:class="{'reopen':data.status=='created', 'open':data.status=='fail', 'resolved':data.status=='pass'}">
                    <div class="card-body">
                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                            <p class="text-muted" style="margin-top:-3px">Updated on: {{dateMonthFormat(data.lastmodifieddate)}} 
                                <span v-bind:class="{'capitalisetext badge badge-pill badge-light-warning mx-50':data.status=='reopen', 'capitalisetext badge badge-pill badge-light-warning mx-50':data.status=='created', 'capitalisetext badge badge-pill badge-light-danger mx-50':data.status=='fail', 'capitalisetext badge badge-pill badge-light-success mx-50':data.status=='pass'}">{{data.status}}</span>
                                <span class="capitalisetext badge badge-pill badge-light-primary mx-50">{{data.activity}}</span></p>
                            </div>

                    <p v-if="data.coments"><span v-html="data.coments"></span></p>
                            <div class="row">
                            <div class="col-xl-2 col-md-4 col-sm-6 mb-1" v-for="(res,index) in (data.attachment ? data.attachment.data:[])" v-bind:key="index">
                            <div class="card card-bordered mb-0">
                                <div class="text-center py-2" style="background:#F8F8F8;">
                                    <img alt="" v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                                    <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                                    <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                                    <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                                    <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                                    <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'xls' || 'xlsx'" src="../../../public/images/icons/ic-xls.png" height="35" />
                                    <a v-on:click="getLogtimeDownloadLink(res.link)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                    <a v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(res.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                                    </div>
                                    <a :href="res.link"  class="py-50 px-50 border-top " style="font-size:0.85rem; color:#888;"  target="_blank">
                                    <span v-if="res.info">
                                        {{res.info}} -123
                                    </span>
                                    <span v-else-if="!res.info">
                                        {{res.link | basename}}
                                    </span>
                                    </a>
                                    </div>
                                
                            </div>
                            </div>
                            <hr class="mt-0" />

                        <div class="d-flex justify-content-between align-items-center">

                            <div class="d-flex">
                                <div class="media align-items-center">
                                <span class="pr-1 text-muted ">
                                    <edit-2-icon size="1.5x" class="custom-class avatar-icon mr-25"></edit-2-icon>
                                    Updated By</span>
                                    <div class="avatar ">
                                    <profilepic v-if="data.imagename" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic>
                                        <img alt="" v-if="!data.imagename" :src="defaultimagepath" width="36" height="36" />
                                    </div>
                                    <div class="media-body ml-50">
                                    <h6 class="mb-0 text-nowrap"> {{(data.createdby)}}</h6>
                                    <span class="text-muted text-nowrap">{{data.designation}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                </div>
                <div>&nbsp;</div>
                <div v-bind:class="[{display:dislogActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div class="card-header" style="cursor:pointer">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-9">
                                <div class="avatar bg-light-primary rounded" >
                                    <div class="avatar-content">
                                        <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex text-primary ml-1 text-primary-dark" >Update Log Time</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{show: isLogActive},card_content,collapse]" >
                    <div class="card-body">
                    <form class="form form-vertical">
                        <div class="row">
                        <div class="form-group col-md-4">
                            <div class="form-group mg-bot0">
                            <label class="form-label" for="spendtime">Spent Time</label>
                            <div id="spendtime">
                            <vue-timepicker type="time" v-model="logtime.spenttime" format="HH:mm"  @close="validatelog()"></vue-timepicker>
                            </div>
                            <div class="errorinputmsg" v-if="this.errors.spenttime">{{ this.errors.spenttime }}</div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group mg-bot0">
                            <label class="form-label">Activity</label>
                            <treeselect placeholder="Select Activity"  class="projectmdllistdd capitalisetext" v-model="logtime.activity" :options="logtime.acivitylist" @input="seltclogactivity" @close="validatelog()"/>
                            <div class="errorinputmsg" v-if="this.errors.activity">{{ this.errors.activity }}</div>
                            </div>
                        </div>
                        <div class="col-md-4" style="display:none;">
                            <div class="form-group mg-bot0">
                                <label class="form-label">Status</label>
                                <treeselect placeholder="Select Status" class="projectmdllistdd capitalisetext" v-model="logtime.status" :options="testcase.statuslist" @input="seltclogstatus" @close="validatelog()"/>
                            </div>
                            <div class="errorinputmsg" v-if="this.errors.status">{{ this.errors.status }}</div>
                        </div>
                        <div class="form-group col-12">
                            <label class="form-label">Comment </label>
                            <ckeditor :editor="editor" placeholder="Comment" v-model="logtime.comment" @input="seltclogcomments" :config="editorConfig" tag-name="textarea"/>
                            <div class="errorinputmsg" v-if="this.errors.comment">{{ this.errors.comment }}</div>            
                        </div> 
                        <div class="col-md-6" style="margin-top:24px">
                            <div class="form-group">
                            <div class="form-group">
                                <label for="attachment" class="btn btn-sm btn-relief-secondary mb-75 mr-75">Select image/docs/videos</label>
                                <div class="table-responsive mb-50" v-if="file.length>0">
                                    <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                        <thead>
                                            <tr>
                                                <th scope="col">SR#</th>
                                                <th scope="col">Document Name</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(image, key) in file" :key="key">
                                                <td>{{ key+1 }}</td>
                                                <td>{{ image.name }}</td>
                                                <td>                                                    
                                                    <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                        <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <button class="btn btn-sm btn-relief-secondary mb-75 mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                                <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                            </div>
                            </div>
                        </div>
                        <div class="col-6 text-right mg-top1">
                            <button type="button" class="btn btn-relief-primary mr-1" @click="savelogData()" v-bind:disabled="testcase.disblesavebtn">Submit</button>
                            <button type="reset" class="btn btn-outline-secondary" @click="handleCancel2()">Cancel</button>
                        </div>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
                <div  v-bind:class="[{display:disRequestActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div class="card-header" style="cursor:pointer">
                        <div class="container-fluid">
                        <div class="row">
                            <div class="col-9">
                                <div class="avatar bg-light-primary rounded d-inline-flex" >
                                    <div class="avatar-content">
                                        <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex text-primary ml-1" >{{this.formActionLabel}}</h4>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div v-bind:class="[{show: isRequestActive},card_content,collapse]" >
                        <div class="card-body">
                            <form class="form form-vertical">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group mg-bot0">
                                            <label for="projectlistdd">Test Case Name</label>
                                            <input type="text" id="testcasename" class="form-control" name="" placeholder="Test Case Name" v-model="testcase.testcasename"
                                            v-bind:class="{ 
                                            'form-control': true,
                                            'is-invalid': !validText(testcase.testcasename) && testcasenameBlured, }"
                                            v-on:blur="testcasenameBlured = true"/>
                                            <div class="invalid-feedback">Testcase Name must be length of 5 altleast</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group mg-bot0">
                                            <label for="projectmdllistdd">Project Name</label>
                                            <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.project" :options="testcase.projectlist" @input="seltestcsproject" @close="validateForm('t39',$event)"/>
                                        </div>
                                        <div class="errorinputmsg" v-if="this.errors.testcase.project">{{ this.errors.testcase.project }}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group mg-bot0">
                                            <label for="projectmdllistdd">Functionality</label>
                                            <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.functionality" :options="testcase.functionalitylist" @input="seltestcsfunctionality" @close="validateForm('t39',$event)"/>
                                        </div>
                                        <div class="errorinputmsg" v-if="this.errors.testcase.functionality">{{ this.errors.testcase.functionality }}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group mg-bot0">
                                            <label for="projectmdllistdd">Task Name</label>
                                            <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.task" :options="testcase.tasklist"/>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group mg-bot0">
                                            <label for="projectmdllistdd">Test Case Type</label>
                                            <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.type" :options="testcase.typelist" @input="seltestcstype" @close="validateForm('t39',$event)"/>
                                        </div>
                                        <div class="errorinputmsg" v-if="this.errors.testcase.type">{{ this.errors.testcase.type }}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group mg-bot0">
                                            <label for="projectmdllistdd">Test Case Severity</label>
                                            <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.sevairty" :options="testcase.sevairtylist" @input="seltestcssevairty" @close="validateForm('t39',$event)"/>
                                        </div>
                                        <div class="errorinputmsg" v-if="this.errors.testcase.sevairty">{{ this.errors.testcase.sevairty }}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group mg-bot0">
                                            <label for="projectmdllistdd">Test Case Priority</label>
                                            <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.priority" :options="testcase.prioritylist" @input="seltestcspriority" @close="validateForm('t39',$event)"/>
                                        </div>
                                        <div class="errorinputmsg" v-if="this.errors.testcase.priority">{{ this.errors.testcase.priority }}</div>
                                    </div>
                                    
                                    <div class="col-md-12">
                                        <div class="form-group">                                    
                                            <label for="lblattachment">Attachment</label>
                                        </div>
                                        <div class="form-group">
                                            <label for="attachment" class="btn btn-sm btn-relief-secondary mb-75 mr-75">Select image/docs/videos</label>
                                            <button class="btn btn-sm btn-relief-secondary mb-75 mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                                            <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                                            <div class="table-responsive" v-if="testcase.attachmentview">
                                                <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                                    
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">SR#</th>
                                                            <th scope="col">Document Name</th>
                                                            <th scope="col">Description</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(data, k) in (this.testcase.attachmentview ? this.testcase.attachmentview.data:[])" :key="k">
                                                            <td>{{k+1}}</td>
                                                            <td>
                                                                <label>
                                    <div class="click-zoom">
                                      <input type="checkbox" />
                                                                <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  :src="data.presignurl"  style="width:50px;height:50px"  />
                                                                <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/pdf.png" style="width:50px;height:50px" />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xlsx'||'csv'||'xls'" src="../../../public/images/icons/xls.png" style="width:50px;height:50px" />
                             &nbsp;
                                                                <a :href="data.presignurl"  target="_blank">{{data.link | basename}}</a>                                       </div>
                                                                </label> 
                                                            </td>
                                                            <td>{{data.info}}</td>
                                                            <td>
                                                                <button type="button" class="btn btn-sm danger" @click="removes3file(k,testcase.attachmentview.data)">
                                                                 <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="errorinputmsg" v-if="this.errors.testcase.attachment">{{ this.errors.testcase.attachment }}</div>
                                                <div class="table-responsive" v-if="file.length>0">
                                                    <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">SR#</th>
                                                                <th scope="col">Document Name</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(image, key) in file" :key="key">
                                                                <td>{{ key+1 }}</td>
                                                                <td>{{ image.name }}</td>
                                                                <td>
                                                                    <input type="text" placeholder="Optional description" v-model="testcase.filedescp[key]"/>
                                                                    &nbsp;&nbsp;
                                                                    <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                                        <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                    </div>
                                    
                                    <div class="col-md-12 px-0">
                                        <fieldset class="fieldset_border_vi mb-2 mx-0 fieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                                        <div class="container px-0"> 
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group mb-1">
                                                    <label for="projectmdllistdd">Country</label>
                                                    <treeselect class="projectmdllistdd capitalisetext" :multiple="true" placeholder="Select Country" v-model="testcase.tcformgroups.country" :options="testcase.tcformgroups.countrylist" @input="getCountry" @close="validateForm($event)"/>
                                                    <div class="errorinputmsg" v-if="this.testcase.tcformgroups.country.length<=0">{{ this.testcase.tcformgroups.errors.country }}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group mb-1">
                                                    <label for="projectmdllistdd">Environment</label>
                                                    <treeselect class="projectmdllistdd capitalisetext" :multiple="true" placeholder="Select Environment" v-model="testcase.tcformgroups.environment" :options="testcase.tcformgroups.environmentlist" @input="getEnv" @close="validateForm($event)"/>
                                                    <div class="errorinputmsg" v-if="this.testcase.tcformgroups.environment.length<=0">{{ this.testcase.tcformgroups.errors.environment }}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group mb-1">
                                                    <label for="projectmdllistdd">Environment Language</label>
                                                    <treeselect :multiple="true" class="projectmdllistdd capitalisetext"  placeholder="Select Environment Language" v-model="testcase.tcformgroups.language" :options="testcase.tcformgroups.languagelist" @input="getLang" @close="validateForm($event)"/>
                                                    <div class="errorinputmsg" v-if="this.testcase.tcformgroups.language.length<=0">{{this.testcase.tcformgroups.errors.language}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group mb-1">
                                                    <label for="projectmdllistdd">Script Type</label>
                                                    <treeselect :multiple="true" class="projectmdllistdd capitalisetext"  placeholder="Select Script Type" v-model="testcase.tcformgroups.scripttype" :options="testcase.tcformgroups.scripttypelist" @input="getScriptType" @close="validateForm($event)"/>
                                                    <div class="errorinputmsg" v-if="this.testcase.tcformgroups.scripttype.length<=0">{{ this.testcase.tcformgroups.errors.scripttype }}</div>
                                                </div>
                                            </div>
                                            
                                            <div v-if="testcase.tcformgroups.scriptgrps.length>0" v-for="(rows, index) in testcase.tcformgroups.scriptgrps" class="col-md-12">
                                                <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bgfieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                                                <legend> 
                                                    <span class="leg_mobile"> {{ rows.country }}</span> -<span class="leg_mobile"> {{ rows.environment }}</span> - <span class="leg_language"> {{ rows.language }} </span> - <span class="leg_automation"> {{ rows.scripttype }}</span>
                                                </legend>
                                                <div class="row">
                                                    <div class="form-group col-sm-12">
                                                        <label v-if="rows.scripttype != 'Manual'" class="form-label" for="projectmdllistdd">Test Case Script :</label>
                                                        <label v-else class="form-label" for="projectmdllistdd">Test Case Steps : </label>
                                                        <ckeditor :editor="editor" v-model="testcase.tcformgroups.scriptgrps[index].scriptsteps"   tag-name="textarea" />
                                                        <div class="errorinputmsg" v-if="(!testcase.tcformgroups.scriptgrps[index].scriptsteps)">
                                                            <small v-if="rows.scripttype != 'Manual'">Test Case Script is required!</small>
                                                            <small  v-else>Test Case Steps are required!</small>
                                                        </div>                                                    </div>
                                                </div>
                                                </fieldset>
                                            </div>

                                            
                                            <div v-if="testcase.tcformgroups.editscriptgrps.length>0" v-for="(editrows, index) in testcase.tcformgroups.editscriptgrps" class="col-md-12">
                                                <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bgfieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                                                <legend> 
                                                    <span class="leg_mobile"> {{ editrows.country }}</span> - <span class="leg_mobile"> {{ editrows.environment }}</span> - <span class="leg_language"> {{ editrows.language }} </span> - <span class="leg_automation"> {{ editrows.scripttype }}</span>
                                                </legend>
                                                <div class="row">
                                                                 
                                                    <div class="form-group col-sm-12 col-md-8 col-lg-8 text_placeholdereditars">
                                                        <label v-if="editrows.scripttype != 'Manual'" class="form-label" for="projectmdllistdd">Test Case Script :</label>
                                                        <label v-else class="form-label" for="projectmdllistdd">Test Case Stpes : </label>                                                        
                                                        <ckeditor :editor="editor" placeholder="Comment" v-model="testcase.tcformgroups.editscriptgrps[index].scriptsteps"   :config="editorConfig" tag-name="textarea" />
                                                        
                                                        <div class="errorinputmsg" v-if="(!testcase.tcformgroups.editscriptgrps[index].scriptsteps)">
                                                            <small v-if="editrows.scripttype != 'Manual'">Test Case Script is required!</small>
                                                            <small  v-else>Test Case Steps are required!</small>
                                                        </div> 
                                                    </div>
                                                    <div class="form-group col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group mb-1">
                                                            <label for="projectmdllistdd">Status</label>
                                                            <input placeholder="Select Status" class="form-control capitalisetext" v-model="testcase.tcformgroups.editscriptgrps[index].status" :options="testcase.tcformgroups.editscriptgrps[index].tcscriptstatuslist" v-if="testcase.tcformgroups.editscriptgrps[index].tcscriptstatuslist.length<=1" disabled/>
                                                            <treeselect :disabled="testcase.tcformgroups.editscriptgrps[index].statusdisable" class="projectmdllistdd capitalisetext" v-model="testcase.tcformgroups.editscriptgrps[index].status" :options="testcase.tcformgroups.editscriptgrps[index].tcscriptstatuslist" v-else/>
                                                        <div class="errorinputmsg" v-if="!testcase.tcformgroups.editscriptgrps[index].status">Status is required!</div>
                                                        </div>
                                                    </div> 
                                                </div>
                                                </fieldset>
                                            </div>

                                            </div>
                                        </div>
                                    </fieldset>
                                    </div> 

                                    <div class="col-12 mg-top1">
                                        <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard('t39',$event)" id="submitbtnt39" v-bind:disabled="testcase.disblesavebtn">Submit</button>
                                        <button type="reset" class="btn btn-outline-secondary" @click="resetForm()">Cancel</button>
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>           
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { MonitorIcon,PlusIcon,PlusSquareIcon,MinusSquareIcon,DownloadIcon,EditIcon,SearchIcon,MoreVerticalIcon,Edit2Icon,PackageIcon,
ClipboardIcon,EyeIcon,UploadCloudIcon,CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,CodesandboxIcon,PenToolIcon,Trash2Icon, WatchIcon, PaperclipIcon,UserPlusIcon,ImageIcon,ZoomInIcon,XIcon } from 'vue-feather-icons'
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import presignurl from '../getPresignurl.vue';
import profilepic from '../profilepic.vue';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import commonMethods from '../../utils/commonMethods';

export default {
    name:'tview',
    mixins: [ commonMethods ],
    components:{
        VueTimepicker,
        Loading,PlusSquareIcon,DownloadIcon,EyeIcon,UploadCloudIcon,CodesandboxIcon,PenToolIcon ,PackageIcon,
        VueElementLoading,MoreVerticalIcon,Edit2Icon,MinusSquareIcon,ClipboardIcon,Trash2Icon,
        vSelect,PlusIcon,CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,MonitorIcon,
        EditIcon,
        Treeselect,
        DatePicker,
        SearchIcon,
        Pagination,
        WatchIcon,
        PaperclipIcon,
        ckeditor: CKEditor.component,
        presignurl,
        profilepic,
        UserPlusIcon,
        ImageIcon,
        ZoomInIcon,XIcon
    },
    data() {
        return {
            componentKey: 0,
            imgExt:['jpeg','jpg','png'],
            listActive:true,
            isLoading: false,
            fullPage: true,
            stylevariation:apiUrl.stylevariation,
            defaultimagepath:apiUrl.defaultimagepath,
            companymasterlist: [],
            editRowData:[],
            glbMdl:'',
            glbMdlLbl:'testcase',
            TimeFormat: apiUrl.TimeFormat,
            testcase:{
                language:null,
                languagelist: apiUrl.languagelist,
                environment: null,
                environmentlist: apiUrl.environmentlist,
                tcgroup: null,
                history:[],
                testcasename:null,
                project:null,
                projectlist:null,
                functionality:null,
                functionalitylist:null,
                type:null,
                typelist:null,
                scenario:null,
                scenariolist:null,
                attachment:null,
                attachmentview:null,
                attachmentnew:null,
                sevairty:null,
                tcformgroups: {
                    language: [],
                    environment: [],
                    scripttype : [],
                    scriptsteps: [],
                    country: [],
                    status: null,
                    languagelist: apiUrl.languagelist, 
                    environmentlist: apiUrl.environmentlist,
                    scripttypelist: apiUrl.scripttypelist,
                    tcscriptstatuslist: apiUrl.tcscriptstatuslist,
                    countrylist: apiUrl.countrylist,
                    scriptgrps: [],
                    editscriptgrps: [],
                    errors: {
                        language: null,
                        environment: null,
                        scripttype : null,
                        status:null,
                        country: null,
                    },
                },
                groupmappingcode: [],
                groupmappingid: [],
                sevairtylist:null,
                priority:null,
                prioritylist:[{
                    id:'low',
                    label:'Low'
                },{
                    id:'medium',
                    label:'Medium'
                },{
                    id:'high',
                    label:'High'
                }],
                allotedto:null,
                allotedtolist:null,
                reasonofissue:null,
                impactofissue:null,
                status:null,
                statuslist: apiUrl.testcaseStatusList,
                iseditstate:false,
                disblesavebtn:false,
                task: null,
                tasklist: [],
                testcasenameopts: null,
                filedescp:[],
                lastmodifiedby: null,
                lastmodifieddate: null
            },
            file:[],
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            showtabledata:true,
            showproject:false,
            modulelist:[],
            isSearchRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            errors:{
                spenttime:"",
                activity:null,
                status:null,
                comment:null,
                testcase:{
                    tcgroup:null,
                    testcasename:null,
                    testcasenameopts:null,
                    project:null,
                    functionality:null,
                    type:null,
                    scenario:null,
                    attachment:null,
                    sevairty:null,
                    priority:null,
                    status:null
                }
            },
            isDetActive:true,
            isRequestActive:false,
            isedit: false,
            card:'card',
            border_warning:'border-warning',
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading', '|', 'bold',
                        'italic', '|', 'bulletedList',
                        'numberedList', '|', 'insertTable', '|',
                        'undo', 'redo', '|',
                    ],
                },
            },
            search:{
                searchprojectname :null,
                projectnameopts :null,
                searchfunctionalityname:null,
                functionalitylist:null,
                searchtestcasename:null,
                searchtaskname:null,
                tasklist:null,
                searchtypename:null,
                typenameopts:null,
                searchseverityname:null,
                severitynameopts:null,
                searchpriorityname:null,
                prioritynameopts:null,
                searchstatus:null,
                statusopts:null
            },
            projectnameList:[],
            testcasenameList:[],
            view:{
                projectname: null,
                functionlityname: null,
                testcasesevairtyname: null,
                testcasetypename: null,
                taskname: null,
                tcgname:null,
            },
            s3bucket: apiUrl.s3url,
            testcasenameBlured: false,
            scenarioBlured:false,
            disRequestActive:true,
            backpagedata:[],
            formActionLabel: 'Create Business Task',
            dislogActive: true,
            isLogActive: false,
            commentBlured:false,
            logtime:{
                acivitylist:apiUrl.acivitylist,
                spenttime:"",
                activity:null,
                status:null,
                comment:null,
                attachmentview:[],
                isTimeLogged: false,
            },
            loginusername:'',
            logtimehistory:[],
            imagext: ['jpg', 'jpeg', 'png'],
            tokendata: null,
            accepttype:apiUrl.uploadfiletype,
            getTCScriptListview: [],
            getTCScriptList: [],
            prevEnvArr: [],
            prevLangArr: [],
            prevSTypeArr: [],
            prevSCountryArr: [],
            statusConfigList: [],
            validFileExtensions:apiUrl.validFileExtensions,
            groupnamelist: [],
            getids: []
        }
    }, 
    created() {
        this.pagefeatures = {}
        let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/testcasetask/list') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    mounted(){
        window.addEventListener('storage', function(event){
              if (event.key == 'logout-mee') { 
                  window.location.reload();
              }
        });
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.loginusername = userdata.username
            this.gettastcasebyid(this.$route.query.testcaseid)
            this.getTCScriptDetails(this.$route.query.testcaseid);
        }
    },
    methods:{
        getScriptType(state) {
            this.testcase.tcformgroups.scripttype = [];
            this.testcase.tcformgroups.scripttype = state;
            if(this.testcase.tcformgroups.scripttype.length < this.prevSTypeArr.length){
                this.testcase.tcformgroups.editscriptgrps.forEach((ele, ii) => {
                    if(!this.testcase.tcformgroups.scripttype.includes(ele.scripttype)){
                        for(let j= this.testcase.tcformgroups.editscriptgrps.length - 1; j >= 0; --j) {
                            if (this.testcase.tcformgroups.editscriptgrps[j].scripttype === ele.scripttype) {
                                this.testcase.tcformgroups.editscriptgrps.splice(j,1);
                            }
                        }
                    }
                });
            }
            if(!state) {
                this.testcase.tcformgroups.errors.scripttype = 'Script Type is required'
            }else{
                this.addItems();
            }
        },
        getCountry(state) {
            this.testcase.tcformgroups.country = [];
            this.testcase.tcformgroups.country = state;
            if(this.testcase.tcformgroups.country.length < this.prevSCountryArr.length){
                this.testcase.tcformgroups.editscriptgrps.forEach((ele, ii) => {
                    if(!this.testcase.tcformgroups.country.includes(ele.country)){
                        for(let j= this.testcase.tcformgroups.editscriptgrps.length - 1; j >= 0; --j) {
                            if (this.testcase.tcformgroups.editscriptgrps[j].country === ele.country) {
                                this.testcase.tcformgroups.editscriptgrps.splice(j,1);
                            }
                        }
                    }
                });
            }
            if(!state) {
                this.testcase.tcformgroups.errors.country = 'Country is required'
            }else{
                this.addItems();
            }
        },
        getEnv(state) {
            this.testcase.tcformgroups.environment = [];
            this.testcase.tcformgroups.environment = state;

            if(this.testcase.tcformgroups.environment.length < this.prevEnvArr.length){
                this.testcase.tcformgroups.editscriptgrps.forEach((ele, ii) => {
                    if(!this.testcase.tcformgroups.environment.includes(ele.environment)){
                        for(let j= this.testcase.tcformgroups.editscriptgrps.length - 1; j >= 0; --j) {
                            if (this.testcase.tcformgroups.editscriptgrps[j].environment === ele.environment) {
                                this.testcase.tcformgroups.editscriptgrps.splice(j,1);
                            }
                        }
                    }
                });
            }
            if(!state) {
                this.testcase.tcformgroups.errors.environment = 'Environment is required'
            }else{
                this.addItems();
            }
        },
        getLang(state) {
            this.testcase.tcformgroups.language = [];
            this.testcase.tcformgroups.language = state;
            if(this.testcase.tcformgroups.language.length < this.prevLangArr.length){
                this.testcase.tcformgroups.editscriptgrps.forEach((ele, ii) => {
                    if(!this.testcase.tcformgroups.language.includes(ele.language)){
                        for(let j= this.testcase.tcformgroups.editscriptgrps.length - 1; j >= 0; --j) {
                            if (this.testcase.tcformgroups.editscriptgrps[j].language === ele.language) {
                                this.testcase.tcformgroups.editscriptgrps.splice(j,1);
                            }
                        }
                    }
                });
            }
            if(!state) {
                this.testcase.tcformgroups.errors.language = 'Language is required'
            }else{
                this.addItems();
            }
        },
        detectCombinations(input, output, position, path) {
            if (position == null) {
                position = 0;
            }
            if (path == null) {
                path = [];
            }
            if (position < input.length) {
                let item = input[position];
                for (let i = 0; i < item.length; ++i) {
                    let value = item[i];
                    path.push(value);
                    this.detectCombinations(input, output, position + 1, path);
                    path.pop();
                }
            } else {
                output.push(path.slice());
            }
            return output;
        },
        addItems() {
            let output = [];
            let input = [];
            this.testcase.tcformgroups.scriptgrps = [];
            input.push(this.testcase.tcformgroups.environment)
            input.push(this.testcase.tcformgroups.language)
            input.push(this.testcase.tcformgroups.scripttype)
            input.push(this.testcase.tcformgroups.country)
            let x = this.detectCombinations(input, output);
            x.forEach(element => {
                this.testcase.tcformgroups.scriptgrps.push({
                    environment: element[0],
                    language: element[1],
                    scripttype: element[2],
                    country: element[3],
                    scriptsteps: null,
                    status: 'Created',
                    labeltext: element[3]+"-"+element[0]+"-"+element[1]+"-"+element[2],
                });
            });

            
            this.testcase.tcformgroups.editscriptgrps.forEach((ele, ii) => {
                const objWithIdIndex = this.testcase.tcformgroups.scriptgrps.findIndex((obj) => obj.labeltext === ele.labeltext);
                if (objWithIdIndex > -1) {
                    this.testcase.tcformgroups.scriptgrps.splice(objWithIdIndex, 1);
                }                
            });
        },
        getStatusConfigList(pid) {
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: pid,
                trackername: 'Testcase',
            };
            axios({
                method: "POST",
                url: '/api/testcase/getStatusConfigListFORTC',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    this.statusConfigList = result.data.data.rows;
                } else {
                    this.statusConfigList = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getTCScriptDetailsedit(tcid) {
            this.input = {
                empid: this.undt.userid,
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                tcid: tcid,
            }
            axios({
                'method': 'POST',
                'url': 'api/testcase/getTCScriptDetails',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    this.getTCScriptList = result.data.data.rows;
                    this.getTCScriptList.forEach((element) => {
                        this.getids.push(element.id)
                        this.testcase.tcformgroups.status  = element.status;
                        if(!this.prevEnvArr.includes(element.enviornment)){
                            this.prevEnvArr.push(element.enviornment)
                        }
                        if(!this.prevLangArr.includes(element.language)){
                            this.prevLangArr.push(element.language)
                        }
                        if(!this.prevSTypeArr.includes(element.scripttype)){
                            this.prevSTypeArr.push(element.scripttype)
                        }
                        if(!this.prevSCountryArr.includes(element.country)){
                            this.prevSCountryArr.push(element.country)
                        }

                        let slist = [];
                        let sflag = false;
                        if(!slist.some(sdata => sdata.id === element.status)){
                            slist.push({ id: element.status, label: element.status });
                        }

                        if(this.statusConfigList.length>0){
                            this.statusConfigList.forEach((rec) => {
                                if(Object.values(rec.statusconfig).length>0){
                                    Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                                        if(skey===element.status){
                                            if(statusnames.length>0) {
                                                statusnames.forEach(val => {
                                                    if(!slist.some(sdata => sdata.id === val)){
                                                        slist.push({ id: val, label: val });
                                                    }
                                                });
                                            }
                                        } 
                                    });
                                }
                            });
                        }

                        this.testcase.tcformgroups.editscriptgrps.push({
                            environment: element.enviornment,
                            language: element.language,
                            scripttype: element.scripttype,
                            scriptsteps: element.scriptsteps,
                            country: element.country,
                            status: element.status,
                            labeltext: element.country+"-"+element.enviornment+"-"+element.language+"-"+element.scripttype,
                            id: element.id,
                            tcscriptcode: element.tcscriptcode,
                            tcscriptstatuslist: slist,
                            statusdisable: sflag,
                        });
                    });
                    if (this.getids.length>0) {
                        this.getgroupsfortestcase(this.getids); 
                    }
                    
                    this.testcase.tcformgroups.language = this.prevLangArr;
                    this.testcase.tcformgroups.environment = this.prevEnvArr;
                    this.testcase.tcformgroups.scripttype  = this.prevSTypeArr;
                    this.testcase.tcformgroups.country  = this.prevSCountryArr;
                } else {
                    this.getTCScriptList = []
                }
            }).catch(e => {
        this.displayError(e)
      })
        },
        getTCScriptDetails(tcid) {
            this.input = {
                empid: this.undt.userid,
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                tcid: tcid,
            }
            axios({
                'method': 'POST',
                'url': 'api/testcase/getTCScriptDetails',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    this.getTCScriptListview = result.data.data.rows;
                    this.getTCScriptListview.forEach((element) => {
                        this.getids.push(element.id)
                    })
                    if (this.getids.length>0) {
                        this.getgroupsfortestcase(this.getids); 
                    }
                } else {
                    this.getTCScriptListview = []
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        forceRerender() {
            this.componentKey += 1;
        },
        gettastcasebyid(moduleid){
            this.isLoading = true;
            let url = "api/testcase/getTestcasebyid";
            this.input = {
                testcaseid:moduleid,
                useremail: this.undt.username,
                empid:this.undt.userid,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.editRowData = result.data.data;
                    this.editRowData=this.editRowData[0]
                    this.openEditModule(this.editRowData,1)
                } else {
                    this.editRowData = [];
                    this.$router.push({ name: 'tlist', params: { search: true }})
                    Swal.fire({
                        title: "Failed!",
                        text: "Your are not authorised user to view this testcase",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        formatData(data) {
            return data
        },
        mydetailscollapse() {
            this.isDetActive = !this.isDetActive
        },
        myrequestcollapse() {
            this.dislogActive=true
            this.isLogActive=false
            this.logtime.spenttime = { "HH": "", "mm": "" }
            this.logtime.activity = null
            this.logtime.status = null
            this.logtime.comment = null

            
        },
    openLogModule(){
      this.isRequestActive=false
      this.disRequestActive=true
      this.logtime.spenttime = { "HH": "", "mm": "" }
    
      this.logtime.activity=null
      this.logtime.comment=''
      this.logtime.allotedto=this.editRowData.allotedto
      this.logtime.status= this.editRowData.status
      this.dislogActive=false
      this.isLogActive=true
      this.testcase.disblesavebtn = false
      $('html, body').animate({ scrollTop: $(document).height() }, 800);
    },
    savelogData(){
      let valid = this.validatelog();
      if(valid){
        this.logtime.isTimeLogged = true
        this.input={
          moduletype:'testcase',
          modulename:this.editRowData.testcasename,
          moduleid:this.editRowData.testcaseid,
          projectname:this.editRowData.projectname,
          projectid:this.editRowData.projectid,
        
          activity:this.logtime.activity,
          assignedto:this.logtime.allotedto,
          status:this.logtime.status,
          module_code: this.editRowData.testcasecode,
          coments:this.logtime.comment,
          createdby:this.undt.username,
          attachment:JSON.stringify(this.testcase.attachmentnew),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        }
        if(typeof(this.logtime.spenttime)=='string' && this.logtime.spenttime) {
            this.input.spenttime = this.logtime.spenttime
        }else if(typeof(this.logtime.spenttime)!='string' && this.logtime.spenttime.HH !='' &&  this.logtime.spenttime.mm!='') {
            this.input.spenttime = this.logtime.spenttime
        }
        if(this.testcase.attachmentnew) {
          let jsonobj1 = JSON.parse(this.testcase.attachmentnew);
          let arraymerge = [...jsonobj1.data]
          this.input.attachment= JSON.stringify({data: arraymerge});
        }
        this.isLoading=true
        axios({
          method: "POST",
          url: 'api/issue/saveLogTime',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.testcase.iseditstate =true
            this.testcase.status=this.logtime.status
            this.savewizard('t42',this.editRowData)
            this.dislogActive=true
            this.isLogActive=false
          }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              title: "Failed!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          }
        }).catch(e => {
            this.displayError(e)
        });
      }
    },
    resetForm() {
            this.testcase.attachmentnew = null
            this.testcase.attachment = null
            this.testcase.attachmentview = null
            this.removeImage()
            this.clearerrorForm()
            this.isRequestActive=false
            this.disRequestActive=true
            this.testcase.disblesavebtn = false
            this.testcase.tcformgroups.environment = [];
            this.testcase.tcformgroups.language = [];
            this.testcase.tcformgroups.scripttype = [];
            this.testcase.tcformgroups.country = [];
            this.testcase.tcformgroups.errors.scripttype = null;
            this.testcase.tcformgroups.errors.language = null;
            this.testcase.tcformgroups.errors.environment = null;
            this.testcase.tcformgroups.errors.country = null;
            $('html, body').animate({ scrollTop: 0 }, 800);
        },
        onFileChange(e) {
            var validFileExtensions = apiUrl.validFileExtensions
            let selectedFiles = e.target.files;
            for (let i=0; i < selectedFiles.length; i++)
            {
            var blnValid = false;
            for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
                var sCurExtension = validFileExtensions[j];
                if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                    blnValid = true;
                    break;
                }
            }
                
                if (!blnValid) {
                    Swal.fire({
                        title: "ERROR",
                        text: "Sorry, Invalid Type of Extension File..!!",
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    return false;
                }
                this.file.push(selectedFiles[i]);
            }
            if(this.file.length>0) {
                this.testcase.disblesavebtn=true
            }
        },
        removeImage (index) {
            if(this.file.length>0) {
                this.file.splice(index, 1);
                this.testcase.filedescp.splice(index, 1);
                var removeimg = JSON.parse(this.testcase.attachmentnew)
                if(removeimg){
                    var remove = removeimg.data.splice(index,1)
                    if(remove.length > 0)
                    this.testcase.attachmentnew = JSON.stringify({data:removeimg.data})
                    else {
                    this.testcase.attachmentnew = null
                  }
                }
            }   
            if(this.file.length < 1) {
                this.testcase.disblesavebtn = false
            }      
        },
        removes3file(key,data) {
            if(data.length>0) {                
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to delete this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        if(this.editRowData.testcaseid !== undefined) {
                            let deletefile = data[key];
                            data.splice(key, 1);
                            const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                            let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                            axios({
                            method: "POST",
                            url: 'api/testcase/s3deletetestcase',
                            data: {
                                testcaseid:this.editRowData.testcaseid,
                                attachment:payload,
                                deletefile: JSON.stringify(deletefile),
                                useremail: this.undt.username,
                                empcode: this.undt.userid,
                            },
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            if (result.data.status) {
                                Swal.fire(
                                'Deleted!',
                                result.data.msg,
                                'success'
                                )
                            } else {
                            Swal.fire(
                                'Deleted!',
                                result.data.msg,
                                'error'
                                )                        
                            }
                        });
                        } else {
                            Swal.fire(
                                'TestCaseId!',
                                'TestCaseId should not blank',
                                'info'
                                )
                        }    
                    }
                }).catch(e => {
                    this.displayError(e)
                })
            }
        },
        savewizard(mdl,objThis){      
            this.valid = this.validateForm(mdl,objThis);
            if (this.valid) {
                this.isLoading=true; 
                let tblNm='t39'
                if(mdl) tblNm=mdl
                this.input = {
                    tbl: tblNm,
                    section: 'testcase',
                    createdby: this.undt.username,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                }
                let apiURL='api/testcase/create'
                this.input.testcasename=this.testcase.testcasename
                this.input.project=this.testcase.project
                this.input.functionality=this.testcase.functionality
                this.input.task = this.testcase.task
                this.input.type=this.testcase.type
                this.input.attachment=this.testcase.attachmentnew
                this.input.sevairty=this.testcase.sevairty
                this.input.priority=this.testcase.priority
                this.input.scriptgrps = this.testcase.tcformgroups.scriptgrps;
                this.input.editscriptgrps = this.testcase.tcformgroups.editscriptgrps;
                this.input.scriptsstatus = this.testcase.tcformgroups.status;       
                this.input.module_code = this.editRowData.testcasecode
                if(this.testcase.iseditstate == true){
                    this.testcase.iseditstate=false
                    apiURL='api/testcase/update'
                    this.input.testcaseid=this.editRowData.testcaseid
                    this.input.projectname = this.editRowData.projectname
                    this.input.isTimeLogged = this.logtime.isTimeLogged
                     if(this.testcase.attachmentnew && this.testcase.attachment !== null) {
                         this.input.attachmentnew = this.testcase.attachmentnew
                        let jsonobj1 = JSON.parse(this.testcase.attachmentnew);
                        let jsonobj2 = this.testcase.attachment;
                        let arraymerge = [...jsonobj1.data,...jsonobj2.data]
                        this.input.attachment= JSON.stringify({data: arraymerge});
                    }
                    
                    this.input.lastmodifiedby=this.undt.username
                    delete this.input.createdby;
                }
                this.isAddState=false
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {     
                    this.isLoading=false;         
                    if(result.data.errorCode == 0){
                        this.forceRerender()
                        this.isRequestActive = false
                        this.disRequestActive=true;
                        this.isDetActive = true
                        this.file =[]
                        this.testcase.filedescp =[]
                        this.logtime.isTimeLogged = false
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.resetForm()
                        this.gettastcasebyid(this.editRowData.testcaseid)
                        this.getTCScriptDetails(this.editRowData.testcaseid)
                        $('html, body').animate({ scrollTop: 0 }, 1200);                        
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                    this.displayError(e)
                })
            }
        },
    getLogtimeDownloadLink(data, curr=null) {   
      if(data) {
        var input =[]
        input.push({link:data,empcode: this.undt.userid,useremail: this.undt.username})
        let payload = {
          documents:input,
          useremail: this.undt.username,
          empcode: this.undt.userid
        };
        axios({
            method: "POST",
            url: '/api/imageupload/getdownloadlink',
            data: payload,
            'headers':{'authorization':this.tokendata}
        })
        .then((result) => {
          if(result.data.status) {
            if(result.data.data[0].presignurl){
              if(curr=='zoom'){
                $('.imagepreview').attr('src', result.data.data[0].presignurl);
                $('#imagemodal').modal('show');  
                return 
              }
              window.location.href=result.data.data[0].presignurl;
              
            }
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
        
       
        handleCancel2() {
            this.dislogActive=true
            this.isLogActive=false
            this.testcase.disblesavebtn = false
            $('html, body').animate({ scrollTop: 0 }, 800);
        },
        projectChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                projectname:node.toLowerCase(),
                useremail: this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.projectnameList = result.data.data;
                } else {
                    this.projectnameList = [];
                }
                }).catch(e => {
                this.displayError(e)
            });
            } 
        },
        handleCancel() {
            this.myrequestcollapse()
            $('html, body').animate({ scrollTop: 0 }, 800); 
        },
        testcaseChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/searching/getTestcaselist";
                this.input = {
                testcasename:node.toLowerCase(),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.testcasenameList = result.data.data;
                } else {
                    this.testcasenameList = [];
                }
                }).catch(e => {
                    this.displayError(e)
                });
            } 
        },
        resetRecords: function() {
            this.search.searchprojectname = null;
            this.search.searchfunctionalityname = null;
            this.search.searchtestcasename= null;
            this.search.searchtaskname = null;
            this.search.searchtypename = null;
            this.search.searchseverityname = null;
            this.search.searchpriorityname = null;
            this.search.searchstatus = null;
            
        },
        validText : function(inputval) {
        var re = /[A-Za-z0-9].{5,}/;
        if(inputval){
            return re.test(inputval.toLowerCase());
         }
        },
        validateForm(mdl,objThis){
            this.clearerrorForm();
            this.isAddState=false
            this.commentBlured=true;
            var isValid=true;
            this.testcasenameBlured = true;
            this.scenarioBlured = true;
            if(mdl=="t39"){
                if(!this.testcase.testcasename){
                    this.errors.testcase.testcasename="Test Case Name required";
                    isValid= false;
                }
                if(!this.testcase.project){
                    this.errors.testcase.project="Project required";
                    isValid= false;
                }
                if(!this.testcase.type){
                    this.errors.testcase.type="Test case type required";
                    isValid= false;
                }
                if(!this.testcase.sevairty){
                    this.errors.testcase.sevairty="Test case severity required";
                    isValid= false;
                }
                if(!this.testcase.priority){
                    this.errors.testcase.priority="Test case priority required";
                    isValid= false;
                }

                if(this.testcase.tcformgroups.environment.length<=0) {
                    this.testcase.tcformgroups.errors.environment = "Environment is required."
                    isValid = false;
                }
                
                if(this.testcase.tcformgroups.country.length<=0) {
                    this.testcase.tcformgroups.errors.country = "Country is required."
                    isValid = false;
                }

                if(this.testcase.tcformgroups.language.length<=0) {
                    this.testcase.tcformgroups.errors.language = "Language is required."
                    isValid = false;
                }

                if(this.testcase.tcformgroups.scripttype.length<=0) {
                    this.testcase.tcformgroups.errors.scripttype = "Script Type is required."
                    isValid = false;
                }

                if(this.testcase.tcformgroups.environment.length<=0 || this.testcase.tcformgroups.language.length<=0 || this.testcase.tcformgroups.scripttype.length<=0) {
                    isValid= false;
                }
                
                
                this.testcase.tcformgroups.scriptgrps.forEach(element => {
                    if(element.scriptsteps == null || element.scriptsteps == '') {
                        isValid= false;
                    }
                });
                
                if(this.testcase.tcformgroups.editscriptgrps.length>0) {
                    this.testcase.tcformgroups.editscriptgrps.forEach(element => {
                        if(element.scriptsteps == null || element.scriptsteps == '') {
                            isValid= false;
                        }
                    });
                }
            }
            return isValid
        },
        clearerrorForm(){
            this.errors={
                testcase:{
                    testcasename:null,
                    project:null,
                    functionality:null,
                    type:null,
                    scenario:'',
                    attachment:null,
                    sevairty:null,
                    language: null,
                    environment: null,
                    priority:null,
                    status:null
                }
            }
        },
        validatelog: function(){
            let isValid=true;
            let spenttime = this.logtime.spenttime && this.logtime.spenttime.HH && this.logtime.spenttime.mm

            if(!spenttime && typeof(this.logtime.spenttime)=='string')
            {
                let logstime = (this.logtime.spenttime).split(':')
                if(logstime){
                    if(logstime[0] && logstime[1]){
                        spenttime = `${logstime[0]}:${logstime[1]}`
                        this.logtime.spenttime = `${logstime[0]}:${logstime[1]}`
                    }
                }
            }
            if(spenttime && typeof(this.logtime.spenttime)!='string'){
                    this.logtime.spenttime = `${this.logtime.spenttime.HH}:${this.logtime.spenttime.mm}`
            }
            if(this.logtime.activity && spenttime==''){
                this.errors.spenttime="Spent-time Hours & minutes is required";
                isValid= false;
            }else{
                this.errors.spenttime='';
            }
            if(!this.logtime.activity && spenttime !=''){
                this.errors.activity="Activity is required";
                isValid= false;
            }else{
                this.errors.activity=null;
            }
            
            if(!this.logtime.comment){
                this.errors.comment="Comment is required";
                isValid= false;
            }else{
                this.errors.comment=null;
            }
            return isValid;
        },
        openEditModule(currRowData, flag){
            if(currRowData && currRowData.length==0){
                currRowData = this.editRowData
            }
            this.logtime.isTimeLogged = false
            this.testcase.disblesavebtn = false
            this.view.projectname=currRowData.projectname
            this.view.projectid=currRowData.projectid
            this.view.functionlityname=currRowData.functionlityname
            this.editRowData = currRowData  
            this.view.testcasesevairtyname = currRowData.testcasesevairtyname
            this.view.testcasetypename = currRowData.testcasetypename
            this.view.taskname = currRowData.taskname
            this.testcase.testcasename = currRowData.testcasename
            this.testcase.project = currRowData.project
            this.testcase.functionality = currRowData.functionality
            this.testcase.task = currRowData.taskid
            this.testcase.type = currRowData.issuetype
            this.testcase.scenario = currRowData.scenario
            this.testcase.attachment = currRowData.attachment
            
            if(currRowData.attachment && currRowData.attachment.hasOwnProperty('data')) {
                this.testcase.attachmentview =[];
                this.getDownloadLink(currRowData.attachment);
            }
            this.getTestcaseLogTimeHistory(currRowData.testcaseid)
            this.testcase.sevairty = currRowData.sevairty
            this.testcase.priority = currRowData.priority
            this.testcase.status = currRowData.status
            this.testcase.createdby= currRowData.createdby
            this.testcase.createddate= currRowData.createddate
            this.testcase.lastmodifiedby= currRowData.lastmodifiedby
            this.testcase.lastmodifieddate= currRowData.lastmodifieddate            
            this.testcase.testcasecode= currRowData.testcasecode
            this.testcase.iseditstate = true
            this.isAddState=true
            if(flag==0){
                this.getStatusConfigList(this.testcase.project);
                this.getTCScriptDetailsedit(this.$route.query.testcaseid);
                this.isedit=true
                this.formActionLabel = `Edit Test Case 33 : ${currRowData.testcasename}`
                this.isRequestActive=true
                this.disRequestActive=false
                this.dislogActive=true
                this.isLogActive=false
                $('html, body').animate({ scrollTop: $(document).height() }, 1200);
            }else{

            }
        },
        getTCGroupNamesList(ids){
            this.isLoading = true;
            let url = "api/testcase/getTCGroupNamesList";
            this.input = {
                ids : ids ,
                useremail: this.undt.username,
                empid: parseInt(this.undt.userid),
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.view.tcgname = result.data.data[0].tcgname;
                } else {
                    this.view.tcgname = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getTestcaseLogTimeHistory(testcaseid){
            this.isLoading = true;
            let url = "api/testcase/getTestcaseLogTimeById";
            this.input = {
                testcaseid,
                useremail: this.undt.username,
                empid: parseInt(this.undt.userid),
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.logtimehistory = result.data.data;
                    const decryptedDepartmentList = this.logtimehistory.map(item => {
                    item.designation = this.decryptText(item.designation);
                    return item;
                    });
                    this.forceRerender()
                } else {
                    this.logtimehistory=[]
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getDownloadLink(data) {
            if(data.data.length>0) {
                let payload = {
                    documents:data.data,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                })
                .then((result) => {
                    if(result.data.status) {
                        this.testcase.attachmentview = result.data;
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        dateFormatHMS(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        },
        addmodule(){
            this.testcase.testcasename = null
            this.testcase.project = null
            this.testcase.functionality = null
            this.testcase.task = null
            this.testcase.type = null
            this.testcase.scenario = ''
            this.testcase.attachment = null
            this.testcase.attachmentview = null
            this.testcase.sevairty = null
            this.testcase.priority = null
            this.testcase.status = null
            this.testcase.iseditstate = false
            this.testcase.disblesavebtn=true
            this.testcase.filedescp=[]
            this.testcase.attachmentview=[]
        },
        seltestcsproject(state,value){
            this.testcase.project=null
            this.testcase.project=state
            if(!state){
                this.testcase.functionality=null
                this.testcase.task = null
                this.testcase.functionalitylist = null
                this.testcase.tasklist = null
                
            }else{
                this.errors.testcase.project=null
                this.getApplicationdata('t37','testcase')
            }
        },     
        seltestcsfunctionality(state,value){
            this.testcase.functionality=null
            this.testcase.functionality=state
            if(!state){
                this.testcase.task = null
                this.testcase.tasklist = null
                
            }else{                
                this.getApplicationdata('t18', 'testcase')
            }
        },
        seltestcsprojectsearch(state,value){
            this.search.searchprojectname = null
            this.search.searchprojectname = state
            if(!state){
                this.search.searchfunctionalityname=null
                this.search.searchtaskname=null
                this.search.searchtaskname = null
                this.search.tasklist = null
                this.errors.testcase.project='Project required'
               
            }else{
                this.getApplicationdata('t37','testcaseSearch')
            }
        },     
        seltestcsfunctionalitysearch(state,value){
            this.search.searchfunctionalityname=null
            this.search.searchfunctionalityname=state
            if(!state){
                this.search.searchtaskname = null
                this.search.tasklist = null
            }else{                
                this.getApplicationdata('t18', 'testcaseSearch')
            }
        },
        seltestcstype(state,value){
            this.testcase.type=null
            this.testcase.type=state
            if(!state && this.isAddState == false){
                this.errors.testcase.type='Type required'
            }else{                
                this.errors.testcase.type=null
            }
        },
        seltestcssevairty(state,value){
            this.testcase.sevairty=null
            this.testcase.sevairty=state
            if(!state && this.isAddState == false){
                this.errors.testcase.sevairty='Severity required'
            }else{                
                this.errors.testcase.sevairty=null
            }
        },
        seltestcspriority(state,value){
            this.testcase.priority=null
            this.testcase.priority=state
            if(!state && this.isAddState == false){
                this.errors.testcase.priority='Priority required'
            }else{                
                this.errors.testcase.priority=null
            }
        },
          selissuecsstatus: function(state,value){
            this.issue.status=null
            this.issue.status=state
            if(!state && this.isAddState == false){
                this.errors.issue.status='Status required'
            }else{     
                this.errors.status = null               
                this.errors.issue.status=null
            }
        },
        seltestcsstatus(state,value){
            this.testcase.status=null
            this.testcase.status=state
            if(!state && this.isAddState == false){
                this.errors.testcase.status='Status required'
            }else{                
                this.errors.testcase.status=null
            }
        },
        seltclogstatus(state, value) {
            this.logtime.status = null
            this.logtime.status = state
            if(!state) {
                this.errors.status = "Status is required"
            } else {
                this.errors.status = null
            }
        },
        seltclogactivity(state, value) {
          this.logtime.activity = null
          this.logtime.activity = state
          if(!state) {
            
          } else {
              this.errors.activity = null
          }
       },
       seltclogcomments(state, value) {
        this.logtime.comment = null
        this.logtime.comment = state
        if(!state) {
            this.errors.comment = "Comment is required"
        } else {
            this.errors.comment = null
        }
    },
    redirecttoPNM:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            this.$router.push({ 

                path: '/project/summary?pid='+rowdata.moduleid,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        getApplicationdata(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            let apiURL='api/master/commonmaster/list'
            if(mdl == 't11'){
                apiURL='api/listing/getProjectAllocatedToEmp'
                this.inputappl = {
                    empid: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                }
            }else if(mdl == 't37' && curracttab == 'testcase'){                
                this.inputappl = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                apiURL = "api/listing/getfunctionalitybyprojectid";
                this.inputappl.projectid = this.testcase.project
            }else if(mdl == 't37' && curracttab == 'testcaseSearch'){                
                this.inputappl = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                apiURL = "api/listing/getfunctionalitybyprojectid";
                this.inputappl.projectid = this.search.searchprojectname
            }else if(mdl == 't40'){
                apiURL='api/master/commonmaster/customselect'
                /* this.inputappl.projectmoduleid=this.testcase.project */
                this.inputappl.isSearch= false
                this.inputappl.section= "testcase"
                this.inputappl.isFilterSelect= 1
                this.inputappl.isFilterPayload= {}
            }else if(mdl == 't41'){
                apiURL='api/master/commonmaster/customselect'
                /* this.inputappl.projectmoduleid=this.testcase.project */
                this.inputappl.isSearch= false
                this.inputappl.section= "testcase"
                this.inputappl.isFilterSelect= 1
                this.inputappl.isFilterPayload= {}
            } else if (mdl == 't18' && curracttab == 'testcase') {
                apiURL = 'api/master/commonmaster/getModuleDropdownOption'
                this.inputappl = {
                    tbl: mdl,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    functionalityid: this.testcase.functionality
                }
            } else if (mdl == 't18' && curracttab == 'testcaseSearch') {
                apiURL = 'api/master/commonmaster/getModuleDropdownOption'
                this.inputappl = {
                    tbl: mdl,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    functionalityid: this.search.searchfunctionalityname
                }
            }
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                if(result.data.errorCode == 0){
                    if(mdl == 't11'){
                        if(curracttab == 'testcase'){
                            this.testcase.projectlist=result.data.data
                            return true
                        }
                    }else if(mdl == 't37' && curracttab == 'testcase'){
                        if(curracttab == 'testcase'){
                            this.testcase.functionalitylist=result.data.data
                            return true
                        }
                    }else if(mdl == 't37' && curracttab == 'testcaseSearch'){
                        this.search.functionalitylist=result.data.data
                        return true
                    } else if(mdl == 't40'){
                        if(curracttab == 'testcase'){
                            this.testcase.typelist=result.data.data.rows
                            return true
                        }
                    }else if(mdl == 't41'){
                        if(curracttab == 'testcase'){
                            this.testcase.sevairtylist=result.data.data.rows
                            return true
                        }
                    } else if(mdl == 't18' && curracttab == 'testcase') {
                            this.testcase.tasklist = result.data.data
                            return true
                    } else if(mdl == 't18' && curracttab == 'testcaseSearch') {
                        this.search.tasklist = result.data.data
                        return true
                    }
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
            }).catch(e => {
                this.displayError(e)
            })
            
        },
        getgroupsfortestcase(ids){ 
            this.isLoading=true 
            this.input={ 
                useremail: this.undt.username, 
                empcode: this.undt.userid, 
                getids: ids 
            } 
            axios({ 
                'method': 'POST', 
                'url': 'api/testcase/getgroupsfortestcase', 
                'data': this.input, 
                'headers':{'authorization':this.tokendata} })
            .then(result => { 
                this.isLoading=false 
                if(result.data.errorCode == 0){ 
                    this.groupnamelist = result.data.data.rows; 
                    for (let j = 0; j < this.groupnamelist.length; j++) { 
                        this.testcase.groupmappingcode.push(this.groupnamelist[j].groupmappingcode)
                        this.testcase.groupmappingid.push(this.groupnamelist[j].groupmappingid)
                    }
                }else {
                    this.groupnamelist = [] 
                } 
            }).catch(e => {
                this.displayError(e)
            })
        },
        redirecttourl:function(btnstate,idx){
            let rowdata = []
            rowdata.id = this.testcase.groupmappingid[idx]
            rowdata.backbtnstate = btnstate
            if(btnstate=="groupview"){
                let routeData = this.$router.resolve({ 
                    path: '/testcase/groupbulkView/?groupmappingid='+rowdata.id,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
                window.open(routeData.href, '_blank');
            }
            else if (btnstate == 'view')
             { 
                this.$router.push({ 
                    path: '/project/summary?pid='+idx
                })
            }
        },
        onSelect(e){
            this.file = e.target.files[0];            
        },
        onUpload(){
            this.isLoading=true
            if(this.file){
                if(this.file.length!=0){
                    let arrGlbMdl=this.glbMdl.split('##')
                    let inputEle='';
                    let btnEle='';
                    if(arrGlbMdl.length > 0){
                        if(typeof arrGlbMdl[0] != 'undefined'){
                            inputEle=arrGlbMdl[0];
                        }
                        if(typeof arrGlbMdl[1] != 'undefined'){
                            btnEle=arrGlbMdl[1];
                        }
                    }
                    if(inputEle == 't39'){
                        btnEle='testcase'
                    }
                    this.apiURL="api/master/fileupload/uploadBulkFileToS3"
                    let formData = new FormData();
                    let maxsize = 0;
                    for (let index = 0; index < this.file.length; index++) {
                        formData.append("imagefile", this.file[index]);
                        maxsize+= this.file[index].size
                    }
                    formData.append("empcode", this.undt.userid);
                    formData.append("useremail", this.undt.username);
                    formData.append("moduletype", 'testcase');
                    formData.append("fcount", parseInt(this.file.length));
                    if (maxsize > 1000 * 1000 * 25) {
                        this.isLoading = false;
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 25mb not allow",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.isLoading=false
                        this.testcase.disblesavebtn = true
                        return false; 
                    }
                    axios({
                        'content-type':'multipart/form-data',
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':formData,
                        'headers':{'authorization':this.tokendata}
                    })
                    .then(result => { 
                        this.isLoading=false;            
                        if(result.data.status){
                            this.testcase.disblesavebtn=false
                            
                            const bindinfo = result.data.data.map((file, index) => {
                                return {"link":file,"info":this.testcase.filedescp[index]}                
                            });
                            let jsonobj = {data:bindinfo}
                            this.testcase.attachmentnew = JSON.stringify(jsonobj);
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    })
                }else{
                     this.isLoading=false; 
                    Swal.fire({
                        title: "",
                        text: 'Select image/docs/videos',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.testcase.attachment=null
                }
            }else{
                    this.isLoading=false; 
                    Swal.fire({
                        title: "",
                        text: 'Select image/docs/videos',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.testcase.attachment=null
                }
        },
        onZoom(e){
            this.getLogtimeDownloadLink(e, 'zoom');
        },
        
        previewpdf(geturl)
        {
            window.open(geturl, "_blank");
        }
    },
    filters: {
        basename: function (value) {
            if (!value) return ''
            return value.substr(value.lastIndexOf("/") + 1)
        }
    }
}
</script>
<style>
.ck-content{
    min-height: 150px !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
.display{
    display: none !important;
}

.pop-up {
    width: 70% !important;
}
.click-zoom input[type=checkbox] {
  display: none;
}

.click-zoom img {
  /* margin: 100px; */
  transition: transform 0.25s ease;
  cursor: zoom-in;
}

.click-zoom input[type=checkbox]:checked~img {
  transform: scale(10);
  cursor: zoom-out;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
}
</style>