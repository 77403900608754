<template>
<div>
    <div class="container-md mx-auto">
    <nav class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow mx-auto mt-0" style="margin-top:5px;">
        <div class="navbar-container d-flex content">
                <div class="bookmark-wrapper d-flex align-items-center">
                    <ul class="nav navbar-nav d-xl-none">
                        <li class="nav-item"><a class="nav-link menu-toggle" href="#">
                            <menu-icon size="1.5x" class="custom-class ficon"></menu-icon>
                            </a></li>
                    </ul>
                </div>
                <div>
                    <a class="brand-logo" href="#">
                        <img alt="" src="../../public/images/logo/firstcry_login2.png"  height="50px">
                    </a>
                </div>
        </div>        
    </nav>
    </div>
    <div class="container-md mx-auto">
    <div class="app-content content ml-0 px-1">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div id="user-profile">
                <div class="row">
                    <div class="col-12">
                        <div class="card profile-header mb-1 bg-white p-1 mt-1">
                            <img  class="card-img-top card-img-top-profile-header" src="../../public/images/portrait/small/banner.png" alt="User Profile Image" style="background-color: rgba(115, 103, 240, 0.12) !important;background-size: cover;"/>
                            

                            <div class="position-relative">
                                <div class="profile-img-container d-flex align-items-center">
                                    <div class="profile-img">
                                        <img  :src="profileurl" class="rounded img-fluid" alt="Card image" />
                                    </div>
                                    <div class="profile-title ml-2">
                                        <h2 class="text-primary capitalisetext">{{profiledata.firstname}} {{profiledata.lastname}}</h2>
                                        <p class="text-primary capitalisetext mb-0">{{decryptText(profiledata.designation)}}</p>
                                    </div>
                                </div>
                            </div>

                            
                            <div class="profile-header-nav">
                               
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <section class="app-user-view">
                    <div class="row" style="display:none" >
                        <div class="col-xl-9 col-lg-8 col-md-7">
                            <div class="card user-card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-12 d-flex flex-column justify-content-between border-container-lg">
                                            <div class="user-avatar-section">
                                                <div class="d-flex justify-content-start">
                                                    <img alt="" class="rounded" :src="profileurl" height="104" width="104" :alt="profiledata.firstname" />
                                                    <div class="d-flex flex-column ml-1">
                                                        <div class="user-info mb-1">
                                                            <h4 class="mb-0 capitalisetext">{{profiledata.firstname}} {{profiledata.lastname}}</h4>
                                                            
                                                        </div>
                                                        <div class="user-info mb-1">
                                                            <h6 class="mb-0 capitalisetext">{{decryptText(profiledata.designation)}}</h6>
                                                            <span class="card-text capitalisetext">{{decryptText(profiledata.department)}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-12 mt-2 mt-xl-0">
                                            <div class="user-info-wrapper">
                                                <div class="d-flex flex-wrap">
                                                    <div class="user-info-title">
                                                        <user-icon size="1.5x" class="custom-class mr-1"></user-icon>
                                                        <span class="card-text user-info-title font-weight-bold mb-0 capitalisetext">Username</span>
                                                    </div>
                                                    <p class="card-text mb-0">{{profiledata.emailid}}</p>
                                                </div>
                                                <div class="d-flex flex-wrap my-50">
                                                    <div class="user-info-title">
                                                         <check-circle-icon size="1.5x" class="custom-class mr-1"></check-circle-icon>
                                                        <span class="card-text user-info-title font-weight-bold mb-0">Status</span>
                                                    </div>
                                                    <p class="card-text mb-0">Active</p>
                                                </div>
                                                <div class="d-flex flex-wrap my-50">
                                                    <div class="user-info-title">
                                                         <star-icon size="1.5x" class="custom-class mr-1"></star-icon>
                                                        <span class="card-text user-info-title font-weight-bold mb-0">Role</span>
                                                    </div>
                                                    <p class="card-text mb-0 capitalisetext">{{decryptText(profiledata.department)}}</p>
                                                </div>
                                                <div class="d-flex flex-wrap my-50">
                                                    <div class="user-info-title">
                                                        <flag-icon size="1.5x" class="custom-class  mr-1"></flag-icon>
                                                        <span class="card-text user-info-title font-weight-bold mb-0">Country</span>
                                                    </div>
                                                    <p class="card-text mb-0 capitalisetext">{{profiledata.country}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>
                    <div class="row">
                         <div class="col-md-4"
                          v-if="educationList || experienceList || skilsList || awardList || courseList || certificateList || topskills || indkills || techkills"  >
                            <div class="card position-static mb-1">
                                <div class="card-body ">
                                    
                                    <div class="mt-2">
                                    <h5 class="mb-75">Joined:</h5>
                                    <p class="card-text">{{profiledata.joiningdate}}</p>
                                    </div>
                                    
                                    <div class="mt-2">
                                    <h5 class="mb-75">Role:</h5>
                                    <p class="card-text capitalisetext">
                                        {{decryptText(profiledata.designation)}}
                                        </p>
                                    </div>
                                    <div class="mt-2">
                                    <h5 class="mb-75">Email:</h5>
                                    <p class="card-text">
                                        <a :href="`mailto:${profiledata.emailid}`" class="text-secondary">
                                        {{profiledata.emailid}}
                                        </a>
                                        </p>
                                    </div>
                                   
                                </div>
                            </div>

                            <div class="card position-static user_profile_heightveiw" >
                                <div class="card-body">
                                    <h5>My Colleague</h5>
                                    <div v-for="(data,index) in colleague" v-bind:key="index">
                                        <div class="d-flex justify-content-start align-items-center mt-1">
                                        <div class="avatar mr-75 colleagueimage">
                                            <profilepic v-if="data.imagename && data.employeecode" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${index}`"></profilepic>
                                            <img alt="" v-if="!data.imagename" :src="defaultimagepath" width="24" height="24" />
                                        </div>
                                        <div class="profile-user-info">
                                            <h6 class="mb-0 text capitalisetext">{{data.label}}</h6>
                                            <small class="text-muted capitalisetext">{{decryptText(data.designation)}}</small>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card position-static capitalisetext"  id="profile-info" style="display:" v-if="this.recommendation.length !=0">
                                <div class="card-body">
                                    <h5>Recommendation</h5>
                                    
                                        <div class="profile-twitter-feed mt-1" v-for="(data,index) in recommendation" v-bind:key="index">
                                        <div class="d-flex justify-content-start align-items-center mb-1">
                                            <div class="avatar mr-75 recommendation">
                                                <profilepic v-if="data.recommendedbyimg && data.employeecode" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.recommendedbyimg" :key="`${index}`"></profilepic>
                                                <img alt="" v-if="!data.recommendedbyimg" :src="defaultimagepath"  width="40" height="40" />
                                            </div>
                                            <div class="profile-user-info">
                                                <h6 class="mb-0">{{data.recommendedby}}</h6>
                                                <a href="javascript:void(0)">
                                                   <small class="text-muted capitalisetext">{{decryptText(data.designation)}}
                                                   </small>
                                                </a>
                                            </div>
                                           
                                        </div>
                                        <p class="card-text mb-0">{{data.message}}</p><hr>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div v-else class="col-md-12 card-columns"  >

                            <div class="card position-static">
                                <div class="card-body">
                                    
                                    <div class="mt-2">
                                    <h5 class="mb-75">Joined:</h5>
                                    <p class="card-text">{{profiledata.joiningdate}}</p>
                                    </div>
                                    
                                    <div class="mt-2">
                                    <h5 class="mb-75">Role:</h5>
                                    <p class="card-text">
                                        {{decryptText(profiledata.department)}}
                                        </p>
                                    </div>
                                    <div class="mt-2">
                                    <h5 class="mb-75">Email:</h5>
                                    <p class="card-text">
                                        <a :href="`mailto:${profiledata.emailid}`" class="text-secondary">
                                        {{profiledata.emailid}}
                                        </a>
                                        </p>
                                    </div>
                                  
                                </div>
                            </div>

                            <div class="card position-static user_profile_heightveiw">
                                <div class="card-body">
                                    <h5>My Colleague </h5>
                                    <div v-for="(data,index) in colleague" v-bind:key="index">
                                        <div class="d-flex justify-content-start align-items-center mt-1">
                                        <div class="avatar mr-75 colleagueimage">
                                            <profilepic v-if="data.imagename && data.employeecode" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${index}`"></profilepic>
                                            <img alt="" v-if="!data.imagename" :src="defaultimagepath" width="40" height="40" />
                                        </div>
                                        <div class="profile-user-info">
                                            <h6 class="mb-0 text capitalisetext">{{data.label}}</h6>
                                            <small class="text-muted capitalisetext">{{decryptText(data.designation)}}</small>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card position-static"  id="profile-info" style="display:" v-if="this.recommendation.length !=0">
                                <div class="card-body">
                                    <h5>Recommendation</h5>
                                   
                                        <div class="profile-twitter-feed mt-1" v-for="(data,index) in recommendation" v-bind:key="index">
                                        <div class="d-flex justify-content-start align-items-center mb-1">
                                            <div class="avatar mr-75 recommendation">
                                                <profilepic v-if="data.recommendedbyimg && data.employeecode" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.recommendedbyimg" :key="`${index}`"></profilepic>
                                                <img alt="" v-if="!data.recommendedbyimg" :src="defaultimagepath"  width="40" height="40" />
                                            </div>
                                            <div class="profile-user-info">
                                                <h6 class="mb-0">{{data.recommendedby}}</h6>
                                                <a href="javascript:void(0)">
                                                   <small class="text-muted capitalisetext">{{decryptText(data.designation)}}
                                                   </small>
                                                    
                                                </a>
                                            </div>
                                            
                                        </div>
                                        <p class="card-text mb-0">{{data.message}}</p><hr>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>


                       
                        


                        <div class="col-md-8">
                    <div class="row" v-if="educationList" >
                        <div class="col-md-12" >
                            <div class="card mb-1">
                                <div class="card-header p-1">
                                    <h4 class="card-title sitebluecolor">
                                        <div class="avatar bg-light-primary avatar-lg mr-50">
                                            <span class="avatar-content"> <book-open-icon size="1x" class="custom-class font-medium-2"></book-open-icon></span>
                                        </div>
                                   Education</h4>
                                </div>
                                <div class="card-body"  style="margin-top:15px;">
                                    <ul class="timeline">
                                        <li class="timeline-item"  v-for="(data,i) in educationList" v-bind:key="data.educationid">
                                             <span v-if="i==1" class="timeline-point timeline-point-indicator"></span>
                                            <span v-if="i%2==0" class="timeline-point  timeline-point-warning timeline-point-indicator"></span>
                                            <span v-else class="timeline-point  timeline-point-info timeline-point-indicator"></span>
                                            <div class="timeline-event">
                                                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 capitalisetext">
                                                    <h6>{{data.school}}</h6>
                                                    <span class="timeline-event-time">{{data.startdate}}</span>
                                                </div>
                                                <p class="mb-25 text-muted capitalisetext">{{data.degree}}</p>
                                                <div class="media align-items-center text-muted">
                                                    <div class="media-body capitalisetext">{{data.field_of_study}}</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="experienceList">
                        <div class="col-md-12">
                            <div class="card mb-1">
                                <div class="card-header p-1">
                                    <h4 class="card-title sitebluecolor">
                                        <div class="avatar bg-light-primary avatar-lg mr-50">
                                            <span class="avatar-content"> <star-icon size="1x" class="custom-class font-medium-2"></star-icon></span>
                                        </div>
                                        Experience</h4>
                                </div>
                                <div class="card-body" style="margin-top:15px;">
                                    <ul class="timeline">
                                        <li class="timeline-item capitalisetext"  v-for="(data,i) in experienceList" v-bind:key="data.experienceid">
                                            <span v-if="i==1" class="timeline-point timeline-point-indicator"></span>
                                            <span v-if="i%2==0" class="timeline-point  timeline-point-warning timeline-point-indicator"></span>
                                            <span v-else class="timeline-point  timeline-point-info timeline-point-indicator"></span>
                                            <div class="timeline-event">
                                                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 capitalisetext">
                                                    <h6>{{data.company}}</h6>
                                                    <span class="timeline-event-time">{{data.startdate}}</span>
                                                </div>
                                                <p class="mb-25 text-muted capitalisetext">{{data.designiation}}</p>
                                                <div class="media align-items-center">
                                                    <div class="media-body text-muted capitalisetext">{{data.employement_type}}</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="row" v-if="skilsList">
                        <div class="col-md-12">
                            <div class="card mb-1">
                                <div class="card-header p-1">
                                    <h4 class="card-title sitebluecolor">
                                        <div class="avatar bg-light-primary avatar-lg mr-50">
                                            <span class="avatar-content"> <link-icon size="1x" class="custom-class font-medium-2"></link-icon></span>
                                        </div>
                                        Skills &amp; Endorsements</h4>
                                </div>
                                <div class="card-body" style="margin-top:15px;">
                                    <div v-for="data in topskills" v-bind:key="data.id">
                                    <h6>
                                        <chevrons-right-icon size="2x" class="custom-class font-medium-2 mr-75 text-secondary"></chevrons-right-icon>{{data.skills}}</h6>
                                    <hr />
                                    </div>
                                    <div v-for="data in indkills" v-bind:key="data.id">
                                    <h6><chevrons-right-icon size="2x" class="custom-class font-medium-2 mr-75 text-secondary"></chevrons-right-icon>{{data.skills}}</h6>
                                    <hr />
                                    </div>
                                    <div v-for="data in techkills" v-bind:key="data.id">
                                    <h6><chevrons-right-icon size="2x" class="custom-class font-medium-2 mr-75 text-secondary"></chevrons-right-icon>{{data.skills}}</h6>
                                    <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="awardList">
                        <div class="col-md-12">
                            <div class="card mb-1">
                                <div class="card-header p-1">
                                    <h4 class="card-title sitebluecolor"> 
                                        <div class="avatar bg-light-primary avatar-lg mr-50">
                                            <span class="avatar-content"> <award-icon size="1x" class="custom-class font-medium-2"></award-icon></span>
                                        </div>
                                        Honors and Awards</h4>
                                </div>
                                <div class="card-body" style="margin-top:15px;">
                                    <div v-for="data in awardList" v-bind:key="data.awardsid">
                                    <h6><chevrons-right-icon size="2x" class="custom-class font-medium-2 mr-75 text-secondary capitalisetext"></chevrons-right-icon>{{data.title}} - {{data.issuer}} ({{data.issuedate}})</h6>
                                    <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="courseList">
                        <div class="col-md-12">
                            <div class="card mb-1">
                                <div class="card-header p-1">
                                    <h3 class="card-title sitebluecolor">
                                        <div class="avatar bg-light-primary avatar-lg mr-50">
                                            <span class="avatar-content"> <pen-tool-icon size="1x" class="custom-class font-medium-2"></pen-tool-icon></span>
                                        </div>Courses</h3>
                                </div>
                                <div class="card-body" style="margin-top:15px;">
                                    <div v-for="data in courseList" v-bind:key="data.courseid">
                                    <h6><chevrons-right-icon size="2x" class="custom-class font-medium-2 mr-75 text-secondary capitalisetext"></chevrons-right-icon>{{data.course_name}}</h6>
                                    <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="certificateList">
                        <div class="col-md-12">
                            <div class="card mb-1">
                                <div class="card-header p-1">
                                    <h3 class="card-title sitebluecolor"> 
                                        <div class="avatar bg-light-primary avatar-lg mr-50">
                                            <span class="avatar-content"> <check-circle-icon size="1x" class="custom-class font-medium-2"></check-circle-icon></span>
                                        </div>
                                        Certification</h3>
                                </div>
                                <div class="card-body" style="margin-top:15px;">
                                    <div v-for="data in certificateList" v-bind:key="data.certificationid">
                                    <h6><chevrons-right-icon size="2x" class="custom-class font-medium-2 mr-75 text-secondary capitalisetext"></chevrons-right-icon>{{data.title}}  - {{data.issuer}} ({{data.issuedate}})</h6>
                                    <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                        </div>
                    </div>

                
            </div>
        </div>
    </div>

    </div>
</div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../constants';
import { MenuIcon } from 'vue-feather-icons'
import { UserIcon } from 'vue-feather-icons'
import { CheckCircleIcon } from 'vue-feather-icons'
import { StarIcon } from 'vue-feather-icons'
import { FlagIcon } from 'vue-feather-icons'
import { PhoneIcon } from 'vue-feather-icons'
import { MinusSquareIcon } from 'vue-feather-icons'
import { ListIcon } from 'vue-feather-icons'
import { PlusCircleIcon,AwardIcon,LinkIcon,PenToolIcon,InfoIcon,BookOpenIcon,ChevronsRightIcon } from 'vue-feather-icons'
import { PlusSquareIcon } from 'vue-feather-icons'
import { PlusIcon } from 'vue-feather-icons'
import profilepic from './profilepic.vue';

export default({
    name:'UserProfile',
        data(){
        return{
            profileurl:apiUrl.defaultimagepath,
            defaultimagepath:apiUrl.defaultimagepath,
            profiledata:[],
            profiledata1:[],
            colleague:[],
            recommendation:[],
            educationList:null,
            experienceList:null,
            skilsList:null,
            awardList: null,
            courseList:null,
            certificateList:null,
            topskills:null,
            indkills:null,
            techkills:null,
            empid:'',
            tokendata:null,
            joiningdate:null,
            message:null,
        }
    },
    components:{
        MenuIcon,AwardIcon,LinkIcon,PenToolIcon,InfoIcon,BookOpenIcon,profilepic,
        UserIcon,
        CheckCircleIcon,
        StarIcon,
        FlagIcon,
        PhoneIcon,
        MinusSquareIcon,
        ListIcon,
        PlusCircleIcon,
        PlusSquareIcon,
        PlusIcon,
        ChevronsRightIcon
    },
    mounted(){
        var userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.empid = userdata.userid
            this.tokendata = window.localStorage.getItem('token');
            this.getUserProfileDetails(this.$route.query.empid);
            this.getUserProfile(this.$route.query.empid);
            this.getEmpListByProject(this.$route.query.empid);
        }
    },
    methods:{
        getprofiledata(){
            this.apiURL="api/master/imageupload/getPreSignedUrl"
            this.input={
                empcode:this.empid,
                useremail: this.undt.username
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.status){
                    let response = result.data.data;
                    if(response.profilepic){
                        this.profileurl=response.profilepic
                    }
                    
                }
            })
        },
        getUserProfileDetails(empid){
            this.apiURL="api/profile/getUserProfileDetails"
            this.input={
                empcodee:empid,
                empcode:this.empid,
                useremail: this.undt.username
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.status){
                    this.profiledata=result.data.data[0];
                    if(result.data.data.length >0){
                        this.empid = this.profiledata.empcode
                        this.getprofiledata()        
                        this.getexpmasterList()
                        this.getedumasterList()
                        this.getcoursemasterList()
                        this.getawardmasterList()
                        this.getcertificatemasterList()
                        this.getskillsmasterList()
                    }
                }
            })
        },
        getUserProfile(empid){
            this.apiURL="api/profile/getUserProfile"
            this.input={
                empcodee:empid,
                empcode:this.empid,
                useremail: this.undt.username
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.status){
                    if(result.data.status){
                    this.recommendation=result.data.data;
                    }
                    this.profiledata1=result.data.data[0];
                    if(result.data.data.length >0){
                        this.empid = this.profiledata1.empid
                    }
                }
            })
        },
        getEmpListByProject(empid){
            this.apiURL="api/profile/getEmpListByProject"
            this.input={
                empcodee:empid,
                empcode:this.empid,
                useremail: this.undt.username
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.status){
                    this.colleague=result.data.data;
                }
            })
        },
        async getskillsmasterList(){
            this.apiUrl="api/profile/getskilldetails";   
              
            const response = await axios({
                'method': 'POST',
                'url': this.apiUrl,
                'data':{ empid:this.empid,empcode: this.undt.userid,useremail: this.undt.username},
                'headers':{'authorization':this.tokendata}
            })         
            if(response){
                if(response.data.status){
                    this.skilsList=null;
                    this.skilsList=response.data.data;   
                    if(this.skilsList.length >0){
                        this.topskills = this.skilsList[0][1]?this.skilsList[0][1]:null
                        this.indkills = this.skilsList[0][2]?this.skilsList[0][2]:null
                        this.techkills = this.skilsList[0][3]?this.skilsList[0][3]:null
                    }
                }else{
                    this.skilsList=null;
                }
            }
        },
        async getexpmasterList(){
            this.apiUrl="api/profile/getexperiencedetails";             
            const response = await axios({
                'method': 'POST',
                'url': this.apiUrl,
                'data':{  empid:this.empid, empcode: this.undt.userid,useremail: this.undt.username},
                'headers':{'authorization':this.tokendata}
            })   
            if(response){
                if(response.data.status){
                    this.experienceList= response.data.data;   
                }else{
                    this.experienceList=null;
                }
            }
        },
        async getedumasterList(){
            this.apiUrl="api/profile/geteducationdetails";     
            const response = await axios({
                'method': 'POST',
                'url': this.apiUrl,
                'data':{  empid:this.empid, empcode: this.undt.userid,useremail: this.undt.username},
                'headers':{'authorization':this.tokendata}
            })   
            if(response){
                if(response.data.status){
                    this.educationList=response.data.data;
                }else{
                    this.educationList=null;
                }
            }
        },
        async getcoursemasterList(){
            this.apiUrl="api/profile/getcoursedetails";   
            const response = await axios({
                'method': 'POST',
                'url': this.apiUrl,
                'data':{ empid:this.empid,empcode: this.undt.userid,useremail: this.undt.username},
                'headers':{'authorization':this.tokendata}
            })        
            if(response){
                if(response.data.status){
                    this.courseList= response.data.data;
                }else{
                    this.courseList=null;
                }
            }
        },
        async getawardmasterList(){
            this.apiUrl="api/profile/getawarddetails";
            const response = await 
            axios({
                    'method': 'POST',
                    'url': this.apiUrl,
                    'data':{ empid:this.empid,empcode: this.undt.userid,useremail: this.undt.username},
                    'headers':{'authorization':this.tokendata}
                })           
                if(response){
                    if(response.data.status){
                        this.awardList= null
                        this.awardList= response.data.data;
                    }else{
                        this.awardList= null
                    }
                }
        },
        async getcertificatemasterList(){
            this.apiUrl="api/profile/getcertificationdetails";   
            const response = await axios({
                'method': 'POST',
                'url': this.apiUrl,
                'data':{ empid:this.empid,empcode: this.undt.userid,useremail: this.undt.username},
                'headers':{'authorization':this.tokendata}
            })
            if(response){
                if(response.data.status){
                    this.certificateList=null;
                    this.certificateList= response.data.data;  
                }else{
                    this.certificateList=null;
                }
            }
        },
    }
})
</script>
<style scoped>
.container-md{
    max-width: 1200px;
}
.header-navbar.floating-nav{
    max-width: 1180px;
    left: 0; 
    right: 0;
}
.user-info-title{
    width:11.78rem !important;
}
.custom-height{
    height: 2rem !important;
    width: 2rem !important;
}
#user-profile .profile-header .profile-img-container{
    bottom: 1rem;
}
.avatar.avatar-lg .avatar-content{
    width: 40px;
    height: 40px;
}
.profile-img img{
    max-height: 100%;
    margin: auto;
    display: block;
}
.colleagueimage img{
    border-radius: 0.375rem;
    width:40px !important;
    height:40px !important;
}
.recommendation img{
    width:40px !important;
    height:40px !important;
}
</style>