<template>
  <div class="app-content content ">
   <loading
     :active.sync="isLoading"
     :can-cancel="false"
     :is-full-page="true"
     color="orange"
     loader="dots"
     :width="100"
     :height="100"
   ></loading>
   <div class="content-overlay"></div>
   <div class="header-navbar-shadow"></div>
   <div class="content-wrapper">
     <section id="card-navigation modern-horizontal-wizard">
       <div class="content-header-left mb-1 pt-0 mobile-padding-0-10">
         <div class="container-fluid px-0 ">
           <div class="row breadcrumbs-top">
             <div class="col-md-9 content-header-left">
               <h3 class="content-header-title float-left mb-0">Forms Configuration</h3>
               <div class="breadcrumb-wrapper">
                 <ol class="breadcrumb">
                   <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                    <router-link :to="{ name: 'FormsConfigurationList', params: { search: true }}">Forms Configuration List</router-link>
                  </li>
                     <li class="breadcrumb-item"><router-link to='/formsconfig/create'>Create Forms Configuration</router-link></li>
                 </ol>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div class="card">
         <div class="card-body p-1">
           <form class="validate-form">
             <div class="row">
              <div class="col-12 px-0">
                <div class="form-group col-md-4 mb-2">
                  <label for="ownername">Department</label>
                  <treeselect  @input="selDepartment" placeholder="Select Department" class="projectlistdd" :options = "deparmentlist" v-model="userdeparment" v-if="deparmentlist.length > 1"/>
                  <treeselect  @input="selDepartment" placeholder="Select Department" class="projectlistdd" :options = "deparmentlist" v-model="userdeparment" disabled v-else/>
                </div>
              </div>
              <div class="col-12">

                 <template v-for="(formconfigure, index) in formconfigure.formdata">
                   <div v-bind:key="index" class="card border-warning" style=" margin-bottom: 1rem !important; ">
                     <div class="card-header p-1 cursor-pointer" v-on:click="handleCollapse(index)" style="background:#EDF4FF">
                       <h4 class="card-title addformconfiguration">Add Form Configuration {{index + 1}}</h4>
                       <div class="heading-elements text-primary" >
                         <ul class="list-inline mb-0">
                           <li>
                             <a data-action="collapse" v-if="!formconfigure.showform"><plus-icon size="4x" class="custom-class"></plus-icon></a>
                             <a data-action="collapse" v-else><minus-icon size="4x" class="custom-class"></minus-icon></a>
                           </li>
                         </ul>
                       </div>
                     </div>
                     <div v-bind:class="[{show: formconfigure.showform},card_content,collapse]">
                       <div class="card-body">
                         <form>
                           <div class="row">
                            <div class="form-group col-md-4">
                               <label for="ownername">Tracker</label>
                               <treeselect placeholder="Select Tracker" class="projectlistdd" v-model="formconfigure.tracker" :options="trackerlist" :multiple="true" :clear-on-select="true"  @close="validateFormConfig(index, $event)" @input="selTracker($event, index)" />
                               <div class="errorinputmsg" v-if="errors.formconfigure.formdata[index].tracker">{{ errors.formconfigure.formdata[index].tracker }}</div>
                             </div>
                             <div class="form-group col-md-4">
                              <div class="demo-inline-spacing custom_radio_creaternote">
                                <label for="companyname" class="font-weight-bold m-0  w-100">Project Selection</label>
                                <div class="mb-0 py-1 m-0 w-100 " style="padding-top: 6px!important;">
                                  <div class=" form-check form-check-inline mt-0 mb-0">
                                    <input class="form-check-input" type="radio" @change="onSelProject($event)"  v-model="projectflag" value="1"> <span class="pl-1" > All Project</span>
                                  </div>
                                  <div class=" form-check form-check-inline mt-0">
                                    <input class="form-check-input" type="radio" @change="onSelProject($event)" v-model="projectflag" value="0" > <span class="pl-1"> Selected Project</span>
                                  </div>
                                </div>
                              </div>
                             </div>

                             <div class="form-group col-md-4" v-if="projectflag == 0">
                               <label for="ownername">Project Name</label>
                               <treeselect   placeholder="Enter Min 4Chars To Search Project" class="projectlistdd" v-model="formconfigure.project" :multiple="true" :clear-on-select="true" v-on:search-change="getallprojectlist" :options="projectlist" @input="selectproject($event,formconfigure.project)" @close="validateFormConfig(index, $event)"/>
                               <div class="errorinputmsg" v-if="errors.formconfigure.formdata[index].project">{{ errors.formconfigure.formdata[index].project }}</div>
                              </div>

                             <div class="form-group col-md-4">
                               <label for="ownername">Header</label>
                               <treeselect   placeholder="Select KRA Header" class="projectlistdd" v-model="formconfigure.fieldheader" :options="formconfigure.fieldheaderlist" v-if="formconfigure.kraheader==true" @input="selectHeader($event, index)" />

                               <input type="text" class="form-control" name="fieldheader" placeholder="Header" v-model="formconfigure.fieldheader" @blur="validateFormConfig(index, $event)" v-else/>
                              </div>
                             <div class="form-group col-md-4">
                               <label for="title">Title</label>
                               <input type="text" class="form-control" name="title" placeholder="Title" v-model="formconfigure.title"
                               v-bind:class="{ 
                                'form-control': true,
                                '': !validText(formconfigure.title)
                                }"
                                @close="validateFormConfig(index, $event)"/>
                               <div class="errorinputmsg" v-if="errors.formconfigure.formdata[index].title && !validText(formconfigure.title)">{{ errors.formconfigure.formdata[index].title }}</div>
                             </div>
                             
                             <div class="form-group col-md-4">
                               <label for="registrar">Input Type	</label>
                               <treeselect placeholder="Select Type" class="projectlistdd" v-model="formconfigure.titletype" :options="titletypelist" @input="selectInputs($event, index)" @close="validateFormConfig(index, $event)"/>
                               <div class="errorinputmsg" v-if="errors.formconfigure.formdata[index].titletype">{{ errors.formconfigure.formdata[index].titletype }}</div>
                             </div>
                           
                            <div class="form-group col-md-4">
                               <label for="country">Validation Type	</label>
                               <treeselect placeholder="Select Validation Type" class="projectlistdd" v-model="formconfigure.titlevalidation" :options="titlevalidationlist" @input="selectval($event, index)" @close="validateFormConfig(index, $event)"/>
                               <div class="errorinputmsg" v-if="errors.formconfigure.formdata[index].titlevalidation">{{ errors.formconfigure.formdata[index].titlevalidation }}</div>
                               <div class="errorinputmsg" v-if="projectflag == 0"></div>
                             </div>

                             <div class="form-group col-md-4">
                               <label for="ownername">Placeholder</label>
                               <input type="text" class="form-control" name="fieldplaceholder" placeholder="Placeholder" v-model="formconfigure.fieldplaceholder" @blur="validateFormConfig(index, $event)"/>
                              </div>
                            
                            <div class="form-group col-md-4">
                               <label for="ownername">Index</label>
                               <input type="number" class="form-control" name="fieldindex" placeholder="Index" v-model="formconfigure.fieldindex" @blur="validateFormConfig(index, $event)"/>
                                
                             </div>
                             <div class="form-group col-md-4">
                               <label for="Comment">Error Message</label>
                               <input type="text" class="form-control" name="fielderrormsg" placeholder="Error Message" v-model="formconfigure.fielderrormsg" @blur="validateFormConfig(index, $event)"/>
                              </div>
                              
                              <div class="form-group col-md-4">
                               <label for="Comment">Form Field Key</label>
                               <treeselect class="projectlistdd" name="fielderrormsg" placeholder="Form Field Key" v-model="formconfigure.fieldnamekey" :options="getbtcstatuslisting"/>
                              </div>

                              <div class="form-group  col-md-2">
                            <div class="form-group">
                              <div class="form-check">
                            <input class="form-check-input"  v-model="formconfigure.ispublish" type="checkbox"   id="myCheckbox1" >
                            <label class="form-check-label" for="myCheckbox1">
                              Is Publish 
                            </label>
                            </div>
                              </div>
                        <div class="form-group " v-if ="(formconfigure.titletype=='radio' || formconfigure.titletype == 'checkbox'  || formconfigure.titletype == 'drop-down' || formconfigure.titletype == 'multiselect-dropdown' || formconfigure.titletype == 'toggle') " >
                            
                            <div class="form-check">
                            <input class="form-check-input"  v-model="formconfigure.db_value" type="checkbox"   id="myCheckbox" checked>
                            <label class="form-check-label  " for="myCheckbox">
                            Get Value From API
                            </label>
                            </div> 
                        </div>
                        <div class="form-group">
                              <div class="form-check">
                            <input class="form-check-input"  v-model="formconfigure.fieldcomment" type="checkbox" id="myCheckbox2" >
                            <label class="form-check-label  " for="myCheckbox2">
                          Allow For Comment
                            </label>
                            </div>
                        </div>
                          <div class="form-group">
                            <div class="form-check">
                              <input class="form-check-input"  v-model="formconfigure.israting" type="checkbox" id="myCheckbox3" >
                              <label class="form-check-label  " for="myCheckbox3">
                                Is Rating
                              </label>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="form-check">
                              <input class="form-check-input"  v-model="formconfigure.isStandard" type="checkbox" id="myCheckbox4" >
                              <label class="form-check-label  " for="myCheckbox4">
                              Is Standard Field</label>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="form-check">
                              <input class="form-check-input"  v-model="formconfigure.isexport" type="checkbox" id="myCheckbox7" >
                              <label class="form-check-label  " for="myCheckbox7">
                              Is Export</label>
                            </div>
                          </div>
                          <div v-if="formconfigure.tracker && formconfigure.tracker.length>0 && formconfigure.tracker.includes(BTCListing)" class="form-group">
                            <div class="form-check">
                              <input class="form-check-input"  v-model="formconfigure.islisting" type="checkbox" id="myCheckbox5" >
                              <label class="form-check-label  " for="myCheckbox5">
                              Is Visiable for the Listing</label>
                            </div>
                          </div>
                          <div v-if="formconfigure.tracker && formconfigure.tracker.length>0 && formconfigure.tracker.includes(BTCListing)" class="form-group">
                            <div class="form-check">
                              <input class="form-check-input"  v-model="formconfigure.iseditlisting" type="checkbox" id="myCheckbox6" >
                              <label class="form-check-label  " for="myCheckbox6">
                              Is Editable from the Listing</label>
                            </div>
                          </div>
                          <div v-if="formconfigure.tracker && formconfigure.tracker.length>0 && formconfigure.tracker.includes(BTCListing)" class="form-group">
                            <div class="form-check">
                              <input class="form-check-input"  v-model="formconfigure.islistingorderby" type="checkbox" id="myCheckbox9" >
                              <label class="form-check-label  " for="myCheckbox9">
                              Is Order By Column  </label>
                            </div>
                          </div>
                      </div>
                             
                             
                              <div class="form-group  col-sm-6" v-if="projectflag == 0">
                              <div class="row mx-0">
                                <div class="col-md-12 px-0">
                                  <div class="tab-content" id="projectroleconfigtable">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                      <div class="row" id="proModuleList">
                                        <div class="col-12">
                                          <div class="mb-4">
                                            <div class="table-responsive " style="overflow: unset;">
                                              <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                <thead>
                                                  <tr>
                                                    <th scope="col" class="text-center first-col-sticky align-middle" style="min-width:45px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
                                                    <th scope="col" class="align-middle" style="width: 180px;">Project Name</th>
                                                    <th scope="col" class="align-middle">Field Validation</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <template v-for="(pro, index) in projectselectionlist">
                                                    <tr v-bind:key="index">
                                                      <td class="text-center first-col-sticky m-0">{{ index + 1 }}</td>
                                                      <td>
                                                        <span class="text-center text-uppercase">{{ pro.label }}</span>
                                                      </td>
                                                      <td>
                                                        <div class="form-group col-md-12">
                                                          <treeselect
                                                            placeholder="Select Validation Type"
                                                            class="projectlistdd"
                                                            v-bind:id="'checkallusers' + index"
                                                            v-model="pro.projectselectionValidation"
                                                            :options="titlevalidationlist"
                                                            @input="onfilterchange(pro, index)"
                                                          />
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </template>
                                                  <template v-if="projectselectionlist.length === 0">
                                                    <tr><td colspan="3" style="text-align: center;">Please Select Projects</td></tr>
                                                  </template>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="pageCenter text-center">
                                    </div>
                                  </div>
                              </div>
                            </div>
                          
                          </div>

                             
                          <div class="form-group col-md-8" >
                
                        <div class="row" v-for="(singleDocField, idx) in formconfigure.optionvalue" :key="`phoneInput-${idx}`"  v-if ="(formconfigure.titletype=='radio' || formconfigure.titletype == 'checkbox' || formconfigure.titletype == 'drop-down' || formconfigure.titletype == 'multiselect-dropdown' || formconfigure.titletype == 'toggle') && formconfigure.db_value==false ">
                  
                          <div class="form-group col-md-6" >
                            <label class="form-label">Option Values </label>
                            <input class="form-control" v-model="singleDocField.optionvalue" type="text" placeholder="Enter Option values"  />
                            <div class="errorinputmsg" v-if="!singleDocField.optionvalue">Option value is required</div>
                          </div>
                      
                          <div class="form-group  mt-2">
                            <label class="form-label"> &nbsp; </label>
                              <div class="btn btn-relief-danger " @click="removeDocumentField(index,idx)">
                                <minus-square-icon size="1.5x" class="" ></minus-square-icon>
                              </div>
                            <div class="form-group  ml-1 float-right"  v-if ="(formconfigure.titletype=='radio' || formconfigure.titletype == 'checkbox' || formconfigure.titletype == 'drop-down' || formconfigure.titletype == 'multiselect-dropdown' || formconfigure.titletype == 'toggle') && formconfigure.db_value==false">  
                              <div class="btn btn-relief-primary mr-4 " @click="addDocumentFields(index)">
                                <span><plus-square-icon size="1.5x"></plus-square-icon> </span>
                              </div>
                            </div>
                          </div>

                          </div>
                        </div>
                          </div>
                           <div class=" mb-0 mt-0 text-right">
                             <div class="btn btn-outline-info mr-1" style="padding-left: 1.4rem; padding-right: 1.4rem" v-on:mousedown="clearField(index)">
                               <span>Clear</span>
                             </div>
                             <div v-if="index !== 0" class="btn btn-outline-danger"  style="padding-left: 1rem; padding-right: 1rem" @click="deleteField(index)">
                               <span>Delete</span>
                             </div>
                           </div>
                         </form>
                       </div>
                     </div>
                   </div>
                 </template>
               </div>
             </div>
           </form>
         </div>
         <div class="flex--items mb-2 px-1 text-right">
           
           <button type="submit" class="btn btn-relief-primary" style="padding-left: 2rem; padding-right:2rem" v-on:click.stop.prevent="saveData()">Submit</button>
           
         </div>
       </div>
     </section>
   </div>
 </div>
</template>

<script>
import axios from 'axios'
import VueElementLoading from 'vue-element-loading';
import Treeselect from '@riophae/vue-treeselect'
import Loading from 'vue-loading-overlay';
import DatePicker from "vue2-datepicker";
import 'vue-loading-overlay/dist/vue-loading.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import "vue2-datepicker/index.css";
import { PlusIcon, StarIcon, MinusIcon,MinusSquareIcon,PlusSquareIcon } from 'vue-feather-icons'
import moment from "moment";
import apiUrl from "../../constants";
import commonMethods from '../../utils/commonMethods';

export default {
 name: 'CreateFormConfiguration',
 mixins: [ commonMethods ],

 components: {
   VueElementLoading,
   Treeselect,
   DatePicker,
   Loading,
   PlusIcon,
   MinusIcon,
   StarIcon,
   MinusSquareIcon,
   PlusSquareIcon,  
 },
 data() {
   return {
    document: {
         fields: [{
          optionvalue: ''       
        }]
      },
      trackerlist:[],
      formconfigure: {
       formdata: [
         {
           title: null,
           titletypelist: null,
           titlevalidation: null,
           tracker:null,
           optionvalue: [{
            optionvalue: '',
           
            }],
           fieldplaceholder:null,
           fieldcomment:false,
           fieldindex:null, 
           fieldheader:null,
           fielderrormsg:null,
           db_value:false,
           ispublish:false,
           isStandard:false,
           isexport:false,
           showform:true,
           fieldheaderlist:[],
           kraheader:false,
           krafieldheader:null,
           israting:false,
           fieldnamekey : null,
           islisting: false,
           iseditlisting: false,
           islistingorderby: false,
         }
       ]
     },
     projectlist:[],
     titletypelist: [
         { label: 'Text', id: 'text'  },
         { label: 'Number', id: 'number'  },
         { label: 'Radio-button', id: 'radio'   },
         { label: 'Checkbox', id: 'checkbox'  },
         { label: 'Date', id: 'date'   },
         { label: 'Timestamp', id: 'datetime-local'  },
         { label: 'Hidden', id: 'hidden'   },
         { label: 'Drop-down', id: 'drop-down'  },
         { label: 'Multiselect-Dropdown', id: 'multiselect-dropdown'  },
         { label: 'Toggle', id: 'toggle'  },
         
     ],
     titlevalidationlist: [
         { label: 'Read-only', id: 'readonly' },
         { label: 'Hidden', id: 'hidden' },
         { label: 'optional', id: 'optional' },
         { label: 'required', id: 'required' },    
     ],
      errors: {
       formconfigure: {
         formdata: [
           {
            title: null,
            titletype: null,
            titlevalidation: null,
            tracker: null,
           optionvalue: [],
            project: null
           }
         ]
       }
     },
     isLoading: false,
     card_content:'card-content',
     collapse:'collapse',
     tab_pane:'tab-pane', 
     fade:'fade',
     TimeFormat: apiUrl.TimeFormat,
     deparmentlist:[],
     userdeparment:null,
     headerlist:[],
     userdeparmentname:null,
     projectValidation:null,
     projectflag: null,
     projectValidation:[],
     projectselectionlist: [],

     selectedproject:[],
     projectselectionValidation:[],
     storearr:[],
     finalarrayofprojectvalidation:[],
     getbtcstatuslisting: [],
     BTCListing: apiUrl.BTCListing,
   }
 },
 mounted() {
     let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
       userdata = JSON.parse(userdata)
       this.undt = userdata
       //console.log('this.undt-->',this.undt)
       this.tokendata = window.localStorage.getItem('token');
      
      this.getEmpDepartList()
      this.titletypelist.sort((a, b) => a.label.localeCompare(b.label));
      this.titlevalidationlist.sort((a, b) => a.label.localeCompare(b.label));
      }
 },
 methods: {
  getBTCSchemaDetails() {
      let url = 'api/listingconfig/getBTCSchemaDetails'
      let payload = new Object({
        useremail  : this.undt.username,
        empcode: this.undt.userid,
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        if(result.data.errorCode == 0) {
          this.getbtcstatuslisting = result.data.data.rowsData;
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.getbtcstatuslisting=[]
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
  getTrackerList(department) {
      this.isLoading = true
      let url = 'api/formconfigure/geTrackerList'
      let payload = new Object({
        moduletype: null,
        useremail  : this.undt.username,
        empcode: this.undt.userid,
        department: department
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.trackerlist = result.data.data
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.trackerlist=[]
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
  addDocumentFields(index) {
    this.formconfigure.formdata[index].optionvalue.push({
       optionvalue: null,
     })
    },
    removeDocumentField(index, idx) {
   if(this.formconfigure.formdata[index].optionvalue && this.formconfigure.formdata[index].optionvalue.length>1) {
    this.formconfigure.formdata[index].optionvalue.splice(idx,1);
      }
    },
   handleCollapse(idx) {
     this.formconfigure.formdata[idx].showform = !this.formconfigure.formdata[idx].showform
   }, 
   
   clearField(idx) {
      this.formconfigure.formdata[idx].title = null
      this.formconfigure.formdata[idx].titletype = null
      this.formconfigure.formdata[idx].titlevalidation = null
      this.formconfigure.formdata[idx].tracker = null
      this.formconfigure.formdata[idx].optionvalue = [{
      optionvalue: '',
       }],
      this.formconfigure.formdata[idx].db_value = false,
      this.formconfigure.formdata[idx].ispublish = false,
      this.formconfigure.formdata[idx].islisting = false,
      this.formconfigure.formdata[idx].iseditlisting = false,
      this.formconfigure.formdata[idx].islistingorderby = false,
      this.formconfigure.formdata[idx].isStandard = false,
      this.formconfigure.formdata[idx].isexport = false,
      this.formconfigure.formdata[idx].fieldindex = null,
      this.formconfigure.formdata[idx].fieldheader = null,
      this.formconfigure.formdata[idx].fielderrormsg = null,
      this.formconfigure.formdata[idx].fieldnamekey = null,
      this.formconfigure.formdata[idx].fieldplaceholder = null,
      this.formconfigure.formdata[idx].fieldcomment = false,
      this.formconfigure.formdata[idx].kraheader = false,
      this.formconfigure.formdata[idx].fieldheaderlist = [],
      this.formconfigure.formdata[idx].krafieldheader = null,
      this.formconfigure.formdata[idx].israting = false,

      
      this.errors.formconfigure.formdata[idx].title = ``
      this.errors.formconfigure.formdata[idx].titletype = ``
      this.errors.formconfigure.formdata[idx].titlevalidation = ``
      this.errors.formconfigure.formdata[idx].tracker = ``

   },
   deleteField(idx) {
     this.formconfigure.formdata.splice(idx, 1)
     this.errors.formconfigure.formdata.splice(idx, 1)
   },
   validateAllFormData() {
     let valid = true
     for(let idx=0; idx<this.formconfigure.formdata.length; idx++) {
       let singleformconfigureForm = this.formconfigure.formdata[idx]
       if(!singleformconfigureForm.title) {
         this.errors.formconfigure.formdata[idx].title = `Title is required`
         valid = false
       } else {
         this.errors.formconfigure.formdata[idx].title = ``
       }
       if (singleformconfigureForm.title && singleformconfigureForm.title.length<4) {
         this.errors.formconfigure.formdata[idx].title = `Min 4 char required for title`
         valid = false
        }
        if(!singleformconfigureForm.titletype) {
          this.errors.formconfigure.formdata[idx].titletype = `Input Type is required`
          valid = false
        } else {
          this.errors.formconfigure.formdata[idx].titletype = ``
        }
        
        if (this.projectflag == 0) {
          this.errors.formconfigure.formdata[idx].titlevalidation = ``

          if (this.formconfigure.formdata[idx].project == undefined || this.formconfigure.formdata[idx].project.length == 0) {
            this.errors.formconfigure.formdata[idx].project = 'Please Select project'
            valid = false
          } else {
            this.errors.formconfigure.formdata[idx].project = ''
          }
       } else {
         if(!singleformconfigureForm.titlevalidation) {
           this.errors.formconfigure.formdata[idx].titlevalidation = `Validation type is required`
           valid = false
          } else {
            this.errors.formconfigure.formdata[idx].titlevalidation = ``
          }
        }
        
        if(!singleformconfigureForm.tracker) {
          this.errors.formconfigure.formdata[idx].tracker = `Tracker is required`
          valid = false
        } else {
          this.errors.formconfigure.formdata[idx].tracker = ``
        }
        if(singleformconfigureForm.db_value==false && (singleformconfigureForm.titletype=='radio' || singleformconfigureForm.titletype == 'checkbox' || singleformconfigureForm.titletype == 'drop-down' || singleformconfigureForm.titletype == 'multiselect-dropdown' )) {
          for(let i=0;i<singleformconfigureForm.optionvalue.length;i++){
            if(!singleformconfigureForm.optionvalue[i].optionvalue){
              valid = false
            }
          }
        }
     }
    
     return valid
   },
   validateFormConfig(idx) {
     let valid = true
     if(!this.formconfigure.formdata[idx].title) {
       this.errors.formconfigure.formdata[idx].title = `Title is required`
       valid = false
     } else {
       this.errors.formconfigure.formdata[idx].title = ``
     }
     if(!this.formconfigure.formdata[idx].titletype) {
       this.errors.formconfigure.formdata[idx].titletype = `Input Type is required`
       valid = false
     } else {
       this.errors.formconfigure.formdata[idx].titletype = ``
     }
     if(this.formconfigure.formdata[idx].titletype && this.formconfigure.formdata[idx].titletype.length<4) {
       this.errors.formconfigure.formdata[idx].titletype = `Min 4 char required for title`
       valid = false
     } else {
       this.errors.formconfigure.formdata[idx].titletype = ``
     }
     if (this.projectflag == 0) {
          this.errors.formconfigure.formdata[idx].titlevalidation = ``
       } else {
        if(!this.formconfigure.formdata[idx].titlevalidation) {
          this.errors.formconfigure.formdata[idx].titlevalidation = `Validation is required`
          valid = false
        } else {
          this.errors.formconfigure.formdata[idx].titlevalidation = ``
        }
       }
     if(!this.formconfigure.formdata[idx].tracker) {
       this.errors.formconfigure.formdata[idx].tracker = `Tracker is required`
       valid = false
     } else {
       this.errors.formconfigure.formdata[idx].tracker = ``
     }
     if (this.formconfigure.formdata[idx].project == undefined || this.formconfigure.formdata[idx].project.length == 0) {
      this.errors.formconfigure.formdata[idx].project = 'Please Select project'
      valid = false
     } else {
      this.errors.formconfigure.formdata[idx].project = ''
     }
   
     return valid
   },
   addFormConfiguration(tracker) {
     this.formconfigure.formdata.push({
       title: null,
       titletype: null,
       titlevalidation: null,
        showform: true,
        optionvalue: [{
            optionvalue: '',
             }],
      fieldindex:null, 
      db_value:false,
      fieldheader:null,
      fielderrormsg:null,
      fieldplaceholder:null,
      fieldcomment:false,
      ispublish:false, 
      islisting:false, 
      iseditlisting:false, 
      islistingorderby:false,
      isStandard:false,
      isexport:false,
      fieldheaderlist:[],
      kraheader:false,
      krafieldheader:null,
      israting:false     
     })
     this.errors.formconfigure.formdata.push({
      title: null,
      titletype: null,
      titlevalidation: null,
      optionvalue: null,
      showform: true,
     })
   },
   saveData() {
     let isValid = this.validateAllFormData()

     for (const obj1 of this.selectedproject) {
      const matchingObj2 = this.storearr.find(obj2 => obj2.projectid === obj1.projectid);
      if (matchingObj2) {
          obj1.validationtype = matchingObj2.validationtype;
      }
    }

      if(isValid) {
       this.isLoading = true
       let url = 'api/formconfigure/create'
       let payload = {
         createdby: this.undt.username,
         formconfigdata: this.formconfigure.formdata,
         useremail  : this.undt.username,
         empcode: this.undt.userid,
         departmentid: this.userdeparment,
         department:this.userdeparmentname,
         projectidarray: this.selectedproject,
         projectflag: parseInt(this.projectflag)
       }
       console.log(payload,'payload------------');
      //  return
       axios({
         method: 'POST',
         url,
         data: payload,
         headers: { authorization :this.tokendata }
       }).then(result => {
         this.isLoading = false
         if(result.data.errorCode == 0) {
           Swal.fire({
             title: "Success!",
             text: result.data.msg,
             icon: 'success',
             customClass: {
               confirmButton: "btn btn-primary"
             },
             buttonsStyling: !1
           })
          this.$router.push({ name: 'FormsConfigurationList', params: { search: true }});
         } else if(result.data.errorCode == 3){
             Swal.fire({
                   title: "Session Expired...!!",
                   text: result.data.msg,
                   icon: 'info',
                   customClass: {
                       confirmButton: "btn btn-primary",
                   },
                   buttonsStyling: !1,
               }).then(function() {
                 window.location.href = "/#/login";
             })
           } else {
           Swal.fire({
             title: "Failed",
             text: result.data.msg,
             icon: 'info',
             customClass: {
               confirmButton: "btn btn-primary"
             },
             buttonsStyling: !1
           })
         }
       }).catch(e => {
          this.displayError(e)
        });
     }
   },
   validText : function(inputval) {
      var re = /[A-Za-z0-9].{4,}/;
      if(inputval){
        return re.test(inputval.toLowerCase());
      }
    },
    selectInputs(state,idx){
      this.formconfigure.formdata[idx].titletype = null
      this.formconfigure.formdata[idx].titletype = state
      if(!state) {
       this.errors.formconfigure.formdata[idx].titletype = `Input Type is required`
      } else {
        this.errors.formconfigure.formdata[idx].titletype = ``
      }
    },
    selectval(state, idx) {
      this.formconfigure.formdata[idx].titlevalidation = null
      this.formconfigure.formdata[idx].titlevalidation = state
      if(!state) {
        this.errors.formconfigure.formdata[idx].titlevalidation = `Validation is required`
     } else {
        this.errors.formconfigure.formdata[idx].titlevalidation = ``
     }
    },
    getEmpDepartList() {
      this.isLoading = true
      let url = 'api/formconfigure/getEmpDepartList'
      let payload = new Object({
        empid: this.undt.userid,
        useremail  : this.undt.username,
        empcode: this.undt.userid
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
           this.deparmentlist = result.data.data.rows;
           let decryptdepartmenttext = this.deparmentlist.map(items =>{
              items.label = this.decryptText(items.label);
              return items;
          })
          if(result.data && result.data.data){
            if(result.data.data.orows && result.data.data.orows.length >0){
              this.userdeparment = result.data.data.orows[0].deptid ? result.data.data.orows[0].deptid :null;
            }
          }
          this.getTrackerList(this.userdeparment)

          let dp = this.deparmentlist.filter((v) => {
          if(v.id==this.userdeparment)
          return v;
          });
          this.userdeparmentname = dp ? dp[0].label : null
          this.deparmentlist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.deparmentlist=[]
          this.userdeparment = null
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
    selDepartment(state) {
 
      let dp = this.deparmentlist.filter((v) => {
      if(v.id==state)
      return v;
      });
      this.userdeparmentname = dp ? dp[0].label : null

      
      this.trackerlist = []
      this.formconfigure.
       formdata =  [
         {
           title: null,
           titletypelist: null,
           titlevalidation: null,
           tracker:null,
           optionvalue: [{
            optionvalue: '',
           
            }],
           fieldplaceholder:null,
           fieldcomment:false,
           fieldindex:null, 
           fieldheader:null,
           fieldnamekey: null,
           fielderrormsg:null,
           db_value:false,
           ispublish:false,
           islisting:false,
           iseditlisting:false,
           islistingorderby:false,
           isStandard:false,
           isexport:false,
           showform:true,
          fieldheaderlist:[],
          kraheader:false,
          krafieldheader:null,
          israting:false  
         }
       ]
  
       if (!state) {
        this.trackerlist = []
        this.formconfigure.
       formdata =  [
         {
           title: null,
           titletypelist: null,
           titlevalidation: null,
           tracker:null,
           optionvalue: [{
            optionvalue: '',
           
            }],
           fieldplaceholder:null,
           fieldcomment:false,
           fieldindex:null, 
           fieldheader:null,
           fieldnamekey: null,
           fielderrormsg:null,
           db_value:false,
           ispublish:false,
           islisting:false,
           iseditlisting:false,
           isStandard:false,
           isexport:false,
           showform:true,
          fieldheaderlist:[],
          kraheader:false,
          krafieldheader:null,
          israting:false    
         }
       ]
      } else {
        this.getTrackerList(state)
       }
    },
    getKRAHeaderList(tracker,index) {
      this.isLoading = true
      let url = 'api/formconfigure/getKRAdetailList'
      let payload = new Object({
         useremail  : this.undt.username,
        empcode: this.undt.userid,
        tracker : tracker
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.formconfigure.formdata[index].fieldheaderlist = result.data.data
          this.errors.formconfigure.formdata[0].titlevalidation = ''
          } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            this.formconfigure.formdata[index].fieldheaderlist = []
         }
      }).catch(e => {
          this.displayError(e)
        });
    },
    selTracker(state,index) {
      console.log(state)
      var matchingIds = [];
      var kraheader = false;
      if(state.includes(this.BTCListing)) {
        console.log(state)
        this.getBTCSchemaDetails();
      }
      this.trackerlist.forEach(function(obj) {
        if (state.includes(obj.id)) {
          matchingIds.push(obj.type);
        }
      });
      if(matchingIds.includes('Appraisal Cycle')){
        kraheader = true
        this.formconfigure.formdata[index].kraheader = true
        this.getKRAHeaderList(state,index)
      }else{
        this.formconfigure.formdata[index].kraheader = false
      }
    },
    selectHeader(state,index){
      let krname = null
      this.formconfigure.formdata[index].fieldheaderlist.forEach(function(obj) {
        if(obj.id==state){
          krname = obj.label
        } 
      });
      this.formconfigure.formdata[index].krafieldheader = krname
    },
    getallprojectlist(node) {
      if (node && node.length>3) {
        this.isLoading = true;
        this.inputfield= {
          empcode: parseInt(this.undt.userid),
          useremail: this.undt.username,
          projectname: node.toLowerCase(),
          isfrompage:'createpage'
        }
        axios({
          'method': 'POST',
          'url': 'api/project/getallprojectlist',
          'data': this.inputfield,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => {
          this.isLoading = false;
          if(result.data.status == true){
            this.projectlist = result.data.data;
          }else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }else{
            this.projectlist = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
    },
    // selectproject($event, projectids) {
     
    //   if(projectids && projectids.length>0){
    //     this.getproject(projectids);
    //   }
    // },
    selectproject(selected, deselected) {
      // Handle selected projects
      if (selected && selected.length > 0) {
        this.getproject(selected);
      }

      // Handle deselected projects
      ;
      if (deselected && deselected.length > 0) {
        const deselectedIds = deselected.map(obj => obj.id); // Assuming obj.id holds the project ID
        this.projectlist = this.projectlist.filter(projectId => !deselectedIds.includes(projectId));
      }
      this.getproject(deselected);
    },

  getproject(projectid) {
    this.inputfield = {
      empcode: parseInt(this.undt.userid),
      useremail: this.undt.username,
      projectid: projectid,
    };

    axios({
      method: 'POST',
      url: 'api/project/getallprojectlist',
      data: this.inputfield,
      headers: { authorization: this.tokendata },
    })
    .then(result => {
      this.isLoading = false;

      if (result.data.status == true) {
        this.projectselectionlist = result.data.data;

        if (this.projectselectionlist) {
          let provalid = {};
          for (let i = 0; i < this.projectselectionlist.length; i++) {
            provalid = {
              projectid: this.projectselectionlist[i].id,
              validationtype: 'required',
            };
            this.projectValidation.push(provalid);
          }

          const seenIds = {};
          let uniqueArrayOfObjects = this.projectValidation.filter(obj => {
            if (!seenIds[obj.projectid]) {
              seenIds[obj.projectid] = true;
              return true;
            }
            return false;
          });

          // Keep only the selected project IDs in this.selectedproject
          this.selectedproject = uniqueArrayOfObjects.filter(obj => {
            return projectid.includes(obj.projectid);
          });
        }
      } else if (result.data.errorCode == 3) {
        Swal.fire({
          title: 'Session Expired...!!',
          text: result.data.msg,
          icon: 'info',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: !1,
        }).then(function() {
          window.location.href = '/#/login';
        });
      } else {
        this.projectselectionlist = [];
      }
    }).catch(e => {
          this.displayError(e)
        });
  },
  onfilterchange(project, index) {
      const provlaid = {
        projectid: project.id,
        validationtype: project.projectselectionValidation,
      };

      const existingIndex = this.storearr.findIndex(obj => obj.projectid === project.id);

      if (existingIndex !== -1) {
        this.storearr[existingIndex].validationtype = project.projectselectionValidation;
      } else {
        this.storearr.push(provlaid);
      }

      this.storearr = this.storearr.filter(obj => obj.validationtype !== undefined);
    },

    onSelProject(event) {
    this.isLoading = true;
    if (event.target.defaultValue == 0) 
    this.errors.formconfigure.formdata[0].titlevalidation = ''
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },
 }
}
</script>
 