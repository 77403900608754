<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h3 class="content-header-title float-left mb-1">Ticket Request Management</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'TicketList', params: { search: true }}">Ticket Request Management List</router-link></li>
                <li class="breadcrumb-item sitebluecolor cursorpointercls">Update Ticket</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
      <div v-bind:class="[{show: isRequestActive},card_content,collapse]" style="margin-top:10px;">
        <div class="card-body">
          <form class="form form-vertical">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mg-bot0">
                  <label for="projectlistdd">Ticket Name</label>
                  <input type="text" id="issuename" class="form-control" name="" placeholder="Ticket name" v-model="ticket.issuename" @blur="validateForm('t42',$event)"/>
                </div>
                <div class="errorinputmsg" v-if="this.errors.ticket.issuename">{{ this.errors.ticket.issuename }}</div>
              </div>
              <div class="col-md-6">
                <div class="form-group mg-bot0">
                  <label for="projectmdllistdd">Project</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="ticket.project" :options="ticket.projectlist" @select="selissuecsproject" :disabled="this.isThisMerchandiseProject" @close="validateForm('t39',$event)"/>
                </div>
                <div class="errorinputmsg" v-if="this.errors.ticket.project">{{ this.errors.ticket.project }}</div>
              </div>
              <div class="col-md-6" v-if="this.ticket.project == merchandisingProId">
                <div class="form-group mb-1">
                    <label for="projectmdllistdd">Brand</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Brand" v-model="ticket.projectmodulename" :options="ticket.projectmodulelist" @input="selProjectModule" @close="validateForm('t39', $event)"/>
                    <div class="errorinputmsg" v-if="this.errors.ticket.projectmodulename">{{ this.errors.ticket.projectmodulename }}</div>
                </div>
                </div>
                <div class="col-md-6" v-if="this.ticket.project == merchandisingProId">
                <div class="form-group mb-1">
                    <label for="projectmdllistdd">Category</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Category" v-model="ticket.applicationname" :options="ticket.applicationlist" @input="selApplication" @close="validateForm('t39', $event)"/>
                    <div class="errorinputmsg" v-if="this.errors.ticket.applicationname">{{ this.errors.ticket.applicationname }}</div>
                </div>
                </div>
                <div class="col-md-6" v-if="this.ticket.project == merchandisingProId">
                <div class="form-group mb-1">
                    <label for="projectmdllistdd">Sub Category</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Sub Category" v-model="ticket.applicationmodulename" :options="ticket.applicationmodulelist" @input="selApplicationModule" @close="validateForm('t39', $event)"/>
                    <div class="errorinputmsg" v-if="this.errors.ticket.applicationmodulename">{{ this.errors.ticket.applicationmodulename }}</div>
                </div>
                </div>
                <div class="col-md-6" v-if="this.ticket.project == merchandisingProId">
                <div class="form-group mb-1">
                    <label for="projectmdllistdd">Product ID/ Product Group ID</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Product ID/ Product Group ID" v-model="ticket.sectionmodulename" :options="ticket.sectionmodulelist" @input="selSectionModule" @close="validateForm('t39', $event)"/>
                    <div class="errorinputmsg" v-if="this.errors.ticket.sectionmodulename">{{ this.errors.ticket.sectionmodulename }}</div>
                </div>
                </div>
                <div class="col-md-6">                    
                    <div class="form-group mb-1" v-if="this.ticket.project == merchandisingProId  && ticket.sectionmodulename">
                        <label for="projectmdllistdd">
                            <small>{{productlabelname}}</small>
                        </label>
                        <input type="text" id="issuenameadd" class="form-control" name=""  :placeholder="productlabelname" v-model="ticket.issuenameaddnew" />
                        <div class="errorinputmsg" v-if="this.errors.ticket.issuenameaddnew">{{ this.productlabelname }} is required.</div>
                    </div>
                    <div class="form-group mb-1" v-else-if="this.ticket.project != merchandisingProId">
                      <label for="projectmdllistdd">
                        <small> Functionality </small></label>
                        <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select functionality name" v-model="ticket.functionality" :options="ticket.functionalitylist" @input="selissuecsfunctionality" @close="validateForm('t42', $event)"/>
                    </div>
                </div>
                <div class="col-md-6" v-if="(this.ticket.project && this.ticket.project != merchandisingProId) || this.companycode =='xb'" >
                    <div class="form-group mb-1">
                      <label for="projectmdllistdd">Ticket Category</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Ticket Category" v-model="ticket.projectmodulename" :options="ticket.projectmodulelist" @input="selProType" @close="validateForm('t39', $event)"/>
                      <div class="errorinputmsg" v-if="this.errors.ticket.projectmodulename">{{ this.errors.ticket.projectmodulename }}</div>
                    </div>
                </div>
                <div class="col-md-6" v-if="(this.ticket.project && this.ticket.project != merchandisingProId) || this.companycode =='xb'">
                    <div class="form-group mb-1">
                      <label for="projectmdllistdd">Ticket Sub Category</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Ticket Sub Category" v-model="ticket.applicationmodulename" :options="ticket.applicationmodulelist" @input="selSubType" @close="validateForm('t39', $event)"/>
                      <div class="errorinputmsg" v-if="this.errors.ticket.applicationmodulename">{{ this.errors.ticket.applicationmodulename }}</div>
                    </div>
                </div>  
                <div class="col-md-6" v-if="!this.isThisBusinessRole">
                <div class="form-group mg-bot0">
                  <label for="projectmdllistdd">Task</label>
                  <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select task name" v-model="ticket.task" :options="ticket.tasklist" @close="validateForm('t42',$event)"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mg-bot0">
                  <label for="projectmdllistdd">Type</label>
                  <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select ticket type" v-model="ticket.type" :options="ticket.mrtypelist" @input="selissuecstype" @close="validateForm('t42',$event)"/>
                </div>
                <div class="errorinputmsg" v-if="this.errors.ticket.type">{{ this.errors.ticket.type }}</div>
              </div>
              <div class="col-md-3">
                <div class="form-group mg-bot0">
                  <label for="projectmdllistdd">Severity</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select ticket severity" v-model="ticket.sevairty" :options="ticket.sevairtylist" @input="selissuecssevairty" @close="validateForm('t42',$event)"/>
                </div>
                <div class="errorinputmsg" v-if="this.errors.ticket.sevairty">{{ this.errors.ticket.sevairty }}</div>
              </div>
              <div class="col-md-3">
                <div class="form-group mg-bot0">
                  <label for="projectmdllistdd">Priority
                    <span class="popover-block-container-custom">
                        <span class="popover-block-container-custom">
                        <button type="button" class="popover_top_note" data-toggle="modal" data-target="#ticketcreatepopover">
                            <em class="las la-info-circle text-warning font-medium-5"></em>
                        </button>
                            <div class="modal fade" id="ticketcreatepopover" tabindex="-1" role="dialog" aria-labelledby="ticketcreatepopovertext" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header ticketcreatepopoverbg">
                                            <h5 class="modal-title" id="ticketcreatepopovertext">Note</h5>
                                            </div>
                                        <div class="modal-body">
                                            <ul>
                                                <li v-for="priority in priorityDefination" :key="priority.id">
                                                    {{ priority.label }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </span>
                  </label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select ticket priority" v-model="ticket.priority" :options="ticket.prioritylist" @input="selissuecspriority" @close="validateForm('t42',$event)"/>
                </div>
                <div class="errorinputmsg" v-if="this.errors.ticket.priority">{{ this.errors.ticket.priority }}</div>
                    <div v-for="priority in priorityDefination" :key="priority.id" class="informative-msg" v-if="ticket.priority === priority.id">
                    {{ priority.label }}
                </div>
            </div>
              <div class="col-md-3" v-if="this.indItHelpDesk!=this.ticket.project">
                <div v-if="(this.ticket.projectmodulename && this.ticket.project == merchandisingProId)">
                    <div class="form-group mb-1">
                        <label for="allotedto">Assigned To</label>
                        <treeselect class="allotedto capitalisetext"  placeholder="Select assigned to" v-model="ticket.allotedto" :options="brandAllocateEmp" @input="selallotedto" @close="validateForm('t42', $event)"/>
                        <div class="errorinputmsg" v-if="this.errors.ticket.allotedto"> {{ this.errors.ticket.allotedto }}</div>
                    </div>
                </div>

                <div v-else-if="this.companycode =='fc'">
                    <div class="form-group mb-1">
                        <label for="allotedto">Assigned To</label>
                        <treeselect class="allotedto capitalisetext"  placeholder="Enter min 4char to search assigne" v-model="ticket.allotedto" :options="ticket.allotedtolist" @input="selallotedto" @close="validateForm('t42', $event)" v-on:search-change="getEmpallocatedtoProject"/>
                        <div class="errorinputmsg" v-if="this.errors.ticket.allotedto"> {{ this.errors.ticket.allotedto }}</div>
                    </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                    <label for="">Subscribers</label>
                    <div class="input-group input-group-merge">
                    <treeselect class="projroleassprojectdd" placeholder="Enter min 4char to search subscriber" :multiple="true" :clear-on-select="true" :options="ticket.subscriberslist" v-model="ticket.subscribers" @input="selSubscribers" v-on:close="validateForm('t18', $event)" v-on:search-change="getEmpallocatedtoProjectForSubscriber"/>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.ticket.subscribers">{{ this.errors.ticket.subscribers }}</div>
                    </div>
                </div>
              <div class="form-group col-12">
                <label class="form-label" for="projectmdllistdd">Scenario</label>
                <ckeditor :editor="editor"  placeholder="Ticket Scenario" v-model="ticket.scenario" :config="editorConfig" tag-name="textarea" @blur="validateForm('t42',$event)"></ckeditor>
                <div class="errorinputmsg" v-if="this.errors.ticket.scenario">{{ this.errors.ticket.scenario }}</div>
              </div>
         
              <div class="col-md-6">
                <div class="form-group mg-bot0">
                  <label for="reasonofissue">Reason of ticket</label>
                  <ckeditor :editor="editor" id="reasonofissue" class="form-control" name="" placeholder="Reason of ticket" v-model="ticket.reasonofissue"
                  :config="editorConfig" tag-name="textarea" @blur="validateForm('t42',$event)"/>
                </div>
                <div class="errorinputmsg" v-if="this.errors.ticket.reasonofissue">{{ this.errors.ticket.reasonofissue }}</div>
              </div>
              <div class="col-md-6">
                <div class="form-group mg-bot0">
                  <label for="impactofissue">Impact of ticket</label>
                  <ckeditor :editor="editor" id="impactofissue" class="form-control" name="" placeholder="Impact of ticket" v-model="ticket.impactofissue" :config="editorConfig" tag-name="textarea" @blur="validateForm('t42',$event)"/>
                </div>
                <div class="errorinputmsg" v-if="this.errors.ticket.impactofissue">{{ this.errors.ticket.impactofissue }}</div>
              </div>
              <div class="col-md-6">
                <div class="form-group mg-bot0">
                  <label for="projectmdllistdd">Status</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select ticket status" v-model="ticket.status" :options="ticket.statuslist" @input="selissuecsstatus" @close="validateForm('t42',$event)"/>
                </div>
                <div class="errorinputmsg" v-if="this.errors.ticket.status">{{ this.errors.ticket.status }}</div>
              </div>
              <div class="col-md-12">
                <div class="form-group">                                    
                    <label for="lblattachment">Attachment</label>
                </div>
                <div class="form-group">
                    <label for="attachment" class="btn btn-sm btn-primary mb-75 mr-75">Select image/docs/videos</label>
                    <button class="btn btn-sm btn-primary mb-75 mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                    <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                    <div class="table-responsive" v-if="ticket.attachmentview">
                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                            <thead>
                                <tr>
                                    <th scope="col">SR#</th>
                                    <th scope="col">Document Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, k) in ticket.attachmentview.data" :key="k">
                                    <td>{{k+1}}</td>
                                    <td>
                                        <label>
                                    <div class="click-zoom">
                                      <input type="checkbox" />
                                        <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  :src="data.presignurl"  style="width:50px;height:50px"  />
                                        <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                                        <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/pdf.png" style="width:50px;height:50px" />
                                        <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xlsx'||'csv'||'xls'" src="../../../public/images/icons/xls.png" style="width:50px;height:50px" />
                                        &nbsp;
                                        <a :href="data.presignurl" target="_blank">{{data.link | basename}}</a>
                                        </div>
                                </label>
                                    </td>
                                    <td>{{data.info}}</td>
                                    <td>
                                        <button type="button" class="btn btn-sm danger" @click="removes3file(k,ticket.attachmentview.data)">
                                          <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.ticket.attachment">{{ this.errors.ticket.attachment }}</div>
                        <div class="table-responsive" v-if="file.length>0">
                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col">SR#</th>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(image, key) in file" :key="key">
                                        <td>{{ key+1 }}</td>
                                        <td>{{ image.name }}</td>
                                        <td>
                                            <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="ticket.filedescp[key]"/>
                                        </td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>
              </div>

                <div class="col-md-12 mt-2">
                    <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bgfieldset_border_vi  business_fieldset_bg">
                                <legend>Ticket Configuration Form</legend>
                                <div class="row">
                                    <div class="col-md-4" v-for="(fieldname,i) in this.fieldnamelist" v-bind:key="i">
                                        
                                            <label for="projectlistdd" class="required-field" v-if="fieldname.validationtype=='required' && fieldname.fieldtype !='hidden'">{{fieldname.label}}:</label>
                                            <label for="projectlistdd" v-else-if="fieldname.fieldtype !='hidden'">{{fieldname.label}}:</label>
                                            <span v-if="fieldname.fieldtype=='text' && fieldname.fieldtype !='hidden'">
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @blur="validateConfigForm($event)"
                                                @input="dynamicroster"
                                                v-bind:class="{ 
                                                'form-control': true,
                                                '': !validText(fieldsnamevalues[fieldname.id])}"
                                                @close="validateConfigForm($event)"
                                                v-if="(fieldname.validationtype=='readonly')" disabled/>

                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @blur="validateConfigForm($event)"
                                                @input="dynamicroster"
                                                v-bind:class="{ 
                                                'form-control': true,
                                                '': !validText(fieldsnamevalues[fieldname.id])}"
                                                @close="validateConfigForm($event)"
                                                v-else/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span>
                                                </p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='number' && fieldname.fieldtype !='hidden'">
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @input="dynamicroster"
                                                v-bind:class="{
                                                'form-control': true,
                                                '': !validText2(fieldsnamevalues[fieldname.id])}"
                                                v-if="(fieldname.validationtype=='readonly')" disabled/>
                                                
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @input="dynamicroster"
                                                v-bind:class="{
                                                'form-control': true,
                                                '': !validText2(fieldsnamevalues[fieldname.id])}"
                                                v-else/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText2(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='drop-down' && fieldname.fieldtype !='hidden'">
                                               <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalu1" @input="dynamicroster"/>
                                               <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='multiselect-dropdown' && fieldname.fieldtype !='hidden'">
                                                <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue2" :multiple="true" :clear-on-select="true" @input="dynamicroster"/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && (!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='checkbox' && fieldname.fieldtype !='hidden'">
                                                <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue" :multiple="true" :clear-on-select="true" @input="dynamicroster"/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && (!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-else-if="fieldname.fieldtype=='radio' && fieldname.fieldtype !='hidden'">
                                                <span v-for="(radiofieldvalues,k) in fieldname.radiooptionalvalue" v-bind:key="k"><br />
                                                    <div class=" form-check form-check-inline mt-0 mb-0">
                                                        <input class="form-check-input" type="radio" v-model="fieldsnamevalues[fieldname.id]" :value="radiofieldvalues.id" @change="changevalue(fieldname.id, radiofieldvalues.id)" @input="dynamicroster"> <span class="pl-1" style="margin-left:5px; font-size: 14px;"> {{Object.values(radiofieldvalues)[0]}} </span>
                                                    </div>
                                                </span>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-else-if="fieldname.fieldtype=='date' && fieldname.fieldtype !='hidden'">
                                                <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                                class="startdate capitalisetext" :id="'EndDate'+i" :minute-step="5" v-if="(fieldname.validationtype=='readonly')" @input="dynamicroster" disabled></date-picker>
                                                <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                                class="startdate capitalisetext" :id="'EndDate'+i" @change="changevalue(fieldname.id, fieldsnamevalues[fieldname.id])" :minute-step="5" @input="dynamicroster" v-else></date-picker>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-else-if="fieldname.fieldtype=='datetime-local' && fieldname.fieldtype !='hidden'">
                                                <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-if="(fieldname.validationtype=='readonly')" disabled></vue-timepicker>
                                                <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-else></vue-timepicker>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='toggle' && fieldname.fieldtype !='hidden'">
                                                <div class="custom-control custom-switch custom-switch-secondary">
                                                    <input type="checkbox" class="custom-control-input" :id="'customSwitch11'+fieldname.id" v-model="fieldsnamevalues[fieldname.id]" @input="dynamicroster"/>
                                                    <label class="custom-control-label" :for="'customSwitch11'+fieldname.id">
                                                        <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[0].label}}</label></span>
                                                        <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[1].label}}</label></span>
                                                    </label>
                                                </div>
                                            </span>
                                            <span v-if="fieldname.fieldtype && fieldname.fieldtype =='hidden'" style="display:none;">
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" v-model="fieldsnamevalues[fieldname.id]"/>
                                            </span>
                                    </div>
                                    
                                </div>
                                </fieldset>
                            </div>

              <div class="col-12 mg-top1 text-right">
                <button type="button" class="btn btn-primary mr-1"  @click="savewizard('t42',$event)" id="submitbtnt42" v-bind:disabled="ticket.disblesavebtn" >Submit</button>
                <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
.informative-msg {
    color: #17a2b8;
    padding: 10px 0px;
    border-radius: 5px;
    font-size: 12px;
}

</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'

import Treeselect from '@riophae/vue-treeselect'
import { PlusSquareIcon, ClipboardIcon, Trash2Icon,DownloadIcon,PlusIcon,Edit2Icon,MoreVerticalIcon,PackageIcon,Link2Icon,EyeIcon,EditIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon } from 'vue-feather-icons'

import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueTimepicker from 'vue2-timepicker'
import commonMethods from '../../utils/commonMethods';

export default {
    name:'IssueCreate',
    mixins: [ commonMethods ],
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        DownloadIcon,
        Edit2Icon,
        PlusSquareIcon,
        ClipboardIcon,
        ckeditor: CKEditor.component,
        Trash2Icon,
        DatePicker,
        VueTimepicker,
    },    
    data() {
        return {
            isThisBusinessRole: false,
            roleid: 0,
            productlabelname: null,
            BRoleid: apiUrl.BRoleid,
            isInProjectAsBR : false,
            TMroleid: apiUrl.TMroleid,
            POroleid: apiUrl.POroleid,
            SupportEnquiry: apiUrl.SupportEnquiry,
            BRFeatureProList: apiUrl.BRFeatureProList,
            imgExt:['jpeg','jpg','png'],
            loginusername:'',
            loginuscondition: '',
            isLoading: false,
            fullPage: true,
            stylevariation:apiUrl.stylevariation,
            priorityDefination: apiUrl.priorityDefination,
            priority:'',
            companymasterlist: [],
            editRowData:[],
            glbMdl:'',
            glbMdlLbl:'ticket',
            TimeFormat: apiUrl.TimeFormat,
            ticket:{
                issuename:null,
                project:null,
                projectlist:null,
                subscriberslist: [],
                subscribers: null,
                functionality:null,
                functionalitylist:null,
                issuenameaddnew: null,
                type:null,
                typelist:apiUrl.ticketTypeList,
                mrtypelist:[],//apiUrl.mrticketTypelist,
                scenario:null,
                scenariolist:null,
                projectmodulename: null,
                applicationname: null,
                applicationmodulename: null,
                sectionmodulename: null,
                projectmodulelist: null,
                applicationlist: null,
                applicationmodulelist: null,
                sectionmodulelist: null,
                attachment:null,
                attachmentview:null,
                attachmentnew:null,
                sevairty:null,
                sevairtylist:null,
                priority:null,
                protype: null,
                subType: null,
                prioritylist:apiUrl.issueclassification,
                allotedto:null,
                allotedtolist:[],
                reasonofissue:null,
                impactofissue:null,
                status: null,
                statuslist: [],
                iseditstate:false,
                disblesavebtn:false,
                task: null,
                tasklist: [],
                filedescp:[],
                ticketresolutiontime: null,
                ticketresponsetime: null,
                lastprojectmodulename : null,
                lastapplicationmodulename : null,
                lastproject : null    
            },
            file:[],
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            showtabledata:true,
            showproject:false,
            modulelist:[],
            isSearchRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            errors:{
                ticket:{
                    issuenameaddnew:null,
                    issuename:null,
                    project:null,
                    functionality:null,
                    type:null,
                    scenario:null,
                    attachment:null,
                    sevairty:null,
                    priority:null,
                    allotedto:null,
                    reasonofissue:null,
                    impactofissue:null,
                    status:null,
                    task: null,
                    subscribers: null,
                    projectmodulename: null,
                    applicationname: null,
                    applicationmodulename: null,
                    sectionmodulename: null,
                    protype: null,
                    subType: null,
                }
            },
            isDetActive:true,
            isRequestActive:true,
            isedit: false,
            card:'card',
            border_warning:'border-warning',
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading', '|', 'bold',
                        'italic', '|', 'bulletedList',
                        'numberedList', '|', 'insertTable', '|',
                        'undo', 'redo', '|',
                    ],
                },
            },
            search:{
                searchprojectname :null,
                functionality: null,
                task:null,
                issuename:null,
                type:null,
                sevairty:null,
                priority:null,
                status:null,
                allotedto:null,
                projectnameList: [],
                functionalitylist: [],
                allotedtolist: [],
                tasklist: [],
            },
            projectnameList:[],
            issuenameList:[],
            view:{
              projectname: null,
              functionlityname: null,
              typename: null,
              sevairtyname: null
            },
            s3bucket: apiUrl.s3url ,
            issuenameBlured:false,
            scenarioBlured:false,
            tokendata: null,
            accepttype:apiUrl.uploadfiletype,
            merchandisingProId: apiUrl.merchandisingProId,
            isThisMerchandiseProject: false,
            sectionmoduleId1: apiUrl.sectionmoduleId1,
            sectionmoduleId2: apiUrl.sectionmoduleId2,
            validFileExtensions:apiUrl.validFileExtensions,
            brandAllocateEmp: [],
            indItHelpDesk: apiUrl.indItHelpDesk,
            ifItHelpDeskNotFound: apiUrl.ifItHelpDeskNotFound,
            itHelpDesk: apiUrl.indItHelpDesk,

            queanslist: [],
            tempcheck: [],
            fieldsnamevalues:{},
            fieldnamelist: [],
            moduletrackerid: 35,
            companycode: apiUrl.companycode,
            priorityMessages: {
                P0: "- Critical: Issues that have a severe impact on the business, causing a complete halt or critical functionality degradation. Immediate attention and availability are required.",
                P1: "- High: Significant issues that affect core functionality, but the system remains operational.",
                P2: "- Medium: Issues that impact specific features or functionalities but do not cause a system-wide disruption.",
                P3: "- Low: Minor issues or feature requests that do not significantly affect operations.",
                P4: "- Lowest: Non-urgent inquiries, general questions, or cosmetic issues."
            },
            autoassignto: null
        }
    },
    created() {
        this.pagefeatures = {}
        let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/assignments') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.loginusername= userdata.userid
            this.loginuscondition = userdata.userid
            this.roleid = this.undt.roleid
            this.getProjectAllocatedToEmp()
            this.getIssueById(this.$route.query.ticketid)
            this.isThisBusinessRole = true
            this.getApplicationdata('t44','ticket')
            this.getSubscribers(this.$route.query.ticketid, 'ticket')
            this.getorientationfeedbackformlabels(35)
        }
        $("[data-toggle=popover]").popover({
            html: true,
            trigger: "focus",
            content: function () {
                let content = $(this).attr("data-popover-content");
                return $(content).children(".popover-body").html();
            }
        });
    },
    methods:{
    getPriorityMessages() {
        let messages = Object.keys(this.priorityMessages).map(priority => {
            return `${priority}: ${this.priorityMessages[priority]}`;
        });
        return messages.join('<br>');
    },
      getIssueById(issueid){
        this.isLoading = true;
        let url = "api/issue/getIssueById";
        this.input = {
            issueid:issueid,
            useremail: this.undt.username,
            empid: parseInt(this.undt.userid),
            empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.editRowData = result.data.data;
              this.editRowData=this.editRowData[0]
              this.openEditModule(this.editRowData,0)
            } else {
                this.$router.push({ name: 'TicketList', params: { search: true }})
                Swal.fire({
                    title: "Failed!",
                    text: "Your are not authorised user to update this ticket",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
            }
          }).catch(e => {
        this.displayError(e)
      });
        },
        getSubscribers(moduleid, moduletype){
            this.isLoading = true;
            let url = "api/subscribers/getSubscribersByModuleid";
            this.input = { moduleid, moduletype,useremail: this.undt.username,empcode: this.undt.userid };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(result.data.data && result.data.data.rows){
                        let decryptdepartmenttext = result.data.data.rows.map(items =>{
                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                        if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                        })
                    }
					this.ticket.subscriberslist = result.data.data.rows;
                    this.ticket.subscribers = result.data.data.rows.map(d => d.id);
                } else {
                    this.ticket.subscriberslist = [];
                    this.ticket.subscribers = null;
                }
            }).catch(e => {
        this.displayError(e)
      });
        },
        getEmpallocatedtoProject(node){
            if (this.ticket.project  && this.ticket.project  !=this.itHelpDesk && (node && node.length > 3)) {
                this.input = { 
                    projectid:this.ticket.project ,
                    useremail: this.undt.username,
                    empcode: this.undt.userid , 
                    asigneename:node.toLowerCase() 
                }
                if(this.isThisBusinessRole) {
                    this.input.roleid = 484
                }
                this.ticket.allotedtolist = [];
                // this.ticket.subscriberslist = [];
                axios({
                    'method': 'POST',
                    'url': 'api/listing/getEmpallocatedtoProject',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => { 
                    this.isLoading= false;         
                    if(result.data.errorCode == 0){
                        if(result.data.data){
                            let decryptdepartmenttext = result.data.data.map(items =>{

                                let tempemplabel1 = items.label;
                                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                                if(tempemplabel2 && tempemplabel2 != null){
                                items.label = tempemplabel1 +'('+tempemplabel2 +')';
                                }
                                return items;
                            })
                        }
                        this.ticket.subscriberslist = result.data.data;
                        this.ticket.allotedtolist=result.data.data;
                    } else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else if (node) {
                        this.getticketcreatormanager() 
                    } 
                }).catch(e => {
        this.displayError(e)
      });
               
        	}
        },
        autoAssignItHelpDeskUser(id) {
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                projectid: id,
                roleid: 484,
                flag: 1
            };
            axios({
                method: "POST",
                url: 'api/ticket/autoAssignItHelpDeskUser',
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.ticket.allotedto = result.data.data[0].empid;
                } else {
                    this.ticket.allotedto = this.ifItHelpDeskNotFound;
                }
            }).catch(e => {
        this.displayError(e)
      });
        },
		getAllocateTo(allotedtoID) {
            this.input = { 
                projectid:this.ticket.project ,
                useremail: this.undt.username,
                empcode: this.undt.userid ,
                assignedtoid: allotedtoID,
            }
			axios({
                'method': 'POST',
                'url': 'api/listing/getEmpallocatedtoProject',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            }).then (result => {
                if(result.data.errorCode == 0){
                    this.ticket.allotedtolist=result.data.data;
                    let decryptdepartmenttext = this.ticket.allotedtolist.map(items =>{

                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                        if(tempemplabel2 && tempemplabel2 != null){
                        items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                    })
                } else {
                    this.ticket.allotedtolist =[];
                }
            }).catch(e => {
        this.displayError(e)
      });
		},
        onFileChange(e) {
        var validFileExtensions = apiUrl.validFileExtensions
        let selectedFiles = e.target.files;
            for (let i=0; i < selectedFiles.length; i++)
            {
            var blnValid = false;
            for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
                var sCurExtension = validFileExtensions[j];
                if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                    blnValid = true;
                    break;
                }
            }
                if (!blnValid) {
                    Swal.fire({
                        title: "ERROR",
                        text: "Sorry, Invalid Type of Extension File..!!",
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    return false;
                }
                this.file.push(selectedFiles[i]);
            }
            if(this.file.length>0) {
                this.ticket.disblesavebtn=true
            }
            document.getElementById('attachment').value = ""
        },
        removeImage (index) {
            if(this.file.length>0) {
                this.file.splice(index, 1);
                this.ticket.filedescp.splice(index, 1);
                var removeimg = JSON.parse(this.ticket.attachmentnew)
                if(removeimg){
                var remove = removeimg.data.splice(index,1)
                if(remove.length > 0)
                this.ticket.attachmentnew = JSON.stringify({data:removeimg.data})
                else{
                  this.ticket.attachmentnew = null
                }
              }
            }
            if(this.file.length < 1) {
                this.ticket.disblesavebtn = false
            }             
        },
        removes3file(key,data) {
            if(data.length>0) {                
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to undo!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        if(this.editRowData.issueid !== undefined) {
                            let deletefile = data[key];
                            data.splice(key, 1);
                            const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                            let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                            axios({
                            method: "POST",
                            url: 'api/issue/s3deleteissue',
                            data: {
                                issueid:this.editRowData.issueid,
                                attachment:payload,
                                deletefile: JSON.stringify(deletefile),
                                useremail: this.undt.username,
                                empcode: this.undt.userid, 
                            },
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            if (result.data.status) {
                                Swal.fire(
                                'Deleted!',
                                result.data.msg,
                                'success'
                                )
                            } else {
                            Swal.fire(
                                'Deleted!',
                                result.data.msg,
                                'error'
                                )                        
                            }
                        }).catch(e => {
                        this.displayError(e)
                        });
                        } else {
                            Swal.fire(
                                'IssueId!',
                                'Issueid should not blank',
                                'info'
                                )
                        }    
                    }
                }).catch(e => {
                this.displayError(e)
                });
            }
        },
        mydetailscollapse() {
            this.isDetActive = true
        },
        myrequestcollapse() {
            this.isRequestActive = !this.isRequestActive
            this.isedit = false
            this.ticket.iseditstate = false
            this.ticket.issuename = null
            this.ticket.project = null
            this.ticket.functionality = null
            this.ticket.type = null
            this.ticket.scenario = ''
            this.ticket.sevairty = null
            this.ticket.priority = null
            this.ticket.allotedto = null
            this.ticket.reasonofissue = null
            this.ticket.impactofissue = null
            this.ticket.status = null
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        handleCancel() {
           this.$router.push({ name: 'TicketList', params: { search: true }})     
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            this.$router.push({ 
                name: 'SectionModule',
                path: '/sectionmodule',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            this.getissuelist()
        },
        savewizard: function(mdl,objThis){           
            this.valid = this.validateForm(mdl,objThis);
            if(this.valid) {
                this.valid = this.validateConfigForm(this.valid);
            }
            if (this.valid) {
                this.isLoading = true;
                let tblNm='t42'
                if(mdl) tblNm=mdl
                this.input = {
                    tbl: tblNm,
                    section: 'ticket',
                    createdby: this.undt.username,
                    empid: this.undt.userid,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                }
                let apiURL='api/issue/create'
                this.input.issuename=this.ticket.issuename
                this.input.project=this.ticket.project
                this.input.functionality=this.ticket.functionality
                this.input.task = this.ticket.task
                this.input.type=this.ticket.type
                this.input.scenario=this.ticket.scenario
                this.input.attachment=this.ticket.attachmentnew
                this.input.sevairty=this.ticket.sevairty
                this.input.priority=this.ticket.priority
                this.input.reasonofissue=this.ticket.reasonofissue
                this.input.subscribers = this.ticket.subscribers
                this.input.impactofissue=this.ticket.impactofissue
                this.input.status=this.ticket.status
                this.input.moduletype='ticket'
                this.input.module_code = this.editRowData.issuecode
                this.input.projectmodulename = this.ticket.projectmodulename;
                this.input.applicationname = this.ticket.applicationname;
                this.input.applicationmodulename = this.ticket.applicationmodulename;
                this.input.sectionmodulename = this.ticket.sectionmodulename;
                this.input.issuenameaddnew = this.ticket.issuenameaddnew;
                this.input.fieldvalues= this.fieldsnamevalues
                this.input.trackerid = this.moduletrackerid
                if((this.autoassignto && this.autoassignto !=null) && (this.ticket.project && this.ticket.project != null) && (this.companycode && this.companycode == 'xb')){
                    this.input.allotedto = this.autoassignto;
                }else if(this.ticket.allotedto && this.ticket.allotedto !=null && this.ticket.allotedto !=undefined){
                    this.input.allotedto = this.ticket.allotedto;
                }else{
                    this.input.allotedto = this.editRowData.allotedto//this.ticket.allotedto;
                }   
                // if(this.ticket.project == this.itHelpDesk) {
                //     this.input.projectmodulename = this.ticket.protype
                //     this.input.applicationmodulename = this.ticket.subType
                // }
                if(this.ticket.iseditstate == true){
                    apiURL='api/master/commonmaster/update'
                    if(tblNm == 't42'){
                        this.input.issueid=this.editRowData.issueid
                        apiURL='api/issue/update'
                    }
                    if(this.ticket.attachmentnew && this.ticket.attachment !== null) {
                      let jsonobj1 = JSON.parse(this.ticket.attachmentnew);
                      let jsonobj2 = this.ticket.attachment;
                      let arraymerge = [...jsonobj1.data,...jsonobj2.data]
                      this.input.attachment= JSON.stringify({data: arraymerge});
                    }
                    this.input.lastmodifiedby=this.undt.username
                    delete this.input.createdby;
                }
                if(this.isedit) {
                    this.isedit = false
                    apiURL='api/master/commonmaster/update'
                    if(tblNm == 't42'){
                        this.input.issueid=this.editRowData.issueid
                        apiURL='api/issue/update'
                    }
                    this.input.lastmodifiedby=this.undt.username
                    delete this.input.createdby;
                }
                this.input.useremail= this.undt.username,
                this.input.empcode= this.undt.userid,
                this.isAddState=false
                if(this.ticket.ticketresolutiontime){
                let reshour = this.ticket.ticketresolutiontime.hours ? this.ticket.ticketresolutiontime.hours.toString().split('') : '00'.toString().split('')
                let resmin = this.ticket.ticketresolutiontime.minutes ? this.ticket.ticketresolutiontime.minutes.toString().split('') : '00'.toString().split('')
                let resolution_hours = this.ticket.ticketresolutiontime.hours ?  reshour.length < 2 ? '0'+this.ticket.ticketresolutiontime.hours: this.ticket.ticketresolutiontime.hours : '00'
                let resolution_min = this.ticket.ticketresolutiontime.minutes ?  resmin.length < 2 ? '0'+this.ticket.ticketresolutiontime.minutes : this.ticket.ticketresolutiontime.minutes : '00'
                 let ticketresolutiontime =  resolution_hours+':'+resolution_min 
                 this.input.ticketresolutiontime = this.ticket.ticketresolutiontime? ticketresolutiontime: null
                }

                   //ticket responsetime
                   if(this.ticket.ticketresponsetime){
                    let resphour = this.ticket.ticketresponsetime.hours ? this.ticket.ticketresponsetime.hours.toString().split('') : '00'.toString().split('')
                    let respmin = this.ticket.ticketresponsetime.minutes ? this.ticket.ticketresponsetime.minutes.toString().split('') : '00'.toString().split('')
                    let response_hours = this.ticket.ticketresponsetime.hours ?  resphour.length < 2 ? '0'+this.ticket.ticketresponsetime.hours: this.ticket.ticketresponsetime.hours : '00'
                    let response_min = this.ticket.ticketresponsetime.minutes ?  respmin.length < 2 ? '0'+this.ticket.ticketresponsetime.minutes : this.ticket.ticketresponsetime.minutes : '00'
                    let ticketresponsetime =  response_hours+':'+response_min 
                    this.input.ticketresponsetime = this.ticket.ticketresponsetime? ticketresponsetime: null
                }
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {       
                    if(result.data.errorCode == 0){
                      this.isLoading=false;
                      this.file =[]
                        this.ticket.filedescp =[]
                      Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                      })
                      this.$router.push({ name: 'TicketList', params: { search: true }})
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }
                }).catch(e => {
                this.displayError(e)
                });
            }
        },
        projectChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.projectnameList = result.data.data;
                } else {
                    this.projectnameList = [];
                }
                }).catch(e => {
                this.displayError(e)
                });
            } 
        },
        projectChangeSearch: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    // isfrompage:'updatepage'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.search.projectnameList = result.data.data;
                } else {
                    this.search.projectnameList = [];
                }
                }).catch(e => {
        this.displayError(e)
      });
            } 
        },
        issueChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/searching/getissuesnamelist";
                this.input = {
                issuename:node.toLowerCase(),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.issuenameList = result.data.data;
                } else {
                    this.issuenameList = [];
                }
                }).catch(e => {
                this.displayError(e)
                });
            } 
        },

        resetRecords: function() {
            this.search.searchprojectname = null;
            this.search.functionality = null;
            this.search.task= null;
            this.search.issuename= null;
            this.search.type = null;
            this.search.sevairty = null;
            this.search.priority = null;
            this.search.status = null;
            this.search.allotedto = null;
            this.getissuelist();
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{4,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validText2 : function(inputval) {
            var re = /[A-Za-z0-9].{1,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        getissuelist: function(){
            let setmoduleform='issuemaster'
            let glbMdlLblval='Ticket Master'
            this.companymasterlist = []
            let tblNm='t42'
            this.glbMdl=tblNm+'##'+setmoduleform
            this.glbst=tblNm
            if(setmoduleform){
                this.glbMdlLbl = glbMdlLblval;
            }
            let apiURL = 'api/issue/getissuelist';
            this.input = {
                empid:this.undt.userid,
                useremail: this.undt.username,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                projectname: this.search.searchprojectname,
                functionality: this.search.functionality,
                taskid: this.search.task,
                issuename: this.search.issuename,
                typename:this.search.type,
                sevairty: this.search.sevairty,
                priority: this.search.priority,
                status: this.search.status,
                allotedto: this.search.allotedto,
                empcode: this.undt.userid,
            }
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading= false;         
                this.showproject=true;   
                if(result.data.errorCode == 0){
                    this.isDetActive=true;
                    this.companymasterlist=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }else{
                    this.isDetActive=true;               
                    this.companymasterlist=[];
                    this.totalcount = 0
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                }
            }).catch(e => {
        this.displayError(e)
      });
        },
        validateForm: function(mdl,objThis){
            this.isAddState=false
            var isValid=true;
            if(mdl=="t42"){
                if(!this.ticket.issuename){
                    this.errors.ticket.issuename="Ticket name must be at least 5 characters long";
                    isValid= false;
                }

                if(this.ticket.project == this.merchandisingProId && this.ticket.sectionmodulename != null && this.ticket.sectionmodulename !=''){
                    if(!this.ticket.issuenameaddnew){
                        this.errors.ticket.issuenameaddnew= this.productlabelname + " is required";
                        isValid= false;
                    }else{
                        this.errors.ticket.issuenameaddnew="";
                    }
                }

                if(!this.ticket.project){
                    this.errors.ticket.project="Project required";
                    isValid= false;
                } else {
                    this.errors.ticket.project = null;
                }
                if(!this.ticket.type){
                    this.errors.ticket.type="Ticket type required";
                    isValid= false;
                }
                if(!this.ticket.scenario){
                    this.errors.ticket.scenario="Ticket scenario required";
                    isValid= false;
                } else {
                    this.errors.ticket.scenario="";
                }
                if(!this.ticket.allotedto){
                    this.errors.ticket.allotedto="Assigned to required";
                    isValid= false;
                } else {
                    this.errors.ticket.allotedto = "";
                }
                if (this.companycode=='xb') {
                    this.errors.ticket.allotedto = " ";
                    isValid = true;
                }
                if(!this.ticket.sevairty){
                    this.errors.ticket.sevairty="Ticket severity required";
                    isValid= false;
                }
                if (this.companycode=='xb') {
                    this.errors.ticket.sevairty = " ";
                    isValid = true;
                }
                if(!this.ticket.priority){
                    this.errors.ticket.priority="Ticket priority required";
                    isValid= false;
                }
                if (this.companycode=='xb') {
                    this.errors.ticket.priority = " ";
                    isValid = true;
                }
                if(!this.ticket.status){
                    this.errors.ticket.status="Ticket status required";
                    isValid= false;
                }
                if((!this.ticket.subscribers) || (this.ticket.subscribers && this.ticket.subscribers.length < 1)) {
                    this.errors.ticket.subscribers = 'Subscribers are required'
                    isValid = false
                } else {
                    this.errors.ticket.subscribers = ''
                }
                if (this.companycode=='xb') {
                    this.errors.ticket.subscribers = " ";
                    isValid = true;
                }
            }
            return isValid;
        },
        openEditModule: function(currRowData, flag){
            this.editRowData = currRowData
            this.view.projectname = currRowData.projectname
            this.view.functionlityname = currRowData.functionlityname
            this.view.typename = currRowData.typename
            this.view.sevairtyname = currRowData.sevairtyname
            this.view.issuecode = currRowData.issuecode
            this.ticket.issuename = currRowData.issuename
            this.ticket.projectmodulename = currRowData.projectmoduleid
            this.ticket.applicationmodulename = currRowData.applicationmoduleid
            this.ticket.project = currRowData.project;
            if(currRowData.project && currRowData.project !=null){
                this.getSLAPriorityDetails();
                this.getEmpallocatedtoProject(currRowData.project)
                this.getApplicationdata('t37','ticket')
                this.getprojectmodules(currRowData.project, 'pmodules');
                this.getprojectmodules(currRowData.project, 'app');
                this.tickettypeformrp(currRowData.project);
            }
            this.ticket.lastproject = currRowData.project
            this.ticket.functionality = currRowData.functionality
            this.ticket.issuenameaddnew = currRowData.functionlityname
            this.ticket.task = currRowData.taskid
            this.ticket.type = currRowData.issuetype
            this.ticket.scenario = currRowData.scenario
            this.ticket.attachment = currRowData.attachment
            this.ticket.lastprojectmodulename = currRowData.projectmoduleid
            this.ticket.applicationname = currRowData.applicationid
            this.ticket.lastapplicationmodulename = currRowData.applicationmoduleid
            this.ticket.sectionmodulename = currRowData.sectionmoduleid
            if (this.ticket.project == this.itHelpDesk) {
                this.ticket.protype = currRowData.projectmoduleid
                this.ticket.subType = currRowData.applicationmoduleid
            }
            if(currRowData.attachment && currRowData.attachment.hasOwnProperty('data')) {
                this.ticket.attachmentview =[];
                this.getDownloadLink(currRowData.attachment);
            }
            if(this.ticket.sectionmodulename == this.sectionmoduleId1) {
                this.productlabelname = 'Product ID';
            } else if(this.ticket.sectionmodulename == this.sectionmoduleId2) {
                this.productlabelname = 'Product Group ID';
            }
            this.ticket.sevairty = currRowData.sevairty
            this.ticket.priority = currRowData.priority
            this.ticket.allotedto = currRowData.allotedto
            this.getAllocateTo();
            this.ticket.reasonofissue = currRowData.reasonofissue
            this.ticket.impactofissue = currRowData.impactofissue
            this.ticket.status = currRowData.status
            let currStatus = currRowData.status ? currRowData.status : 'open';
            this.getStatusConfigList(this.ticket.project, currStatus);
            this.getAppModulesbyprojectid(currRowData.projectid);
            this.getSectionModules(currRowData.projectid);
            this.getBrandWiseUserDetails(this.ticket.projectmodulename, this.ticket.project)
            this.ticket.iseditstate = true
            this.isAddState=true
            if(flag==0){
                this.isedit=true
                this.isRequestActive=true
            }
            if(this.ticket.projectlist && this.ticket.projectlist.length > 0 && (this.ticket.project && this.ticket.project !=null)){
                const checkexist = this.ticket.projectlist.some(item =>item.id === this.ticket.project);
                if(!checkexist){
                    this.getProjectnamebyid(this.ticket.project);
                }
            }else if(this.ticket.projectlist.length ==0 && (this.ticket.project && this.ticket.project !=null)){
                this.getProjectnamebyid(this.ticket.project);
            }
            // if(this.companycode =='fc') {
            //     if(this.BRFeatureProList.length>0) {
            //         if(this.BRFeatureProList.includes(parseInt(currRowData.projectid))) {
            //         this.checkIsBusinessRole(currRowData.projectid);
            //         }
            //     } else {
            //         this.checkIsBusinessRole(currRowData.projectid);
            //     }
            // }

        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        getDownloadLink(data) {
            if(data.data.length>0) {
                let payload = {
                documents:data.data,
                useremail: this.undt.username,
                empcode: this.undt.userid
                };
                axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                })
                .then((result) => {
                if(result.data.status) {
                    this.ticket.attachmentview = result.data;
                }
                });
            }
        },
        checkIsBusinessRole: function(pid) {
            this.isLoading = true
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                projectid: pid,
                roleid: this.BRoleid
            }
            let apiUrl = 'api/ticket/getsupportengg'
            axios({
                method: 'POST',
                url: apiUrl,
                data: this.input,
                headers: { authorization: this.tokendata }
            }).then(result => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.isInProjectAsBR = true;
                    if(this.isInProjectAsBR == true){
                        this.ticket.type = this.SupportEnquiry;
                        this.getrolenamewithid(pid);
                    }
                } else {
                this.isInProjectAsBR = false;
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getrolenamewithid : function(projectid) {
            this.isLoading = true
            let url = "api/ticket/getProjWiseSpecificRolewithCount";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                projectid:projectid,
                roleidarr: [this.TMroleid, this.POroleid],
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(this.isInProjectAsBR == true && this.ticket.type == this.SupportEnquiry){
                        let tmroless = result.data.data.rows.filter(p => (p.roleid == this.TMroleid));
                        let prroless = result.data.data.rows.filter(p => (p.roleid == this.POroleid));
                        this.ticket.subscribers = [];
                        this.ticket.allotedtolist = [];

                        //task manager
                        if(tmroless && tmroless.length==1)
                        {
                            tmroless.forEach(element => {
                                let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                                //for subscribers
                                const found = this.ticket.subscriberslist.some(el => el.id == element.empid);
                                if(!found) {
                                    this.ticket.subscriberslist.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                                }
                                const founds = this.ticket.subscribers.some(el => el == element.empid);
                                if(!founds) {
                                    this.ticket.subscribers.push(element.empid);
                                }

                                //for alloted to
                                this.ticket.allotedtolist.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                                this.ticket.allotedto = element.empid;
                            });
                        }
                        //product owner
                        if(prroless && prroless.length>0)
                        {
                            prroless.forEach(element => {
                                let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                                const found = this.ticket.subscriberslist.some(el => el.id == element.empid);
                                if(!found) {
                                    this.ticket.subscriberslist.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                                }
                                const founds = this.ticket.subscribers.some(el => el == element.empid);
                                if(!founds) {
                                    this.ticket.subscribers.push(element.empid);
                                }
                            });
                        }
                        this.getemployeedetails(this.undt.userid);
                    }
                } else {
            }
        }).catch(e => {
            this.displayError(e)
        });
        },
        getemployeedetails: function(empid){
            this.isLoading = true;
            this.inputfield= {
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
                empid: empid,
                moduletype: 'Appraisal Cycle'
            }
            axios({
                'method': 'POST',
                'url': 'api/feedbackform/getemployeedetails',
                'data': this.inputfield,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;
                if(result.data.status == true){
                if(result.data.data.rows.length>0) {
                    result.data.data.rows.forEach(element => {
                    let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                    const found = this.ticket.subscriberslist.some(el => el.id == element.empcode);
                        if(!found) {
                            this.ticket.subscriberslist.push({"id":element.empcode,"label": element.empname +'('+tempemplabel2 +')' });
                        }
                        const founds = this.ticket.subscribers.some(el => el == element.empcode);
                        if(!founds) {
                            this.ticket.subscribers.push(element.empcode);
                        }
                    });
                }
                }else{
                this.employeedetails=[]
                }
                }).catch(e => {
                        this.displayError(e)
                });
        },
        addmodule: function(){
            this.ticket.issuename = null
            this.ticket.project = null
            this.ticket.functionality = null
            this.ticket.type = null
            this.ticket.scenario = ''
            this.ticket.attachment = null
            this.ticket.attachmentview = null
            this.ticket.sevairty = null
            this.ticket.priority = null
            this.ticket.attachmentnew= null
            this.ticket.allotedto = null
            this.ticket.reasonofissue = null
            this.ticket.impactofissue = null
            this.ticket.status = null
            this.ticket.iseditstate = false
            this.ticket.disblesavebtn=true
        },
        selissuecsproject: function(state,value){
            this.ticket.project=null
            this.ticket.project=state.id
            
            if(state && this.editRowData.project != state.id) {
                this.ticket.allotedto = null
                this.ticket.subscribers = null
                this.ticket.allotedtolist = []
                this.ticket.projectmodulelist = []
                this.ticket.projectmodulename = null
                this.ticket.applicationlist =null
                this.ticket.applicationname = null
                this.ticket.applicationmodulename = null 
                this.ticket.sectionmodulename = null
                this.ticket.issuenameaddnew = null 
                this.ticket.functionality = null 
            }
            if(!state){
                this.ticket.functionality = null
                this.ticket.task = null
                this.ticket.tasklist = null
                this.ticket.allotedto = null
                this.ticket.allotedtolist = []
                this.errors.ticket.project='Project required'
                this.ticket.functionalitylist=null
                this.ticket.statuslist = [];
                this.ticket.projectmodulename = null
                this.ticket.applicationlist =null
                this.ticket.applicationname = null
                this.ticket.applicationmodulename = null 
                this.ticket.sectionmodulename = null
                this.ticket.issuenameaddnew = null 
                this.ticket.functionality = null 
                //make sla null
                this.ticket.priority = null
                this.ticket.ticketresolutiontime = null
                this.ticket.ticketresponsetime = null          
                this.ticket.lastproject = null   
            }else{
                this.getSLAPriorityDetails()
                this.errors.ticket.project=null
                this.getEmpallocatedtoProject(state.id, 'ticket');
                if(state.id == this.indItHelpDesk) {
                    this.autoAssignItHelpDeskUser(state.id);
                }
                this.getApplicationdata('t37','ticket')
                let currStatus = this.ticket.status ? this.ticket.status : 'open';
                this.getStatusConfigList(state.id, currStatus);
                this.getprojectmodules(state.id, 'pmodules');
                this.getprojectmodules(state.id, 'app');
                this.getAppModulesbyprojectid(state.id);
                this.getSectionModules(state.id);
                if(state.id == this.merchandisingProId) {
                    $('#disabledfield').hide();
                } else {
                    $('#disabledfield').show();
                }
                this.tickettypeformrp(state.id)
                if (state.id != this.editRowData.projectid) {
                    this.ticket.type = null   
                }
                if(this.companycode =='fc') {
                    if(this.BRFeatureProList.length>0) {
                        if(this.BRFeatureProList.includes(parseInt(state.id))) {
                        this.checkIsBusinessRole(state.id);
                        }
                    } else {
                        this.checkIsBusinessRole(state.id);
                    }
                }
            }
            if(this.companycode=='xb'){
                this.getRosterDetails();
            }
        },
        getTasktypeConfigLists(projectid) {
            this.isLoading =true;
        let payload = {
            empcode: this.undt.userid,
            useremail: this.undt.username,
            projectid: parseInt(projectid.toString()),
            trackername: 'Ticket Request Type',
        };
        axios({
            method: "POST",
            url: '/api/businesstask/getTasktypeConfigList',
            data: payload,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading =false;
            if(result.data.errorCode == 0){
                let slist = [];
                result.data.data.rows.forEach((rec) => {
                    if(Object.values(rec.statusconfig).length>0){
                        Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                            if(statusnames.length>0) {
                                statusnames.forEach(val => {
                                    if(!slist.some(sdata => sdata.id === val)){
                                        slist.push({ id: val, label: val });
                                    }
                                });
                            }
                        });
                    }
                })
                if(this.ticket.project != null && this.ticket.project == apiUrl.merchandisingProId){
                    this.sortTypeList(slist, 'merchandising')
                } else{
                    this.sortTypeList(slist, 'otherprojects')
                }
            } else {
                this.ticket.mrtypelist = [];
            }
        }).catch(e => {
        this.displayError(e)
      });
        },
        sortTypeList(typelist, type){
        let numArrFromTypelist = [];
            typelist.forEach(element => {
                if(element.id != null) {
                numArrFromTypelist.push(element.id);
                }
            });
            if(numArrFromTypelist.length>0) {
                this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                moduletype: 'ticket',
                typearr: numArrFromTypelist,
                projecttype: type
                };
                axios({
                method: "POST",
                url: 'api/ticket/sortTypeList',
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.ticket.mrtypelist = result.data.data.rows;
                    this.ticket.mrtypelist.sort((a, b) => {
                  return a.label.localeCompare(b.label);
                });
                } else {
                    this.ticket.mrtypelist = [];
                }
                }).catch(e => {
        this.displayError(e)
      });
            }
        },
        tickettypeformrp(state){
            this.getTasktypeConfigLists(state);
        },
        selSectionModule(state, value) {
        this.ticket.sectionmodulename = null;
        this.ticket.sectionmodulename = state;
        if (!state) {
            this.errors.ticket.sectionmodulename = "Product ID/ Product Group ID is required";

        } else {
            if(state == this.sectionmoduleId1) {
                this.productlabelname = 'Product ID';
            } else if(state == this.sectionmoduleId2) {
                this.productlabelname = 'Product Group ID';
            }
            this.errors.ticket.sectionmodulename = null;
        }
        },
        selApplication(state, value) {
        this.ticket.applicationname = null;
        this.ticket.applicationname = state;
        if (!state) {
            this.errors.ticket.applicationname = "Category is required";

        } else {
            this.errors.ticket.applicationname = null;
        }
        },
        selProjectModule(state, value) {
        this.ticket.projectmodulename = null;
        this.ticket.projectmodulename = state;

        if (state && this.ticket.project) {
            this.getBrandWiseUserDetails(state, this.ticket.project)
        }

        if (!state) {
            this.errors.ticket.projectmodulename = "Brand is required";
        } else {
            this.errors.ticket.projectmodulename = null;
            // this.getAppModules(state);
        }
        },
        selApplicationModule(state, value) {
        this.ticket.applicationmodulename = null;
        this.ticket.applicationmodulename = state;
        if (!state) {
            this.errors.ticket.applicationmodulename = "Sub Category is required";
        } else {
            this.errors.ticket.applicationmodulename = null;
            // this.getSectionModules(state);
        }
        },
        getSectionModules(projectid) {
        this.input = {
            projectid: projectid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
        };
        axios({
            method: "POST",
            url: 'api/listing/getsectionbyprojectid',
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
            this.ticket.sectionmodulelist = result.data.data;
            } else {
            this.ticket.sectionmodulelist = [];
            }
        }).catch(e => {
        this.displayError(e)
      });
        },
        getAppModulesbyprojectid(projectid) {
        this.input = {
            projectid: projectid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
            isfrompage: 'updatepage'
        };
        axios({
            method: "POST",
            url: 'api/listing/getappmodulenamebyprojectid',
            data: this.input,
            'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.ticket.applicationmodulelist = result.data.data;
                    if(this.ticket.applicationmodulelist && this.ticket.applicationmodulelist.length > 0 && (this.ticket.applicationmodulename && this.ticket.applicationmodulename !=null)){
                        const checkexistproject = this.ticket.applicationmodulelist.some(item =>item.id === this.ticket.applicationmodulename);
                        if(!checkexistproject){
                            this.getAppModulesbyappmoduleid(this.ticket.applicationmodulename);
                        }
                    }else if(this.ticket.applicationmodulelist.length ==0 && (this.ticket.applicationmodulename && this.ticket.applicationmodulename !=null)){
                        this.getAppModulesbyappmoduleid(this.ticket.applicationmodulename);
                    }
                } else {
                    this.ticket.applicationmodulelist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getAppModulesbyappmoduleid(appmid) {
        this.input = {
            appmoduleid: appmid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
        };
        axios({
            method: "POST",
            url: 'api/listing/getappmodulenamebyprojectid',
            data: this.input,
            'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(result.data.data && result.data.data.length > 0){
                        this.ticket.applicationmodulelist.push(result.data.data[0]);
                    }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getprojectmodules(projecteid, type) {
            let url = '';
            if(type=='pmodules') {
                url = 'api/listing/getpromodulebyapplicationid';
            } else if(type=='app') {
                url = "api/listing/getappnamebyprojecteid";
            }
            this.input = {
                projecteid: projecteid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            if(type=='pmodules'){
                this.input.isfrompage = 'updatepage'
            }
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                if(type=='pmodules') {
                    this.ticket.projectmodulelist = result.data.data;
                    if(this.ticket.projectmodulelist && this.ticket.projectmodulelist.length > 0 && (this.ticket.projectmodulename && this.ticket.projectmodulename !=null)){
                        const checkexistproject = this.ticket.projectmodulelist.some(item =>item.id === this.ticket.projectmodulename);
                        if(!checkexistproject){
                            this.getprojectmodulesbyid(this.ticket.projectmodulename);
                        }
                    }else if(this.ticket.projectmodulelist.length ==0 && (this.ticket.projectmodulename && this.ticket.projectmodulename !=null)){
                        this.getprojectmodulesbyid(this.ticket.projectmodulename);
                    }
                    this.ticket.projectmodulelist.sort((a, b) => {
                        return a.label.localeCompare(b.label);
                    });
                } 
                if(type=='app') {
                    this.ticket.applicationlist = result.data.data;
                    this.ticket.applicationlist.sort((a, b) => {
                        return a.label.localeCompare(b.label);
                    });
                }
                } else {
                    this.ticket.projectmodulelist = [];
                    this.ticket.applicationlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getprojectmodulesbyid(moduleid) {
            let url = 'api/listing/getpromodulebyapplicationid';
            this.input = {
                projectmoduleid: moduleid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(this.ticket.projectmodulelist && this.ticket.projectmodulelist.length > 0){
                        const checkexistprojectmodule = this.ticket.projectmodulelist.some(item =>item.id === moduleid);
                        if(!checkexistprojectmodule){
                            this.ticket.projectmodulelist.push(result.data.data[0]);
                        }
                    }else if(this.ticket.projectmodulelist.length ==0){
                        this.ticket.projectmodulelist.push(result.data.data[0]);
                    }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getStatusConfigList(pid, status) {
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: pid,
                // trackername: 'Issue',
                trackername: 'Ticket Request',
                currStatus: status,
            };
            axios({
                method: "POST",
                url: '/api/testcase/getStatusConfigList',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    this.ticket.statuslist = result.data.data.rows;
                } else {
                    this.ticket.statuslist = [];
                }
            }).catch(e => {
        this.displayError(e)
      });
        },
        selSubscribers(state) {
            this.ticket.subscribers = null
            this.ticket.subscribers = state
            if(!state || (state && state.length < 1) && this.companycode !='xb') {
                this.errors.ticket.subscribers = 'Subscribers are required';
            } else {
                this.errors.ticket.subscribers = '';
            }
        },
        selissuecsfunctionality: function(state,value){
            this.ticket.functionality=null
            this.ticket.functionality=state
            if(!state){
                this.ticket.task = null
                this.ticket.tasklist = null
            }else{                
                this.getApplicationdata('t18', 'ticket')
            }
        },
        selissuecsprojectSearch: function(state,value){
            this.search.searchprojectname=null
            this.search.searchprojectname=state
            if(!state){
                this.search.functionality = null 
                this.search.task = null
                this.search.allotedto = null
            }else{
                this.getApplicationdata('t37','issueSearch')
            }
        },
        selissuecsfunctionalitySearch: function(state,value){
            this.search.functionality=null
            this.search.functionality=state
            if(!state){
                this.search.task = null
                this.search.tasklist = null
            }else{                
                this.getApplicationdata('t18', 'issueSearch')
            }
        },
        selissuecstype: function(state,value){
            this.ticket.type=null
            this.ticket.type=state
            if(!state && this.isAddState == false){
                this.errors.ticket.type='Type required'
            }else{                
                this.errors.ticket.type=null
            }
        },
        selissuecsscenario: function(state,value){
            this.ticket.scenario=''
            this.ticket.scenario=state
            if(!state && this.isAddState == false){
                this.errors.ticket.scenario='Scenario required'
            }else{                
                this.errors.ticket.scenario=''
            }
        },
        selissuecssevairty: function(state,value){
            this.ticket.sevairty=null
            this.ticket.sevairty=state
            if(!state && this.isAddState == false && this.companycode != 'xb'){
                this.errors.ticket.sevairty='Sevairty required'
            }else{                
                this.errors.ticket.sevairty=null
            }
        },
        selissuecspriority: function(state,value){
            this.ticket.priority=null
            this.ticket.priority=state
            if(!state && this.isAddState == false && this.companycode != 'xb'){
                this.errors.ticket.priority='Priority required'
            }else{                
                this.errors.ticket.priority=null
            }
        },
        selissuecsstatus: function(state,value){
            this.ticket.status=null
            this.ticket.status=state
            if(!state && this.isAddState == false){
                this.errors.ticket.status='Status required'
            }else{                
                this.errors.ticket.status=null
            }
        },
        selallotedto: function(state,value){
            this.ticket.allotedto=null
            this.ticket.allotedto=state
            if(!state && this.isAddState == false){
                this.errors.ticket.allotedto='Assigned To required'
            }else{                
                this.errors.ticket.allotedto=null
            }
        },
        selProType(state) {
            this.ticket.projectmodulename = null;
            this.ticket.projectmodulename = state
            if(state && this.editRowData.projectmoduleid != state) {
                  this.ticket.applicationmodulename = null  
            }
            if (!state) {
                this.errors.ticket.projectmodulename = 'Ticket Category is required'
            } else {
                this.errors.ticket.projectmodulename = null
            }
            if(this.companycode=='xb'){
                this.getRosterDetails();
            }
        },
        selSubType(state) {
            this.ticket.applicationmodulename = null;
            this.ticket.applicationmodulename = state
            
            if (!state) {
                this.errors.ticket.applicationmodulename = 'Ticket Sub Category is required'
            } else {
                this.getSLAPriorityDetails()
                this.errors.ticket.applicationmodulename = null
            }
            if(this.companycode=='xb'){
                this.getRosterDetails();
            }
        },
        getProjectAllocatedToEmp() {
            this.isLoading = true
            let url = "api/listing/getProjectAllocatedToEmp";
            this.input = {
                empid: parseInt(this.undt.userid),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                isfrompage:'updatepage'
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.ticket.projectlist = result.data.data;
                    if(this.ticket.projectlist && this.ticket.projectlist.length > 0 && (this.ticket.project && this.ticket.project !=null)){
                        const checkexistproject = this.ticket.projectlist.some(item =>item.id === this.ticket.project);
                        if(!checkexistproject){
                            this.getProjectnamebyid(this.ticket.project);
                        }
                    }else if(this.ticket.projectlist.length ==0 && (this.ticket.project && this.ticket.project !=null)){
                        this.getProjectnamebyid(this.ticket.project);
                    }
                    if(this.ticket.projectlist.length ==1){
                        this.ticket.project = this.ticket.projectlist[0].id
                        this.isThisMerchandiseProject = true
                    } else{
                        this.isThisMerchandiseProject = false
                    }
                } else {
                    this.ticket.projectlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getProjectnamebyid(pid){
            this.isLoading = true;
            this.apiURL="api/searching/getAssignedProjectList"
            this.input={
                useremail: this.undt.username,
                empid :this.undt.userid,
                empcode: this.undt.userid,
                projectid: pid,
                isfrompage: 'isprojectid'
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading = false;         
                if(result.data.errorCode == 0){
                    if(result.data.data && result.data.data.length > 0){
                        this.ticket.projectlist.push(result.data.data[0]);
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getApplicationdata: function(mdl,curracttab){            
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            let apiURL='api/master/commonmaster/list'
            if(mdl == 't11'){
                apiURL='api/listing/getprojectlist'
                this.inputappl = {
                    tbl:mdl
                }
            }else if(mdl == 't37' && curracttab == 'ticket'){
                this.ticket.functionality = null
                this.ticket.functionalitylist = []
                this.inputappl = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                apiURL = "api/listing/getfunctionalitybyprojectid";
                this.inputappl.projectid = this.ticket.project
            }else if(mdl == 't37' && curracttab == 'issueSearch'){
                this.inputappl = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                apiURL = "api/listing/getfunctionalitybyprojectid";
                this.inputappl.projectid = this.search.searchprojectname
            }else if(mdl == 't43'){
                apiURL='api/master/commonmaster/customselect'
                this.inputappl.isSearch= false
                this.inputappl.section= "issue"
                this.inputappl.isFilterSelect= 1
                this.inputappl.isFilterPayload= {}
            }else if(mdl == 't44'){
                apiURL='api/master/commonmaster/customselect'
                this.inputappl.isSearch= false
                this.inputappl.section= "issue"
                this.inputappl.isFilterSelect= 1
                this.inputappl.isFilterPayload= {}
            }else if(mdl == 't26'){
                apiURL='api/master/commonmaster/getModuleDropdownOption'
                this.inputappl = {
                    tbl: "t26"
                }
            } else if (mdl == 't18' && curracttab == 'ticket') {
                this.ticket.task = null
                this.ticket.tasklist = []
                apiURL = 'api/master/commonmaster/getModuleDropdownOption'
                this.inputappl = {
                    tbl: mdl,
                    useremail: this.undt.username,
                    functionalityid: this.ticket.functionality,
                    empcode: this.undt.userid
                }
            } else if (mdl == 't18' && curracttab == 'issueSearch') {
                apiURL = 'api/master/commonmaster/getModuleDropdownOption'
                this.inputappl = {
                    tbl: mdl,
                    useremail: this.undt.username,
                    functionalityid: this.search.functionality,
                    empcode: this.undt.userid
                }
            }
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    if(mdl == 't11'){
                        if(curracttab == 'ticket'){
                            return true
                        }
                    }else if(mdl == 't37' && curracttab == 'ticket'){
                        this.ticket.functionalitylist=result.data.data
                        this.ticket.functionalitylist.sort((a, b) => {
                            return a.label.localeCompare(b.label);
                        });
                        if(this.ticket.iseditstate) {
                            this.ticket.functionality = this.editRowData.functionality    
                        } else {
                            this.ticket.functionality = null
                            this.ticket.task = null
                        }
                        return true
                    }else if(mdl == 't37' && curracttab == 'issueSearch'){
                        this.search.functionalitylist=result.data.data
                        this.search.functionalitylist.sort((a, b) => {
                            return a.label.localeCompare(b.label);
                        });
                        return true
                    }else if(mdl == 't43'){
                        if(curracttab == 'ticket'){
                            this.ticket.typelist=result.data.data.rows
                            return true
                        }
                    }else if(mdl == 't44'){
                        if(curracttab == 'ticket'){
                            this.ticket.sevairtylist=result.data.data.rows
                            return true
                        }
                    }else if(mdl == 't26'){
                        if(curracttab == 'ticket'){
                            return true
                        }
                    } else if(mdl == 't18' && curracttab == 'ticket') {
                        this.ticket.tasklist = result.data.data
                        if(this.ticket.iseditstate) {
                            this.ticket.task = this.editRowData.taskid
                        } else {
                            this.ticket.task = null
                        }
                        return true
                    } else if(mdl == 't18' && curracttab == 'issueSearch') {
                        this.search.tasklist = result.data.data
                        return true
                    }
                } else {
                    if(mdl == 't37' && curracttab == 'ticket'){
                        this.ticket.functionalitylist=[]
                        return true
                    } else if(mdl == 't18' && curracttab == 'ticket'){
                        this.ticket.tasklist = []
                        return true
                    }
                }
            }).catch(e => {
        this.displayError(e)
      });
            // return retResponse;
        },
        onSelect: function(e){
            this.file = e.target.files[0];
        },
        onUpload(){
          if(this.file){
            this.isLoading=true
            if(this.file.length !== 0){
                let arrGlbMdl=this.glbMdl.split('##')
                let inputEle='';
                let btnEle='';
                if(arrGlbMdl.length > 0){
                    if(typeof arrGlbMdl[0] != 'undefined'){
                        inputEle=arrGlbMdl[0];
                    }
                    if(typeof arrGlbMdl[1] != 'undefined'){
                        btnEle=arrGlbMdl[1];
                    }
                }
                if(inputEle == 't42'){
                    btnEle='issuemgt'
                }
                this.apiURL="api/master/fileupload/uploadBulkFileToS3"
                let formData = new FormData();
                //   var files = this.$refs.uploadfiles.files;
                //     var totalfiles = this.$refs.uploadfiles.files.length;
                
                let maxsize = 0;
                for (let index = 0; index < this.file.length; index++) {
                    formData.append("imagefile", this.file[index]);
                    maxsize+= this.file[index].size
                }

                //formData.append("imagefile[]", this.file);
                formData.append("empcode", this.undt.userid);
                formData.append("useremail", this.undt.username);
                formData.append("moduletype", 'issuemgt');
                formData.append("fcount", parseInt(this.file.length));
                
                if (maxsize > 1024 * 1024 * 25) {
                    this.isLoading = false;
                    Swal.fire({
                        title: "Failed",
                        text: "Your document uploaded above 25mb not allow",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                    this.isLoading=false
                   this.ticket.disblesavebtn=true
                    return false; 
                }
                // formData.imagefile = this.file;
                // formData.empcode=this.undt.userid
                // formData.moduletype=btnEle
                axios({
                    'content-type':'multipart/form-data',
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':formData,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => { 
                    this.isLoading=false; 
                    this.ticket.disblesavebtn=false           
                    if(result.data.status){
                        
                        //const bindinfo = [{"link":result.data.data.filename,"info":result.data.data.filename}]
                         const bindinfo = result.data.data.map((file, index) => {
                            return {"link":file,"info":this.ticket.filedescp[index]}                
                        });
                        let jsonobj = {data:bindinfo}
                        this.ticket.attachmentnew = JSON.stringify(jsonobj);
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    } else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.ticket.attachment=null
                    }
                }).catch(e => {
        this.displayError(e)
      });
            }else{
                  this.isLoading=false; 
                Swal.fire({
                    title: "",
                    text: 'Select image/docs/videos',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                this.ticket.attachment=null
            }
          } else {
              this.isLoading=false; 
              Swal.fire({
                  title: "",
                  text: 'Select image/docs/videos',
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
              })
              this.ticket.attachment=null
          }
        },
        getBrandWiseUserDetails(brandid, projectid) {
            this.isLoading = true;
            // this.ticket.allotedto = null
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                brandid: parseInt(brandid),
                projectid: parseInt(projectid),
            }
            let apiURL = 'api/thirdparty/getBrandWiseUserDetails'

            axios({
                method: "POST",
                url: apiURL,
                data: this.input,
                headers: { authorization: this.tokendata },
            }).then((result) => {
							this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.brandAllocateEmp = result.data.data
                } else {
                    this.brandAllocateEmp = [];
                }
            })
        },
        getorientationfeedbackformlabels(trackerid) {
        this.isLoading = true
        let url = 'api/feedbackform/getorientationfeedbackformlabels'
        let payload = new Object({
            empcode: this.undt.userid,
            useremail  : this.undt.username,
            moduletracker: trackerid
        })
        axios({
            method: 'POST',
            url,
            data: payload,
            headers:{ authorization :this.tokendata }
        }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.fieldnamelist = result.data.data
                    var temArray = {};
                    for (let i = 0; i < this.fieldnamelist.length; i++) {
                        if ((this.fieldnamelist[i].fieldheader ==null || this.fieldnamelist[i].fieldheader =='') && this.fieldnamelist[i].fieldtype !='hidden') {
                            this.tempcheck.push(i)
                        }

                        // header logic start
                        if (this.fieldnamelist[i].fieldheader!=null) {
                            if(!temArray.hasOwnProperty(this.fieldnamelist[i].fieldheader)){
                                temArray[this.fieldnamelist[i].fieldheader] = [];
                            }
                            var pushObj = this.fieldnamelist[i];
                                temArray[this.fieldnamelist[i].fieldheader].push(pushObj);
                        }
                        // header logic end                  


                        if (this.fieldnamelist[i].fieldtype=="text") {
                            let tempdata = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata[j] = {}
                                    tempdata[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue']=tempdata
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="checkbox") {
                            let tempdata = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata[j] = {}
                                    tempdata[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue']=tempdata
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="radio") {
                            let tempdata2 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata2[j] = {}
                                    tempdata2[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata2[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['radiooptionalvalue']=tempdata2
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="drop-down") {
                            let tempdata3 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata3[j] = {}
                                    tempdata3[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata3[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalu1']=tempdata3
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="multiselect-dropdown") {
                            let tempdata4 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata4[j] = {}
                                    tempdata4[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata4[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue2']=tempdata4
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="toggle") {
                            let tempdata5 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata5[j] = {}
                                    tempdata5[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata5[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue3']=tempdata5
                            }
                        }
                    }
                    this.tempheader=temArray;
                    this.getticketconfigdata();
                } else {
                    this.fieldnamelist = [];
                }
            }).catch(e => {
        this.displayError(e)
      });
        },
        getticketconfigdata(){
            this.isLoading = true;
            this.inputfield= {
                useremail: this.undt.username,
                empcode: parseInt(this.undt.userid),
                trackerid: 35,
                formid: parseInt(this.$route.query.ticketid),
            }
            axios({
                'method': 'POST',
                'url': 'api/ticket/getticketconfigdata',
                'data': this.inputfield,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;
                if(result.data.status == true){
                    this.isLoading = true;
                    this.saveddata = result.data.data;
                    this.isedit =true;
                    for (let p = 0; p < this.saveddata.length; p++) {

                        if (this.saveddata[p].fieldvalue && (this.saveddata[p].fieldtype == 'checkbox' || this.saveddata[p].fieldtype == 'multiselect-dropdown')) {
                            this.fieldsnamevalues[this.saveddata[p].fieldid] = this.saveddata[p].fieldvalue.split(',');
                        }else{
                            this.fieldsnamevalues[this.saveddata[p].fieldid] = this.saveddata[p].fieldvalue;
                        }
                    }
                    this.isLoading = false;
                }else{
                   this.saveddata=[]
                }
            }).catch(e => {
        this.displayError(e)
      });
        },
        changevalue(id,state){
            this.isLoading =true;
            this.fieldsnamevalues[id]= null;
            this.fieldsnamevalues[id] =state;
            this.isLoading =false;
        },
        formatData(data) {
            return data
        },
         getSLAPriorityDetails() {
      this.isLoading = true;
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        maincategoryid: this.ticket.projectmodulename? this.ticket.projectmodulename : null,
        subcategoryid: this.ticket.applicationmodulename ? this.ticket.applicationmodulename : null,
        project: this.ticket.project ? this.ticket.project : null,
      }
      let apiURL = 'api/slaconfigure/geSLAPriority'
      axios({
        method: "POST",
        url: apiURL,
        data: this.input,
        headers: { authorization: this.tokendata },   
      }).then((result) => {
        this.isLoading = false;
        if(this.ticket.projectmodulename !=this.ticket.lastprojectmodulename || this.ticket.applicationmodulename != this.ticket.lastapplicationmodulename || this.ticket.project !=this.ticket.lastproject){
          if (result.data.errorCode == 0) {
             let d= result.data.data.rows
            this.ticket.priority = d[0].issueclassification
            this.ticket.ticketresolutiontime = d[0].resolutiontime
            this.ticket.ticketresponsetime = d[0].responsetime

          } else {
             this.ticket.priority = null
             this.ticket.ticketresolutiontime = null
             this.ticket.ticketresponsetime = null

           // this.brandAllocateEmp = [];
          }
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    validateConfigForm(isValid){
      this.isAddState = false;
      for (let i=0; i<this.fieldnamelist.length; i++) {
        //Timepicker HH:mm value assignment start\
        if (this.fieldnamelist[i].validationtype=='required'){
        if (this.fieldnamelist[i].fieldtype=='datetime-local') {

            if (typeof this.fieldsnamevalues[this.fieldnamelist[i].id] == undefined || typeof (this.fieldsnamevalues[this.fieldnamelist[i].id]) == 'undefined') {
                isValid=false;
            }else{
                let hours = this.fieldsnamevalues[this.fieldnamelist[i].id]
                let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)
                if (this.fieldsnamevalues[this.fieldnamelist[i].id][1]=='H' || this.fieldsnamevalues[this.fieldnamelist[i].id][4]=='m') {
                    isValid=false;
                    Swal.fire({
                        title: "Failed",
                        text: this.fieldnamelist[i].label+' '+'required in HH:mm format value',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
                if(typeof hours === 'string' && hours.length === 5){
                    isValidHours = true
                }
                if(isValidHours && hours !== null && typeof hours === 'object') {
                    this.fieldsnamevalues[this.fieldnamelist[i].id] = `${hours.HH}:${hours.mm}`
                }
            }

        }
    }

    if (this.fieldnamelist[i].fieldtype=='datetime-local') {
        if (typeof this.fieldsnamevalues[this.fieldnamelist[i].id] != undefined || typeof (this.fieldsnamevalues[this.fieldnamelist[i].id]) != 'undefined') {
          let hours = this.fieldsnamevalues[this.fieldnamelist[i].id]
          let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)

          if(typeof hours === 'string' && hours.length === 5){
              isValidHours = true
          }
          if(isValidHours && hours !== null && typeof hours === 'object') {
              this.fieldsnamevalues[this.fieldnamelist[i].id] = `${hours.HH}:${hours.mm}`
          }
        }
      }
        //Timepicker HH:mm value assignment end

        if (this.fieldnamelist[i].fieldtype != 'toggle') {
            
            if (this.fieldnamelist[i].validationtype=='required') {
                if (this.fieldsnamevalues[this.fieldnamelist[i].id] == '' || this.fieldsnamevalues[this.fieldnamelist[i].id] == null || this.fieldsnamevalues[this.fieldnamelist[i].id] == []) {
                    isValid=false;
                    if(isValid==false){
                        Swal.fire({
                            title: "Failed",
                            text: this.fieldnamelist[i].label+' '+'is required',
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        return
                    }
                }
                if (this.fieldnamelist[i].fieldtype=='datetime-local' && (this.fieldsnamevalues[this.fieldnamelist[i].id] == '' || this.fieldsnamevalues[this.fieldnamelist[i].id] == null || this.fieldsnamevalues[this.fieldnamelist[i].id] == '[object Object]')) {
                    isValid=false;
                    Swal.fire({
                        title: "Failed",
                        text: this.fieldnamelist[i].label+' '+'is required',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
                if (this.fieldnamelist[i].fieldtype=='datetime-local' && (this.fieldsnamevalues[this.fieldnamelist[i].id][1]=='H' || this.fieldsnamevalues[this.fieldnamelist[i].id][4]=='m')) {
                    isValid=false;
                    Swal.fire({
                        title: "Failed",
                        text: this.fieldnamelist[i].label+' '+'required in HH:mm format value',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
                if (typeof (this.fieldsnamevalues[this.fieldnamelist[i].id]) == undefined || typeof (this.fieldsnamevalues[this.fieldnamelist[i].id]) == 'undefined') {
                    isValid=false;
                } 
                else{
                    if(this.fieldsnamevalues[this.fieldnamelist[i].id].length < 0) {
                        isValid=false;
                    }
                }
            }
          }
        }
        return isValid;
    },
	getticketcreatormanager() {
      this.isLoading = true
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid
      }
      let apiUrl = 'api/ticket/getticketcreatormanager'
      axios({
        method: 'POST',
        url: apiUrl,
        data: this.input,
        headers: { authorization: this.tokendata }
      }).then(result => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
            if(result.data.data && result.data.data.rows){
                let decryptdepartmenttext = result.data.data.rows.map(items =>{

                    let tempemplabel1 = items.label;
                    let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                    if(tempemplabel2 && tempemplabel2 != null){
                    items.label = tempemplabel1 +'('+tempemplabel2 +')';
                    }
                    return items;
                })
            }
            this.ticket.subscriberslist = result.data.data.rows;
            this.ticket.subscribers = result.data.data.rows.map(m => m.id);
        } else {
          this.ticket.subscriberslist = [];
          this.ticket.subscribers = null;
        }
      })
    },
    getEmpallocatedtoProjectForSubscriber(node){
            if (this.ticket.project  && this.ticket.project  !=this.itHelpDesk && (node && node.length > 3)) {
                this.input = { 
                    projectid:this.ticket.project ,
                    useremail: this.undt.username,
                    empcode: this.undt.userid , 
                    asigneename:node.toLowerCase() 
                }
                // if(this.isThisBusinessRole) {
                //     this.input.roleid = 484
                // }
                this.ticket.allotedtolist = [];
                // this.ticket.subscriberslist = [];
                axios({
                    'method': 'POST',
                    'url': 'api/listing/getEmpallocatedtoProject',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => { 
                    this.isLoading= false;         
                    if(result.data.errorCode == 0){
                        if(result.data.data){
                            let decryptdepartmenttext = result.data.data.map(items =>{

                                let tempemplabel1 = items.label;
                                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                                if(tempemplabel2 && tempemplabel2 != null){
                                items.label = tempemplabel1 +'('+tempemplabel2 +')';
                                }
                                return items;
                            })
                        }
                        this.ticket.subscriberslist = result.data.data;
                        this.ticket.allotedtolist=result.data.data;
                    } else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else if (node) {
                        this.getticketcreatormanager() 
                    } 
                }).catch(e => {
                    this.displayError(e)
                });
        	}
        },
        getRosterDetails() {
            this.isLoading = true;
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                category: this.ticket.projectmodulename? this.ticket.projectmodulename : null,
                subcategory: this.ticket.applicationmodulename ? this.ticket.applicationmodulename : null,
                projectid: this.ticket.project ? this.ticket.project : null,
                fieldvalues:this.fieldsnamevalues ? this.fieldsnamevalues : null
            }
            let apiURL = 'api/roaster/getrosterdetails'
            axios({
            method: "POST",
            url: apiURL,
            data: this.input,
            headers: { authorization: this.tokendata },   
            }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                let d= result.data.data;
                console.log(result.data.data,'=================');
                this.autoassignto = d[0].allocatedto;
            } else {
                this.autoassignto = null;
            }
            }).catch(e => {
                this.displayError(e)
            });
        },
        dynamicroster(){
            if(this.companycode=='xb'){
                this.getRosterDetails();
            }
        }
    },
    filters: {
      basename: function (value) {
          if (!value) return ''
          //value = value.toString()
          return value.substr(value.lastIndexOf("/") + 1)
      }
    }
}
</script>
