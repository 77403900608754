<template>
<div class="app-content content ">
    <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5 px-0">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h3 class="content-header-title float-left mb-0">Test Case Group Master</h3>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to='/testcasetask/list'>Test Case</router-link></li>
                            <li class="breadcrumb-item sitebluecolor cursorpointercls">Test Case Group Master</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-warning filter_card_searchbgst" >
        <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchBoxCollapse">
                <div class="container-fluid" >
                <div class="row" >
                    <div class="w-auto px-1 mobile-padding-0-2">
                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                        <div class="avatar-content">
                        <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                        </div>
                    </div>
                    <h4 class="card-title d-inline-flex ml-1">Search Test Case Group Master</h4>
                </div>
                <div v-if="pagefeatures.creategroupmaster" class=" ml-auto px-1 text-right mobile_margin-px-0_00" >
                    <div class="input-group-append float-right">
                        <button id="commonaddmodule" class="btn btn-sm btn-relief-success " data-toggle="modal" aria-haspopup="true" aria-expanded="false" :data-target="currentmodule" @click="addModule($event)" type="button" data-backdrop="static" data-keyboard="false"> 
                        
                         <span class="mobile_hide_create_text ml-1">Create Test Case Group</span> <plus-circle-icon size="1.5x" class="custom-class "></plus-circle-icon></button>
                       <span class="rotetions_view_arrow" style="position: relative; top: 3px;"> 
                        <em class="las la-angle-down"></em>
                    </span>
                    </div>
                    
                </div>
                </div>
                </div>
            </div>
            <div v-bind:class="[{show: isSearchBoxActive},card_content,collapse]" >
                <div class="card-body pt-1 px-sm-mobile-10">
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                        <label class="form-label lable-left">Test Case Group Name</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 char of Group Name" v-model="search.moduleName" 
                        v-on:search-change="groupmapnameChange" :options="moduleNameList"/>
                        </div>   
                        <div class="col-sm-6 col-md-6 col-lg-3">
                            <label class="form-label lable-left">Status</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" v-model="search.status" :options="statuslist"/>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <button type="submit" class="btn btn-relief-primary mt-2 tcbuilkbtns" @click="getlist(search)"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                            <button type="reset" class="btn btn-outline-secondary mt-2 tcbuilkbtns mx-1" @click="clearSearchFields()"><x-icon  size="1.5x" class="custom-class" ></x-icon><span> Clear</span></button>
                            <button type="move" class="btn btn-relief-primary mt-2 tcbuilkbtns" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon><span> Export</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section id="card-navigation">
            <div class="file-manager-content-body">
                <div class="drives">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card mb-3">
                                <div class="">
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div class="row" id="proModuleList">
                                                <div class="col-12">
                                                    <div class="card">
                                                        <div class="table-responsive datatableview">
                                                            <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" class="srno align-middle first-col-sticky" style="min-width:45px;">Sr#</th>
                                                                        <th scope="col" class="align-middle second-col-sticky">Group Name</th>
                                                                        <th scope="col" class="align-middle">Status</th>
                                                                        <th scope="col" class="align-middle">Country</th>
                                                                        <th scope="col" class="align-middle">Environment</th>
                                                                        <th scope="col" class="align-middle">Language</th>
                                                                        <th scope="col" class="align-middle">Script Type</th>
                                                                        <th scope="col" class="align-middle">Test Execution Type</th>
                                                                        <th scope="col" class="align-middle" style="min-width: 160px;">  Created Details </th>
                                                                        <th scope="col" class="align-middle" style="min-width: 160px;">Date Details</th>
                                                                        <th scope="col" class="clsaction align-middle">Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <template v-for="(data,index) in companymasterlist">        
                                                                        <tr>
                                                                            <td data-column="Sr#" class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                            <td data-column="Group Name" class="second-col-sticky">
                                                                                <span  class="font-weight-normal capitalisetext">{{data.modulename}}</span>
                                                                            </td>
                                                                            <td data-column="Status">
                                                                                <span  class="font-weight-normal capitalisetext">{{data.status}}</span>
                                                                            </td>
                                                                            <td data-column="Country" class="test_list_caseulli">
                                                                                <span v-if="data.country" class="font-weight-normal capitalisetext">
                                                                                    <ol><li v-for="(con) in data.country.split(',')">{{ con }}</li></ol>
                                                                                </span>
                                                                                <span v-else><ul><li>NA</li></ul></span>
                                                                            </td>
                                                                            <td data-column="Environment" class="test_list_caseulli">
                                                                                <span  v-if="data.environment" class="font-weight-normal capitalisetext">
                                                                                    <ol><li v-for="(env) in data.environment.split(',')">{{ env }}</li></ol>
                                                                                </span>
                                                                                <span v-else><ul><li>NA</li></ul></span>
                                                                            </td>
                                                                            <td data-column="Language" class="test_list_caseulli">
                                                                                <span v-if="data.language" class="font-weight-normal capitalisetext">
                                                                                    <ol><li v-for="(lan) in data.language.split(',')">{{ lan }}</li></ol>
                                                                                </span>
                                                                                <span v-else><ul><li>NA</li></ul></span>
                                                                            </td>
                                                                            <td data-column="Script Type" class="test_list_caseulli">
                                                                                <span v-if="data.type" class="font-weight-normal capitalisetext">
                                                                                    <ol><li v-for="(typ) in data.type.split(',')">{{ typ }}</li></ol>
                                                                                </span>
                                                                                <span v-else><ul><li>NA</li></ul></span>
                                                                            </td>
                                                                            <td data-column="Test Execution Type" class="test_list_caseulli">
                                                                                <span  class="font-weight-normal capitalisetext">{{data.testexecutiontype}}</span>
                                                                                
                                                        
                                                                            </td>
                                                                            <td data-column="Created Details"> 
                                                                                <span class="release_hr_tag_span"> Created by :</span> 
                                                                                {{data.createdby.split("@")[0]}}
                                                                                <hr class="release_hr_tag_tab"/>
                                                                                <span class="release_hr_tag_span"> Created Date :</span> 
                                                                                {{dateMonthFormat(data.createddate)}}
                                                                            </td>
                                                                            <td data-column="Date Details"> 
                                                                                <span class="release_hr_tag_span"> Reviewed Date:</span> 
                                                                                {{data.reviewddate ?dateMonthFormat(data.reviewddate):'NA'}}
                                                                                <hr class="release_hr_tag_tab">
                                                                                <span class="release_hr_tag_span"> Approved Date :</span> 
                                                                                {{data.approveddate?dateMonthFormat(data.approveddate):'NA'}}
                                                                            </td>
                                                                            <td data-column="Actions" class="clsaction">                                                                  
                                                                                <div class=" align-items-center sitebluecolor" href="javascript:void(0);" v-if="data.status != 'Approved'"  data-toggle="modal" :data-target="currentmodule" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false" style="cursor: pointer" >
                                                                                    <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                </div>
                                                                                <div class=" align-items-center sitebluecolor" href="javascript:void(0);" v-else-if="data.status == 'Approved' && pagefeatures.approvegroupmaster"  data-toggle="modal" :data-target="currentmodule" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false" style="cursor: pointer" >
                                                                                    <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-if="companymasterlist.length==0">
                                                                        <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pageCenter text-center">
                                        <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
   
    <div class="modal modal-slide-in fade" id="groupmaster" aria-hidden="true">
        <div class="modal-dialog sidebar-lg">
            <div class="modal-content p-0">
                <button type="button" class="close" data-dismiss="modal"  aria-label="Close" style="color:#ffff !important" @click="resetForm()">×</button>
                <div class="modal-header mb-1 px-1" style="background-color:#ff7f0e !important;">
                    <h5 class="modal-title" style="color:#ffff !important">
                        
                        <span class="align-middle ml-50 mx-0">Add/Update Test Case Group Master Details </span>
                    </h5>
                </div>
                <div class="modal-body flex-grow-1 devUniqueForm mobile-padding-0-2 px-0">
                    <form>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label">Test Case Group Name</label>
                                <input id="modulenamet36"  class="form-control" type="text" placeholder="Test Case Module Name " v-model="modulename" v-bind:class="{'form-control':true, '' : !validText(modulename)}"/>
                                <div class="errorinputmsg" v-if="!validText(modulename)">Test case group name is required and must be  greater than 5 char</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Test Case Group Description</label>
                                <textarea class="form-control" id="prmoduledescription"  rows="3" placeholder="Test Case Group Description" v-model="moduledesc" v-bind:class="{'form-control':true, '' : !validText(moduledesc)}"></textarea>
                                <div class="errorinputmsg" v-if="!validText(moduledesc)">Test case group description is required and must be  greater than 5 char</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Status</label>
                                <treeselect placeholder="Select Status" v-bind:disabled="disabledStatus" v-model="modulestatus" :options="statusOption"  @input="modulestatusdata"/>
                                <div class="errorinputmsg" v-if="this.errors.statusmodule">{{ this.errors.statusmodule }}</div> 
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="projectmdllistdd">Country</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Country" v-model="country" :multiple="true" :options="countrylist" @input="countrydata"/>
                                <div class="errorinputmsg" v-if="this.errors.country">{{ this.errors.country }}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Environment</label>
                                <treeselect placeholder="Select Environment" :multiple="true" v-model="environment" :options="environmentlist" @input="environmentdata"/>
                                <div class="errorinputmsg" v-if="this.errors.environment">{{ this.errors.environment }}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="projectmdllistdd">Environment Language</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Environment Language" :multiple="true" v-model="language" :options="languagelist" @input="languagedata"/>
                                <div class="errorinputmsg" v-if="this.errors.language">{{ this.errors.language }}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="projectmdllistdd">Type</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Type" v-model="type" :multiple="true" :options="typeslist" @input="typedata"/>
                                <div class="errorinputmsg" v-if="this.errors.type">{{ this.errors.type }}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="projectmdllistdd">Test Execution Type</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Type" v-model="testExecutionType"  :options="testExecutionTypeList" @input="testExecutionData"/>
                                <div class="errorinputmsg" v-if="this.errors.testExecutionType">{{ this.errors.testExecutionType }}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button   type="button" class="btn btn-relief-primary mr-1 " id="submitbtnt36" data-dismiss="modal" v-on:click.stop.prevent="savewizard($event)">Submit</button>
                                <button style="margin-right:2px;" type="reset" class="btn btn-outline-secondary" @click="resetForm()">Clear</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>   
</div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Pagination from 'vue-pagination-2'
import { PlusCircleIcon,PlusSquareIcon,Edit2Icon,EditIcon, ThumbsDownIcon,SearchIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import commonMethods from '../../utils/commonMethods';

export default {
    name:'GroupMaster',
    mixins: [ commonMethods ],
    components:{
        Loading,
        VueElementLoading,
        Pagination,Edit2Icon,ExternalLinkIcon,
        PlusCircleIcon,PlusSquareIcon,EditIcon,
        Treeselect,ThumbsDownIcon,SearchIcon,XIcon
    },
    data(){
        return{
            isSearchBoxActive: false,
            card_content:'card-content',
            collapse:'collapse',
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            companymasterlist:[],
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            currentmodule:"#groupmaster",
            moduledesc:"",
            modulename:"",
            modulestatus: null,
            statusOption: [],
            environment: [],
            environmentlist: apiUrl.environmentlist.sort((a, b) => a.label.localeCompare(b.label)),
            language: [],
            languagelist: apiUrl.languagelist.sort((a, b) => a.label.localeCompare(b.label)),
            type: [],
            typeslist: apiUrl.scripttypelist.sort((a, b) => a.label.localeCompare(b.label)),
            country: [],
            countrylist: apiUrl.countrylist.sort((a, b) => a.label.localeCompare(b.label)),
            testExecutionType: null,
            testExecutionTypeList: apiUrl.testexecutiontype.sort((a, b) => a.label.localeCompare(b.label)),
            isEdit: false,
            editRowData: [],
            pagefeatures:[],
            errors : {
                statusmodule: '',
                environment: '',
                language: '',
                type: '',
                country: '',
                testExecutionType: ''
            },
            disabledStatus: true,
            search : {
                moduleName: null,
                status: null,
            },
            statuslist: apiUrl.tcscriptstatuslist.sort((a, b) => a.label.localeCompare(b.label)),
            moduleNameList: []
        }
       
    },
    created() {
        this.pagefeatures = {};
        var pagefeatures1 = JSON.parse(
          window.atob(window.localStorage.getItem("pagefeatures"))
        );
        pagefeatures1.forEach((element) => {
          if (element.page === "/testcasetask/groupmaster") {
            this.pagefeatures[element.featurename] = element.featureaccess;
          }
        });
    },
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.getlist();
                // this.getmodulename();
                this.search.moduleName=null
                this.search.status = null
                localStorage.removeItem('searchgroupItems')
            }
        }
        $(".filter_rotation_onlick").click(function(){
            $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        getStatusConfigList(pid, status) {
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: pid,
                trackername: 'Testcase',
                currStatus: status,
            };
            axios({
                method: "POST",
                url: '/api/testcase/getStatusConfigList',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    this.statusOption = result.data.data.rows;
                    this.statusOption.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
                } else {
                    this.statusOption = [];
                }
            }).catch(e => {
    this.displayError(e)
    })
        },
        searchBoxCollapse() {
            this.isSearchBoxActive = !this.isSearchBoxActive
        },
        openEditModule(currRowData){
            this.editRowData = currRowData;
            this.modulename = currRowData.modulename;
            this.moduledesc = currRowData.description;
            this.modulestatus = currRowData.status?currRowData.status:'Created';
            this.getStatusConfigList(null, this.modulestatus);
            if (currRowData.environment) {
                var envList = currRowData.environment.split(",")
                this.environment = envList.map(e => e)
            }
            if(currRowData.language) {
                var langList = currRowData.language.split(",")
                this.language = langList.map(l => l)
            }
            if(currRowData.type) {
                var typeList = currRowData.type.split(",")
                this.type = typeList.map(t => t) 
            }
            if(currRowData.country) {
                var countrylist = currRowData.country.split(",")
                this.country = countrylist.map(c => c) 
            }
        
            this.testExecutionType = currRowData.testexecutiontype;
          

            this.isEdit = true;
            this.disabledStatus = false
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getlist()
        },
        addModule(){
            this.moduledesc=null
            this.modulename=null
            this.modulestatus = "Created"
            this.environment=[]
            this.language=[]
            this.type=[]
            this.country=[],
            this.testExecutionType=null,
            this.disabledStatus=true 
            this.getStatusConfigList(null, this.modulestatus);
        },
        getSearchItems(search) {
            this.search.moduleName = JSON.parse(localStorage.getItem('moduleNameList'));
            this.search.moduleName = search.moduleName;
            this.search.status = search.status;
            this.isSearchBoxActive = true;
        },
        getlist(objThis){
            if(objThis) {
                this.page =1
                localStorage.setItem('searchgroupItems', JSON.stringify(objThis))
            }
            this.isLoading = true
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                limit: apiUrl.LIMIT,
                empcode: this.undt.userid,
                offset:this.pageoffset
            }
            
            let search = JSON.parse(localStorage.getItem('searchgroupItems'))
            if(search){
                this.input.moduleName = search.moduleName
                this.input.status = search.status
                // this.input.offset= 1
                // this.currPageNum=0
            }
            axios({
                    'method': 'POST',
                    'url': 'api/testcase/getlist',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false             
                    if(result.data.errorCode == 0){
                        this.companymasterlist = result.data.data.rows
                        this.totalcount = result.data.data.count;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        this.companymasterlist=[]
                        this.totalcount = 0;
                        this.pageCount =0;
                    }
                }).catch(e => {
    this.displayError(e)
    })
        },
        clearSearchFields: function() {
            this.search.moduleName=null
            this.search.status = null
            localStorage.removeItem('searchgroupItems')
            this.page=1
            this.getlist();
        },
        savewizard(){ 
            
            this.valid = this.validateForm();
            if(this.valid){
            
                this.isLoading = true
                this.input={
                    modulename : this.modulename,
                    description :this.moduledesc,
                    modulestatus: this.modulestatus,
                    environment: this.environment,
                    language: this.language,
                    type:this.type,
                    country: this.country,
                    testExecutionType: this.testExecutionType,
                    useremail  : this.undt.username,
                    isactive:1,
                    empcode: this.undt.userid,
                }
                let url
                if(this.isEdit === true){
                    url = 'api/testcase/updategroupmaster';
                    this.input.id = this.editRowData.id    
                } else  {
                    url = 'api/testcase/savegroupmaster';
                   
                }
                
                axios({
                    'method': 'POST',
                    'url': url,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {   
                    this.isLoading = false
                    if(result.data.errorCode == 0){
                        this.moduledesc=null
                        this.modulename=null
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.isEdit = false
                        this.getlist()
                        $('.close').click();
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }
                    this.moduledesc=null
                    this.modulename=null
                    this.modulestatus = ""
                    this.environment=[]
                    this.language=[]
                    this.type=[]
                    this.country=[],
                    this.testExecutionType=null
                }).catch(e => {
    this.displayError(e)
    })
            }
        },
        groupmapnameChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/groupbulkmapping/getGroupmapnameList";
                this.input = {
                    groupmappname:node.replace(/'/g, " ").toLowerCase(),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.moduleNameList = result.data.data;
                    localStorage.setItem('moduleNameList', JSON.stringify(result.data.data))
                } else {
                    this.moduleNameList = [];
                    localStorage.removeItem('moduleNameList')
                }
                }).catch(e => {
    this.displayError(e)
    })
            } 
        },
        resetForm(){
            this.moduledesc=null
            this.modulename=null
            this.environment=[]
            this.language=[]
            this.type=[]
            this.country=[]
            this.testExecutionType=null
            if (this.isEdit==true) {
                this.modulestatus=null
            }
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        modulestatusdata(state){
            this.modulestatus = state;
            if(!state && this.modulestatus == null){
                this.errors.statusmodule = 'Module Status is required'
            } else {
                this.errors.statusmodule = null
            }
        },
        environmentdata(state){
            this.environment = []
            this.environment = state;
            if(!state && this.environment.length == 0){
                this.errors.environment = 'Environment is required'   
            } else {
                this.errors.environment = null
            }
        },
        languagedata(state){
            this.language = []
            this.language = state;
            if(!state && this.language.length == 0) {
                this.errors.language = 'Language is required'
            } else {
                this.errors.language = null
            }
        },
        typedata(state){
            this.type = []
            this.type = state;
            if(!state && this.type.length == 0) {
                this.errors.type = 'Type is required'
            } else  {
                this.errors.type =  null
            }
        },
        countrydata(state) {
            this.country = []
            this.country = state;
            if(!state && this.country.length == 0) {
                this.errors.country = 'country is required'
            } else  {
                this.errors.country =  null
            }
        },
        testExecutionData(state) {
          this.testExecutionType = null
          this.testExecutionType = state;
          if(!state) {
            this.errors.testExecutionType = 'Test Execution Type is required'
          } else {
            this.errors.testExecutionType = null
          }
        },
        validateForm(){
            var isValid=true;
            if(this.modulename == null || this.modulename == '' || this.modulename == 0){
                isValid= false;
            }
            if(this.modulename && this.modulename.length <= 5){
                isValid= false;
            }
            if(this.modulename && this.moduledesc.length <= 5){
                isValid= false;
            }
            if(this.moduledesc == null || this.moduledesc == '' || this.moduledesc == 0){
                isValid= false;
            }
            if (this.modulestatus == null){
                this.errors.statusmodule = 'Module Status is required'
                isValid= false;
            }
            if (this.environment.length == 0){
                this.errors.environment = 'Environment is required'
                isValid=false
            }
            if (this.language.length == 0){
                this.errors.language = 'Language is required'
                isValid= false;
            }
            if (this.type.length == 0){
                this.errors.type = 'Type is required'
                isValid= false;
            }
            if(this.country.length == 0){
                this.errors.country = 'Country is required'
                isValid= false;
            }
            if (this.testExecutionType.length == 0) {
                this.errors.testExecutionType = 'Test Execution Type is required'
                isValid= false;
            }
            return isValid;
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        ExportXL() {
            this.islistLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input = {
                limit:null,
                offset:null,
                moduleName : this.search.moduleName,
                status : this.search.status,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                empid: this.undt.userid,
            };
            axios({
                method: "POST",
                url: `api/testcase/getlist`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.islistLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    let filename = "";
                    this.download(result.data.data.rows,filename)
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
            }).catch(e => {
    this.displayError(e)
    })
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
                let ShowLabel = 'TestCaseGroupReportDetails_'+filename
                let JSONData = readExportdata
                let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
                let CSV = ''
                let row = ''
                if (ShowLabel) {
                    CSV = 'Sr#,GroupName,Description,Status,Country,Environment,Language,Type,TestExecutionType,CreatedDate,CreatedBy' + '\r\n'
                }
                for (let i=0; i<arrData.length; i++) {
                    let index = arrData[i]
                    let srno = i+1
                    let modulename = index.hasOwnProperty('modulename') ? index.modulename == null||index.modulename.toLowerCase() == 'na'?'':index.modulename : '';
                    let description = index.hasOwnProperty('description') ? index.description == null||index.description.toLowerCase() == 'na'?'':index.description : '';
                    let status = index.hasOwnProperty('status') ? index.status : '';
                    let country = index.hasOwnProperty('country') ? index.country == null||index.country.toLowerCase() == 'na'?'':index.country : '';
                    let environment = index.hasOwnProperty('environment') ? index.environment == null||index.environment.toLowerCase() == 'na'?'':index.environment : '';
                    let language = index.hasOwnProperty('language') ? index.language == null||index.language.toLowerCase() == 'na'?'':index.language : '';
                    let type = index.hasOwnProperty('type') ? index.type == null||index.type.toLowerCase() == 'na'?'':index.type : '';
                    let testexecutiontype = index.hasOwnProperty('testexecutiontype') ? index.testexecutiontype == null||index.testexecutiontype.toLowerCase() == 'na'?'':index.testexecutiontype : '';
                    let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
                    let createdby = index.hasOwnProperty('createdby') ? index.createdby : '';
                    createddate = this.dateMonthFormat(createddate)

                    row += '"' + srno + '",',
                    row += '"' + modulename + '",',
                    row += '"' + description + '",',
                    row += '"' + status + '",',
                    row += '"' + country + '",',
                    row += '"' + environment + '",',
                    row += '"' + language + '",',
                    row += '"' + type + '",',
                    row += '"' + testexecutiontype + '",',
                    row += '"' + createddate + '",'
                    row += '"' + createdby + '",',
                    row += '\r\n'
                }
                CSV += row
                    if (CSV === '') {
                        Swal.fire({
                            title: "",
                            text: "Data not found",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        });
                        return;
                    }
                    let fileName = 'TestCaseGroupReportDetails_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                    let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                    let link = document.createElement('a')
                    link.href = uri
                    link.style = 'visibility:hidden'
                    link.download = fileName + '.csv'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                        Swal.fire({
                        title: "Success!",
                        text: "Export Report Successful",
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
            }else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            }

    },
    export (data, filename, mime) {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    }
    }
}
</script>