<template>
    <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
              <div class="col-12">
                <h3 class="content-header-title float-left mb-0">functionality Create</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/functionality/list'>Functionality List</router-link></li>
                    <li class="breadcrumb-item sitebluecolor cursorpointercls">create functionality</li>
                  </ol>
                </div>
              </div>
        </div>
        <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div v-bind:class="[{show: isRequestActive},card_content,collapse]" style="margin-top:10px;">
            <div class="card-body">
              <div class="table-responsive" style="overflow-x: inherit !important; margin:10px, 5px ,5px,5px;">
                <table class="table table-sm dataTableListing" aria-describedby="mydesc">
                  <thead>
                    <tr>
                      <th scope="col">Project Name</th>
                      <th scope="col">Project Module Name</th>
                      <th scope="col">Application Name</th>
                      <th scope="col">Application Module</th>
                      <th scope="col">Section Module</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{this.functionalitymodule.project}}</td>
                      <td>{{this.functionalitymodule.projectmodule}}</td>
                      <td>{{this.functionalitymodule.applicationname}}</td>
                      <td>{{this.functionalitymodule.applicationmodulename}}</td>
                      <td>{{this.functionalitymodule.sectionname}}</td>

                    </tr>
                  </tbody>
                </table>
              </div>
              <form class="form form-vertical" style="margin-top:2rem">
                <div class="row"> 
                  <div class="col-12">
                    <div class="form-group mg-bot0">
                      <label for="appmdlnm">Functionality Name</label>
                      <input type="text" id="modulenamet22" class="form-control" name="" placeholder="Functionality Name" v-model="functionalitymodule.functionlityname"
                        v-bind:class="{ 
                          'form-control': true,
                          'is-invalid': !valid2Text(functionalitymodule.functionlityname) && functionlitynameBlured, 
                        }" v-on:blur="functionlitynameBlured = true"/>
                      <div class="invalid-feedback">Functionality Name required</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group mg-bot0">
                      <label for="reasonofissue">Functionality Description</label>
                      <ckeditor :editor="editor" id="reasonofissue" class="form-control" name="" placeholder="Functionality Description" v-model="functionalitymodule.functionlitydescription" 
                        :config="editorConfig"  tag-name="textarea" @blur="validateForm('t37',$event)"/>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.functionalitymodule.functionlitydescription">{{ this.errors.functionalitymodule.functionlitydescription}}</div>
                  </div>
                  <button style="margin-left:15px;" type="button" class="btn btn-primary mr-1"  @click="saveData('t37',$event)" id="submitbtnt37">Submit</button>
                  <button type="button" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import { PlusSquareIcon,FolderIcon,PlusIcon,Edit2Icon,MoreVerticalIcon,PackageIcon,Link2Icon,BoxIcon,ChromeIcon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon } from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name:'fupdate',
    components:{
        Loading,
        ckeditor: CKEditor.component,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        FolderIcon,
        PlusSquareIcon
    },
    
    data() {
        return {
            card_content: "card-content",
            collapse: "collapse",
            isRequestActive: true,
            editor: ClassicEditor,
            showbutton:false,
            isLoading: false,
            fullPage: true,
            stylevariation:apiUrl.stylevariation,
            companymasterlist: [],
            selected: null,
            editRowData:[],
            glbMdl:'',
            TimeFormat: apiUrl.TimeFormat,
            functionalitymodule:{
                sectionmasterid:null,
                sectionmasterlist:null,
                applicationmoduleid:null,
                applicationmodulelist:null,
                projectid:null,
                projectname:null,
                projectlist:null,
                applicationid:null,
                applicationlist:null,
                functionlityname:null,
                functionlitydescription:null,
                appprojectdd:null,
                projectmoduleid:null,
                label:null,
                project:null,
                projectmodule:null,
                applicationname:null,
                applicationmodulename:null,
                sectionname:null,
                projecttypelist:null,
                projecttypeid:null,
                businesslist:null,
                businessverticleid:null,
                companyid:null,
                companylist:null,
                iseditstate:false
            },
            applinameBlured:false,
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            pageoffset:1,
            currPageNum: 0,
            pagefeatures:[],
            showtabledata:true,
            showproject:false,
            activeprojectid:'',
            modulelist:[],
            isSearchRequestActive:false,
            errors:{
                functionalitymodule:{
                    functionlityname:null,
                    functionlitydescription:null
                },
            },
            valid:false,
            projectmoduleid:null,
            projectname:null,
            projectmodulename:null,
            projectnameList:[],
            projectmodulenameList:[],
            search: {
                applicationModuleid: null,
                applicationModuleOpts: [],
                applicationMasterid: null,
                applicationMasterOpts: [],
                projectMasterid: null,
                projectMasterOpts: [],
                projectModuleid: null,
                projectModuleOpts: [],
                sectionMasterid: null,
                sectionMasterOpts: [],
                functionalityMasterid: null,
                functionalityMasterOpts: [],
            },
            isSearch:false,
            backpagedata:null,
            functionlitynameBlured:false,
            functionlitydescBlured:false,
            tokendata:null,
            editorConfig: {
                toolbar: {
                items: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "insertTable",
                    "|",
                    "undo",
                    "redo",
                    "|",
                ],
                },
            },
        }
    },
    created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
            if (element.page === '/functionalitymodule') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
        })
    },
    mounted(){
        
        var userdata = window.localStorage.getItem('userdata');
        
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
        }        
        this.backpagedata=this.$route.params.clickeddata
        this.getfunctionalitybyid(this.$route.query.functionalityid)
        this.getApplicationByAppModuleId(this.$route.params.clickeddata.applicationmoduleid)
    },
    methods:{
        getfunctionalitybyid(funcid) {
            this.isLoading = true;
            let url = "api/functionality/getfunctionalitybyid";
            this.input = {
                functionlitymasterid: funcid,
                useremail: this.undt.username,
                empid: parseInt(this.undt.userid),
                empcode: this.undt.userid

            };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.editRowData = result.data.data;
                this.editRowData=this.editRowData[0]
                this.openEditModule(this.editRowData,0)
            } else {
                 this.$router.push('/functionality/list')
                Swal.fire({
                    title: "Failed!",
                    text: "Your are not authorised user to update this issue",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
            }
          })
        },
        getProjectDataByProjectID(projectmasterid){
            this.isLoading=true
            let url = 'api/listing/getprojectlistbycompanyid'
            this.input = {
                "companyid":null,
                "projectmasterid":projectmasterid,
                empcode: this.undt.userid,
                useremail: this.undt.username,

            }
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading=false;           
                if(result.data.errorCode == 0){                    
                    this.search.projectMasterOpts=result.data.data;
                }else{
                    this.search.projectMasterOpts=[]
                      this.$router.push('/functionality/list')
                }
            })
        },
        redirecttobreadkumb:function(objThis){
            if(objThis.rowdata){
                objThis.rowdata.backbtnstate=objThis.btnstate
            }else{
                objThis.rowdata={}
                objThis.rowdata.backbtnstate=objThis.btnstate
            }
            this.$router.push({ 
                name: objThis.name,
                path: '/'+objThis.path,
                params: {
                    backbtnstate:objThis.btnstate,
                    clickeddata:objThis.rowdata
                }
            })
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            this.$router.push({ 
                name: 'Work Step',
                path: '/workstep',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        handleCancel() {
            this.$router.push('/functionality/list')
        },
        selappapltype(state,value){
            this.application.applicationtype=null
            this.application.applicationtype=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationtype='Application type required'
            }else{                
                this.application.errorapplicationtype=null
            }
        },
        selappaplaccess(state,value){
            this.application.applicationaccess=null
            this.application.applicationaccess=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationaccess ='Application access required'
            }else{                
                this.application.errorapplicationaccess =null
            }
        },
        getmodulelist:function(){
            this.isLoading=true
            let url = 'api/projectapplication/getSectionList'
            this.input = {
                "applicationmoduleid":0,
                "usermail":this.undt.username,
                "offset":1,
                "limit":null,
                "empcode": this.undt.userid,

            }
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading=false;           
                if(result.data.errorCode == 0){                    
                    this.modulelist=result.data.data;
                }else{
                    this.modulelist=[]
                }
            })
        },
        
        getFilter: function() {
            this.isLoading=true
            let url = 'api/projectapplication/getfunctionalitylist'
            this.input = {
                "offset":1,
                "tbl":"t34",
                "limit":1000,
                "isSearch":true,
                "section":"functionalitymodule",
                "isFilterSelect":1,
                "isFilterPayload":{}
            }
            if(this.search.applicationModuleid && this.search.applicationModuleid != null) {
                this.input.isFilterPayload.applicationModuleid = this.search.applicationModuleid
            }
            if(this.search.projectMasterid && this.search.projectMasterid != null) {
                this.input.isFilterPayload.projectMasterid = this.search.projectMasterid
            }
            if(this.search.applicationMasterid && this.search.applicationMasterid != null) {
                this.input.isFilterPayload.applicationMasterid = this.search.applicationMasterid
            }
            if(this.search.projectModuleid && this.search.projectModuleid != null) {
                this.input.isFilterPayload.projectModuleid = this.search.projectModuleid
            }
            if(this.search.sectionMasterid && this.search.sectionMasterid != null) {
                this.input.isFilterPayload.sectionMasterid = this.search.sectionMasterid
            }
            this.input.empcode= this.undt.userid
            this.input.useremail= this.undt.username
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
                .then(result => {  
                    this.isLoading=false;           
                    if(result.data.errorCode == 0) {            
                        this.companymasterlist=result.data.data;
                        this.totalcount = result.data.data.length
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    } else {
                        this.companymasterlist=[]
                        this.totalcount = 0
                    }
                })
        },
        projectChange: function (node, instanceId) {
            if(node && node.length>1) {
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.projectMasterOpts = result.data.data;
                    } else {
                        this.search.projectMasterOpts = [];
                    }
                });
            }
        },
        functionalityChange: function(node, instanceId) {
            if(node && node.length>1) {
                this.isLoading = true;
                let url = "api/master/commonmaster/getModuleDropdownOption";
                this.input = {
                    tbl: "t37",
                    functionalityname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.functionalityMasterOpts = result.data.data;
                    } else {
                        this.search.functionalityMasterOpts = [];
                    }
                });
            }
        },
        selprojectid(state, value) {
            this.search.projectMasterid = state;
            if (!state && this.isAddState == false) {
                this.search.applicationMasterOpts = null;
                this.search.functionalityMasterOpts = null;
                this.search.projectModuleid = null;
                this.search.applicationMasterid = null;
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
            } else {
                this.getDropdownApplicationdata("t12");
                
            }
        },
        selprojectModuleid(state, value) {
            
            this.search.projectModuleid = state;
            if (!state && this.isAddState == false) {
                this.search.applicationModuleOpts = null;
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
            } else {
                this.getDropdownApplicationdata("t34");
            }
        },
        selApplicationid(state, value) {
            this.search.applicationMasterid = state;
            if (!state && this.isAddState == false) {
                this.search.projectModuleOpts = null;
                this.search.projectModuleid = null;
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
            } else {
                this.getDropdownApplicationdata("t36");
            }
        },

        selApplicationModuleid(state,value) {
            this.search.applicationModuleid = state;
            if (!state && this.isAddState == false) {
                this.search.sectionMasterOpts = null;
                this.search.sectionMasterid = null;
            } else {
                this.getDropdownApplicationdata("t35");
            }
        },
        resetFilter: function (){
            this.search.applicationModuleid = null;
            this.search.applicationMasterid = null;
            this.search.projectmoduleid= null;
            this.search.projectMasterid = null;
            this.search.projectModuleid = null;
            this.search.functionalityMasterid=null;
            this.projectnameList = null;
            this.search.functionalityMasterOpts=null;
            let rowdata = {}
            this.getfunctionalitylist(rowdata)
        },
        resetForm: function () {
            this.functionalitymodule.functionlityname = null       
            this.functionalitymodule.functionlitydescription = null
        },

        getProjectname(){
            this.apiURL="api/projectapplication/getprojectnamelist"
            this.input={
                projectid:0,
                empcode: this.undt.userid,
                useremail: this.undt.username,
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {           
                if(result.data.errorCode == 0){
                    this.search.projectMasterOpts = result.data.data;
                }
                else{
                    this.search.projectMasterOpts =[]
                }

            })
        },

        getDropdownApplicationdata(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail: this.undt.username,
            }
            if(mdl === "t36") {
                this.input.applicationMasterid = this.search.applicationMasterid
            } else if(mdl === "t12") {
                this.input.projectMasterid = this.search.projectMasterid
            } else if(mdl === "t34") {
                this.input.projectModuleid = this.search.projectModuleid
            }
            else if(mdl === "t35") {
                this.input.applicationModuleid = this.search.applicationModuleid
            } 
                else if(mdl === "t37") {
                this.input.sectionMasterid = this.search.sectionMasterid
            } 
            this.input.empcode= this.undt.userid
            this.input.useremail= this.undt.username

            axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = result.data.data
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = result.data.data
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = result.data.data;
                        } else if(mdl === "t35") {
                            this.search.sectionMasterOpts = result.data.data;
                        } 
                    this.loadFunctionalityNames("t37")
                    } else {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = [];
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = [];
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = [];
                        } else if(mdl === "t35") {
                            this.search.sectionMasterOpts = [];
                        }
                    }
                })

        },
        loadFunctionalityNames(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail: this.undt.username,
                projectMasterid: this.search.projectMasterid,
                applicationMasterid: this.search.applicationMasterid,
                projectModuleid: this.search.projectModuleid,
                applicationModuleid: this.search.applicationModuleid,
                sectionMasterid: this.search.sectionMasterid,
                empcode: this.undt.userid,
            }
            axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        this.search.functionalityMasterOpts = result.data.data;
                    } else {
                        this.search.functionalityMasterOpts = null;
                    }
                })
        },
        getfunctionalitylist(rowdata){
            this.isLoading=true
            this.clickedprojdata=rowdata
            this.input = {
               offset: this.pageoffset,
               limit: apiUrl.LIMIT,
               sectionmasterid:rowdata.sectionmasterid,
               usermail:this.undt.username,
               projectMasterid: rowdata.projectMasterid,
               projectModuleid: rowdata.projectModuleid,
               applicationMasterid: rowdata.applicationMasterid,
               applicationModuleid: rowdata.applicationModuleid,
               sectionMasterid: rowdata.sectionMasterid,
               functionalityMasterid: rowdata.functionalityMasterid,
               uniqueid: this.undt.userid,
                empcode: this.undt.userid,
                useremail: this.undt.username,
            }
            localStorage.setItem('functionalitymodule',window.btoa(JSON.stringify(this.input)));
            let url = 'api/functionality/list'
            this.functionalitymodule.project=this.clickedprojdata.projectname
            this.functionalitymodule.projectmodule=this.clickedprojdata.projectmodulename
            this.functionalitymodule.appprojectdd = this.clickedprojdata.projectid
            this.functionalitymodule.projectmoduleid = this.clickedprojdata.projectmoduleid
            this.functionalitymodule.applicationid = this.clickedprojdata.applicationid
            this.functionalitymodule.applicationname = this.clickedprojdata.applicationname
            this.functionalitymodule.applicationmodulename = this.clickedprojdata.applicationmodulename
            this.functionalitymodule.sectionname = this.clickedprojdata.sectionname
            this.functionalitymodule.sectionmasterid = this.clickedprojdata.sectionmasterid
            this.functionalitymodule.applicationmoduleid = this.clickedprojdata.applicationmoduleid
            this.functionalitymodule.projectid = this.clickedprojdata.projectid
            this.functionalitymodule.label = this.clickedprojdata.label
            this.functionalitymodule.projecttypeid=this.clickedprojdata.projecttypeid
            this.functionalitymodule.businessverticleid=this.clickedprojdata.businessverticleid
            this.functionalitymodule.companyid=this.clickedprojdata.companyid
            this.getApplicationdata('t3','functionalitymodule')
            this.getApplicationdata('t2','functionalitymodule')
            this.getApplicationdata('t1','functionalitymodule')
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading=false; 
                if(result.data.errorCode == 0){
                    this.showproject=true;
                    this.companymasterlist=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                }else{
                    this.companymasterlist=[]
                    this.showproject=true;
                    this.totalcount = 0
                }
                setTimeout(function(){
                    if(rowdata.backbtnstate == 'edit'){
                        $('#commonaddmodule').click();
                    }
                }, 1000);                
            })
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle == 't22'){
                this.customlisting(inputEle,'')
            }else{
                this.getfunctionalitylist(this.clickedprojdata)
            }
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        valid2Text : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        setSelected(value)
        {
            this.selected=value
        },
        validateForm(mdl,objThis){
            this.isAddState=false
            var isValid=true;
            this.functionlitydescBlured=true;
            this.functionlitynameBlured=true;
            if(mdl=="t37"){
                if(!this.functionalitymodule.sectionmasterid){
                    this.errors.functionalitymodule.sectionmasterid="Section required";
                    isValid= false;
                }
                if(!this.functionalitymodule.applicationmoduleid){
                    this.errors.functionalitymodule.applicationmoduleid="Application Module required";
                    isValid= false;
                }
                if(!this.functionalitymodule.projectid){
                    this.errors.functionalitymodule.projectid="Project required";
                    isValid= false;
                }
                if(!this.functionalitymodule.functionlityname){
                    this.errors.functionalitymodule.functionlityname="Functionality name required";
                    isValid= false;
                }
                if(!this.functionalitymodule.functionlitydescription){
                    this.errors.functionalitymodule.functionlitydescription="Functionality description required";
                    isValid= false;
                }
            }
            return isValid
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm(){
            this.errors= {
                functionalitymodule:{
                    functionlityname:null,
                    functionlitydescription:null
                },
            };
        },
        openEditModule(currRowData){
            this.editRowData = currRowData
            this.functionalitymodule.sectionmasterid = currRowData.sectionmasterid
            this.functionalitymodule.projectid = currRowData.projectid
            this.functionalitymodule.projectmoduleid = currRowData.projectmoduleid
            this.functionalitymodule.applicationmoduleid = currRowData.applicationmoduleid
            this.functionalitymodule.applicationid = currRowData.applicationid
            this.functionalitymodule.functionlityname = currRowData.functionlityname
            this.functionalitymodule.functionlitydescription = currRowData.functionlitydescription

            this.functionalitymodule.project = currRowData.projectname
            this.functionalitymodule.projectmodule= currRowData.projectmodulename
            this.functionalitymodule.applicationname= currRowData.applicationname
            this.functionalitymodule.applicationmodulename= currRowData.applicationmodulename
            this.functionalitymodule.sectionname= currRowData.sectionname
            this.functionalitymodule.projecttypeid= currRowData.projecttypeid
            this.functionalitymodule.businessverticleid= currRowData.businessverticleid
            this.functionalitymodule.companyid= currRowData.companyid

            this.functionalitymodule.iseditstate = true
            this.isAddState=true
        },
        getApplicationByAppModuleId: function(appmoduleid){
            this.inputprojappl = { appmoduleid,empcode: this.undt.userid,useremail: this.undt.username }
            let apiURL='api/application/getApplicationByAppModuleId'
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.inputprojappl,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.functionalitymodule.applicationname = result.data.data[0].applicationname
                }else{
                    this.applicationmodule.applicationid=null
                }
            })
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        saveData(tblNm,objThis){
            if( tblNm=="t37"){
                this.valid=this.validateForm(tblNm)
            }else{
                this.valid=false;
            }
            if (this.valid) {
                let nameobj=document.getElementById('modulename'+tblNm)
                this.input = {
                    tbl: tblNm,
                    section: 'assignment',
                    createdby: this.undt.username
                }
                if(nameobj != null && nameobj != 'undefined')
                {
                    this.input.name=nameobj.value
                }
                let apiURL='api/functionality/create'
                if(tblNm == 't37'){
                    this.input.sectionid=this.functionalitymodule.sectionmasterid
                    this.input.projectid=this.functionalitymodule.projectid
                    this.input.projectmoduleid=this.functionalitymodule.projectmoduleid
                    this.input.applicationmoduleid=this.functionalitymodule.applicationmoduleid
                    if(this.functionalitymodule.applicationid){
                        this.input.applicationid=this.functionalitymodule.applicationid
                    }else{
                        this.input.applicationid=null
                    }
                    this.input.functionlityname=this.functionalitymodule.functionlityname
                    this.input.functionlitydescription=this.functionalitymodule.functionlitydescription
                    this.input.section='assignment'
                    delete this.input.name;
                } 
                if(this.functionalitymodule.iseditstate == true){
                    apiURL='api/functionality/update'
                    this.input.lastmodifiedby=this.undt.username
                    this.input.id=this.editRowData.functionlitymasterid
                    delete this.input.createdby;
                }   
                this.input.empcode=this.undt.userid
                this.input.useremail=this.undt.username
                this.isAddState=false
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        $('.close').click();
                         this.$router.push('/functionality/list')
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.rowdata={
                            label : this.functionalitymodule.label,
                            projectname : this.functionalitymodule.project,
                            projectmodulename : this.functionalitymodule.projectmodule,
                            appprojectdd : this.functionalitymodule.projectid,
                            projectmoduleid : this.functionalitymodule.projectmoduleid,
                            sectionmasterid : this.functionalitymodule.sectionmasterid,
                            applicationid : this.functionalitymodule.applicationid,
                            applicationname : this.functionalitymodule.applicationname,
                            applicationmodulename : this.functionalitymodule.applicationmodulename,
                            sectionname : this.functionalitymodule.sectionname,
                            applicationmoduleid : this.functionalitymodule.applicationmoduleid,
                            projectid : this.functionalitymodule.projectid,
                            projecttypeid : this.functionalitymodule.projecttypeid,
                            businessverticleid : this.functionalitymodule.businessverticleid,
                            companyid : this.functionalitymodule.companyid
                        }
                        this.getfunctionalitylist(this.rowdata)
                        this.isLoading = false
                        this.clearerrorForm();
                    } else {
                         this.$router.push('/functionality/list')
                        Swal.fire({
                            title: "Oops...!",
                            text: result.data.msg,
                            icon: "error",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }
                })
            }
        },
        selfmsectionmdldata(state,value){
            this.functionalitymodule.sectionmasterid=null
            this.functionalitymodule.sectionmasterid=state
            if(!state && this.isAddState == false){
                this.errors.functionalitymodule.sectionmasterid="Section Required"
            }else{                
                this.errors.functionalitymodule.sectionmasterid=null
                this.getfiltereddata('functionalitymodule');
            }
        },
        selfmprojectdata(state,value){
            this.functionalitymodule.projectid=null
            this.functionalitymodule.projectid=state
            if(!state && this.isAddState == false){
                this.errors.functionalitymodule.projectid="Project Required"
            }else{                
                this.errors.functionalitymodule.projectid=null
            }
        },
        selfmapplmdldata(state,value){
            this.functionalitymodule.applicationmoduleid=null
            this.functionalitymodule.applicationmoduleid=state
            if(!state && this.isAddState == false){
                this.errors.functionalitymodule.applicationmoduleid='Application Module required'
            }else{                
                this.errors.functionalitymodule.applicationmoduleid=null
            }
        },
        selfmappmdldata(state,value){
            this.functionalitymodule.applicationid=null
            this.functionalitymodule.applicationid=state
            if(!state && this.isAddState == false){
                this.errors.functionalitymodule.applicationid='Application required'
            }else{                
                this.errors.functionalitymodule.applicationid=null
            }
        },
        getApplicationdata(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT
            }
            let apiURL='api/master/commonmaster/dropdownlist'
            if(curracttab == 'functionalitymodule'){
                this.inputappl = {
                    tbl:mdl
                }
            }
            this.inputappl.empcode=this.undt.userid
            this.inputappl.useremail=this.undt.username

            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    if(mdl == 't2'){
                        this.functionalitymodule.businesslist=result.data.data.rows
                        return true;                            
                    }else if(mdl == 't3'){
                        if(curracttab == 'functionalitymodule'){
                            this.functionalitymodule.projecttypelist=result.data.data.rows
                            return true;
                        }
                    }else if(mdl == 't1'){
                        this.functionalitymodule.companylist=result.data.data.rows
                        return true;
                    }
                }
            })
        },
        addmodule(){         
            this.functionalitymodule.functionlityname = null
            this.functionalitymodule.functionlitydescription = null
            this.functionalitymodule.iseditstate = false
        }
    }
}
</script>