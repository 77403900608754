<template>
    <div>
        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :is-full-page="fullPage"
          color="orange"
          loader="dots"
          :width="64"
          :height="64"
        ></loading>
        <div class="app-content content devcontent ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper">
                <div class="content-header row">
                    <div class="content-header-left col-md-12 col-12 mb-1">
                        <div class="row breadcrumbs-top">
                            <div class="col-12 mobile_view_padd-05">
                                <h3 class="content-header-title float-left mb-0 view_breadcram_title_view ">Designation Band Master</h3>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item"><router-link to='/trs/mydashboard'>My Dashboard</router-link></li>
                                        <li class="breadcrumb-item"><router-link to='/trs/designationbandmaster'>Designation Band Master</router-link></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-body">
                    <div class="row">
                        <div class="col-12 mobile_view_padd-05">
                            <div class="card border-warning filter_card_searchbgst">
                               <div class="card-header filter_rotation_onlick" v-on:click="mysearchrequestollapse" style="cursor:pointer;">
                                    <div class="container-fluid" >
                                        <div class="row fc_my-05" >
                                            <div class="w-auto px-1 mobile-px-0">
                                                <div class="avatar bg-light-secondary rounded d-inline-flex" >
                                                    <div class="avatar-content">
                                                    <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                                    </div>
                                                </div>
                                                <h4 class="card-title d-inline-flex ml-1">Search Designation Band Master</h4>
                                            </div>
                                           
                                            
                                            <div class="ml-auto d-flex">
                                                <div class="input-group-append float-right mr-1">
                                                    <button id="commonaddmodule" class="btn btn-sm btn-relief-success"  data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#designationbandmaster"  type="button" data-backdrop="static" data-keyboard="false" >
                                                        <plus-circle-icon  size="1.5x" class="custom-class font-medium-2"></plus-circle-icon>
                                                        <span class="mobile-view_text-hide-01"> Create</span>
                                                    </button>
                                                    
                                                </div>
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
                                    <div class="card-body card_padding_10">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4 col-xs-12 mb-1">
                                                <label class="form-label">Department </label>
                                                <treeselect placeholder="Select Department" v-model="search.deptid" :options="departmentList" />
                                            </div>
                                            <div class="col-md-4 col-sm-4 col-xs-12 mb-1">
                                                <label class="form-label">Designation </label>
                                                <treeselect placeholder="Select Designation" v-model="search.designationid"  :options="designationList"/>
                                            </div>
                                            <div class="col-md-4 col-sm-4 col-xs-12 common_trs_buttons_res">
                                                <button type="submit" class="btn btn-primary mt-2 mr-1" @click="getmasterlist(search.deptid,search.designationid)" >Search</button>
                                                <button type="reset" class="btn btn-outline-secondary mt-2" v-on:click="mysearchrequestollapse"  @click="resetRecords()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card border-warning filter_card_searchbgst">
                                <div class="card-header filter_rotation_onlick" style="cursor:pointer;" v-on:click="mydetailsollapse">
                                    <div class="container-fluid" >
                                        <div class="row fc_my-05" >
                                            <div class="w-auto px-1 mobile-px-0">
                                                <div class="avatar bg-light-secondary rounded d-inline-flex" >
                                                    <div class="avatar-content">
                                                        <clipboard-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></clipboard-icon>
                                                    </div>
                                                </div>
                                                <h4 class="card-title d-inline-flex ml-1">Designation Band Master Details</h4>
                                            </div>
                                            
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isDetActive},card_content,collapse]" >
                                    <div class="card-body px-0 pt-0">
                                        <div class="row mx-0">
                                            <div class="table-responsive"  v-if="showtabledata">
                                                <table aria-describedby="mydesc" class="table table-sm dataTableListing table-striped theade_padding_y10">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" class="py-1">SR#</th>
                                                            <th class="text-left py-1" scope="col" style="min-width:220px">Designation</th>
                                                            <th class="text-left py-1" scope="col">Department</th>
                                                            <th scope="col" class="text-left py-1">Band</th>
                                                            <th class="text-left py-1" scope="col">LastModifiedDate</th>
                                                            <th scope="col" class="text-left py-1">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(data,index) in requestList" v-bind:key="index">
                                                            <td>{{ index+1+currPageNum*pagelimit }}</td>
                                                            <td class="text-left">{{data.designation}}</td>
                                                            <td class="text-left">{{data.department}}</td>
                                                            <td>{{data.band}}</td>
                                                             <td class="text-left">{{dateFormat(data.lastmodifieddate)}}</td>
                                                            <td class="trasportactivecolor">
                                                                 <a class="dropdown-item devEditEle" href="javascript:void(0);"   data-toggle="modal" data-target="#designationbandmaster" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false" style="padding:5px;">
                                                                 <edit-icon size="1.5x" class="custom-class mr-50 font-medium-3"></edit-icon>
                                                                    <span>Edit</span>
                                                                 </a>
                                                            </td>
                                                        </tr>
                                                          <tr v-if="requestList.length ==0">
                                                            <td colspan="6">No Record Found</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                         <div class="pageCenter pt-2 text-center">
                                            <pagination class="pull-right" v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add/Edit HOD  Master -->
        <div class="modal modal-slide-in fade" id="designationbandmaster" aria-hidden="true">
                <div class="modal-dialog sidebar-lg">
                    <div class="modal-content p-0">
                        <button type="button" class="close" data-dismiss="modal"  aria-label="Close" style="color:#ffff !important" @click="resetForm()">×</button>
                        <div class="modal-header mb-1 px-1" style="background-color:#8c564b !important;">
                            <h5 class="modal-title sidebar-w-95" style="color:#ffff !important">
                                <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                                <span class="align-middle ml-50">Add/Update Designation Band Master Details</span>
                            </h5>
                        </div>
                        <div class="modal-body flex-grow-1 devUniqueForm px-0">
                            
                            <form>
                               
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="appmdldesc">Designation</label>
                                        <treeselect placeholder="Select Designation" v-model="request.designationid"  :options="designationList"   @input="seldesignationdata" @close="validateForm('t37',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.request.designationid">{{ this.errors.request.designationid }}</div>
                                    </div>
                                </div>
                                 <div class="col-12">
                                   <div class="form-group">
                                     <label for="appmdlnm">Department</label>
                                        <treeselect placeholder="Select Department" v-model="request.deptid"  :options="departmentList" @input="seldeptdata" @close="validateForm('t37',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.request.deptid">{{ this.errors.request.deptid }}</div>
                                    </div>
                                </div>
                                 <div class="col-12 mb-2">
                                      <label class="form-label">Band</label>
                                      <input type="text" v-model="request.band" class="form-control" placeholder="Enter Band" @input="selbanddata" @close="validateForm('t37',$event)"/>
                                      <div class="errorinputmsg" v-if="this.errors.request.band">{{ this.errors.request.band }}</div>
                                  </div>
                                  <br>
                                <div class="col-12">
                                    <div class="form-group d-flex flex-wrap mb-0" >
                                        <button type="button" class="btn btn-relief-primary mr-1 " id="submitbtnt36" data-dismiss="modal" v-on:click.stop.prevent="saveData('t37',$event)">Submit</button>
                                        <button style="margin-right:2px;" type="reset" class="btn btn-outline-secondary" @click="resetForm()">Clear</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Add/Edit HOD Master --> 
             
    </div>
    </template>
    <script>
    import axios from 'axios'
    import moment from "moment";
    import apiUrl from "../../../constants";
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Pagination from "vue-pagination-2";
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import { SearchIcon,PlusIcon,ClipboardIcon,EditIcon,PlusSquareIcon,PlusCircleIcon } from 'vue-feather-icons'
    export default {
        name: 'DesignationBandMaster',
        data(){
            return{
                search:{
                    deptid:null,
                    designationid:null,
                },
                request:{
                    designation:'',
                    department:'',
                    deptid:null,
                    designationid:null,
                    band:null
                },
                errors:{
                    request:{
                        designation:'',
                        department:'',
                        deptid:null,
                        designationid:null,
                        band:null
                    }
                },
                designationList:null,
                departmentList:null,
                isLoading: false,
                fullPage: true,
                isSearchRequestActive:false,
                isDetActive:true,
                requestList:[],
                card_content:'card-content',
                collapse:'collapse',
                empid:null,
                username:null,
                glbMdl: "",
                pagefeatures:[],
                isRequestActive:false,
                nextbtn: "next",
                addformbtntxt:'Submit',
                currttbl: "t37",
                iseditstate:false,
                showtabledata: true,
                 totalcount: 0,
               pageCount: 0,    
               page:1,
               pagelimit:apiUrl.LIMIT,
               currPageNum: 0,
               pageoffset:1,
               tokendata: null,
            }
        },
        components: {
            SearchIcon,Loading,PlusIcon,ClipboardIcon,Pagination,
            Treeselect,EditIcon,PlusSquareIcon,PlusCircleIcon
        },
        created() {
            this.pagefeatures = {}
            let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
            if (element.page === '/designationbandmaster') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
            })
        },
        mounted(){
            let userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.tokendata = window.localStorage.getItem("token");
                this.username = userdata.username
                this.empid = userdata.userid
                this.getmasterlist()
                this.getdepartmentlist()
                this.getdesignationlist()
            }
        },
        methods:{
          clickCallback: function(pageNum) {
                this.pageoffset=pageNum
                this.currPageNum = pageNum - 1
                this.getmasterlist(this.clickedprojdata)
            },
            mysearchrequestollapse:function(){
                this.isSearchRequestActive=!this.isSearchRequestActive
            },
            mydetailsollapse:function(){
                this.isDetActive=!this.isDetActive
            },
             myrequestollapse:function(){
                this.isRequestActive=!this.isRequestActive
            },
            dateFormat(value){
                if (!value) 
                    return "";
                else 
                    return moment(String(value)).format('DD-MM-YYYY HH:mm');
            },
            saveData(){
                this.isValid=false
                this.isValid = this.validateForm();
                if(this.isValid){
                    let apiURL='api/designationbandmaster/create'
                    this.input={
                        deptid:this.request.deptid,
                        designationid:this.request.designationid,
                        band:(this.request.band).toUpperCase(),
                        createdby:this.username ,
                        lastmodifieddate:this.lastmodifieddate,
                        empcode:this.empid,
                        useremail: this.username
                    }
                    if(this.iseditstate===true){
                        this.input.designationbandmasterid= this.editRowData.id
                        apiURL='api/designationbandmaster/update'
                    }
                    axios({
                        'method': 'POST',
                        'url': apiURL,
                        'data':this.input,
                        headers: { authorization: this.tokendata },
                    })
                    .then(result => {     
                        if(result.data.status){
                            $(".close").click();
                            this.response = result.data.data;
                            this.iseditstate=false
                            this.request.deptid = null
                            this.request.designationid = null 
                            this.request.band = null
                            this.getmasterlist()
                            Swal.fire({
                                title: "Success",
                                text: this.response.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                            this.rowdata={
                                deptid:this.request.deptid,
                                designationid:this.request.designationid,
                                band:this.request.band
                            }
                        }else{
                            this.response=null
                            $(".close").click();
                            this.request.deptid = null
                            this.request.designationid = null 
                            this.request.band = null
                            Swal.fire({
                                title: "",
                                text:  result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    })
                }
            },
             resetRecords: function () {
                this.search.deptid = null;
                this.search.designationid = null;
                this.search.band = null
                localStorage.removeItem('searchItems')
                this.getmasterlist();
                this.validateForm()
            },
            openEditModule(currRowData){
                this.editRowData = currRowData
                this.request.deptid = currRowData.deptid
                this.request.designationid = currRowData.designationid
                this.request.band = currRowData.band
                this.isAddState=true
                this.iseditstate = true
            },
           resetForm: function() {
                this.request.deptid = null
                this.request.designationid = null
                this.request.band = null
                this.file=[]
            },
            validateForm(){
                let isValid=true;
                if(!this.request.deptid){
                    this.errors.request.deptid="Department name is  required";
                    isValid= false;
                } else {
                  this.errors.request.deptid = ""
                }
    
                if(!this.request.designationid){
                    this.errors.request.designationid="Designation name is  required";
                    isValid= false;
                } else {
                  this.errors.request.designationid = ""
                }
                 if(!this.request.band){
                    this.errors.request.band="Band is  required";
                    isValid= false;
                } else {
                  this.errors.request.band = ""
                }
                return isValid
            },
            
            getmasterlist(deptid,designationid){
                this.isValid=false;
                this.apiURL="api/designationbandmaster/getmasterList" 
                this.input={
                    offset:this.pageoffset,
                    limit: apiUrl.LIMIT,
                    deptid:deptid,
                    designationid:designationid,
                    empcode:this.empid,
                    useremail: this.username
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                  .then(result => {             
                     if(result.data.status){
                        this.isLoading= false;
                         this.isDetActive=true;
                        this.requestList = result.data.data.responsedata;
                        this.totalcount = result.data.data.count
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }else{
                        this.requestList = [];
                        this.isLoading= false;
                        this.totalcount = 0;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }
                })
            },
               getdepartmentlist(){
                this.apiURL="api/listing/getdepartmentlist"
                this.input={
                    deptid:null,
                    empcode:this.empid,
                    useremail: this.username
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {             
                    if(result.data.status){
                        this.departmentList = result.data.data;
                    }else{
                        this.departmentList=null
                    }
                })
            },
            getdesignationlist(){
                this.apiURL="api/listing/getdesignationlist"
                this.input={
                    designationid:null,
                    empcode:this.empid,
                    useremail: this.username
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {             
                    if(result.data.status){
                        this.designationList = result.data.data;
                    }else{
                        this.designationList=null
                    }
                })
            },
              seldeptdata(state,value){
                this.request.deptid=null
                this.request.deptid=state
              if(!state && this.isAddState === false){
                this.errors.request.deptid='Department name is required'
            }else{                
                this.errors.request.deptid=null
            }
            },
            seldesignationdata(state,value){
                this.request.designationid=null
                this.request.designationid=state
             if(!state && this.isAddState === false){
                this.errors.request.designationid='Designationid name is required'
            }else{                
                this.errors.request.designationid=null
            }
            },
            selbanddata(state,value){
                this.band=null
                this.band=state
              if(!state && this.isAddState === false){
                this.errors.request.band='Band is required'
            }else{                
                this.errors.request.band=null
            }
            },
        }
    }
    </script>
    
    
    <style scoped>
    .trasportactivecolor a:hover, .trasportactivecolor a:active{
        background: transparent;
        color: blue;
        cursor: pointer;
    }
    </style>