<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper pb-1">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-2 pt-1 pad_top_mar_bot-5">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left pl-0 ">
                <h3 class="content-header-title float-left mb-0">Delayed Business Task Summary</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/reports/delayedBusinessTaskSummary'>Delayed Business Task Summary</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning" id="domain-list">
          <div class="card-body p-0 ps ps--active-x">
            <div class="table-responsive" >
              <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc" style="border-collapse:collapse;">
                <thead>
                   <tr>
                      <th scope="col" class="align-middle text-center first-col-sticky make-bold" style="min-width:140px;">Year/Week/Dept</th>
                      <th scope="col" class="align-middle text-center">TOTAL COUNT</th>
                    </tr>
                </thead>
                <tbody>
                  <template v-for="(data,index) in yearwise"  >
                    <tr v-bind:key="index+data.btyear" data-toggle="collapse" v-bind:data-target="'.demo'+data.btyear" style="font-weight: 600">
                      <td class="align-middle text-center first-col-sticky sitebluecolor make-bold" style="cursor:pointer" @click="getweekwise(data.btyear)">{{data.btyear}}</td>
                      <td class="align-middle text-center">{{data.count}}</td>
                    </tr>
                      <template v-for="(data2,index2) in weekwise">
                        <tr v-if="clickedYear == data.btyear" v-bind:key="'.demo'+index2+data.btyear+data2.btweek" data-toggle="collapse" :class="'collapse demo'+data.btyear" v-bind:data-target="'.demo'+data.btyear+data2.btweek">
                          <td class="hiddenRow align-middle text-center first-col-sticky make-bold" >
                              <div :class="'collapse sitebluecolor demo'+data.btyear"  style="cursor:pointer" @click="getdeptwise(data2.btyear, data2.btweek)" >{{data2.btweek}} : {{data2.btdates}}</div>
                          </td>
                          <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.count, 'count')">
                              <div :class="'collapse demo'+data.btyear" @click="openModal(data2, 'weekly')" style="cursor:pointer">{{data2.count}}</div>
                          </td>
                        </tr>
                        <template v-for="(data3,index3) in deptwise" >
                          <tr v-if="clickedYear == data.btyear && clickedWeek == data2.btweek" v-bind:key="'.demo'+index2+index3" :class="'collapse demo'+data3.btyear+data3.btweek">
                            <td class="hiddenRow align-middle text-center first-col-sticky make-bold">
                                <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.department}}</div>
                            </td>
                            <td class="hiddenRow align-middle text-center">
                                <div :class="'collapse rounded-circle demo'+data3.btyear+data3.btweek" style="cursor:pointer" @click="openModal(data3, 'department')">{{data3.count}}</div>
                            </td>
                          </tr>
                        </template>
                      </template>
                  </template>
                  <template v-if="businesstaskdeptmasterlist.length==0">
                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal fade" id="imagemodal" tabindex="2" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
          <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content ">              
                  <div class="modal-body p-0 ">
                    <button type="button" class="modal-video-close-btn modal-video-close-btn2" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                  </div>
                  <div class="modal-body p-2 mt-2">
                    <div v-if="currData && currData.btdates" style="display: flex; gap: 16px; margin-bottom: 16px; justify-content: center;">
                      <div><span style="font-weight:600">Estimated Date: </span>{{currData.btdates.replace('-->', ' to ')}}</div>
                      <span class="mx-50">|</span>
                      <div v-if="currType === 'department'">
                        <span style="font-weight:600">Department: </span> {{currData.department}}
                        <span class="mx-50">|</span>
                      </div>
                      <div>
                        <p v-if="tasklist.length==0">No record found</p>
                        <p v-else> {{tasklist.length}} {{" "}} Records</p>
                      </div>
                    </div>
                    <div class="card border-warning" id="document-list">
                      <div class="card-body p-0 ps ps--active-x">
                        <div class="table-responsive" >
                          <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
                            <thead>
                              <tr>
                                <th scope="col" class="text-center first-col-sticky" style="min-width:45px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
                                <th scope="col" class="text-center second-col-sticky">BUSINESS TASK DETAILS</th>
                                <th scope="col" class="text-center">Description</th>
                                <th scope="col" class="text-center">Date Details</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(data,index) in tasklist"  >
                                <tr v-bind:key="index" >
                                  <td class="text-center text-capitalize">{{ index+1+currPageNum*pagelimit }}</td>
                                  <td class="text-normal details_column_table second-col-sticky" style="max-width:20%;">
                                      <span> {{data.businesstaskcode}}</span>
                                      <hr class="hr_border-bottom" />
                                      <div v-if="data.taskkey.toLowerCase()=='key' || data.taskkey.toLowerCase()=='urgent'" class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.taskkey}}</div>
                                      <div v-if="data.taskkey.toLowerCase()=='major'" class="badge badge-pill badge-light-warning mr-50 capitalisetext">{{data.taskkey}}</div>
                                      <div v-if="data.taskkey.toLowerCase()=='minor'" class="badge badge-pill badge-light-primary mr-50 capitalisetext">{{data.taskkey}}</div>
                                      <div v-if="data.taskkey.toLowerCase()=='cosmetic'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.taskkey}}</div>
                                      <div v-if="data.taskkey.toLowerCase()=='normal'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.taskkey}}</div>
                                      <div v-if="(data.isapproved_edd && data.isapproved_edd == 'Pending')" class="badge badge-pill badge-light-warning mr-50 capitalisetext">Pending</div>
                                      <div class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.taskstatus}}</div>
                                      <hr class="hr_border-bottom" />
                                      <span> <strong>Redmine ID : </strong> </span>
                                      <span class="capitalisetext">
                                      {{data.redmineid? data.redmineid:'NA'}}
                                      <hr class="hr_border-bottom" />
                                      <span> <strong>Name : </strong> </span>
                                      <span class="font-weight-bold sitebluecolor"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer;">{{data.taskname}}</span>
                                    </span>
                                  </td>
                                  <td class="text-capitalize" style="max-width:5%;">
                                    <span v-html="displayStrings(data.taskdescription)"></span>
                                  </td>
                                  <td class="details_column_table">
                                    <span class="heading_column_text text-capitalize"> CREATED BY : </span>
                                    {{data.createdby.split('@')[0].split('.').join(' ')}}
                                    <hr class="hr_border-bottom" />
                                    <span class="heading_column_text"> Due date : </span>
                                    <span v-if="data.due_date"> {{dateMonthFormat(data.estimatedddate)}} </span>
                                    <span v-else> NA </span>
                                    <hr class="hr_border-bottom" />
                                    <span class="heading_column_text full_100"> Created date : </span>
                                    {{dateMonthFormat2(data.createddate)}}
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
$('.collapse').on('show.bs.collapse', function () {
    $('.collapse.in').collapse('hide');
});
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import Treeselect from '@riophae/vue-treeselect'
import DatePicker from "vue2-datepicker";
import moment from "moment";

import 'vue-loading-overlay/dist/vue-loading.css'
import "vue2-datepicker/index.css";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { PlusCircleIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, ExternalLinkIcon, XIcon } from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';

export default {
  name: 'DelayedBusinessTaskSummary',
  components: {
    VueElementLoading,
    Loading,
    Treeselect,
    DatePicker,
    PlusCircleIcon,
    EditIcon,
    CheckCircleIcon,
    XCircleIcon,
    SearchIcon,
    ExternalLinkIcon,
    XIcon,
  },
  mixins: [ commonMethods ],

  data() {
    return {
      businesstaskdeptmasterlist: [],
      tasklist: [],
      currData: null,
      currType: null,
      yearList: ['2024', '2023', '2022', '2021', '2020'],
      yearwise: [],
      weekwise: [],
      deptwise: [],
      clickedYear: null,
      clickedWeek: null,
      search: {
      },
      errors: {
      },
      isactive: true,
      isSearchBoxActive: false,
      card_content:'card-content',
      collapse:'collapse',
      isLoading: false,
      undt: null,
      tokendata: null,
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,
      pagefeatures:[],
      TimeFormat: apiUrl.TimeFormat,
    }
  },
  mounted() {
    // let permission = this.$Service.verifyAccess();
    // if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
        this.getbusinesstaskdeptmasterlist()
      }
    // }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    displayStrings(givenstring) {
      if(!givenstring) {
        return 'NA'
      }
      if(givenstring.length > 130) {
        return givenstring.substring(0,130) + '...'
      }
      return givenstring
    },
    openModal(data, type) {
      this.isLoading = true
      this.currData = data
      this.currType = type
      let [estimateddatefrom, estimatedateto] = data.btdates.split('-->')
      let department = null
      if(type === 'department') {
        department = this.currData.department
      }
      let url = 'api/reports/getDelayedTaskList'
      let payload = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        estimateddatefrom,
        estimatedateto,
        department,
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.status) {
          this.tasklist = result.data.data.rows
        } else {
          this.tasklist = []
          Swal.fire({
            title: "",
            text: result.data.msg,
            icon: "info",
            customClass: { confirmButton: "btn btn-primary" },
            buttonsStyling: !1,
          });
        }
      }).catch(e => {
        this.tasklist = []
        Swal.fire({
          title: "",
          text: e,
          icon: "info",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: !1,
        });
      })
      $('#imagemodal').modal('toggle');
    },
    closeModal() {
      $('#imagemodal').modal('toggle');
      console.log('sadklj')
    },
    redirecttourl(btnstate,rowdata) {
      rowdata.backbtnstate=btnstate
        let routeData = this.$router.resolve({ 
        path: '/businesstask/view?businessid='+rowdata.businesstaskmasterid,
        params: {
          backbtnstate:btnstate,
          clickeddata:rowdata
        }
      })
      window.open(routeData.href, '_blank');
    },
    issmaller(year, week, currval, key) {
      let prevweekdata = this.weekwise.filter(i => i.btyear == year && i.btweek == parseInt(week) - 1)
      if(prevweekdata && prevweekdata.length) {
        let prevval = prevweekdata[0][key]
        if(parseInt(prevval) > parseInt(currval)) {
          return 'red'
        } else if(parseInt(prevval) < parseInt(currval)) {
          return 'green'
        }
      } else {
        return 'green'
      }
      return 'orange'
    },
    getdeptwise(year, week) {
      this.clickedWeek = week
      let weekdata = this.businesstaskdeptmasterlist.filter(i => i.btyear == year && i.btweek == week)
      let deptwise = []
      let distinctDept = []
      for(let data of weekdata) {
        let department = data.department
        let currDeptData = weekdata.filter(i => i.btyear == year && i.btweek == week && i.department == department)
        if(!distinctDept.includes(department)) {
          distinctDept.push(department)
          deptwise.push({
            "btyear": year,
            "btweek": week,
            "btdates": data.weekdates,
            "department": department ? department : 'NA',
            "count": currDeptData.reduce((total, currVal) => total + parseInt(currVal.count), 0)
          })
        }
      }
      this.deptwise = deptwise
    },
    getweekwise(year) {
      this.clickedYear = year
      this.clickedWeek = null
      let yearData = this.businesstaskdeptmasterlist.filter(i => i.btyear == year)
      let weekwise = []
      let distinctWeeks = []
      for(let weekdata of yearData) {
        let btweek = weekdata.btweek
        let currWeekData = yearData.filter(i => i.btyear == year && i.btweek == btweek)
        if(!distinctWeeks.includes(btweek)) {
          distinctWeeks.push(btweek)
          console.log(weekdata,'--')
          weekwise.push({
            "btyear": year,
            "btweek": btweek,
            "btdates": weekdata.weekdates,
            "count": currWeekData.reduce((total, currVal) => total + parseInt(currVal.count), 0)
          })
        }
      }
      this.weekwise = weekwise
    },
    getbusinesstaskdeptmasterlist(search, isExportedFlag) {
      this.isLoading = true
      let url = 'api/reports/delayedBusinessTaskSummary'
      let payload = {
        useremail: this.undt.username,
        empcode: this.undt.userid
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.status) {
          let d = result.data.data.rows
          let ys = result.data.data.yearList
          this.businesstaskdeptmasterlist = d
          this.yearList = ys.map(i => i.date_trunc)
          let yearwise = []
          for(let year of this.yearList) {
            let yearData = this.businesstaskdeptmasterlist.filter(i => i.btyear == year)
            let obj = {
              "btyear": year,
              "count": yearData.reduce((total, currVal) => total + parseInt(currVal.count), 0),
            }
            yearwise.push(obj)
            this.yearwise = yearwise
          }
        } else {
          this.businesstaskdeptmasterlist = []
          Swal.fire({
            title: "",
            text: result.data.msg,
            icon: "info",
            customClass: { confirmButton: "btn btn-primary" },
            buttonsStyling: !1,
          });
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    dateMONFormat(value){
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateMonthFormat(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY');
    },
    dateMonthFormat2(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
  }
}
</script>
<style scoped>
  .box-inc {
    min-width: 200px;
  }
  .mark-red {
    color: red;
  }
  .mark-green {
    color: green;
  }
  .mark-orange {
    color: orangered;
  }
  .make-bold {
    font-weight: 600
  }
  .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
  }
  .modal-video-close-btn2 {

  }
</style>