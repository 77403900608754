import axios from "axios"
import moment from "moment"
import CryptoJS from 'crypto-js';
import constants from "../constants";
const key = constants.key;
const iv  = constants.iv;
const SUCCESS_COLOER = '#51cf66'
const ERROR_COLOR = '#ffabab'
const NORMAL_COLOR = '#ffe066'

export default {
  methods: {
    formatTimeStamp(ts) {
      if(!ts) return '-'
      let timeStamp = moment(ts).format('YYYY-MM-DD HH:mm')
      return timeStamp
    },
    formatTimeStampToMonth(ts) {
      if(!ts) return '-'
      let timeStamp = moment(ts).format("DD MMM 'YY HH:mm")
      return timeStamp
    },
    formatCSVCellData(value) {
      if(!value)  return '-'
      if(typeof value === 'string') return value.replace(/"/g, '""')
      return value
    },
    formatValue(value) {
      if(!value) return '-'
      return value
    },
    currentDateForFileName() {
      return moment().format('DD_MM_YY_HH_mm_ss') 
    },
    convertEmailIdToName(emailid) {
      if(!emailid)  return '-'
      let name = emailid.split('@')
      if(name && name.length) {
        let username = name[0].replace('.', ' ')
        username = username.split(' ').map(i => i.charAt(0).toUpperCase() + i.slice(1))
        name = username.join(' ')
        return name
      } else {
        return emailid
      }
    },
    sweetAlertSnackbar(message, type, end='bottom') {
      const Toast = Swal.mixin({
        toast: true,
        position: end,
        showConfirmButton: false,
        timer: !type ? 4000 : 8000,
        timerProgressBar: true,
        background: type === 'success' ? SUCCESS_COLOER : type === 'error' ? ERROR_COLOR : NORMAL_COLOR,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });
      Toast.fire({
        icon: type,
        title: message,
      })
    },
    displayError(e) {
      let response = e ? e.response : e
      this.isLoading = false
      let message = 'Something went wrong! ' + e
      if(response && response.data) message = ` ${response.data.msg ? response.data.msg : response.data.message}`
      this.sweetAlertSnackbar(message, 'error')
      if(response && (response.data.errorCode == 101 || response.data.errorCode == 3)) {
        window.location.href = "/#/login";
        localStorage.clear();
        Swal.fire({
          title: "Failed!",
          text: message,
          icon: 'info',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        })
        .then(function() {
          window.location.href = "/#/login";
        })
      }
    },
    getDownloadedLink(key) {
      axios({
        method: 'GET',
        url: `api/files/s3/getPresignedURL?fileKey=${key}`,
        headers: {
          authorization: this.tokendata,
          useremail: this.undt.username,
          empcode: this.undt.userid
        }
      })
      .then(result => {
        if(result.data.status) {
          this.sweetAlertSnackbar(result.data.message, 'success')
          window.open(result.data.data, '_blank')
        } else {
          this.sweetAlertSnackbar(result.data.message, 'error')
        }
      })
      .catch(e => {
        this.displayError(e)
      })
    },
    encrypt(text) {
      return CryptoJS.AES.encrypt(text, key, { iv: iv }).toString()
    },
    decryptText(text) {
      if(!text) return '-'
      let value =  Buffer.from(CryptoJS.AES.decrypt(text, key, { iv: iv }).toString(), 'hex').toString()
      return value
    }
  }
}