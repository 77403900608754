<template>
    <div>
        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :is-full-page="fullPage"
          color="orange"
          loader="dots"
          :width="64"
          :height="64"
        ></loading>
        <div class="app-content content devcontent ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper">
                <div class="content-header row">
                    <div class="content-header-left col-md-9 col-12 mb-1">
                        <div class="row breadcrumbs-top">
                            <div class="col-12 mobile_view_padd-05">
                                <h3 class="content-header-title float-left mb-0">My Approvals</h3>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item"><router-link to='/trs/mydashboard'>My Dashboard</router-link></li>
                                        <li class="breadcrumb-item"><router-link to='/trs/viewrequest'>View Request</router-link></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-body">
                   <div class="row">
                        <div class="col-12 mobile_view_padd-05">
                            <div class="card border-warning filter_card_searchbgst">
                               
                                <div class="card-header filter_rotation_onlick" v-on:click="mysearchrequestollapse" style="cursor:pointer;">
                                    <div class="container-fluid" >
                                        <div class="row" >
                                            <div class="w-auto px-1 mobile-padding-0-2">
                                            <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                <div class="avatar-content">
                                                    <search-icon
                                                    size="1.5x"
                                                    class="custom-class avatar-icon font-medium-3"
                                                    ></search-icon>
                                                </div>
                                            </div>
                                            <h4 class="card-title d-inline-flex ml-1">Search Approval Request</h4>
                                            </div>
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body card_padding_10">
                                        <div class="row">
                                        
                                            <div class="col-md-4 col-sm-4 col-xs-12 mb-1">
                                                <label class="form-label">Request ID     </label>
                                                <input class="form-control" type="text" placeholder="Enter Request ID" v-model="search.requestid"/>
                                            </div>
                                            <div class="col-md-4 col-sm-4 col-xs-12 mb-1">
                                                <label class="form-label">Status</label>
                                                <treeselect placeholder="Select Ticket Status" v-model="search.status"  :options="statuslist" />
                                            </div>
                                            <div class="col-md-4 col-sm-4 col-xs-12 common_trs_buttons_res">
                                                <button type="submit" class="btn btn-primary mt-2 mr-1" @click="getmasterlist(search.status,search.requestid)" >Search</button>
                                                <button type="reset" class="btn btn-outline-secondary mt-2" @click="resetRecords()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card card_box_shadow filter_card_searchbgst">
                                <div class="card-header filter_rotation_onlick" style="cursor:pointer;" v-on:click="mydetailsollapse">
                                    <div class="container-fluid" >
                                   
                                    <div class="row" >
                                            <div class="w-auto px-1 mobile-padding-0-2">
                                            <div class="avatar bg-light-secondary rounded d-inline-flex" >
                                                <div class="avatar-content">
                                                    <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                                </div>
                                            </div>
                                            <h4 class="card-title d-inline-flex ml-1">Travel Request Approval Details</h4>
                                            </div>
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                 </div>
                                </div>
                                <div v-bind:class="[{show: isDetActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body px-0 pt-0 full_main_containers_mobile">
    
                                        <div class="row">
                                             <b-container fluid class="table-responsive_view">
                                                <!-- Main table element -->
                                                <div class="full_main_containers">
                                                <b-table class="table table-sm dataTableListing table-striped theade_padding_y10"
                                                :items="items"
                                                :fields="fields"
                                                :current-page="currentPage"
                                                :per-page="perPage"
                                                :filter="filter"
                                                :filter-included-fields="filterOn"
                                                :sort-by.sync="sortBy"
                                                :sort-desc.sync="sortDesc"
                                                :sort-direction="sortDirection"
                                                stacked="md"
                                                show-empty
                                                small
                                                @filtered="onFiltered"
                                                v-model="currentItems"
                                                >
                                                <template v-slot:cell(actions)="{ detailsShowing, item,index }" >
                                                    <b-btn class="show_details_btn01" @click="toggleDetails(item);onRowSelected(item,index);">{{ detailsShowing ? 'Hide' : 'Show'}} Details</b-btn>
                                                </template>
                                                
                                                <template #row-details="row">
                                                    <b-card class="p-0 card_travels_mobile_veiw">
                                                       <div class="table-responsive" style="max-width: 100vh; width: 100%; min-width: 100%;">
                                                        <div id="app" class="table_responce44">
                                                            <b-row class="mx-0 inner_table_inlie_view mb-2">
                                                                <b-col class="inner_icons_table_request_table_head">
                                                                    <b-icon icon="person-fill" scale="2"  class="inner_icons_table_request_table" variant="secondary"></b-icon>
                                                                    <span class="viewdetais_namestext">{{ticketreq.empname}} </span>
                                                                </b-col>
                                                                <b-col class="inner_icons_table_request_table_head">
                                                                    <b-icon icon="envelope" scale="2" class="inner_icons_table_request_table" variant="warning"></b-icon>
                                                                    <span class="viewdetais_namestext">{{ticketreq.designation}} </span>
                                                                </b-col>
                                                                <b-col class="inner_icons_table_request_table_head">
                                                                    <b-icon icon="briefcase" scale="2" class="inner_icons_table_request_table" variant="info"></b-icon>
                                                                    <span class="viewdetais_namestext">{{ticketreq.empid}} </span>
                                                                </b-col>
                                                                <b-col class="inner_icons_table_request_table_head">
                                                                    <b-icon icon="grid-fill" scale="2" class="inner_icons_table_request_table" variant="success"></b-icon>
                                                                    <span class="viewdetais_namestext">{{ticketreq.dept}} </span>
                                                                </b-col>
                                                            </b-row>                                                       
                                                            <b-table-simple responsive>
                                                            <b-thead>
                                                                <b-tr>                                                               
                                                                    <b-th>Index</b-th>
                                                                    <b-th>City node</b-th>
                                                                    <b-th class="text-left" style="min-width:200px;">From City - To city</b-th>
                                                                    <b-th>Group Travel List</b-th>
                                                                    <b-th>Mode of Transport</b-th>
                                                                    <b-th>Special Instructions</b-th>
                                                                    <b-th class="text-left" style="min-width:270px;">Date Details</b-th>
                                                                    <!-- <b-th>Date Of Travel</b-th>
                                                                    <b-th>Appointment Date</b-th>
                                                                    <b-th style="min-width:200px;">CheckIn Date - CheckOut Date</b-th> -->
                                                                    <b-th>Operations</b-th>
                                                                    <b-th>Action</b-th>
                                                                </b-tr>
                                                            </b-thead>
                                                            <b-tbody>                                                            
                                                                <b-tr v-for="(data,index) in ticketdetails" :key="index">
                                                                    <b-td>{{index+1}}</b-td>
                                                                    <b-td>
                                                                        <span v-if="data.returnbooking ==2">Return Journey Details</span>
                                                                        <span v-else-if="data.returnbooking ==1">City node - {{data.node}}</span>                                                    
                                                                    </b-td>
                                                                    <b-td class="text-left">
                                                                        <span class="readmore_texttrs" v-if="data.fromcityname != null && data.cityname != null">
                                                                            <div class="col-md-12 px-0 tast_case_groupingview_readmore">
                                                                                <div class="custom-container case_bg_17">
                                                                                    <div class="outer" :id="'cycle_readmore'+'fromcityname'+index">
                                                                                        <span class="fc_m16_btcode_text" v-html="formatData(`${data.fromcityname} To ${data.cityname}`)"></span>
                                                                                    </div>
                                                                                    <div class="cycle_viewmoretext" v-if="(data.fromcityname+' '+data.cityname).length > 10">
                                                                                        <a class="btn">
                                                                                        <strong :id="'fromcityname'+index" @click="idclick('fromcityname'+index)">
                                                                                            Read More
                                                                                        </strong>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                        <span v-else>No Travel</span>
                                                                    </b-td>
                                                                    <b-td  >
                                                                        <span v-if="data.isgrouptravelreq == 1 && data.nameofgrouptravel !=null" style="cursor: pointer;" class="vew_request_hovertext">                                                    
                                                                            <eye-icon size="1.5x" class="custom-class" @click="getGroupTravelList(data.nameofgrouptravel)"></eye-icon>
                                                                        </span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <span v-if="data.transportmode != null">{{data.transportmode}}</span>
                                                                        
                                                                        <span v-else>N/A</span>
                                                                        
                                                                    </b-td>
                                                                    <b-td class="text-left">
                                                                        <span class="readmore_texttrs" v-if="data.instruction != null">                                                                 
                                                                            <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                                                <div class="custom-container case_bg_17">
                                                                                    <div class="outer" :id="'cycle_readmore'+'instruction'+index">
                                                                                        <span class="fc_m16_btcode_text" v-html="formatData(data.instruction)"></span>
                                                                                    </div>
                                                                                    <div class="cycle_viewmoretext" v-if="data.instruction.length > 10">
                                                                                        <a class="btn">
                                                                                        <strong :id="'instruction'+index" @click="idclick('instruction'+index)">
                                                                                            Read More
                                                                                        </strong>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    
                                                                    <b-td class="text-left details_column_table">
                                                                        <span class="heading_column_text"> Date Of Travel : </span>
                                                                        <span v-if="data.traveldate">{{data.traveldate}}</span>
                                                                        <span v-else>N/A</span>
                                                                        <br/>
                                                                        <span class="heading_column_text"> Appointment Date : </span>
                                                                        <span v-if="data.appointmentdate">{{data.appointmentdate}}</span>
                                                                        <span v-else>N/A</span>
                                                                        <br/>
                                                                        <span class="heading_column_text"> CheckIn Date - CheckOut Date : </span>
                                                                        <span v-if="data.checkindate && data.checkoutdate">{{data.checkindate}} To {{data.checkoutdate}}</span>
                                                                        <span v-else-if="data.returnbooking ==2">N/A</span>
                                                                        <span v-else>No Accomodation</span>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <span v-if="row.item.hodid ==1">
                                                                            <div class="transaction-item">
                                                                                <div class="media">
                                                                                    <div class="avatar bg-light-warning rounded">
                                                                                        <div class="avatar-content">
                                                                                            <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <small class="badge badge-pill badge-primary mr-1"> HOD Pending</small>                                                                                
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                        <span class="d-flex mt-2" v-else>
                                                                            <div class="transaction-item" v-if="data.assignedto==1">
                                                                                <div class="media">
                                                                                    <div class="avatar bg-light-warning rounded">
                                                                                        <div class="avatar-content">
                                                                                            <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <h6 class="transaction-title">L1 Status</h6>
                                                                                        <small class="badge badge-pill badge-primary mr-1" v-if="data.l1_status==0">Pending</small>
                                                                                        <small class="badge badge-pill badge-secondary mr-1"  v-else-if="data.l1_status==1">Draft</small>
                                                                                        <small class="badge badge-pill badge-success mr-1" v-else-if="data.l1_status==2">Approved</small>
                                                                                        <small class="badge badge-pill badge-warning mr-1"  v-else-if="data.l1_status==3">Feedback</small>
                                                                                        <small class="badge badge-pill badge-danger mr-1"  v-else-if="data.l1_status==4">Reject</small>
                                                                                        <small class="badge badge-pill badge-danger mr-1"  v-else-if="data.l1_status==6">Closed</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="transaction-item" v-if="data.l1_feedback && data.assignedto==1 && [2,3,4].includes(data.l1_status)">
                                                                                <div class="media">
                                                                                    <div class="avatar bg-light-warning rounded">
                                                                                        <div class="avatar-content">
                                                                                            <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="media-body readmore_texttrs">
                                                                                        <h6 class="transaction-title">L1 Feedback</h6>
                                                                                         <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                                                            <div class="custom-container case_bg_17">
                                                                                                <div class="outer" :id="'cycle_readmore'+'l1_feedback'+index">
                                                                                                    <span class="fc_m16_btcode_text" v-html="formatData(data.l1_feedback)"></span>
                                                                                                </div>
                                                                                                <div class="cycle_viewmoretext" v-if="data.l1_feedback.length > 10">
                                                                                                    <a class="btn">
                                                                                                    <strong :id="'l1_feedback'+index" @click="idclick('l1_feedback'+index)">
                                                                                                        Read More
                                                                                                    </strong>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="transaction-item" v-if="data.assignedto==2">
                                                                                <div class="media">
                                                                                    <div class="avatar bg-light-warning rounded">
                                                                                        <div class="avatar-content">
                                                                                            <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <h6 class="transaction-title">L2 Status</h6>
                                                                                        <small class="badge badge-pill badge-danger mr-1" v-if="data.l1_status==4">Reject</small>
                                                                                        <small class="badge badge-pill badge-primary mr-1" v-else-if="data.l2_status==0">Pending</small>
                                                                                        <small class="badge badge-pill badge-secondary mr-1"  v-else-if="data.l2_status==1">Draft</small>
                                                                                        <small class="badge badge-pill badge-success mr-1" v-else-if="data.l2_status==2">Approved</small>
                                                                                        <small class="badge badge-pill badge-warning mr-1"  v-else-if="data.l2_status==3">Feedback</small>
                                                                                        <small class="badge badge-pill badge-danger mr-1"  v-else-if="data.l2_status==4">Reject</small>
                                                                                        <small class="badge badge-pill badge-danger mr-1"  v-else-if="data.l2_status==6">Closed</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="transaction-item" v-if="data.l2_feedback && data.assignedto==2 && [2,3,4].includes(data.l2_status)">
                                                                                <div class="media">
                                                                                    <div class="avatar bg-light-warning rounded">
                                                                                        <div class="avatar-content">
                                                                                            <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <h6 class="transaction-title">L2 Feedback</h6>
                                                                                         <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                                                            <div class="custom-container case_bg_17">
                                                                                                <div class="outer" :id="'cycle_readmore'+index">
                                                                                                    <span class="fc_m16_btcode_text" v-html="formatData(data.l2_feedback)"></span>
                                                                                                </div>
                                                                                                <div class="cycle_viewmoretext" v-if="data.l2_feedback.length > 10">
                                                                                                    <a class="btn">
                                                                                                    <strong :id="index" @click="idclick(index)">
                                                                                                        Read More
                                                                                                    </strong>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <span v-if="row.item.hodid ==1">
                                                                            <div class="transaction-item">
                                                                                <div class="media">
                                                                                    <div class="avatar bg-light-warning rounded">
                                                                                        <div class="avatar-content">
                                                                                            <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <small class="badge badge-pill badge-primary mr-1"> HOD Pending</small>                                                                                
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                        <span v-else>
                                                                            <span v-if="data.cancellation == 0">
                                                                            <div class="transaction-item view_request_buttons_view view_request_buttons_view_dotteds01"  v-if="(data.l1_status == 0 && data.assignedto == 1)  || (data.l2_status == 0 && data.assignedto == 2)">
                                                                                
                                                                             <div class="dropdown chart-dropdown">
                                                                             
                                                                                <b-icon icon="three-dots-vertical" class="cursor" font-scale="1.6" data-toggle="dropdown"></b-icon>
                                                                                
                                                                                <div class="dropdown-menu dropdown-menu-right view_request_buttons_view_dotteds">
                                                                                  
                                                                                    <a  class="dropdown-item" @click="changeStatus(data,5,'approve')">
                                                                                         <span class="checkCircleIcon_approved" style="top: 1px;">  
                                                                                           <b-icon icon="check2" class="cursor" font-scale="1.3"></b-icon>
                                                                                         </span>Approve
                                                                                    </a>
                                                                                    <a  class="dropdown-item"  @click="changeStatus(data,3,'feedback')"> 
                                                                                        <span class="checkCircleIcon_approved">  
                                                                                           <b-icon icon="chat-left-text" class="cursor" font-scale="1.1"></b-icon>
                                                                                         </span>Feedback
                                                                                    </a>
                                                                                    <a  class="dropdown-item"  @click="changeStatus(data,4,'reject')">
                                                                                       <span class="checkCircleIcon_approved">  
                                                                                           <b-icon icon="x-lg" class="cursor" font-scale="0.8"></b-icon>
                                                                                         </span>Reject
                                                                                    </a>
                                                                            </div>
                                                                             </div>
                                                                            </div>
                                                                            </span>
                                                                            <span v-else>
                                                                            <div class="transaction-item view_request_buttons_view view_request_buttons_view_dotteds01">
                                                                            <div class="dropdown chart-dropdown">
                                                                             
                                                                                <b-icon icon="three-dots-vertical" class="cursor" font-scale="1.6" data-toggle="dropdown"></b-icon>
                                                                                
                                                                                <div class="dropdown-menu dropdown-menu-right view_request_buttons_view_dotteds">
                                                                                    <a  class="dropdown-item" @click="cancellationStatus(data,2,'approved')">
                                                                                         <span class="checkCircleIcon_approved" style="top: 1px;">  
                                                                                           <b-icon icon="check2" class="cursor" font-scale="1.3"></b-icon>
                                                                                         </span>Cancellation Accept
                                                                                    </a>
                                                                                    <a  class="dropdown-item"  @click="cancellationStatus(data,0,'rejected')">
                                                                                       <span class="checkCircleIcon_approved">  
                                                                                           <b-icon icon="x-lg" class="cursor" font-scale="0.8"></b-icon>
                                                                                         </span>Cancellation Reject
                                                                                    </a>
                                                                            </div>
                                                                            </div>
                                                                        </div> 
                                                                        </span>    
                                                                            
                                                                        </span>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody> 
                                                            </b-table-simple>
                                                        </div>
                                                        </div>
                                                    </b-card> 
                                                </template>
                                                </b-table>
                                                </div>
                                                <!-- User Interface controls -->
                                                <b-row class="pagination_view_con mt-2 m-0">
                                                    <b-col class="my-1 pull-right pagination_view_co">
                                                        <b-pagination
                                                        v-model="currentPage"
                                                        :total-rows="totalRows"
                                                        :per-page="perPage"
                                                        align="fill"
                                                        size="sm"
                                                        class="my-0"
                                                        ></b-pagination>
                                                    </b-col>
                                                </b-row>
                                            </b-container>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <div class="modal fade" id="grouptravelist" tabindex="2" role="dialog" aria-labelledby="grouptravelTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg_background_popups_top">
                        <h5 class="modal-title" id="EmploadDetails">Group Employee Travel List</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body bg_background_popups">
                        <div class="row match-height">
                            <div class="table-responsive">
                                <b-table striped hover :items="groputravelinfo"></b-table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import moment from 'moment'
    import { PlusIcon,PocketIcon,CodepenIcon,AtSignIcon,MailIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,CheckSquareIcon,
    MessageCircleIcon,XSquareIcon,
    SearchIcon,PlusCircleIcon,ClipboardIcon,UserIcon,MapPinIcon,InfoIcon,MoreVerticalIcon,EditIcon,EyeIcon,MinusCircleIcon } from 'vue-feather-icons'
    // Import component
    import Loading from 'vue-loading-overlay';
     // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Pagination from "vue-pagination-2";
    import apiUrl from "../../../constants";
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import { BTable,BTableLite,BTbody,BThead,BTr,BTd,BTh } from 'bootstrap-vue'
    export default({
        name:'TravelRequest',
        
        data() {
            return {
                tokendata:null,
                ticketdetails:[],
                isLoading: false,
                fullPage: true,
                un:'',
                username:'',
                empid:'',
                l1:'',
                l2:'',
                search:{
                    requestid:'',
                    status:null
                },
                request:{
                    empid:'',
                    empname:'',
                    designation:'',
                    dept:'',
                    deptid:'',
                    designationid:'',
                },
                ticketreq: {
                    empid:'',
                    empname:'',
                    designation:'',
                    dept:''
                },
                errors: {
                     requestid:null,
                    status:null
                },
                rowindex:0,
                emptypeBlured:false,
                pagefeatures:[],
                requestList:[],
                isDetActive:true,
                isRequestActive:false,
                isSearchRequestActive:false,
                card_content:'card-content',
                collapse:'collapse',
                isActiveEndDate:false,
                showmanager:true,
                transportList:[],
                managerid:'',
                visitname:'',
                visitlocation:'',
                purpose:'',
                startdate:'',
                enddate:'',
                comments:'',
                ticketstatus:[],
                loguser:'',
                l1mail:'',
                l2mail:'',
                totalcount: 0,
                pageCount: 0,
                page: 1,
                pagelimit: apiUrl.LIMIT,
                currPageNum: 0,
                pageoffset: 1,
                hodmastercountClick:false,
                showtabledata: true,
                statuslist:[{
                    id:'0',
                    label:'Pending'
                    },{
                        id:'2',
                        label:'Approved'
                    },{
                        id:'3',
                        label:'Feedback'
                    },{
                        id:'4',
                        label:'Rejected'
                    },{
                        id:'6',
                        label:'Closed'
                    },
                    {
                        id:'7',
                        label:'Revoked'
                    }
                ],
                items:[],
                fields: [                
              { key: 'ticketid', label: 'Request ID', thClass: 'travelName_ht_textleft', tdClass: 'travelName_td130' },
              { key: 'requestorname', label: 'Requestor Name'},
              { key: 'visitname', label: 'Visit Name', thClass: 'travelName_ht_textleft', tdClass: 'travelName_td130'},
              { key: 'visitlocation', label: 'Locations Visit', thClass: 'travelName_ht_textleft', tdClass: 'travelName_td130'},
              { key: 'purpose', label: 'Purpose', thClass: 'travelName_ht_textleft', tdClass: 'travelName_td130'},
              { key: 'startdate', label: 'Start Date'},
              { key: 'enddate', label: 'End Date'},
              { key: 'createddate', label: 'Request Date' },
              {
                key: 'status',
                label: 'status',
                formatter: (value, key, item) => {
                    if(value == 0) {
                        return 'Pending'
                    } else if(value == 1) {
                        return 'Draft'
                    } else if(value == 2) {
                        return 'Approved'
                    } else if(value == 3) {
                        return 'Feedback'
                    } else if(value == 4) {
                        return 'Rejected'
                    } else if(value == 5) {
                        return 'File Uploaded'
                    } else if(value == 6) {
                        return 'Closed'
                    }  else if(value == 7) {
                        return 'Revoked'
                    } else if(value == 8) {
                        return 'Cancellation'
                    }
                },
                sortable: true,
                sortByFormatted: true,
                filterByFormatted: true
              },
              { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 21,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            currentItems:[],
            groputravelinfo:[]
            }  
        } ,
        components: {
            PocketIcon,CodepenIcon,AtSignIcon,MailIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,
            EditIcon,CheckSquareIcon,MessageCircleIcon,XSquareIcon,
            EyeIcon,
            MoreVerticalIcon,
            Loading,
            PlusIcon,
            SearchIcon,
            PlusCircleIcon,
            ClipboardIcon,
            UserIcon,
            MapPinIcon,
            InfoIcon,
            Pagination,
            Treeselect,
            MinusCircleIcon,
          BTable,BTableLite,BTbody,BThead,BTr,BTd,BTh      
        },
        created() {
            this.pagefeatures = {}
            var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
            if (element.page === '/travelrequest') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
            })
        },
        computed: {
          sortOptions() {
            // Create an options list from our fields
            return this.fields
              .filter(f => f.sortable)
              .map(f => {
                return { text: f.label, value: f.key }
              })
          }
        },
        mounted(){
            let userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.tokendata = window.localStorage.getItem("token");
                this.username = userdata.username
    
                this.empid = userdata.userid
                this.loguser = userdata.firstname+' '+userdata.lastname
                this.getprofiledata()
            }
            if (feather) {
                feather.replace({
                    width: 14,
                    height: 14
                });
            }
            this.getmasterlist(0,'');
            $(".filter_rotation_onlick").click(function(){
            $(this).toggleClass("common_arrow_rotetion");
            });
        },
        
        methods:{
            formatData(data) {
                console.log("data",data,data.length);
                return data
            },
            idclick(indexx){
                if ($("#cycle_readmore"+indexx).hasClass("readmore")) {
                    $("#"+indexx).html("Read More");
                    $("#cycle_readmore"+indexx).removeClass("readmore");
                } else {
                    $("#"+indexx).html("Read Less");
                    $("#cycle_readmore"+indexx).addClass("readmore");
                }
            },
            getGroupTravelList(groupemp) {
                this.apiURL="api/listing/getemployeedetails"
                this.input={
                    empid: groupemp,
                    empcode: this.empid,
                    useremail: this.username
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {
                    this.groputravelinfo=[]        
                    if(result.data.status){
                        this.groputravelinfo = result.data.data;
                        $("#grouptravelist").modal('show')
                        
                    }else{
                        this.groputravelinfo=[]
                        Swal.fire({
                            title: "Info!",
                            text: "Data Not Found",
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })
            },
            toggleDetails(row) {
                if(row._showDetails){
                    this.$set(row, '_showDetails', false)
                }else{
                    this.currentItems.forEach(item => {
                    this.$set(item, '_showDetails', false)
                    })
    
                    this.$nextTick(() => {
                    this.$set(row, '_showDetails', true)
                    })
                }
            },
            onRowSelected(item,index) {   
                this.rowindex = index
                this.getTicketDetails(item.ticketid)
            },
            onFiltered(filteredItems) {
                console.log("filteredItems==>",filteredItems);
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            clickCallback: function(pageNum) {
                this.pageoffset=pageNum
                this.currPageNum = pageNum - 1
                this.getmasterlist()
            },
          resetRecords: function () {
                this.search.requestid = null;
                this.search.status = null;
                localStorage.removeItem('searchItems')
                this.getmasterlist(0,'');
            },
          changeStatus(data,changeto,operation){
                $(document).off('focusin.modal');
                let assignedto=1
                let remark =''
                if(changeto==5){
                    changeto=2
                    if(data.assignedto==1){
                        assignedto =3 //assigned to l2 manager
                        remark ='Line 1 manager has approved the request'
                    }else if (data.assignedto==2){
                         //if l1 approved then final approved
                        assignedto=3 // assigned to admin desk
                        remark ='Line 2 manager has approved the request'
                    }               
                }else if (changeto==3){
                    assignedto = data.assignedto
                    if(data.assignedto==1)
                        remark ='Line 1 manager has given the feedback '
                    if(data.assignedto==2)
                        remark ='Line 2 manager has given the feedback '
                       
                }else if (changeto==4){
                    assignedto = data.assignedto
                    if(data.assignedto==1)
                        remark ='Ticket has been rejected by L1 manager '
                    if(data.assignedto==2)
                        remark ='Ticket has been rejected by L2 manager'
                }        
                this.input={
                    createdname:this.loguser,
                    nextl1mail: this.l1mail?this.l1mail:'',
                    nextl1name: this.l1?this.l1.toLowerCase():'',
                    operation:operation+'ed',
                    assignedto :assignedto,
                    managerid : changeto ==2 ? this.ticketreq.l2manager:this.empid,
                    ticketid : data.ticketid,
                    requestid : data.id,
                    createdby : this.username,
                    remark : remark,
                    empcode:this.empid,
                    useremail: this.username,
                    nextlevel: this.ticketdetails.length == 1 && [2,4].includes(changeto) ? 'yes' : 'no'
                }
                var _self = this;
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to "+operation+" this travel request?",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText:'No, go back',
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, proceed!",
                    }).then((result) => {
                    if (result.isConfirmed) {
                        new Promise(function (resolve, reject) {
                            let payload = {
                                assignedto:data.assignedto,
                                ticketid: data.ticketid,
                                empcode: _self.empid,
                                useremail: _self.username
                            }
                            if(_self.ticketdetails.length == 1 && [4,3,2].includes(changeto)) {
                                axios({
                                    'method': 'POST',
                                    'url': "api/reimbursement/getTravelTicketStatus",
                                    'data':payload,
                                    headers: { authorization: _self.tokendata },
                                }).then((result) => {
                                    console.log("result------->",result);
                                    if(result.data.status && (result.data.data.includes(3)) || changeto ==3) {
                                        _self.input.assignedto = data.assignedto
                                        _self.input.managerid = _self.empid
                                        _self.input.nextlevel = 'no'
                                        resolve(true)
                                    } else if(result.data.status && (result.data.data.includes(2)) || [2,4].includes(changeto)){
                                        _self.input.assignedto = data.assignedto==1?data.assignedto+2 :data.assignedto+1
                                        _self.input.managerid = _self.ticketreq.l2manager
                                        _self.input.nextlevel = 'yes'
                                        _self.input.atleast1approv = result.data.data.includes(2) ? true:changeto==2?true:false;
                                        resolve(true)
                                    }  
                                })
                                .catch((error) => {
                                    console.log("catch getTravelTicketStatus", error);
                                    reject(error);
                                });
                            } else {
                                resolve(true)
                            }
                        }).then(async function (oRetObject) {
                            const { value: text } = await Swal.fire({
                                input: 'textarea',
                                inputLabel: 'Comment',
                                inputPlaceholder: 'Please comment here...',
                                inputAttributes: {
                                    'aria-label': 'Please comment here'
                                },
                                showCancelButton: true,
                                customClass: {
                                    validationMessage: 'my-validation-message'
                                },
                                preConfirm: (value) => {
                                    if (!value) {
                                    Swal.showValidationMessage(
                                        '<em class="fa fa-info-circle"></em> Comment is required'
                                    )
                                    }
                                }
                            })
                            if (text) {
                                if(data.assignedto==1) {
                                    _self.input.l1_status = changeto
                                    _self.input.l1_feedback = text
                                } else if(data.assignedto==2) {
                                    _self.input.l2_status = changeto
                                    _self.input.l2_feedback = text
                                }
                                _self.updateStatus(_self.input)
                            }
                        }).catch(function (err) {
                            Swal.fire({
                                title: "Failed!",
                                text: err,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        });
                    } 
                });
            },
            cancellationStatus(data,status,operation){
                $(document).off('focusin.modal');
                let remark ='Line 1 manager has cancellation '+operation+' the request'
                this.input={
                    ticketid : data.ticketid,
                    id : data.id,
                    cancellation:status,
                    remark : remark,
                    status:data.status,
                    empcode:this.empid,
                    useremail: this.username,
                    totalrows: this.ticketdetails.length
                }
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to cancellation "+operation+" this request?",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText:'No, go back',
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, proceed!",
                    }).then((result) => {
                    if (result.isConfirmed) {
                       this.isLoading = true;
                        let apiURL='api/reimbursement/cancellationStatus' 
                        axios({
                            'method': 'POST',
                            'url': apiURL,
                            'data':this.input,
                            headers: { authorization: this.tokendata },
                        })
                        .then(result => {   
                            this.isLoading = false;          
                            if(result.data.status){
                                this.getTicketDetails(data.ticketid)
                                this.getmasterlist(0,'')
                                Swal.fire({
                                    title: "Success!",
                                    text: "The cancellation has been "+operation+" successfully",
                                    icon: 'success',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                            }else{
                                this.isLoading= false;
                                Swal.fire({
                                    title: "Failed!",
                                    text: result.data.msg,
                                    icon: 'info',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                            }
                        }) .catch(error => {
                            this.isLoading = false;
                            Swal.fire({
                                title: "Failed!",
                                text: error,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        })
                    } 
                });
            },
            updateStatus(payload) {
                this.apiURL="api/reimbursement/changeticketStatus"
                 axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':payload,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {             
                    if(result.data.status){
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.getTicketDetails(payload.ticketid)
                        this.getmasterlist(0,'')
                        this.$forceUpdate()
                    }else{
                        Swal.fire({
                            title: "Failed!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }                
                })
            },
            getTravelTicketStatus(payload) {
                return axios({
                    'method': 'POST',
                    'url': "api/reimbursement/getTravelTicketStatus",
                    'data':payload,
                    headers: { authorization: this.tokendata },
                })
            },
            dateFormat(date) {
                if (!date) return '';
                return moment(date).format(this.TimeFormat);
            },
            onCancel() {
                console.log('User cancelled the loader.')
            },
            testcall(state,emplvalue){
            },
            getprofiledata(){
                var userdata = window.localStorage.getItem('userdata');
                if(userdata){
                    userdata = JSON.parse(userdata)
                    this.empid = userdata.userid
                    this.apiURL="api/profile/getProfileDetails"
                    this.input={
                        empcode:this.empid,
                        useremail: this.username
                    }
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':this.input,
                        headers: { authorization: this.tokendata },
                    })
                    .then(result => {             
                        if(result.data.status){
                            let response = result.data.data;
                            if(response.profilepic){
                                this.profileurl=response.profilepic
                            }
                            this.joiningdate=response.joiningdate
                            this.employeeid = response.empid
                            this.birthday = response.birthday
                            this.reportingto = response.reportto
                            this.year = response.year?(response.year+' yr ') : ''
                            this.month = response.month?response.month+' mos':''
                            this.totalexp =this.year+ ''+this.month
    
                            this.dept = response.designation+' ['+response.department+']'
                            this.email = response.emailid
                            this.address = response.city+','+response.state+','+response.country
                            this.mobilenumber = response.mobileno
                            this.firstname=response.firstname
                            this.lastname=response.lastname
                            this.request.deptid = response.deptid
                            this.request.designationid = response.designationid
                            this.request.managerid = response.managerid
                            this.l1 = response.l1
                            this.l2 = response.l2
                            this.request.empid=response.empid
                            this.request.empname = (this.firstname +' '+ this.lastname).toUpperCase()
                            this.request.designation = (response.designation).toUpperCase()
                            this.request.dept = (response.department).toUpperCase()
                            this.l1mail = response.l1mail
                            this.l2mail = response.l2mail
                            this.request.l2managerid = response.l2managerid
                        }
                    })
                }
            },
            onChange : function(ticketid) {
                if (ticketid == '') {
                    return;
                }
                var specialCharRegex = /[~@|$&^<>\*+=;?`'())[\]]/;
                if(specialCharRegex.test(ticketid)){
                    alert('special characters are not allowed');
                    g_form.setValue('app_long_name','');
                }
            },
            getTicketDetails(ticketid){
                var userdata = window.localStorage.getItem('userdata');
                this.ticketdetails =[]
                if(userdata){
                    this.apiURL="api/reimbursement/getTicketDetails"
                    this.input={
                        ticketid:ticketid,
                        managerid:this.empid,
                        isreject: 'yes',
                        empcode: this.empid,
                        useremail: this.username
                    }
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':this.input,
                        headers: { authorization: this.tokendata },
                    })
                    .then(result => {     
                            
                        if(result.data.status){
                            let response = result.data.data;
                            if(response.length>0) {
                                this.ticketdetails = response
                                this.visitname=response[0].visitname
                                this.visitlocation=response[0].visitlocation
                                this.purpose=response[0].purpose
                                this.startdate=response[0].startdate
                                this.enddate=response[0].enddate
                                this.ticketreq.empid = response[0].empid
                                this.ticketreq.empname =response[0].employname
                                this.ticketreq.designation =response[0].designation
                                this.ticketreq.dept = response[0].department
                                this.ticketreq.l2manager = response[0].l2managerid
                            } else {
                                this.ticketdetails =[]
                                this.visitname=''
                                this.visitlocation=''
                                this.purpose=''
                                this.startdate=''
                                this.enddate=''
                                this.ticketreq.empid = ''
                                this.ticketreq.empname =''
                                this.ticketreq.designation =''
                                this.ticketreq.dept = ''
                            }
                            
                        }
                    })
                }
            },
            displaymgr(){
                this.showmanager = !this.showmanager
            },
            
            mydetailsollapse:function(){
                this.isDetActive=!this.isDetActive
            },
            mysearchrequestollapse:function(){
                this.isSearchRequestActive=!this.isSearchRequestActive
            },
            getmasterlist(statusVal,ticketid){
                this.requestList = []
                this.isLoading = true;
                let apiURL='api/reimbursement/getapprovalrequest' 
                this.input={
                    offset:this.pageoffset,
                    limit: apiUrl.LIMIT,
                    ticketid:ticketid,
                    empcode:this.empid,
                    status:statusVal,
                    useremail: this.username
                }               
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {   
                    this.isLoading = false;          
                    if(result.data.status){
                        this.isDetActive=true;
                      this.items = result.data.data.responsedata;                  
                        this.totalRows = result.data.data.count
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }else{
                        this.items = [];
                        this.isLoading= false;
                        this.totalRows = 1;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }
                })
    
            }
        }
    })
    </script>
    <style scoped>
    .display{
        display: none;
    }
    .readmore_texttrs .outer span{
      overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .readmore_texttrs .outer.readmore span{
          overflow: unset;
        display: -webkit-box;
        -webkit-line-clamp: unset;
        line-clamp: 2;
        -webkit-box-orient: unset;
    }
    .cycle_viewmoretext a{
      text-align: left;
    }
    .cycle_viewmoretext a strong{
            color: blue;
    }
    .table_responce44 .table.b-table .transaction-item.view_request_buttons_view .btn{
        padding: 0.786rem 0.6rem;
        font-size: 13px;
        margin: 5px 0px;
        min-width: auto;
    }
    </style>