<template>
    <div>
        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :is-full-page="fullPage"
          color="orange"
          loader="dots"
          :width="64"
          :height="64"
        ></loading>
        <div class="app-content content devcontent ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper">
                <div class="content-header row">
                    <div class="content-header-left col-md-9 col-12 mb-1">
                        <div class="row breadcrumbs-top">
                            <div class="col-12 mobile_view_padd-05">
                                <h3 class="content-header-title float-left mb-0">Admin Panel</h3>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item">Home</li>
                                        <li class="breadcrumb-item"><router-link to='/trs/mydashboard'>My Dashboard</router-link></li>
                                        <li class="breadcrumb-item"><router-link to='/trs/adminpanalviewtickets'>Admin Panel</router-link></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-body">
                   <div class="row">
                        <div class="col-12 mobile_view_padd-05">
                            <div class="card card_box_shadow">
                                <div class="card-header filter_rotation_onlick" v-on:click="mysearchrequestollapse" style="cursor:pointer;">
                                    <div class="container-fluid" >
                                        <div class="row fc_my-05" >
                                            <div class="w-auto px-1 mobile-px-0" >
                                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                <div class="avatar-content">
                                                    <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                                </div>
                                                </div>
                                                <h4 class="card-title d-inline-flex ml-1">Search Admin Request</h4>
                                            </div>
                                            
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body card_padding_10">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4 col-xs-12 mb-1">
                                                <label class="form-label">Request ID	 </label>
                                                <input class="form-control" type="text" placeholder="Enter Request ID" v-model="search.requestid"/>
                                            </div>
                                            <div class="col-md-4 col-sm-4 col-xs-12 mb-1">
                                                <label class="form-label">Status</label>
                                                <treeselect placeholder="Select Ticket Status" v-model="search.status"  :options="statuslist" />
                                            </div>
                                            <div class="col-sm-4 col-xs-12 common_trs_buttons_res">
                                                <button type="submit" class="btn btn-primary mt-2 mr-1" @click="getmasterlist(search)" >Search</button>
                                                <button type="reset" class="btn btn-outline-secondary mt-2" v-on:click="mysearchrequestollapse" @click="resetRecords()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card card_box_shadow">
                                <div class="card-header filter_rotation_onlick" style="cursor:pointer;" v-on:click="mydetailsollapse">
                                    <div class="container-fluid" >
                                        <div class="row fc_my-05" >
                                            <div class="w-auto px-1 mobile-px-0">
                                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                        <div class="avatar-content">
                                                            <clipboard-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></clipboard-icon>
                                                        </div>
                                                    </div>
                                                    <h4 class="card-title d-inline-flex ml-1">Admin Request Details</h4>
                                               
                                            </div>
                                            
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isDetActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body px-0 full_main_containers_mobile pt-0">
                                        <div class="row">
                                            <b-container fluid class="table-responsive_view">
                                                <!-- Main table element -->
                                                <div class="full_main_containers table_width_col">
                                                <b-table class="table table-sm dataTableListing table-striped theade_padding_y10 "
                                                :items="items"
                                                :fields="fields"
                                                :current-page="currentPage"
                                                :per-page="perPage"
                                                :filter="filter"
                                                :filter-included-fields="filterOn"
                                                :sort-by.sync="sortBy"
                                                :sort-desc.sync="sortDesc"
                                                :sort-direction="sortDirection"
                                                stacked="md  new_admin_panel_ticket"
                                                show-empty
                                                small
                                                @filtered="onFiltered"
                                                v-model="currentItems"
                                                >
                                                <template v-slot:cell(actions)="{ detailsShowing, item }">
                                                    <div class="view_request_buttons_view_dotteds01">
                                                     <div class="dropdown chart-dropdown">
                                                    <b-icon icon="three-dots-vertical" class="cursor" font-scale="1.6" data-toggle="dropdown"></b-icon>
                                                    <div class="dropdown-menu dropdown-menu-right view_request_buttons_view_dotteds">
                                                      <a size="sm" class="dropdown-item" v-if="item.adminclaim == 0 || item.adminclaim == null" @click="claimedReq(item)"> <span class="checkCircleIcon_approved" >  
                                                                                           <b-icon icon="person-plus" class="cursor" font-scale="1.2"></b-icon>
                                                                                         </span>Allocate Me</a> 
                                                      <a size="sm" class="dropdown-item" @click="toggleDetails(item);onRowSelected(item);"> <span class="checkCircleIcon_approved"> <b-icon icon="card-checklist" class="cursor" font-scale="1.2"></b-icon> </span>{{ detailsShowing ? 'Hide' : 'Show'}} Details</a>
                                                    </div>
                                                </div>
                                                </div>
                                                </template>
                                                
                                                <template #row-details="row" class="w-100">
                                                    <b-card class="p-0 card_travels_mobile_veiw">
                                                        <div class="table-responsive" style="max-width: 100vh; width: 100%; min-width: 100%;">
                                                        <div id="app" class="table_responce44">
                                                             <b-row  class="mx-0 inner_table_inlie_view mb-2">
                                                                <b-col class="inner_icons_table_request_table_head">
                                                                    <b-icon icon="person-fill" scale="2" class="inner_icons_table_request_table" variant="danger"></b-icon>
                                                                    <span class="inner-text-left-02"> {{ticketcreatereq.empname}} </span>
                                                                </b-col>
                                                                <b-col class="inner_icons_table_request_table_head">
                                                                    <b-icon icon="envelope" scale="2" class="inner_icons_table_request_table" variant="warning"></b-icon>
                                                                    <span class="inner-text-left-02"> {{ticketcreatereq.designation}} </span>
                                                                </b-col>
                                                                <b-col class="inner_icons_table_request_table_head">
                                                                    <b-icon icon="briefcase" scale="2" class="inner_icons_table_request_table"  variant="info"></b-icon>
                                                                    <span class="inner-text-left-02"> {{ticketcreatereq.empid}} </span>
                                                                </b-col>
                                                                <b-col class="inner_icons_table_request_table_head">
                                                                    <b-icon icon="grid-fill" scale="2" class="inner_icons_table_request_table" variant="success"></b-icon>
                                                                    <span class="inner-text-left-02"> {{ticketcreatereq.dept}} </span>
                                                                </b-col>
                                                            </b-row>
                                                            <b-table-simple responsive>
                                                            <b-thead>
                                                                <b-tr>
                                                                    <b-th class="py-1">Index</b-th>
                                                                    <b-th class="text-left" style="min-width: 250px;">City Details</b-th>
                                                                    <!-- <b-th>City node</b-th>
                                                                    <b-th style="min-width:180px;">From City - To city</b-th> -->
                                                                    <b-th>Group Travel List</b-th>
                                                                    <b-th>Mode of Transport</b-th>
                                                                    <b-th>Special Instructions</b-th>
                                                                    <b-th class="text-left" style="min-width:270px!important;">Date Details</b-th>
                                                                    
                                                                    <b-th>Ticket Docs</b-th>
                                                                    <b-th>Action</b-th>
                                                                </b-tr>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr v-for="(data,index) in ticketdetails" :key="index">
                                                                    <b-td>{{index+1}}</b-td>
                                                                    <b-td class="text-left details_column_table">
                                                                        <span class="heading_column_text"> City node : </span>
                                                                        <span v-if="data.returnbooking ==2">Return Journey Details</span>
                                                                        <span v-else-if="data.returnbooking ==1">City node - {{data.node}}</span> 
                                                                        <br/>
                                                                        <span class="heading_column_text"> From City - To City : </span>
                                                                        <span v-if="data.fromcityname != null && data.cityname != null">{{data.fromcityname}} To {{data.cityname}}</span>
                                                                        <span v-else>No Travel</span>
    
                                                                    </b-td>
                                                                    
                                                                    <b-td>
                                                                        <span v-if="data.isgrouptravelreq == 1 && data.nameofgrouptravel !=null">                                                    
                                                                            <eye-icon size="1.5x" class="custom-class" @click="getGroupTravelList(data.nameofgrouptravel)" style="color:blue;cursor:pointer"></eye-icon>
                                                                        </span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <span v-if="data.transportmode != null">{{data.transportmode}}</span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td class="text-left">
                                                                        <!-- <span v-if="data.instruction != null">{{data.instruction}}</span>
                                                                        <span v-else>N/A</span> -->
                                                                        <!--  -->
                                                                        <span class="readmore_texttrs" v-if="data.instruction != null">                                                                 
                                                                            <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                                                <div class="custom-container case_bg_17">
                                                                                    <div class="outer" :id="'cycle_readmore'+'instruction'+index">
                                                                                        <span class="fc_m16_btcode_text" v-html="formatData(data.instruction)"></span>
                                                                                    </div>
                                                                                    <div class="cycle_viewmoretext" v-if="data.instruction.length > 10">
                                                                                        <a class="btn">
                                                                                        <strong :id="'instruction'+index" @click="idclick('instruction'+index)">
                                                                                            Read More
                                                                                        </strong>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                        <span v-else>N/A</span>
                                                                        <!--  -->
                                                                    </b-td>
                                                                    <b-td class="text-left details_column_table">
                                                                        <span class="heading_column_text"> Date Of Travel : </span>
                                                                        <span v-if="data.traveldate">{{data.traveldate}}</span>
                                                                        <span v-else>N/A</span>
                                                                        <br/>
                                                                        <span class="heading_column_text"> Appointment Date : </span>
                                                                        <span v-if="data.appointmentdate">{{data.appointmentdate}}</span>
                                                                        <span v-else>N/A</span>
                                                                        <br/>
                                                                        <span class="heading_column_text"> CheckIn Date - CheckOut Date : </span>
                                                                        <span v-if="data.checkindate && data.checkoutdate">{{data.checkindate}} To {{data.checkoutdate}}</span>
                                                                        <span v-else-if="data.returnbooking ==2">N/A</span>
                                                                        <span v-else>No Accomodation</span>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <div class="vertical-modal-ex">
                                                                        <eye-icon size="1.5x" class="custom-class" data-toggle="modal" data-target="#ticketDoc" style="color:blue;cursor:pointer"></eye-icon>
                                                <!-- Modal -->
                                                <div class="modal fade" id="ticketDoc" tabindex="-1" role="dialog" aria-labelledby="ticketDocTitle" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="ticketDocTitle">TICKET DOCS</h5>
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class=" mb-0" v-if="data.ticketdocs.length>0">
                                                                            <div class="card-body">
                                                                                
                                                                                <div class="row">
                                                                                    <div class="table-responsive">
                                                                                        <table aria-describedby="mydesc" class="table table-sm dataTableListing table-striped theade_padding_y10 inner_table-collapseview">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th scope="col">SR#</th>
                                                                                                    <th scope="col">Filename</th>
                                                                                                    <th scope="col">Description</th>
                                                                                                    <th scope="col">Action</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr v-for="(doc,i) in data.ticketdocs" :key="i" >
                                                                                                    <td> {{i+1}}</td>
                                                                                                    <td>{{doc.filename}}</td>
                                                                                                    <td>{{doc.description}}</td>
                                                                                                    <td style="text-align: center!important;">
                                                                                                        <span v-if="doc.path!=''">
                                                                                                            <div class="avatar bg-info rounded m-1" style="margin:2px!important;">
                                                                                                                <div class="avatar-content">
                                                                                                                    <a @click="getDownloadedLink(doc)" target="_blank">
                                                                                                                        <download-icon size="1.5x" class="custom-class font-medium-3 sitebluecolor text-white">
                                                                                                                        </download-icon>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>                                                                                                
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <span v-else>  No data</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </b-td>
                                                                    <b-td>          
                                                                        <button type="button" v-if="data.revokestatus == 1 && ![1,2].includes(data.cancellation)" class="btn btn-sm vew_request_hovertext" title="Revoked Request" @click="RevokeReqConfirmation(data)">
                                                                            <alert-circle-icon size="1.5x" class="custom-class"></alert-circle-icon>
                                                                        </button>
                                                                        <button type="button" v-if="data.l3_status != 6 && ![1,2].includes(data.cancellation) && ![1,3].includes(data.revokestatus) && row.item.adminclaim == empid" class="btn btn-sm vew_request_hovertext" title="Ticket Details Upload" data-toggle="modal" data-target="#uploadTicketDetails" @click="uploadTravelTicket(data)">
                                                                            <upload-icon size="1.5x"  class="custom-class "></upload-icon>
                                                                        </button>                                                            
                                                                        <button v-if="data.ticketdocs.length>0 && data.l3_status != 6 && ![1,2].includes(data.cancellation) && ![1,3].includes(data.revokestatus) && row.item.adminclaim == empid" type="button" title="Closed Ticket" class="btn btn-sm vew_request_hovertext" @click="closeTicket(data)">
                                                                            <power-icon size="1.5x" class="custom-class"  ></power-icon>
                                                                        </button> 
                                                                    </b-td>                                                                
                                                                </b-tr>
                                                            </b-tbody> 
                                                            </b-table-simple>
                                                        </div>
                                                        </div>
                                                    </b-card> 
                                                </template>
                                                
                                                </b-table>
                                                </div>
                                                <!-- User Interface controls -->
                                                <b-row class="pagination_view_con mt-2 m-0 text-center">
                                                    <b-col class="my-1 pull-right pagination_view_co">
                                                        <b-pagination
                                                        v-model="currentPage"
                                                        :total-rows="totalRows"
                                                        :per-page="perPage"
                                                        align="fill"
                                                        size="sm"
                                                        class="my-0"
                                                        ></b-pagination>
                                                    </b-col>
                                                </b-row>
                                            </b-container>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <!-- Modal -->
    <div class="modal fade bd-example-modal-lg" id="uploadTicketDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Travel Tickets Upload</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body popup_height_modal_admin">
              <div class="panel-group">
        <div class="panel panel-primary">
            <div class="panel-body"> 
                <div class="avatar bg-success pull-right  rounded ml-1 mb-2 mt-1" style="float:right;">
                    <div class="avatar-content" @click="addRow(traveldocument)" style="float:right;">
                        <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                    </div>
                </div>
                <div class="table-responsive popup_table_Admin">
                <table aria-describedby="mydesc" class="table table-bordered">
                <thead class="text text-success">
                    <tr>                            
                        <th scope="col">File Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Attachement</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                       <tr v-for='(doc, index) in traveldocument' :key="index">                            
                        <td>
                        <input  v-model="doc.filename" placeholder="Filename"  class="form-control" type="text"/>
                        </td>
                        <td>
                            <textarea class="form-control"  rows="3" placeholder="Description" v-model="doc.description"></textarea>
                        </td>
                        <td>
                            <label for="attachment" class="btn btn-sm btn-primary">Browse</label>
                            <input  type="file" id="attachment" hidden accept="image/*"  ref="file" @change="onSelect($event) "/>
                        </td>
                        <td>
                            <div class="d-flex">
                            <div>
                                <button class="btn btn-sm btn-info ml-1 uploadbutton" :data-id="index" v-on:click.stop.prevent="onUpload($event,index)"> Upload</button>
                            </div>
                            
                            <div class="avatar bg-danger rounded ml-1">
                                <div class="avatar-content" @click="deleteRow(index, doc)">
                                    <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                </div>
                            </div>
                            </div>
                        </td>
                    </tr>                        
                    </tbody>
                </table>
                </div>
            </div> 
          </div>
        </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" v-on:click.stop.prevent="saveDocument">Save Changes</button>
          </div>
        </div>
      </div>
    </div>
     <div class="modal fade" id="grouptravelist" tabindex="2" role="dialog" aria-labelledby="grouptravelTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog modal-lg" role="document" >
                <div class="modal-content">
                    <div class="modal-header bg_background_popups_top">
                        <h5 class="modal-title" id="EmploadDetails">Group Employee Travel List</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body bg_background_popups">
                        <div class="row match-height">
                            <div class="table-responsive">
                                <b-table striped hover :items="groputravelinfo"></b-table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import { PlusIcon,AlertCircleIcon,PocketIcon,CodepenIcon,AtSignIcon,MailIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,CheckSquareIcon,
    MessageCircleIcon,XSquareIcon,
    SearchIcon,PlusCircleIcon,ClipboardIcon,UserIcon,MapPinIcon,InfoIcon,MoreVerticalIcon,EditIcon,EyeIcon,UploadIcon,PowerIcon,PlusSquareIcon,MinusSquareIcon,DownloadIcon } from 'vue-feather-icons'
    // Import component
    import Loading from 'vue-loading-overlay';
     // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Pagination from "vue-pagination-2";
    import apiUrl from "../../../constants";
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import { BTable,BTableLite,BTbody,BThead,BTr,BTd,BTh } from 'bootstrap-vue'
    export default({
        name:'TravelRequest',
        
        data() {
            return {
                tokendata: null,
                totalcount: 0,
                pageCount: 0,
                page: 1,
                pagelimit: apiUrl.LIMIT,
                currPageNum: 0,
                pageoffset: 1,
                hodmastercountClick:false,
                showtabledata: true,
                ticketdetails:[],
                isLoading: false,
                fullPage: true,
                search:{
                    requestid:'',
                    status:null,
                },
                statuslist:[
                    {
                        id:'2',
                        label:'Pending'
                    },
                    {
                        id:'7',
                        label:'Claimed'
                    }, 
                    {
                        id:'5',
                        label:'File Uploaded'
                    },
                    {
                        id:'6',
                        label:'Closed'
                    },
                ],
                traveldocument:[{filename:'',description:'',attachemnt:''}],
                emptypeBlured:false,
                pagefeatures:[],
                requestList:[],
                isDetActive:true,
                isRequestActive:false,
                isSearchRequestActive:false,
                card_content:'card-content',
                collapse:'collapse',
                isActiveEndDate:false,
                showmanager:true,
                transportList:[],
                managerid:'',
                visitname:'',
                visitlocation:'',
                purpose:'',
                startdate:'',
                enddate:'',
                file: [],
                doc: [],
                traveldocumentsupload: [],
                editdata:{},
                attachment:[],
                ticketreq: {
                    empid:'',
                    empname:'',
                    designation:'',
                    dept:''
                },
                ticketcreatereq: {
                    empid:'',
                    empname:'',
                    designation:'',
                    dept:''
                },
                loguser:'',
                items:[],
                fields: [
                    { key: 'ticketid', label: 'Request ID' },
                    { key: 'employname', label: 'Travel Name', thClass: 'travelName_ht130', tdClass: 'travelName_td130' },
                    { key: 'visitname', label: 'Visit Name', thClass: 'travelName_ht130', tdClass: 'travelName_td130'},
                    { key: 'purpose', label: 'Purpose', thClass: 'travelName_ht130', tdClass: 'travelName_td130'},
                    { key: 'startdate', label: 'Start Date'},
                    { key: 'enddate', label: 'End Date'},
                    { key: 'adminame', label: 'Claimed By',thClass: 'travelName_ht130', tdClass: 'travelName_td130'},
                    { key: 'actions', label: 'Actions', tdClass: 'requestpannelDetails', thClass: 'requestpannelDetails1'}
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 21,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                currentItems: [],
                groputravelinfo:[]
            }  
        } ,
        components: {
            PocketIcon,CodepenIcon,AtSignIcon,MailIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,
            EditIcon,CheckSquareIcon,MessageCircleIcon,XSquareIcon,
            EyeIcon,
            MoreVerticalIcon,
            Loading,
            PlusIcon,
            SearchIcon,
            PlusCircleIcon,
            ClipboardIcon,
            UserIcon,
            MapPinIcon,
            InfoIcon,
            UploadIcon,
            PowerIcon,
            PlusSquareIcon,
            MinusSquareIcon,
            DownloadIcon,
            Pagination,
            Treeselect,
            BTable,BTableLite,BTbody,BThead,BTr,BTd,BTh,
            AlertCircleIcon
        },
         computed: {
          sortOptions() {
            // Create an options list from our fields
            return this.fields
              .filter(f => f.sortable)
              .map(f => {
                return { text: f.label, value: f.key }
              })
          }
        },
        created() {
            this.pagefeatures = {}
            let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
            if (element.page === '/travelrequest') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
            })
        },
        mounted(){
            
            let userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.tokendata = window.localStorage.getItem("token");
                this.username = userdata.username
                this.empid = userdata.userid
                this.loguser = userdata.firstname+' '+userdata.lastname
                this.getprofiledata()
            }
            if (feather) {
                feather.replace({
                    width: 14,
                    height: 14
                });
            }
            this.getmasterlist(null,0)       
        },
        
        methods:{
            getDownloadedLink(data) {
                if(data) {
                    let payload = {
                        documents:[data],
                        empcode: this.empid,
                        useremail: this.username
                    };
                    this.apiURL="/api/reimbursement/getdownloadlink"
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':payload,
                        headers: { authorization: this.tokendata },
                    })
                    .then((result) => {
                        this.isLoading = false
                        if(result.data.status) {
                            if(result.data.data[0].presignurl){
                                window.open(result.data.data[0].presignurl, '_blank')     
                            }
                        }
                    });
                }
            },
    
            RevokeReqConfirmation(data) {
               const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success mt-1',
                    cancelButton: 'btn btn-danger mr-1 mt-1'
                },
                buttonsStyling: false
                })
    
                swalWithBootstrapButtons.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revoked this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, revoked it!',
                    cancelButtonText: 'No, revoked!',
                    reverseButtons: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                }).then((result) => {
                if (result.isConfirmed) {
                    this.revokedticketinfo(data.id,2,data.ticketid)
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    this.revokedticketinfo(data.id,0,data.ticketid)
                }
                })
            },
            async revokedticketinfo(id,status,ticketid) {
                const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Revoked log expenses',
                inputPlaceholder: 'Please revoked log expenses here...',
                inputAttributes: {
                    'aria-label': 'Please revoked log expenses here'
                },
                showCancelButton: true,
                customClass: {
                    validationMessage: 'my-validation-message'
                },
                preConfirm: (value) => {
                    if (!value) {
                    Swal.showValidationMessage(
                        '<em class="fa fa-info-circle"></em> Revoked log expenses is required'
                    )
                    }
                }
            })
            if (text) {
                this.apiURL="api/reimbursement/revoketravelticketbyadmin"
                this.input={
                    id: id,
                    revokestatus: status,
                    logexpenses: text,
                    useremail: this.username,
                    empcode: this.empid, 
                    totalrows: this.ticketdetails.length,
                    ticketid:ticketid
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {
                    if(result.data.status){
                        let flag = status ==2 ? 'accepted' :'not accepted';
                        Swal.fire({
                            title: "Success!",
                            text: "Revoked ticket has been "+flag+" sucessfully",
                            icon: "success",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        });
                        this.getmasterlist(null,0)                    
                    }else{
                        this.groputravelinfo=[]
                        Swal.fire({
                            title: "Info!",
                            text: result.data.msg,
                            icon: 'warning',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })
            }
            },
            claimedReq(data) {
                this.apiURL="api/reimbursement/adminClaimRequest"
                this.input={
                    useremail: this.username,
                    id:data.id,
                    adminclaim: this.empid,
                    empcode:this.empid,                
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {
                    this.groputravelinfo=[]        
                    if(result.data.status){
                        Swal.fire({
                            title: "Success!",
                            text: "Claimed Request allocated sucessfully",
                            icon: "success",
                            customClass: {
                            confirmButton: "btn btn-primary mt-1",
                            },
                            buttonsStyling: !1,
                        });
                        this.getmasterlist(null,0)                    
                    }else{
                        this.groputravelinfo=[]
                        Swal.fire({
                            title: "Info!",
                            text: result.data.msg,
                            icon: 'warning',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })
            },
            getGroupTravelList(groupemp) {
                this.apiURL="api/listing/getemployeedetails"
                this.input={
                    empid: groupemp,
                    empcode: this.empid,
                    useremail: this.username
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {
                    this.groputravelinfo=[]        
                    if(result.data.status){
                        this.groputravelinfo = result.data.data;
                        $("#grouptravelist").modal('show')
                        
                    }else{
                        this.groputravelinfo=[]
                        Swal.fire({
                            title: "Info!",
                            text: "Data Not Found",
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })
            },
            toggleDetails(row) {
                if(row._showDetails){
                    this.$set(row, '_showDetails', false)
                }else{
                    this.currentItems.forEach(item => {
                    this.$set(item, '_showDetails', false)
                    })
    
                    this.$nextTick(() => {
                    this.$set(row, '_showDetails', true)
                    })
                }
            },
             onRowSelected(item) {
                this.getTicketDetails(item.ticketid)
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            isDisabled: function(){
                return !this.terms;
            }, 
            resetRecords: function () {
                this.search.requestid = null;
                this.search.status = null;
                localStorage.removeItem('searchItems')
                this.getmasterlist(null,0);
                this.validateForm()
            },
            uploadTravelTicket(data) {
                this.traveldocument = [{filename:'',description:'',attachemnt:''}]
                this.editdata = {}
                this.editdata = data
            },
            addRow: function() {      
                this.traveldocument.push({filename:'',description:'',attachemnt:''})
            },
            deleteRow(index){    
                this.traveldocument.splice(index,1);  
                this.traveldocumentsupload.splice(index,1);           
            },
            onSelect(event){
                this.file= event.target.files;
            },
            onUpload(event,rowindex) {  
                if (this.file) {
                if (this.file.name !== "") {
                this.isLoading = true;
                this.apiURL = "api/reimbursement/uploadBulkFileToS3";
                let formData = new FormData();
                let maxsize = 0;
                // loop changes verify this condition
                for (const fval of this.file) {
                    formData.append("imagefile", fval);
                    maxsize+= fval.size
                }
    
                formData.append("moduletype", "traveltickets");
                formData.append("empcode", this.empid);
                formData.append("useremail",this.username);
                formData.append("fcount", parseInt(this.file.length));
                if (maxsize > 1024 * 1024 * 1) {
                    this.isLoading = false;
                    Swal.fire({
                        title: "Failed",
                        text: "Your document uploaded above 1mb not allow",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                    this.isLoading=false
                    return false; 
                }
                axios({
                    "content-type": "multipart/form-data",
                    method: "POST",
                    url: this.apiURL,
                    data: formData,
                    headers: { authorization: this.tokendata },
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.status) {
                    this.disblesavebtn = false;
                    this.file= [];
                    this.traveldocumentsupload[rowindex]= {filename:this.traveldocument[rowindex].filename,description:this.traveldocument[rowindex].description,path:result.data.data[0]}
                    
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: "success",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                    } else {
                    this.isLoading = false;
                        Swal.fire({
                            title: "Oops...!",
                            text: result.data.msg,
                            icon: 'error',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.attachment=null
                    }
                });
                } else {
                this.isLoading = false;
                Swal.fire({
                    title: "Oops...!",
                    text: "Select travel docs",
                    icon: "error",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
                this.attachment = null;
                }
            } else {
                this.isLoading = false;
                Swal.fire({
                title: "Oops...!",
                text: "Select travel docs",
                icon: "error",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                });
                this.attachment = null;
            }      
            },
            validateForm(mdl){
                let isValid=true;
                if(mdl=="doc"){
                    for(const index in this.traveldocument){
                        if(!this.validText(this.traveldocument[index].filename) || !this.validText(this.traveldocument[index].description)){
                            isValid=false
                        }
                    }                
                }
                return isValid
            },
            validText : function(inputval) {
                let re = /[A-Za-z0-9].{5,}/;
                if(inputval){
                    return re.test(inputval.toLowerCase());
                }
            },
            formatData(data) {
                console.log("data",data,data.length);
                return data
            },
            idclick(indexx){
                if ($("#cycle_readmore"+indexx).hasClass("readmore")) {
                    $("#"+indexx).html("Read More");
                    $("#cycle_readmore"+indexx).removeClass("readmore");
                } else {
                    $("#"+indexx).html("Read Less");
                    $("#cycle_readmore"+indexx).addClass("readmore");
                }
            },
            saveDocument(){
                this.isLoading = true;
                if(this.traveldocumentsupload.length>0){
                    
                    let url = "api/reimbursement/travelTicketUpload";
                    axios({
                        method: "POST",
                        url: url,
                        data: {id: this.editdata.id, document:JSON.stringify(this.traveldocumentsupload),ticketid:this.editdata.ticketid,createdby:this.username,empcode: this.empid,useremail: this.username},
                        headers: { authorization: this.tokendata },
                    }).then((result) => {
                        this.isLoading = false;
                        if (result.data.errorCode == 0) {  
                            this.getmasterlist(null,0)
                            this.traveldocumentsupload = []     
                            this.traveldocument = []                         
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                            $('#uploadTicketDetails').modal('hide');
                        } else {
                            Swal.fire({
                                title: "Failed",
                                text: result.data.msg,
                                icon: "info",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                        }
                    });
                }else{
                    Swal.fire({
                        title: "Failed",
                        text: "At least 1 Fields are compulsory",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                    this.isLoading = false;
                }
            },         
            closeTicket(data){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be close this travel ticket!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, closed it!'
                }).then(async(result) => {
                    let payload = {
                        assignedto:data.assignedto,
                        ticketid: data.ticketid,
                        isadmin:'yes',
                        empcode: this.empid,
                        useremail: this.username
                    }
                    let mclose =''
                    await this.getTravelTicketStatus(payload).then((result) => {
                         
                        if(result.data.status){
                            let tstatus = result.data.data;
                            if(tstatus.reduce((a, v) => (v === 5 ? a + 1 : a), 0) ==1 && result.data.data.length ==1) 
                                mclose = 'yes'                    
                        }  
                    })
                if (result.isConfirmed) {
                    axios({
                        method: "POST",
                        url: 'api/reimbursement/closedTravelTicket',
                        data: {id:data.id,closedby:this.username,masterclose:mclose,ticketid:data.ticketid,createdname:this.loguser,empcode:this.empid,useremail: this.username},
                        headers: { authorization: this.tokendata },
                    }).then((result) => {
                        this.isLoading = false;
                        if (result.data.status) {
                            this.getmasterlist(null,0)
                            Swal.fire(
                            'Ticket Closed!',
                            result.data.msg,
                            'success'
                            )
                        } else {
                           Swal.fire(
                            'Ticket Closed!',
                            result.data.msg,
                            'error'
                            )                        
                        }
                    });    
                }
            })
               
            },
            getTravelTicketStatus(payload) {
                return axios({
                    'method': 'POST',
                    'url': "api/reimbursement/getTravelTicketStatus",
                    'data':payload,
                    headers: { authorization: this.tokendata },
                })
            },
            getTicketDetails(ticketid){
                let userdata = window.localStorage.getItem('userdata');
                this.ticketdetails=[]
                this.ticketcreatereq={
                    empid:'',
                    empname:'',
                    designation:'',
                    dept:''
                }
                if(userdata){
                    this.apiURL="api/reimbursement/getAdminApproveTicketDetails"
                    this.input={
                        ticketid:ticketid,
                        empcode: this.empid,
                        useremail: this.username
                    }
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':this.input,
                        headers: { authorization: this.tokendata },
                    })
                    .then(result => {             
                        if(result.data.status){
                            let response = result.data.data;
                            this.ticketdetails = response
                            if(this.ticketdetails.length>0) {
                                this.ticketcreatereq.empid=response[0].empid
                                this.ticketcreatereq.empname = (response[0].employname).toUpperCase()
                                this.ticketcreatereq.designation = (response[0].designation).toUpperCase()
                                this.ticketcreatereq.dept = (response[0].department).toUpperCase()
                            }
                        }
                    })
                }
            },        
            getprofiledata() {
                let userdata = window.localStorage.getItem('userdata');
                if(userdata){
                    userdata = JSON.parse(userdata)
                    this.empid = userdata.userid
                    this.apiURL="api/profile/getProfileDetails"
                    this.input={
                        empcode:this.empid,
                        useremail: this.email,
                    }
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':this.input,
                        headers: { authorization: this.tokendata },
                    })
                    .then(result => {           
                        if(result.data.status){
                            let response = result.data.data;
                            if(response.profilepic){
                                this.profileurl=response.profilepic
                            }
                            this.joiningdate=response.joiningdate
                            this.employeeid = response.empid
                            this.birthday = response.birthday
                            this.reportingto = response.reportto
                            this.year = response.year?(response.year+' yr ') : ''
                            this.month = response.month?response.month+' mos':''
                            this.totalexp =this.year+ ''+this.month
    
                            this.dept = response.designation+' ['+response.department+']'
                            this.email = response.emailid
                            this.address = response.city+','+response.state+','+response.country
                            this.mobilenumber = response.mobileno
                            this.firstname=response.firstname
                            this.lastname=response.lastname
                            this.l1 = response.l1
                            this.l2 = response.l2
                            this.ticketreq.empid=response.empid
                            this.ticketreq.empname = (this.firstname +' '+ this.lastname).toUpperCase()
                            this.ticketreq.designation = (response.designation).toUpperCase()
                            this.ticketreq.dept = (response.department).toUpperCase()
                        }
                    })
                }
            },
            getmodeoftransport(){
                this.transportList=[]
                this.apiURL="api/listing/getmodeoftransport"
                this.input={
                    deptid:this.ticketreq.deptid,
                    designationid:this.ticketreq.designationid,
                    empcode:this.empid,
                    useremail: this.username
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {             
                    if(result.data.status){
                        this.transportList= result.data.data;
                    }
                })
            },
            displaymgr(){
                this.showmanager = !this.showmanager
            },
            
            mydetailsollapse:function(){
                this.isDetActive=!this.isDetActive
            },
            mysearchrequestollapse:function(){
                this.isSearchRequestActive=!this.isSearchRequestActive
            },
            
            getmasterlist(searchObj,statusVal){
                this.requestList=[]
                let payload = {
                    status:2,
                    empcode:this.empid,
                    useremail: this.username
                }
                this.isLoading = true;
                let apiURL='api/reimbursement/getApproveTravelTicketDetails'  
                if (searchObj){
                    payload.ticketid=searchObj.requestid ==null ?'':searchObj.requestid
                    payload.status=searchObj.status == null ? 2:searchObj.status
                }             
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data':payload,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {   
                    this.isLoading = false;          
                    if(result.data.status){
                        this.isDetActive=true;
                        this.items = result.data.data.responsedata;
                        this.totalRows = result.data.data.count
                    }else{
                        this.items = [];
                        this.isLoading= false;
                        this.totalRows = 0;
                    }
                })
    
            }
        }
    })
    </script>
    <style scoped>
    .display{
        display: none;
    }
    .pagination_view_co ul{
        margin: 0px auto;
    }
    .readmore_texttrs .outer span{
      overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .readmore_texttrs .outer.readmore span{
          overflow: unset;
        display: -webkit-box;
        -webkit-line-clamp: unset;
        line-clamp: 2;
        -webkit-box-orient: unset;
    }
    .cycle_viewmoretext a{
      text-align: left;
    }
    .cycle_viewmoretext a strong{
            color: blue;
    } 
    
    </style>