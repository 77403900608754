<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12 new_dash_mar_top10">
            <h3 class="content-header-title float-left mb-1">Role Project API Configuration</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls">Add Configuration</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card " >
          <div v-bind:class="[{show: isRequestActive},card_content,collapse]" >
              <div class="card-header indent_border_bottom border-bottom py-1 px-2 py-2">
                  <h3 class="card-title">
                      Add Role Project API Configuration
                  </h3>
              </div>
            <div class="card-body pt-1">
              <form class="form form-vertical">
                <div class="row mb-1">
                  
                  <div class="col-md-4">
                    <div class="form-group mb1">
                        <label for="allotedto">Project</label>
                        <treeselect class="allotedto capitalisetext" placeholder="Select Project" v-model="roletrack.project" :options="projectlist" @input="handleInputChange" />
                        <div class="errorinputmsg" v-if="this.errors.roletrack.project">{{ this.errors.roletrack.project }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group mb-1">
                      <label for="allotedto">Cams Role</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select role" v-model="roletrack.roleid" :options="rolelist" @input="handleRoleChange"    />
                    <div class="errorinputmsg" v-if="this.errors.roletrack.roleid">{{ this.errors.roletrack.roleid }}</div>
                    </div>
                  </div>
                  <div class="col-md-4" v-if="roletrack.roleid">
                    <div class="form-group mb-1">
                      <label for="allotedto">Copy From Role</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select Copy role" v-model="roletrack.copyroleid" :options="rolelist" @input="handleCopyRoleChange"  />
                     </div>
                  </div>
                </div>
            
                <div class="card " id="performanceList">
                    <div class="card-body p-0">
                          <div class="table-responsive" v-if="roletrack.project && roletrack.roleid">
                            <table class="table table-sm dataTableListing" aria-describedby="mydesc">
                              <thead>
                                  <tr>
                                    <th scope="col" class="text-center">#</th>
                                      <th scope="col" class="text-left">API URL</th>
                                      <th scope="col" class="text-left">API Code</th>
                                      <th scope="col" class="text-left">ModuleName</th>
                                      <th scope="col" class="text-left">Funcationality</th>

                                  </tr>
                              </thead>
                              <tbody>
                                  <template>

                                      <tr v-for="(data,index) in apiconfiglist" v-bind:key="index" >
                                        <td class="text-center text-capitalize">

                                        <input v-if="(roleapiconfiglist.length > 0 && !roletrack.copyroleid) && (roleapiconfiglist[0].apiconfig.some(item => item.id === data.id)) == true" @change="selectedItem(data.id, $event)" :id="'checkbox-' + index" name="productfilter" class="productfilter masterid ml-1 " v-model="checkedItems[index]" type="checkbox" checked>

                                        <input v-else-if="(copyroleapiconfiglist.length > 0 && roletrack.copyroleid) && (copyroleapiconfiglist[0].apiconfig.some(item => item.id === data.id)) == true" @change="selectedItem(data.id, $event)" :id="'checkbox-' + index" name="productfilter" v-model="checkedItems[index]" class="productfilter masterid ml-1" type="checkbox" checked>

                                        <input v-else @change="selectedItem(data.id, $event)" :id="'checkbox-' + index" v-model="checkedItems[index]" name="productfilter" class="productfilter masterid ml-1" type="checkbox"></td>
                                        <td class="text-left text-capitalize">{{data.apiurl}}</td>
                                        <td class="text-left text-capitalize">{{data.apicode}}</td>
                                        <td class="text-left text-capitalize">{{data.modulename ? data.modulename : 'NA' }}</td>
                                        <td class="text-left text-capitalize">{{data.functionalityinfo ? data.functionalityinfo :'NA'}}</td>

                                    
                                      </tr>     
                                  </template>
                              </tbody>
                            </table>
                     
 
                        </div>
                      
                            <div class="projectmapping_text_bg" v-else style="text-align: center; padding:10px 0px; background: #f6f6f6;border-radius: 10px;">Please select role and Project</div>
                    </div>
                </div>
                <div class="col-12 mg-top1 text-center">

                    <button v-if="roletrack.roleid && roletrack.project && apiconfiglist.length > 0" type="button" class="btn btn-relief-primary mr-1"  @click="savewizard()" id="submitbtnt42">Submit</button>
                    
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>

.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import { EditIcon, EyeIcon, Trash2Icon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon } from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import commonMethods from '../../utils/commonMethods';

export default {
  name:'RoleTracker',
  mixins: [ commonMethods ],
  components:{
    Loading,
    VueElementLoading,
    // vSelect,
    EditIcon,
    EyeIcon,
    Treeselect,
    SearchIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    PlusIcon,
    DownloadIcon,
    Edit2Icon,
    PlusSquareIcon,
    ClipboardIcon,
    Trash2Icon,
  },    
  data() {
    return {
      isThisBusinessRole: false,
      loginuser:'',
      isLoading: false,
      fullPage: true,
      projectlist: [],
      editRowData:[],
      roletrack:{
        rolename:null,
        roleid: null,
        project:null,
        copyroleid:null,
        optionvalue: [{
          id:''
        }]
      },
      isAddState:false,
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,
      pagefeatures:[],
      showtabledata:true,
      showproject:false,
      modulelist:[],
      isRequestActive:true,
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      errors:{
        roletrack:{
          rolename:null,
          roleid: null,
          project:null  
        }
      },
      isedit: false,
      card:'card',
      statusrowid: null,
      statuscolumnid: null,     
      rolelist: [],
      newarray: [],
      apiconfiglist:[],
      roleapiconfiglist:[],
      selectedItems: [],
      copyroleapiconfiglist:[],
      checkedItems: []
     
    }
  },
  mounted(){
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata){
          userdata = JSON.parse(userdata)
          this.undt=userdata
          this.loginusername=userdata.username
          this.loginuscondition = userdata.userid 
          this.tokendata = window.localStorage.getItem('token');  
          this.getProjectAllocatedToEmp()
      }
   }
  },
  methods:{
    getThirdPartyAPIcall(){
  this.inputthrid = {
    projectid:this.roletrack.project ? this.roletrack.project:null,
    empcode: parseInt(this.undt.userid),
    useremail: this.undt.username,
  }
  axios({
    'method': 'POST',
    'url': 'api/projectapiconfig/getProjectWiseCamsRolesList',
    'data': this.inputthrid,
    'headers':{'authorization':this.tokendata}
  })
  .then(result => {
    if(result.data.status == true){
      this.rolelist = result.data.data
    }else{
      this.rolelist=[]
    }
    this.rolelist.sort((a, b) => {
                return a.label.localeCompare(b.label);
     });
  }).catch(e => {
        this.displayError(e)
      });
},
    cleardata(){
      this.roletrack.roleid = null;
      this.roletrack.project = null;
      this.errors.roletrack.roleid = null;
      this.errors.roletrack.trackername = null;
      this.apiconfiglist = []
      this.selectedItems = []
      this.roleapiconfiglist = [];
      this.copyroleapiconfiglist = []
      this.roletrack.copyroleid = null

    },
    //raja
    getProjectAllocatedToEmp() {
      this.isLoading = true;
      let url = "api/listing/getProjectAllocatedToEmp";
      this.input = {
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        empcode: this.undt.userid,
        isfrompage:'listingpage'
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectlist = result.data.data;
        } else {
          this.projectlist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getProjectApiConfiglist() {
      this.isLoading = true;
      let url = "api/projectapiconfig/getProjectAPIConfiguredList";
      this.input = {
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        empcode: this.undt.userid,
        offset:null,
        limit:null,
        project:this.roletrack.project ? [this.roletrack.project]:null
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.apiconfiglist = result.data.data.rows;
        } else {
          this.apiconfiglist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    handleInputChange(state){
      this.selectedItems = []
      this.roletrack.roleid = null
      this.roletrack.copyroleid = null
      this.apiconfiglist = []
      this.copyroleapiconfiglist = []
      this.checkedItems = []
      if(state!=null){
          this.getThirdPartyAPIcall();
          this.getProjectApiConfiglist()
         this.getRoleAPIConfiguredList() 
        }else{
        this.apiconfiglist = null
        }
        },
        getRoleAPIConfiguredList() {
      this.isLoading = true;
      let url = "api/projectapiconfig/getRoleAPIConfiguredList";
      this.input = {
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        empcode: this.undt.userid,
        project:this.roletrack.project ? this.roletrack.project:null,
        roleid:this.roletrack.roleid ? this.roletrack.roleid:null
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.roleapiconfiglist = result.data.data.rows;
    
          this.selectedItems = []
          if(this.roleapiconfiglist[0].apiconfig.length > 0 ){
            
            for (let index in this.apiconfiglist) {              

               if((this.roleapiconfiglist[0].apiconfig.some(item => parseInt(item.id) == this.apiconfiglist[index].id)==true)){
               this.selectedItems.push(this.apiconfiglist[index]);
               }
            }
          }

        } else {
          this.roleapiconfiglist = [];
          this.selectedItems = []
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
      selectedItem(index, event) {
    if (event.target.checked) {
      let matcheddata = this.apiconfiglist.find(obj => obj.id == index)
      this.selectedItems.push(matcheddata);
    } else {
      let itemIndex = this.selectedItems.findIndex(obj => obj.id == index);
      if (itemIndex !== -1) {
        this.selectedItems.splice(itemIndex, 1);
      }
    }
    },
    savewizard: function(mdl,objThis){
       // this.valid = this.validateForm(mdl,objThis);
    //    if (this.valid) {
            this.isLoading = true;
            this.input = {
                createdby: this.undt.username,
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
            }
           
            let apiURL='api/projectapiconfig/createRoleAPIConfigure'
            this.input.roleid = this.roletrack.roleid ? this.roletrack.roleid : null
            this.input.project = this.roletrack.project ? this.roletrack.project : null
            this.input.formconfigdata = this.selectedItems.length > 0 ? this.selectedItems : []
            axios({
            'method': 'POST',
            'data': this.input,
            'url': apiURL,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;             
                if(result.data.errorCode == 0){
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.cleardata();
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                          title: "Session Expired...!!",
                          text: result.data.msg,
                          icon: 'info',
                          customClass: {
                              confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: !1,
                      }).then(function() {
                        window.location.href = "/#/login";
                    })
                  } else {
                    Swal.fire({
                        title: "Failed",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            }).catch(e => {
            this.displayError(e)
          })
     //   }
    },  
    handleRoleChange(state){
      this.roletrack.copyroleid = null
      this.checkedItems = []
      if(state!=null){
    this.getProjectApiConfiglist()
    this.getRoleAPIConfiguredList()
    }else{
    this.roletrack.project = null
    this.apiconfiglist = null
    this.selectedItems = []
    this.roletrack.copyroleid = null
    }
    },
    handleCopyRoleChange(state){
    this.roleapiconfiglist=[]
    this.checkedItems = []
      if(state!=null){
        this.selectedItems = []
      this.copyroleapiconfiglist = []
      this.getCopyRoleAPIConfiguredList()
    }else{
      this.copyroleapiconfiglist = []
     this.roletrack.copyroleid = null
     this.selectedItems = []
     }
    },
    getCopyRoleAPIConfiguredList() {
      this.isLoading = true;
      let url = "api/projectapiconfig/getRoleAPIConfiguredList";
      this.input = {
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        empcode: this.undt.userid,
        project:this.roletrack.project ? this.roletrack.project:null,
        roleid:this.roletrack.copyroleid ? this.roletrack.copyroleid:null
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.copyroleapiconfiglist = result.data.data.rows;
    
           this.selectedItems = []
          if(this.copyroleapiconfiglist[0].apiconfig.length > 0 ){
            for (let index in this.apiconfiglist) {              
               if((this.copyroleapiconfiglist[0].apiconfig.some(item => parseInt(item.id) == this.apiconfiglist[index].id)==true)){
                this.selectedItems.push(this.apiconfiglist[index]);
               }
            }
          }
        } else {
          this.copyroleapiconfiglist = []
        }
      }).catch(e => {
        this.displayError(e)
      });
    }        
  }, 
  filters: {
    basename: function (value) {
        if (!value) return ''
        return value.substr(value.lastIndexOf("/") + 1)
    }
}
}
</script>