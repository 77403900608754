<template>
<div>
    <div class="app-content content devcontent ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
                
            </div>
            <div class="content-body">
                <section id="dashboard-ecommerce">
                    <div v-if="pagefeatures.compnaydetails" class="row match-height dshboard_margin_x">
                        <div class="col-lg-12 col-12 dshboard_padd_x">
                            <div class="row match-height dashTiles ">
                            <div class="col-lg-3 col-md-3 col-6">
                                <div class="card card_bottom_line_animation bottom_bg_bor3">
                                <div class="card-header">
                                    <div class="top_header_card_width">
                                    <h2 class="font-weight-bolder mb-0">
                                        {{ companycount }}
                                    </h2>
                                    <p class="card-text">Company </p>
                                    </div>
                                    <div class="dashboard_card_top">
                                    <div class="avatar bg-light-primary p-50 m-0">
                                    <div class="avatar-content">
                                        <em class="las la-building line_font_size"></em>
                                    
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-6">
                                <div class="card card-tiny-line-stats card_bottom_line_animation bottom_bg_bor4">
                                <div class="card-header">
                                    <div class="top_header_card_width">
                                    <h2 class="font-weight-bolder mb-0">
                                        {{ projectmasterList }}
                                    </h2>
                                    <p class="card-text">Projects</p>
                                    </div>
                                    <div class="dashboard_card_top">
                                    <div class="avatar bg-light-success p-50 m-0">
                                    <div class="avatar-content">
                                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" width="21" height="21">
                                            <g id="Layer">
                                                <g id="Layer">
                                                    <g id="Layer">
                                                        <g id="Layer">
                                                            <circle fill="currentColor" id="Layer" class="s0" cx="10.5" cy="10.5" r="1.8"/>
                                                        </g>
                                                    </g>
                                                    <g id="Layer">
                                                        <path id="Layer" fill="currentColor" fill-rule="evenodd" class="s0" d="m17 14.1q0.1 0.4 0.1 0.8c0.5 2.2 0.1 3.8-0.9 4.4q-0.6 0.4-1.3 0.4c-1.2 0-2.8-0.8-4.4-2.3-2.3 2-4.4 2.8-5.7 1.9-1-0.6-1.4-2.2-0.9-4.4q0-0.5 0.2-1-0.5-0.2-0.9-0.3c-2.1-0.9-3.2-2.1-3.2-3.3 0-1.4 1.5-2.7 4-3.4q-0.1-0.4-0.1-0.7c-0.4-0.2-0.6-0.5-0.6-0.9 0-0.3 0.1-0.6 0.4-0.8 0-1.4 0.4-2.4 1.1-2.8 1.3-0.9 3.4-0.1 5.7 1.9 2.3-2 4.4-2.8 5.7-1.9 1.2 0.7 1.5 2.7 0.7 5.4q0.5 0.1 0.9 0.3 0.6 0.3 1.1 0.6 0.2-0.1 0.3-0.1c0.5 0 1 0.5 1 1q0 0 0 0 0.8 0.9 0.8 1.8c0 1.4-1.5 2.7-4 3.4zm-7.2 2.6q-0.9-0.9-1.7-1.9-1.6-0.2-3.1-0.6-0.1 0.5-0.2 0.8c-0.3 1.8-0.1 3.1 0.6 3.5 0.8 0.6 2.6-0.2 4.4-1.8zm-5.1-12.3c0.3 0.1 0.6 0.5 0.6 0.9 0 0.3-0.2 0.6-0.4 0.8q0 0.2 0.1 0.5 1.5-0.3 3.2-0.5 0.8-1 1.6-1.8c-1.8-1.6-3.6-2.4-4.4-1.8-0.6 0.3-0.7 1.2-0.7 1.9zm2.6 9.3q-0.3-0.4-0.5-0.8-0.4-0.7-0.8-1.3-0.4 0.8-0.7 1.7 0.9 0.2 2 0.4zm0.1-6.5q-1.1 0.1-2.1 0.4 0.3 0.9 0.7 1.8 0.4-0.7 0.8-1.3 0.3-0.4 0.6-0.9zm0.2 5.1q0.5 0.8 1 1.5 0.9 0.1 1.8 0.1 1 0 1.9 0 0.6-0.8 1.1-1.6 0.6-0.8 1.1-1.8-0.5-1-1.1-1.9-0.5-0.7-1-1.4-0.9-0.1-1.8-0.1-1-0.1-1.9 0-0.6 0.8-1.1 1.5-0.6 0.9-1.1 1.9 0.5 1 1.1 1.8zm8.1 1.1q-0.3-0.9-0.7-1.8-0.4 0.6-0.8 1.3-0.3 0.4-0.6 0.9 1.1-0.1 2.1-0.4zm-0.7-4q0.4-0.8 0.7-1.7-1-0.2-2-0.4 0.3 0.4 0.5 0.8 0.4 0.6 0.8 1.3zm0.6-6.9c-0.8-0.6-2.6 0.2-4.4 1.8q0.9 0.9 1.7 1.9 1.6 0.2 3.1 0.6c0.6-2.2 0.4-3.8-0.4-4.3zm-4.1 3.6q-0.5-0.6-1-1.1-0.5 0.5-1 1.1 0.3 0 0.5 0 0.3 0 0.6 0 0.4 0 0.9 0zm-10.5 4.2c0 0.8 0.9 1.7 2.6 2.4q0.3 0.1 0.8 0.3 0.4-1.2 1-2.5-0.7-1.4-1.1-2.7c-2.1 0.6-3.3 1.6-3.3 2.5zm8.5 4.6q0.5 0.6 1 1.1 0.5-0.5 1-1.1-0.5 0-1.1 0-0.4 0-0.9 0zm1.7 1.8c1.8 1.6 3.6 2.4 4.4 1.8 0.7-0.4 0.9-1.7 0.6-3.5q-0.1-0.2-0.2-0.6-1.5 0.3-3.2 0.5-0.8 1-1.6 1.8zm8.8-6q0-0.4-0.4-0.9-0.2 0.1-0.4 0.1c-0.5 0-1-0.5-1-1q0-0.1 0-0.2-0.3-0.2-0.8-0.4-0.3-0.1-0.8-0.3-0.4 1.2-1 2.5 0.7 1.4 1.1 2.7c2.1-0.7 3.3-1.6 3.3-2.5z"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-6">
                                <div class="card card-tiny-line-stats card_bottom_line_animation bottom_bg_bor1">
                                <div class="card-header">
                                    <div class="top_header_card_width">
                                    <h2 class="font-weight-bolder mb-0">
                                        {{ projectmodulemasterList }}
                                    </h2>
                                    <p class="card-text">Project Modules</p>
                                    </div>
                                    <div class="dashboard_card_top">
                                    <div class="avatar bg-light-danger p-50 m-0">
                                    <div class="avatar-content">
                                        <box-icon
                                        size="4x"
                                        class="custom-class font-medium-5"
                                        ></box-icon>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-6">
                                <div class="card card-tiny-line-stats card_bottom_line_animation bottom_bg_bor5">
                                <div class="card-header">
                                    <div class="top_header_card_width">
                                    <h2 class="font-weight-bolder mb-0">
                                        {{ applicationmasterList }}
                                    </h2>
                                    <p class="card-text">Applications</p>
                                    </div>
                                    <div class="dashboard_card_top">
                                    <div class="avatar bg-light-warning p-50 m-0">
                                    <div class="avatar-content">
                                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" width="21" height="21">
                                            <path id="Layer" fill="currentColor" d="m17.1 1.3h2.6v2.6h-2.6z"/>
                                            <path id="Layer" fill="currentColor" d="m17.1 5.3h2.6v2.6h-2.6z"/>
                                            <path id="Layer" fill="currentColor" d="m13.1 1.3h2.7v2.6h-2.7z"/>
                                            <path id="Layer" fill="currentColor" d="m13.1 5.3h2.7v2.6h-2.7z"/>
                                            <path id="Layer" fill-rule="evenodd" fill="currentColor" d="m19.7 10.5v3.9q0 0.3-0.1 0.5-0.1 0.3-0.3 0.5-0.2 0.1-0.4 0.3-0.3 0-0.5 0.1h-5.3v2.6h2.7v1.3h-10.5v-1.3h2.6v-2.6h-5.3q-0.2-0.1-0.5-0.1-0.2-0.2-0.4-0.3-0.2-0.2-0.3-0.5-0.1-0.2-0.1-0.5v-10.5q0-0.2 0.1-0.5 0.1-0.2 0.3-0.4 0.2-0.2 0.4-0.3 0.3-0.1 0.5-0.1h7.9v1.3h-7.9v10.5h15.8v-3.9zm-10.5 7.9h2.6v-2.7h-2.6z"/>
                                            <path id="_Transparent_Rectangle_" fill="none" class="s1" d="m0 0h21v21h-21z"/>
                                        </svg>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-6">
                                <div
                                class="card card-tiny-line-stats card_bottom_line_animation bottom_bg_bor6"
                                >
                                <div class="card-header">
                                    <div class="top_header_card_width">
                                    <h2 class="font-weight-bolder mb-0">
                                        {{ applicationmodulemasterList }}
                                    </h2>
                                    <p class="card-text">Application Modules</p>
                                    </div>
                                    <div class="dashboard_card_top">
                                    <div class="avatar bg-light-secondary p-50 m-0">
                                    <div class="avatar-content">
                                    <codesandbox-icon
                                        size="4x"
                                        class="custom-class font-medium-5"
                                        ></codesandbox-icon>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-6">
                                <div
                                class="card card-tiny-line-stats card_bottom_line_animation bottom_bg_bor5"
                                >
                                    <div class="card-header">
                                    <div class="top_header_card_width">
                                    <h2 class="font-weight-bolder mb-0">
                                        {{ sectionList }}
                                    </h2>
                                    <p class="card-text">Section Module</p>
                                    </div>
                                    <div class="dashboard_card_top">
                                    <div class="avatar bg-light-warning p-50 m-0">
                                    <div class="avatar-content">
                                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" width="21" height="21" >                            
                                            <rect id="Layer" fill="currentColor" x="15" y="9.2" width="5.2" height="2.6" rx="1"></rect>
                                            <rect id="Layer" fill="currentColor" x="7.9" y="9.2" width="5.2" height="2.6" rx="1"></rect>
                                            <rect id="Layer" fill="currentColor"  x=".8" y="9.2" width="5.2" height="2.6" rx="1"></rect>
                                            <path id="Layer" fill="currentColor" fill-rule="evenodd"  d="m2.1 14.7c0-0.5 0.4-1 1-1h14.8c0.6 0 1 0.5 1 1v3.8c0 0.6-0.4 1-1 1h-14.8c-0.6 0-1-0.4-1-1zm1.9 2.9h13v-1.9h-13z"/>
                                            <path id="Layer" fill="currentColor" fill-rule="evenodd" d="m2.1 2.5c0-0.6 0.4-1 1-1h14.8c0.6 0 1 0.4 1 1v3.8c0 0.5-0.4 1-1 1h-14.8c-0.6 0-1-0.5-1-1zm1.9 2.8h13v-1.9h-13z"/>
                                        </svg>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-6">
                                <div
                                class="card card-tiny-line-stats card_bottom_line_animation bottom_bg_bor2"
                                >
                                <div class="card-header">
                                    <div class="top_header_card_width">
                                    <h2 class="font-weight-bolder mb-0">
                                        {{ functionlityList }}
                                    </h2>
                                    <p class="card-text">Functionality Module</p>
                                    </div>
                                    <div class="dashboard_card_top">
                                    <div class="avatar bg-light-info p-50 m-0">
                                    <div class="avatar-content">
                                        <layers-icon
                                        size="4x"
                                        class="custom-class font-medium-5"
                                        ></layers-icon>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <usercommondashboard />   
                    <section v-if="pagefeatures.userstat" id="dashboard-ecommerce">
                        <section v-if="pagefeatures.userstat" id="dashboard-ecommerce">
                            <h4 class="customTitle py-1 mb-0">
                                User Profile Statistics
                            </h4>
                            <div class="row match-height customAnimation" style="border-bottom:none;">
                                <div class="col-xl-2 col-md-4 col-sm-6">
                                <div class="card text-center">
                                <div class="card-body userstats_profile_card_bg" >
                                    <div class="avatar bg-light-info p-50 mb-1">
                                        <div class="avatar-content">
                                       
                                        <em class="las la-graduation-cap font-medium-5"></em>

                                       
                                        </div>
                                    </div>
                                    <h2 class="font-weight-bolder">{{ educount }}</h2>
                                    <p class="card-text">Education</p>
                                    </div>
                                </div>
                                </div>
                                <div class="col-xl-2 col-md-4 col-sm-6">
                                <div class="card text-center">
                                    <div class="card-body userstats_profile_card_bg">
                                    <div class="avatar bg-light-primary p-50 mb-1">
                                        <div class="avatar-content">
                                       
                                        <em class="las la-file-invoice font-medium-5"></em>
                                       
                                        </div>
                                    </div>
                                    <h2 class="font-weight-bolder">{{expcount}}</h2>
                                    <p class="card-text">Experience</p>
                                    </div>
                                </div>
                                </div>
                                <div class="col-xl-2 col-md-4 col-sm-6">
                                <div class="card text-center">
                                    <div class="card-body userstats_profile_card_bg">
                                    <div class="avatar bg-light-secondary p-50 mb-1">
                                        <div class="avatar-content">
                                        <em class="las la-user-cog font-medium-5"></em>
                                       
                                        </div>
                                    </div>
                                    <h2 class="font-weight-bolder">{{skillscount}}</h2>
                                    <p class="card-text">Skills</p>
                                    </div>
                                </div>
                                </div>
                                <div class="col-xl-2 col-md-4 col-sm-6">
                                <div class="card text-center">
                                    <div class="card-body userstats_profile_card_bg">
                                    <div class="avatar bg-light-success p-50 mb-1">
                                        <div class="avatar-content">
                                        <em class="las la-chalkboard-teacher font-medium-5"></em>
                                        </div>
                                    </div>
                                    <h2 class="font-weight-bolder">{{coursecount}}</h2>
                                    <p class="card-text">Courses</p>
                                    </div>
                                </div>
                                </div>
                                <div class="col-xl-2 col-md-4 col-sm-6">
                                <div class="card text-center">
                                    <div class="card-body userstats_profile_card_bg">
                                    <div class="avatar bg-light-warning p-50 mb-1">
                                        <div class="avatar-content">
                                        <check-circle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></check-circle-icon>
                                        </div>
                                    </div>
                                    <h2 class="font-weight-bolder">{{certcount}}</h2>
                                    <p class="card-text">Certifications</p>
                                    </div>
                                </div>
                                </div>
                                <div class="col-xl-2 col-md-4 col-sm-6">
                                <div class="card text-center">
                                    <div class="card-body userstats_profile_card_bg">
                                    <div class="avatar bg-light-danger p-50 mb-1">
                                        <div class="avatar-content">
                                        <award-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></award-icon>
                                        </div>
                                    </div>
                                    <h2 class="font-weight-bolder">{{awardscount}}</h2>
                                    <p class="card-text">Awards</p>
                                    </div>
                                </div>
                                </div>

                            </div>
                        </section>
                    </section>
                    <div class="row match-height" style="display:none">
                        <div class="col-lg-12 col-12">
                            <div class="card card-company-table">
                                <div class="card-body p-0">
                                    <div class="table-responsive datatableview">
                                        <table class="table" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Education</th>
                                                    <th scope="col">Experience</th>
                                                    <th scope="col">Skill</th>
                                                    <th scope="col">Courses</th>
                                                    <th scope="col">Certifications</th>
                                                    <th scope="col">Awards</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-column="Education">
                                                        <div class="d-flex align-items-center">
                                                            <div class="font-weight-bolder">{{educount}}</div>
                                                        </div>
                                                    </td>
                                                    <td data-column="Experience">
                                                        <div class="d-flex align-items-center">
                                                            <span  class="font-weight-bolder mb-25">{{expcount}}</span>
                                                        </div>
                                                    </td>
                                                    <td data-column="Skill" class="text-nowrap">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight-bolder mb-25">{{skillscount}}</span>
                                                        </div>
                                                    </td>
                                                    <td data-column="Courses">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight-bolder mb-25">{{coursecount}}</span>
                                                        </div>
                                                    </td>
                                                    <td data-column="Certifications">
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight-bolder mr-1">{{certcount}}</span>
                                                        </div>
                                                    </td>
                                                    <td data-column="Awards">
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight-bolder mr-1">{{awardscount}}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="pagefeatures.projectlist" class="row match-height">
                        <div class="col-lg-12 col-12">
                            <div class="card card-company-table">
                                <div class="card-body p-0">
                                    <div class="table-responsive datatableview">
                                        <table class="table table-hover table-striped" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                   <th scope="col" class="align-middle">Company</th>
                                                    <th scope="col" class="align-middle text-center">Projects</th>
                                                    <th scope="col" class="align-middle text-center">Modules</th>
                                                    <th scope="col" class="align-middle text-center">Application</th>
                                                    <th scope="col" class="align-middle text-center">Application Module</th>
                                                    <th scope="col" class="align-middle text-center">Section Module</th>
                                                    <th scope="col" class="align-middle text-center">Functionlity Module</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data,index) in projectdashboardList" v-bind:key="index">
                                                    <td data-column="Company">
                                                        <div class="d-flex align-items-center">
                                                            <div class="avatar rounded">
                                                                <div class="avatar-content">
                                                                    <div v-if="data.companyname=='Firstcry India' || data.companyname=='Firstcry China' || data.companyname=='Firstcry ME'">
                                                                        <country-flag v-if="data.companyname=='Firstcry India'" country='in' size='normal'/>
                                                                        <country-flag v-if="data.companyname=='Firstcry China'" country='cn' size='normal'/>
                                                                        <country-flag v-if="data.companyname=='Firstcry ME'" country='ae' size='normal'/>
                                                                    </div>
                                                                    <div v-else>
                                                                        <img alt="" src="../../public/images/icons/small-logo.png" height="20" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="font-weight-normal">{{data.companyname}}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td data-column="Projects" class="text-center">
                                                            <span  class="font-weight-normal mb-25">{{data.projectcount}}</span>
                                                    </td>
                                                    <td data-column="Modules" class="text-nowrap text-center">
                                                            <span class="font-weight-normal mb-25">{{data.modulecount}}</span>
                                                    </td>
                                                    <td data-column="Application" class="text-center">
                                                            <span class="font-weight-normal mb-25">{{data.applicationcount}}</span>
                                                    </td>
                                                    <td data-column="Application Module" class="text-center">
                                                            <span class="font-weight-normal mr-1">{{data.applicationmodulecount}}</span>
                                                    </td>
                                                    <td data-column="Section Module" class="text-center">
                                                            <span class="font-weight-normal mr-1">{{data.sectioncount}}</span>
                                                    </td>
                                                    <td data-column="Functionlity Module" class="text-center">
                                                            <span class="font-weight-normal mr-1">{{data.functionalitycount}}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    </div>
    <div class="modal modal-slide-in fade" id="company" aria-hidden="true">
        <div class="modal-dialog sidebar-lg">
            <div class="modal-content p-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                <div class="modal-header mb-1">
                    <h5 class="modal-title">
                        <span class="align-middle">Create Company</span>
                    </h5>
                </div>
                <div class="modal-body flex-grow-1 devUniqueForm">
                    <form id="companyform">
                        <div class="form-group">
                            <label class="form-label" for="project-name">Company Name </label>
                            <treeselect :options="ddapplicationlist" @input="seltechapptypedata" v-model="selectedApp" @close="validate('t1')" />
                            <div class="errorinputmsg" v-if="company.nameerror">Application Type required</div>
                        </div>
                        <div class="form-group">
                            <label for="taskdescription">About Company</label>
                            <div class="input-group input-group-merge">
                                <textarea class="form-control" id="taskdescription" rows="3" placeholder="About Company" v-model="company.about" v-on:blur="validText('t1')"></textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Date of Formation</label>
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><em data-feather="user"></em></span>
                                </div>
                                <date-picker v-model="company.formationdate" valueType="format" class="startdate"></date-picker>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="project-name">Add VP to Business</label>
                            <treeselect :options="vplist" :multiple="true" :clear-on-select="true" @input="seltevptypedata" v-model="selectedVp" />
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="project-name">Add AVP to Business</label>
                            <treeselect :options="avplist" :multiple="true" :clear-on-select="true" @input="selteavptypedata" v-model="selectedAvp" />
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="project-name">Add Sr. Delivery / Delivery Manager</label>
                            <treeselect :options="dmgrlist" :multiple="true" :clear-on-select="true" @input="seltedmngrtypedata" v-model="selectedmngr" />
                        </div>
                        <div class="form-group d-flex flex-wrap mb-0" >
                            <button type="button" class="btn btn-outline-primary mr-1" data-dismiss="modal">Save As Draft</button>
                            <button type="button" class="btn btn-relief-primary mr-1 " id="type-success" data-dismiss="modal"  v-on:click.stop.prevent="saveCompanyData">Save & Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="modal modal-slide-in fade" id="portfollio" aria-hidden="true">
        <div class="modal-dialog sidebar-lg">
            <div class="modal-content p-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                <div class="modal-header mb-1">
                    <h5 class="modal-title">
                        <span class="align-middle">Create Portfollio</span>
                    </h5>
                </div>
                <div class="modal-body flex-grow-1 devUniqueForm">
                    <form>
                        <div class="form-group">
                            <label class="form-label" for="project-name">Portfollio Name </label>
                            <treeselect :options="businesslist" @input="businessdata" v-model="portfollio.businessid" @close="validate('t1')" />
                        </div>
                        <div class="form-group">
                            <label for="taskdescription">Is Product Involved</label>
                            <div class="form-check form-check-inline mx-2">
                                <input class="form-check-input ismastertypeOptions" type="radio" name="ismastertypeOptions" id="ismastertype1" value="1"  v-model="selectedRadio" checked />
                                <label class="form-check-label" for="ismastertype1">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input ismastertypeOptions" type="radio" name="ismastertypeOptions" id="ismastertype2" value="0" v-model="selectedRadio" />
                                <label class="form-check-label" for="ismastertype2">No</label>
                            </div>
                        </div>
                        <div>
                            <label for="ddcountry">Deploying Country</label>
                            <treeselect v-model="portfollio.country" :options="portfollio.countrylist" @input="selcountry"/>
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="project-name">Select AVP Product</label>
                            <treeselect :options="avplist" @input="selteavptypedata" v-model="selectedproductAvp" />
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="project-name">Select QA Manager</label>
                            <treeselect :options="qalist" @input="seltedqatypedata" :multiple="true" :clear-on-select="true" v-model="selectqalist" />
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="project-name">Select Program Manager</label>
                            <treeselect :options="dmgrlist" @input="seltedmngrtypedata"  :multiple="true" :clear-on-select="true" v-model="selectedprogrammngr" />
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="project-name">Select Project Manager</label>
                            <treeselect :options="pmlist" @input="seltedpmtypedata"  :multiple="true" :clear-on-select="true" v-model="selectpmlist" />
                        </div>
                        <div class="form-group d-flex flex-wrap mb-0" >
                            <button type="button" class="btn btn-primary mr-1" data-dismiss="modal">Save As Draft</button>
                            <button type="button" class="btn btn-relief-primary mr-1" id="type-success" data-dismiss="modal"  v-on:click.stop.prevent="createportfollio">Save & Submit</button>
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="modal modal-slide-in fade" id="productandservice" aria-hidden="true">
        <div class="modal-dialog sidebar-lg">
            <div class="modal-content p-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                <div class="modal-header mb-1">
                    <h5 class="modal-title">
                        <span class="align-middle">Add Product and Service</span>
                    </h5>
                </div>
                <div class="modal-body flex-grow-1 devUniqueForm">
                    <form>
                        <div class="form-group">
                            <label class="form-label" for="project-name">Onboard Program Manager</label>
                            <treeselect :options="dmgrlist" @input="seltedmngrtypedata" v-model="selectedmngr" />
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="project-name">Onboard Project Manager</label>
                            <treeselect :options="pmlist" @input="seltedpmtypedata" v-model="selectpmlist" />
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="project-name">Onboard APM</label>
                            <treeselect :options="apmlist" @input="selapm" v-model="selapmdata" />
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="project-name">Onboard Project Lead</label>
                            <treeselect :options="pllist" @input="selpl" v-model="selpldata" />
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="project-name">Onboard QA Lead</label>
                            <treeselect :options="qaleadlist" @input="selqalead" v-model="selqaleaddata" />
                        </div>
                        <div class="form-group d-flex flex-wrap mb-0" >
                            <button type="button" class="btn btn-primary mr-1" data-dismiss="modal">Save As Draft</button>
                            <button type="button" class="btn btn-relief-primary mr-1" id="type-success" data-dismiss="modal"  v-on:click.stop.prevent="saveData">Save & Submit</button>
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade text-left" id="xlarge" tabindex="-1" role="dialog" aria-labelledby="myModalLabel16" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel16">Company Details</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" id="table-small1">
                        <div class="col-12">
                            <div class="card">
                                <div class="table-responsive datatableview">
                                    <table class="table table-sm" aria-describedby="mydesc">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sr. No</th>
                                                <th scope="col">Company Name</th>
                                                <th scope="col">Formation Date</th>
                                                <th scope="col">VP Name</th>
                                                <th scope="col">AVP Name</th>
                                                <th scope="col">Delivery Manager</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,index) in companylist" v-bind:key="index">
                                                <td data-column="Sr. No">{{index+1}}</td>
                                                <td data-column="Company Name">{{data.companyname}}</td>
                                                <td data-column="Formation Date">{{data.formationdate}}</td>
                                                <td data-column="VP Name">{{data.vpname}}</td>
                                                <td data-column="AVP Name">{{data.avpname}}</td>
                                                <td data-column="Delivery Manager">{{data.deliverymanager}}</td>
                                                
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import axios from 'axios'
import apiURL from '../constants';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  InfoIcon,
  PlusIcon,
  TrendingUpIcon,
  BookIcon,
  AwardIcon,
  PenToolIcon,
  CheckCircleIcon,
  LinkIcon,
  MonitorIcon,
  CodesandboxIcon,
  PackageIcon,
  LayersIcon,
  FolderIcon,EyeIcon,
  CpuIcon,
  BoxIcon } from 'vue-feather-icons'
import CountryFlag from 'vue-country-flag'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import usercommondashboard from './dashboards/Usercommondashboard';
export default({
    name:'Dashboard',
    data() {
        return {
            isLoading: false,
            fullPage: true,
            un:'',
            createdby:'',
            company:{
                name:'',
                nameerror:null,
                about:'',
                formationdate:'',
                vp:'',
                avp:'',
                mngr:''
            },
            redincident:0,
            greenincident:0,
            blueincident:0,
            nameBlured:false,
            ddapplicationlist:[],
            vplist:[],
            avplist:[],
            dmgrlist:[],
            qalist:[],
            pmlist:[],
            selectqalist:null,
            selectpmlist:null,
            selectedApp:null,
            selectedmngr:null,
            selectedAvp:null,
            selectedVp:null,
            selqaleaddata:null,
            selpldata:null,
            selapmdata:null,
            pagelimit:apiURL.LIMIT,
            pageoffset:1,
            portfollio:{
                country:null,
                businessid:null,
                countrylist: [
                    { label: 'India', id: 'India' },
                    { label: 'UAE', id: 'UAE' },
                    { label: 'China', id: 'China' },
                    { label: 'Global', id: 'Global' },
                    { label: 'GCC', id: 'GCC' },
                    { label: 'Oman', id: 'Oman' },
                    { label: 'Kuwait', id: 'Kuwait' },
                    { label: 'KSA', id: 'KSA' },
                ]
            },
            selectedRadio:'no',
            qaleadlist:[],
            apmlist:[],
            pllist:[],
            companylist:[],
            companycount:0,
            businesslist:[],
            businesscount:0,
            productavplist:[],
            selectedproductAvp:null,
            selectedprogrammngr:null,
            pagefeatures:[],
            projectmasterList:0,
            projectmodulemasterList:0,
            applicationmasterList:0,
            applicationmodulemasterList:0,
            sectionList:0,
            functionlityList:0,
            projectdashboardList:[],
            userDataList:[],
            educount:0,
            expcount:0,
            coursecount:0,
            skillscount:0,
            awardscount:0,
            certcount:0,
            tokendata:null
        }  
    } ,
    created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/dashboard') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                
                userdata = JSON.parse(userdata)
                this.empid = userdata.userid
                this.un=userdata.firstname+' '+userdata.lastname
                this.createdby=userdata.emailaddress
                this.tokendata = window.localStorage.getItem('token');
            }
            if (!localStorage.getItem('reloaded')) {
                localStorage.setItem('reloaded', '1');
                location.reload();
            }
            if (feather) {
                feather.replace({
                    width: 14,
                    height: 14
                });
            }
            this.getApplicationdata('t1')
            this.getBuisnessdata('t2')
            this.getProjectmastercount();
            this.getProjectmodulemastercount()
            this.getSectioncount();
            this.getApplicationmastercount()
            this.getFunctionlitycount()
            this.getApplicationmodulemastercount()
            this.getProjectDashboard()
            this.getUserDataDashboard()
        }
    },
    components: {
        TrendingUpIcon,
    BookIcon,
    AwardIcon,
    PenToolIcon,
    CheckCircleIcon,
    LinkIcon,
    InfoIcon,
    Treeselect,
    DatePicker,
    EyeIcon,
    CountryFlag,
    usercommondashboard,
    MonitorIcon,
    CodesandboxIcon,
    PackageIcon,
    LayersIcon,
    FolderIcon,
    CpuIcon,
    BoxIcon,
    Loading,
    DatePicker,
    PlusIcon,
    usercommondashboard,
    },
    methods:{
        getcompanydetails(){
           
            this.getVPEmployeedata('vp')
            this.getAVPEmployeedata('avp')
            this.getEmployeedata('project manager')
        },
        getportfolliodetails(){
            this.getProductAVPEmployeedata('avp')
            this.getQAEmployeedata('qa engineer')
            this.getPMEmployeedata('program manager')
            this.getAPMEmployeedata('assistant project manager')
            this.getPLEmployeedata('project lead')
            this.getQALeadEmployeedata('qa lead')
            
        },
        validText(tblNm){
            var re = /[A-Za-z0-9].{5,}/;
            if(tblNm=="t1"){
                this.inputval = this.company.name
            }
            if(this.inputval){
                return re.test(this.inputval.toLowerCase());
            }
        },
        validate : function(tblNm){
            this.nameBlured=true
            if(tblNm=="t1"){
                this.inputval = this.company.name
            }
            if( this.inputval && this.validText(this.inputval)){
                this.valid = true;
            }
        },
        seltechapptypedata(state,value){
            this.selectedApp=null
            this.selectedApp=state
            if(!state && this.isAddState == false){
                this.company.nameerror=true
            }else{                
                this.company.nameerror=false
            }
        },
        seltevptypedata(state,value){
            this.selectedVp=null
            this.selectedVp=state
        },
        businessdata(state,value){
            this.portfollio.businessid=null
            this.portfollio.businessid=state
        },
        selteavptypedata(state,value){
            this.selectedvp=null
            this.selectedvp=state
        },
        seltedmngrtypedata(state,value){
            this.selectedmngr=null
            this.selectedmngr=state
        },
        seltedqatypedata(state,value){
            this.selectqalist=null
            this.selectqalist=state
        },
        seltedpmtypedata(state,value){
            this.selectpmlist=null
            this.selectpmlist=state
        },
        selcountry(state,value){
            this.portfollio.country=null
            this.portfollio.country=state
        },
        selapm(state,value){
            this.selapmdata=null
            this.selapmdata=state
        },
        selqalead(state,value){
            this.selqaleaddata=null
            this.selqaleaddata=state
        },
        selpl(state,value){
            this.selpldata=null
            this.selpldata=state
        },
        getBuisnessdata(mdl){
            this.isLoading=true
            this.pageoffset=1;
            var tmpClassNm='default'; 
            this.inputdata = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:this.pagelimit,
                empcode: this.empid,
                useremail: this.createdby,
            }
            let apiURL='api/master/commonmaster/list'                
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputdata,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.isLoading=false;
                    this.businesslist=result.data.data.rows
                    this.businesscount = (this.businesslist).length
                    $('.'+tmpClassNm).wrap('<div class="position-relative"></div>').select2({
                        dropdownAutoWidth: true,
                        dropdownParent: $('.'+tmpClassNm).parent(),
                        width: '100%',
                        data: this.businesslist
                    });
                }
            })
        },

        getApplicationdata(mdl){
            this.isLoading=true
            this.pageoffset=1;
            var tmpClassNm='default'; 
            this.inputdata = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:this.pagelimit,
                empcode: this.empid,
                useremail: this.createdby,
            }
            let apiURL='api/master/commonmaster/list'                
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputdata,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading=false
                if(result.data.errorCode == 0){
                    this.ddapplicationlist=result.data.data.rows
                    this.companycount = (this.ddapplicationlist).length
                    $('.'+tmpClassNm).wrap('<div class="position-relative"></div>').select2({
                        dropdownAutoWidth: true,
                        dropdownParent: $('.'+tmpClassNm).parent(),
                        width: '100%',
                        data: this.ddapplicationlist
                    });
                }
            })
        },
        getEmployeedata(mdl){
            var tmpClassNm='default'; 
            this.inputdata = {
                dept:'it',
                designation:mdl,
                empcode: this.empid,
                useremail: this.createdby,
            }
            let apiURL='api/listing/getemoloyeedetailsbyrole'                
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputdata,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.dmgrlist=result.data.data
                    $('.'+tmpClassNm).wrap('<div class="position-relative"></div>').select2({
                        dropdownAutoWidth: true,
                        dropdownParent: $('.'+tmpClassNm).parent(),
                        width: '100%',
                        data: this.dmgrlist
                    });
                }
            })
        },
        getVPEmployeedata(mdl){
            var tmpClassNm='default'; 
            this.inputdata = {
                dept:'it',
                designation:mdl,
                empcode: this.empid,
                useremail: this.createdby,
            }
            let apiURL='api/listing/getemoloyeedetailsbyrole'                
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputdata,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.vplist=result.data.data
                    $('.'+tmpClassNm).wrap('<div class="position-relative"></div>').select2({
                        dropdownAutoWidth: true,
                        dropdownParent: $('.'+tmpClassNm).parent(),
                        width: '100%',
                        data: this.vplist
                    });
                }
            })
        },
        getAVPEmployeedata(mdl){
            var tmpClassNm='default'; 
            this.inputdata = {
                dept:'it',
                designation:mdl,
                empcode: this.empid,
                useremail: this.createdby,
            }
            let apiURL='api/listing/getemoloyeedetailsbyrole'                
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.inputdata,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.avplist=result.data.data
                    $('.'+tmpClassNm).wrap('<div class="position-relative"></div>').select2({
                        dropdownAutoWidth: true,
                        dropdownParent: $('.'+tmpClassNm).parent(),
                        width: '100%',
                        data: this.avplist
                    });
                }
            })
        },
        getProductAVPEmployeedata(mdl){
            var tmpClassNm='default'; 
            this.inputdata = {
                dept:'it',
                designation:mdl,
                empcode: this.empid,
                useremail: this.createdby,
            }
            let apiURL='api/listing/getemoloyeedetailsbyrole'                
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.inputdata,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.productavplist=result.data.data
                    $('.'+tmpClassNm).wrap('<div class="position-relative"></div>').select2({
                        dropdownAutoWidth: true,
                        dropdownParent: $('.'+tmpClassNm).parent(),
                        width: '100%',
                        data: this.productavplist
                    });
                }
            })
        },
        getQAEmployeedata(mdl){
            var tmpClassNm='default'; 
            this.inputdata = {
                dept:'it',
                designation:mdl,
                empcode: this.empid,
                useremail: this.createdby,
            }
            let apiURL='api/listing/getemoloyeedetailsbyrole'                
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.inputdata,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.qalist=result.data.data
                    $('.'+tmpClassNm).wrap('<div class="position-relative"></div>').select2({
                        dropdownAutoWidth: true,
                        dropdownParent: $('.'+tmpClassNm).parent(),
                        width: '100%',
                        data: this.qalist
                    });
                }
            })
        },
        getPMEmployeedata(mdl){
            var tmpClassNm='default'; 
            this.inputdata = {
                dept:'it',
                designation:mdl,
                empcode: this.empid,
                useremail: this.createdby,
            }
            let apiURL='api/listing/getemoloyeedetailsbyrole'                
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.inputdata,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.pmlist=result.data.data
                    $('.'+tmpClassNm).wrap('<div class="position-relative"></div>').select2({
                        dropdownAutoWidth: true,
                        dropdownParent: $('.'+tmpClassNm).parent(),
                        width: '100%',
                        data: this.pmlist
                    });
                }
            })
        },

        getAPMEmployeedata(mdl){
            var tmpClassNm='default'; 
            this.inputdata = {
                dept:'it',
                designation:mdl,
                empcode: this.empid,
                useremail: this.createdby,
            }
            let apiURL='api/listing/getemoloyeedetailsbyrole'                
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.inputdata,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.apmlist=result.data.data
                    $('.'+tmpClassNm).wrap('<div class="position-relative"></div>').select2({
                        dropdownAutoWidth: true,
                        dropdownParent: $('.'+tmpClassNm).parent(),
                        width: '100%',
                        data: this.apmlist
                    });
                }
            })
        },
        getPLEmployeedata(mdl){
            var tmpClassNm='default'; 
            this.inputdata = {
                dept:'it',
                designation:mdl,
                empcode: this.empid,
                useremail: this.createdby,
            }
            let apiURL='api/listing/getemoloyeedetailsbyrole'                
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.inputdata,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.pllist=result.data.data
                    $('.'+tmpClassNm).wrap('<div class="position-relative"></div>').select2({
                        dropdownAutoWidth: true,
                        dropdownParent: $('.'+tmpClassNm).parent(),
                        width: '100%',
                        data: this.pllist
                    });
                }
            })
        },
        getQALeadEmployeedata(mdl){
            var tmpClassNm='default'; 
            this.inputdata = {
                dept:'it',
                designation:mdl,
                empcode: this.empid,
                useremail: this.createdby,
            }
            let apiURL='api/listing/getemoloyeedetailsbyrole'                
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.inputdata,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.qaleadlist=result.data.data
                    $('.'+tmpClassNm).wrap('<div class="position-relative"></div>').select2({
                        dropdownAutoWidth: true,
                        dropdownParent: $('.'+tmpClassNm).parent(),
                        width: '100%',
                        data: this.qaleadlist
                    });
                }
            })
        },
        saveCompanyData(){
            this.isLoading=true
            this.input={
                companyid:this.selectedApp,
                description:this.company.about,
                formationdate:this.company.formationdate,
                vpid :this.selectedVp,
                avpid:this.selectedAvp,
                deliverymanagerid:this.selectedmngr,
                createdby:this.createdby,
                empcode: this.empid,
                useremail: this.createdby,
            }
            let apiURL='api/companyportfollio/savecompany'                
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading=false;
                if(result.data.errorCode == 0){
                    $('.close').click();
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }else{
                    Swal.fire({
                        title: "Oops...!",
                        text: result.data.msg,
                        icon: 'error',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            })
        },
        getcompanylist(requestid){
            this.isLoading=true
            this.input={
                companyid:requestid,
                empcode: this.empid,
                useremail: this.createdby,
            }
            let apiURL='api/companyportfollio/companylist'                
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading=false
                if(result.data.errorCode == 0){
                    this.companylist = result.data.data
                }
            })
        },
        createportfollio(){
            this.input={
                businessverticleid:this.portfollio.businessid,
                isproductinvolved:this.selectedRadio,
                country:this.portfollio.country,
                productavpid:this.selectedproductAvp,
                qamanagerid:this.selectqalist,
                programmanagerid:this.selectedprogrammngr,
                projectmanagerid:this.selectpmlist,
                createdby:this.createdby,
                empcode: this.empid,
                useremail: this.createdby,
            }
            console.log(this.input)

            let apiURL='api/companyportfollio/createportfollio'                
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    $('.close').click();
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }else{
                    Swal.fire({
                        title: "Oops...!",
                        text: result.data.msg,
                        icon: 'error',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            })
        },
        getProjectmastercount(){
            this.projectmasterList =[]
            this.apiURL="api/dashboard/getprojectmastercount"
            this.input={
                id:0,
                empcode: this.empid,
                useremail: this.createdby,
            }

            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.status){
                    let response =result.data.data;
                    this.projectmasterList = response[0].projectmaster;
                }
            })
        },
        getProjectmodulemastercount(){
            this.projectmodulemasterList =[]
            this.apiURL="api/dashboard/getprojectmodulemastercount"
            this.input={
                id:0,
                empcode: this.empid,
                useremail: this.createdby,
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.status){
                    let response =result.data.data;
                this.projectmodulemasterList = response[0].projectmodulemaster;
                }
            })
        },
        getApplicationmastercount(){
            this.applicationmasterList =[]
            this.apiURL="api/dashboard/getApplicationmastercount"
            this.input={
            
                empcode: this.empid,
                useremail: this.createdby,
                }

            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}

            })
            .then(result => {
                if(result.data.status){
                    let response =result.data.data;
                this.applicationmasterList = response[0].applicationmaster;
                }
            })
        },
        getApplicationmodulemastercount(){
            this.applicationmodulemasterList =[]
            this.apiURL="api/dashboard/getApplicationmodulemastercount"
            this.input={
            
                empcode: this.empid,
                useremail: this.createdby,
            }

            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.status){
                    let response =result.data.data;
                this.applicationmodulemasterList = response[0].applicationmodulemaster;
                }
            })
        },
        getSectioncount(){
            this.sectionList =[]
            this.apiURL="api/dashboard/getsectionmastercount"
            this.input={
            
                empcode: this.empid,
                useremail: this.createdby,
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
             })
            .then(result => {
                if(result.data.status){
                    let response =result.data.data;
                this.sectionList = response[0].sectionmaster;
                }
            })
        },
        
        getFunctionlitycount(){
            this.functionlityList =[]
            this.apiURL="api/dashboard/getfunctionlitymastercount"
            this.input={
                
                empcode: this.empid,
                useremail: this.createdby,
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}

            })
            .then(result => {
                if(result.data.status){
                    let response =result.data.data;
                this.functionlityList = response[0].functionlity;
                }
            })
        },
        getProjectDashboard(){
            this.functionlityList =[]
            this.apiURL="api/dashboard/getProjectDashboard"
            this.input={
                
                empcode: this.empid,
                useremail: this.createdby,
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}

            })
            .then(result => {
                if(result.data.status){
                    this.projectdashboardList = result.data.data;
                }
            })
        },
        getUserDataDashboard(){
            this.userDataList=[]
            this.apiURL="api/dashboard/getUserDashboard"
            this.input={
                id:0,
                empcode: this.empid,
                useremail: this.createdby,
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.status){
                    this.userDataList = result.data.data;
                    this.educount=this.userDataList[0].count
                    this.expcount=this.userDataList[1].count
                    this.skillscount=this.userDataList[2].count
                    this.coursecount=this.userDataList[3].count
                    this.certcount=this.userDataList[4].count
                    this.awardscount=this.userDataList[5].count
                    
                }
            })
        },
    }
})
</script>
<style scoped>
.alignment{
    margin-left: 20px;
    margin-bottom: 20px;
}
.cardcenter{
    text-align: center;
    color: white;
}
</style>
