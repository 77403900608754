<template>
<div class="app-content content">
      <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">

            <div class="card p-1 commonBgcolorOngoing">
                <div class="bg-light p-1 border-radius rounded-top">
                    <div class="row  px-0 pt-0 pb-0">
                        <div class="col-md-10">
                            <h4 class="text-primary"> Executive- Human Resource </h4>
                            <p class="mb-0"> HO,Pune,Maharashtra,India,(HO-BB)</p>
                        </div>
                        <div class="col-md-2">
                            <div class="demo-inline-spacing" style="justify-content: flex-end;">
                                <div class="btn-group mt-0">
                                    <button class="btn bg-transparent btn-flat-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <em data-feather='download-cloud' style="    width: 20px;height: 20px;"></em>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="javascript:void(0);">Resume</a>
                                        <a class="dropdown-item" href="javascript:void(0);">Resume1</a>
                                        <a class="dropdown-item" href="javascript:void(0);">Resume2</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   <div class="row py-1">
                       <div class="col-md-6">
                         <div class="design-planning job_details_right_border">
                            <p class="card-text mb-25">Department</p>
                            <h6 class="mb-0">Human Resource (EXE-HR(BB)</h6>
                         </div>
                       </div>
                       <div class="col-md-6">
                          <div class="design-planning ">
                            <p class="card-text mb-25">Employee Type</p>
                            <h6 class="mb-0"> Permanent</h6>
                         </div>
                       </div>
                    </div>
               </div>
                <section id="vertical-tabs">
                    <div class="row match-height">
                        <div class="col-lg-12">
                            <div class="card">
                                
                                <div class="card-body border border-top-0 p-0">
                                    <div class="nav-vertical candidates_details-hr">
                                        <div class="left-side">
                                        <ul class="nav nav-tabs nav-left flex-column w-100" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="biograpicalDetailstab1" data-toggle="tab" aria-controls="biograpicalDetails" href="#biograpicalDetails" role="tab" aria-selected="true">Biograpical</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="contact_candidates-tab2" data-toggle="tab" aria-controls="contact_candidates" href="#contact_candidates" role="tab" aria-selected="false">Contact</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="personalIdentity-tab3" data-toggle="tab" aria-controls="personalIdentity" href="#personalIdentity" role="tab" aria-selected="false"> Personal Identity
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="personalSkills-tab3" data-toggle="tab" aria-controls="personalSkills" href="#personalSkills" role="tab" aria-selected="false"> Skills
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                        <div class="tab-content candidates_right pl-2">
                                            <div class="tab-pane active" id="biograpicalDetails" role="tabpanel" aria-labelledby="biograpicalDetails-tab1">
                                                <div class="card-body" style="padding-top:5px;">
                                                  <div class="card-header px-0">
                                                    <h4 class="card-title">Biographical </h4>
                                                </div>
                                    <form class="form">
                                        <div class="row">
                                            <div class="col-md-6 col-12">
                                                <div class="form-group">
                                                    <label class="display-flex" for="first-name-column">First Name <span class="star_icon_t">* </span></label>
                                                    <input type="text" id="first-name-column" class="form-control" placeholder="First Name" name="fname-column" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div class="form-group">
                                                    <label class="display-flex" for="last-name-column">Last Name <span class="star_icon_t">* </span> </label>
                                                    <input type="text" id="last-name-column" class="form-control" placeholder="Last Name" name="lname-column" />
                                                </div>
                                            </div>
                                            <div class="col-12 px-1 text-right">
                                                <button type="reset" class="btn btn-relief-success">Update</button>
                                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                            </div>
                                            <div class="tab-pane" id="contact_candidates" role="tabpanel" aria-labelledby="contact_candidates-tab2">
                                                <div class="card-body" style="padding-top:5px;">
                                                  <div class="card-header px-0">
                                                    <h4 class="card-title">Contact </h4>
                                                </div>
                                    <form class="form">
                                        <div class="row">
                                            <div class="col-md-6 col-12">
                                                <div class="form-group">
                                                    <label class="display-flex" for="first-name-column">First Name <span class="star_icon_t">* </span></label>
                                                    <input type="text" id="first-name-column" class="form-control" placeholder="First Name" name="fname-column" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div class="form-group">
                                                    <label class="display-flex" for="last-name-column">Last Name <span class="star_icon_t">* </span> </label>
                                                    <input type="text" id="last-name-column" class="form-control" placeholder="Last Name" name="lname-column" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div class="form-group">
                                                    <label class="display-flex" for="last-name-column">Email ID <span class="star_icon_t">* </span> </label>
                                                    <input type="text" id="last-name-column" class="form-control" placeholder="Enter Email Address" name="lname-column" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div class="form-group">
                                                    <label class="display-flex" for="last-name-column">Contact No <span class="star_icon_t">* </span> </label>
                                                    <input type="text" id="last-name-column" class="form-control" placeholder="Last Name" name="lname-column" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div class="form-group">
                                                    <label class="display-flex" for="last-name-column">Contact No <span class="star_icon_t">* </span> </label>
                                                    <input type="text" id="last-name-column" class="form-control" placeholder="Last Name" name="lname-column" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div class="form-group">
                                                    <label class="display-flex" for="last-name-column">Contact No <span class="star_icon_t">* </span> </label>
                                                    <input type="text" id="last-name-column" class="form-control" placeholder="Last Name" name="lname-column" />
                                                </div>
                                            </div>
                                            <div class="col-12 px-1 text-right">
                                                <button type="reset" class="btn btn-relief-success">Update</button>
                                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                            </div>
                                            <div class="tab-pane" id="personalIdentity" role="tabpanel" aria-labelledby="personalIdentity-tab3">
                                                <div class="card-body" style="padding-top:5px;">
                                                  <div class="card-header px-0">
                                                    <h4 class="card-title">Personal Identity </h4>
                                                </div>
                                    <form class="form">
                                        <div class="row">
                                            <div class="col-md-6 col-12">
                                                <div class="form-group">
                                                    <label class="display-flex" for="first-name-column">First Name <span class="star_icon_t">* </span></label>
                                                    <input type="text" id="first-name-column" class="form-control" placeholder="First Name" name="fname-column" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div class="form-group">
                                                    <label class="display-flex" for="last-name-column">Last Name <span class="star_icon_t">* </span> </label>
                                                    <input type="text" id="last-name-column" class="form-control" placeholder="Last Name" name="lname-column" />
                                                </div>
                                            </div>
                                            <div class="col-12 px-1 text-right">
                                                <button type="reset" class="btn btn btn-relief-success">Update</button>
                                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                            </div>
                                            <div class="tab-pane" id="personalSkills" role="tabpanel" aria-labelledby="personalSkills-tab3">
                                                <div class="card-body" style="padding-top:5px;">
                                                  <div class="card-header px-0">
                                                    <h4 class="card-title">Skills </h4>
                                                </div>
                                    <form class="form">
                                        <div class="row">
                                            <div class="col-md-6 col-12">
                                                <div class="form-group">
                                                    <label class="display-flex" for="first-name-column">First Name <span class="star_icon_t">* </span></label>
                                                    <input type="text" id="first-name-column" class="form-control" placeholder="First Name" name="fname-column" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div class="form-group">
                                                    <label class="display-flex" for="last-name-column">Last Name <span class="star_icon_t">* </span> </label>
                                                    <input type="text" id="last-name-column" class="form-control" placeholder="Last Name" name="lname-column" />
                                                </div>
                                            </div>
                                            <div class="col-12 px-1 text-right">
                                                <button type="reset" class="btn btn-relief-success">Update</button>
                                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>
   </div>
</div>
   </div>
</template>
<script>
   
   import "vue-loading-overlay/dist/vue-loading.css";
   import DatePicker from "vue2-datepicker";
   import "vue2-datepicker/index.css";
   
   export default {
     name: "CandidatesDetail",
     components: {
         DatePicker
        
     },
     data() {
     return {
         defaultValue: new Date(),
         isLoading: false,
         fullPage: true,
     }
   }
   };
</script>