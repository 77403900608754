<template>
  <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left col-md-9 col-12 mb-1 mt-1">
          <div class="row breadcrumbs-top">
            <div class="col-12 px-0">
              <h3 class="content-header-title float-left mb-0">Ticket Details</h3>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'TicketList', params: { search: true }}">Ticket Request Management List</router-link></li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">View Ticket</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning" >
          <div v-bind:class="[{show: isRequestActiveView}, card_content,collapse]">
            <div class="card-header py-1" style="cursor:pointer">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-auto d-inline-flex align-items-center">
                    <div class="avatar bg-light-primary rounded d-inline-flex">
                      <div class="avatar-content">
                        <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                      </div>
                    </div>
                    <h3 class="card-title ml-2 d-inline-flex text-primary">TICKET CODE: {{this.view.issuecode}}</h3>
                  </div>
                  <div class="ml-auto text-right">
                    <!--  BA role-->
                    <div v-if="(btcissuebtnaccessarry.includes(parseInt(loginuscondition)) && editRowData.childid == null)" class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-2"  @click="redirectToIssueCreate('createTicketBTC')">
                      <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                      <span>Create BTC</span>
                    </div>
                    <div v-if="(btcissuebtnaccessarry.includes(parseInt(loginuscondition)) && editRowData.childid == null)" class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-2"  @click="redirectToIssueCreate('createTicketIssue')">
                      <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                      <span>Create Issue</span>
                    </div>

                    <div v-if="loginusername == view.createdby || pagefeatures.edit" class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-2"  @click="openEditModule(backpagedata,0)">
                      <edit-2-icon size="1.5x" class="custom-class avatar-icon mr-50"></edit-2-icon>
                      <span>Edit</span>
                    </div>
                    <div v-if="pagefeatures.logtime" class="btn btn-sm btn-outline-primary waves-effect waves-effect"  @click="openLogModule()">
                      <watch-icon size="1.5x" class="custom-class avatar-icon mr-50"></watch-icon>
                      <span>Log Time</span>
                  </div>

                  </div>
                </div>
              </div>
          </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                <div class=" card-transaction">
                    <div class="mb-2" >
                        <div class="transaction-item">
                        <div class="media">
                            <div class="avatar bg-light-success rounded">
                                <div class="avatar-content">
                                    <pen-tool-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pen-tool-icon>
                                </div>
                            </div>
                            <div class="media-body">
                                <h6 class="transaction-title">{{this.issue.issuename}}</h6>
                                <small>Created by {{this.view.createdby}}</small>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
              </div>
              </div>

              <div class="row match-height">
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                  <div class="">
                    <div class="transaction-item">
                      <div class="media">
 
                        <div v-if="this.issue.priority=='high'" class="avatar bg-light-danger rounded">
                          <div class="avatar-content">
                              <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                          </div>
                        </div>
                        <div v-else-if="this.issue.priority=='low'" class="avatar bg-light-info rounded">
                          <div class="avatar-content">
                              <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                          </div>
                        </div>
                        <div v-else-if="this.issue.priority=='medium'" class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                              <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                          </div>
                        </div>
                        <div v-else class="avatar bg-light-dark rounded">
                          <div class="avatar-content">
                              <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Priority</h6>
                          <small v-if="this.issue.priority=='high'" class="capitalisetext text-danger" style="font-weight:500">{{this.issue.priority}}</small>
                          <small v-else-if="this.issue.priority=='low'" class="capitalisetext text-info" style="font-weight:500">{{this.issue.priority}}</small>
                          <small v-else-if="this.issue.priority=='medium'" class="capitalisetext text-warning" style="font-weight:500">{{this.issue.priority}}</small>
                          <small v-else class="capitalisetext text-dark" style="font-weight:500">
                          {{this.issue.priority ? this.issue.priority: 'NA'}}</small> 
                           
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div v-if="this.view.sevairtyname=='Critical'" class="avatar bg-light-danger rounded">
                          <div class="avatar-content">
                              <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                          </div>
                        </div>
                        <div v-else-if="this.view.sevairtyname=='Major'" class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                              <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                          </div>
                        </div>
                        <div v-else-if="this.view.sevairtyname=='Minor'" class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                              <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                          </div>
                        </div>
                        <div v-else-if="this.view.sevairtyname=='Cosmetic'" class="avatar bg-light-success rounded">
                          <div class="avatar-content">
                              <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                          </div>
                        </div>
                        <div v-else-if="this.view.sevairtyname=='Moderate'" class="avatar bg-light-info rounded">
                          <div class="avatar-content">
                              <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                          </div>
                        </div>
                        <div v-else class="avatar bg-light-dark rounded">
                          <div class="avatar-content">
                              <em class="i_icon_st la-bug las font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Severity</h6>
                          <small v-if="this.view.sevairtyname=='Critical'" class="text-danger" style="font-weight:500">{{this.view.sevairtyname}}</small>
                          <small v-else-if="this.view.sevairtyname=='Major'" class="text-warning" style="font-weight:500">{{this.view.sevairtyname}}</small>
                          <small v-else-if="this.view.sevairtyname=='Minor'" class="text-primary" style="font-weight:500">{{this.view.sevairtyname}}</small>
                          <small v-else-if="this.view.sevairtyname=='Cosmetic'" class="text-success" style="font-weight:500">{{this.view.sevairtyname}}</small>
                          <small v-else-if="this.view.sevairtyname=='Moderate'" class="text-info" style="font-weight:500">{{this.view.sevairtyname}}</small>
                          <small v-else class="capitalisetext text-dark" style="font-weight:500">{{ this.view.sevairtyname ? this.view.sevairtyname: 'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div v-if="this.issue.status=='open'" class="avatar bg-light-warning danger rounded">
                          <div class="avatar-content">
                            <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                          </div>
                        </div>
                         <div v-else-if="this.issue.status=='resolved'" class="avatar bg-light-primary danger rounded">
                          <div class="avatar-content">
                            <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                          </div>
                        </div>
                         <div v-else-if="this.issue.status=='closed'" class="avatar bg-light-success danger rounded">
                          <div class="avatar-content">
                            <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                          </div>
                        </div>
                         <div v-else-if="this.issue.status=='reopen'" class="avatar bg-light-danger danger rounded">
                          <div class="avatar-content">
                            <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                          </div>
                        </div>
                        <div v-else-if="this.issue.status=='feedback'" class="avatar bg-light-secondary danger rounded">
                          <div class="avatar-content">
                            <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                          </div>
                        </div>
                        <div v-else class="avatar bg-light-secondary danger rounded">
                          <div class="avatar-content">
                            <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Status</h6>
                          <small v-if="this.issue.status=='open'" class="capitalisetext text-warning" style="font-weight:500">{{this.issue.status}}</small>
                          <small v-else-if="this.issue.status=='resolved'" class="capitalisetext text-primary" style="font-weight:500">{{this.issue.status}}</small>
                          <small v-else-if="this.issue.status=='closed'" class="capitalisetext text-success" style="font-weight:500">{{this.issue.status}}</small>
                          <small v-else-if="this.issue.status=='reopen'" class="capitalisetext text-danger" style="font-weight:500">{{this.issue.status}}</small>
                          <small v-else-if="this.issue.status=='feedback'" class="capitalisetext text-dark" style="font-weight:500">{{this.issue.status}}</small>
                          <small v-else class="capitalisetext text-dark" style="font-weight:500">{{this.issue.status}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-info rounded">
                          <div class="avatar-content">
                            <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Type</h6>
                          <small>{{this.view.typename}}</small>
                        </div>
                      </div>
                    </div>                   
                  </div>                                                     
                </div>
              </div>              
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                  <div class="">
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="las la-clipboard-list font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Micro Task</h6>
                          <small>{{this.view.taskname}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-danger rounded">
                            <div class="avatar-content">
                              <em class="las la-boxes font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 v-if="this.editRowData.projectid == merchandisingProId && this.issue.sectionmodulename == sectionmoduleId1" class="transaction-title">Product ID</h6>
                            <h6 v-else-if="this.editRowData.projectid == merchandisingProId && this.issue.sectionmodulename == sectionmoduleId2" class="transaction-title">Product Group ID</h6>
                            <h6 v-else class="transaction-title">Functionality</h6>
                            <small>{{this.view.functionlityname}}</small>
                          </div>
                        </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Project</h6>
                          <small class="text" style="cursor:pointer; color:Blue;" @click="redirecttoPNM('view', {moduleid:view.projectid})">{{this.view.projectname}}</small>
                        </div>
                      </div>
                    </div>
                    <div v-if="editRowData.projectid == merchandisingProId" class="transaction-item">
                    <div classs = "transaction-item" v-for="(data,index) in getMRPDetails" v-bind:key="index">
                      <div class="media">
                        <div class="avatar bg-light-success rounded">
                          <div class="avatar-content">
                            <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Brand</h6>
                          <small class="capitalisetext">{{data.projectmodulename?data.projectmodulename:'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div v-if="this.editRowData.projectid == merchandisingProId" class="transaction-item">
                      <div classs = "transaction-item" v-for="(data,index) in getMRPDetails" v-bind:key="index">
                        <div class="media">
                          <div class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                              <em class="i_icon_st la-hdd las lab font-medium-5"></em>
                            </div>
                          </div>
                        <div class="media-body">
                            <h6 class="transaction-title">Category</h6>
                            <small class="capitalisetext">{{data.applicationname?data.applicationname:'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div v-if="this.editRowData.projectid == merchandisingProId" class="transaction-item">
                      <div classs = "transaction-item" v-for="(data,index) in getMRPDetails" v-bind:key="index">
                        <div class="media">
                          <div class="avatar bg-light-info rounded">
                            <div class="avatar-content">
                              <em class="i_icon_st la-buffer lab font-medium-5" style="font-weight: 500;"></em>
                            </div>
                          </div>
                        <div class="media-body">
                            <h6 class="transaction-title">Sub Category</h6>
                            <small class="capitalisetext">{{data.applicationmodulename?data.applicationmodulename:'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div v-if="this.editRowData.projectid == merchandisingProId" class="transaction-item">
                      <div classs = "transaction-item" v-for="(data,index) in getMRPDetails" v-bind:key="index">
                        <div class="media">
                          <div class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                              <em class="las la-box font-medium-5"></em>
                            </div>
                          </div>
                        <div class="media-body">
                            <h6 class="transaction-title">Product ID/ Product Group ID</h6>
                            <small>{{data.sectionname?data.sectionname:'NA'}}</small>
                        </div>
                        </div>
                        </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <em class="las la-sync-alt font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">QA Cycle</h6>
                          <small>{{this.view.qacycle}}</small>
                        </div>
                      </div>
                    </div>
                    <div  class="transaction-item" v-if="this.issue.project != merchandisingProId">
                      <div classs = "transaction-item" v-for="(data,index) in getMRPDetails" v-bind:key="index">
                        <div class="media">
                          <div class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                              <em class="las la-box font-medium-5"></em>
                            </div>
                          </div>
                        <div class="media-body">
                            <h6 class="transaction-title">Ticket Category</h6>
                            <small>{{data.projectmodulename?data.projectmodulename:'NA'}}</small>
                        </div>
                        </div>
                        </div>
                    </div>
                    <div  class="transaction-item" v-if="this.issue.project != merchandisingProId">
                      <div classs = "transaction-item" v-for="(data,index) in getMRPDetails" v-bind:key="index">
                        <div class="media">
                          <div class="avatar bg-light-info rounded">
                            <div class="avatar-content">
                              <em class="i_icon_st la-buffer lab font-medium-5" style="font-weight: 500;"></em>
                            </div>
                          </div>
                        <div class="media-body">
                            <h6 class="transaction-title">Ticket Sub Category</h6>
                            <small class="capitalisetext">{{data.applicationmodulename?data.applicationmodulename:'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>                                                                
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                  <div class="">
                    <div v-if="this.editRowData.projectid == merchandisingProId" class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Assigned To</h6>
                          <small class="capitalisetext">{{this.view.empname}}[{{decryptText(this.view.department)}}]</small>
                        </div>
                      </div>
                    </div>
                    <div v-else class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Assigned To</h6>
                          <small class="capitalisetext">{{this.view.empfullname}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Created By</h6>
                          <small>{{this.view.createdby}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Created Date</h6>
                          <small>{{dateMonthFormat(this.view.createddate)}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Last Modified By</h6>
                          <small>{{this.view.lastmodifiedby}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Last Modified Date</h6>
                          <small>{{dateMonthFormat(this.view.lastmodifieddate)}}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
               <div class="col-12 px-0">
                    <div v-if="this.queanslist && this.queanslist.length > 0">
                    <div class="card pb-0 mb-0 pt-0">
                      <div class="container px-0">
                              
                                  <div class="card-body pb-0">
                                      <form>
                                          <div class="row">
                                              <div class="col-md-12 px-0">
                                                  <div class="mb-0">
                                                      
                                                          <div aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="card-transaction">
                                                              
                                                              <div class="row  ">
                                                                  <div class="media col-sm-12 " v-for="(v, i) in  queanslist" style="margin-bottom: 1.5rem;">
                                                                    <div class="transaction-item" style="overflow: auto; align-items: start;">
                                                                      <div class="avatar bg-light-warning rounded" >
                                                                        <div class="avatar-content">
                                                                          <em class="las la-clipboard-list font-medium-5"></em>
                                                                        </div>
                                                                          
                                                                      </div>
                                                                      <div class="avatar-content ticket_dynamic_title03 ">
                                                                        <h6 class="transaction-title">  {{v.fieldname}} </h6>
                                                                          <div class="media-body tast_case_groupingview_readmore">
                                                                              <div class="custom-container case_bg_17 micro_case_groupingview_readmore">
                                                                                  <div v-bind:class="[readmore]" :id="'cycle_readmore'+i">
                                                                                      <span class="fc_m16_btcode_text" v-html="formatData(v.fieldvalue)"></span>
                                                                                  </div>
                                                                                  <div class="cycle_viewmoretext" v-if="v.fieldvalue && v.fieldvalue.length > 150">
                                                                                      <a class="btn">
                                                                                      <strong :id="i" @click="idclick(i)">
                                                                                          Read More
                                                                                      </strong>
                                                                                      </a>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </form>
                                  </div>
                      </div>
                    </div>
                </div>
                </div>

            </div>

              <div class="container mb-1">
                <div class="row media-list mx-0">
                        <div class="col-sm-6">
                          <div class="media business_dis_rows business_dis_rowsbg bg_bt2 mb-1">
                          <div class="media-left ">
                            <div class="avatar bg-light-primary rounded avatar-lg ">
                            <div class="avatar-content">
                              <package-icon size="1.5x" class="custom-class  avatar-icon font-medium-3 text-white" ></package-icon>
                            </div>
                            </div>
                          </div>

                          <div class="media-body data_dynamic_discription_ids">
                            <h6 class="transaction-title text-white">Reason of ticket	</h6>
                            <span class="disc text-white" v-html="this.issue.reasonofissue"></span>
                          </div>
                          </div>
                        </div>

                        <div class="col-sm-6">
                        <div class="media business_dis_rows mb-1 business_dis_rowsbg bg_bt4">
                          <div class="media-left">
                            <div class="avatar bg-light-primary rounded avatar-lg">
                              <div class="avatar-content">
                                <package-icon size="1.5x" class="custom-class  avatar-icon font-medium-3 text-white"></package-icon>
                              </div>
                            </div>
                          </div>
                          <div class="media-body data_dynamic_discription_ids">
                            <h6 class="transaction-title text-white">Impact of ticket	</h6>
                            <span class="disc text-white" v-html="this.issue.impactofissue"></span>
                          </div>
                          </div>
                      </div>

                      <div class="col-sm-12">
                      <div class="card-transaction business_dis_rows mb-1 business_dis_rows_disc border">
                        <div class="transaction-item px-1 pb-1" style="overflow: auto;align-items: start;">
                        <div class="media-left">
                          <div class="avatar bg-light-success rounded">
                            <div class="avatar-content">
                              <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                            </div>
                          </div>
                        </div>
                        <div class="media-body data_dynamic_discription_ids">
                          <h6 class="transaction-title">Ticket Scenario</h6>
                          <!-- <span class="disc" v-html="this.issue.scenario"></span> -->
                          <div class=" tast_case_groupingview_readmore mx-0">
                              <div class="col-md-12 px-0 micro_case_groupingview_readmore">
                                  <div class="custom-container case_bg_17">
                                      <div v-if="this.issue.scenario" v-bind:class="[readmore]" :id="'cycle_readmore8'">
                                          <span class="fc_m16_btcode_text" v-html="formatData(this.issue.scenario)"></span>
                                          
                                      </div>
                                      
                                      <div class="cycle_viewmoretext" v-if="this.issue.scenario && this.issue.scenario.length > 50">
                                          <a class="btn">
                                          <strong :id="'id8'" @click="idclick8()">
                                            Read More
                                        </strong>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                        </div>
                      </div>
                      </div>



                      </div>

              </div>
              
              <div v-if="issue.history" class="col-lg-12 col-md-6 col-12" style="display:none;">
                  <div class="card-body">
                    <h5 class="mb-1">History</h5>
                    <div class="row tbl">
                      <div class="table-responsive mb-1">
                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                          <thead>
                            <tr>
                              <th scope="col">SR#</th>
                              <th scope="col">Status</th>
                              <th scope="col">Assigned To</th>
                              <th scope="col">Action Date</th>
                              <th scope="col">Assigned BY</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data,index) in (issue.history ? issue.history:[])" v-bind:key="index">
                              <td>{{index+1}}</td>
                              <td>{{data.status}}</td>
                              <td>{{data.assignedto}}</td>
                              <td>{{dateMonthFormat(data.lastmodifieddate)}}</td>
                              <td>{{data.lastmodifiedby?data.lastmodifiedby.split("@")[0]:''}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
              </div>

             
          </div>
        </div>
        

            
                  <div v-if="issue.attachmentview">
                    <div class="card">
                        <div class="card-header px-2 py-1 border-bottom">
                            <h4 class="card-title">Document List</h4>
                        </div>
                        <div class="card-body pb-0">
                            <div class="row">
                        <div class="col-md-12">
                            <div class="row documentGrid match-height">
                                <div class="col-xl-2 col-md-4 col-sm-6 my-1" v-for="(data,index) in (this.issue.attachmentview ? this.issue.attachmentview.data:[])" v-bind:key="index">
                                    <div class="card card-bordered mb-50">
                                        <div class="text-center py-2" style="background:#F8F8F8;">
                                          <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                                          <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                                          <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                                          <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                                          <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                                          <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xls' || 'xlxs'" src="../../../public/images/icons/ic-xls.png" height="35" />
                                          <a :href="data.presignurl" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                          <a v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(data.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                                          <a v-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" target="_blank" v-on:click="previewpdf(data.presignurl)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop" title="Click to Preview"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>  
                                        </div>
                                          <a :href="data.presignurl" class="py-50 px-50 border-top " style="font-size:0.85rem; color:#888;"  target="_blank">{{data.link | basename}}</a>
                                      </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                  </div>

                  <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content">              
                        <div class="modal-body p-0">
                          <button type="button" class="close imageModalClose" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                          <img alt="" src="" class="imagepreview"  >
                        </div>
                      </div>
                    </div>
                  </div>

        <div class="customTimeline">
          <div class="card " v-if="logtimehistory && logtimehistory.length > 0">
            <div class="card-body mb-2">
              <ul class="timeline">
                <li class="timeline-item" v-for="(data,index) in logtimehistory" v-bind:key="index">
                  <span v-bind:class="{'timeline-point timeline-point-warning timeline-point-indicator':data.status=='reopen', 'timeline-point timeline-point-secondary timeline-point-indicator':data.status=='closed', 'timeline-point timeline-point-danger timeline-point-indicator':data.status=='open', 'timeline-point timeline-point-success timeline-point-indicator':data.status=='resolved'}"></span>
                  
                  <div class="timeline-event">
                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                      <p class="text-muted" style="margin-top:-3px">Updated on: {{dateMonthFormat(data.lastmodifieddate)}} 
                        <span class="capitalisetext badge badge-pill badge-light-primary mx-50">{{data.status}}</span>
                        <span class="capitalisetext badge badge-pill badge-light-primary mx-50">{{data.activity}}</span></p>
                    </div>
                    <div class="d-flex">
                      <div class="media align-items-center my-50">
                        <span class="pr-1 text-muted  d-inline-flex">
                        
                              <user-plus-icon size="1.5x" class="custom-class avatar-icon font-medium-3 mr-50"></user-plus-icon> 
                         To</span>
                        <div class="avatar">
                          <profilepic v-if="data.assignedtoimagename" v-bind:get-url="'empprofile/'+data.assigntoempcode+'/'+data.assignedtoimagename" :key="`${componentKey + index}`"></profilepic>
                          <img alt="" v-if="!data.assignedtoimagename" :src="defaultimagepath" width="40" height="40" />
                        </div>
                        <div class="media-body ml-50 text-trasform_capitalise">
                          <h6 class="mb-0 text-nowrap">{{data.assigend}}</h6>
                          <span class="text-muted text-nowrap">{{data.assignedtodesignatio}}</span>
                        </div>
                      </div>
                    </div>

                    <p v-if="data.coments"><span v-html="data.coments"></span></p>
                    <div class="row">
                    <div class="col-xl-2 col-md-4 col-sm-6 my-1" v-for="(res,index) in (data.attachment ? data.attachment.data:[])" v-bind:key="index">
                      <div class="card card-bordered mb-0">
                          <div class="text-center py-2" style="background:#F8F8F8;">
                            <img alt="" v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'xls' || 'xlxs'" src="../../../public/images/icons/ic-xls.png" height="35" />
                            <a v-on:click="getLogtimeDownloadLink(res.link)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                            <a v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(res.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                          </div>
                             <a :href="res.link"  class="py-50 px-50 border-top " style="font-size:0.85rem; color:#888;"  target="_blank">
                              <span v-if="res.info">
                                {{res.info}} -123
                              </span>
                              <span v-else-if="!res.info">
                                {{res.link | basename}}
                              </span>
                              </a>
                             </div>
                      </div>
                      </div>
                  <div class="d-flex flex-sm-row flex-column mt-50 mb-2 pt-50 arrow_box">
                      <div class="media align-items-center">
                         <span class="pr-1 text-muted ">
                           
                             <edit-2-icon size="1.5x" class="custom-class avatar-icon mr-25"></edit-2-icon>
                            By</span>
                          <div class="avatar ">
                             <profilepic v-if="data.imagename" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic>
                              <img alt="" v-if="!data.imagename" :src="defaultimagepath"  width="40" height="40" />
                          </div>
                          <div class="media-body ml-50 text-trasform_capitalise">
                            <h6 class="mb-0 text-nowrap"> {{(data.createdby)}}</h6>
                            <span class="text-muted text-nowrap">{{data.designation}}</span>
                            </div>
                      </div>
                  </div>

                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div>&nbsp;</div>
        </div>
       
        <div v-bind:class="[{display:dislogActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div class="card-header" style="cursor:pointer">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-9">
                  <div class="avatar bg-light-primary rounded d-inline-flex mr-2" >
                    <div class="avatar-content">
                        <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                    </div>
                  </div>
                  <h4 class="card-title text-primary d-inline-flex">Update Log Time</h4>
                </div>
              </div>
            </div>
          </div>
          <div v-bind:class="[{show: isLogActive},card_content,collapse]" >
            <div class="card-body">
              <form class="form form-vertical">
                <div class="row">
                  <div class="form-group col-md-3">
                      <div class="form-group mg-bot0">
                      <label class="form-label" for="spendtime">Spent Time</label>
                      <div id="spendtime">
                      <vue-timepicker type="time" v-model="logtime.spenttime" format="HH:mm"  @close="validatelog()"></vue-timepicker>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.spenttime">{{ this.errors.spenttime }}</div>
                      </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group mg-bot0">
                      <label class="form-label">Activity</label>
                      <treeselect placeholder="Select Activity"  class="projectmdllistdd capitalisetext" v-model="logtime.activity" :options="logtime.acivitylist" @input="selissuelogactivity" @close="validatelog()"/>
                      <div class="errorinputmsg" v-if="this.errors.activity">{{ this.errors.activity }}</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group mg-bot0">
                        <label class="form-label">Assigned To</label>
                        <treeselect placeholder="Select Enter min 4 Chars to search Assigned To" class="allotedto capitalisetext" v-model="logtime.allotedto" :options="issue.allotedtolist" @input="selallotedto" @close="validatelog()" @search-change="getEmpallocatedtoProject"/>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.allotedto">{{ this.errors.allotedto }}</div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group mg-bot0">
                        <label class="form-label">Status</label>
                        <treeselect placeholder="Select Status" class="projectmdllistdd capitalisetext" v-model="logtime.status" :options="issue.statuslist" @input="selissuecsstatus" @close="validatelog()"/>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.status">{{ this.errors.status }}</div>
                  </div>
                  <div class="col-12">
                    <div class="form-group mg-bot0">
                      <label for="reasonofissue">Comment</label>
                      <ckeditor :editor="editor" :config="editorConfig" id="reasonofissue" class="form-control" name="" placeholder="Enter Comments" v-model="logtime.comment" 
                        v-bind:class="{ 
                          'form-control': true,
                          'is-invalid': !validText(logtime.comment) && commentBlured, 
                        }" v-on:blur="commentBlured = true"/>
                        <div class="invalid-feedback">Comment required</div>
                    </div>
                  </div>
                  <div class="col-md-12" style="margin-top:24px">
                    <div class="form-group">
                      <div class="form-group">
                        <label for="attachment" class="btn-sm btn btn-relief-secondary mb-75 mr-75">Select image/docs/videos</label>
                        <div class="table-responsive" v-if="file && file.length>0">
                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col">SR#</th>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(image, key) in file" :key="key">
                                        <td>{{ key+1 }}</td>
                                        <td>{{ image.name }}</td>
                                        <td>
                                            <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="issue.filedescp[key]"/>
                                        </td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <button class="btn btn-relief-secondary btn-sm mb-75 mr-75 "  v-on:click.stop.prevent="onUpload()" >Upload</button>
                        <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mg-top1 text-right">
                    <button type="button" class="btn btn-relief-primary mr-1" @click="savelogData()" v-bind:disabled="issue.disblesavebtn">Submit</button>
                    <button type="reset" class="btn btn-outline-secondary" @click="handleCancel2()">Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div v-bind:class="[{display:disRequestActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div class="card-header" style="cursor:pointer" v-on:click="myrequestollapse">
            <div class="container-fluid">
              <div class="row">
                  <div class="col-9">
                    <div class="avatar bg-light-primary rounded d-inline-flex" >
                          <div class="avatar-content">
                              <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                          </div>
                      </div>
                      <h4 class="card-title d-inline-flex text-primary ml-1">{{this.formActionLabel}}</h4>
                  </div>
              </div>
              </div>
          </div>
          <div v-bind:class="[{show: isRequestActive},card_content,collapse]" >
              <div class="card-body">
                <form class="form form-vertical">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group mg-bot0">
                        <label for="projectlistdd">Ticket Name</label>
                        <input type="text" id="issuename" class="form-control" name="" placeholder="Ticket Name" v-model="issue.issuename" @blur="validateForm('t42',$event)"/>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.issue.issuename">{{ this.errors.issue.issuename }}</div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mg-bot0">
                        <label for="projectmdllistdd">Project</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="issue.project" :options="issue.projectlist" @select="selissuecsproject" @close="validateForm('t39',$event)"/>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.issue.project">{{ this.errors.issue.project }}</div>
                    </div>
                    <div class="col-md-6" v-if="this.issue.project == merchandisingProId">
                      <div class="form-group mb-1">
                          <label for="projectmdllistdd">Brand</label>
                          <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Brand" v-model="issue.projectmodulename" :options="issue.projectmodulelist" @input="selProjectModule" @close="validateForm('t39', $event)"/>
                          <div class="errorinputmsg" v-if="this.errors.issue.projectmodulename">{{ this.errors.issue.projectmodulename }}</div>
                      </div>
                      </div>
                      <div class="col-md-6" v-if="this.issue.project == merchandisingProId">
                      <div class="form-group mb-1">
                          <label for="projectmdllistdd">Category</label>
                          <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Category" v-model="issue.applicationname" :options="issue.applicationlist" @input="selApplication" @close="validateForm('t39', $event)"/>
                          <div class="errorinputmsg" v-if="this.errors.issue.applicationname">{{ this.errors.issue.applicationname }}</div>
                      </div>
                      </div>
                      <div class="col-md-6" v-if="this.issue.project == merchandisingProId">
                      <div class="form-group mb-1">
                          <label for="projectmdllistdd">Sub Category</label>
                          <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Sub category" v-model="issue.applicationmodulename" :options="issue.applicationmodulelist" @input="selApplicationModule" @close="validateForm('t39', $event)"/>
                          <div class="errorinputmsg" v-if="this.errors.issue.applicationmodulename">{{ this.errors.issue.applicationmodulename }}</div>
                      </div>
                      </div>
                      <div class="col-md-6" v-if="this.issue.project == merchandisingProId">
                      <div class="form-group mb-1">
                          <label for="projectmdllistdd">Product ID/ Product Group ID</label>
                          <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Product ID/ Product Group ID" v-model="issue.sectionmodulename" :options="issue.sectionmodulelist" @input="selSectionModule" @close="validateForm('t39', $event)"/>
                          <div class="errorinputmsg" v-if="this.errors.issue.sectionmodulename">{{ this.errors.issue.sectionmodulename }}</div>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="this.issue.project == merchandisingProId  && issue.sectionmodulename">                    
                    <div class="form-group mb-1">
                        <label for="projectmdllistdd">
                          <small>{{productlabelname}}</small>
                        </label>
                        <input type="text" id="issuenameadd" class="form-control" name=""  :placeholder="productlabelname" v-model="issue.issuenameaddnew" />
                        <div class="errorinputmsg" v-if="this.errors.issue.issuenameaddnew">{{ this.productlabelname }} is required.</div>
                    </div>
                    </div>

                    <div class="col-md-6" v-if="(this.issue.project && this.issue.project != merchandisingProId) || this.companycode =='xb'">
                      <div class="form-group mb-1">
                        <label for="projectmdllistdd">Ticket Category</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Ticket Category" v-model="issue.projectmodulename" :options="issue.projectmodulelist" @input="selProType" @close="validateForm('t39', $event)"/>
                        <div class="errorinputmsg" v-if="this.errors.issue.projectmodulename">{{ this.errors.issue.projectmodulename }}</div>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="(this.issue.project && this.issue.project != merchandisingProId) || this.companycode =='xb'">
                      <div class="form-group mb-1">
                        <label for="projectmdllistdd">Ticket Sub Category</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Ticket Sub Category" v-model="issue.applicationmodulename" :options="issue.applicationmodulelist" @input="selSubType" @close="validateForm('t39', $event)"/>
                        <div class="errorinputmsg" v-if="this.errors.issue.applicationmodulename">{{ this.errors.issue.applicationmodulename }}</div>
                      </div>
                    </div>
                    
                    <div class="col-md-6" id="disabledfield">                    
                        <div class="form-group mb-1">
                          <label for="projectmdllistdd">
                            <small v-if="this.issue.project == merchandisingProId && issue.sectionmodulename== sectionmoduleId1">Product ID</small>
                            <small v-else-if="this.issue.project == merchandisingProId && issue.sectionmodulename== sectionmoduleId2">Product Group ID</small>
                            <small v-else> Functionality </small></label>
                          <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select functionality name" v-model="issue.functionality" :options="issue.functionalitylist" @input="selissuecsfunctionality" @close="validateForm('t42', $event)"/>
                        </div>
                    </div>

                    <div class="col-md-6" v-if="!this.isThisBusinessRole">
                        <div class="form-group mg-bot0">
                            <label for="projectmdllistdd">Micro Task</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select micro task name" v-model="issue.task" :options="issue.tasklist" @close="validateForm('t42',$event)"/>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mg-bot0">
                            <label for="projectmdllistdd">Type</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select ticket type" v-model="issue.type" :options="issue.mrtypelist" @input="selissuecstype" @close="validateForm('t42',$event)"/>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.type">{{ this.errors.issue.type }}</div>
                    </div>
                    <div class="col-md-12 mt-2 px-0">
                            
                                <div class="row mx-0">
                                    <div class="col-md-4" v-for="(fieldname,i) in this.fieldnamelist" v-bind:key="i" v-if="fieldname.fieldtype !='hidden'">
                                        
                                            <label for="projectlistdd" class="required-field" v-if="fieldname.validationtype=='required' && fieldname.fieldtype !='hidden'">{{fieldname.label}}:</label>
                                            <label for="projectlistdd" v-else-if="fieldname.fieldtype !='hidden'">{{fieldname.label}}:</label>
                                            <span v-if="fieldname.fieldtype=='text' && fieldname.fieldtype !='hidden'">
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @blur="validateConfigForm($event)"
                                                @input="dynamicroster"
                                                v-bind:class="{ 
                                                'form-control': true,
                                                '': !validText(fieldsnamevalues[fieldname.id])}"
                                                @close="validateConfigForm($event)"
                                                v-if="(fieldname.validationtype=='readonly')" disabled/>

                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @blur="validateConfigForm($event)"
                                                @input="dynamicroster"
                                                v-bind:class="{ 
                                                'form-control': true,
                                                '': !validText(fieldsnamevalues[fieldname.id])}"
                                                @close="validateConfigForm($event)"
                                                v-else/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span>
                                                </p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='number' && fieldname.fieldtype !='hidden'">
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @input="dynamicroster"
                                                v-bind:class="{
                                                'form-control': true,
                                                '': !validText2(fieldsnamevalues[fieldname.id])}"
                                                v-if="(fieldname.validationtype=='readonly')" disabled/>
                                                
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @input="dynamicroster"
                                                v-bind:class="{
                                                'form-control': true,
                                                '': !validText2(fieldsnamevalues[fieldname.id])}"
                                                
                                                v-else/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText2(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='drop-down' && fieldname.fieldtype !='hidden'">
                                               <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalu1" @input="dynamicroster"/>
                                               <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='multiselect-dropdown' && fieldname.fieldtype !='hidden'">
                                                <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue2" :multiple="true" :clear-on-select="true" @input="dynamicroster"/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && (!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='checkbox' && fieldname.fieldtype !='hidden'">
                                                <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue" :multiple="true" :clear-on-select="true" @input="dynamicroster"/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && (!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-else-if="fieldname.fieldtype=='radio'">
                                                <span v-for="(radiofieldvalues,k) in fieldname.radiooptionalvalue" v-bind:key="k"><br />
                                                    <div class=" form-check form-check-inline mt-0 mb-0">
                                                        <input class="form-check-input" type="radio" v-model="fieldsnamevalues[fieldname.id]" :value="radiofieldvalues.id"  @change="changevalue(fieldname.id, radiofieldvalues.id)" @input="dynamicroster"> <span class="pl-1" > {{Object.values(radiofieldvalues)[0]}} </span>
                                                    </div>
                                                </span>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-else-if="fieldname.fieldtype=='date' && fieldname.fieldtype !='hidden'">
                                                <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                                class="startdate capitalisetext" id="EndDate" :minute-step="5" v-if="(fieldname.validationtype=='readonly')" @input="dynamicroster" disabled></date-picker>
                                                <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                                class="startdate capitalisetext" :id="'EndDate'+i" @change="changevalue(fieldname.id, fieldsnamevalues[fieldname.id])" :minute-step="5" @input="dynamicroster" v-else></date-picker>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-else-if="fieldname.fieldtype=='datetime-local' && fieldname.fieldtype !='hidden'">
                                                <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-if="(fieldname.validationtype=='readonly')" disabled></vue-timepicker>
                                                <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-else></vue-timepicker>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='toggle' && fieldname.fieldtype !='hidden'">
                                                <div class="custom-control custom-switch custom-switch-secondary custom-switch-width-01">
                                                    <input type="checkbox" class="custom-control-input" :id="'customSwitch11'+fieldname.id" v-model="fieldsnamevalues[fieldname.id]" @input="dynamicroster"/>
                                                    <label class="custom-control-label" :for="'customSwitch11'+fieldname.id">
                                                        <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[0].label}}</label></span>
                                                        <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[1].label}}</label></span>
                                                    </label>
                                                </div>
                                            </span>
                                            <span v-if="fieldname.fieldtype && fieldname.fieldtype =='hidden'" style="display:none;">
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" v-model="fieldsnamevalues[fieldname.id]"/>
                                            </span>
                                    </div>
                                    
                                </div>
                          </div>
                    <div class="col-md-3">
                        <div class="form-group mg-bot0">
                            <label for="projectmdllistdd">Severity</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select ticket severity" v-model="issue.sevairty" :options="issue.sevairtylist" @input="selissuecssevairty" @close="validateForm('t42',$event)"/>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.sevairty">{{ this.errors.issue.sevairty }}</div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group mg-bot0">
                            <label for="projectmdllistdd">Priority
                              <span class="popover-block-container-custom">
                                <span class="popover-block-container-custom">
                                  <button type="button" class="popover_top_note" data-toggle="modal" data-target="#ticketcreatepopover">
                                    <em class="las la-info-circle text-warning font-medium-5"></em>
                                  </button>
                                    <div class="modal fade" id="ticketcreatepopover" tabindex="-1" role="dialog" aria-labelledby="ticketcreatepopovertext" aria-hidden="true">
                                          <div class="modal-dialog modal-dialog-centered" role="document">
                                              <div class="modal-content">
                                                  <div class="modal-header ticketcreatepopoverbg">
                                                      <h5 class="modal-title" id="ticketcreatepopovertext">Note</h5>
                                                      </div>
                                                  <div class="modal-body">
                                                    <ul>
                                                      <li v-for="priority in priorityDefination" :key="priority.id">
                                                        {{ priority.label }}
                                                      </li>
                                                    </ul>
                                                </div>
                                              </div>
                                          </div>
                                      </div>
                                </span>
                              </span>
                            </label>
                            <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select ticket priority"  v-model="issue.priority" :options="issue.prioritylist" @input="selissuecspriority" @close="validateForm('t42',$event)"/>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.priority">{{ this.errors.issue.priority }}</div>
                        <div v-for="priority in priorityDefination" :key="priority.id" class="informative-msg" v-if="issue.priority === priority.id">
                          {{ priority.label }}
                        </div>
                    </div>
                    <div class="col-md-3">
                      <div v-if="(this.issue.projectmodulename && this.issue.project == merchandisingProId)">
                        <div class="form-group mb-1">
                          <label for="allotedto">Assigned To</label>
                          <treeselect class="allotedto capitalisetext"  placeholder="Select assigned to" v-model="issue.allotedto" :options="brandAllocateEmp" @input="selallotedto" @close="validateForm('t42', $event)"/>
                          <div class="errorinputmsg" v-if="this.errors.issue.allotedto"> {{ this.errors.issue.allotedto }}</div>
                        </div>
                      </div>

                      <div v-else>
                        <div class="form-group mb-1">
                          <label for="allotedto">Assigned To</label>
                          <treeselect class="allotedto capitalisetext"  placeholder="Enter min 4char to search assigne" v-model="issue.allotedto" :options="issue.allotedtolist" @input="selallotedto" @close="validateForm('t42', $event)" v-on:search-change="getEmpallocatedtoProject"/>
                          <div class="errorinputmsg" v-if="this.errors.issue.allotedto"> {{ this.errors.issue.allotedto }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group mg-bot0">
                            <label for="">Subscribers</label>
                            <treeselect class="projroleassprojectdd" placeholder="Enter min 4char to search subscriber" :multiple="true" :clear-on-select="true" :options="issue.subscriberslist" v-model="issue.subscribers" @input="selSubscribers" v-on:close="validateForm('t18', $event)" v-on:search-change="getEmpallocatedtoProjectForSubscriber"/>
                        </div>
                           <div class="errorinputmsg" v-if="this.errors.issue.subscribers">{{ this.errors.issue.subscribers }}</div>
                    </div>
                    <div class="form-group col-12">
                        <label class="form-label" for="projectmdllistdd">Scenario</label>
                        <ckeditor :editor="editor"  placeholder="Ticket Scenario" v-model="issue.scenario" :config="editorConfig" tag-name="textarea" @blur="validateForm('t42',$event)"></ckeditor>
                        <div class="errorinputmsg" v-if="this.errors.issue.scenario">{{ this.errors.issue.scenario }}</div>
                    </div>
      
                      <div class="col-md-6">
                        <div class="form-group mg-bot0">
                            <label for="reasonofissue">Reason of ticket</label>
                            <ckeditor :editor="editor" id="reasonofissue" class="form-control" name="" placeholder="Reason of ticket" v-model="issue.reasonofissue" 
                            :config="editorConfig" tag-name="textarea" @blur="validateForm('t42',$event)"/>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.reasonofissue">{{ this.errors.issue.reasonofissue }}</div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mg-bot0">
                            <label for="impactofissue">Impact of ticket</label>
                            <ckeditor :editor="editor" id="impactofissue" class="form-control" name="" placeholder="Impact of ticket" v-model="issue.impactofissue" 
                            :config="editorConfig" tag-name="textarea" @blur="validateForm('t42',$event)"/>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.impactofissue">{{ this.errors.issue.impactofissue }}</div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mg-bot0">
                            <label for="projectmdllistdd">Status</label>
                            <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select ticket status"  v-model="issue.status" :options="issue.statuslist" @input="selissuecsstatus" @close="validateForm('t42',$event)"/>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.status">{{ this.errors.issue.status }}</div>
                    </div>
                    <div class="col-md-12">
                        <label for="attachment" class="btn btn-sm btn btn-relief-secondary mb-75 mr-75">Select image/docs/videos</label>
                        <button class="btn btn-sm btn-relief-secondary mb-75 mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                        <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                        <div class="table-responsive" v-if="issue.attachmentview">
                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col">SR#</th>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data, k) in (issue.attachmentview ? issue.attachmentview.data:[])" :key="k">
                                        <td>{{k+1}}</td>
                                        <td>
                                          <label>
                                    <div class="click-zoom">
                                      <input type="checkbox" />
                                          <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  :src="data.presignurl"  style="width:50px;height:50px"  />
                                          <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/pdf.png" style="width:50px;height:50px" />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xlsx'||'csv'||'xls'" src="../../../public/images/icons/xls.png" style="width:50px;height:50px" />
                             &nbsp;
                                             <a :href="data.presignurl" target="_blank">{{data.link | basename}}</a>
                                    </div>
                                          </label>
                                        </td>
                                        <td>{{data.info}}</td>
                                        <td>
                                            <button type="button" class="btn btn-sm danger" @click="removes3file(k,issue.attachmentview.data)">
                                              <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.attachment">{{ this.errors.issue.attachment }}</div>
                        <div class="table-responsive" v-if="file &&  file.length>0">
                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col">SR#</th>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(image, key) in file" :key="key">
                                        <td>{{ key+1 }}</td>
                                        <td>{{ image.name }}</td>
                                        <td>
                                            <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="issue.filedescp[key]"/>
                                        </td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                    <div class="col-12 mg-top1 text-right">
                        <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard('t42',$event)" id="submitbtnt42" v-bind:disabled="issue.disblesavebtn">Submit</button>
                        <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>

.issue-view-title {
  background-color: #f8f9fa;
  padding: 6px 24px;
  font-weight: bold;
  margin-right: 20px;
}
.card .card-header{
  padding: 0.8rem;
}
.createbtnparentdiv{
  padding-right: 0% !important;
}
.displayblk{
  display:block !important;
}
.createbtn{
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}
.tbl {
  margin-left: 0 !important;
}

.disc p:last-child{
  margin-bottom: 0;
}
.informative-msg {
    color: #17a2b8;
    padding: 10px 0px;
    border-radius: 5px;
    font-size: 12px;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import { PaperclipIcon,WatchIcon,EditIcon, MinusSquareIcon, Trash2Icon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon, UploadCloudIcon,CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,PenToolIcon, AlertCircleIcon,UserPlusIcon,ZoomInIcon,XIcon,PieChartIcon} from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import presignurl from '../getPresignurl.vue';
import profilepic from '../profilepic.vue';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import commonMethods from '../../utils/commonMethods';

export default {
  name:'IssueList',
  mixins: [ commonMethods ],
  components:{
    VueTimepicker,
    Loading,WatchIcon,PaperclipIcon,profilepic,
    VueElementLoading,
    vSelect,
    EditIcon,
    EyeIcon,
    Trash2Icon,
    Treeselect,
    SearchIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    PlusIcon,
    DownloadIcon,
    Edit2Icon,
    PlusSquareIcon,
    ClipboardIcon,
    UploadCloudIcon, MinusSquareIcon, CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,PenToolIcon,
    AlertCircleIcon,
    UserPlusIcon,
    ZoomInIcon,
    XIcon,
    PieChartIcon,
    ckeditor: CKEditor.component,
    presignurl,
    DatePicker,
    VueTimepicker,
  },    
  data() {
    return {
      isThisBusinessRole: false,
      roleid: 0,
      productlabelname: null,
      BRoleid: apiUrl.BRoleid,
      isInProjectAsBR : false,
      TMroleid: apiUrl.TMroleid,
      POroleid: apiUrl.POroleid,
      TOroleid: apiUrl.TOroleid,
      SupportEnquiry: apiUrl.SupportEnquiry,
      BRFeatureProList: apiUrl.BRFeatureProList,
      componentKey: 0,
      imgExt:['jpeg','jpg','png'],
      defaultimagepath:apiUrl.defaultimagepath,
      isRequestActiveView: true,
      dislogActive:true,
      isLogActive:false,
      formActionLabel: 'Create Ticket',
      disRequestActive: true,
      isDetActive:false,
      isRequestActive:true,
      isdocsRequestActive:false,
      isEditDocsRequestActive: false,
      addocument:true,
      editdocument: true,
      isdisabled: true,
      isActive:true,
      files:[],
      doclist:[],
      editdoclist:[],
      alldocs:[],
      attachment: [],
      docs:[{
        title:null,
        docs:null,
        comment:null,
        attachment:null
      }],
      backpagedata: [],
      loginuser:'',
      isLoading: false,
      fullPage: true,
      s3url: apiUrl.s3url,
      stylevariation:apiUrl.stylevariation,
      priorityDefination: apiUrl.priorityDefination,
      priority:'',
      companymasterlist: [],
      editRowData:[],
      glbMdl:'',
      glbMdlLbl:'issue',
      TimeFormat: apiUrl.TimeFormat,
      issue:{
        history:[],
        issuename:null,
        project:null,
        projectlist:null,
        subscriberslist: [],
				supportengg: [],
        subscribers: null,
        issuenameaddnew: null,
        functionality:null,
        functionalitylist:null,
        type:null,
        typelist:apiUrl.ticketTypeList,
        mrtypelist: [], //apiUrl.mrticketTypelist,
        scenario:null,
        scenariolist:null,
        projectmodulename: null,
        applicationname: null,
        applicationmodulename: null,
        sectionmodulename: null,
        projectmodulelist: null,
        applicationlist: null,
        applicationmodulelist: null,
        sectionmodulelist: null,
        attachment:null,
        attachmentview:null,
        attachmentnew:null,
        sevairty:null,
        sevairtylist:null,
        priority:null,
        protype: null,
        subType: null,
        prioritylist:apiUrl.issueclassification,
        allotedto:null,
        allotedtolist:[],
        reasonofissue:null,
        impactofissue:null,
        status:null,
        statuslist:apiUrl.statuslist,
        iseditstate:false,
        disblesavebtn:false,
        task: null,
        tasklist: [],
        filedescp:[],
        lastproject:null,
        lastprojectmodulename:null,
        lastapplicationmodulename:null 
      },
      file:[],
      isAddState:false,
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,
      pagefeatures:[],
      showtabledata:true,
      showproject:false,
      modulelist:[],
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      errors:{
        spenttime:"",
        activity:null,
        status:null,
        allotedto:null,
        comment: null,
        issue:{
          issuename:null,
          project:null,
          issuenameaddnew: null,
          functionality:null,
          type:null,
          scenario:null,
          attachment:null,
          sevairty:null,
          priority:null,
          allotedto:null,
          reasonofissue:null,
          impactofissue:null,
          status:null,
          task: null,
          subscribers: null,
          projectmodulename: null,
          applicationname: null,
          applicationmodulename: null,
          sectionmodulename: null,
          protype: null,
          subType: null,
          ticketresolutiontime: null,
          ticketresponsetime: null   
        },
        docs:{
          title:null,
          docs:null,
          comment:null,
          attachment:null
        } 
      },
      isedit: false,
      card:'card',
      border_warning:'border-warning',
      search:{
        searchprojectname :null,
        functionality: null,
        task:null,
        issuename:null,
        type:null,
        sevairty:null,
        priority:null,
        status:null,
        allotedto:null,
        projectnameList: [],
        functionalitylist: [],
        allotedtolist: [],
        tasklist: [],
      },
      projectnameList:[],
      issuenameList:[],
      view:{
        issueid: null,
        projectname: null,
        functionlityname: null,
        typename: null,
        sevairtyname: null,
        taskname: null,
        createdby: null,
        empfullname: null,
        empnamedept: null,
        issuecode: null,
        documentlist: [],
        createddate: null,
        lastmodifiedby: null,
        lastmodifieddate: null,
        department:null,
        empname:null
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading', '|', 'bold',
            'italic', '|', 'bulletedList',
            'numberedList', '|', 'insertTable', '|',
            'undo', 'redo', '|',
          ],
        },
      },
      s3bucket: apiUrl.s3url ,
      issuenameBlured:false,
      scenarioBlured:false,
      commentBlured:false,
      tokendata:null,
      logtime:{
        acivitylist:apiUrl.acivitylist,
        spenttime:"",
        activity:null,
        allotedto:null,
        status:null,
        comment:null,
        attachmentview:[],
        isTimeLogged: false,
      },
      loginusername:'',
      logtimehistory:[],
      imagext: ['jpg', 'jpeg', 'png'],
      accepttype:apiUrl.uploadfiletype,
      userrole:null,
      getMRPDetails: [],
      merchandisingProId: apiUrl.merchandisingProId,
      sectionmoduleId1: apiUrl.sectionmoduleId1,
      sectionmoduleId2: apiUrl.sectionmoduleId2,
      validFileExtensions:apiUrl.validFileExtensions,
      brandAllocateEmp: [],
      indItHelpDesk: apiUrl.indItHelpDesk,
      ifItHelpDeskNotFound: apiUrl.ifItHelpDeskNotFound,
      itHelpDesk: apiUrl.indItHelpDesk,
      companycode: apiUrl.companycode,
      queanslist: [],
      tempcheck: [],
      fieldsnamevalues:{},
      fieldnamelist: [],
      isAddState:false,
      moduletrackerid: 35,
      isSupportEngg : false,
      FcRoleWiseAPI:apiUrl.FcRoleWiseAPI,
      readmore:'outer',
      priorityMessages: {
        P0: "- Critical: Issues that have a severe impact on the business, causing a complete halt or critical functionality degradation. Immediate attention and availability are required.",
        P1: "- High: Significant issues that affect core functionality, but the system remains operational.",
        P2: "- Medium: Issues that impact specific features or functionalities but do not cause a system-wide disruption.",
        P3: "- Low: Minor issues or feature requests that do not significantly affect operations.",
        P4: "- Lowest: Non-urgent inquiries, general questions, or cosmetic issues."
      },
      autoassignto: null,
      btcissuebtnaccessarry: [],
      issuecreateSub: [],
      issuecreateSubArry: [],
      loginuscondition:null,
    }
  },
  created() {
    this.pagefeatures = {}
    if(window.localStorage.getItem('pagefeatures')){
      let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
      pagefeatures1.forEach(element => {
        if (element.page === '/ticket/list') {
          this.pagefeatures[element.featurename] = element.featureaccess
        }
      })
    }
  },
  mounted(){
    window.addEventListener('storage', function(event){
        if (event.key == 'logout-mee') { 
            //this.$router.push('/login');
            window.location.reload();
        }
    });
    let userdata = window.localStorage.getItem('userdata');
    if(userdata){
      userdata = JSON.parse(userdata)
      this.undt=userdata
      this.loginusername=userdata.username
      this.loginuscondition = userdata.userid
      this.tokendata = window.localStorage.getItem('token');
      this.userrole = window.localStorage.getItem("userrole");
      this.roleid = this.undt.roleid
      this.getProjectAllocatedToEmp()
      this.getIssueById(this.$route.query.ticketid)
      // if(this.roleid && parseInt(this.roleid) === 413) {
        this.isThisBusinessRole = true
      // }
      this.getApplicationdata('t44','issue')
      this.getSubscribers(this.$route.query.ticketid, 'ticket')
      this.getConfigurationFormDetails(this.$route.query.ticketid)
      this.getorientationfeedbackformlabels(35)
    }
    $("[data-toggle=popover]").popover({
      html: true,
      trigger: "focus",
      content: function () {
        let content = $(this).attr("data-popover-content");
        return $(content).children(".popover-body").html();
      }
    });
    
  },
  methods:{
    redirectToIssueCreate(btnstate) {
      if(btnstate == 'createTicketBTC') {
        let rowdata = {
          backbtnstate:btnstate,
          issuecreateSub: this.issuecreateSub,
          issuecreateSubArry: this.issuecreateSubArry,
          rowTData: this.editRowData,
          empid: this.undt.userid,
        }
        this.$router.push({ 
            name: 'Create Business Task ',
            path: '/businesstask/create',
            params: {
                clickeddata: rowdata,
                backbtnstate:btnstate,
            }
        })
      } else if(btnstate == 'createTicketIssue') { 
        let rowdata = {
          backbtnstate:btnstate,
          issuecreateSub: this.issuecreateSub,
          issuecreateSubArry: this.issuecreateSubArry,
          rowTData: this.editRowData,
        }
        this.$router.push({ 
            name: 'IssueCreate',
            path: '/issues/create',
            params: {
                clickeddata: rowdata,
                backbtnstate:btnstate,
            }
        })
      }
    },
    getPriorityMessages() {
        let messages = Object.keys(this.priorityMessages).map(priority => {
            return `${priority}: ${this.priorityMessages[priority]}`;
        });
        return messages.join('<br>');
    },
    getSubscribers(moduleid, moduletype){
        this.isLoading = true;
        let url = "api/subscribers/getSubscribersByModuleid";
        this.input = { moduleid, moduletype,useremail: this.undt.username,empcode: this.undt.userid };
        axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) { 
               let decryptdepartmenttext = result.data.data.rows.map(items =>{
                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
                })
                this.issue.subscriberslist = result.data.data.rows;
                this.issue.subscribers = result.data.data.rows.map(d => d.id);
            } else {
              this.issue.subscriberslist = [];
              this.issue.subscribers = null;
            }
        }).catch(e => {
        this.displayError(e)
      });
    },
    selSubscribers(state) {
        this.issue.subscribers = null
        this.issue.subscribers = state
        if(!state || (state && state.length < 1) && this.companycode !='xb') {
            this.errors.issue.subscribers = 'Subscribers are required';
        } else {
            this.errors.issue.subscribers = '';
        }
    },
    idclick(indexx){
            if ($("#cycle_readmore"+indexx).hasClass("readmore")) {
                $("#"+indexx).html("Read More");
                $("#cycle_readmore"+indexx).removeClass("readmore");
                this.readmore = 'outer'
            } else {
                $("#"+indexx).html("Read Less");
                $("#cycle_readmore"+indexx).addClass("readmore");
                this.readmore = 'outer readmore'
            }
        },
    
    idclick8(){
      if ($("#cycle_readmore8").hasClass("readmore")) {
          $("#id8").html("Read More");
          $("#cycle_readmore8").removeClass("readmore");
          this.readmore = 'outer'
      } else {
          $("#id8").html("Read Less");
          $("#cycle_readmore8").addClass("readmore");
          this.readmore = 'outer readmore'
      }
    },

    forceRerender() {
      this.componentKey += 1;
    },
    getIssueById(issueid){
      this.isLoading = true;
      let url = "";
  if(!this.FcRoleWiseAPI){
     url = "api/issue/getIssueById";
      this.input = {
        issueid:issueid,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        empcode: this.undt.userid,
        userrole:this.userrole,
      };
    }

    if(this.FcRoleWiseAPI){
      url = "api/issue/getissuelistfc";
      this.input = {
        issuename:issueid,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        empcode: this.undt.userid,
        userrole:this.userrole,
        createdby:[this.undt.username],       
        userroleid:this.roleid,
        ispagetype:"view",
        moduletype:'ticket',
        ispagefeatures:this.pagefeatures.multiviewuser ? this.pagefeatures.multiviewuser : false 
      };
      if(!this.input.ispagefeatures){
        this.input.allotedto = [this.undt.userid]
       } 
    }

      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if(this.FcRoleWiseAPI){
          this.editRowData = result.data.data.rows;
          }else{
          this.editRowData = result.data.data;
          }
          this.editRowData=this.editRowData[0]
          this.openEditModule(this.editRowData,4)
        } else {
          this.editRowData = [];
          this.$router.push({ name: 'TicketList', params: { search: true }})
          Swal.fire({
              title: "Failed!",
              text: "Your are not authorised user to view this issue",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          })
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getMRPDetailss(issueid, pid){
      this.isLoading = true;
      let url = "api/issue/getMRPDetails";
      this.input = {
        issueid:issueid,
        useremail: this.undt.username,
        empcode: parseInt(this.undt.userid),
        userrole:this.userrole,
        pid: pid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.getMRPDetails = result.data.data;
        } else {
          this.getMRPDetails = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    openLogModule(){
      this.logtime.spenttime=null
      this.logtime.spenttime = { "HH": "", "mm": "" }
      this.logtime.activity=null
      this.logtime.comment=''
      this.errors.activity=null
      this.errors.coments=null
      this.logtime.allotedto=this.editRowData.allotedto
      this.logtime.status= this.editRowData.status
      this.dislogActive=false
      this.isLogActive=true
      this.isRequestActive=false
      this.disRequestActive=true
      this.issue.disblesavebtn = false
      $('html, body').animate({ scrollTop: $(document).height() }, 800);
    },
    savelogData(){
      let valid = this.validatelog();
      if(valid){
        this.logtime.isTimeLogged = true
        this.input={
          moduletype:'ticket',
          modulename:this.editRowData.issuename,
          moduleid:this.editRowData.issueid,
          projectname:this.editRowData.projectname,
          projectid:this.editRowData.projectid,
          activity:this.logtime.activity,
          assignedto:this.logtime.allotedto,
          status:this.logtime.status,
          module_code: this.editRowData.issuecode,
          createdby:this.undt.username,
          attachment:JSON.stringify(this.issue.attachmentnew),
          subscribers: this.issue.subscribers,
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        }
        // if(this.logtime.spenttime) {
        //   this.input.spenttime = this.logtime.spenttime
        // }
        if(typeof(this.logtime.spenttime)=='string' && this.logtime.spenttime) {
            this.input.spenttime = this.logtime.spenttime
        }else if(typeof(this.logtime.spenttime)!='string' && this.logtime.spenttime.HH !='' &&  this.logtime.spenttime.mm!='') {
            this.input.spenttime = this.logtime.spenttime
        }
        if(this.logtime.comment) {
          this.input.coments = this.logtime.comment
        }
        if(this.issue.attachmentnew) {
          let jsonobj1 = JSON.parse(this.issue.attachmentnew);
          let arraymerge = [...jsonobj1.data]
          this.input.attachment= JSON.stringify({data: arraymerge});
        }
        this.isLoading=true
        axios({
          method: "POST",
          url: 'api/issue/saveLogTime',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.issue.iseditstate =true
            this.issue.status=this.logtime.status
            this.issue.allotedto = this.logtime.allotedto
            this.editRowData.logtimeFlag=1
            this.savewizard('t42',this.editRowData)
            this.dislogActive=true
            this.isLogActive=false
          }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              title: "Failed!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
      

    },

    validatelog: function(){
      var isValid=true;
      let spenttime = this.logtime.spenttime && this.logtime.spenttime.HH && this.logtime.spenttime.mm
      
      if(!spenttime && typeof(this.logtime.spenttime)=='string')
      {
        let logstime = (this.logtime.spenttime).split(':')
        if(logstime){
            if(logstime[0] && logstime[1]){
                spenttime = `${logstime[0]}:${logstime[1]}`
                  this.logtime.spenttime = `${logstime[0]}:${logstime[1]}`
            }
        }
      }
      if(spenttime && typeof(this.logtime.spenttime)!='string'){
             this.logtime.spenttime = `${this.logtime.spenttime.HH}:${this.logtime.spenttime.mm}`
      }

      if(this.logtime.activity && spenttime==''){
        this.errors.spenttime="Spent-time Hours & minutes is required";
        isValid= false;
      }else{
        this.errors.spenttime='';
      }
      if(!this.logtime.activity && spenttime !=''){
        this.errors.activity="Activity is required";
        isValid= false;
      }else{
        this.errors.activity=null;
      }
      if(!this.logtime.allotedto){
        this.errors.allotedto="Assigned to is required";
        isValid= false;
      }else{
        this.errors.allotedto=null;
      }
      if(!this.logtime.status){
        this.errors.status="Status is required";
        isValid= false;
      }else{
        this.errors.status=null;
      }
      
      return isValid
    },
    openEditModule(currRowData, flag){
      if(currRowData && currRowData.length==0){
        currRowData = this.editRowData
      }
      this.logtime.isTimeLogged = false
      this.view.issueid = currRowData.issueid;
      this.issue.projectmodulename = currRowData.projectmoduleid
      this.view.projectid=currRowData.projectid;
      if(currRowData.project && currRowData.project !=null){
        this.getSLAPriorityDetails();
        this.getEmpallocatedtoProject(currRowData.project, 'ticket')
        this.getApplicationdata('t37','issue')
        this.getprojectmodules(currRowData.project, 'pmodules');
        this.getprojectmodules(currRowData.project, 'app');
        this.tickettypeformrp(currRowData.project);
      }
      this.view.projectname = currRowData.projectname
      this.view.functionlityname = currRowData.functionlityname
      this.view.taskname = currRowData.taskname
      this.view.typename = currRowData.typename
      this.view.sevairtyname = currRowData.sevairtyname
      this.view.createdby = currRowData.createdby
      this.view.empfullname = currRowData.empfullname
      this.view.empname = currRowData.empname
      this.view.department = currRowData.department
      this.view.documentlist = currRowData.attachment ? currRowData.attachment.data:[]
      this.view.issuecode = currRowData.issuecode
      this.issue.issuenameaddnew = currRowData.functionlityname
      this.view.createddate = currRowData.createddate
      this.view.lastmodifiedby = currRowData.lastmodifiedby      
      this.view.lastmodifieddate = currRowData.lastmodifieddate
      this.view.projectmodulename = currRowData.projectmodulename
      this.view.applicationname = currRowData.applicationname
      this.view.applicationmodulename = currRowData.applicationmodulename
      this.view.sectionmodulename = currRowData.sectionname
      this.issue.issuename = currRowData.issuename
      this.issue.project = currRowData.project
      this.issue.functionality = currRowData.functionality
      this.issue.task = currRowData.taskid
      this.issue.type = currRowData.issuetype
      this.issue.scenario = currRowData.scenario
      this.issue.attachment = currRowData.attachment
      this.view.qacycle = currRowData.qacycle
      this.issue.applicationname = currRowData.applicationid
      this.issue.applicationmodulename = currRowData.applicationmoduleid
      this.issue.lastproject = currRowData.project
      this.issue.lastprojectmodulename = currRowData.projectmoduleid
      this.issue.lastapplicationmodulename = currRowData.applicationmoduleid
      // if (this.issue.project == this.itHelpDesk) {
      //   this.issue.protype = currRowData.projectmoduleid
      //   this.issue.subType = currRowData.applicationmoduleid
      // }
      this.issue.sectionmodulename = currRowData.sectionmoduleid
      if(currRowData.attachment && currRowData.attachment.hasOwnProperty('data')) {
        this.issue.attachmentview =[];
        this.getDownloadLink(currRowData.attachment);
      }
      if(this.issue.sectionmodulename == this.sectionmoduleId1) {
          this.productlabelname = 'Product ID';
      } else if(this.issue.sectionmodulename == this.sectionmoduleId2) {
          this.productlabelname = 'Product Group ID';
      }
      this.issue.sevairty = currRowData.sevairty
      this.issue.priority = (currRowData.priority == '' || currRowData.priority == undefined) ? null : currRowData.priority
      this.issue.allotedto = currRowData.allotedto
      this.getAllocateTo(currRowData.allotedto)
      this.issue.reasonofissue = currRowData.reasonofissue
      this.issue.impactofissue = currRowData.impactofissue
      this.issue.status = currRowData.status
      let currStatus = currRowData.status ? currRowData.status : 'open';
      this.getBrandWiseUserDetails(this.issue.projectmodulename, this.issue.project);
      this.getStatusConfigList(this.issue.project, currStatus);
      this.getissueHistory(currRowData.issueid)
      this.getissueLogTimeHistory(currRowData.issueid)
      this.getAppModulesbyprojectid(currRowData.projectid);
      this.getSectionModules(currRowData.projectid);
      this.getApplicationdata('t37','issue')
			if (currRowData.projectid != this.itHelpDesk && currRowData.projectid != this.merchandisingProId) {
				this.getsupportengg(currRowData.projectid);
			}
      if(this.companycode =='fc') {
        if(this.BRFeatureProList.length>0) {
          if(this.BRFeatureProList.includes(parseInt(currRowData.projectid))) {
            this.btnVisibilityTR(currRowData.projectid);
          }
        } else {
          this.btnVisibilityTR(currRowData.projectid);
        }
      }
			this.getMRPDetailss(currRowData.issueid, this.issue.project);
      // if(this.issue.project == this.merchandisingProId) {
      //   this.getMRPDetailss(currRowData.issueid, this.issue.project);
      // } else if (this.issue.project == this.itHelpDesk || this.companycode=='xb') {
      //   this.getMRPDetailss(currRowData.issueid, this.issue.project);
      // }
      if(this.docs){
        for(let i in this.docs){
          this.docs[i].comment=null
          this.docs[i].desc=null
          this.docs[i].title=null
          if(i>0){
            this.docs.splice(i, 1);
          }
        }
      }
      if(flag==0){
        this.issue.iseditstate=true
        this.isedit = true
        this.isRequestActive=true
        this.disRequestActive=false
        this.dislogActive=true
        this.isLogActive = false
        this.formActionLabel = `Edit Ticket: ${currRowData.issuename}`
        $('html, body').animate({ scrollTop: $(document).height() }, 800);
      }
      if(flag==2){
        this.isdocsRequestActive=true
        this.addocument=false
        this.editdocument=true
        $('html, body').animate({ scrollTop: $(document).height() }, 1200);
      }

      if(flag==3){
        this.isdocsRequestActive=true
        this.editdocument=false
        this.isEditDocsRequestActive = true
        $('html, body').animate({ scrollTop: $(document).height() }, 1200);
      }
      if(this.issue.projectlist && this.issue.projectlist.length > 0 && (this.issue.project && this.issue.project !=null)){
          const checkexist = this.issue.projectlist.some(item =>item.id === this.issue.project);
          if(!checkexist){
              this.getProjectnamebyid(this.issue.project);
          }
      }else if(this.issue.projectlist.length ==0 && (this.issue.project && this.issue.project !=null)){
          this.getProjectnamebyid(this.issue.project);
      }
    },
    getissueHistory(issueid){
      this.isLoading = true;
      let url = "api/issue/getIssueLogById";
      this.input = {
        issueid:issueid,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.issue.history = result.data.data;
        } else {
          this.issue.history=[]
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getissueLogTimeHistory(issueid){
      this.isLoading = true;
      let url = "api/issue/getIssueLogTimeById";
      this.input = {
        issueid:issueid,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        this.forceRerender()
        if (result.data.errorCode == 0) {
          this.logtimehistory = result.data.data;
          const decryptedDepartmentList = this.logtimehistory.map(item => {
          item.assignedtodesignatio = this.decryptText(item.assignedtodesignatio);
          item.designation = this.decryptText(item.designation);
          return item;
          });
        } else {
          this.logtimehistory=[]
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getDownloadLink(data) {
      if(data.data.length>0) {
        let payload = {
          documents:data.data,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
      axios({
            method: "POST",
            url: '/api/imageupload/getdownloadlink',
            data: payload,
            'headers':{'authorization':this.tokendata}
        })
        .then((result) => {
          if(result.data.status) {
            this.issue.attachmentview = result.data;
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getLogtimeDownloadLink(data, curr=null) {      
      if(data) {
        var input =[]
        input.push({link:data,useremail: this.undt.username,empcode: this.undt.userid })
        let payload = {
          documents:input,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
                method: "POST",
                url: '/api/imageupload/getdownloadlink',
                data: payload,
                'headers':{'authorization':this.tokendata}
        })
        .then((result) => {
          if(result.data.status) {
            if(result.data.data[0].presignurl){
              if(curr=='zoom'){
                $('.imagepreview').attr('src', result.data.data[0].presignurl);
                $('#imagemodal').modal('show');  
                return 
              }
              window.location.href=result.data.data[0].presignurl;
            }
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    docsuploadcollapse:function(){
      if(this.docs){
        for(let i in this.docs){
          this.docs[i].comment=null
          this.docs[i].desc=null
          this.docs[i].title=null
          if(i>0){
            this.docs.splice(i, 1);
          }
        }
      }
      this.isDetActive=true
      this.addocument=true
      this.editdocument = true
      this.isdocsRequestActive=!this.isdocsRequestActive
    },
    myeditdocrequestollapse: function() {
      this.isEditDocsRequestActive =!this.isEditDocsRequestActive
    },
    myadddocrequestollapse: function() {
      this.isdocsRequestActive =!this.isdocsRequestActive
    },
    myrequestollapse:function(){
      // this.isRequestActive=!this.isRequestActive
      this.disRequestActive=!this.disRequestActive
    },
    addField(value, fieldType,opt='add') {
      if(opt=='edit') {
        let row = fieldType[fieldType.length-1];
        if(row.businessdocmasterid == undefined) return false
      }            
      this.isActive=false
      fieldType.push({ value: "" });
    },
    removeField(index, fieldType) {
      if(fieldType && fieldType.length>1){
        this.docs.splice(index,1);
        this.attachment.splice(index,1);
      }
    },
    removeEditField(index, fieldType,data) {            
      Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to delete this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          if(data.businessdocmasterid !== undefined) {
            axios({
              method: "POST",
              url: 'api/workstep/deletebusinesstaskdoc',
              data: {id:data.businessdocmasterid,deleteby:this.undt.username,useremail: this.undt.username,empcode: this.undt.userid},
              'headers':{'authorization':this.tokendata}
            }).then((result) => {
              this.isLoading = false;
              if (result.data.status) {
                fieldType.splice(index, 1);
                Swal.fire(
                  'Deleted!',
                  result.data.msg,
                  'success'
                  )
              } else {
                Swal.fire(
                  'Deleted!',
                  result.data.msg,
                  'error'
                )
              }
            });
          } else {
            fieldType.splice(index, 1);
          }    
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getEmpallocatedtoProject(node){
      if (this.issue.project &&  (node && node.length > 3)) {
        this.input = { 
          projectid: this.issue.project, 
          useremail: this.undt.username,
          empcode: this.undt.userid, 
          asigneename:node.toLowerCase()          
        }
        if(this.isThisBusinessRole) {
          this.input.roleid = 484
        }
        if (this.isSupportEngg) {
          this.input.roleid = null
        } 
        if (this.issue.project == this.itHelpDesk ) {
          this.input.roleid= 484
          this.input.flag= 1
          this.input.issueid = this.editRowData.issueid
          axios({
            method: "POST",
            url: 'api/ticket/autoAssignItHelpDeskUser',
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              // this.issue.subscriberslist = result.data.data;
              // this.issue.allotedtolist=result.data.data;
              let templistarray =[]
              templistarray = result.data.data;
              let decryptdepartmenttext = templistarray.map(items =>{
                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
                })
                this.issue.subscriberslist = templistarray;
                this.issue.allotedtolist=templistarray;
            } else {
              this.issue.allotedto = this.ifItHelpDeskNotFound;
            }
          });
        }else{
            axios({
            'method': 'POST',
            'url': 'api/listing/getEmpallocatedtoProject',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => { 
            this.isLoading= false;         
            if(result.data.errorCode == 0){
              if(result.data.data){
                let decryptdepartmenttext = result.data.data.map(items =>{

                  let tempemplabel1 = items.label;
                  let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                  if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                  }
                  return items;
                })
              }
              this.issue.allotedtolist=result.data.data;
              this.issue.subscriberslist=result.data.data;
            } else if (node) {
              this.getticketcreatormanager()
            }else{
                this.issue.allotedtolist = [];
                this.issue.subscriberslist = [];
            }
          }).catch(e => {
        this.displayError(e)
      });
        }
      }
    },
    getAllocateTo(allotedtoID) {
      this.input = { 
        projectid:this.issue.project ,
        useremail: this.undt.username,
        empcode: this.undt.userid ,
        assignedtoid: allotedtoID,
      }
      axios({
        'method': 'POST',
        'url': 'api/listing/getEmpallocatedtoProject',
        'data': this.input,
        'headers':{'authorization':this.tokendata}
      }).then (result => {
        if(result.data.errorCode == 0){
          this.issue.allotedtolist=result.data.data;
          let decryptdepartmenttext = this.issue.allotedtolist.map(items =>{
            let tempemplabel1 = items.label;
            let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
            if(tempemplabel2 && tempemplabel2 != null){
              items.label = tempemplabel1 +'('+tempemplabel2 +')';
            }
            return items;
          })
        } else {
          this.issue.allotedtolist =[];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    onFileChange(e) {
      var validFileExtensions = apiUrl.validFileExtensions
      let selectedFiles = e.target.files;
        for (let i=0; i < selectedFiles.length; i++)
        {
          var blnValid = false;
          for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
              var sCurExtension = validFileExtensions[j];
              if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                  blnValid = true;
                  break;
              }
          }
            
            if (!blnValid) {
                Swal.fire({
                    title: "ERROR",
                    text: "Sorry, Invalid Type of Extension File..!!",
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                return false;
            }
            this.file.push(selectedFiles[i]);
        }
        if(this.file.length>0) {
            this.issue.disblesavebtn=true
        }
        document.getElementById('attachment').value = ""
    },
    removeImage (index) {
      if(this.file.length>0) {
          this.file.splice(index, 1);
          this.issue.filedescp.splice(index, 1);
          var removeimg = JSON.parse(this.issue.attachmentnew)
          if(removeimg){
          var remove = removeimg.data.splice(index,1)
          if(remove.length > 0)
          this.issue.attachmentnew = JSON.stringify({data:removeimg.data})
          else{
            this.issue.attachmentnew = null
          }
        }         
      }
      if(this.file.length < 1) {
                this.issue.disblesavebtn = false
            }              
    },
    removes3file(key,data) {
      if(data.length>0) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
          if (result.isConfirmed) {
              if(this.editRowData.issueid !== undefined) {
                  let deletefile = data[key];
                  data.splice(key, 1)
                  const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                  let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                  axios({
                  method: "POST",
                  url: 'api/issue/s3deleteissue',
                  data: {
                      issueid:this.editRowData.issueid,
                      attachment:payload,
                      deletefile: JSON.stringify(deletefile),
                      useremail: this.undt.username,
                      empcode: this.undt.userid, 
                  },
                  'headers':{'authorization':this.tokendata}
              }).then((result) => {
                  if (result.data.status) {
                      //this.issue.attachmentview.data = data.splice(key, 1);
                      //fieldType.splice(index, 1);
                      Swal.fire(
                      'Deleted!',
                      result.data.msg,
                      'success'
                      )
                  } else {
                  Swal.fire(
                      'Deleted!',
                      result.data.msg,
                      'error'
                      )                        
                  }
              });
              } else {
                  Swal.fire(
                      'IssueId!',
                      'Issueid should not blank',
                      'info'
                      )
              }    
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    mydetailscollapse() {
      this.isDetActive = true
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    savewizard: function(mdl,objThis){           
      this.valid = this.validateForm(mdl,objThis);
      if(this.valid) {
        this.valid = this.validateConfigForm(this.valid);
      }
      if(objThis.logtimeFlag && objThis.logtimeFlag==1){
        this.valid =true;
      }
      if (this.valid) {
          let tblNm='t42'
          if(mdl) tblNm=mdl
          this.input = {
              tbl: tblNm,
              section: 'issue',
              createdby: this.undt.username,
              empid: this.undt.userid,
              useremail: this.undt.username,
              empcode: this.undt.userid,
          }
          let apiURL='api/issue/create'
          this.input.issuename=this.issue.issuename
          this.input.project=this.issue.project
          this.input.functionality=this.issue.functionality
          this.input.task = this.issue.task
          this.input.type=this.issue.type
          this.input.scenario=this.issue.scenario
          this.input.attachment= this.issue.attachmentnew          
          this.input.sevairty=this.issue.sevairty
          this.input.priority=this.issue.priority
          this.input.subscribers = this.issue.subscribers
          this.input.reasonofissue=this.issue.reasonofissue
          this.input.impactofissue=this.issue.impactofissue
          this.input.status=this.issue.status
          this.input.moduletype='ticket'
          this.input.module_code = this.editRowData.issuecode
          this.input.projectmodulename = this.issue.projectmodulename;
          this.input.applicationname = this.issue.applicationname;
          this.input.applicationmodulename = this.issue.applicationmodulename;
          this.input.sectionmodulename = this.issue.sectionmodulename;
          this.input.issuenameaddnew = this.issue.issuenameaddnew;
          if((this.autoassignto && this.autoassignto !=null) && (this.issue.project && this.issue.project != null) && (this.companycode && this.companycode == 'xb')){
            this.input.allotedto = this.autoassignto;
          }else if(this.issue.allotedto && this.issue.allotedto !=null){
            this.input.allotedto = this.issue.allotedto;
          }else{
            this.input.allotedto = this.editRowData.allotedto//this.issue.allotedto;
          }  
          // if (this.issue.project == this.itHelpDesk) {
          //   this.input.projectmodulename = this.issue.protype
          //   this.input.applicationmodulename = this.issue.subType
          // }
          if(this.issue.iseditstate == true){
              if(tblNm == 't42'){
                this.input.issueid= this.editRowData.issueid
                this.input.projectname = this.editRowData.projectname
                this.input.isTimeLogged = this.logtime.isTimeLogged
                this.input.fieldvalues= this.fieldsnamevalues
                this.input.trackerid = this.moduletrackerid
                apiURL='api/issue/update'
              }
              if(this.issue.attachmentnew && this.issue.attachment !== null) {
                this.input.attachmentnew = this.issue.attachmentnew
                let jsonobj1 = JSON.parse(this.issue.attachmentnew);
                let jsonobj2 = this.issue.attachment;
                let arraymerge = [...jsonobj1.data,...jsonobj2.data]
                this.input.attachment= JSON.stringify({data: arraymerge});
                this.view.documentlist = arraymerge
              }
              this.input.lastmodifiedby=this.undt.username
              delete this.input.createdby;
          }
          this.input.useremail= this.undt.username,
          this.input.empcode= this.undt.userid,
          this.isAddState=false
          if(this.issue.ticketresolutiontime){

                  let reshour = this.issue.ticketresolutiontime.hours ? this.issue.ticketresolutiontime.hours.toString().split('') : '00'.toString().split('')
                  let resmin = this.issue.ticketresolutiontime.minutes ? this.issue.ticketresolutiontime.minutes.toString().split('') : '00'.toString().split('')
                  let resolution_hours = this.issue.ticketresolutiontime.hours ?  reshour.length < 2 ? '0'+this.issue.ticketresolutiontime.hours: this.issue.ticketresolutiontime.hours : '00'

                  let resolution_min = this.issue.ticketresolutiontime.minutes ?  resmin.length < 2 ? '0'+this.issue.ticketresolutiontime.minutes : this.issue.ticketresolutiontime.minutes : '00'

                  let ticketresolutiontime =  resolution_hours+':'+resolution_min 
                  this.input.ticketresolutiontime = this.issue.ticketresolutiontime? ticketresolutiontime: null              
            
                }
                 //ticket responsetime
                 if(this.issue.ticketresponsetime){
                    let resphour = this.issue.ticketresponsetime.hours ? this.issue.ticketresponsetime.hours.toString().split('') : '00'.toString().split('')
                    let respmin = this.issue.ticketresponsetime.minutes ? this.issue.ticketresponsetime.minutes.toString().split('') : '00'.toString().split('')
                    let response_hours = this.issue.ticketresponsetime.hours ?  resphour.length < 2 ? '0'+this.issue.ticketresponsetime.hours: this.issue.ticketresponsetime.hours : '00'
                    let response_min = this.issue.ticketresponsetime.minutes ?  respmin.length < 2 ? '0'+this.issue.ticketresponsetime.minutes : this.issue.ticketresponsetime.minutes : '00'
                    let ticketresponsetime =  response_hours+':'+response_min 
                    this.input.ticketresponsetime = this.issue.ticketresponsetime? ticketresponsetime: null
                }
          axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {             
            if(result.data.errorCode == 0){
              this.isLoading=false;
              $('html, body').animate({ scrollTop: 0 }, 1200);
              this.file =[]
              this.issue.filedescp =[]
              this.logtime.isTimeLogged = false
              Swal.fire({
                  title: "Success!",
                  text: result.data.msg,
                  icon: 'success',
                  customClass: {
                      confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
              })
              this.handleCancel()
              this.disRequestActive=true
              this.getIssueById(this.editRowData.issueid)
              this.getConfigurationFormDetails(this.$route.query.ticketid)
            }else if(result.data.errorCode == 3){
                Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                  }).then(function() {
                    window.location.href = "/#/login";
                })
              } else {
                Swal.fire({
                    title: "Failed",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
          }).catch(e => {
        this.displayError(e)
      });
      }
    },
    handleCancel() {
      this.issue.attachmentnew = null
      this.issue.attachment = null
      this.issue.attachmentview = null
      this.myrequestollapse()
      $('html, body').animate({ scrollTop: 0 }, 1200); 
    },
    handleCancel2() {
      this.dislogActive=true
      this.isLogActive=false
      $('html, body').animate({ scrollTop: 0 }, 1200);
    },
    handleLogCancel() {
      this.dislogActive=true
      this.isLogActive=false
      this.logtime.comment = ''
      $('html, body').animate({ scrollTop: 0 }, 1200);
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
    },
    dateMonthFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    getApplicationdata: function(mdl,curracttab){            
      this.pageoffset=1
      this.inputappl = {
        tbl:mdl,
        offset:this.pageoffset,
        limit:apiUrl.LIMIT,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      }
      let apiURL='api/master/commonmaster/list'
      if(mdl == 't11'){
        apiURL='api/listing/getprojectlist'
        this.inputappl = {
            tbl:mdl
        }
      }else if(mdl == 't37' && curracttab == 'issue'){
        this.inputappl = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        apiURL = "api/listing/getfunctionalitybyprojectid";
        this.inputappl.projectid = this.issue.project
      }else if(mdl == 't37' && curracttab == 'issueSearch'){
        this.inputappl = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        apiURL = "api/listing/getfunctionalitybyprojectid";
        this.inputappl.projectid = this.search.searchprojectname
      }else if(mdl == 't43'){
        apiURL='api/master/commonmaster/customselect'
        /* this.inputappl.projectmoduleid=this.issue.project */
        this.inputappl.isSearch= false
        this.inputappl.section= "issue"
        this.inputappl.isFilterSelect= 1
        this.inputappl.isFilterPayload= {}
      }else if(mdl == 't44'){
        apiURL='api/master/commonmaster/customselect';
        this.inputappl.isSearch= false
        this.inputappl.section= "issue"
        this.inputappl.isFilterSelect= 1
        this.inputappl.isFilterPayload= {}
      }else if(mdl == 't26'){
        apiURL='api/master/commonmaster/getModuleDropdownOption'
        this.inputappl = {
          tbl: "t26"
        }
      } else if (mdl == 't18' && curracttab == 'issue') {
        apiURL = 'api/master/commonmaster/getModuleDropdownOption'
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,
          functionalityid: this.issue.functionality
        }
      } else if (mdl == 't18' && curracttab == 'issueSearch') {
        apiURL = 'api/master/commonmaster/getModuleDropdownOption'
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,
          functionalityid: this.search.functionality
        }
      }
      this.inputappl.empcode = this.undt.userid,
      this.inputappl.useremail= this.undt.username
      axios({
        'method': 'POST',
        'url': apiURL,
        'data': this.inputappl,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {             
        if(result.data.errorCode == 0){
          if(mdl == 't11'){
            if(curracttab == 'issue'){
              return true
            }
          }else if(mdl == 't37' && curracttab == 'issue'){
            this.issue.functionalitylist=result.data.data
            this.issue.functionalitylist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
            return true
          }else if(mdl == 't37' && curracttab == 'issueSearch'){
            this.search.functionalitylist=result.data.data
            this.search.functionalitylist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
            return true
          }else if(mdl == 't43'){
            if(curracttab == 'issue'){
              this.issue.typelist=result.data.data.rows
              return true
            }
          }else if(mdl == 't44'){
            if(curracttab == 'issue'){
              this.issue.sevairtylist=result.data.data.rows
              return true
            }
          }else if(mdl == 't26'){
            if(curracttab == 'issue'){
              return true
            }
          } else if(mdl == 't18' && curracttab == 'issue') {
            this.issue.tasklist = result.data.data
            return true
          } else if(mdl == 't18' && curracttab == 'issueSearch') {
            this.search.tasklist = result.data.data
            return true
          }
        } else {
          if(mdl == 't37' && curracttab == 'issue'){
            this.issue.functionalitylist=[]
            return true
          } else if(mdl == 't18' && curracttab == 'issue'){
            this.issue.tasklist = []
            return true
          }
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    validText : function(inputval) {
      var re = /[A-Za-z0-9].{5,}/;
      if(inputval){
        return re.test(inputval.toLowerCase());
      }
    },
    validText2 : function(inputval) {
            var re = /[A-Za-z0-9].{1,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
    validateForm: function(mdl,objThis){
      this.isAddState=false
      this.commentBlured=true;
      var isValid=true;
      if(mdl=="t42"){
        if(!this.issue.issuename){
          this.errors.issue.issuename="Ticket name must be at least 5 characters long";
          isValid= false;
        }

        if(this.issue.project == this.merchandisingProId && this.issue.sectionmodulename != null && this.issue.sectionmodulename !=''){
          if(!this.issue.issuenameaddnew){
            this.errors.issue.issuenameaddnew= this.productlabelname + " is required";
            isValid= false;
          }else{
            this.errors.issue.issuenameaddnew="";
          }
        }
        
        if(!this.issue.project){
          this.errors.issue.project="Project required";
          isValid= false;
        } else {
          this.errors.issue.project = null;
        }
        if(!this.issue.type){
            this.errors.issue.type="Ticket type required";
            isValid= false;
        }
        if(!this.issue.scenario){
          this.errors.issue.scenario="Ticket scenario required";
          isValid= false;
        } else {
          this.errors.issue.scenario="";
        }
        if(!this.issue.allotedto){
          this.errors.issue.allotedto="Assigned to required";
          isValid= false;
        } else {
          this.errors.issue.allotedto = "";
        }
        if (this.companycode=='xb') {
          this.errors.issue.allotedto = " ";
          isValid = true;
        }
        if(!this.issue.sevairty){
          this.errors.issue.sevairty="Ticket severity required";
          isValid= false;
        }
        if (this.companycode=='xb') {
          this.errors.issue.sevairty = " ";
          isValid = true;
        }
        if(!this.issue.priority){
          this.errors.issue.priority="Ticket priority required";
          isValid= false;
        }
        if (this.companycode=='xb') {
          this.errors.issue.priority = " ";
          isValid = true;
        }
        if(!this.issue.status){
          this.errors.issue.status="Ticket status required";
          isValid= false;
        }
        if((!this.issue.subscribers) || (this.issue.subscribers && this.issue.subscribers.length < 1)) {
            this.errors.issue.subscribers = 'Subscribers are required'
            isValid = false
        } else {
            this.errors.issue.subscribers = ''
        }
        if (this.companycode=='xb') {
          this.errors.issue.subscribers = '';
          isValid = true;
        }
      }else if(mdl=="doc"){
        for(let index in this.docs){
          if(!this.validText(this.docs[index].title) || !this.validText(this.docs[index].comment) || !this.validText(this.docs[index].desc)){
            isValid=false
          }
        }
      }
      return isValid
    },
    selissuecsproject: function(state,value){
      // this.projectnameList = null
      this.issue.project=null;
      this.issue.project=state.id;
      this.issue.subscribers = null
      if(state && this.editRowData.projectid != state.id  ) {
        this.issue.subscribers = null
        //this.issue.subscriberslist = null
        this.issue.allotedto = null
        this.issue.allotedtolist = null
        this.issue.projectmodulename = null
        this.issue.applicationmodulename = null
        this.issue.projectmodulelist = []
        this.issue.applicationmodulelist = []
      }
      if(!state){
        this.issue.functionality = null
        this.issue.task = null
        this.issue.tasklist = null
        this.issue.allotedto = null
        this.issue.allotedtolist = null
        this.issue.subscribers = null
        this.errors.issue.project='Project required'
        this.issue.functionalitylist=null
        this.issue.statuslist = [];
        //make sla null
        this.issue.priority = null
        this.issue.ticketresolutiontime = null
        this.issue.ticketresponsetime = null          
        this.issue.lastproject = null   
        this.issue.projectmodulename = null
        this.issue.applicationmodulename = null
        this.issue.projectmodulelist = []
        this.issue.applicationmodulelist = []
      }else{
        this.getSLAPriorityDetails();
        this.errors.issue.project=null;
        // this.getEmpallocatedtoProject(state.id, 'issue')
        this.getApplicationdata('t37','issue')
        let currStatus = this.issue.status ? this.issue.status : 'open';
        this.getStatusConfigList(state.id, currStatus);
        this.getprojectmodules(state.id, 'pmodules');
        this.getprojectmodules(state.id, 'app');
        this.getAppModulesbyprojectid(state.id);
        this.getSectionModules(state.id);
        this.tickettypeformrp(state.id)
        if(state.id == this.merchandisingProId) {
            $('#disabledfield').hide();
        } else {
            $('#disabledfield').show();
        }
        if (state.id != this.editRowData.projectid) {
          this.issue.type = null;
        }
       
      if(this.companycode =='fc') {
          if(this.BRFeatureProList.length>0) {
            if(this.BRFeatureProList.includes(parseInt(state.id))) {
              this.checkIsBusinessRole(state.id);
            }
          } else {
            this.checkIsBusinessRole(state.id);
          }
        }
      }
      
      if(this.companycode=='xb'){
        this.getRosterDetails();
      }
    },
    checkIsBusinessRole: function(pid) {
            this.isLoading = true
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                projectid: pid,
                roleid: this.BRoleid
            }
            let apiUrl = 'api/ticket/getsupportengg'
            axios({
                method: 'POST',
                url: apiUrl,
                data: this.input,
                headers: { authorization: this.tokendata }
            }).then(result => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.isInProjectAsBR = true;
                    if(this.isInProjectAsBR == true){
                        this.issue.type = this.SupportEnquiry;
                        this.getrolenamewithid(pid);
                    }
                } else {
                this.isInProjectAsBR = false;
                }
            }).catch(e => {
                this.displayError(e)
            });
    },
    btnVisibilityTR: function(projectid) {
      let url = "api/ticket/getProjWiseSpecificRolewithCount";
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            projectid:projectid,
            roleidarr: [this.TMroleid, this.TOroleid, this.POroleid],
        };
        axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers': { 'authorization': this.tokendata }
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                let tmroless = result.data.data.rows.filter(p => (p.roleid == this.TMroleid));
                let toroless = result.data.data.rows.filter(p => (p.roleid == this.TOroleid));
                let prroless = result.data.data.rows.filter(p => (p.roleid == this.POroleid));

                this.btcissuebtnaccessarry = [];
                this.issuecreateSub = [];
                this.issuecreateSubArry = [];

                //task manager
                if(tmroless && tmroless.length==1)
                {
                    tmroless.forEach(element => {
                        let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                        //for subscribers
                        this.issuecreateSubArry.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                        this.issuecreateSub.push(element.empid)
                    });
                }
                //product owner
                if(prroless && prroless.length>0)
                {
                    prroless.forEach(element => {
                        let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                        this.issuecreateSubArry.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                        this.issuecreateSub.push(element.empid)
                    });
                }

                //task manager
                if(tmroless && tmroless.length>0)
                {
                    tmroless.forEach(element => {
                        this.btcissuebtnaccessarry.push(element.empid)
                    });
                }
                
                //Task owner
                if(toroless && toroless.length>0)
                {
                    toroless.forEach(element => {
                      this.btcissuebtnaccessarry.push(element.empid)
                    });
                }
            } else {
              this.btcissuebtnaccessarry = [];
        }
    }).catch(e => {
        this.displayError(e)
    });
    },
    getrolenamewithid : function(projectid) {
        this.isLoading = true
        let url = "api/ticket/getProjWiseSpecificRolewithCount";
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            projectid:projectid,
            roleidarr: [this.TMroleid, this.POroleid],
        };
        axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers': { 'authorization': this.tokendata }
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                if(this.isInProjectAsBR == true && this.issue.type == this.SupportEnquiry){
                    let tmroless = result.data.data.rows.filter(p => (p.roleid == this.TMroleid));
                    let prroless = result.data.data.rows.filter(p => (p.roleid == this.POroleid));
                    this.issue.subscribers = [];
                    this.issue.allotedtolist = [];
                    this.issuecreateSub = [];
                    this.issuecreateSubArry = [];

                    //task manager
                    if(tmroless && tmroless.length==1)
                    {
                        tmroless.forEach(element => {
                            let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                            //for subscribers
                            const found = this.issue.subscriberslist.some(el => el.id == element.empid);
                            if(!found) {
                                this.issue.subscriberslist.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                              }
                              this.issuecreateSubArry.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                            const founds = this.issue.subscribers.some(el => el == element.empid);
                            if(!founds) {
                                this.issue.subscribers.push(element.empid);
                            }
                            //for alloted to
                            this.issue.allotedtolist.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                            this.issue.allotedto = element.empid;
                            this.issuecreateSub.push(element.empid)
                        });
                    }
                    //product owner
                    if(prroless && prroless.length>0)
                    {
                        prroless.forEach(element => {
                            let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                            const found = this.issue.subscriberslist.some(el => el.id == element.empid);
                            if(!found) {
                                this.issue.subscriberslist.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                              }
                              this.issuecreateSubArry.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                            const founds = this.issue.subscribers.some(el => el == element.empid);
                            if(!founds) {
                                this.issue.subscribers.push(element.empid);
                            }
                            this.issuecreateSub.push(element.empid)
                        });
                    }

                    //creator
                    this.getemployeedetails(this.undt.userid);
                }
            } else {
        }
    }).catch(e => {
        this.displayError(e)
    });
    },
    getemployeedetails: function(empid){
        this.isLoading = true;
        this.inputfield= {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empid: empid,
            moduletype: 'Appraisal Cycle'
        }
        axios({
            'method': 'POST',
            'url': 'api/feedbackform/getemployeedetails',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
            this.isLoading = false;
            if(result.data.status == true){
            if(result.data.data.rows.length>0) {
                result.data.data.rows.forEach(element => {
                let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                const found = this.issue.subscriberslist.some(el => el.id == element.empcode);
                    if(!found) {
                        this.issue.subscriberslist.push({"id":element.empcode,"label": element.empname +'('+tempemplabel2 +')' });
                    }
                    const founds = this.issue.subscribers.some(el => el == element.empcode);
                    if(!founds) {
                        this.issue.subscribers.push(element.empcode);
                    }
                });
            }
            }else{
            this.employeedetails=[]
            }
            }).catch(e => {
                    this.displayError(e)
            });
    },
    autoAssignTicket(id) {
      this.isLoading = true;
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        projectid: id,
      };
      axios({
        method: "POST",
        url: 'api/listing/autoAssignTicket',
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.autoassignto = result.data.data[0].id;
        }else{
          this.autoassignto = null;
        }
      });
    },
    getTasktypeConfigLists(projectid) {
      let payload = {
          empcode: this.undt.userid,
          useremail: this.undt.username,
          projectid: parseInt(projectid.toString()),
          trackername: 'Ticket Request Type',
      };
      axios({
          method: "POST",
          url: '/api/businesstask/getTasktypeConfigList',
          data: payload,
          'headers':{'authorization':this.tokendata}
      }).then((result) => {
          if(result.data.errorCode == 0){
              let slist = [];
              result.data.data.rows.forEach((rec) => {
                  if(Object.values(rec.statusconfig).length>0){
                      Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                          if(statusnames.length>0) {
                              statusnames.forEach(val => {
                                  if(!slist.some(sdata => sdata.id === val)){
                                      slist.push({ id: val, label: val });
                                  }
                              });
                          }
                      });
                  }
              })
              if(this.issue.project != null && this.issue.project == apiUrl.merchandisingProId){
                  this.sortTypeList(slist, 'merchandising')
              } else{
                  this.sortTypeList(slist, 'otherprojects')
              }
          } else {
              this.issue.mrtypelist = [];
          }
      }).catch(e => {
        this.displayError(e)
      });
    },
    sortTypeList(typelist, type){
      let numArrFromTypelist = [];
      typelist.forEach(element => {
        if(element.id != null) {
          numArrFromTypelist.push(element.id);
        }
      });
      if(numArrFromTypelist.length>0) {
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          moduletype: 'ticket',
          typearr: numArrFromTypelist,
          projecttype: type
        };
        axios({
          method: "POST",
          url: 'api/ticket/sortTypeList',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.issue.mrtypelist = result.data.data.rows;
            this.issue.mrtypelist.sort((a, b) => {
                  return a.label.localeCompare(b.label);
                });
          } else {
            this.issue.mrtypelist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    tickettypeformrp(state){
      this.getTasktypeConfigLists(state);
    },
    selSectionModule(state, value) {
        this.issue.sectionmodulename = null;
        this.issue.sectionmodulename = state;
        if (!state) {
            this.errors.issue.sectionmodulename = "Product ID/ Product Group ID is required";
        } else {
            if(state == this.sectionmoduleId1) {
                this.productlabelname = 'Product ID';
            } else if(state == this.sectionmoduleId2) {
                this.productlabelname = 'Product Group ID';
            }
            this.errors.issue.sectionmodulename = null;
        }
        },
        selApplication(state, value) {
        this.issue.applicationname = null;
        this.issue.applicationname = state;
        if (!state) {
            this.errors.issue.applicationname = "category is required";

        } else {
            this.errors.issue.applicationname = null;
        }
        },
        selProjectModule(state, value) {
        this.issue.projectmodulename = null;
        this.issue.projectmodulename = state;

        if (state && this.issue.project) {
          this.getBrandWiseUserDetails(state, this.issue.project)
        }

        if (!state) {
            this.errors.issue.projectmodulename = "brand is required";
        } else {
            this.errors.issue.projectmodulename = null;
            // this.getAppModules(state);
        }
        },
        selApplicationModule(state, value) {
        this.issue.applicationmodulename = null;
        this.issue.applicationmodulename = state;
        if (!state) {
            this.errors.issue.applicationmodulename = "sub category is required";
        } else {
            this.errors.issue.applicationmodulename = null;
            // this.getSectionModules(state);
        }
        },
        getSectionModules(projectid) {
        this.input = {
            projectid: projectid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
        };
        axios({
            method: "POST",
            url: 'api/listing/getsectionbyprojectid',
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
            this.issue.sectionmodulelist = result.data.data;
            } else {
            this.issue.sectionmodulelist = [];
            }
        }).catch(e => {
        this.displayError(e)
      });
        },
        getAppModulesbyprojectid(projectid) {
          this.input = {
            projectid: projectid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
            isfrompage: 'updatepage'
          };
          axios({
            method: "POST",
            url: 'api/listing/getappmodulenamebyprojectid',
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.issue.applicationmodulelist = result.data.data;
              if(this.issue.applicationmodulelist && this.issue.applicationmodulelist.length > 0 && (this.issue.applicationmodulename && this.issue.applicationmodulename !=null)){
                const checkexistproject = this.issue.applicationmodulelist.some(item =>item.id === this.issue.applicationmodulename);
                if(!checkexistproject){
                  this.getAppModulesbyappmoduleid(this.issue.applicationmodulename);
                }
              }else if(this.issue.applicationmodulelist.length ==0 && (this.issue.applicationmodulename && this.issue.applicationmodulename !=null)){
                this.getAppModulesbyappmoduleid(this.issue.applicationmodulename);
              }
            } else {
              this.issue.applicationmodulelist = [];
            }
          }).catch(e => {
        this.displayError(e)
      });
        },
        getAppModulesbyappmoduleid(appmid) {
          this.input = {
            appmoduleid: appmid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: 'api/listing/getappmodulenamebyprojectid',
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              if(result.data.data && result.data.data.length > 0){
                this.issue.applicationmodulelist.push(result.data.data[0]);
              }
            }
          }).catch(e => {
            this.displayError(e)
          });
        },
        getprojectmodules(projecteid, type) {
          let url = '';
          if(type=='pmodules') {
            url = 'api/listing/getpromodulebyapplicationid';
          } else if(type=='app') {
            url = "api/listing/getappnamebyprojecteid";
          }
          this.input = {
            projecteid: projecteid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
          };
          if(type=='pmodules'){
            this.input.isfrompage = 'updatepage'
          }
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
            if(type=='pmodules') {
              this.issue.projectmodulelist = result.data.data;
              if(this.issue.projectmodulelist && this.issue.projectmodulelist.length > 0 && (this.issue.projectmodulename && this.issue.projectmodulename !=null)){
                const checkexistproject = this.issue.projectmodulelist.some(item =>item.id === this.issue.projectmodulename);
                if(!checkexistproject){
                  this.getprojectmodulesbyid(this.issue.projectmodulename);
                }
              }else if(this.issue.projectmodulelist.length ==0 && (this.issue.projectmodulename && this.issue.projectmodulename !=null)){
                this.getprojectmodulesbyid(this.issue.projectmodulename);
              }
              this.issue.projectmodulelist.sort((a, b) => {
                  return a.label.localeCompare(b.label);
              });
            } 
            if(type=='app') {
                this.issue.applicationlist = result.data.data;
                this.issue.applicationlist.sort((a, b) => {
                    return a.label.localeCompare(b.label);
                });
            }
            } else {
              this.issue.applicationmodulelist = [];
              this.issue.applicationlist = [];
            }
          }).catch(e => {
            this.displayError(e)
          });
        },
        getprojectmodulesbyid(moduleid) {
          let url = 'api/listing/getpromodulebyapplicationid';
          this.input = {
            projectmoduleid: moduleid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
          }
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              if(this.issue.projectmodulelist && this.issue.projectmodulelist.length > 0){
                const checkexistprojectmodule = this.issue.projectmodulelist.some(item =>item.id === moduleid);
                if(!checkexistprojectmodule){
                  this.issue.projectmodulelist.push(result.data.data[0]);
                }
              }else if(this.issue.projectmodulelist.length ==0){
                this.issue.projectmodulelist.push(result.data.data[0]);
              }
            }
          }).catch(e => {
            this.displayError(e)
          });
        },
    getStatusConfigList(pid, status) {
        let payload = {
            empcode: this.undt.userid,
            useremail: this.undt.username,
            projectid: pid,
            // trackername: 'Issue',
            trackername: 'Ticket Request',
            currStatus: status,
        };
        axios({
            method: "POST",
            url: '/api/testcase/getStatusConfigList',
            data: payload,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            if(result.data.errorCode == 0){
                this.issue.statuslist = result.data.data.rows;
            } else {
                this.issue.statuslist = [];
            }
        }).catch(e => {
        this.displayError(e)
      });
    },
    selissuecsfunctionality: function(state,value){
        this.issue.functionality=null
        this.issue.functionality=state

        if(!state){
            this.issue.task = null
            this.issue.tasklist = null
        }else{                
            this.getApplicationdata('t18', 'issue')
        }
    },
    selissuecstype: function(state,value){
        this.issue.type=null
        this.issue.type=state
        if(!state && this.isAddState == false){
            this.errors.issue.type='Ticket type required'
        }else{                
            this.errors.issue.type=null
        }
    },
    selissuecssevairty: function(state,value){
        this.issue.sevairty=null
        this.issue.sevairty=state
        if(!state && this.isAddState == false && this.companycode != 'xb'){
            this.errors.issue.sevairty='Ticket severity required'
        }else{                
            this.errors.issue.sevairty=null
        }
    },
    selissuecspriority: function(state,value){
        this.issue.priority=null
        this.issue.priority=state
        if(!state && this.isAddState == false && this.companycode != 'xb'){
            this.errors.issue.priority='Ticket priority required'
        }else{                
            this.errors.issue.priority=null
        }
    },
    selissuecsstatus: function(state,value){
        this.issue.status=null
        this.issue.status=state
        if(!state && this.isAddState == false){
            this.errors.issue.status='Ticket status required'
        }else{     
            this.errors.status = null               
            this.errors.issue.status=null
        }
    },
    selallotedto: function(state,value){
        this.issue.allotedto=null
        this.issue.allotedto=state
        if(!state && this.isAddState == false && this.companycode != 'xb'){
            this.errors.issue.allotedto='Assigned To required'
        }else{ 
            this.errors.allotedto= null              
            this.errors.issue.allotedto=null
        }
    },
    selProType(state) {
      this.issue.projectmodulename = null;
      this.issue.projectmodulename = state
      if (!state) {
        this.errors.issue.projectmodulename = 'Ticket Category is required'
      } else {
        this.getSLAPriorityDetails()
        this.errors.issue.projectmodulename = null
      }
      if(this.companycode=='xb'){
        this.getRosterDetails();
      }
    },
    selSubType(state) {
      this.issue.applicationmodulename = null;
      this.issue.applicationmodulename = state
      
      if (!state) {
        this.errors.issue.applicationmodulename = 'Ticket Sub Category is required'
      } else {
        this.getSLAPriorityDetails()
        this.errors.issue.applicationmodulename = null
      }
      if(this.companycode=='xb'){
        this.getRosterDetails();
      }
    },
    selissuelogactivity(state, value) {
      this.logtime.activity = null
      this.logtime.activity = state
      if(!state) {
          // this.errors.activity = "Task Activity is required"
      } else {
          this.errors.activity = null
      }
    },
    redirecttoPNM:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            this.$router.push({ 

                path: '/project/summary?pid='+rowdata.moduleid,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        
    getProjectAllocatedToEmp() {
      this.isLoading = true
      let url = "api/listing/getProjectAllocatedToEmp";
      this.input = {
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        empcode: this.undt.userid,
        isfrompage:'updatepage'
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers': { 'authorization': this.tokendata }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.issue.projectlist = result.data.data;
          if(this.issue.projectlist && this.issue.projectlist.length > 0 && (this.issue.project && this.issue.project !=null)){
              const checkexistproject = this.issue.projectlist.some(item =>item.id === this.issue.project);
              if(!checkexistproject){
                  this.getProjectnamebyid(this.issue.project);
              }
          }else if(this.issue.projectlist && this.issue.projectlist.length ==0 && (this.issue.project && this.issue.project !=null)){
              this.getProjectnamebyid(this.issue.project);
          }
        } else {
          this.issue.projectlist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getProjectnamebyid(pid){
        this.isLoading = true;
        this.apiURL="api/searching/getAssignedProjectList"
        this.input={
            useremail: this.undt.username,
            empid :this.undt.userid,
            empcode: this.undt.userid,
            projectid: pid,
            isfrompage: 'isprojectid'
        }
        axios({
            'method': 'POST',
            'url': this.apiURL,
            'data':this.input,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {  
            this.isLoading = false;         
            if(result.data.errorCode == 0){
                if(result.data.data && result.data.data.length > 0){
                    this.issue.projectlist.push(result.data.data[0]);
                }
            }
        }).catch(e => {
            this.displayError(e)
        })
    },
    onSelect(event){
      this.file = e.target.files[0];          
    },
    onUpload(){
      if(this.file){
        this.isLoading=true
        if(this.file.length !== 0){
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle == 't42'){
                btnEle='issuemgt'
            }
            this.apiURL="api/master/fileupload/uploadBulkFileToS3"
            let formData = new FormData();
            let maxsize = 0;
            for (let index = 0; index < this.file.length; index++) {
                formData.append("imagefile", this.file[index]);
                maxsize+= this.file[index].size
            }
            formData.append("empcode", this.undt.userid);
            formData.append("useremail", this.undt.username);
            formData.append("moduletype", 'issuemgt');
            formData.append("fcount", parseInt(this.file.length));
            
            if (maxsize > 1024 * 1024 * 25) {
              this.isLoading = false;
              Swal.fire({
                  title: "Failed",
                  text: "Your document uploaded above 25mb not allow",
                  icon: "info",
                  customClass: {
                  confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              })
              this.isLoading=false
              this.issue.disblesavebtn=true 
              return false; 
            }
            axios({
                'content-type':'multipart/form-data',
                'method': 'POST',
                'url': this.apiURL,
                'data':formData,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading=false;            
                if(result.data.status){
                  this.issue.disblesavebtn=false
                  // const bindinfo = [{"link":result.data.data.filename,"info":result.data.data.filename}]
                  const bindinfo = result.data.data.map((file, index) => {
                    return {"link":file,"info":this.issue.filedescp[index]}                
                  });
                  let jsonobj = {data:bindinfo}
                  this.issue.attachmentnew = JSON.stringify(jsonobj);
                  Swal.fire({
                      title: "Success!",
                      text: result.data.msg,
                      icon: 'success',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                  })
              } else {
                  Swal.fire({
                      title: "",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                  })
                  this.issue.attachment=null
              }
          }).catch(e => {
        this.displayError(e)
      });
        }else{
          this.isLoading=false; 
          Swal.fire({
            title: "",
            text: 'Select image/docs/videos',
            icon: 'info',
            customClass: {
                confirmButton: "btn btn-primary"
            },
            buttonsStyling: !1
          })
          this.issue.attachment=null
        }
      } else {
          this.isLoading=false; 
          Swal.fire({
              title: "",
              text: 'Select image/docs/videos',
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
          })
          this.issue.attachment=null
      }
    },
    onZoom(e){
      this.getLogtimeDownloadLink(e, 'zoom');
    },
		getBrandWiseUserDetails(brandid, projectid) {
      this.isLoading = true;
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        brandid: parseInt(brandid),
        projectid: parseInt(projectid),
      }
      let apiURL = 'api/thirdparty/getBrandWiseUserDetails'

      axios({
        method: "POST",
        url: apiURL,
        data: this.input,
        headers: { authorization: this.tokendata },   
      }).then((result) => {
        this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.brandAllocateEmp = result.data.data
          } else {
            this.brandAllocateEmp = [];
          }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getSLAPriorityDetails() {
      this.isLoading = true;
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        maincategoryid: this.issue.projectmodulename? this.issue.projectmodulename : null,
        subcategoryid: this.issue.applicationmodulename ? this.issue.applicationmodulename : null,
        project: this.issue.project ? this.issue.project : null,
      }
      let apiURL = 'api/slaconfigure/geSLAPriority'
      axios({
        method: "POST",
        url: apiURL,
        data: this.input,
        headers: { authorization: this.tokendata },   
      }).then((result) => {
        this.isLoading = false;
        if(this.issue.projectmodulename !=this.issue.lastprojectmodulename || this.issue.applicationmodulename != this.issue.lastapplicationmodulename || this.issue.project !=this.issue.lastproject){
          if (result.data.errorCode == 0) {
             let d= result.data.data.rows
             if(this.issue.iseditstate == true){
            this.issue.priority = d[0].issueclassification
            this.issue.ticketresolutiontime = d[0].resolutiontime
            this.issue.ticketresponsetime = d[0].responsetime

             }
          } else {
            if(this.issue.iseditstate == true){
             this.issue.priority = null
             this.issue.ticketresolutiontime = null
             this.issue.ticketresponsetime = null

            }
           // this.brandAllocateEmp = [];
          }
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getConfigurationFormDetails(taskid) {
        this.isLoading = true;
        let url = "api/issue/getConfigurationFormDetails"
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            taskid: taskid,
        };
        axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.queanslist = result.data.data[0]
                // for (const config of this.queanslist) {
                //     if (config.fieldvalue == "1") {
                //         config.fieldvalue = "Yes"
                //     } else if (config.fieldvalue == "0") {
                //         config.fieldvalue = "No"
                //     }
                // }
            }else{
                this.queanslist = [];
            }
        }).catch(e => {
        this.displayError(e)
      });
    },

    getorientationfeedbackformlabels(trackerid) {
        this.isLoading = true
        let url = 'api/feedbackform/getorientationfeedbackformlabels'
        let payload = new Object({
            empcode: this.undt.userid,
            useremail  : this.undt.username,
            moduletracker: trackerid
        })
        axios({
            method: 'POST',
            url,
            data: payload,
            headers:{ authorization :this.tokendata }
        }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.fieldnamelist = result.data.data
                    var temArray = {};
                    for (let i = 0; i < this.fieldnamelist.length; i++) {
                        if ((this.fieldnamelist[i].fieldheader ==null || this.fieldnamelist[i].fieldheader =='') && this.fieldnamelist[i].fieldtype !='hidden') {
                            this.tempcheck.push(i)
                        }

                        // header logic start
                        if (this.fieldnamelist[i].fieldheader!=null) {
                            if(!temArray.hasOwnProperty(this.fieldnamelist[i].fieldheader)){
                                temArray[this.fieldnamelist[i].fieldheader] = [];
                            }
                            var pushObj = this.fieldnamelist[i];
                                temArray[this.fieldnamelist[i].fieldheader].push(pushObj);
                        }
                        // header logic end                  


                        if (this.fieldnamelist[i].fieldtype=="text") {
                            let tempdata = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata[j] = {}
                                    tempdata[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue']=tempdata
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="checkbox") {
                            let tempdata = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata[j] = {}
                                    tempdata[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue']=tempdata
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="radio") {
                            let tempdata2 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata2[j] = {}
                                    tempdata2[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata2[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['radiooptionalvalue']=tempdata2
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="drop-down") {
                            let tempdata3 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata3[j] = {}
                                    tempdata3[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata3[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalu1']=tempdata3
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="multiselect-dropdown") {
                            let tempdata4 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata4[j] = {}
                                    tempdata4[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata4[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue2']=tempdata4
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="toggle") {
                            let tempdata5 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata5[j] = {}
                                    tempdata5[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata5[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue3']=tempdata5
                            }
                        }
                        
                    }
                    this.tempheader=temArray;
                    this.getticketconfigdata();
                } else {
                    this.fieldnamelist = [];
                }
            }).catch(e => {
        this.displayError(e)
      });
        },

        getticketconfigdata(){
            this.isLoading = true;
            this.inputfield= {
                useremail: this.undt.username,
                empcode: parseInt(this.undt.userid),
                trackerid: 35,
                formid: parseInt(this.$route.query.ticketid),
            }
            axios({
                'method': 'POST',
                'url': 'api/ticket/getticketconfigdata',
                'data': this.inputfield,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;
                if(result.data.status == true){
                    this.saveddata = result.data.data;
                    this.isedit =true;
                    for (let p = 0; p < this.saveddata.length; p++) {

                        if (this.saveddata[p].fieldvalue && (this.saveddata[p].fieldtype == 'checkbox' || this.saveddata[p].fieldtype == 'multiselect-dropdown')) {
                            this.fieldsnamevalues[this.saveddata[p].fieldid] = this.saveddata[p].fieldvalue.split(',');
                        }else{
                            this.fieldsnamevalues[this.saveddata[p].fieldid] = this.saveddata[p].fieldvalue;
                        }

                    }
                }else{
                   this.saveddata=[]
                }
            });
        },

        changevalue(id,state){
            this.isLoading =true;
            this.fieldsnamevalues[id]= null;
            this.fieldsnamevalues[id] =state;
            this.isLoading =false;
          },
    getRosterDetails() {
      this.isLoading = true;
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        category: this.issue.projectmodulename? this.issue.projectmodulename : null,
        subcategory: this.issue.applicationmodulename ? this.issue.applicationmodulename : null,
        projectid: this.issue.project ? this.issue.project : null,
        fieldvalues:this.fieldsnamevalues ? this.fieldsnamevalues : null
      }
      let apiURL = 'api/roaster/getrosterdetails'
      axios({
        method: "POST",
        url: apiURL,
        data: this.input,
        headers: { authorization: this.tokendata },   
      }).then((result) => {
      this.isLoading = false;
      if (result.data.errorCode == 0) {
        let d= result.data.data;
        this.autoassignto = d[0].allocatedto;
      } else {
        this.autoassignto = null;
      }
      }).catch(e => {
        this.displayError(e)
      });
    },
    dynamicroster(){
      if(this.companycode=='xb'){
        this.getRosterDetails();
      }
    },
    formatData(data) {
      return data
    },

    validateConfigForm(isValid){
      this.isAddState = false;
      for (let i=0; i<this.fieldnamelist.length; i++) {
        //Timepicker HH:mm value assignment start
      if (this.fieldnamelist[i].validationtype=='required') {
        if (this.fieldnamelist[i].fieldtype=='datetime-local') {

            if (typeof this.fieldsnamevalues[this.fieldnamelist[i].id] == undefined || typeof (this.fieldsnamevalues[this.fieldnamelist[i].id]) == 'undefined') {
                isValid=false;
            }else{
                let hours = this.fieldsnamevalues[this.fieldnamelist[i].id]
                let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)
                if (this.fieldsnamevalues[this.fieldnamelist[i].id][1]=='H' || this.fieldsnamevalues[this.fieldnamelist[i].id][4]=='m') {
                    isValid=false;
                    Swal.fire({
                        title: "Failed",
                        text: this.fieldnamelist[i].label+' '+'required in HH:mm format value',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
                if(typeof hours === 'string' && hours.length === 5){
                    isValidHours = true
                }
                if(isValidHours && hours !== null && typeof hours === 'object') {
                    this.fieldsnamevalues[this.fieldnamelist[i].id] = `${hours.HH}:${hours.mm}`
                }
            }

        }
      }

      if (this.fieldnamelist[i].fieldtype=='datetime-local') {
        if (typeof this.fieldsnamevalues[this.fieldnamelist[i].id] != undefined || typeof (this.fieldsnamevalues[this.fieldnamelist[i].id]) != 'undefined') {
          let hours = this.fieldsnamevalues[this.fieldnamelist[i].id]
          let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)

          if(typeof hours === 'string' && hours.length === 5){
              isValidHours = true
          }
          if(isValidHours && hours !== null && typeof hours === 'object') {
              this.fieldsnamevalues[this.fieldnamelist[i].id] = `${hours.HH}:${hours.mm}`
          }
        }
      }
        //Timepicker HH:mm value assignment end

        if (this.fieldnamelist[i].fieldtype != 'toggle') {
            
            if (this.fieldnamelist[i].validationtype=='required') {
                if (this.fieldsnamevalues[this.fieldnamelist[i].id] == '' || this.fieldsnamevalues[this.fieldnamelist[i].id] == null || this.fieldsnamevalues[this.fieldnamelist[i].id] == []) {
                    isValid=false;
                    if(isValid==false){
                        Swal.fire({
                            title: "Failed",
                            text: this.fieldnamelist[i].label+' '+'is required',
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        return
                    }
                }
                if (this.fieldnamelist[i].fieldtype=='datetime-local' && (this.fieldsnamevalues[this.fieldnamelist[i].id] == '' || this.fieldsnamevalues[this.fieldnamelist[i].id] == null || this.fieldsnamevalues[this.fieldnamelist[i].id] == '[object Object]')) {
                  isValid=false;
                    Swal.fire({
                        title: "Failed",
                        text: this.fieldnamelist[i].label+' '+'is required',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
                if (this.fieldnamelist[i].fieldtype=='datetime-local' && (this.fieldsnamevalues[this.fieldnamelist[i].id][1]=='H' || this.fieldsnamevalues[this.fieldnamelist[i].id][4]=='m')) {
                    isValid=false;
                    Swal.fire({
                        title: "Failed",
                        text: this.fieldnamelist[i].label+' '+'required in HH:mm format value',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
                if (typeof (this.fieldsnamevalues[this.fieldnamelist[i].id]) == undefined || typeof (this.fieldsnamevalues[this.fieldnamelist[i].id]) == 'undefined') {
                    isValid=false;
                } 
                else{
                    if(this.fieldsnamevalues[this.fieldnamelist[i].id].length < 0) {
                        isValid=false;
                    }
                }
            }
          }
        }
        return isValid;
    },
    getticketcreatormanager() {
      this.isLoading = true
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid
      }
      let apiUrl = 'api/ticket/getticketcreatormanager'
      axios({
        method: 'POST',
        url: apiUrl,
        data: this.input,
        headers: { authorization: this.tokendata }
      }).then(result => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.issue.subscriberslist = result.data.data.rows;
          let decryptdepartmenttext = this.issue.subscriberslist.map(items =>{

            let tempemplabel1 = items.label;
            let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

            if(tempemplabel2 && tempemplabel2 != null){
            items.label = tempemplabel1 +'('+tempemplabel2 +')';
            }
            return items;
          })
        } else {
          this.issue.subscriberslist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
		getsupportengg() {
			this.isLoading = true
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
				projectid: this.issue.project,
				roleid: 484
      }
      let apiUrl = 'api/ticket/getsupportengg'
      axios({
        method: 'POST',
        url: apiUrl,
        data: this.input,
        headers: { authorization: this.tokendata }
      }).then(result => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.issue.supportengg = result.data.data.rows;
          if (this.issue.supportengg.length == 1) {
            this.isSupportEngg = true
          }
        } else {
          this.issue.supportengg = []
        }
      }).catch(e => {
        this.displayError(e)
      });
		},
    getEmpallocatedtoProjectForSubscriber(node){
      if (this.issue.project &&  (node && node.length > 3)) {
        this.input = { 
          projectid: this.issue.project, 
          useremail: this.undt.username,
          empcode: this.undt.userid, 
          asigneename:node.toLowerCase()          
        }
        // if(this.isThisBusinessRole) {
        //   this.input.roleid = 484
        // }
        if (this.isSupportEngg) {
          this.input.roleid = null
        } 
        if (this.issue.project == this.itHelpDesk ) {
          this.input.roleid= 484
          this.input.flag= 1
          this.input.issueid = this.editRowData.issueid
          axios({
            method: "POST",
            url: 'api/ticket/autoAssignItHelpDeskUser',
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.issue.subscriberslist = result.data.data;
              this.issue.allotedtolist=result.data.data;
              let templistarray =[]
              templistarray = result.data.data;
              let decryptdepartmenttext = templistarray.map(items =>{
                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
                })
                this.issue.subscriberslist = templistarray;
                this.issue.allotedtolist=templistarray;

            } else {
              this.issue.allotedto = this.ifItHelpDeskNotFound;
            }
          }).catch(e => {
        this.displayError(e)
      });
        }else{
            axios({
            'method': 'POST',
            'url': 'api/listing/getEmpallocatedtoProject',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => { 
            this.isLoading= false;         
            if(result.data.errorCode == 0){
              if(result.data.data){
                let decryptdepartmenttext = result.data.data.map(items =>{

                  let tempemplabel1 = items.label;
                  let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                  if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                  }
                  return items;
                })
              }
              this.issue.allotedtolist=result.data.data;
              this.issue.subscriberslist=result.data.data;
            } else if (node) {
              this.getticketcreatormanager()
            }else{
              this.issue.allotedtolist = [];
              this.issue.subscriberslist = [];
            }
          }).catch(e => {
        this.displayError(e)
      });
        }
      }
    },
    
    previewpdf(geturl)
    {
        window.open(geturl, "_blank");
    }
  },
  filters: {
    basename: function (value) {
      if (!value) return ''
      //value = value.toString()
      return value.substr(value.lastIndexOf("/") + 1)
    }
  }
}
</script>
