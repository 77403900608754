<template>
    <div>
        <div class="app-content content devcontent ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper pb-2">
                <div class="content-header row">
                    <div class="content-header-left col-md-12 col-12 mb-1">
                        <div class="row breadcrumbs-top">
                            <div class="col-12">
                                <h3 class="content-header-title float-left mb-0">Dashboard</h3>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="#">Home</a>
                                        </li>
                                        <li class="breadcrumb-item"><router-link to='/mydashboard'>My Dashboard</router-link>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-body">
                    <!-- Dashboard Ecommerce Starts -->
                    <section id="dashboard-ecommerce">
                        <div class="row match-height customAnimation">
                            <div class="col-lg-12 col-12 px-0 dashboard_all_container">
                                <div class="row mx-0 match-height">
                                   
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 mb-2">
                                        <div class="card text-center card-tiny-line-stats mb-0 my_projects-bgs_co my_projects-bgs-1">
                                            <div class="card-body pb-50 px-1 customstyle_dashboard_view" @click="redirect('My Open Requests','l4',0)">
                                                <div class="avatar bg-light-info p-50 mb-1">
                                                    <div class="avatar-content">
                                                       <em data-feather='file-text' class="font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <h2 class="card-text mb-1 cardcenter font-weight-bolder" style="cursor:pointer">{{reqpending}}</h2>
                                                <p class=" text-white">My Open Requests</p>
                                                
                                                <div id="statistics-order-chart"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 mb-2">
                                        <div class="card text-center card-tiny-line-stats mb-0 my_projects-bgs_co my_projects-bgs-2" >
                                            <div class="card-body pb-50 px-1 customstyle_dashboard_view" @click="redirect('Requests In Draft','l4',1)">
                                                <div class="avatar bg-light-primary p-50 mb-1">
                                                    <div class="avatar-content ">
                                                        <em data-feather='save' class="font-medium-5"></em>
                                                    </div>
                                                </div>
                                                
                                                <h2 class="card-text mb-1 cardcenter font-weight-bolder" style="cursor:pointer">{{draft}}</h2>
                                                <p class=" text-white">Requests In Draft</p>
                                                <div id="statistics-profit-chart1"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 mb-2">
                                        <div class="card text-center card-tiny-line-stats mb-0 my_projects-bgs_co my_projects-bgs-3" >
                                            <div class="card-body pb-50 px-1 customstyle_dashboard_view" @click="redirect('Requests In Approve','l4',2)">
                                                <div class="avatar bg-light-secondary p-50 mb-1">
                                                    <div class="avatar-content ">
                                                        <em data-feather="file-plus" class="font-medium-5"></em>
                                                        
                                                    </div>
                                                </div>
                                                <h2 class="card-text mb-1 font-weight-bolder text-white" style="cursor:pointer">{{reqapprove}}</h2>
                                                <p class=" text-white">Requests In Approve</p>
                                                <div id="statistics-profit-chart1"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 mb-2">
                                        <div class="card text-center card-tiny-line-stats mb-0 my_projects-bgs_co my_projects-bgs-4" >
                                            <div class="card-body pb-50 px-1 customstyle_dashboard_view" @click="redirect('Requests In Feedback','l4',3)">
                                                <div class="avatar bg-light-success p-50 mb-1">
                                                    <div class="avatar-content">
                                                        <em data-feather="message-square" class="font-medium-5"></em>
                                                    </div>
                                                </div>
                                                
                                                <h2 class="card-text mb-1 font-weight-bolder text-white" style="cursor:pointer">{{reqfeedback}}</h2>
                                                <p class="text-white">Requests In Feedback</p>
                                                <div id="statistics-profit-chart2"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 mb-2">
                                        <div class="card text-center card-tiny-line-stats mb-0 my_projects-bgs_co my_projects-bgs-5" >
                                            <div class="card-body pb-50 px-1 customstyle_dashboard_view" @click="redirect('Requests In Reject','l4',4)">
                                                <div class="avatar bg-light-warning p-50 mb-1">
                                                    <div class="avatar-content "> 
                                                        <em data-feather="user-x" class="font-medium-5"></em>
                                                    </div>
                                                </div>
                                                
                                                <h2 class="card-text mb-1 font-weight-bolder text-white" style="cursor:pointer">{{reqreject}}</h2>
                                                <p class="text-white">Requests In Reject</p>
                                                <div id="statistics-profit-chart3"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 mb-2">
                                        <div class="card text-center card-tiny-line-stats mb-0 my_projects-bgs_co my_projects-bgs-6">
                                            <div class="card-body pb-50 px-1 customstyle_dashboard_view" @click="redirect('Total Travel Request','l1',5)">
                                                <div class="avatar bg-light-danger p-50 mb-1">
                                                    <div class="avatar-content">
                                                        <em data-feather="airplay" class="font-medium-5"></em>
                                                    </div>
                                                </div>
                                                
                                                <h2 class="font-weight-bolder mb-1 cardcenter" style="cursor:pointer">{{ totalcnt}}</h2>
                                                <p class="text-white">Total Travel Request</p>
                                                <div id="statistics-profit-chart5"></div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                         <!-- legend -->
                         <div class="col-xl-12 col-md-12 col-12 px-0 mb-1">
                                <fieldset class="fieldset_border_vi dashboard_fieldset_bg mobileview_col_padd-5">
                                    <legend> L1 Manager</legend>
                                    <div class="row match-height customAnimation">
                                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 mb-1">
                                            <div class="card text-center card-tiny-line-stats mb-0 my_projects-bgs_co my_projects_trs-bgs-1" >
                                                <div class="card-body pb-50 px-1 customstyle_dashboard_view" @click="redirect('L1 Manager Pending','l1',0)">
                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em data-feather="refresh-cw" class="font-medium-5"></em>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="top_header_card_width">
                                                        <h2 class="font-weight-bolder mb-1 " style="cursor:pointer">{{l1pending}}</h2>
                                                        <p class="card-text mb-1">L1 Manager Pending</p>
                                                        <div id="statistics-profit-chart6"></div>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 mb-1">
                                            <div class="card text-center card-tiny-line-stats mb-0 my_projects-bgs_co my_projects_trs-bgs-2">
                                                <div class="card-body pb-50 px-1 customstyle_dashboard_view" @click="redirect('L1 Manager Approve','l1',2)">
                                                        <div class="avatar bg-light-secondary p-50 mb-1">
                                                            <div class="avatar-content">
                                                                <em data-feather="file-plus" class="font-medium-5"></em>
                                                            </div>
                                                        </div>
                                                    <div class="top_header_card_width">
                                                    <h2 class="font-weight-bolder mb-1 " style="cursor:pointer">{{l1approve}}</h2>
                                                    <p  class="card-text mb-1">L1 Manager Approve</p>
                                                    <div id="statistics-profit-chart7"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 mb-1">
                                            <div class="card text-center card-tiny-line-stats mb-0 my_projects-bgs_co my_projects_trs-bgs-3">
                                                <div class="card-body pb-50 px-1 customstyle_dashboard_view" @click="redirect('L1 Manager Feedback','l1',3)">
                                                    
                                                    <div class="avatar bg-light-secondary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em data-feather="message-square" class="font-medium-5"></em>
                                                        </div>
                                                    </div>
                                                    <div class="top_header_card_width">
                                                    <h2 class="font-weight-bolder mb-1" style="cursor:pointer">{{l1feedback}}</h2>
                                                    <p  class="card-text mb-1">L1 Manager Feedback</p>
                                                    <div id="statistics-profit-chart7"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 mb-1">
                                            <div class="card text-center card-tiny-line-stats mb-0 my_projects-bgs_co my_projects_trs-bgs-4">
                                                <div class="card-body pb-50 px-1 customstyle_dashboard_view" @click="redirect('L1 Manager Reject','l1',4)">
                                                    <div class="avatar bg-light-danger p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em data-feather="user-x" class="font-medium-5"></em>
                                                        </div>
                                                    </div>
                                                    <div class="top_header_card_width">
                                                    <h2 class="font-weight-bolder mb-1 " style="cursor:pointer">{{l1reject}}</h2>
                                                    <p  class="card-text mb-1">L1 Manager Reject</p>
                                                    <div id="statistics-profit-chart7"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                </fieldset>
    
                            </div>
                            <!-- legend end -->
                    </section>
                    <div class="col-xl-12 col-md-12 col-12 px-0">
                        <fieldset class="fieldset_border_vi dashboard_fieldset_bg">
                            <legend> Admin </legend>
                            <div class="row match-height customAnimation dashboard_fieldset_mobileview">
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                    <div class="card card_bottom_line_animation bottom_bg_bor1 mb-1">
                                        <div class="card-header bg-white top_header_card_width_trsview" @click="redirect('Admin Pending','l3',0)">
                                            <div class="top_header_card_width">
                                            <h4 class="font-weight-bolder mb-1 cardcenter " style="cursor:pointer">{{adminpending}}</h4>
                                            <p  class="text-white">Admin Pending</p>
                                            <div id="statistics-profit-chart7"></div>
                                            </div>
        
                                            <div class="avatar bg-light-warning p-50 mb-1">
                                                <div class="avatar-content">
                                                    <em data-feather="refresh-cw" class="font-medium-5"></em>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="card card_bottom_line_animation bottom_bg_bor3 mb-1">
                                    <div class="card-header bg-white top_header_card_width_trsview" @click="redirect('Closed','l3',6)">
                                        <div class="top_header_card_width">
                                        
                                        <h4 class="font-weight-bolder mb-1 cardcenter" style="cursor:pointer">{{closed}}</h4>
                                        <p  class="text-white">Closed</p>
                                        <div id="statistics-profit-chart7"></div>
                                        </div>
                                        <div class="avatar bg-light-primary p-50 mb-1">
                                            <div class="avatar-content">
                                                <em data-feather="x-octagon" class="font-medium-5"></em>
                                                
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                       </fieldset>
                    </div>
                    <!-- Dashboard Ecommerce ends -->
    
                </div>
            </div>
        </div>
        
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    export default({
        name:'MyDashboard',
        data() {
            return {
                un:'',
                reqpending: 0,
                reqapprove: 0,
                reqfeedback: 0,
                reqreject: 0,
                totalcnt: 0,
                l1pending: 0,
                draft: 0,
                l1approve: 0,
                l1feedback: 0,
                l1reject: 0,
                l2pending: 0,
                l2approve: 0,
                l2feedback: 0,
                l2reject: 0,
                closed: 0,
                adminpending: 0,
                tokendata:null,
            }  
        } ,
        mounted(){
            let userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.tokendata = window.localStorage.getItem("token");
                this.un=userdata.firstname+' '+userdata.lastname
                this.username = userdata.username
                this.getDashboardData()
                if (!localStorage.getItem('reloaded')) {
                    localStorage.setItem('reloaded', '1');
                    location.reload();
                }
            }
            
            if (feather) {
                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        },
        methods:{
            getDashboardData() {
                let userdata = window.localStorage.getItem('userdata');
                if(userdata){
                    userdata = JSON.parse(userdata)
                    this.empid = userdata.userid
                    
                    let payload ={
                        empcode:this.empid,
                        useremail: this.username,
                    }
                    axios({
                        'method': 'POST',
                        'url': "api/dashboard/getTravelDashboardDetails",
                        'data':payload,
                        headers: { authorization: this.tokendata },
                    })
                    .then(result => {           
                        if(result.data.status && result.data.data.length>0){
                            let response = result.data.data[0];
                            this.reqpending=response.reqpending || 0
                            this.reqapprove=response.reqapprove || 0
                            this.reqfeedback=response.reqfeedback || 0
                            this.reqreject=response.reqreject || 0
                            this.totalcnt=response.cnt || 0
                            this.l1pending=response.l1pending || 0
                            this.draft=response.draft || 0
                            this.l1approve=response.l1approve || 0
                            this.l1feedback=response.l1feedback || 0
                            this.l1reject=response.l1reject || 0
                            this.l2pending=response.l2pending || 0
                            this.l2approve=response.l2approve || 0
                            this.l2feedback=response.l2feedback || 0
                            this.l2reject=response.l2reject || 0
                            this.closed=response.closed || 0
                            this.adminpending = response.adminpending || 0
                        }
                    })
                }
            },
            redirect: function(lable,level,status){
                this.$router.push({ 
                    name: 'TravelList',
                    path: '/trs/travelist',
                    query: { 
                        lablename: lable,
                        level: level, 
                        status: status
                    }
                })
            },
        },
        components: {
            
        }
    })
    </script>
    
    <style scoped>
    .alignment{
        margin-left: 20px;
        margin-bottom: 20px;
    }
    .cardcenter{
        text-align: center;
        color: white;
    }
    </style>