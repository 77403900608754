<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="modern-horizontal-wizard" >
          <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
          <div class="container-fluid">
              <div class="row breadcrumbs-top">
                <div class="col-md-12 content-header-left px-0">
                  <h3 class="content-header-title float-left mb-0">Dl Email Configuration Details </h3>
                  <div class="breadcrumb-wrapper">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/configuration/dlemailist'>Dl Email Configuration  List</router-link></li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div class="card border-warning filter_card_searchbgst">
              <div class="card-header filter_rotation_onlick" style="cursor: pointer" v-on:click="mysearchrequestollapse">
                <div class="container-fluid" >
                <div class="row fc_my-05 ">
                  <div class="w-auto px-1 mobile-px-0 ">
                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                      <div class="avatar-content">
                        <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                      </div>
                    </div>
                    <h4 class="card-title d-inline-flex ml-1 ">Search DL Email Configuration Details </h4>
                  </div>
                  <div class="ml-auto d-flex pr-md-1">
                  <div  class="ml-auto px-sm-1 px-sm-0 text-right mobile-px-0">
                    <div class="input-group-append float-right">
                      <button id="commonaddmodule" class="btn btn-sm btn-relief-success" @click="redirecttourl('create', null)"> 
                        <span class="mobile_hide_bt">Create Configuration</span> <plus-circle-icon size="1.5x" class="custom-class "></plus-circle-icon>
                      </button>
                    </div>
                  </div>
                  <span class="rotetions_view_arrow"> 
                    <em class="las la-angle-down"></em>
                  </span>
                  </div>
                </div>
                </div>
              </div>
              <div v-bind:class="[{ show: isSearchRequestActive },card_content,collapse,]">
                <div class="card-body px-sm-mobile-10">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="search.searchprojectname" @input="selSearchProject"  :options="search.projectnameopts"/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Field Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Field name" v-model="search.searchfieldname" :options="search.fieldlist" @input="selissuecsfield"/>
                  </div>
                  <!-- <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Values</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Values" v-model="search.searchvalue" :options="search.valueslist"/>
                  </div> -->
                  <div class="col-md-4" v-if="this.inputtypeselection">
                        <div class="form-group mb-1">
                          <label for="projectmdllistdd">Values</label>
                        
                            <treeselect v-if="this.inputtypeselection == 'drop-down'"
                            class="projectmdllistdd capitalisetext"
                            placeholder="Select Values"
                            v-model="search.searchvalue"
                            :options="search.valueslist"
                          />
                          <input v-if="this.inputtypeselection == 'text'" class="form-control"  v-model="search.searchvalue" type="text" placeholder="Enter Text">
                          <input v-if="this.inputtypeselection == 'number'"  class="form-control"  v-model="search.searchvalue" type="number" placeholder="Enter Number">
                          <date-picker v-if="this.inputtypeselection == 'date'" placeholder="Select date" v-model="search.searchvalue" valueType="format" class="enddate w-100" :default-value="new Date()"></date-picker>

                        </div>
                      </div>
                       <!-- for fail condition -->
                    <div class="col-md-4" v-else>
                        <div class="form-group mb-1">
                          <label for="projectmdllistdd">Values</label>
                          <treeselect 
                            class="projectmdllistdd capitalisetext"
                            placeholder="Select Values"
                            v-model="search.searchvalue"
                            :options="search.valueslist"
                          />
                        </div>
                      </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Type</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select type" v-model="search.tktype" :options="search.mrtypelist"/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Priority</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Priority" v-model="search.priority" :options="search.prioritylist"/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Severity</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Severity" v-model="search.sevairty" :options="search.sevairtylist"/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                   <label class="form-label lable-left">Dl Email</label>
                   <input type="text" class="form-control" v-model="search.email" placeholder="Search Email"/>
                </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Assigned To</label>
                      <treeselect class="allotedto capitalisetext" placeholder="select agent name" :multiple="true" :clear-on-select="true" :options="search.allotedtolist" v-model="search.allotedto"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <div class="form-group mb-1">
                        <label for="allotedto">Active/Inactive</label>
                        <treeselect class="allotedto capitalisetext" placeholder="Select activation" v-model="search.isactive" :options="isactivelist" :multiple="true" :clear-on-select="true"/>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-12 mb-0 text-right">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="roasterlist(search)"><search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                      <button type="move" class="btn btn-relief-primary mt-2"  @click="ExportXL()" v-if="isBothDateSelected" disabled><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                      <button type="move" class="btn btn-relief-primary mt-2"  @click="ExportXL()" v-else><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div id="scrollToListGrid"></div>
          <div class="row">
            <div class="col-md-12">
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div class="row" id="table-small">
                    <div class="col-12">
                      <div class="card">
                        <div class="table-responsive">
                          <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="Task Time Log Details">
                            <thead>
                              <tr>
                                <th scope="col" class="align-middle  first-col-sticky py-1" style="min-width: 45px;">Sr#</th>
                                <th scope="col" class="align-middle " style="min-width: 150px;">Project Name</th>
                                <th scope="col" class="align-middle" style="min-width: 170px;"> Field Name</th>
                                <th scope="col" class="align-middle" style="min-width:170px" >Values</th>
                                <th scope="col" class="align-middle text-center" style="min-width: 150px;">Type</th>
                                <th scope="col" class="align-middle text-center" style="min-width: 150px;">Priority</th>
                                <th scope="col" class="align-middle text-center" style="min-width: 150px;">Severtiy</th>
                                <th scope="col" class="align-middle" style="min-width: 170px;">Sender Host </th>
                                <th scope="col" class="align-middle" style="min-width: 170px;">Receiver Host </th>
                                <th scope="col" class="align-middle text-center" style="min-width: 150px;">Dl Email</th>
                                <th scope="col" class="align-middle" style="min-width: 170px;">Assigned Name</th>
                                <th scope="col" class="align-middle" style="min-width: 170px;">Created date</th>
                                <th scope="col" class="align-middle ">IsActive</th>
                                <th scope="col" class="align-middle">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(data, index) in dlemailist">
                                <tr v-bind:key="data.id">
                                  <td class="centeralign first-col-sticky">{{ index + 1 + currPageNum * pagelimit }}</td>
                                  <td>
                                    <span class="font-weight capitalisetext">{{data.projectname}}</span>
                                  </td>
                                  <td class="rolelistdd">{{ data.fieldname }}</td>
                                  <td>
                                    <div v-if="data.fieldname && data.fieldname=='Ticket Category'" class="rolelistdd" >{{ data.category ? data.category :'NA'}}</div>
                                    <div v-else-if="data.fieldname && data.fieldname=='Ticket Sub Category'" class="rolelistdd" >{{ data.subcategory ? data.subcategory :'NA'}}</div>
                                    <div v-else class="rolelistdd">{{ data.fieldvalue ? data.fieldvalue :'NA' }}</div>
                                  </td>
                                  <td class="font-weight-normal text-center">{{ data.typename }}</td>
                                  <td class="text-center">
                                    <span class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25 ">{{ data.priority}} </span></td>
                                  <td class="text-center">
                                    <div v-if="data.sevairtyname=='critical'" class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                    <div  v-else-if="data.sevairtyname=='major'" class="badge badge-pill badge-light-warning mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                    <div v-else-if="data.sevairtyname=='minor'" class="badge badge-pill badge-light-primary mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                    <div v-else-if="data.sevairtyname=='cosmetic'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                    <div v-else-if="data.sevairtyname=='moderate'" class="badge badge-pill badge-light-info mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                    <div v-else-if="data.sevairtyname == null || data.sevairtyname == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>
                                    <div v-else class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">{{data.sevairtyname}}</div>
                                  </td>
                                  <td class="font-weight-normal text-center">{{ data.sendhost}}</td>
                                  <td class="font-weight-normal text-center">{{ data.dlhost}}</td>
                                  <td class="font-weight-normal">{{ data.dlemail}}</td>
                                  <td class="font-weight-normal">{{ data.empname }}</td>
                                  <td class="text-nowrap font-weight-normal">{{ dateMonthFormat(data.createddate) }}</td>
                                  <td class="text-center text-capitalize"  v-if="data.isactive === 1">
                                    <em class="las la-check-circle custom-class"  style="color: #28c76f;font-size: 24px;"></em>
                                  </td>
                                  <td class="text-center text-capitalize" v-else>
                                    <em class="las la-times-circle custom-class" style="color: #b82323;font-size: 24px;"></em>
                                  </td>
                                  <td class="clsaction" >
                                    <div class="dropdown">
                                      <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                        <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                      </button>
                                      <div class="dropdown-menu">
                                        <a class="dropdown-item devEditEle" @click="redirecttourl('edit', data)" href="javascript:void(0);">
                                          <div class="d-flex align-items-center">
                                            <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                            <span>Edit</span>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </template>
                              <template v-if="dlemailist.length == 0">
                                <tr>
                                  <td colspan="14" style="text-align: center;">No record found</td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pageCenter text-center">
                <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  <script>
  import axios from "axios";
  import apiUrl from "../../constants";
  import moment from "moment";
  import VueElementLoading from "vue-element-loading";
  import vSelect from "vue-select";
  import { EditIcon,ListIcon,GridIcon,MessageSquareIcon,PlusCircleIcon,XIcon,MoreVerticalIcon,Edit2Icon,FileTextIcon,MonitorIcon,UserIcon,SearchIcon,ExternalLinkIcon,PlusSquareIcon,EyeIcon,PlusIcon,MinusSquareIcon,ClipboardIcon } from "vue-feather-icons";
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import Pagination from "vue-pagination-2";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import profilepic from '../profilepic.vue';
  import commonMethods from '../../utils/commonMethods';
  
  export default {
    name: "dlemailist",
    mixins: [ commonMethods ],
  
    components: {
      Loading,ListIcon,GridIcon,
      EyeIcon,
      PlusSquareIcon,
      VueElementLoading,
      vSelect,
      ExternalLinkIcon,
      EditIcon,
      Treeselect,
      XIcon,
      Pagination,
      PlusIcon,
      SearchIcon,
      MinusSquareIcon,
      ClipboardIcon,
      MessageSquareIcon,
      FileTextIcon,
      MonitorIcon,
      profilepic,PlusCircleIcon,MoreVerticalIcon,Edit2Icon,DatePicker,UserIcon,
    },
    data() {
      return {
        componentKey: 0,
        currentTaskDescription: {},
        taskcodelist:null,
        showcreatetaskbtn: false,
        clickedTaskData: null,
        isBothDateSelected: false,
        systemEngieerRole: apiUrl.systemEngieerRole,
        search: {
          searchfieldname:null,
          searchprojectname: null,
          searchvalue:null,
          allotedto: null,
          allotedtolist:null,
          fieldlist:null,
          projectnameopts:null,
          valueslist:[],
          searchvalue:null,
          tktype:null,
          mrtypelist:[],
          sevairty:null,
          sevairtylist:null,
          priority:null,
          email:null,
          isactive: null,
          prioritylist:apiUrl.issueclassification
        },
        isactivelist: [
          {id: 1, label:'Active'},
          {id: 0, label:'Inactive'},
        ],
        isSearchRequestActive: false,
        selectedLevel: 10,
        level: 1,
        isLoading: false,
        fullPage: true,
        dlemailist: [],
        showAddForm: false,
        selected: null,
        editRowData: [],
        glbMdl: "",
        glbst: "",
        TimeFormat: apiUrl.TimeFormat,
        taskversionlist: [],
        taskBlured: false,
        valid: false,
        projvalue: null,
        applvalue: null,
        startdate: null,
        isAddState: false,
        totalcount: 0,
        pageCount: 0,
        page: 1,
        pagelimit: apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset: 1,
        pagefeatures: [],
        projectnameist: [],
        loginuscondition: "",
        attachment: [],
        attachmentview: null,
        isDetActive: false,
        isListDetActive:false,
        isListActive:false,
        displayblk:'displayblk',
        isgridView:false,
        blog_list_wrapper :'blog-list-wrapper',
        card: "card",
        isRequestActive: false,
        card_content: "card-content",
        border_warning: "border-warning",
        collapse: "collapse",
        showtabledata:true,
        allottedto: null,
        loginusername:'',
        sitebluecolor:"sitebluecolor",
        activegridlink:true,
        activelistlink:false,
        countStatus: null,
        taskcountClick:false,
        tokendata:null,
        isresponce:false,
        isAdminRole: false,
        isThisBusinessRole: false,
        inputtypeselection:null,
        roleid: 0,
        TKRosterFields:apiUrl.TKRosterFields.sort((a, b) => a.label.localeCompare(b.label)),
        errors:{
          fromdate: null
        }
      };
    },
    mounted() {
     let permission = this.$Service.verifyAccess();
      if(permission){
       let userdata = window.localStorage.getItem("userdata");
        if (userdata) {
          userdata = JSON.parse(userdata);
          this.undt = userdata;
          this.tokendata = window.localStorage.getItem('token');
          this.loginuscondition = userdata.userid;
          this.loginusername=userdata.username
          this.roleid = this.undt.roleid;
          this.isThisBusinessRole = true;
          this.search.isactive =[1];
          this.getProjectAllocatedToEmp();
          this.roasterlist()
          this.sortTypeList([], 'alltypes')
          this.getApplicationdata("t44", "ticket");
        }
      }
      $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
    },
    created() {
        this.pagefeatures = {}
        let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
          if (element.page === '/configuration/dlemailist') {
            this.pagefeatures[element.featurename] = element.featureaccess
          }
        })
      },
    methods: {
      mysearchrequestollapse: function () {
        this.isSearchRequestActive = !this.isSearchRequestActive;
      },
      myListView: function () {
        this.isListDetActive = true;
        this.isgridView=true;
        this.activegridlink=false
        this.activelistlink=true
        $('html, body').animate({
            scrollTop: $("#scrollToListGrid").offset().top -70
          });
      },
      myGridView(){
          this.isListDetActive = false;
          this.isgridView=false;
          this.activegridlink=true
          this.activelistlink=false
          $('html, body').animate({
            scrollTop: $("#scrollToListGrid").offset().top -70
          });
      },
      redirecttourl:function(btnstate,rowdata){
        if(rowdata){
          rowdata.backbtnstate=btnstate
         if(btnstate=="edit"){
            this.$router.push({ 
              path: `/configuration/dlemailupdate/?rid=${rowdata.id}`,
              params: {
                backbtnstate:btnstate,
                clickeddata:rowdata
              }
            })
          } 
        }else{
        if(btnstate=="create"){
          this.$router.push({
            name: 'dlemailcreate',
            path: `/configuration/dlemailcreate`,
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata
            }
          })
        }
      }
        
      },
      resetRecords: function () {
        this.search.searchprojectname = null;
        this.search.searchfieldname = null;
        this.search.allotedto = null;
        this.search.priority =null;
        this.search.sevairty =null;
        this.search.tktype =null;
        this.search.email =null;
        this.page=1;
        this.search.isactive =[1];
        this.roasterlist();
      },
      clickCallback: function (pageNum) {
        this.pageoffset = pageNum;
        this.page = pageNum;
        this.currPageNum = pageNum - 1;
        this.roasterlist();
        setTimeout(function(){
          $('html, body').animate({
              scrollTop: $("#scrollToListGrid").offset().top -70
          });
        }, 500);
      },
      getfieldame(field) {
        if(this.search.fieldlist && this.search.fieldlist !=null && this.search.fieldlist.length>0){        
          let fiel = this.search.fieldlist.filter(fiel => fiel.id == field)
          if(fiel && fiel.length) {
            return fiel[0].label
          }
          return null
        }
      },
      roasterlist(search,flag) {
        this.isLoading = true;

        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          isactive: this.search.isactive,
          limit: apiUrl.LIMIT,
        }
        if(flag == 1){
          this.input.offset = 1;
          this.currPageNum = 0;
        }else{
          this.input.offset = this.pageoffset;
        }
         if(search) {
          this.input.projectid = search.searchprojectname;
          this.input.fieldname = this.getfieldame(search.searchfieldname);
          this.input.fieldvalue = search.searchvalue != null ? search.searchvalue.toString() : '';
          this.input.allotedto = search.allotedto;
          this.input.typename =search.tktype
          this.input.sevairty = search.sevairty
          this.input.priority = search.priority
          this.input.email = search.email

        }
      this.input.isSearch = false;
        var url = "api/roaster/getdlemailist";
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          this.isresponce=true
          if (result.data.errorCode == 0) {
            this.dlemailist = result.data.data.rows;
            this.totalcount = result.data.data.count;
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          }else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            }
           else {
            this.isDetActive = true;
            this.dlemailist = [];
            this.totalcount = 0;
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          }
        }).catch(e => {
          this.displayError(e)
        });
        this.getProjectAllocatedToEmp()
      },
      sortTypeList(typelist, type){
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            moduletype: 'ticket',
            typearr: [],
            projecttype: type
        };
        axios({
            method: "POST",
            url: 'api/ticket/sortTypeList',
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.inputtypeselection = 'drop-down'
                this.search.mrtypelist = result.data.data.rows;
            }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                this.search.mrtypelist = [];
            }
        });
      },
      tickettypeformrp(state){
        if(state && this.search.searchprojectname == apiUrl.merchandisingProId){
          this.sortTypeList([], 'merchandising')
        } else{
          this.sortTypeList([], 'alltypes')
        }
      },
      getApplicationdata: function (mdl, curracttab) {
      this.pageoffset = 1;
      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      let apiURL = "api/master/commonmaster/list";
       if (mdl == "t44") {
        apiURL = "api/master/commonmaster/customselect";
        /* this.inputappl.projectmoduleid=this.ticket.project */
        this.inputappl.isSearch = false;
        this.inputappl.section = "issue";
        this.inputappl.isFilterSelect = 1;
        this.inputappl.isFilterPayload = {};
      } 
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        if (result.data.errorCode == 0) {
            if (mdl == "t44") {
            if (curracttab == "ticket") {
              this.search.sevairtylist = result.data.data.rows;
              return true;
            }
          } 
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
      dateMONFormat(value){
        if(value){
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        }else{
          return "";
        }
      },
      dateMonthFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM YYYY HH:mm');
      },
      getProjectAllocatedToEmp() {
          this.isLoading = true
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            roleid : this.undt.roleid,
            isfrompage:'listingpage'
          };
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.search.projectnameopts = result.data.data;
            } else {
                this.search.projectnameopts = [];
            }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getEmpallocatedtoProject() {
        this.isLoading = true;
        //let currtab = 'ticket'
        this.input = { projectid: this.search.searchprojectname,useremail: this.undt.username,empcode: this.undt.userid};
      if (this.isThisBusinessRole) {
        this.input.roleid = 484;
      }
      if (this.search.searchprojectname == this.itHelpDesk) {
        this.input.roleid = null;
      }
      axios({
        method: "POST",
        url: "api/listing/getEmpallocatedtoProject",
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
            this.search.allotedtolist = result.data.data;
            let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
        }
      });
    },
    getticketfield(searchprojectname) {
      this.isLoading = true;
      let url = "api/roaster/getticketfield";
      this.input = {
        empcode: this.undt.userid,
        useremail  : this.undt.username,
        projectid: [searchprojectname]
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
        
          this.search.fieldlist = result.data.data.concat(this.TKRosterFields);
          
        } else {
          this.search.fieldlist = [];
        }
      });
    },
    getticketfieldvalues() {
      this.isLoading = true;
      let url = "api/roaster/getticketfieldvalues";
      this.input = {
        empcode: this.undt.userid,
        useremail: this.undt.username,
        projectid: this.search.searchprojectname,
        field: this.search.searchfieldname
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      })
        .then((result) => {
          this.isLoading = false;
          if (result.data.errorCode === 0) {
            this.search.valueslist = result.data.data[0].label.map((item) => {
              return {
                label: item.optionvalue,
                id: item.optionvalue,
              };
            });
          } else {
            console.error("API error:", result.data.msg);
            this.search.valueslist = [];
          }
        })
        .catch((error) => {
          console.error("Error fetching ticket values:", error);
          this.search.valueslist = [];
        });
    },
      selSearchProject(state) {
        this.search.searchprojectname = null
        this.search.searchprojectname = state
        this.search.allotedto = null;
        this.search.allotedtolist = [];
        if(!state) {
          this.search.fieldlist = null;
          this.search.valueslist =null;
          this.search.allotedto = null;
          this.search.allotedtolist = [];
        }
        else {
        this.getEmpallocatedtoProject(state)
        this.getticketfield(state)
      }
      },
      selissuecsfield(state, value) {
      if(!this.TKRosterFields.some(i=>i.id === state)){
      this.search.field = state;
      if (!state) {
      this.search.searchvalue = null;
      this.search.valueslist = [];

      } else {
      let dp = this.search.fieldlist.filter((v) => {
      if(v.id==state) 
      return v;
      });
      this.inputtypeselection = dp.length > 0 ? dp[0].fieldtype : null
      this.search.searchvalue = null;
      this.getticketfieldvalues(state,this.search.searchprojectname)
      }
      }
      if(this.TKRosterFields.some(i=>i.id === state)){
      if(state=='Ticket Category'){
        this.search.searchvalue = null;
      this.getprojectmodules(this.search.searchprojectname)
      }
      if(state=='Ticket Sub Category'){
        this.search.searchvalue = null;
      this.getAppModulesbyprojectid(this.search.searchprojectname)
      }
      }

      },
      getprojectmodules(projecteid) {
      let url = '';
        url = 'api/listing/getpromodulebyapplicationid';
    
      this.input = {
        projecteid: projecteid,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.inputtypeselection = 'drop-down'
          this.search.valueslist = result.data.data;
        }else {
          this.search.valueslist = [];
        }  
      }).catch(e => {
        this.displayError(e)
      });
  },
  getAppModulesbyprojectid(projectid) {
    this.input = {
      projectid: projectid,
      useremail: this.undt.username,
      empcode: this.undt.userid,
    };
    axios({
      method: "POST",
      url: 'api/listing/getappmodulenamebyprojectid',
      data: this.input,
      'headers':{'authorization':this.tokendata}
    }).then((result) => {
      this.isLoading = false;
      if (result.data.errorCode == 0) {
        this.inputtypeselection = 'drop-down'
        this.search.valueslist = result.data.data;
      } else {
        this.search.valueslist = [];
      }
    }).catch(e => {
      this.displayError(e)
    });
  },
  selectedfromdate(){
    if((this.search.fromdate && this.search.todate) || (!this.search.fromdate && !this.search.todate)){
      this.isBothDateSelected = false;
      this.errors.fromdate ='';
    }else if((this.search.fromdate && !this.search.todate) ||( !this.search.fromdate && this.search.todate)){
      this.isBothDateSelected = true;
    }
  },
  selectedtodate(){
    if((this.search.fromdate && this.search.todate) || (!this.search.fromdate && !this.search.todate)){
      this.isBothDateSelected = false;
      this.errors.fromdate ='';
    }else if((this.search.fromdate && !this.search.todate) ||( !this.search.fromdate && this.search.todate)){
      this.isBothDateSelected = true;
    }
  },
  validateDate(mdl) {
    let fromdate = this.search.fromdate;
    let todate = this.search.todate;
    if(mdl === 'todate') {
      if ((Date.parse(todate) < Date.parse(fromdate))) {
        this.errors.fromdate = "Shift to date should be greater than shift from date";
        this.isBothDateSelected = true;
        this.search.todate = '';
      } else {
        this.errors.fromdate = '';
      }
    } 
    // else if(mdl === 'fromdate') {
    //   if ((Date.parse(todate) < Date.parse(fromdate))) {
    //     this.errors.fromdate = "Shift from date should be lesser than shift to date";
    //     this.search.fromdate = '';
    //     this.isBothDateSelected = true;
    //   } else {
    //     this.errors.fromdate = '';
    //   }
    // }
  },
      ExportXL() {
        this.isLoading = true;
        this.exportxlsdata = [];
        this.exporterr = "";
        this.input = {
          projectid : this.search.searchprojectname,
          fieldname: this.getfieldame(this.search.searchfieldname),
          fieldvalue: this.search.searchvalue != null ? this.search.searchvalue.toString() : '',
          isactive: this.search.isactive,
          allotedto : this.search.allotedto,
          priority: this.search.priority,
          sevairty: this.search.sevairty,
          email: this.search.email,
          typename: this.search.tktype,
          useremail: this.undt.username,
          empid : this.undt.userid,
          empcode: this.undt.userid,
          limit: null,
          offset: null
        };
        axios({
          method: "POST",
          url: `api/roaster/getdlemailist`,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.exportxlsdata = result.data.data.rows;
            var filename = "";
            this.download(this.exportxlsdata,filename)
          }else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }
          
          if (result.data.status) {
            this.isDetActive = true;
          } 
        }).catch(e => {
          this.displayError(e)
        });
      },
  
      download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          var ShowLabel = 'DlemaiListReport_'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
          if (ShowLabel) {
            let row = ''
            for (let index in arrData[0]) {
                row += index + ','
            }
            row = row.slice(0, -1)
            CSV = 'Sr#,Dlemailcode, Project Name, Field Name, Values,Sender Host,Receiver Host,Dl Email,Type,Priority,Severity, Assigned Name, Created Date' + '\r\n'
          }
  
          var row = ''
          for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let id = index.hasOwnProperty('id') ? index.id : '';
            let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
            let fieldname = index.hasOwnProperty('fieldname') ? index.fieldname : '';
            let fieldvalue ;
            if(fieldname && fieldname =='Ticket Category'){
              fieldvalue = index.hasOwnProperty('category') ? index.category ==null || index.category =='' || index.category ==' ' ? 'NA' : index.category : 'NA';
            }else if(fieldname && fieldname =='Ticket Sub Category'){
              fieldvalue = index.hasOwnProperty('subcategory') ? index.subcategory ==null || index.subcategory =='' || index.subcategory ==' ' ? 'NA' : index.subcategory : 'NA';
            }else{
              fieldvalue = index.hasOwnProperty('fieldvalue') ? index.fieldvalue ==null || index.fieldvalue =='' || index.fieldvalue ==' ' ? 'NA' : index.fieldvalue : 'NA';
            }
            let sendhost = index.hasOwnProperty('sendhost') ? index.sendhost : '';
            let dlhost = index.hasOwnProperty('dlhost') ? index.dlhost : '';
            let dlemail = index.hasOwnProperty('dlemail') ? index.dlemail : '';
            let typename = index.hasOwnProperty('typename') ? index.typename : '';
            let priority = index.hasOwnProperty('priority') ? index.priority : '';
            let sevairtyname = index.hasOwnProperty('sevairtyname') ? index.sevairtyname : '';
            let empname = index.hasOwnProperty('empname') ? index.empname ==null || index.empname.toLowerCase() =='na'?'':index.empname : '';         
            let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';

            createddate = this.dateMONFormat(createddate);
              row += '"' + srno + '",',
              row += '"' + id + '",',
              row += '"' + projectname + '",',
              row += '"' + fieldname + '",',
              row += '"' + fieldvalue + '",',
              row += '"' + sendhost + '",',
              row += '"' + dlhost + '",',
              row += '"' + dlemail + '",',
              row += '"' + typename + '",',
              row += '"' + priority + '",',
              row += '"' + sevairtyname + '",',
              row += '"' + empname + '",',           
              row += '"' + createddate + '",'
              row += '\r\n'
          }
          CSV += row
          if (CSV === '') {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
            return;
          }
          var fileName = 'DlemailListReport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
          var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
          var link = document.createElement('a')
          link.href = uri
          link.style = 'visibility:hidden'
          link.download = fileName + '.csv'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          Swal.fire({
            title: "Success!",
            text: "Export Report successful",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
      } else {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });      
      }
    },
    export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
    },
  };
  </script>
  <style scoped>
  .displayblk{
      display:none !important;
  }
  .createbtn {
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
  }
  .bordertable .row1{
    color:black;
  }
  </style>
  