<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper mt-1">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h3 class="content-header-title float-left mb-1">Test Cycle Set</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Test Execution</li>

                <li
                  v-if="pagefeatures.productstatus"
                  class="breadcrumb-item sitebluecolor cursorpointercls"
                >
                  <router-link
                    :to="{ name: 'TestCycleSetProStatusList', params: { search: true } }"
                    >Product Cycle Set's List</router-link
                  >
                </li>
                <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">
                  <router-link
                    :to="{ name: 'TestCycleSetList', params: { search: true } }"
                    >Test Cycle Set's</router-link
                  >
                </li>
                <li class="breadcrumb-item sitebluecolor cursorpointercls">
                  View Test Cycle Set
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div
          class="card border-warning"
          style="
            box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
            margin-bottom: 1rem !important;
          "
        >
          <div class="card">
            <div class="card-header py-1 px-0" style="cursor: pointer">
              <div
                class="container-fluid px-2"
                style="border-bottom: 1px solid #eee; padding-bottom: 15px"
              >
                <div class="row">
                  <div class="col-md-10 d-flex align-items-center">
                    <div class="avatar bg-light-primary rounded">
                      <div class="avatar-content">
                        <eye-icon
                          size="1.5x"
                          class="custom-class avatar-icon font-medium-3"
                        ></eye-icon>
                      </div>
                    </div>
                    <h3 class="card-title ml-2 d-inline-flex text-primary">
                      Test Cycle Set Code: {{ testcycle.cyclesetcode }}
                    </h3>
                  </div>
                  <div class="col-md-2 text-right">
                    <!-- <router-link to="/testexecution/TestCaseManagement_listView" > -->
                      <button class="btn btn-sm btn-relief-success" @click="redirecttourl()" href="javascript:void(0);">
                        <span class="mobile_hide_create_text">Excel View</span> <plus-circle-icon size="1.5x" class="custom-class mr-1"></plus-circle-icon>
                      </button>
                    <!-- </router-link> -->
                  </div>
                </div>
              </div>
              <div class="container px-2">
                <section class="modern-horizontal-wizard mb-1">
                  <div class="bs-stepper wizard-modern modern-wizard-example">
                    <div class="bs-stepper-header">
                      <div class="step" data-target="#account-details-modern">
                        <button type="button" class="step-trigger">
                          <div class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                              <calendar-icon
                                size="1.5x"
                                class="custom-class avatar-icon font-medium-3"
                              ></calendar-icon>
                            </div>
                          </div>
                          <span class="bs-stepper-label">
                            <span class="bs-stepper-title">Created Date</span>
                            <span class="bs-stepper-subtitle">{{
                              dateMonthFormat(testcycle.createddate)
                            }}</span>
                          </span>
                        </button>
                      </div>
                      <div class="line">
                        <em class="las la-angle-double-right font-medium-2"></em>
                      </div>
                      <div class="step" data-target="#personal-info-modern">
                        <button type="button" class="step-trigger">
                          <div class="avatar bg-light-success rounded">
                            <div class="avatar-content">
                              <calendar-icon
                                size="1.5x"
                                class="custom-class avatar-icon font-medium-3"
                              ></calendar-icon>
                            </div>
                          </div>
                          <span class="bs-stepper-label">
                            <span class="bs-stepper-title">Start Date</span>
                            <span class="bs-stepper-subtitle">{{
                              dateMonthFormat(testcycle.startdate)
                            }}</span>
                          </span>
                        </button>
                      </div>

                      <div class="line">
                        <em class="las la-angle-double-right font-medium-2"></em>
                      </div>
                      <div class="step" data-target="#social-links-modern">
                        <button type="button" class="step-trigger">
                          <div class="avatar bg-light-danger rounded">
                            <div class="avatar-content">
                              <calendar-icon
                                size="1.5x"
                                class="custom-class avatar-icon font-medium-3"
                              ></calendar-icon>
                            </div>
                          </div>
                          <span class="bs-stepper-label">
                            <span class="bs-stepper-title">Last Modify Date</span>
                            <span class="bs-stepper-subtitle">{{
                              dateMonthFormat(testcycle.lastmodifieddate)
                            }}</span>
                          </span>
                        </button>
                      </div>
                      <div class="line">
                        <em class="las la-angle-double-right font-medium-2"></em>
                      </div>
                      <div class="step" data-target="#social-links-modern">
                        <button type="button" class="step-trigger">
                          <div class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                              <calendar-icon
                                size="1.5x"
                                class="custom-class avatar-icon font-medium-3"
                              ></calendar-icon>
                            </div>
                          </div>
                          <span class="bs-stepper-label">
                            <span class="bs-stepper-title">Review Date</span>
                            <span class="bs-stepper-subtitle">{{
                              dateMonthFormat(testcycle.revieweddate)
                                ? dateMonthFormat(testcycle.revieweddate)
                                : "NA"
                            }}</span>
                          </span>
                        </button>
                      </div>
                      <div class="line">
                        <em class="las la-angle-double-right font-medium-2"></em>
                      </div>
                      <div class="step" data-target="#address-step-modern">
                        <button type="button" class="step-trigger">
                          <div class="avatar bg-light-success rounded">
                            <div class="avatar-content">
                              <calendar-icon
                                size="1.5x"
                                class="custom-class avatar-icon font-medium-3"
                              ></calendar-icon>
                            </div>
                          </div>
                          <span class="bs-stepper-label">
                            <span class="bs-stepper-title"
                              >Product Pending for Review</span
                            >
                            <span class="bs-stepper-subtitle">{{
                              dateMonthFormat(testcycle.productpendingreviewdate)
                                ? dateMonthFormat(testcycle.productpendingreviewdate)
                                : "NA"
                            }}</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
                <div class="row my-1">
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-success rounded">
                              <div class="avatar-content">
                                <em class="las la-user-edit font-medium-3"></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Cycle Set Name</h6>
                              <small>{{ testcycle.cyclename }} </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-success rounded">
                              <div class="avatar-content">
                                <em class="las la-sync-alt font-medium-5"></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Business Task Code</h6>
                              <small>{{ testcycle.businesscode }} </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-warning rounded">
                              <div class="avatar-content">
                                <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Created By</h6>
                              <small class="capitalisetext" style="font-weight: 500">{{
                                testcycle.createdby
                              }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-primary rounded">
                              <div class="avatar-content">
                                <settings-icon
                                  size="1.5x"
                                  class="custom-class avatar-icon font-medium-3"
                                ></settings-icon>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Versions</h6>
                              <small>{{ testcycle.version }} </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-primary rounded">
                              <div class="avatar-content">
                                <em class="las la-id-card font-medium-5"></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Business Task Name</h6>
                              <small>{{ testcycle.businessname }} </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-primary rounded">
                              <div class="avatar-content">
                                <UserIcon
                                  size="1.5x"
                                  class="custom-class avatar-icon font-medium-3"
                                ></UserIcon>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Last Modified By</h6>
                              <small class="capitalisetext" style="font-weight: 500">{{
                                testcycle.lastmodifiedby
                              }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-warning rounded">
                              <div class="avatar-content">
                                <em class="las la-clipboard-list font-medium-5"></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Status</h6>
                              <small class="capitalisetext" style="font-weight: 500">{{
                                editRowData.status
                              }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-success rounded">
                              <div class="avatar-content">
                                <em class="las la-users font-medium-5"></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Group Name</h6>
                              <span
                                class="font-weight-bold"
                                v-if="testcycle.mappingname"
                                v-html="showmappingname(testcycle.mappingname)"
                              ></span>
                              <small class="font-weight-bold" v-else>NA</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-warning rounded">
                              <div class="avatar-content">
                                <em class="las la-calendar font-medium-5"></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Cycle Last Run Date</h6>
                              <small class="capitalisetext" style="font-weight: 500">{{
                                dateMonthFormat(testcycle.rundate)
                                  ? dateMonthFormat(testcycle.rundate)
                                  : "NA"
                              }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-primary rounded">
                              <div class="avatar-content">
                                <em
                                  class="i_icon_st la-sitemap las lab font-medium-5"
                                ></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Total Scripts</h6>
                              <small class="capitalisetext" style="font-weight: 500">{{
                                testcycle.nooftestcase
                              }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-danger rounded">
                              <div class="avatar-content">
                                <em class="las la-sync-alt font-medium-5"></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">No Of Times Cycle Run</h6>
                              <small class="capitalisetext" style="font-weight: 500">{{
                                testcycle.cycleruncount ? testcycle.cycleruncount : "0"
                              }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-success rounded">
                              <div class="avatar-content">
                                <em class="las la-calendar font-medium-5"></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Cycle Last Finish Date</h6>
                              <small class="capitalisetext" style="font-weight: 500">{{
                                dateMonthFormat(testcycle.finishdate)
                                  ? dateMonthFormat(testcycle.finishdate)
                                  : "NA"
                              }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-success rounded">
                              <div class="avatar-content">
                                <em class="las la-thumbs-up font-medium-5"></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Total Last Pass Scripts</h6>
                              <small class="capitalisetext" style="font-weight: 500">{{
                                testcycle.spasscount ? testcycle.spasscount : 0
                              }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-danger rounded">
                              <div class="avatar-content">
                                <em class="las la-thumbs-down font-medium-5"></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Total Last Fail Scripts</h6>
                              <small class="capitalisetext" style="font-weight: 500">{{
                                testcycle.sfailcount ? testcycle.sfailcount : 0
                              }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item" style="overflow: auto">
                          <div class="media">
                            <div class="avatar bg-light-primary rounded">
                              <div class="avatar-content">
                                <em class="las la-user-edit font-medium-3"></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Product Comment By</h6>
                              {{ testcycle.commentedby ? testcycle.commentedby : "NA" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="card-transaction">
                      <div class="card-body px-0 py-1">
                        <div class="transaction-item" style="overflow: auto">
                          <div class="media">
                            <div class="avatar bg-light-primary rounded">
                              <div class="avatar-content">
                                <monitor-icon
                                  size="1.5x"
                                  class="custom-class avatar-icon font-medium-3"
                                ></monitor-icon>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Product Comment</h6>
                              <span
                                v-html="
                                  testcycle.statuscomment
                                    ? formatData(testcycle.statuscomment)
                                    : 'NA'
                                "
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="card-transaction mb-1 border rounded">
                      <div class="card-body px-1 py-1">
                        <div class="transaction-item" style="overflow: auto">
                          <div class="media">
                            <div class="avatar bg-light-success rounded">
                              <div class="avatar-content">
                                <monitor-icon
                                  size="1.5x"
                                  class="custom-class avatar-icon font-medium-3"
                                ></monitor-icon>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Cycle Last Finish Comment</h6>
                              <!-- {{ testcycle.finishcomment ? testcycle.finishcomment : 'NA'}} -->
                              <div
                                class="text-center tast_case_groupingview_readmore mx-0"
                              >
                                <div
                                  class="col-md-12 px-0 mt-1 tast_case_groupingview_readmore"
                                >
                                  <div class="custom-container case_bg_17">
                                    <div
                                      v-if="testcycle.finishcomment"
                                      v-bind:class="[readmore]"
                                      :id="'cycle_readmore4'"
                                    >
                                      <span
                                        class="fc_m16_btcode_text"
                                        v-html="formatData(testcycle.finishcomment)"
                                      ></span>
                                    </div>
                                    <div
                                      v-else
                                      v-bind:class="[readmore]"
                                      :id="'cycle_readmore4'"
                                    >
                                      <span class="fc_m16_btcode_text">NA</span>
                                    </div>
                                    <div
                                      class="cycle_viewmoretext"
                                      v-if="
                                        testcycle.finishcomment &&
                                        testcycle.finishcomment.length > 150
                                      "
                                    >
                                      <a class="btn">
                                        <strong :id="'id4'" @click="idclick4(indexx)">
                                          Read More
                                        </strong>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4"></div>
                  <div class="col-md-4"></div>
                  <div
                    class="col-md-12"
                    v-if="
                      pagefeatures.productstatus &&
                      (editRowData.status == 'Product Pending for Review' ||
                        editRowData.status == 'In Product Review' ||
                        editRowData.status == 'Product Declined' ||
                        editRowData.status == 'Approved')
                    "
                  >
                    <fieldset class="fieldset_border_vi mb-2 mt-1">
                      <legend>Update Status</legend>
                      <div class="card-body px-0 pt-0 pb-0">
                        <form>
                          <div class="row">
                            <div class="form-group col-md-4">
                              <div class="form-group mb-1">
                                <h6 class="transaction-title">Status</h6>
                                <input
                                  placeholder="Select Status"
                                  class="form-control capitalisetext"
                                  v-model="testcycle.status"
                                  :options="statuslist"
                                  v-if="statuslist.length <= 1"
                                  disabled
                                />
                                <treeselect
                                  class="projectmdllistdd capitalisetext"
                                  placeholder="Select Status"
                                  v-model="testcycle.status"
                                  :options="statuslist"
                                  @input="selstatus"
                                  v-else
                                />
                              </div>
                            </div>
                            <div
                              class="form-group col-md-4"
                              v-if="
                                pagefeatures.productstatus &&
                                testcycle.status == 'Product Approved'
                              "
                            >
                              <div class="form-check form-check-inline mt-2">
                                <div
                                  class="custom-control custom-switch custom-switch-secondary"
                                >
                                  <input
                                    type="checkbox"
                                    class="form-check-input ml-1"
                                    id="business-checkbox-2"
                                    @change="changevalue($event)"
                                    v-model="bulkscriptapprove"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="business-checkbox-2"
                                  ></label>
                                </div>
                                <lable
                                  class="form-check-label transaction-title"
                                  style="font-size: 14px"
                                  >All Script Bulk Approve</lable
                                >
                              </div>
                            </div>
                            <div
                              class="form-group col-md-12 newClass mb-0"
                              v-if="
                                testcycle.status == 'Product Approved' ||
                                testcycle.status == 'Product Declined' ||
                                (resultids && resultids.length > 0)
                              "
                            >
                              <div class="form-group mb-0">
                                <h6 class="transaction-title">Comment</h6>
                                <ckeditor
                                  :editor="editor"
                                  placeholder="Comment"
                                  v-model="testcycle.comment"
                                  :config="editorConfig"
                                  tag-name="textarea"
                                />
                                <div
                                  class="errorinputmsg"
                                  v-if="
                                    !testcycle.comment && !validText(testcycle.comment)
                                  "
                                >
                                  Comment Is Required
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="form-group mb-0 text-right">
                        <button
                          type="button"
                          class="btn btn-relief-primary ml-1 mt-1"
                          id="submitbtnt39"
                          v-if="statuslist.length <= 1"
                          disabled
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          class="btn btn-relief-primary ml-1 mt-1"
                          @click="
                            updatebulkscriptstatus(
                              testcycle,
                              bulkscriptapprove,
                              testcycle.comment
                            )
                          "
                          id="submitbtnt39"
                          v-else-if="
                            testcycle.status == 'Product Approved' &&
                            bulkscriptapprove == 1
                          "
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          class="btn btn-relief-primary ml-1 mt-1"
                          @click="
                            updatebulkscriptstatus(
                              testcycle,
                              null,
                              testcycle.comment,
                              resultids
                            )
                          "
                          id="submitbtnt39"
                          v-else-if="resultids && resultids.length > 0"
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          class="btn btn-relief-primary ml-1 mt-1"
                          @click="
                            updatebulkscriptstatus(testcycle, null, testcycle.comment)
                          "
                          id="submitbtnt39"
                          v-else-if="
                            !bulkscriptapprove &&
                            bulkscriptapprove == null &&
                            (testcycle.status == 'Product Approved' ||
                              testcycle.status == 'Product Declined')
                          "
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          class="btn btn-relief-primary ml-1"
                          @click="savestatus(testcycle)"
                          id="submitbtnt39"
                          v-else
                        >
                          Submit
                        </button>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div
                  class="container px-0"
                  v-if="testcycle.cyclehistory && testcycle.cyclehistory.length > 0"
                >
                  <div class="row">
                    <div class="col-md-8">
                      <div class="avatar bg-light-warning rounded">
                        <div class="avatar-content">
                          <em class="las la-file-alt font-medium-5"></em>
                        </div>
                      </div>
                      <h3 class="card-title ml-2 d-inline-flex">Cycle History</h3>
                    </div>
                  </div>
                  <div class="card-body pt-1 px-0">
                    <div class="mb-1 px-0">
                      <div
                        class="table-responsive datatableview"
                        style="overflow-x: scroll !important"
                      >
                        <table
                          aria-describedby="cyclehistorytbl"
                          id="cyclehistorytbl"
                          class="table table-sm dataTableListing table-hover table-striped table-bordered"
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                class="text-center align-middle py-1"
                                style="min-width: 70px"
                              >
                                CYCLE NO
                              </th>
                              <th
                                scope="col"
                                class="text-center align-middle"
                                style="min-width: 180px"
                              >
                                Cycle Set Code
                              </th>
                              <th scope="col" class="text-center align-middle">
                                Total Script
                              </th>
                              <th scope="col" class="text-center align-middle">
                                Total Pass
                              </th>
                              <th scope="col" class="text-center align-middle">
                                Total Fail
                              </th>
                              <th
                                scope="col"
                                class="text-center align-middle"
                                style="min-width: 180px"
                              >
                                Cycle Run Date
                              </th>
                              <th
                                scope="col"
                                class="text-center align-middle"
                                style="min-width: 180px"
                              >
                                Cycle Finish date
                              </th>
                              <th
                                scope="col"
                                class="text-center align-middle"
                                style="min-width: 180px"
                              >
                                Cycle Finish Comment
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(hlist, indexx) in testcycle.cyclehistory"
                              v-bind:key="indexx"
                            >
                              <td data-column="CYCLE  NO" class="text-center">
                                {{ hlist.cyclerunno }}
                              </td>
                              <td data-column="Cycle Set Code" class="text-center">
                                {{ hlist.cyclecode }}
                              </td>
                              <td data-column="Total Script" class="text-center">
                                {{ hlist.nooftestcase ? hlist.nooftestcase : 0 }}
                              </td>
                              <td data-column="Total Pass" class="text-center">
                                {{ hlist.totalpass ? hlist.totalpass : 0 }}
                              </td>
                              <td data-column="Total Fail" class="text-center">
                                {{ hlist.totalfail ? hlist.totalfail : 0 }}
                              </td>
                              <td data-column="Cycle Run Date" class="text-center">
                                {{
                                  dateMonthFormat(hlist.rundate)
                                    ? dateMonthFormat(hlist.rundate)
                                    : "NA"
                                }}
                              </td>
                              <td data-column="Cycle Finish date" class="text-center">
                                {{
                                  dateMonthFormat(hlist.finishdate)
                                    ? dateMonthFormat(hlist.finishdate)
                                    : "NA"
                                }}
                              </td>
                              <!-- <td class="text-center">
                                                        {{ hlist.finishcomment ? hlist.finishcomment : 'NA' }}
                                                    </td> -->
                              <td
                                data-column="Cycle Finish Comment"
                                class="text-center tast_case_groupingview_readmore"
                              >
                                <div
                                  class="col-md-12 mt-1 tast_case_groupingview_readmore"
                                >
                                  <div class="custom-container case_bg_17">
                                    <div
                                      v-if="hlist.finishcomment"
                                      v-bind:class="[readmore]"
                                      :id="'cycle_readmore3' + indexx"
                                    >
                                      <span
                                        class="fc_m16_btcode_text"
                                        v-html="formatData(hlist.finishcomment)"
                                      ></span>
                                    </div>
                                    <div
                                      v-else
                                      v-bind:class="[readmore]"
                                      :id="'cycle_readmore3' + indexx"
                                    >
                                      <span class="fc_m16_btcode_text">NA</span>
                                    </div>
                                    <div
                                      class="cycle_viewmoretext"
                                      v-if="
                                        hlist.finishcomment &&
                                        hlist.finishcomment.length > 150
                                      "
                                    >
                                      <a class="btn">
                                        <strong
                                          :id="'id33' + indexx"
                                          @click="idclick3(indexx)"
                                        >
                                          Read More
                                        </strong>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr v-if="testcycle.cyclehistory.length == 0">
                              <td colspan="14" style="text-align: center">
                                No record found
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container px-0">
                  <div class="col-md-12 px-0">
                    <div class="mb-2 mx-0 business_fieldset_bg">
                      <div class="tab-content" id="myTabContent">
                        <div
                          class="row"
                          v-if="
                            pagefeatures.productstatus &&
                            (editRowData.status == 'Approved' ||
                              editRowData.status == 'Product Pending for Review' ||
                              editRowData.status == 'In Product Review')
                          "
                        >
                          <div
                            class="col-sm-12 col-md-12"
                            v-for="(datax, indexx) in editRowDatas"
                            v-bind:key="indexx"
                          >
                            <div class="tast_case_groupingview_01">
                              <div class="common_groupview_cases px-0">
                                <div class="cycle_group_bg d-flex">
                                  <span
                                    class="d-flex custom-control custom-checkbox"
                                    v-if="
                                      pagefeatures.productstatus &&
                                      editRowData.status == 'In Product Review'
                                    "
                                    style="position: relative; top: 2px"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input mr-1 mr-0"
                                      :id="'business-checkbox-2' + indexx"
                                      v-model="datax[1][0].isselect"
                                      @input="selectcheckbox($event, datax[1][0], indexx)"
                                    />
                                    <label
                                      class="custom-control-label mr-0"
                                      :for="'business-checkbox-2' + indexx"
                                    ></label>
                                  </span>
                                  <div
                                    class="common_groupview_cases_grouptext d-flex align-items-center"
                                    v-if="
                                      pagefeatures.productstatus &&
                                      editRowData.status == 'In Product Review'
                                    "
                                  >
                                    <span class="leg_cycle_text leg_cycle_text_setss">
                                      <span class="testCaseName01">
                                        Test Case Name :</span
                                      >
                                      <span class="testCaseinput02">
                                        <input
                                          type="text"
                                          class="form-control capitalisetext"
                                          v-model="datax[1][0].ispositionno"
                                          placeholder="Sequence"
                                          @keypress="validNumber"
                                        />
                                      </span>
                                    </span>
                                    <span class="testCasetext03">
                                      {{ datax[1][0].testcasename }}
                                    </span>
                                  </div>
                                  <span
                                    class="leg_cycle_text common_groupview_cases_grouptext"
                                    v-else
                                  >
                                    <span class="testCaseName01">Test Case Name 22: </span
                                    ><span class="test_casename_t-03 test_casename_twrap"
                                      >{{
                                        datax[1][0].ispositionno
                                          ? datax[1][0].ispositionno + "."
                                          : ""
                                      }}
                                      {{ datax[1][0].testcasename }}
                                    </span></span
                                  >
                                </div>

                                <div
                                  class="col-sm-12 col-md-12 px-0"
                                  v-for="(datax1, indexx1) in datax[1]"
                                  v-bind:key="indexx1"
                                >
                                  <div
                                    class="grouptestcase_views tast_case_groupingview_readmore tast_case_groupingview_bg01"
                                  >
                                    <div class="tast_case_groupingview_full-width">
                                      <div class="full-100">
                                        <span class="leg_group_testcase case_bg_11"
                                          >{{ datax1.tcscriptcode
                                          }}<em class="las la-angle-double-right"></em>
                                        </span>
                                        <span class="leg_group_testcase case_bg_12"
                                          >{{ datax1.country }}
                                          <em class="las la-angle-double-right"></em
                                        ></span>
                                        <span class="leg_group_testcase case_bg_13"
                                          >{{ datax1.enviornment }}
                                          <em class="las la-angle-double-right"></em
                                        ></span>
                                        <span class="leg_group_testcase case_bg_14"
                                          >{{ datax1.language }}
                                          <em class="las la-angle-double-right"></em
                                        ></span>
                                        <span class="leg_group_testcase case_bg_11"
                                          >{{ datax1.scripttype
                                          }}<em class="las la-angle-double-right"></em
                                        ></span>
                                        <span
                                          class="leg_group_testcase case_bg_12 capitalisetext"
                                          v-if="datax1.assignee"
                                          >{{
                                            datax1.assignee ? datax1.assigneename : "NA"
                                          }}<em class="las la-angle-double-right"></em
                                        ></span>

                                        <span
                                          class="leg_group_testcase case_bg_13 capitalisetext"
                                          v-if="
                                            !pagefeatures.productstatus &&
                                            datax1.resultstatus &&
                                            datax1.resultstatus == 'Product Declined'
                                          "
                                          >{{
                                            datax1.resultstatus
                                              ? datax1.resultstatus
                                              : "NA"
                                          }}</span
                                        >
                                        <span
                                          class="leg_group_testcase case_bg_15 capitalisetext"
                                          v-else-if="
                                            !pagefeatures.productstatus &&
                                            datax1.resultstatus &&
                                            datax1.resultstatus == 'Product Approved'
                                          "
                                          >{{
                                            datax1.resultstatus
                                              ? datax1.resultstatus
                                              : "NA"
                                          }}</span
                                        >
                                        <span
                                          class="leg_group_testcase case_bg_13 capitalisetext"
                                          v-else-if="
                                            editRowData.status != 'In Product Review' &&
                                            datax1.resultstatus &&
                                            datax1.resultstatus == 'Product Declined' &&
                                            editRowData.status != 'Product Declined'
                                          "
                                          >{{
                                            datax1.resultstatus
                                              ? datax1.resultstatus
                                              : "NA"
                                          }}</span
                                        >
                                        <span
                                          class="leg_group_testcase case_bg_15 capitalisetext"
                                          v-else-if="
                                            editRowData.status != 'In Product Review' &&
                                            datax1.resultstatus &&
                                            datax1.resultstatus == 'Product Approved' &&
                                            editRowData.status != 'Product Approved'
                                          "
                                          >{{
                                            datax1.resultstatus
                                              ? datax1.resultstatus
                                              : "NA"
                                          }}</span
                                        >
                                        <span
                                          class="leg_group_testcase case_bg_13 capitalisetext"
                                          v-else-if="
                                            editRowData.status == 'Product Declined' &&
                                            datax1.resultstatus &&
                                            datax1.resultstatus == 'Product Declined'
                                          "
                                          >{{
                                            datax1.resultstatus
                                              ? datax1.resultstatus
                                              : "NA"
                                          }}</span
                                        >

                                        <span
                                          class="leg_group_testcase case_bg_13 capitalisetext"
                                          v-if="
                                            datax1.resultstatus &&
                                            datax1.resultstatus == 'fail'
                                          "
                                          >{{
                                            datax1.resultstatus
                                              ? datax1.resultstatus
                                              : "NA"
                                          }}</span
                                        >
                                        <span
                                          class="leg_group_testcase case_bg_15 capitalisetext"
                                          v-else-if="
                                            datax1.resultstatus &&
                                            datax1.resultstatus == 'pass'
                                          "
                                          >{{
                                            datax1.resultstatus
                                              ? datax1.resultstatus
                                              : "NA"
                                          }}</span
                                        >
                                        <span
                                          class="leg_group_testcase case_bg_14 capitalisetext"
                                          v-else-if="
                                            editRowData.status != 'Product Approved' &&
                                            (datax1.resultstatus == 'NA' ||
                                              datax1.resultstatus == null ||
                                              datax1.resultstatus == '' ||
                                              datax1.resultstatus == ' ')
                                          "
                                          >NA</span
                                        >
                                        <div
                                          class="cycle_viewmoretext leg_group_testcase capitalisetext"
                                          v-if="
                                            datax1.scriptsteps &&
                                            datax1.scriptsteps.length > 5
                                          "
                                        >
                                          <a class="btn">
                                            <strong
                                              :id="'id01' + indexx + indexx1"
                                              @click="idclick01(indexx, indexx1)"
                                            >
                                              Read More
                                            </strong>
                                          </a>
                                        </div>
                                      </div>
                                      <div class="leg_group_testcase capitalisetext px-0">
                                        <div class="clearfix"></div>
                                        <div
                                          class="col-md-12 mt-0 tast_case_groupingview_readmore px-0"
                                        >
                                          <div class="custom-container case_bg_17">
                                            <div
                                              class="outer cycle_readmoresdis mb-1 testcyclereadmoreview"
                                              :id="'cycle_readmore002' + indexx + indexx1"
                                            >
                                              <span
                                                class="cycle_readmore_dis"
                                                v-if="datax1.scripttype == 'Manual'"
                                                >Steps :
                                              </span>
                                              <span
                                                class="cycle_readmore_dis"
                                                v-if="datax1.scripttype == 'Automation'"
                                                >Script :
                                              </span>
                                              <span
                                                class="fc_m16_btcode_text"
                                                v-html="formatData(datax1.scriptsteps)"
                                              ></span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- <span class="container px-0" style="float:left;" v-else-if="pagefeatures.productstatus && ((indexx1+1) == datax[1].length)">
                                                                        <div class="row">
                                                                            <div class="form-group col-md-12">
                                                                                <div class="form-group mb-0 p-0">
                                                                                    <div class="outer cycle_readmoresdis textcycel_product_comments mb-1" :id="'cycle_readmore5'+masterpage+indexx1" v-if="(editRowData.status !='In Product Review' && datax1.comment) || (editRowData.status =='In Product Review' && datax1.isscriptapproved ==null)">
                                                                                        <span class="cycle_readmore_dis">Product Comment: </span>
                                                                                        <span class="fc_m16_btcode_text" v-if="datax1.comment" v-html="formatData(datax1.comment)"></span>
                                                                                        <span class="fc_m16_btcode_text" v-else>NA</span>
                                                                                    </div>
                                                                                    <div class="cycle_viewmoretext" v-if="editRowData.status !='In Product Review' && (datax1.comment && datax1.comment.length >50)">
                                                                                        <a class="btn">
                                                                                        <strong :id="'id5'+masterpage+indexx1" @click="idclick5(indexx1)">
                                                                                            Read More
                                                                                        </strong>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </span> -->
                                    </div>
                                  </div>
                                  <span
                                    class="container px-0"
                                    style="float: left; margin-top: 5px"
                                    v-if="
                                      pagefeatures.productstatus &&
                                      indexx1 + 1 == datax[1].length &&
                                      editRowData.status == 'In Product Review'
                                    "
                                  >
                                    <div class="row">
                                      <div class="form-group col-md-4">
                                        <div class="form-group mb-0 p-0">
                                          <lable class="">Product Status:</lable>
                                          <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            placeholder="Select status"
                                            v-model="datax1.isscriptapproved"
                                            :options="testscriptreuslt"
                                            v-if="
                                              editRowData.status == 'In Product Review'
                                            "
                                          />
                                        </div>
                                      </div>
                                      <div class="form-group col-md-8">
                                        <div
                                          class="row mx-0"
                                          v-if="
                                            pagefeatures.productstatus &&
                                            (datax1.isscriptapproved == 0 ||
                                              datax1.isscriptapproved == 1) &&
                                            editRowData.status == 'In Product Review' &&
                                            editRowData.status != 'Product Approved'
                                          "
                                        >
                                          <div class="col-sm-12 col-md-6 px-0">
                                            <div class="form-group mb-0">
                                              <lable class="">Product Comment:</lable>
                                              <textarea
                                                type="text"
                                                class="form-control col-md-12"
                                                rows="3"
                                                placeholder="Enter Comment"
                                                v-model="datax1.comment"
                                              ></textarea>
                                              <div
                                                class="errorinputmsg"
                                                v-if="
                                                  !datax1.comment &&
                                                  !validText(datax1.comment)
                                                "
                                              >
                                                Comment Is Required
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="col-sm-3 leg_group_testcase mb-0"
                                            v-if="
                                              pagefeatures.productstatus &&
                                              editRowData.status == 'In Product Review'
                                            "
                                          >
                                            <div class="form-group mt-1 p-0">
                                              <button
                                                type="button"
                                                class="btn btn-relief-primary"
                                                @click="
                                                  singleupdatescriptstatus(
                                                    datax1,
                                                    datax[1][0].ispositionno
                                                  )
                                                "
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </div>

                                <div class="container text-right px-0">
                                  <a
                                    href="javascript:void(0);"
                                    type="text"
                                    @click="viewproductcommenthistory(datax[0])"
                                    >View History</a
                                  >
                                </div>
                                <div
                                  class="modal fade"
                                  id="productcommenthistory"
                                  tabindex="2"
                                  role="dialog"
                                  aria-labelledby="exampleModalScrollableTitle"
                                  aria-hidden="true"
                                >
                                  <div
                                    class="modal-dialog modal-dialog-scrollable modal-lg"
                                    role="document"
                                  >
                                    <div class="modal-content">
                                      <div
                                        class="modal-header"
                                        style="text-align: center; margin-bottom: 1.2rem"
                                      >
                                        <h4 class="modal-title">
                                          Product Comment History
                                        </h4>
                                        <button
                                          type="button"
                                          class="close removeclassreadmore"
                                          @click="idclickclose()"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body sm-overflow_scroll_div">
                                        <div class="row">
                                          <div class="col-md-12">
                                            <ul class="timeline">
                                              <li
                                                class="timeline-item"
                                                v-for="(his, idx) in historylist"
                                                v-bind:key="idx"
                                              >
                                                <span class="timeline-point"
                                                  ><edit-2-icon
                                                    size="1.5x"
                                                    class="custom-class"
                                                  ></edit-2-icon
                                                ></span>
                                                <div class="timeline-event">
                                                  <div
                                                    class="product_content_history_view01 mb-sm-0 mb-1"
                                                  >
                                                    <div
                                                      class="readmoreremovess outer"
                                                      :id="
                                                        'cycle_readmore6' +
                                                        his.testcasemasterid +
                                                        idx
                                                      "
                                                    >
                                                      <p>
                                                        <strong>
                                                          {{
                                                            his.createdby
                                                              ? formatName(his.createdby)
                                                              : "NA"
                                                          }}
                                                          Commented:</strong
                                                        >
                                                        <span
                                                          class="product_content_history_text"
                                                          v-html="his.comment"
                                                        >
                                                        </span>
                                                      </p>
                                                    </div>
                                                    <div
                                                      class="cycle_viewmoretext"
                                                      v-if="
                                                        his.comment &&
                                                        his.comment.length > 150
                                                      "
                                                    >
                                                      <a class="btn">
                                                        <strong
                                                          class="history_popop"
                                                          :id="
                                                            'id6' +
                                                            his.testcasemasterid +
                                                            idx
                                                          "
                                                          @click="
                                                            idclick6(
                                                              his.testcasemasterid,
                                                              idx
                                                            )
                                                          "
                                                        >
                                                          Read More
                                                        </strong>
                                                      </a>
                                                    </div>
                                                  </div>
                                                  <span class="timeline-event-time"
                                                    >At:
                                                    {{
                                                      his.createddate
                                                        ? dateMonthSecondFormat(
                                                            his.createddate
                                                          )
                                                        : "NA"
                                                    }}</span
                                                  >
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        colspan="14"
                                        style="text-align: center"
                                        class="mb-3"
                                        v-if="historylist.length == 0"
                                      >
                                        History not found
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" v-else>
                          <div
                            class="col-sm-12 col-md-12"
                            v-for="(datax, indexx) in editRowDatas"
                            v-bind:key="indexx"
                          >
                            <div class="tast_case_groupingview_01">
                              <div class="common_groupview_cases px-0">
                                <div class="cycle_group_bg d-flex">
                                  <span
                                    class="d-flex custom-control custom-checkbox"
                                    v-if="
                                      pagefeatures.productstatus &&
                                      editRowData.status == 'In Product Review'
                                    "
                                    style="position: relative; top: 2px"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input mr-1 mr-0"
                                      :id="'business-checkbox-2' + indexx"
                                      v-model="datax.isselect"
                                      @input="selectcheckbox($event, datax, indexx)"
                                    />
                                    <label
                                      class="custom-control-label mr-0"
                                      :for="'business-checkbox-2' + indexx"
                                    ></label>
                                  </span>
                                  <div
                                    class="d-flex align-items-center"
                                    v-if="
                                      pagefeatures.productstatus &&
                                      editRowData.status == 'In Product Review'
                                    "
                                  >
                                    <span class="leg_cycle_text leg_cycle_text_setss">
                                      <span class="testCaseName01">
                                        Test Case Name :</span
                                      >
                                      <span class="testCaseinput02">
                                        <input
                                          type="text"
                                          class="form-control capitalisetext"
                                          v-model="datax.ispositionno"
                                          placeholder="Sequence"
                                          @keypress="validNumber"
                                        />
                                      </span>
                                      <span class="testCasetext03">
                                        {{ datax.testcasename }}
                                      </span></span
                                    >
                                  </div>
                                  <span class="leg_cycle_text" v-else>
                                    <span class="testCaseName01">Test Case Name : </span
                                    ><span class="test_casename_t-03"
                                      >{{
                                        datax.ispositionno ? datax.ispositionno + "." : ""
                                      }}
                                      {{ datax.testcasename }}
                                    </span></span
                                  >
                                </div>
                                <div
                                  class="grouptestcase_views tast_case_groupingview_readmore tast_case_groupingview_bg01"
                                >
                                  <div class="tast_case_groupingview_full-width">
                                    <span class="leg_group_testcase case_bg_11"
                                      >{{ datax.tcscriptcode
                                      }}<em class="las la-angle-double-right"></em>
                                    </span>
                                    <span class="leg_group_testcase case_bg_12"
                                      >{{ datax.country }}
                                      <em class="las la-angle-double-right"></em
                                    ></span>
                                    <span class="leg_group_testcase case_bg_13"
                                      >{{ datax.enviornment }}
                                      <em class="las la-angle-double-right"></em
                                    ></span>
                                    <span class="leg_group_testcase case_bg_14"
                                      >{{ datax.language }}
                                      <em class="las la-angle-double-right"></em
                                    ></span>
                                    <span class="leg_group_testcase case_bg_11"
                                      >{{ datax.scripttype
                                      }}<em class="las la-angle-double-right"></em
                                    ></span>
                                    <span
                                      class="leg_group_testcase case_bg_12 capitalisetext"
                                      v-if="datax.assignee"
                                      >{{ datax.assignee ? datax.assigneename : "NA"
                                      }}<em class="las la-angle-double-right"></em
                                    ></span>

                                    <span
                                      class="leg_group_testcase case_bg_16"
                                      v-if="
                                        pagefeatures.productstatus &&
                                        editRowData.status == 'In Product Review'
                                      "
                                    >
                                      <div class="col-md-12 px-0">
                                        <div class="form-group mb-0 p-0">
                                          <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            placeholder="Select status"
                                            v-model="datax.isscriptapproved"
                                            :options="testscriptreuslt"
                                            v-if="
                                              editRowData.status == 'In Product Review'
                                            "
                                          />
                                        </div>
                                      </div>
                                    </span>

                                    <span
                                      class="leg_group_testcase case_bg_13 capitalisetext"
                                      v-if="
                                        !pagefeatures.productstatus &&
                                        datax.resultstatus &&
                                        datax.resultstatus == 'Product Declined'
                                      "
                                      >{{
                                        datax.resultstatus ? datax.resultstatus : "NA"
                                      }}</span
                                    >
                                    <span
                                      class="leg_group_testcase case_bg_15 capitalisetext"
                                      v-else-if="
                                        !pagefeatures.productstatus &&
                                        datax.resultstatus &&
                                        datax.resultstatus == 'Product Approved'
                                      "
                                      >{{
                                        datax.resultstatus ? datax.resultstatus : "NA"
                                      }}</span
                                    >
                                    <span
                                      class="leg_group_testcase case_bg_13 capitalisetext"
                                      v-else-if="
                                        editRowData.status != 'In Product Review' &&
                                        datax.resultstatus &&
                                        datax.resultstatus == 'Product Declined' &&
                                        editRowData.status != 'Product Declined'
                                      "
                                      >{{
                                        datax.resultstatus ? datax.resultstatus : "NA"
                                      }}</span
                                    >
                                    <span
                                      class="leg_group_testcase case_bg_15 capitalisetext"
                                      v-else-if="
                                        editRowData.status != 'In Product Review' &&
                                        datax.resultstatus &&
                                        datax.resultstatus == 'Product Approved' &&
                                        editRowData.status != 'Product Approved'
                                      "
                                      >{{
                                        datax.resultstatus ? datax.resultstatus : "NA"
                                      }}</span
                                    >
                                    <span
                                      class="leg_group_testcase case_bg_13 capitalisetext"
                                      v-else-if="
                                        editRowData.status == 'Product Declined' &&
                                        datax.resultstatus &&
                                        datax.resultstatus == 'Product Declined'
                                      "
                                      >{{
                                        datax.resultstatus ? datax.resultstatus : "NA"
                                      }}</span
                                    >

                                    <span
                                      class="leg_group_testcase case_bg_13 capitalisetext"
                                      v-if="
                                        datax.resultstatus && datax.resultstatus == 'fail'
                                      "
                                      >{{
                                        datax.resultstatus ? datax.resultstatus : "NA"
                                      }}</span
                                    >
                                    <span
                                      class="leg_group_testcase case_bg_15 capitalisetext"
                                      v-else-if="
                                        datax.resultstatus && datax.resultstatus == 'pass'
                                      "
                                      >{{
                                        datax.resultstatus ? datax.resultstatus : "NA"
                                      }}</span
                                    >
                                    <span
                                      class="leg_group_testcase case_bg_14 capitalisetext"
                                      v-else-if="
                                        editRowData.status != 'Product Approved' &&
                                        (datax.resultstatus == 'NA' ||
                                          datax.resultstatus == null ||
                                          datax.resultstatus == '' ||
                                          datax.resultstatus == ' ')
                                      "
                                      >NA</span
                                    >

                                    <div
                                      class="cycle_viewmoretext leg_group_testcase capitalisetext"
                                      v-if="
                                        datax.scripttype && datax.scripttype.length > 5
                                      "
                                    >
                                      <a class="btn">
                                        <strong
                                          :id="'id003' + datax.resultid + indexx"
                                          v-on:click="idclick03(datax.resultid, indexx)"
                                        >
                                          Read More
                                        </strong>
                                      </a>
                                    </div>

                                    <div></div>
                                  </div>
                                  <div class="grouptestcase_views_dd">
                                    <div
                                      class="row mx-0"
                                      v-if="
                                        pagefeatures.productstatus &&
                                        (datax.isscriptapproved == 0 ||
                                          datax.isscriptapproved == 1) &&
                                        editRowData.status == 'In Product Review' &&
                                        editRowData.status != 'Product Approved'
                                      "
                                    >
                                      <div class="col-6 px-0">
                                        <div class="form-group">
                                          <lable class="transaction-title"
                                            >Product Comment:</lable
                                          >
                                          <textarea
                                            type="text"
                                            class="form-control col-md-12"
                                            placeholder="Enter Comment"
                                            v-model="datax.comment"
                                          ></textarea>
                                          <div
                                            class="errorinputmsg"
                                            v-if="
                                              !datax.comment && !validText(datax.comment)
                                            "
                                          >
                                            Comment Is Required
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="col-sm-2 leg_group_testcase mb-0"
                                        v-if="
                                          pagefeatures.productstatus &&
                                          editRowData.status == 'In Product Review'
                                        "
                                      >
                                        <div class="form-group mt-2 p-0">
                                          <lable class="d-block w-100">&nbsp;</lable>
                                          <button
                                            type="button"
                                            class="btn btn-relief-primary"
                                            @click="singleupdatescriptstatus(datax)"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="clearfix"></div>
                                    <div
                                      class="col-md-12 mt-0 tast_case_groupingview_readmore px-0"
                                    >
                                      <div
                                        class="custom-container case_bg_17 outer cycle_readmoresdis mb-1 testcyclereadmoreview readmoreremovess"
                                        :id="
                                          'cycle_readmore003' + datax.resultid + indexx
                                        "
                                      >
                                        <div
                                          class="outer cycle_readmoresdis textcycel_product_comments mb-1"
                                          v-if="
                                            (editRowData.status != 'In Product Review' &&
                                              datax.comment) ||
                                            (editRowData.status == 'In Product Review' &&
                                              datax.isscriptapproved == null)
                                          "
                                        >
                                          <span class="cycle_readmore_dis"
                                            >Product Comment :
                                          </span>
                                          <span
                                            class="fc_m16_btcode_text"
                                            v-html="formatData(datax.comment)"
                                          ></span>
                                        </div>

                                        <div
                                          class="outer cycle_readmoresdis mb-1"
                                          :id="
                                            'cycle_readmore003' + datax.resultid + indexx
                                          "
                                        >
                                          <span
                                            class="cycle_readmore_dis"
                                            v-if="datax.scripttype == 'Manual'"
                                            >Steps :</span
                                          >
                                          <span
                                            class="cycle_readmore_dis"
                                            v-if="datax.scripttype == 'Automation'"
                                            >Script :
                                          </span>
                                          <span
                                            class="fc_m16_btcode_text"
                                            v-html="formatData(datax.scriptsteps)"
                                          ></span>
                                        </div>
                                        <!-- <div class="cycle_viewmoretext" v-if="datax.scriptsteps && datax.scriptsteps.length >50">
                                                                        <a class="btn">
                                                                        <strong :id="'id1'+indexx" @click="idclick(indexx)">
                                                                            Read More
                                                                        </strong>
                                                                        </a>
                                                                    </div> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="container text-right px-0">
                                  <a
                                    href="javascript:void(0);"
                                    type="text"
                                    @click="
                                      viewproductcommenthistory(datax.testcasemasterid)
                                    "
                                    >View History</a
                                  >
                                </div>
                                <div
                                  class="modal fade"
                                  id="productcommenthistory"
                                  tabindex="2"
                                  role="dialog"
                                  aria-labelledby="exampleModalScrollableTitle"
                                  aria-hidden="true"
                                >
                                  <div
                                    class="modal-dialog modal-dialog-scrollable modal-lg"
                                    role="document"
                                  >
                                    <div class="modal-content">
                                      <div
                                        class="modal-header"
                                        style="text-align: center; margin-bottom: 1.2rem"
                                      >
                                        <h4 class="modal-title">
                                          Product Comment History
                                        </h4>
                                        <button
                                          type="button"
                                          class="close removeclassreadmore"
                                          @click="idclickclose()"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body sm-overflow_scroll_div">
                                        <div class="row">
                                          <div class="col-md-12">
                                            <ul class="timeline">
                                              <li
                                                class="timeline-item"
                                                v-for="(his, idx) in historylist"
                                                v-bind:key="idx"
                                              >
                                                <span class="timeline-point"
                                                  ><edit-2-icon
                                                    size="1.5x"
                                                    class="custom-class"
                                                  ></edit-2-icon
                                                ></span>
                                                <div class="timeline-event">
                                                  <div
                                                    class="product_content_history_view01 mb-sm-0 mb-1"
                                                  >
                                                    <!-- <p>{{ formatName(his.createdby) }} Commented: <strong v-html="his.comment"> </strong></p> -->
                                                    <div
                                                      class="outer readmoreremovess"
                                                      :id="
                                                        'cycle_readmore7' +
                                                        his.testcasemasterid +
                                                        idx
                                                      "
                                                    >
                                                      <p>
                                                        <strong>
                                                          {{
                                                            his.createdby
                                                              ? formatName(his.createdby)
                                                              : "NA"
                                                          }}
                                                          Commented:</strong
                                                        >
                                                        <span
                                                          class="product_content_history_text"
                                                          v-html="his.comment"
                                                        >
                                                        </span>
                                                      </p>
                                                    </div>
                                                    <div
                                                      class="cycle_viewmoretext"
                                                      v-if="
                                                        his.comment &&
                                                        his.comment.length > 150
                                                      "
                                                    >
                                                      <a class="btn">
                                                        <strong
                                                          :id="
                                                            'id7' +
                                                            his.testcasemasterid +
                                                            idx
                                                          "
                                                          @click="
                                                            idclick7(
                                                              his.testcasemasterid,
                                                              idx
                                                            )
                                                          "
                                                        >
                                                          Read More
                                                        </strong>
                                                      </a>
                                                    </div>
                                                  </div>
                                                  <span class="timeline-event-time"
                                                    >At:
                                                    {{
                                                      his.createddate
                                                        ? dateMonthSecondFormat(
                                                            his.createddate
                                                          )
                                                        : "NA"
                                                    }}</span
                                                  >
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        colspan="14"
                                        style="text-align: center"
                                        class="mb-3"
                                        v-if="historylist.length == 0"
                                      >
                                        History not found
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="pageCenter text-center"
                          v-if="
                            pagefeatures.productstatus &&
                            (editRowData.status == 'Approved' ||
                              editRowData.status == 'Product Pending for Review' ||
                              editRowData.status == 'In Product Review') &&
                            tempeditRowDatas
                          "
                        >
                          <div class="VuePagination_view">
                            <div class="VuePagination_view_02">
                              <a
                                class="VuePagination_view_count"
                                href="javascript:void(0);"
                                @click="prevPage()"
                                id="btn_prev"
                                >Prev
                              </a>
                              <span class="VuePagination_view_count" id="page"> 1 </span>
                              <a
                                class="VuePagination_view_count"
                                href="javascript:void(0);"
                                @click="nextPage()"
                                id="btn_next"
                                v-if="tempeditRowDatas.length > 21"
                              >
                                Next</a
                              >
                            </div>
                          </div>
                        </div>
                        <div class="pageCenter text-center" v-else>
                          <pagination
                            v-model="page"
                            :records="totalcount"
                            :per-page="pagelimit"
                            @paginate="clickCallback"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.fc_m16_btcode_text p {
  margin-bottom: 5px;
  color: #3e4757;
  font-weight: 400;
}
.newClass {
  cursor: text;
}
.common_groupview_cases .leg_group_testcase:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 25px;
  height: 23px;
  top: 6px;
  right: -13px;
  transform: rotate(48deg);
  z-index: 9;
  border-radius: 0px 0px 0px 58px;
}
.common_groupview_cases .leg_group_testcase.case_bg_11:after {
  background: #4f46e5;
}
.common_groupview_cases .leg_group_testcase.case_bg_12:after {
  background: #f9a014;
}

.common_groupview_cases .leg_group_testcase.case_bg_13:after {
  background: #f3493e;
}
.common_groupview_cases .leg_group_testcase.case_bg_14:after {
  background: #5b9bd5;
}
.common_groupview_cases .leg_group_testcase.case_bg_15:after {
  background: #5ab45b;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "vue-pagination-2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import commonMethods from "../../utils/commonMethods";
import {
  PlusCircleIcon,
  PlusSquareIcon,
  Edit2Icon,
  CalendarIcon,
  UserIcon,
  EyeIcon,
  WatchIcon,
  SettingsIcon,
  MonitorIcon,
} from "vue-feather-icons";
export default {
  name: "TestCycleVeiw",
  components: {
    Loading,
    vSelect,
    Treeselect,
    VueElementLoading,
    Pagination,
    Edit2Icon,
    CalendarIcon,
    PlusCircleIcon,
    PlusSquareIcon,
    UserIcon,
    EyeIcon,
    WatchIcon,
    SettingsIcon,
    MonitorIcon,
    ckeditor: CKEditor.component,
  },
  mixins: [commonMethods],
  data() {
    return {
      isLoading: false,
      islistLoading: false,
      fullPage: true,
      listfullPage: true,
      isistLoading: false,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      cyclelist: [],
      testcasereuslt: apiUrl.testcasereuslt,
      testscriptreuslt: apiUrl.testscriptreuslt,
      readmore: "outer",
      testcycle: {
        cyclename: null,
        tcgrouplist: [],
        businesscode: null,
        businessname: null,
        assignto: null,
        startdate: null,
        enddate: null,
        tcgroup: null,
        btccodelist: [],
        btcnamelist: [],
        assigntolist: [],
        status: null,
        version: null,
        cyclesetid: null,
        createdby: null,
        createddate: null,
        lastmodifiedby: null,
        lastmodifieddate: null,
        cyclesetcode: null,
        mappingname: null,
        revieweddate: null,
        productpendingreviewdate: null,
        rundate: null,
        finishdate: null,
        groupname: [],
        spasscount: null,
        sfailcount: null,
        nooftestcase: null,
        cycleruncount: null,
        cyclehistory: [],
        comment: null,
        statuscomment: null,
        commentedby: null,
        finishcomment: null,
      },
      resultstatus: null,
      btndisabled: true,
      editRowData: [],
      editRowDatas: [],
      tempeditRowDatas: [],
      statuslist: [],
      status: null,
      comment: null,
      pagefeatures: [],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
            "|",
          ],
        },
      },
      bulkscriptapprove: null,
      bulkscriptcomment: null,
      errors: {
        comment: null,
      },
      resultids: [],
      current_page: 1,
      records_per_page: 21,
      masterpage: 0,
      historylist: [],
      readmoreid: "",
      readmorerow: "",
    };
  },
  created() {
    this.pagefeatures = {};
    var pagefeatures1 = JSON.parse(
      window.atob(window.localStorage.getItem("pagefeatures"))
    );
    pagefeatures1.forEach((element) => {
      if (element.page === "/testexecution/testcycleproductlist") {
        this.pagefeatures[element.featurename] = element.featureaccess;
      }
    });
  },
  mounted() {
    window.addEventListener("storage", function (event) {
      if (event.key == "logout-mee") {
        window.location.reload();
      }
    });
    let userdata = window.localStorage.getItem("userdata");
    if (userdata) {
      userdata = JSON.parse(userdata);
      this.undt = userdata;
      this.tokendata = window.localStorage.getItem("token");
      this.loginusername = userdata.username;
      this.getcyclesetviewbyid(this.$route.query.cyclesetid);
      this.getcyclehistory(this.$route.query.cyclesetid);
    }
  },
  methods: {
    idclick4() {
      if ($("#cycle_readmore4").hasClass("readmore")) {
        $("#id4").html("Read More");
        $("#cycle_readmore4").removeClass("readmore");
        this.readmore = "outer";
      } else {
        $("#id4").html("Read Less");
        $("#cycle_readmore4").addClass("readmore");
        this.readmore = "outer readmore";
      }
    },
    idclick3(indexx) {
      if ($("#cycle_readmore3" + indexx).hasClass("readmore")) {
        $("#id3" + indexx).html("Read More");
        $("#cycle_readmore3" + indexx).removeClass("readmore");
        this.readmore = "outer";
      } else {
        $("#id3" + indexx).html("Read Less");
        $("#cycle_readmore3" + indexx).addClass("readmore");
        this.readmore = "outer readmore";
      }
    },
    idclick(indexx) {
      if ($("#cycle_readmore2" + indexx).hasClass("readmore")) {
        $("#id1" + indexx).html("Read More");
        $("#cycle_readmore2" + indexx).removeClass("readmore");
      } else {
        $("#id1" + indexx).html("Read Less");
        $("#cycle_readmore2" + indexx).addClass("readmore");
      }
    },
    idclick01(indexx, indexx1) {
      this.readmoreid = "cycle_readmore002" + indexx + indexx1;
      this.readmorerow = "id01" + indexx + indexx1;
      if ($("#cycle_readmore002" + indexx + indexx1).hasClass("readmore")) {
        $("#" + "id01" + indexx + indexx1).html("Read More");
        $("#cycle_readmore002" + indexx + indexx1).removeClass("readmore");
      } else {
        $("#" + "id01" + indexx + indexx1).html("Read Less");
        $("#cycle_readmore002" + indexx + indexx1).addClass("readmore");
      }
    },
    idclick03(rid, indexx) {
      this.readmoreid = "cycle_readmore003" + rid + indexx;
      this.readmorerow = "id003" + rid + indexx;
      if ($("#cycle_readmore003" + rid + indexx).hasClass("readmore")) {
        $("#" + "id003" + rid + indexx).html("Read More");
        $("#cycle_readmore003" + rid + indexx).removeClass("readmore");
      } else {
        $("#" + "id003" + rid + indexx).html("Read Less");
        $("#cycle_readmore003" + rid + indexx).addClass("readmore");
      }
    },
    idclick5(indexx) {
      if ($("#cycle_readmore5" + this.masterpage + indexx).hasClass("readmore")) {
        $("#" + "id5" + this.masterpage + indexx).html("Read More");
        $("#cycle_readmore5" + this.masterpage + indexx).removeClass("readmore");
      } else {
        $("#" + "id5" + this.masterpage + indexx).html("Read Less");
        $("#cycle_readmore5" + this.masterpage + indexx).addClass("readmore");
      }
    },
    idclick6(tid, idx) {
      this.readmoreid = "cycle_readmore6" + tid + idx;
      this.readmorerow = "id6" + tid + idx;
      if ($("#cycle_readmore6" + tid + idx).hasClass("readmore")) {
        $("#" + "id6" + tid + idx).html("Read More");
        $("#cycle_readmore6" + tid + idx).removeClass("readmore");
      } else {
        $("#" + "id6" + tid + idx).html("Read Less");
        $("#cycle_readmore6" + tid + idx).addClass("readmore");
      }
    },
    idclick7(tid, idx) {
      this.readmoreid = "cycle_readmore7" + tid + idx;
      this.readmorerow = "id7" + tid + idx;
      if ($("#cycle_readmore7" + tid + idx).hasClass("readmore")) {
        $("#" + "id7" + tid + idx).html("Read More");
        $("#cycle_readmore7" + tid + idx).removeClass("readmore");
      } else {
        $("#" + "id7" + tid + idx).html("Read Less");
        $("#cycle_readmore7" + tid + idx).addClass("readmore");
      }
    },
    idclickclose() {
      $("#" + this.readmorerow).html("Read More");
      $("#" + this.readmoreid).removeClass("readmore");
    },
    redirecttourl: function () {
        this.$router.push({
          name: 'TestCaseManagement_listView',
          path: `/testexecution/TestCaseManagement_listView`,
          // path: `/testexecution/testcycleview?cyclesetid=${rowdata.cyclesetid}`,
          params: {
            ccID: this.$route.query.cyclesetid,
            ccStatus: this.editRowData.status
          }
        })
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.page = pageNum;
      this.currPageNum = pageNum - 1;
      if (this.readmoreid && this.readmorerow) {
        this.idclickclose();
      }
      this.getcyclesetviewresulttbl(this.$route.query.cyclesetid);
      setTimeout(function () {
        $("html, body").animate({
          scrollTop: $("#myTabContent").offset().top - 70,
        });
      }, 500);
    },
    getcyclesetviewbyid(cyclesetid, statussetdefault) {
      this.isLoading = true;
      let url = "api/testcycle/getcyclesetviewbyid";
      this.input = {
        cyclesetid: cyclesetid,
        useremail: this.undt.username,
        empid: this.undt.userid,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      })
        .then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.editRowData = result.data.data;
            this.editRowData = this.editRowData[0];
            this.openEditModule(this.editRowData, statussetdefault);
          } else {
            this.editRowData = [];
            this.$router.push({ name: "TestCycleSetList", params: { search: true } });
            Swal.fire({
              title: "Failed!",
              text: "Your are not authorised user to update this test case",
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }
        })
        .catch((e) => {
          this.displayError(e);
        });
    },
    getcyclesetviewresulttbl(cyclesetid) {
      this.isLoading = true;
      let url = "api/testcycle/getcyclesetviewresulttbl";
      this.input = {
        cyclesetid: cyclesetid,
        useremail: this.undt.username,
        empcode: this.undt.userid,
        limit: apiUrl.LIMIT,
        offset: this.pageoffset,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      })
        .then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.editRowDatas = result.data.data.rows;
            this.totalcount = result.data.data.count;
            var temArray = {};

            for (let i = 0; i < this.editRowDatas.length; i++) {
              if (this.editRowDatas[i].comment && this.editRowDatas[i].comment != null) {
                this.editRowDatas[i].comment = this.convertintotxt(
                  this.editRowDatas[i].comment
                );
              }

              if (
                this.pagefeatures.productstatus &&
                (this.editRowData.status == "Approved" ||
                  this.editRowData.status == "Product Pending for Review" ||
                  this.editRowData.status == "In Product Review")
              ) {
                if (!temArray.hasOwnProperty(this.editRowDatas[i].testcasemasterid)) {
                  temArray[this.editRowDatas[i].testcasemasterid] = [];
                }
                var pushObj = this.editRowDatas[i];
                temArray[this.editRowDatas[i].testcasemasterid].push(pushObj);
              }
            }
            if (
              this.pagefeatures.productstatus &&
              (this.editRowData.status == "Approved" ||
                this.editRowData.status == "Product Pending for Review" ||
                this.editRowData.status == "In Product Review")
            ) {
              this.editRowDatas = Object.entries(temArray);
              this.tempeditRowDatas = Object.entries(temArray);
              this.paginate(1, 21);
              this.totalcount = Object.values(this.editRowDatas).length;
            }
          } else {
            this.editRowDatas = [];
            this.totalcount = 0;
          }
        })
        .catch((e) => {
          this.displayError(e);
        });
    },
    convertintotxt(html) {
      return html.replace(/<[^>]*>/g, "");
    },
    openEditModule(currRowData, statussetdefault) {
      this.testcycle.cyclesetid = currRowData.cyclesetid;
      this.testcycle.cyclename = currRowData.cyclesetname;
      this.testcycle.cyclesetcode = currRowData.cyclesetcode;
      this.testcycle.businesscode = currRowData.businesstaskcode;
      this.testcycle.businessname = currRowData.taskname;
      // this.testcycle.status=currRowData.status
      this.testcycle.startdate = currRowData.startdate;
      this.testcycle.enddate = currRowData.enddate;
      this.testcycle.createdby = currRowData.createdby;
      this.testcycle.createddate = currRowData.createddate;
      this.testcycle.lastmodifiedby = currRowData.lastmodifiedby;
      this.testcycle.lastmodifieddate = currRowData.lastmodifieddate;
      this.testcycle.version = currRowData.versionno;
      this.testcycle.mappingname = currRowData.mappingname;
      this.testcycle.assignto = currRowData.assignedto;
      this.testcycle.revieweddate = currRowData.revieweddate;
      this.testcycle.productpendingreviewdate = currRowData.productpendingreviewdate;
      this.testcycle.rundate = currRowData.rundate;
      this.testcycle.finishdate = currRowData.finishdate;
      this.testcycle.spasscount = currRowData.spasscount;
      this.testcycle.sfailcount = currRowData.sfailcount;
      this.testcycle.nooftestcase = currRowData.nooftestcase;
      this.testcycle.cycleruncount = currRowData.cycleruncount;
      this.testcycle.statuscomment = currRowData.statuscomment;
      this.testcycle.commentedby = currRowData.commentedby;
      this.testcycle.finishcomment = currRowData.finishcomment;
      this.getcyclesetviewresulttbl(currRowData.cyclesetid);
      if (currRowData.status && currRowData.projectist) {
        this.getStatusConfigList(currRowData.projectist.split(","), currRowData.status);
      } else {
        this.getStatusConfigList(null, currRowData.status);
      }
      if (statussetdefault && (statussetdefault != null || statussetdefault != "")) {
        this.testcycle.status = statussetdefault;
      } else {
        this.testcycle.status = currRowData.status;
      }
    },
    getStatusConfigList(pid, status) {
      let payload = {
        empcode: this.undt.userid,
        useremail: this.undt.username,
        projectid: pid,
        trackername: "Test Cycle Set",
        currStatus: status,
      };
      axios({
        method: "POST",
        url: "/api/testcase/getBStatusConfigList",
        data: payload,
        headers: { authorization: this.tokendata },
      })
        .then((result) => {
          if (result.data.errorCode == 0) {
            this.statuslist = result.data.data.rows;
          } else {
            this.statuslist = [];
          }
        })
        .catch((e) => {
          this.displayError(e);
        });
    },
    getassignto(assignto) {
      this.input = {
        useremail: this.undt.username,
        empid: this.undt.userid,
        empcode: this.undt.userid,
        assignto: assignto,
      };
      axios({
        method: "POST",
        url: "api/testcycle/getassignto",
        data: this.input,
        headers: { authorization: this.tokendata },
      })
        .then((result) => {
          if (result.data.errorCode == 0) {
            this.testcycle.assignto = result.data.data.rows[0].label;
          } else {
            this.testcycle.assignto = [];
          }
        })
        .catch((e) => {
          this.displayError(e);
        });
    },
    getcyclegroup(groupnameidlist) {
      this.input = {
        useremail: this.undt.username,
        empid: this.undt.userid,
        empcode: this.undt.userid,
        groupnameid: groupnameidlist,
      };
      axios({
        method: "POST",
        url: "api/testcycle/getcyclegroup",
        data: this.input,
        headers: { authorization: this.tokendata },
      })
        .then((result) => {
          if (result.data.errorCode == 0) {
            this.testcycle.tcgrouplist = result.data.data.rows;
            for (let i = 0; i < this.testcycle.tcgrouplist.length; i++) {
              this.testcycle.groupname.push(this.testcycle.tcgrouplist[i].label);
            }
          } else {
            (this.testcycle.tcgrouplist = []), (this.testcycle.groupname = []);
          }
        })
        .catch((e) => {
          this.displayError(e);
        });
    },
    dateMonthFormat(value) {
      if (!value) return "";
      return moment(String(value)).format("DD MMM YYYY HH:mm");
    },
    dateMonthSecondFormat(value) {
      if (!value) return "";
      return moment(String(value)).format("DD MMM YYYY HH:mm:ss");
    },
    showmappingname(mappingname) {
      let responsestr = "";
      let prlist = mappingname.split(",");
      if (prlist.length > 1) {
        for (var i = 0; i < prlist.length; i++) {
          responsestr += i + 1 + ". " + prlist[i] + " <br/>";
        }
      } else {
        for (var i = 0; i < prlist.length; i++) {
          responsestr += prlist[i];
        }
      }
      return responsestr;
    },
    formatData(data) {
      return data;
    },
    selresult(state) {
      this.resultstatus = null;
      this.resultstatus = state;
    },
    getcyclehistory(id) {
      this.isLoading = true;
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        cyclesetid: id,
      };
      axios({
        method: "POST",
        url: "api/testcycle/getcyclehistory",
        data: this.input,
        headers: { authorization: this.tokendata },
      })
        .then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.testcycle.cyclehistory = result.data.data.rows;
          } else {
            this.testcycle.cyclehistory = [];
          }
        })
        .catch((e) => {
          this.displayError(e);
        });
    },
    savestatus(testdata) {
      if (this.testcycle.status) {
        this.isLoading = true;
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        let apiURL = "api/testcycle/savestatus";
        (this.input.cyclesetid = parseInt(testdata.cyclesetid)),
          (this.input.status = this.testcycle.status);
        this.input.comment = this.testcycle.comment;
        axios({
          method: "POST",
          url: apiURL,
          data: this.input,
          headers: { authorization: this.tokendata },
        })
          .then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              this.testcycle.comment = null;
              this.errors.comment = "";
              this.$router.push({
                name: "TestCycleSetProStatusList",
                params: { search: true },
              });
            } else if (result.data.errorCode == 3) {
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function () {
                window.location.href = "/#/login";
              });
            } else {
              Swal.fire({
                title: "Failed",
                text: result.data.msg,
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            }
          })
          .catch((e) => {
            this.displayError(e);
          });
      }
    },
    changevalue(event) {
      if (event.target.checked == true) {
        this.bulkscriptapprove = 1;
        this.errors.comment = "Comment Is Required";
      } else {
        this.bulkscriptapprove = null;
        this.errors.comment = "";
      }
    },
    selstatus(state) {
      if (state == "Product Approved" || state == "Product Declined") {
        this.errors.comment = "Comment Is Required";
      } else {
        this.errors.comment = "";
      }
      if (state == "Product Declined") {
        this.bulkscriptapprove = null;
      }
    },
    validText: function (inputval) {
      var re = /[A-Za-z0-9].{1,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    updatebulkscriptstatus(testcycle, bulkscriptapprove, comment, resultids) {
      if (testcycle) {
        this.isLoading = true;
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          cyclesetid: this.$route.query.cyclesetid,
          comment: comment,
        };
        if (
          bulkscriptapprove &&
          bulkscriptapprove == 1 &&
          this.testcycle.status == "Product Approved"
        ) {
          this.input.bulkscriptapprove = bulkscriptapprove;
        }
        if (
          bulkscriptapprove == 1 ||
          (testcycle.status == 1 && resultids && resultids.length > 0)
        ) {
          this.input.status = "Product Approved";
          if (testcycle.status == 1 && resultids) {
            this.input.bulkscriptapprove = 1;
          }
        } else if (
          bulkscriptapprove == 0 ||
          (testcycle.status == 0 && resultids && resultids.length > 0)
        ) {
          this.input.status = "Product Declined";
          if (testcycle.status == 0 && resultids) {
            this.input.bulkscriptapprove = 0;
          }
        }
        if (resultids && resultids.length > 0) {
          this.input.testcasemasterid = resultids;
        }
        if (bulkscriptapprove == null && !resultids) {
          if (this.testcycle.status == "Product Approved") {
            this.input.status = "Product Approved";
            this.input.bulkscriptapprove = 1;
          } else if (this.testcycle.status == "Product Declined") {
            this.input.status = "Product Declined";
            this.input.bulkscriptapprove = 0;
          }
        }
        let apiURL = "api/testcycle/updatebulkscriptstatus";
        axios({
          method: "POST",
          url: apiURL,
          data: this.input,
          headers: { authorization: this.tokendata },
        })
          .then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              if (this.testcycle.status == "Product Approved" && bulkscriptapprove == 1) {
                this.savestatus(testcycle);
              } else if (
                bulkscriptapprove == null &&
                !resultids &&
                (this.testcycle.status == "Product Approved" ||
                  this.testcycle.status == "Product Declined")
              ) {
                this.savestatus(testcycle);
              } else {
                this.testcycle.comment = null;
                this.errors.comment = "";
                this.resultids = [];
                this.getcyclesetviewbyid(this.$route.query.cyclesetid);
                this.getcyclehistory(this.$route.query.cyclesetid);
                Swal.fire({
                  title: "Success!",
                  text: result.data.msg,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
                });
              }
            } else if (result.data.errorCode == 3) {
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function () {
                window.location.href = "/#/login";
              });
            } else if (result.data.errorCode == -1) {
              this.savestatus(testcycle);
              this.getcyclesetviewbyid(this.$route.query.cyclesetid);
              this.getcyclehistory(this.$route.query.cyclesetid);
            } else {
              Swal.fire({
                title: "Failed",
                text: result.data.msg,
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            }
          })
          .catch((e) => {
            this.displayError(e);
          });
      }
    },
    singleupdatescriptstatus(data1, ispositionno) {
      if (data1) {
        this.isLoading = true;
        let statussetdefault = null;
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          cyclesetid: this.$route.query.cyclesetid,
          isscriptapproved: data1.isscriptapproved,
          testcasemasterid: data1.testcasemasterid,
          comment: data1.comment,
          ispositionno: ispositionno,
          resultid: data1.resultid,
        };
        if (data1.isscriptapproved == 1) {
          this.input.status = "Product Approved";
          statussetdefault = "Product Approved";
        } else if (data1.isscriptapproved == 0) {
          this.input.status = "Product Declined";
          statussetdefault = "Product Declined";
        }
        let apiURL = "api/testcycle/singleupdatescriptstatus";
        axios({
          method: "POST",
          url: apiURL,
          data: this.input,
          headers: { authorization: this.tokendata },
        })
          .then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              this.getcyclesetviewbyid(this.$route.query.cyclesetid, statussetdefault);
              this.getcyclehistory(this.$route.query.cyclesetid);
            } else if (result.data.errorCode == 3) {
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function () {
                window.location.href = "/#/login";
              });
            } else {
              Swal.fire({
                title: "Failed",
                text: result.data.msg,
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            }
          })
          .catch((e) => {
            this.displayError(e);
          });
      }
    },
    selectcheckbox(event, datax, i) {
      if (event.target.checked == true) {
        this.resultids.push(parseInt(datax.testcasemasterid));
      } else if (event.target.checked == false) {
        this.resultids.forEach((element, i) => {
          if (element == datax.testcasemasterid) {
            this.resultids.splice(i, 1);
          }
        });
      }
      if (this.resultids && this.resultids.length > 0) {
        this.testcycle.status = null;
        this.statuslist = this.testscriptreuslt;
      } else {
        this.testcycle.status = null; //this.editRowData.status;
        this.getStatusConfigList(
          this.editRowData ? this.editRowData.projectist.split(",") : null,
          this.editRowData.status
        );
      }
    },
    validNumber: (event) => {
      let keyCode = event.keyCode;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    prevPage() {
      if (this.readmoreid && this.readmorerow) {
        this.idclickclose();
      }
      if (this.current_page > 1) {
        this.current_page--;
        this.paginate(this.current_page, this.records_per_page);
      }
    },
    nextPage() {
      this.isLoading = true;
      if (this.readmoreid && this.readmorerow) {
        this.idclickclose();
      }
      if (parseInt(this.current_page) < parseInt(this.numPages())) {
        this.current_page++;
        this.paginate(this.current_page, this.records_per_page);
      }
      this.isLoading = false;
    },
    paginate(page, pageSize) {
      this.isLoading = true;
      this.masterpage = page;
      let startIndex = (page - 1) * pageSize;
      let endIndex = Math.min(page * pageSize);
      if (this.tempeditRowDatas.length < endIndex) {
        endIndex = this.tempeditRowDatas.length;
      }
      this.editRowDatas = [];
      if (this.tempeditRowDatas.length > 21) {
        this.editRowDatas = this.tempeditRowDatas.slice(startIndex, endIndex);
      } else {
        this.editRowDatas = this.tempeditRowDatas;
      }

      $("#page").html(page);

      if (page < 1) page = 1;
      if (page > this.numPages()) page = this.numPages();

      if (page == 1) {
        $("#btn_prev").hide();
      } else {
        $("#btn_prev").show();
        setTimeout(function () {
          $("html, body").animate({
            scrollTop: $("#myTabContent").offset().top - 70,
          });
        }, 500);
      }

      if (page == this.numPages()) {
        $("#btn_next").hide();
      } else {
        $("#btn_next").show();
        setTimeout(function () {
          $("html, body").animate({
            scrollTop: $("#myTabContent").offset().top - 70,
          });
        }, 500);
      }
      this.isLoading = false;
    },
    numPages() {
      // let numArr = [];
      // this.tempeditRowDatas.forEach(ele => {
      //     numArr.push(ele[1].length)
      // });
      // let maxno = (numArr.length>0) ? Math.max.apply(Math, numArr) : null;
      let maxno = this.tempeditRowDatas.length;
      if (maxno) {
        return Math.ceil(maxno / this.records_per_page);
      }
    },
    formatName(authorstring) {
      let author = authorstring.replace("@firstcry.com", "");
      if (author.includes("@firstcry.in")) author = author.replace("@firstcry.in", "");
      author = author.replace(".", " ");
      author = author.charAt(0).toUpperCase() + author.slice(1);
      return author;
    },
    viewproductcommenthistory(testcasemasterid, resultid) {
      if (testcasemasterid && testcasemasterid != null) {
        $("#productcommenthistory").modal("show");
        this.isLoading = true;
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          cyclesetid: this.$route.query.cyclesetid,
          testcasemasterid: testcasemasterid,
          resultid: resultid,
        };
        axios({
          method: "POST",
          url: "api/testcycle/viewproductcommenthistory",
          data: this.input,
          headers: { authorization: this.tokendata },
        })
          .then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.historylist = result.data.data.rows;
            } else {
              this.historylist = [];
            }
          })
          .catch((e) => {
            this.displayError(e);
          });
      }
    },
  },
};
</script>
<style scoped>
.leg_cycle_text {
  justify-content: start;
  display: flex;
  align-items: baseline;
}
.testCaseName01 {
  white-space: nowrap;
  font-weight: 600;
}
/* .tast_case_groupingview_01{
    padding-bottom: 5px;
} */
.tast_case_groupingview_readmore .cycle_viewmoretext a.btn {
  padding-top: 0px;
}
.form-check-inline .form-check-input {
  cursor: pointer;
}
.fc_m16_btcode_text {
  color: #3e4757;
}
</style>
