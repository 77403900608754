<template>
<div>
    <div class="app-content content devcontent ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header-left mb-1 pt-0 ">
                <div class="container-fluid">
                    <div class="row breadcrumbs-top">
                    <div class="col-md-9 content-header-left px-0 ">
                        <h3 class="content-header-title float-left mb-0">Sonarqube</h3>
                        <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/sonarqube'>Sonarqube List's</router-link></li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            <div class="content-body">
                <section id="dashboard-ecommerce">
                <!-- start -->
              
                <div  class="card p-1 pb-0 mb-1 shadow-none border-warning">
                    <div class="container sonarqube_details px-0">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-3" style="display:none"> 
                                <h4 class="card-title mb-1"> Quality Gate Status</h4>
                                <div class="card shadow border-warning p-1">
                                    <h5 class="card-title text-center"> Quality Gate <span class="text-success"> Passed </span> </h5>
                                     <div class="sonarqube_leftside mb-2">
                                        <img src="../../public/images/icons/sonar.png"   class="img-fluid" alt="">
                                     </div>
                                     <p class="text-center"> Enjoy your sparkling clean code! </p>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-9">
                                <h4 class="card-title mb-1" v-if="convertedArr && convertedArr.length > 0"> Measures <span class="text-primary"> {{ convertedArr[0][0] }} </span></h4>
                                <div class="card shadow border-warning px-1 pt-1 pb-0 mb-1">
                                    <div class="col-sm-12 px-0">
                                         <ul class="listGrid sonarqube_viewlistgrid mb-1 float-left d-inline-flex">
                                             <li class="cursorpointercls " @click="sonarqube_toggleDiv('div1')" :class="{ active: activeButton === 'div1' }"> New Code</li>
                                             <li class="cursorpointercls" @click="sonarqube_toggleDiv('div2')" :class="{ active: activeButton === 'div2' }"> Overall Code</li>
                                         </ul>
                                    </div>
                                    <div class="container px-0">
                                        <div v-if="showDiv1">
                                            <div class="row match-height" v-if="convertedArr && convertedArr.length > 0">
                                                <div class=" col-sm-6 col-lg-4">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg01" >
                                                        <div class="cardsonardetails ">
                                                            <div v-for="item in convertedArr" :key="item[0]" class="sonarqube-item">
                                                                <div class="d-sm-flex justify-content-between align-items-center mb-1 w-100">
                                                                <div class="avatar bg-light-secondary sonar_details_bg-common rounded p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-bug font-medium-5"></em> 
                                                                    </div>
                                                                </div>
                                                                <div class="icon_passing">
                                                                    <span class="sonar_count_details rounded-circle">{{ calculateRating(getMeasureValue(item[1], 'new_bugs')) }}</span> 
                                                                </div>
                                                            </div>
                                                            <h6 class="card-text sonar_title_text_name">Reliability</h6>
                                                                <h2 class="font-weight-bolder sonar_title_text_name_count">{{ getMeasureValue(item[1], 'new_bugs') }} </h2>
                                                                <p class="card-text sonar_title_text_nameP">New Bugs</p>
                                                            </div>                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" col-sm-6 col-lg-4">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg02" >
                                                        <div class="cardsonardetails ">
                                                            <div v-for="item in convertedArr" :key="item[0]" class="sonarqube-item">
                                                                <div class="d-sm-flex justify-content-between align-items-center mb-1 w-100">
                                                                <div class="avatar bg-light-secondary sonar_details_bg-common rounded p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-tools font-medium-5"></em> 
                                                                    </div>
                                                                </div>
                                                                <div class="icon_passing">
                                                                    <span class="sonar_count_details rounded-circle">{{ calculateRating(getMeasureValue(item[1], 'new_code_smells')) }}</span> 
                                                                </div>
                                                                </div>
                                                                <h6 class="card-text sonar_title_text_name">Maintainability</h6>
                                                                    <h2 class="font-weight-bolder sonar_title_text_name_count">{{ getMeasureValue(item[1], 'new_code_smells') }}</h2>
                                                                    <p class="card-text sonar_title_text_nameP">New Code Smells</p>
                                                            </div>                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" col-sm-6 col-lg-4">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg03" >
                                                        <div class="cardsonardetails ">
                                                            <div v-for="item in convertedArr" :key="item[0]" class="sonarqube-item">
                                                                <div class="d-sm-flex justify-content-between align-items-center mb-1 w-100">

                                                                <div class="avatar bg-light-secondary sonar_details_bg-common rounded p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-shield-alt font-medium-5"></em> 
                                                                    </div>
                                                                </div>
                                                                <div class="icon_passing">
                                                                    <span class="sonar_count_details rounded-circle">{{ calculateRating(getMeasureValue(item[1], 'new_vulnerabilities')) }}</span> 
                                                                </div>
                                                                </div>
                                                                <h6 class="card-text sonar_title_text_name">Security</h6>
                                                                    <h2 class="font-weight-bolder sonar_title_text_name_count">{{ getMeasureValue(item[1], 'new_vulnerabilities') }}</h2>
                                                                    <p class="card-text sonar_title_text_nameP">New Vulnerabilities</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" col-sm-6 col-lg-4">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg04" >
                                                        <div class="cardsonardetails ">
                                                            <div v-for="item in convertedArr" :key="item[0]" class="sonarqube-item">
                                                                <div class="d-sm-flex justify-content-between align-items-center mb-1 w-100">

                                                                <div class="avatar bg-light-secondary sonar_details_bg-common rounded p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-file-code font-medium-5"></em> 
                                                                    </div>
                                                                </div>
                                                                <div class="icon_passing">
                                                                    <span class="sonar_count_details rounded-circle">{{ calculateRating(getMeasureValue(item[1], 'new_security_hotspots')) }}</span> 
                                                                </div>
                                                                </div>
                                                                <h6 class="card-text sonar_title_text_name">Security Review</h6>
                                                                    <h2 class="font-weight-bolder sonar_title_text_name_count">{{ getMeasureValue(item[1], 'new_security_hotspots') }}</h2>
                                                                    <p class="card-text sonar_title_text_nameP">New Security Hotspots</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 sonar_details_bg8bg">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg05">
                                                    <div class="d-flex justify-content-between align-items-center h-100 mb-1 w-100">
                                                        <div class="sonar_top_header_card_width">
                                                            <h6 class="card-text sonar_title_text_name">Duplications</h6>
                                                            <div v-for="item in convertedArr" :key="item[0]" class="sonarqube-item">
                                                                <h2 class="font-weight-bolder sonar_title_text_name_count">{{ getMeasureValue(item[1], 'new_duplicated_lines_density') }} %</h2>
                                                            </div>
                                                        </div>
                                                        <div class="sonar_dashboard_card_top">
                                                            <div class=" mb-25 design-planning_top_40 d-sm-flex justify-content-center align-items-center"> 
                                                            <span class="state-chart-warning mr-1"></span>
                                                        
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-else class="row match-height">
                                                <div class=" col-sm-6 col-lg-4">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg01" >
                                                        <div class="cardsonardetails ">
                                                            <div  class="sonarqube-item">
                                                                <div class="d-sm-flex justify-content-between align-items-center mb-1 w-100">
                                                                <div class="avatar bg-light-secondary sonar_details_bg-common rounded p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-bug font-medium-5"></em> 
                                                                    </div>
                                                                </div>
                                                                <div class="icon_passing">
                                                                    <span class="sonar_count_details rounded-circle">A</span> 
                                                                </div>
                                                            </div>
                                                            <h6 class="card-text sonar_title_text_name">Reliability</h6>
                                                                <h2 class="font-weight-bolder sonar_title_text_name_count">0</h2>
                                                                <p class="card-text sonar_title_text_nameP">New Bugs</p>
                                                            </div>                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" col-sm-6 col-lg-4">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg02" >
                                                        <div class="cardsonardetails ">
                                                            <div class="sonarqube-item">
                                                                <div class="d-sm-flex justify-content-between align-items-center mb-1 w-100">
                                                                <div class="avatar bg-light-secondary sonar_details_bg-common rounded p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-tools font-medium-5"></em> 
                                                                    </div>
                                                                </div>
                                                                <div class="icon_passing">
                                                                    <span class="sonar_count_details rounded-circle">A</span> 
                                                                </div>
                                                                </div>
                                                                <h6 class="card-text sonar_title_text_name">Maintainability</h6>
                                                                    <h2 class="font-weight-bolder sonar_title_text_name_count">0</h2>
                                                                    <p class="card-text sonar_title_text_nameP">New Code Smells</p>
                                                            </div>                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" col-sm-6 col-lg-4">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg03" >
                                                        <div class="cardsonardetails ">
                                                            <div class="sonarqube-item">
                                                                <div class="d-sm-flex justify-content-between align-items-center mb-1 w-100">

                                                                <div class="avatar bg-light-secondary sonar_details_bg-common rounded p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-shield-alt font-medium-5"></em> 
                                                                    </div>
                                                                </div>
                                                                <div class="icon_passing">
                                                                    <span class="sonar_count_details rounded-circle">A</span> 
                                                                </div>
                                                                </div>
                                                                <h6 class="card-text sonar_title_text_name">Security</h6>
                                                                    <h2 class="font-weight-bolder sonar_title_text_name_count">0</h2>
                                                                    <p class="card-text sonar_title_text_nameP">New Vulnerabilities</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" col-sm-6 col-lg-4">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg04" >
                                                        <div class="cardsonardetails ">
                                                            <div class="sonarqube-item">
                                                                <div class="d-sm-flex justify-content-between align-items-center mb-1 w-100">

                                                                <div class="avatar bg-light-secondary sonar_details_bg-common rounded p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-file-code font-medium-5"></em> 
                                                                    </div>
                                                                </div>
                                                                <div class="icon_passing">
                                                                    <span class="sonar_count_details rounded-circle">A</span> 
                                                                </div>
                                                                </div>
                                                                <h6 class="card-text sonar_title_text_name">Security Review</h6>
                                                                    <h2 class="font-weight-bolder sonar_title_text_name_count">0</h2>
                                                                    <p class="card-text sonar_title_text_nameP">New Security Hotspots</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 sonar_details_bg8bg">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg05">
                                                    <div class="d-flex justify-content-between align-items-center h-100 mb-1 w-100">
                                                        <div class="sonar_top_header_card_width">
                                                            <h6 class="card-text sonar_title_text_name">Duplications</h6>
                                                            <div  class="sonarqube-item">
                                                                <h2 class="font-weight-bolder sonar_title_text_name_count">0 %</h2>
                                                            </div>
                                                        </div>
                                                        <div class="sonar_dashboard_card_top">
                                                            <div class=" mb-25 design-planning_top_40 d-sm-flex justify-content-center align-items-center"> 
                                                            <span class="state-chart-warning mr-1"></span>
                                                        
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="showDiv2">
                                            <!-- Your content for Div 2 goes here -->
                                            <div class="row match-height">
                                                <div class=" col-sm-6 col-lg-4">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg01" >
                                                        <div class="cardsonardetails ">
                                                            <div v-for="item in convertedArr" :key="item[0]" class="sonarqube-item">
                                                                <div class="d-sm-flex justify-content-between align-items-center mb-1 w-100">

                                                                <div class="avatar bg-light-secondary sonar_details_bg-common rounded p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-bug font-medium-5"></em> 
                                                                    </div>
                                                                </div>
                                                                <div class="icon_passing">
                                                                    <span class="sonar_count_details rounded-circle">{{ calculateRating(getMeasureValue(item[1], 'bugs')) }}</span> 
                                                                </div>
                                                                </div>
                                                                <h6 class="card-text sonar_title_text_name">Reliability</h6>
                                                                    <h2 class="font-weight-bolder sonar_title_text_name_count">{{ getMeasureValue(item[1], 'bugs') }}</h2>
                                                                    <p class="card-text sonar_title_text_nameP">Bugs</p>
                                                            </div>                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" col-sm-6 col-lg-4">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg02" >
                                                        <div class="cardsonardetails ">
                                                            <div v-for="item in convertedArr" :key="item[0]" class="sonarqube-item">
                                                                <div class="d-sm-flex justify-content-between align-items-center mb-1 w-100">

                                                                <div class="avatar bg-light-secondary sonar_details_bg-common rounded p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-tools font-medium-5"></em> 
                                                                    </div>
                                                                </div>
                                                                <div class="icon_passing">
                                                                    <span class="sonar_count_details rounded-circle">{{ calculateRating(getMeasureValue(item[1], 'code_smells')) }}</span> 
                                                                </div>
                                                                </div>
                                                                <h6 class="card-text sonar_title_text_name">Maintainability</h6>
                                                                    <h2 class="font-weight-bolder sonar_title_text_name_count">{{ getMeasureValue(item[1], 'code_smells') }}</h2>
                                                                    <p class="card-text sonar_title_text_nameP">Code Smells</p>
                                                            </div>                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" col-sm-6 col-lg-4">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg03" >
                                                        <div class="cardsonardetails ">
                                                            <div v-for="item in convertedArr" :key="item[0]" class="sonarqube-item">
                                                                <div class="d-sm-flex justify-content-between align-items-center mb-1 w-100">

                                                                <div class="avatar bg-light-secondary sonar_details_bg-common rounded p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-shield-alt font-medium-5"></em> 
                                                                    </div>
                                                                </div>
                                                                <div class="icon_passing">
                                                                    <span class="sonar_count_details rounded-circle">{{ calculateRating(getMeasureValue(item[1], 'vulnerabilities')) }}</span> 
                                                                </div>
                                                                </div>
                                                                <h6 class="card-text sonar_title_text_name">Security</h6>
                                                                    <h2 class="font-weight-bolder sonar_title_text_name_count">{{ getMeasureValue(item[1], 'vulnerabilities') }}</h2>
                                                                    <p class="card-text sonar_title_text_nameP">Vulnerabilities</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" col-sm-6 col-lg-4">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg04" >
                                                        <div class="cardsonardetails ">
                                                            <div v-for="item in convertedArr" :key="item[0]" class="sonarqube-item">
                                                                <div class="d-sm-flex justify-content-between align-items-center mb-1 w-100">

                                                                <div class="avatar bg-light-secondary sonar_details_bg-common rounded p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-file-code font-medium-5"></em> 
                                                                    </div>
                                                                </div>
                                                                <div class="icon_passing">
                                                                    <span class="sonar_count_details rounded-circle">{{ calculateRating(getMeasureValue(item[1], 'security_hotspots_reviewed')) }}</span> 
                                                                </div>
                                                                </div>
                                                                <h6 class="card-text sonar_title_text_name">Security Review</h6>
                                                                    <h2 class="font-weight-bolder sonar_title_text_name_count">{{ getMeasureValue(item[1], 'security_hotspots_reviewed') }}</h2>
                                                                    <p class="card-text sonar_title_text_nameP">Security Hotspots</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 sonar_details_bg8bg">
                                                    <div class="card mb-1 shadow border-warning sonar_details_bg05">
                                                    <div class="d-flex justify-content-between align-items-center h-100 mb-1 w-100">
                                                        <div class="sonar_top_header_card_width">
                                                            <h6 class="card-text sonar_title_text_name">Duplications</h6>
                                                            <div v-for="item in convertedArr" :key="item[0]" class="sonarqube-item">
                                                                <h2 class="font-weight-bolder sonar_title_text_name_count">{{ getMeasureValue(item[1], 'duplicated_lines_density') }} %</h2>
                                                                <!-- <p class="card-text sonar_title_text_nameP">Duplications on 162 New Lines</p> -->

                                                            </div>
                                                        </div>
                                                        <div class="sonar_dashboard_card_top">
                                                            <div class=" mb-25 design-planning_top_40 d-sm-flex justify-content-center align-items-center"> 
                                                            <span class="state-chart-warning mr-1"></span>
                                                        
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div class="container px-0" style="display:none">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-7">
                            <div class="card px-1 pt-1">
                                <div class="card-header mb-1">
                                    <div>
                                        <h4 class="card-title mb-1">Activity</h4>
                                        <span class="card-subtitle text-muted">Demo Purpose</span>
                                    </div>
                                    <div class="col-sm-3 px-0">
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Issues" />
                                        </div>
                                </div>
                                <div class="card-body px-0">
                                <canvas class="line-chart-ex chartjs" data-height="450"></canvas>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-12 col-sm 12 col-md 6 col-lg-5">
                            <div class="card card-transaction mb-1 ">
                                <div class="card-body px-0 py-0 common_div_transcation_sonar_details">
                                <div class="common_div_transcation_sonar border-bottom p-1">
                                    <div class="transaction-item card-app-design mb-1">
                                        <div class="media">
                                            <div class="media-body">
                                                <h6 class="transaction-title mb-50">January 25, 2024 at 1:12 PM</h6>
                                                <div class="badge badge-light-secondary"> Not Provided </div>
                                            </div>
                                            </div>
                                            <div class=" ml-1">
                                                <div class="font-weight-normal mb-50">Quality Gate:</div>
                                                <div class="d-flex align-items-center mr-2">
                                                    <span class="sonar_alert_icon_padd bullet bullet-success font-small-3 mr-50 cursor-pointer rounded alert-success">
                                                        <em class="las la-check"></em>
                                                    </span>
                                                    <span>Passed</span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="sonar_newanalysis d-flex ">  <strong> New analysis: </strong>  
                                        <div class="d-flex align-items-center ml-1">
                                            <div class="d-flex align-items-center mr-2">
                                                <span class=" font-small-3 mr-50 cursor-pointer sonar_analysisfa">
                                                    <em class="las la-arrow-right text-success font-medium-5"></em>
                                                </span>
                                                <span>-6,030 Issues</span>
                                            </div>
                                            <div class="d-flex align-items-center ml-75">
                                                <span class="mx-50 bullet bullet-warning bullet-sm"></span>
                                                <span>-43.8% Duplications</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="common_div_transcation_sonar border-bottom p-1">
                                    <div class="transaction-item card-app-design mb-1">
                                        <div class="media">
                                            <div class="media-body">
                                                <h6 class="transaction-title mb-50">January 24, 2024 at 12:39 PM</h6>
                                                <div class="badge badge-light-secondary"> Not Provided </div>
                                            </div>
                                            </div>
                                            <div class="ml-1">
                                                <div class="font-weight-normal mb-50">Quality Gate:</div>
                                                <div class="d-flex align-items-center mr-2">
                                                    <span class="sonar_alert_icon_padd bullet bullet-success font-small-3 mr-50 cursor-pointer rounded alert-success">
                                                        <em class="las la-check"></em>
                                                    </span>
                                                    <span>Passed</span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="sonar_newanalysis d-flex">  <strong> New analysis: </strong>  
                                        <div class="d-flex align-items-center ml-1">
                                            <div class="d-flex align-items-center mr-2">
                                                <span class=" font-small-3 mr-50 cursor-pointer sonar_analysisfa">
                                                    <em class="las la-arrow-right text-success font-medium-5"></em>
                                                </span>
                                                <span>-6,030 Issues</span>
                                            </div>
                                            <div class="d-flex align-items-center ml-75">
                                                <span class="mx-50 bullet bullet-warning bullet-sm"></span>
                                                <span>-43.8% Duplications</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="common_div_transcation_sonar border-bottom p-1">
                                    <div class="transaction-item card-app-design mb-1">
                                        <div class="media">
                                            <div class="media-body">
                                                <h6 class="transaction-title mb-50">January 23, 2024 at 8:16 PM</h6>
                                                <div class="alert alert-default mt-1 mb-0 alert-validation-msg border"> 
                                                    <div class="alert-body  "> <em class="las la-times text-danger feather feather-info mr-50 align-middle text-secondary"></em>
                                                        <span class="font-small-1"> First analysis since upgrading to SonarQube 10.3.0.82913</span>
                                                    </div> 
                                                     </div>
                                            </div>
                                            </div>
                                            <div class="">
                                                <div class="font-weight-normal mb-50">Quality Gate:</div>
                                                <div class="d-flex align-items-center mr-2">
                                                    <span class="sonar_alert_icon_padd bullet bullet-danger font-small-3 mr-50 cursor-pointer rounded alert-danger">
                                                        <em class="las la-times"></em>
                                                    </span>
                                                    <span>Failed</span>
                                                </div>
                                            </div>
                                    </div>
                                    <p> Quality Profile:"Sonar way" (JavaScript) updated with 28 new rules</p>
                                    <div class="sonar_newanalysis d-flex">  <strong> New analysis: </strong>  
                                        <div class="d-flex align-items-center ml-1">
                                            <div class="d-flex align-items-center mr-1">
                                                <span class=" font-small-3 mr-50 cursor-pointer sonar_analysisfa_danger">
                                                    <em class="las la-arrow-right text-danger font-medium-5"></em>
                                                </span>
                                                <span>-6,030 Issues</span>
                                            </div>
                                            <div class="d-flex align-items-center ml-75">
                                                <span class="mx-50 bullet bullet-warning bullet-sm"></span>
                                                <span class=" font-small-3 mr-50 cursor-pointer sonar_analysisfa_danger">
                                                    <em class="las la-arrow-right text-danger font-medium-5"></em>
                                                </span>
                                                <span>-43.8% Duplications</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="common_div_transcation_sonar border-bottom p-1">
                                    <div class="transaction-item card-app-design mb-1">
                                        <div class="media">
                                            <div class="media-body">
                                                <h6 class="transaction-title mb-50">January 12, 2024 at 11:14 AM</h6>
                                               
                                            </div>
                                            </div>
                                            <div class="">
                                                <div class="font-weight-normal mb-50">Quality Gate:</div>
                                                <div class="d-flex align-items-center mr-2">
                                                    <span class="sonar_alert_icon_padd bullet bullet-danger font-small-3 mr-50 cursor-pointer rounded alert-danger">
                                                        <em class="las la-times text-danger"></em>
                                                    </span>
                                                    <span>Failed</span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="sonar_newanalysis d-flex">  <strong> New analysis: </strong>  
                                        <div class="d-flex align-items-center ml-1">
                                            <div class="d-flex align-items-center mr-2">
                                                <span class=" font-small-3 mr-50 cursor-pointer sonar_analysisfa">
                                                    <em class="las la-arrow-right text-success font-medium-5"></em>
                                                </span>
                                                <span>-6,030 Issues</span>
                                            </div>
                                            <div class="d-flex align-items-center ml-75">
                                                <span class="mx-50 bullet bullet-warning bullet-sm"></span>
                                                <span>-43.8% Duplications</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="common_div_transcation_sonar border-bottom p-1">
                                    <div class="transaction-item card-app-design mb-1">
                                        <div class="media">
                                            <div class="media-body">
                                                <h6 class="transaction-title mb-50">January 25, 2024 at 1:12 PM</h6>
                                                <div class="badge badge-light-secondary"> Not Provided </div>
                                            </div>
                                            </div>
                                            <div class=" ml-1">
                                                <div class="font-weight-normal mb-50">Quality Gate:</div>
                                                <div class="d-flex align-items-center mr-2">
                                                    <span class="sonar_alert_icon_padd bullet bullet-success font-small-3 mr-50 cursor-pointer rounded alert-success">
                                                        <em class="las la-check"></em>
                                                    </span>
                                                    <span>Passed</span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="sonar_newanalysis d-flex ">  <strong> New analysis: </strong>  
                                        <div class="d-flex align-items-center ml-1">
                                            <div class="d-flex align-items-center mr-2">
                                                <span class=" font-small-3 mr-50 cursor-pointer sonar_analysisfa">
                                                    <em class="las la-arrow-right text-success font-medium-5"></em>
                                                </span>
                                                <span>-6,030 Issues</span>
                                            </div>
                                            <div class="d-flex align-items-center ml-75">
                                                <span class="mx-50 bullet bullet-warning bullet-sm"></span>
                                                <span>-43.8% Duplications</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="common_div_transcation_sonar border-bottom p-1">
                                    <div class="transaction-item card-app-design mb-1">
                                        <div class="media">
                                            <div class="media-body">
                                                <h6 class="transaction-title mb-50">January 25, 2024 at 1:12 PM</h6>
                                                <div class="badge badge-light-secondary"> Not Provided </div>
                                            </div>
                                            </div>
                                            <div class=" ml-1">
                                                <div class="font-weight-normal mb-50">Quality Gate:</div>
                                                <div class="d-flex align-items-center mr-2">
                                                    <span class="sonar_alert_icon_padd bullet bullet-success font-small-3 mr-50 cursor-pointer rounded alert-success">
                                                        <em class="las la-check"></em>
                                                    </span>
                                                    <span>Passed</span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="sonar_newanalysis d-flex ">  <strong> New analysis: </strong>  
                                        <div class="d-flex align-items-center ml-1">
                                            <div class="d-flex align-items-center mr-2">
                                                <span class=" font-small-3 mr-50 cursor-pointer sonar_analysisfa">
                                                    <em class="las la-arrow-right text-success font-medium-5"></em>
                                                </span>
                                                <span>-6,030 Issues</span>
                                            </div>
                                            <div class="d-flex align-items-center ml-75">
                                                <span class="mx-50 bullet bullet-warning bullet-sm"></span>
                                                <span>-43.8% Duplications</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="common_div_transcation_sonar border-bottom p-1">
                                    <div class="transaction-item card-app-design mb-1">
                                        <div class="media">
                                            <div class="media-body">
                                                <h6 class="transaction-title mb-50">January 25, 2024 at 1:12 PM</h6>
                                                <div class="badge badge-light-secondary"> Not Provided </div>
                                            </div>
                                            </div>
                                            <div class=" ml-1">
                                                <div class="font-weight-normal mb-50">Quality Gate:</div>
                                                <div class="d-flex align-items-center mr-2">
                                                    <span class="sonar_alert_icon_padd bullet bullet-success font-small-3 mr-50 cursor-pointer rounded alert-success">
                                                        <em class="las la-check"></em>
                                                    </span>
                                                    <span>Passed</span>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="sonar_newanalysis d-flex ">  <strong> New analysis: </strong>  
                                        <div class="d-flex align-items-center ml-1">
                                            <div class="d-flex align-items-center mr-2">
                                                <span class=" font-small-3 mr-50 cursor-pointer sonar_analysisfa">
                                                    <em class="las la-arrow-right text-success font-medium-5"></em>
                                                </span>
                                                <span>-6,030 Issues</span>
                                            </div>
                                            <div class="d-flex align-items-center ml-75">
                                                <span class="mx-50 bullet bullet-warning bullet-sm"></span>
                                                <span>-43.8% Duplications</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                
                            </div>
                        </div>
                     </div>
                    </div>
            </section>

            </div>
        </div>
    </div>
    

</div>
</template>

<script>

import axios from 'axios'
import apiURL from '../constants';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  SearchIcon,XIcon,
  } from 'vue-feather-icons'
import CountryFlag from 'vue-country-flag'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import usercommondashboard from './dashboards/Usercommondashboard';
import commonMethods from '../utils/commonMethods';

export default({
    name:'sonarqubeDetails',
    components:{
        SearchIcon,Treeselect,XIcon,
        Loading
    },
    mixins: [ commonMethods ],
    data() {
        return {
            isLoading: false,
            fullPage: true,
            showDiv1: true,
            showDiv2: false,
            activeButton: 'div1',
            un:'',
            createdby:'',
            isSearchRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            tokendata:null,
            pagefeatures:[], 
            sonarqubeData:[],
            convertedArr:[],
            
        }  
    } ,
    
    mounted(){  
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
          userdata = JSON.parse(userdata)
          this.undt=userdata
          this.tokendata = window.localStorage.getItem('token');
        }
        console.log(this.$route,"routes;;;;;;;")

        this.getSonarQubeDataByRepo(this.$route.query.repo,'new')

        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        // sonarqube_toggleDiv(div) {
        //     if (this.activeButton !== div) {
        //         this.activeButton = div;
        //         this.showDiv1 = div === 'div1';
        //         this.showDiv2 = div === 'div2';
        //     }
        // },
        sonarqube_toggleDiv(div) {
             if (this.activeButton !== div) {
                this.activeButton = div;
                this.showDiv2 = div === 'div2';
                this.showDiv1 = div === 'div1';
                if (div === 'div2') {
                    this.getSonarQubeDataByRepo(this.$route.query.id, 'overall');
                } else if(div === 'div1') {
                    this.getSonarQubeDataByRepo(this.$route.query.id, 'new');
                }
            }
        },
        getMeasureValue(data, measureMetric) {
            const measure = data.find(item => item.measureMetric === measureMetric);
            if (measure) {
                return measure ? (measure.measureValue !== null ? measure.measureValue : 0) : 0;
            }
            return '';
        },
        calculateRating(measureValue) {
            if (measureValue <= 5) {
                return 'A';
            } else if (measureValue <= 10) {
                return 'B';
            } else if (measureValue <= 20) {
                return 'C';
            } else if (measureValue <= 50) {
                return 'D';
            } else {
                return 'E';
            }
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        getSonarQubeDataByRepo(repo,perspective) {
            this.isLoading = true
            let url = 'api/sonarqube/getSonarQubeReportbyRepo'
            let payload = new Object({
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                // project: parseInt(this.project),
                perspective : perspective,
                repo: this.$route.query.repo,//repo
            })
            axios({
            method: 'POST',
            url,
            data: payload,
            headers:{ authorization :this.tokendata }
            }).then(result => {
            this.isLoading = false
            if(result.data.errorCode == 0) {
                this.sonarqubeData = result.data.data
                const groupBySonarData = result.data.data.reduce((group, product) => {
                  const { componentKey } = product;
                  group[componentKey] = group[componentKey] ?? [];
                  group[componentKey].push(product);
                  return group;
                }, {});
                this.convertedArr = Object.entries(groupBySonarData);

                // this.totalcount = result.data.data.count
                // this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
            } else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
                this.sonarqubeData=[]
        //     this.totalcount = 0
        //    this.pageCount = 0   
            }
                }).catch(e => {
                this.displayError(e)
            }); 
        },
    }
})
</script>
<style scoped>
.alignment{
    margin-left: 20px;
    margin-bottom: 20px;
}
.cardcenter{
    text-align: center;
    color: white;
}
</style>
