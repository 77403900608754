<template>
    <video  controls ref="videoElement" style="width:100%;">
        <source :src="presignurldatamp4" type="video/mp4" class="videoclass" v-if="presignurldatamp4">
        <source :src="presignurldatamp3" type="video/mp3" class="videoclass" v-if="presignurldatamp3">
        <source :src="presignurldatawebm" type="video/webm" class="videoclass" v-if="presignurldatawebm">
        <source :src="presignurldatavlc" type="video/vlc" class="videoclass" v-if="presignurldatavlc">
    </video>
</template>
<script>
import axios from 'axios'
import { DownloadIcon} from 'vue-feather-icons'
import commonMethods from '../utils/commonMethods';

export default {
    name: 'articlevideo',
    mixins: [ commonMethods ],
    props: ['getUrl'],
    components:{
        DownloadIcon
    },
    data() {
        return {
          presignurldatamp4: null,
          presignurldatamp3: null,
          presignurldatawebm: null,
          presignurldatavlc: null,
        }
    },
    beforeMount() {
        this.getLogtimeDownloadLink()
    },
    methods:{
        getLogtimeDownloadLink() {
            this.tokendata = window.localStorage.getItem('token');
            let userdata = window.localStorage.getItem('userdata');
            userdata = JSON.parse(userdata)
            this.undt = userdata

            let payload = {
                link:this.getUrl,
                empcode: this.undt.userid,
                useremail: this.undt.username
            };
            axios({
                'method': 'POST',
                'url': "/api/imageupload/getProfilePicDownloadLink",
                'data':payload,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                if(result.data.status) {
                    if(result.data.data.length>0) {
                        if(this.getUrl.substring(this.getUrl.lastIndexOf('.')+1) == 'mp4'){
                            this.presignurldatamp4 =result.data.data;
                        }else if(this.getUrl.substring(this.getUrl.lastIndexOf('.')+1) == 'mp3'){
                            this.presignurldatamp4 =result.data.data;
                        }else if(this.getUrl.substring(this.getUrl.lastIndexOf('.')+1) == 'webm'){
                            this.presignurldatamp4 =result.data.data;
                        }else if(this.getUrl.substring(this.getUrl.lastIndexOf('.')+1) == 'vlc'){
                            this.presignurldatamp4 =result.data.data;
                        }
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        }
    }
}
</script>