<template>
<div>
   <nav class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow" style="margin:0 !important;width:100% !important;height:70px !important;">
        <div class="navbar-container d-flex content">
            <div class="bookmark-wrapper d-flex align-items-center">
                <ul class="nav navbar-nav d-xl-none">
                    <li class="nav-item"><a class="nav-link menu-toggle" href="#">
                        <menu-icon size="1.5x" class="custom-class ficon"></menu-icon>
                        </a></li>
                </ul>
            </div>
            <div class="logo_view_smart">
                <a class="brand-logo" alt="SmartBees" title="SmartBees" href="#">
                    <img src="../../public/images/logo/logonew-1.png"  height="48px" class="desktop_view_show" alt="">
                    <img src="../../public/images/logo/logo_mobile.png" class="mobile_view_show" height="50px" alt="">
                </a>
                <a class="brand-logo logo_for_darktheme" alt="SmartBees" title="SmartBees" href="#">
                    <img src="../../public/images/logo/logo-dark-4.png"  height="55px" class="desktop_view_show" alt="">
                    <img src="../../public/images/logo/logo_mobile.png" class="mobile_view_show" height="50px" alt="">
                </a>
            </div>
            <div class="nav-search  search_filtter_animation sarch_afterchange_design12">
                    <div class="search">
                        <input type="text" class="search-box" placeholder="Search..." v-model="searchTerm" @keyup.enter="searchAll"/>
                        <button type="button" id="search_bar" v-on:click.stop.prevent="searchAll"><search-icon size="1.5x" class="custom-class"></search-icon></button>
                    </div>
            </div>
            <ul class=" navbar-nav align-items-center  res_nav_navbar_content">
                <li class="nav-item b-nav-dropdown dropdown dropdown-notification mr-25" style="display:none;">
                     <span class="feather-icon add_sidebar_projectlist_icons " data-toggle="modal" data-target="#add_sidebar_projectlist">
                          
                        <em class="feather feather-bell ficon new-icon" data-feather="grid"></em>
                    </span>
                </li>
                <li class="nav-item b-nav-dropdown dropdown dropdown-notification" style="display:none;">
                    <a class="nav-link dropdown-toggle dropdown-user-link text-primary" id="whats_new" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="getnotificationlist">
                        <span class="feather-icon position-relative">
                            <em class="feather feather-mail ficon new-icon" data-feather="mail"></em>
                        <span class="badge badge-pill notification" style="overflow:initial">{{this.unreaddocs}}</span>
                        </span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right" aria-labelledby="whats_new">
                        <li class="dropdown-menu-header"><div class="dropdown-header d-flex"><h4 class="notification-title mb-0 mr-auto"> Post Notifications </h4><span class="badge badge-light-primary badge-pill"> {{this.notificationlist.length}} New </span></div></li>
                        <div class="col-md-12 px-0 mt-0 tast_case_groupingview_readmore">
                            <div v-if="notificationlist">
                                <li class="ps-container scrollable-container media-list scroll-area ps ps--active-y">
                                    <template v-for="(datas, indexx) in notificationlist">
                                        <a style="cursor: default" target="_self" :id="datas.courseid" v-bind:key="indexx" @click="redirecttourl('article',datas)">
                                            
                                            <div class="media d-flex align-items-start  postnotificationview_top10 pb-0">
                                                <div class="avatar mr-75 colleagueimage">
                                                    <profilepic v-if="datas.imagename && datas.empcode" v-bind:get-url="'empprofile/'+datas.empcode+'/'+datas.imagename" :key="`${indexx}`"></profilepic>
                                                    <img alt="" :src="defaultimagepath" width="40" height="40" v-else/>
                                                </div>
                                                
                                                <div class="media-body">
                                                    <div class="postnotificationview" style="justify-content: space-between; align-items:center">
                                                        <div class="media-heading">
                                                            <span class="font-weight-bolder capitalisetext">{{ datas.postedbyname }}</span> 
                                                            <div class="d-block">
                                                            <span v-if="datas.createddate != datas.lastmodifieddate"> updated: </span> 
                                                            <span v-else> posted: </span> 
                                                            <span class="capitalisetext">{{datas.coursename}}</span>
                                                            </div>
                                                        </div>
                                                        <p class="notification-text text-right mb-0">
                                                        <span v-if="datas.createddate != datas.lastmodifieddate">Updated On: {{datas.lastmodifieddate ? dateMonthFormat(datas.lastmodifieddate) : 'NA'}}</span>
                                                        <span v-else>Posted On: {{datas.createddate ? dateMonthFormat(datas.createddate) : 'NA'}}</span>
                                                    </p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </a>
                                    </template>
                                </li>
                            </div>
                            <div class="cycle_viewmoretext dropdown-menu-footer notify_readmore" v-if="notificationlist && notificationlist.length > 1">
                                <a class="btn readmore_text">
                                        Read More
                                </a>
                            </div>
                        </div>
                    </ul>
                </li>
                <li class="nav-item b-nav-dropdown dropdown dropdown-notification mr-25">
                    <a class="nav-link dropdown-toggle dropdown-user-link text-primary" id="whats_new" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="feather-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell ficon new-icon"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                        <span class="badge badge-pill notification" style="overflow:initial">{{this.unreaddocs}}</span>
                        </span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right" aria-labelledby="whats_new">
                        <li class="dropdown-menu-header"><div class="dropdown-header d-flex"><h4 class="notification-title mb-0 mr-auto"> Notifications </h4><span class="badge badge-light-primary badge-pill"> {{this.whats_new_list.length}} New </span></div></li>
                        <li class="ps-container scrollable-container media-list scroll-area ps ps--active-y">
                            <template v-for="(data, index) in whats_new_list">
                                <a style="cursor: default" target="_self" v-bind:key="index">
                                    
                                    <div class="media pt-0 pb-0">
                                        <div class="media-aside align-self-start">
                                            <span class="b-avatar badge-light-success rounded-circle" style="width: 32px; height: 32px;"></span>
                                        </div>
                                        <div class="media-body">
                                            <div class="d-flex" style="justify-content: space-between; align-items:center">
                                            <p class="media-heading capitalisetext">
                                                <span class="font-weight-bolder">{{data.filename}}</span>
                                            </p>
                                            <span><a @click="getLogtimeDownloadLink(data.docname)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect"><download-icon size="1.5x" class="custom-class "></download-icon> </a></span>
                                            </div>
                                            <p class="notification-text">
                                                <span>{{dateMonthFormat(data.createddate)}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </template>
                        </li>
                         <li class="dropdown-menu-footer notify_readmore">
                            <a class="readmore_text" href="#">ReadMore</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown dropdown-user">
                    <a class="nav-link dropdown-toggle dropdown-user-link text-primary" id="dropdown-user" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                         <span class="avatar">
                            <img  class="round" :src="profileurl"  height="40" width="40" alt="">
                            <span class="avatar-status-online"></span>
                        </span>
                        <div class="user-nav d-sm-flex d-none">
                            <span class="user-name font-weight-bolder">{{un}}</span>
                            <span class="user-status">{{decryptText(designation)}} <span class="dept_text"> [{{decryptText(dept)}}]</span></span>
                        </div>
                         
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown_profileview_icons" aria-labelledby="dropdown-user">
                        <router-link to="/profile" class="dropdown-item" >
                        <em class="las la-user custom-class mr-50"></em>
                         Profile</router-link>
                        <router-link to="/myactivity" v-if="this.roleid && this.roleid !=566" class="dropdown-item" >
                            <em class="las la-history custom-class mr-50"></em>
                            My Activity
                        </router-link>
                        <!-- <router-link to="/teamactivity" v-if="this.roleid && this.roleid !=566" class="dropdown-item" >
                             <watch-icon size="1.5x" class="custom-class mr-50"></watch-icon>
                            Team Activity
                        </router-link>
                        <router-link to="/logtimeactivity" v-if="this.roleid && this.roleid !=566" class="dropdown-item" >
                             <em class="las la-file-invoice custom-class mr-50"></em>
                            Log Activity
                        </router-link> -->
                         <!-- <router-link to="/mycalendars" v-if="this.roleid && this.roleid !=566" class="dropdown-item" >
                         <i data-feather='calendar' class="custom-class mr-50"></i>
                            My Calendar 
                        </router-link>
                        <router-link to="/login" class="dropdown-item" >
                             <power-icon size="1.5x" class="custom-class mr-50" data-feather="power"></power-icon>
                             Logout
                        </router-link> -->
                        <a type="submit" class="dropdown-item text-primary"  tabindex="4" @click="LogSession()" >
                            <power-icon size="1.8x" class="custom-class mr-50" data-feather="power" @click="LogSession()" style="    position: relative;left: 2px;top: -1px;"></power-icon> 
                            &nbsp;Logout</a>

                    </div>
                </li>
                
            </ul>
        </div>
    </nav>
    <div class="modal modal-slide-in event-sidebar fade" id="add_sidebar_projectlist">
        <div class="modal-dialog sidebar-lg">
            <div class="modal-content p-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                <div class="modal-header mb-1">
                    <h5 class="modal-title">Related Projects</h5>
                </div>
                <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
                    <div class="search_projectslist">
                        <div class="nav-search search_filtter_animation"> 
                            <div class="search">
                                <input type="text" class="search-box" placeholder="Search..." v-model="searchAllocatedSys"/>
                                <button type="button" id="search_bar"><search-icon size="1.5x" class="custom-class"></search-icon></button>
                            </div>
                        </div>
                        <div class="projectlistgrid mt-2 customAnimation ">
                            <div class="row mx-0">
                                <div class="col-sm-4 projectlistgrid_padd05" v-for="(system, index) in filteredSystems" :key="index">
                                    <div class="card text-center shadow-none mb-1">
                                        <div class="card-body rightside_projectlistgrid_custom px-0 py-1" @click="userAlloSystems(system.systemkey)" >
                                            <div class="avatar bg-light-danger p-50 mb-1">
                                                <div class="avatar-content">
                                                    <img class="card-title font-weight-bold mb-0 img-fluid" src="../../public/images/logo/logo_mobile.png" alt="bg">
                                                </div>
                                            </div>
                                            <p class="card-text" style="text-transform: capitalize; color: blue;  font-size: 14px" @click="redirecttoProject(system.systemname)">{{ system.systemname }}</p>

                                            <!-- <button class="btn btn-relief-primary btn-block mb-1" tabindex="4"   >
                                                <vue-element-loading
                                                    :active="isLoggingIn"
                                                    spinner="bar-fade-scale"
                                                    color="#4F46E5"
                                                    size="25"
                                                    />
                                                <span>{{ system.systemname }}</span>
                                            </button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div> 

</template>
<script>
import axios from 'axios'
import apiUrl from '../constants';
import moment from "moment";
import { MenuIcon,WatchIcon, DownloadIcon, SearchIcon,PowerIcon,UserIcon } from 'vue-feather-icons'
import { EventBus } from '../main'
import commonMethods from '../utils/commonMethods';
import profilepic from './profilepic.vue';

import VueElementLoading from "vue-element-loading";

export default ({
    name:'Nav',
    mixins: [ commonMethods ],
    components:{
        UserIcon,
        PowerIcon,
        MenuIcon,WatchIcon, DownloadIcon, SearchIcon,profilepic,VueElementLoading
    },
    data() {
        return {
            un:'',
            dept:'',
            designation:'',
            profileurl :apiUrl.defaultimagepath,
            whats_new_list: [],
            searchTerm:null,
            search_results: [],
            useremail :null,
            unreaddocs:null,
            roleid:null,
            isLoading: false,
            isLoggingIn: false,
            undt: null,
            notificationlist: [],
            defaultimagepath: apiUrl.defaultimagepath,
            userallocatedsystems:[],
            searchAllocatedSys: '',
            storedPassword:'',
            captchaRes:''
        }  
    } ,

    computed: {
        filteredSystems() {
            const searchAllocatedSys = this.searchAllocatedSys.toLowerCase().trim();
            if (!searchAllocatedSys) {
                return this.userallocatedsystems;
            } else {
                return this.userallocatedsystems.filter(system =>
                system.systemname.toLowerCase().includes(searchAllocatedSys)
                );
            }
        }
    },

    mounted(){
        
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.tokendata = window.localStorage.getItem('token');
            this.roleid = userdata.roleid
            this.undt = userdata;
            this.empid = userdata.userid
            this.useremail = userdata.emailaddress
            this.userallocatedsystems = userdata.userallocatedsystems
            this.getprofiledata()
            this.getWhatsNewFeatureList()
            this.storedPassword = localStorage.getItem('dwsp');

            // this.getDownloadCount()
        }else{
            this.$router.push('/login');
        }
        
    },
    methods: {
        backtoHomepage: function() {
            this.$router.push({ path: "/home" }); 
        },

        userAlloSystems(systemkey){
            let payload = {
                password: this.storedPassword,
                username: this.useremail,
                systemkey: systemkey
            };
            this.isLoading = true;  
            this.isLoggingIn = true
  
            let url = "api/user/campUserAllocatedSystem";
            axios({
                method: "POST",
                url: url,
                data: payload,
                headers: { authorization: this.tokendata },
            }).then((result) => {
                this.isLoading = false;
                let details = result.data.data
                let pagefeatures = []
                let sidebarData = []
                let sidebararr = details.access['menudetail']
                let pageroutes = details.access['pageroutedetail']

                let defaultPage = pageroutes.filter(item => item.defaultpage == true)
                    let finalcheck = []
                    for (let i = 0; i <pageroutes.length; i++) {
                        if(pageroutes[i].pagefeatures.length > 0){
                            pageroutes[i].pagefeatures.forEach(element => {
                            pagefeatures.push({page: pageroutes[i].pageroute.replace(/\s+/g, '').toLowerCase(), featurename: element.featurename, featureaccess: element.featureaccess.c === 'Y'})
                            })
                        }
                    }

                    for (let i = 0; i <sidebararr.length; i++) {
                        if(sidebararr[i].pagefeatures.length > 0){
                            sidebararr[i].pagefeatures.forEach(element => {
                            pagefeatures.push({page: sidebararr[i].menuname.replace(/\s+/g, '').toLowerCase(), featurename: element.featurename, featureaccess: element.featureaccess.c === 'Y'})
                            })
                        }
                        let parentmenu = ''
                        parentmenu = sidebararr[i].menuname
                        let childmenu = ''
                        let children = []
                        if (sidebararr[i].submenu) {
                            childmenu = sidebararr[i].submenu
                            if (childmenu.length > 0) {
                                for (let j = 0; j < childmenu.length; j++) {
                                    finalcheck.push('#/'+parentmenu+'/'+childmenu[j].pagename.split('/')[2])
                                    children.push({
                                        'name': childmenu[j].menuname,
                                        'url': childmenu[j].pagename,
                                        'icon': childmenu[j].iconname
                                    })
                                }
                            }
                                
                        }
                        sidebarData.push({
                        name: parentmenu,
                            'url':  sidebararr[i].pagename,
                            'icon': sidebararr[i].iconname,
                            'children': children
                        })
                        
                        if (i == sidebararr.length-1) {
                            localStorage.setItem('sidebarnew',window.btoa(JSON.stringify(sidebarData)));
                            localStorage.setItem('check',window.btoa(JSON.stringify(finalcheck)));
                            localStorage.setItem('pagefeatures',window.btoa(JSON.stringify(pagefeatures)));
                            localStorage.setItem('systemid',window.btoa(JSON.stringify(details.systemid)));

                            this.returnToUrl = sessionStorage.getItem('pathToLoadAfterLogin')
                            localStorage.setItem('userrole',null);
                            if( details.roleid==429 || details.roleid==430 || details.roleid==453 || details.roleid==528){
                                /*If user having product role redirect to welcome page and tag role*/
                                localStorage.setItem('taskmodule',1);
                                localStorage.setItem('userrole','product');
                                this.$router.push(sessionStorage.getItem('pathToLoadAfterLogin') || '/welcome');
                            }else if(details.roleid==500  || details.roleid==413  || details.roleid==446  || details.roleid==533){
                                /*If user having non tech role redirect to welcome page*/
                                localStorage.setItem('taskmodule',0);
                                this.$router.push(sessionStorage.getItem('pathToLoadAfterLogin') || '/welcome');
                            }
                            // else{
                            //     localStorage.setItem('taskmodule',0);
                            //     this.$router.push(sessionStorage.getItem('pathToLoadAfterLogin') || '/dashboard');
                            // }
                            sessionStorage.removeItem('pathToLoadAfterLogin');
                        }
                        setTimeout(function () {
                            window.location.reload();
                        }, 300);
                        this.isLoggingIn = false


                        // window.location.reload(); 
                        
                    }
                
            }).catch(e => {
                this.displayError(e)
            });
        },  

        redirecttoProject:function(systemname){
            if(systemname =='trs'){
                this.$router.push({ 
                    path: '/trs/mydashboard',
                    params: {
                            // backbtnstate:btnstate,
                        clickeddata:systemname
                    }
                })
            }
            else{
                this.$router.push({ 
                    path: '/welcome',
                    params: {
                            // backbtnstate:btnstate,
                        clickeddata:systemname
                    }
                })
            }
        },
        getprofiledata(){
            this.apiURL="api/master/imageupload/getPreSignedUrl"
            this.input={
                empcode:this.empid,
                useremail:this.useremail
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.status){
                    let response = result.data.data;
                    if(response.profilepic){
                        this.profileurl=response.profilepic;
                        
                    }
                    this.un=response.firstname+' '+response.lastname
                    this.dept = response.department
                    this.designation = response.designation
                    
                }
            }).catch(e => {
            this.displayError(e)
            })
        },  
        getWhatsNewFeatureList() {
            this.isLoading = true
            let url = 'api/whats-new/list'
            axios({
                method: 'POST',
                url,
                data: { empid: parseInt(this.empid),empcode: this.empid, useremail: this.useremail },
                headers:{ authorization :this.tokendata }
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.whats_new_list = result.data.data
                    this.getDownloadCount()
                } else {
                    this.whats_new_list = []
                }
                }).catch(e => {
                this.displayError(e)
                })
        },
        formatName(authorstring) {
            if(!authorstring) return 'NA'
            let author=authorstring.replace('@firstcry.com','')
            if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
            author = author.replace('.',' ')
            author = author.charAt(0).toUpperCase() + author.slice(1);
            return author
        },
        dateMonthFormat(value) {
            if (!value) return "NA";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        getLogtimeDownloadLink(data) {      
            if(data) {
                this.isLoading = true
                var input =[]
                input.push({link:data, useremail: this.useremail, empcode: this.empid})
                let payload = {
                    documents:input,
                    useremail:this.useremail,
                    empcode: this.empid
                };

                let url = '/api/imageupload/getdownloadlink'
                axios({
                    method: 'POST',
                    url,
                    data: payload,
                    headers:{ authorization :this.tokendata }
                }).then(result => {
                    this.isLoading = false
                    if(result.data.status) {
                        this.markasdownload()
                        if(result.data.data[0].presignurl){
                            window.open(result.data.data[0].presignurl, '_blank')        
                        }
                    }
                }).catch(e => {
                    this.isLoading = false
                    this.displayError(e)
                    })
                // axios.post("/api/imageupload/getdownloadlink", payload)
                //     .then((result) => {
                //         this.isLoading = false
                //         if(result.data.status) {
                //             this.markasdownload()
                //             if(result.data.data[0].presignurl){
                //                 // window.location.href =  result.data.data[0].presignurl;    
                //                 window.open(result.data.data[0].presignurl, '_blank')        
                //             }
                //         }
                //     })
                //     .catch(e => {
                //         this.isLoading = false
                //         console.log(`error: ${e}`)
                //     })
            }
        },
        getDownloadCount(){
            let url = 'api/whats-new/getDownloadCount'
            axios({
                method: 'POST',
                url,
                data: { empid: parseInt(this.empid),useremail:this.useremail,empcode: this.empid },
                headers:{ authorization :this.tokendata }
            }).then(result => {
                if(result.data.errorCode == 0) {
                    if((result.data.data).length>0){
                        let totaldocs = (this.whats_new_list).length
                        let totalread = result.data.data[0].totaldownloads
                        this.unreaddocs =totaldocs >0 ?((totalread>totaldocs)?0:totaldocs - totalread): 0
                    }else{
                        this.unreaddocs = this.whats_new_list?(this.whats_new_list).length:0
                    }
                } else {
                    this.unreaddocs = this.whats_new_list?(this.whats_new_list).length:0
                }
            }).catch(e => {
                    this.displayError(e)
                    })
        },
        markasdownload(){
            let url = 'api/whats-new/markasdownload'
            axios({
                method: 'POST',
                url,
                data: { empid: parseInt(this.empid),useremail:this.useremail, empcode: this.empid },
                headers:{ authorization :this.tokendata }
            }).then(result => {
                this.getWhatsNewFeatureList()
            })
        },
        searchAll() {
            if(!this.searchTerm || (this.searchTerm && !this.searchTerm.trim())) return
            let searchCode = this.searchTerm.trim().toUpperCase()
            EventBus.$emit('search', searchCode)
            this.$router.push({
                path: `/searchdetails?code=${searchCode}`, 
                query: { code: searchCode }, 
                params: { search: true }}
            ).catch(error => {
                if (error.name != "NavigationDuplicated") {
                    throw error;
                }
            });
        },
        LogSession(){
            let payload = {
                emailid:this.useremail,
                empcode: this.empid,
                useremail: this.useremail
            };
            localStorage.clear();
            this.isLoading = true;    
                let url = "api/user/LogSession";
                axios({
                    method: "POST",
                    url: url,
                    data: payload,
                    headers: { authorization: this.tokendata },
                }).then((result) => {
                    this.isLoading = false;
                    
                }).catch(e => {
                this.displayError(e)
                });
                this.backtoLoginpage()
        },
        backtoLoginpage: function() {
            this.$router.push({ path: "/login" }); 
        },
        getnotificationlist() {
            this.isLoading =true;
            let url = 'api/coursemaster/getarticletraingnotification'
            axios({
                method: 'POST',
                url,
                data: { useremail: this.undt.username, empcode: this.undt.userid, coursetype: 'article' },
                headers: { authorization: this.tokendata }
            }).then(result => {
                this.isLoading =false;
                if(result.data.errorCode == 0) {
                    this.notificationlist = result.data.data.rows;
                } else {
                    this.notificationlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate;
            if(btnstate=="article"){
                this.$router.push({ 
                    // path: `/training/articles`,
                    name: 'TrainingArticles',
                    query: { key: Date.now() },
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
        },
        idclick(){
            if ($("#cycle_readmore").hasClass("readmore")) {
                $("#id").html("Read More");
                $("#cycle_readmore").removeClass("readmore");
            } else {
                $("#id").html("Read Less");
                $("#cycle_readmore").addClass("readmore");
            }
        },
    }
})
</script>
<style scoped>
    .feather-icon.position-relative .new-icon {
        stroke: #777777;
        width: 25px!important;
        height: 25px!important;
        display: inline-block
    }
    .feather-icon.position-relative .new-icon:hover {
        stroke: #28c76f;
    }
    .dark-layout .feather-icon.position-relative .new-icon{
      stroke: #fff;
    }
     .dark-layout .feather-icon.position-relative .new-icon:hover {
        stroke: #28c76f;
    }
    .new-icon:active {
        stroke: #91d5b0;
    }
    div.search input[type=text] {
        /* padding: 5px 10px;
        border: 1px solid #e5e5e5d6;
        float: right;
        width: 100%;
        background: #fff;
        font-size: 16px; */
        /* border-radius: 6px;
         padding-right: 30px;
         transition: all .3s ease-in; */
    }
    div.search input[type=text]:focus {
     width: 100%;
     transition: all .3s ease-in;
    }
    .sarch_afterchange_design12{
        margin: 7px auto;
        width: 40%;
    }
   
    
    div.search::after {
        content: "";
        clear: both;
        display: table;
    }
    
</style>
