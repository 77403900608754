<template>
	<div class="app-content content">
		<loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage" color="orange" loader="dots"
			:width="100" :height="100"></loading>
		<div class="content-overlay"></div>
		<div class="header-navbar-shadow"></div>
		<div class="content-wrapper">
			<section id="modern-horizontal-wizard">
				<div class="row breadcrumbs-top">
					<div class="col-12 new_dash_mar_top10">
						<h3 class="content-header-title float-left mb-1">Type Master</h3>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item sitebluecolor cursorpointercls">Add Configuration</li>
							</ol>
						</div>
					</div>
				</div>
				<div class="card border-warning filter_card_searchbgst">
					<div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchBoxCollapse">
						<div class="container-fluid" >
							<div class="row" >
								<div class="w-auto px-1 mobile-padding-0-2">
									<div class="avatar bg-light-secondary rounded d-inline-flex">
										<div class="avatar-content">
											<search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
										</div>
									</div>
								    <h4 class="card-title d-inline-flex ml-1">Search Type Master</h4>
								</div>
								<div class="ml-auto d-flex">
									<div class="px-1 text-right mobile-padding-0-2">
										<div class="input-group-append float-right">
											<button
												id="commonaddmodule"
												class="btn btn-sm btn-relief-success"
												data-toggle="modal"
												aria-haspopup="true"
												aria-expanded="false"
												data-target="statusEditPopup"
												@click="createTypeMaster($event)"
												type="button"
												data-backdrop="static"
												data-keyboard="false"
											>
												<span class="mobile_hide_bt">Create Type Master</span> <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon>
											</button>
										</div>
									</div>
									<span class="rotetions_view_arrow ml-auto mx-1"> 
										<em class="las la-angle-down"></em>
									</span>
             		            </div>
							</div>
						</div>
					</div>
					<div v-bind:class="[{show: isSearchBoxActive},card_content,collapse]" >
						<div class="card-body pt-1 px-sm-mobile-10">
							<div class="row">
								<div class="col-sm-6 col-md-6 col-lg-4">
									<label class="form-label lable-left"> Module Type</label>
									<treeselect class="projectmdllistdd capitalisetext" placeholder="Select Module Type" v-model="search.moduleType" :options="typelist" :clear-on-select="true"/>
								</div>
								<div class="col-sm-12 col-md-12 col-lg-4 text-right  text-lg-left">
									<button type="submit" class="btn btn-relief-primary mt-2 ml-1" @click="typeMasterList(search)"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
									<button type="reset" class="btn btn-outline-secondary mt-2 ml-1" @click="clearSearchFields()"><x-icon  size="1.5x" class="custom-class" ></x-icon><span> Clear</span></button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="card border-warning" id="domain-list">
					<div class="card-body p-0 ps ps--active-x">
						<div class="table-responsive" >
							<table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
								<thead>
									<tr>
										<th scope="col" class="text-center first-col-sticky" style="min-width:45px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
										<th scope="col" class="text-left ">Type Name</th>
										<th scope="col" class="text-left ">Module Type</th>
										<th scope="col" class="text-center ">IsActive</th>
										<th scope="col" class="text-center ">Action</th>
									</tr>
								</thead>
							 	<tbody>
									<template v-for="(data,index) in masterList"  >
										<tr v-bind:key="index">
											<td class="centeralign first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
											<td class="text-left capitalisetext">{{ data.tasktypename }}</td>
											<td class="text-left capitalisetext">{{ data.moduletype }}</td>
											<td class="text-center text-capitalize"  v-if="data.isactive === 1">
												<em class="las la-check-circle custom-class"  style="color: #28c76f;font-size: 24px;"></em>
											</td>
											<td class="text-center text-capitalize" v-else>
												<em class="las la-times-circle custom-class" style="color: #b82323;font-size: 24px;"></em>
											</td>
											<td class="centeralign">
												<span class=" devEditEle edit_open_box_exam" @click="editRow(data,index)">
													<em class="las la-pen custom-class" style="color: #4F46E5;font-size: 24px;cursor: pointer;"> </em>
												</span>
											</td>
										</tr>
									</template>
							 	</tbody>
							</table>
						</div>
						<div class="pageCenter text-center mt-2">
							<pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                        </div>
					</div>
				</div>

				<div class="modal modal-slide-in new-user-modal fade" id="statusEditPopup">
					<div class="modal-dialog">
						<form class="add-new-user modal-content pt-0">
							<button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color: #ffff !important" @click="clearAllPopupFields()">×</button>
							<div class="modal-header mb-1" style="background-color: #1f77b4 !important">
								<h5 class="modal-title" style="color: #ffff !important" v-if="this.editData == true"> <edit-2-icon size="1.5x" class="custom-class"></edit-2-icon> <span class="align-middle"> Update Type Master</span></h5>
								<h5 class="modal-title" style="color: #ffff !important" v-else> <span class="align-middle">Create Type Master </span></h5>
							</div>
							<div class="modal-body flex-grow-1 px-1">
								<div class="form-group">                            
									<label for="message-text" class="col-form-label">Select Module</label>
									<treeselect class="projectmdllistdd" placeholder="Select Module Type" v-model="editCell.moduleType" :options="typelist"/>
								</div>
								<div class="form-group">                            
									<label for="message-text" class="col-form-label">Type Name</label>
									<input class="form-control" placeholder="Enter Type Name" v-model="editCell.type"/>
								</div>
								<div v-if="this.editData == true" :class="{'isactive==': editCell.isactive}" class="toggle__button2">
									<label :for="'isactive-'+editCell.id" :class="{'active': editCell.isactive}" class="toggle__button2 col-form-label">Is Active
										<input type="checkbox" :id="'isactive-'+editCell.id" v-model="editCell.isactive">
										<span class="toggle__switch"></span>
										<span :class="{'off-lable-color': !editCell.isactive, 'on-label-color': editCell.isactive }"></span>
									</label>
								</div>
							</div>
							<div class="col-12 mt-1">
								<button type="submit" class="btn btn-relief-primary mr-1" style="padding-left: 1rem; padding-right:1rem" v-on:click.stop.prevent="updateData(editCell,editData)">Save</button>
								<button type="submit" class="btn btn-relief-info" style="padding-left: 1rem; padding-right:1rem;" v-on:click.stop.prevent="cancelUpdate()">Close</button>
							</div>
						</form>
					</div>
				</div>

			</section>
		</div>
	</div>
</template>

<style>
.createbtnparentdiv {
	padding-right: 0% !important;
}

.displayblk {
	display: block !important;
}

.createbtn {
	border-color: #053e52 !important;
	color: #fff !important;
	background-color: #008000 !important;
	padding: 0.386rem 0.5rem !important;
}
</style>

<script>
	import axios from 'axios'
	import apiUrl from '../../constants';
	import moment from 'moment'
	import VueElementLoading from 'vue-element-loading';
	import vSelect from 'vue-select'
	import { EditIcon, EyeIcon, Trash2Icon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon, PocketIcon,PlusCircleIcon ,CheckCircleIcon, XCircleIcon, ThumbsDownIcon, XIcon} from 'vue-feather-icons'
	import Treeselect from '@riophae/vue-treeselect'
	import '@riophae/vue-treeselect/dist/vue-treeselect.css'
	import Pagination from 'vue-pagination-2'
	import Loading from 'vue-loading-overlay';
	import 'vue-loading-overlay/dist/vue-loading.css';
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
	import CKEditor from '@ckeditor/ckeditor5-vue2';
	import commonMethods from '../../utils/commonMethods';

	export default {
		name: 'TypeModuleListiing',
		components: {
			Loading,
			VueElementLoading,
			vSelect,
			EditIcon,
			EyeIcon,
			Treeselect,
			SearchIcon,
			GridIcon,
			CodesandboxIcon,
			CloudIcon,
			ChromeIcon,
			BoxIcon,
			Link2Icon,
			PackageIcon,
			Pagination,
			MoreVerticalIcon,
			PlusIcon,
			DownloadIcon,
			Edit2Icon,
			PlusSquareIcon,
			ClipboardIcon,
			ckeditor: CKEditor.component,
			Trash2Icon,
			PocketIcon,
			PlusCircleIcon,
			CheckCircleIcon, XCircleIcon, XIcon
		},
		mixins: [ commonMethods ],
		data() {
			return {
				isLoading: false,
				fullPage: true,
				isRequestActive: true,
				card_content: 'card-content',
				collapse: 'collapse',
				isSearchBoxActive: false,
				currPageNum: 0,
				pagelimit:apiUrl.LIMIT,
				pageoffset:1,
				pageCount: 0,
				totalcount: 0,
				page:1,

				moduleCreate: {
					moduleType: null,
					type: null,
					description: null,
				},
				search: {
					moduleType: null
				},
				editCell: {
					moduleType: null,
					type: null,
					isactive: true,
					keytype: null
				},

			editData: false,
			errors: {
				moduleType: '',
				type: '',
			},
			typelist: [
				{ id: 'issue', label: 'issue' },
				{ id: 'task', label: 'task' },
				{ id: 'testcase', label: 'testcase' },
				{ id: 'incident', label: 'incident' },
				{ id: 'ticket', label: 'ticket' }
			],
			modulename: [],
			moduleid: null,
			moduleArr: [],

			masterList: []
			}
		},
		mounted() {
			// let permission = this.$Service.verifyAccess();
			// if(permission){
			var userdata = window.localStorage.getItem('userdata');
			if (userdata) {
				userdata = JSON.parse(userdata)
				this.undt = userdata
				this.loginusername = userdata.username
				this.loginuscondition = userdata.userid
				this.tokendata = window.localStorage.getItem('token');
				this.typeMasterList()
				this.typelist.sort((a, b) => a.label.localeCompare(b.label));
				// this.admintypelist =  this.admintypelist.map((file) => {
				//     return {id:file.label,label:file.label}                
				// });
			}
			// }
		},
		methods: {
			searchBoxCollapse() {
				this.isSearchBoxActive = !this.isSearchBoxActive
			},
			typeMasterList(search) {
				this.input = {
					useremail: this.undt.username,
					empcode: parseInt(this.undt.userid),
					limit: apiUrl.LIMIT,
					offset:this.pageoffset
				}
				if (search) {
					this.input.searchData = search.moduleType
				}
				axios({
					'method': 'POST',
					'url': 'api/tasks/getModuleTypeMaster',
					'data': this.input,
					'headers': { 'authorization': this.tokendata }
				}).then(result => {
					if (result.data.status == true) {
						this.masterList = result.data.data.rows
						this.totalcount = result.data.data.count
						this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
					} else {
						this.masterList = []
						this.totalcount = 0
         		this.pageCount = 0
					}
				}).catch(e => {
                  this.displayError(e)
                })
			},
			updateData(data,editData) {
				let apiUrl
				if (editData) {
					this.input = {
						useremail: this.undt.username,
						empcode: parseInt(this.undt.userid),
						tasktypemasterid : data.id,
						tasktypename: data.type,
						moduletype: data.moduleType,
						isactive: data.isactive ? 1 : 0,
						keytype : data.keytype
					}
					apiUrl = 'api/tasks/updateModuleTypeMaster'
				} else {
					this.input = {
						useremail: this.undt.username,
						empcode: parseInt(this.undt.userid),
						moduleType: data.moduleType,
						type: data.type,
						description: this.moduleCreate.description,
						keytype : this.editCell.keytype
					}
					apiUrl = 'api/tasks/saveTypeModule'
				}
				
				axios({
					method: "POST",
					url: apiUrl,
					data: this.input,
					'headers': { 'authorization': this.tokendata }
				}).then(result => {
					this.isLoading=false;
					if(result.data.errorCode == 0){
						Swal.fire({
							title: 'Success!',
							text: result.data.msg,
							icon: 'success',
							customClass: {
								confirmButton: "btn btn-primary"
							},
							buttonsStyling: !1
						})
						$(".close").click();
						this.clearAllPopupFields();
						this.typeMasterList(this.search);
					} 
					else if(result.data.errorCode == 3){
						Swal.fire({
							title: "Session Expired...!!",
							text: result.data.msg,
							icon: 'info',
							customClass: {
								confirmButton: "btn btn-primary",
							},
							buttonsStyling: !1,
						}).then(function() {
							window.location.href = "/#/login";
						})
					} else {
						Swal.fire({
							title: "Failed",
							text: result.data.msg,
							icon: 'info',
							customClass: {
								confirmButton: "btn btn-primary"
							},
							buttonsStyling: !1
						})
					}
				}).catch(e => {
                  this.displayError(e)
                })
			},
			createTypeMaster() {
				$('#statusEditPopup').modal('show');
				this.isSearchBoxActive =true;
			},
			cancelUpdate() {
				$(".close").click();
				this.editCell.moduleType = null
				this.editCell.type= null
				this.editCell.id= null
				this.editCell.isactive = false
				this.editData = false
				this.editCell.keytype= null
			},
			editRow:function(data,idx){
				this.editData = true
				$('#statusEditPopup').modal('show');
				this.editCell.moduleType = data.moduletype
				this.editCell.type = data.tasktypename
				this.editCell.id = data.tasktypemasterid
				this.editCell.isactive = data.isactive
				this.editCell.keytype = data.tasktypekey
   		    },
			clearAllPopupFields() {
				this.editCell.moduleType = null
				this.editCell.type= null
				this.editCell.id= null
				this.editCell.isactive = false
				this.editData = false
				this.editCell.keytype = null
			},
			clickCallback: function(pageNum) {
				this.pageoffset=pageNum
				this.currPageNum = pageNum - 1
				this.typeMasterList();
				setTimeout(function(){
					$('html, body').animate({
						scrollTop: $("#domain-list").offset().top -70
					});
				}, 500);
			},
			clearSearchFields(){
				this.search.moduleType = null
				this.typeMasterList()
			}
		}
	}

</script>