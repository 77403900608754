import { render, staticRenderFns } from "./dlemailcreate.vue?vue&type=template&id=69b63ff9"
import script from "./dlemailcreate.vue?vue&type=script&lang=js"
export * from "./dlemailcreate.vue?vue&type=script&lang=js"
import style0 from "./dlemailcreate.vue?vue&type=style&index=0&id=69b63ff9&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports