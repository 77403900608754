<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h3 class="content-header-title float-left mb-2">Create Bulk Mapping</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                  <router-link :to="{ name: 'TCBulkMapping', params: { search: true }}"
                  >Test Cases Group and Test Cases Bulk Mapping</router-link
                  >
                </li>
                <li class="breadcrumb-item sitebluecolor cursorpointercls">Create Bulk Mapping</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div v-bind:class="[{show: isRequestActive},card_content,collapse]" style="margin-top:10px;">
            <div class="card-body">
              <form class="form form-vertical">
                <div class="row">
                    <div class="col-md-4 mb-1">
                        <div class="form-group">
                        <label for="projectmasterdd">Group Name</label>
                        <treeselect v-model="bulkmap.groupname" :options="bulkmap.groupnameList" placeholder="Enter Min 4Chars To Search Group Name"
                        :clear-on-select="true" @input='selectGroupname' v-on:search-change="getGroupNameList"/>
                        <div class="errorinputmsg" v-if="this.errors.bulkmap.groupname">{{ this.errors.bulkmap.groupname }}</div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-1">
                      <div class="form-group">
                        <label for="applicationmasterdd">Status</label>
                        <treeselect v-model="bulkmap.status" placeholder="Select status" :options="tcscriptstatuslist" @input="selectStatus" @close="validateForm($event)" :disabled="true"/>
                        <div class="errorinputmsg" v-if="this.errors.bulkmap.status">{{ this.errors.bulkmap.status }}</div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-1">
                      <div class="form-group">
                        <label for="applicationmasterdd">Businesstask Code</label>
                        <treeselect v-model="bulkmap.bcode" placeholder="Enter Min 8 Chars To Search Businesstask Code" :options="bcodelist" @close="validateForm($event)" v-on:search-change="getbtccodelist"/>
                      </div>
                    </div>
                </div>
                 <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                  <legend class="test_casebhg_fieldset_bg"> 
                      <span class="leg_mobile">Test Case Group Mapping</span> 
                  </legend>
                <div class="row">
                   <div class="col-md-12">
                    <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-3">
                      <div class="form-group">
                        <label for="projectmasterdd">Project Name</label>
                        <treeselect v-model="project" :options="projectList" placeholder="Enter Min 4Chars To Search Project" :multiple="true" :clear-on-select="true" 
                        @input='seltestcsproject' v-on:search-change="getProjectList"/>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-3">
                      <div class="form-group">
                        <label for="projectmasterdd">Functionality Name</label>
                        <treeselect v-model="functionality" :options="functionalityList" :multiple="true"  :clear-on-select="true" placeholder="Select functionality name"
                        @input='seltestcsfunctionality'/>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-3">
                      <div class="form-group">
                        <label for="projectmasterdd">Testcase Name</label>
                        <treeselect v-model="testcasefromproject" :options="testcaseFromprojectList" :multiple="true"  :clear-on-select="true" placeholder="Select Testcase name"
                        @input='seltestc'/>
                      </div>
                    </div>
                    <div class="ccol-xs-12 col-sm-12 col-md-2 col-lg-2 text-search_right_mo767">
                        <div class="form-group">
                          <label class="w-100" >&nbsp;</label>
                          <button type="button" class="btn btn-relief-primary  tcbuilkbtns px-1" @click.prevent="getTestcaseList()"><search-icon size="1.5x" class="custom-class"></search-icon><span style="margin-left:5px;">Search</span></button>
                      </div>
                  </div>
                    </div>
                   </div>
                   <div class="col-md-12">
                    <section id="card-navigation" class="testcaselist">
                    <div class="row match-height">
                        <div class="col-md-12 px-0">
                            <div class=" mb-3 ">
                            <div class="card-body" v-if="testcaselist.length">
                                <div class="tab-content" id="myTabContent">
                                <div v-if="testcaselist.length" class="tab-pane fade" v-bind:class="{'show':testcaselist.length, 'active': testcaselist.length}" id="tasklist" role="tabpanel" aria-labelledby="tasklist-tab">
                                    <div class="row">
                                        <div class="col-md-6 canvasoverflowscrool bulikmapping_kraview">
                                          <table class="table" aria-describedby="Bulk Mapping">
                                          <thead> 
                                            <th scope="col"> Test Case Scripts</th>
                                          </thead>
                                            <div v-for="(data, index) in testcaselist" v-bind:key="index" class="form-check form-check-inline ">
                                              <tr>
                                                <td>
                                                <input type="checkbox" class="productfilter masterid ml-1"
                                                  name="productfilter" v-bind:id="'checkallusers'+data.scriptid" value=""
                                                  style=" margin: 0px 5px 0px 0px!important;" v-on:change="onfilterchange(data.scriptid, data.label, data.testcaseid ,$event)"> {{ data.label }}
                                                </td>
                                              </tr>
                                            
                                            </div>
                                          </table>
                                          </div>
                                          <div class="col-md-6 canvasoverflowscrool bulikmapping_kraview">
                                            <table class="table" aria-describedby="Bulk Mapping">
                                              <thead> 
                                                <th scope="col" colspan="2">Test Case Scripts</th>
                                              </thead>
                                                <div v-for="(data, index) in this.tcarray" v-bind:key="index">
                                                  <tr v-bind:id="'checkallusers'+data[1]" >
                                                    <td class="text-left " v-bind:id="'checkallusers'+data[1]">
                                                      <span class="f?ont-weight ">{{data[0]}}</span>
                                                    </td> 
                                                    <td class="text-center pl-0 pr-0">
                                                      <em class="las la-trash-alt weightage_delete" @click="onclickchange(data, $event)"></em>
                                                    </td>
                                                  </tr> 
                                                </div>
                                            </table>
                                          </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </section>
                   </div>
                </div>
                 </fieldset>
                                  
                <div class="row">                  
                  <div class="col-md-12 text-right">
                    <div class="form-group text-right">
                          <button type="button" class="btn btn-relief-primary mr-1"  v-on:click.stop.prevent="saveData()" id="submitbtnt13">Submit</button>
                          <button type="button" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
.tcbuilkbtns{
    margin:2px 5px;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import {CodesandboxIcon,SearchIcon,XIcon,PlusCircleIcon } from 'vue-feather-icons'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import commonMethods from '../../utils/commonMethods';
export default {
    name:'Masters',
    mixins: [ commonMethods ],
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        Treeselect,
        CodesandboxIcon,
        Pagination,
        SearchIcon,
        XIcon,
        PlusCircleIcon
    },
    data() {
        return {
            isRedirected: false,
            card_content: "card-content",
            collapse: "collapse",
            isRequestActive: true,
            isLoading: false,
            fullPage: true,
            showempdata: [],
            isProjectSel: 0,
            isProjectRoleSel: 0,
            showAddForm:false,
            projectType:[],
            bulkmap:{
                groupname:null,
                testcases:null,
                status:null,
                groupnameList: [],
                testcasenameList: [],
                bcode: null
            },
            errors: {
                bulkmap:{
                    groupname:null,
                    testcases:null,
                    status:null,
                },
            },
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            pageoffset:1,
            pagefeatures:[],
            tcscriptstatuslist:apiUrl.tcscriptstatuslist.sort((a, b) => a.label.localeCompare(b.label)),
            tokendata:null,
            project:null,
            projectList: [],
            functionality:null,
            functionalityList: [],
            testcaselist:[],
            isChecked: [],
            isSelectAllChecked: false,
            testcasemasterid: null,
            tescasename: null,
            tcarray: [],
            testcasefromproject: null,
            testcaseFromprojectList: [],
            bcodelist: []
        }
    },
    created() {
      this.pagefeatures = {}
      let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
      pagefeatures1.forEach(element => {
      })
    },
    mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
          userdata = JSON.parse(userdata)
          this.undt=userdata
          this.tokendata = window.localStorage.getItem('token');
          this.bulkmap.status = "Created"
        }
    },
    methods:{
        validText : function(inputval) {
          let re = /[A-Za-z0-9].{0,}/;
          if(inputval){
              return re.test(inputval.toLowerCase());
          }
        },
        validate : function(){
          this.valid = true
          if((this.bulkmap.groupname && this.bulkmap.testcases && this.bulkmap.status )){
            this.valid = true;
          }
          if(!this.bulkmap.groupname){
            this.errors.bulkmap.groupname="Group name is required";
          }
          return this.valid
        },
        getGroupNameList(node) {
          if(node && node.length > 3){
            this.isLoading = true
            let url = "api/groupbulkmapping/getGroupNameList";
            this.input = {
              empcode: this.undt.userid,
              useremail: this.undt.username,
              groupname: node.toLowerCase()
            };
            axios({
              method: "POST",
              url,
              data: this.input,
              'headers':{'authorization':this.tokendata}
          }).then(result => {
              this.isLoading = false
              if (result.data.errorCode == 0) {
                this.bulkmap.groupnameList = result.data.data;
              }
              else {
                this.bulkmap.groupnameList = [];
              }
            }).catch(e => {
              this.displayError(e)
            })
          }
        },
        selectGroupname(state) {
            this.bulkmap.groupname = null
            this.bulkmap.groupname = state
            if(!state || (state && state.length < 1)){
                this.errors.bulkmap.groupname = 'Group name is required'
            } else {
                this.errors.bulkmap.groupname = ''         
            }
        },
        handleCancel() {
          this.$router.push({ name: 'TCBulkMapping', params: { search: true }});
        },
        selectStatus(state) {
            if(!state) {
                this.errors.bulkmap.status = 'Task status is required'
            } else {
                this.errors.bulkmap.status = ''
            }
        },
        saveData(){  
          this.newarr = Array.from(this.tcarray, function (item) {
            return { id: item[1], name: item[0], masterid: item[2]};
          })
          this.valid = this.validateForm();
          if(this.valid && (this.newarr).length<=0){
            Swal.fire({
              title: "Failed!",
              text:"Select scripts for test case group mapping",
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          }
          if (this.valid && (this.newarr).length>0) {
              this.input = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                tcarray: this.newarr
              }
              let apiURL='api/groupbulkmapping/BulkmappingCreate'
              if(this.bulkmap.groupname){
                this.input.groupname=this.bulkmap.groupname
              }
              if(this.bulkmap.status){
                this.input.status=this.bulkmap.status
              }
              if(this.bulkmap.bcode && this.bulkmap.bcode !=null){
                this.input.bcode = this.bulkmap.bcode
              }
              this.isAddState=false
              axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
              })
              .then(result => {             
                  if(result.data.errorCode == 0){
                    Swal.fire({
                      title: "Success!",
                      text: result.data.msg,
                      icon: 'success',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                    })
                    this.$router.push({ name: 'TCBulkMapping', params: { search: true }})
                  }
                  else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                  }else if(result.data.errorCode == 4){
                    Swal.fire({
                      title: "Failed!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                    })
                  }
              }).catch(e => {
    this.displayError(e)
    })
          }
        },
        validateForm(){
          this.clearerrorForm();
          this.isAddState=false
          var isValid=true;
          if(!this.bulkmap.groupname){
            this.errors.bulkmap.groupname="Group name is required";
            isValid= false;
          }
          if(!this.bulkmap.status){
            this.errors.bulkmap.status="Status is required";
            isValid= false;
          }
          return isValid
        },
        clearerrorForm(){
          this.errors.bulkmap.groupname = null
          this.errors.bulkmap.testcases = null
          this.errors.bulkmap.status = null
        },
        getProjectList(node) {
          if(node && node.length > 3){
            this.isLoading = true
            let url = "api/listing/getProjectAllocatedToEmp";
            this.input = {
              empid: parseInt(this.undt.userid),
              useremail: this.undt.username,
              empcode: this.undt.userid,
              projectname: node.toLowerCase(),
              isfrompage: 'createpage'
            };
            axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
            }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                this.projectList = result.data.data;
              } else {
                this.projectList = [];
              }
            }).catch(e => {
              this.displayError(e)
            })
          }
        },
        seltestcsproject(state,value){
            this.functionality=null
            this.project=state
            if(!state){
                this.functionalityList = []
            }else{
                this.getApplicationdata('t37','testcase')
                this.getTestCasesforSearch()
            }
        },
        seltestcsfunctionality(state,value){
            this.functionality=null
            this.functionality=state
            if(!state){
              this.testcaseFromprojectList = []
            }else{
              this.getTestCasesforSearch()
            }
        },
        seltestc(state){
          this.testcasefromproject=null
          this.testcasefromproject=state
        },
        getApplicationdata(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT
            }
            let apiURL='api/master/commonmaster/list'
            if(mdl == 't37' && curracttab == 'testcase'){                
                this.inputappl = {
                    useremail: this.undt.username,
                };
                apiURL = "api/listing/getfunctionalitybyprojectid";
                this.inputappl.projectidarr = this.project
            }
            this.inputappl.empcode = this.undt.userid 
            this.inputappl.useremail= this.undt.username
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                if(result.data.errorCode == 0){
                    if(mdl == 't37' && curracttab == 'testcase'){
                        if(curracttab == 'testcase'){
                            this.functionalityList=result.data.data
                            return true
                        }
                    }
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
            }).catch(e => {
    this.displayError(e)
    })
        },
        getSearchItems(search) {
            this.projectList = JSON.parse(localStorage.getItem('projectList'))
            this.project = project
            this.functionality = functionality
        },
        getTestcaseList() {
          this.isSelectAllChecked = false
          if(this.bulkmap.groupname && this.bulkmap.groupname!=null){
            this.isLoading = true
            this.input = {
              useremail: this.undt.username,
              empcode: this.undt.userid,
              projectname : this.project,
              functionlityname : this.functionality,
              testcaseid: this.testcasefromproject ,
              groupmasterid:this.bulkmap.groupname
            };
            let url = 'api/groupbulkmapping/gettestcaselist' 
            axios({
                method: 'POST',
                url,
                data: this.input,
                headers:{ authorization :this.tokendata }
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                  this.testcaselist = result.data.data.rows
                  this.totalcount = result.data.data.count
                  this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }
            }).catch(e => {
    this.displayError(e)
    })
          }else{
            this.errors.bulkmap.groupname="Group name is required";
          }
        },
        clearSearchFields(){
          this.project = null
          this.functionality = null
        },
        selectAll() {
          if(!this.isSelectAllChecked) {
            this.isChecked = Array(this.totalcount).fill(true)
          } else {
            this.isChecked = Array(this.totalcount).fill(false)
          }
        },
        alterSelectAllCheck(index) {
          if(this.isChecked[index]) {
            this.isSelectAllChecked = false
          } else {
            this.isSelectAllChecked = this.isChecked.every((i, idx) => {
              if(idx !== index) {
                return i === true
              } else {
                return true
              }
            })
          }
      },

      onfilterchange(scriptid, label, testcaseid, event){
        if (event.target.checked == true) {
          this.mytestcaseid = testcaseid;
          this.mylabel = label;
          this.tcarray.push([this.mylabel, scriptid, testcaseid])
        }else{
          this.mytestcaseid = null;
          this.mylabel = null;
          let id  = "checkallusers"+scriptid;     
          const objWithIdIndex = this.tcarray.findIndex((obj) => obj[1] == scriptid);
          if (objWithIdIndex > -1) {
            this.tcarray.splice(objWithIdIndex, 1);

            $("#"+id).prop("checked", false);
          }
        }
      },

      onclickchange(data,event){
        let id  = "checkallusers"+data[1];     
        const objWithIdIndex = this.tcarray.findIndex((obj) => obj[1] === data[1]);
        if (objWithIdIndex > -1) {
          this.tcarray.splice(objWithIdIndex, 1);

          $("#"+id).prop("checked", false);
        }
      },
      getTestCasesforSearch() {
        axios({
            method: "POST",
            url: "api/groupbulkmapping/getTestCasesforSearch",
            data: { 
              projectid: this.project,
              functionalityid: this.functionality,
              empcode: this.undt.userid,
              useremail: this.undt.username
            },
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
            this.testcaseFromprojectList = result.data.data.rows
            } else {
            this.testcaseFromprojectList = []
            }
        }).catch(e => {
    this.displayError(e)
    })
      },
      getbtccodelist(node){
        if (node.length>7) {
          this.input={
            useremail  : this.undt.username,
            empid: this.undt.userid,
            empcode: this.undt.userid,
            taskid: node.toUpperCase(),
          }
          axios({
            'method': 'POST',
            'url': 'api/testcycle/getsearchbtccodelist',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {
            if(result.data.errorCode == 0){
              this.bcodelist = result.data.data.rows;
            } else {
              this.bcodelist = [];
            }
          }).catch(e => {
    this.displayError(e)
    })
        }
      },
    }
}
</script>