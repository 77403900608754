<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-1">Course Module Details</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li v-if="pagefeatures.trainniglist" class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/training/list'>Course Module List</router-link></li>
                                <li v-else class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/user/training/list'>Course Module List</router-link></li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">View Course Module Master</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div>
                        <div class="card-header py-2" style="cursor:pointer">
                            <div class="container-fluid btmView">
                            <div class="row">
                                <div class="width:auto px-sm-1 mobile-px-0">
                                    <div class="avatar bg-light-primary rounded">
                                        <div class="avatar-content">
                                            <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                                        </div>
                                    </div>
                                    <h3 class="card-title ml-2 d-inline-flex text-primary">COURSE CODE: {{this.course.coursecode}}</h3>
                                </div>
                                <div class="ml-auto text-right" v-if="course.ispublished && loginusername === course.publishedby && course.coursetype != 'article'">
                                    <button v-if="!this.course.iscompleted" type="button" class="btn btn-sm btn-relief-primary waves-effect waves-effect mr-2" @click="sendCourseCompletionReminder()" v-bind:disabled="iscoursemailremindersenttoday"><mail-icon size="1.5x" class="custom-class avatar-icon mr-50"></mail-icon><span>Course Mail Reminder</span></button>
                                </div>
                                <div class="text-right" v-if="this.course.iscompleted && course.coursetype != 'article'" v-bind:class="{'ml-auto': !(course.ispublished && loginusername === course.publishedby)}">
                                    <div class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-2 cat history">
                                        <check-icon size="1.5x" class="custom-class avatar-icon mr-50"></check-icon>
                                        <span>Completed</span>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                       <div class="row match-height">
                            <div class="col-12">
                                <div class=" card-transaction">
                                    <div class="card-body mobile-padding-15-10">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <pen-tool-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pen-tool-icon>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title capitalisetext">{{this.course.coursename}}</h6>
                                                <small>Created by {{this.course.createdby}}</small>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12 ">
                                <div class="card card-transaction">
                                    <div class="card-body mobile-padding-15-10">
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-success rounded">
                                                    <div class="avatar-content">
                                                        <monitor-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></monitor-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Course name</h6>
                                                    <small>{{this.course.coursename}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <pocket-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pocket-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">No.of Trainings</h6>
                                                    <small>{{this.course.nooftraining}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-info rounded">
                                                <div class="avatar-content">
                                                    <calendar-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></calendar-icon>
                                                </div>
                                                </div>
                                                <div class="media-body">
                                                <h6 class="transaction-title">Start Date</h6>
                                                <small>{{this.course.startdate ? dateFormat2(this.course.startdate) : 'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-info rounded">
                                                <div class="avatar-content">
                                                    <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                                </div>
                                                </div>
                                                <div class="media-body">
                                                <h6 class="transaction-title">End Date</h6>
                                                <small>{{this.course.enddate ? dateFormat2(this.course.enddate) : 'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>                                                          
                                    
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                               <div class="card card-transaction">
                                <div class="card-body mobile-padding-15-10">
                                    <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-warning rounded">
                                            <div class="avatar-content">
                                                <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                                            </div>
                                            </div>
                                            <div class="media-body">
                                            <h6 class="transaction-title">Created By</h6>
                                            <small>{{this.course.createdby}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-warning rounded">
                                            <div class="avatar-content">
                                                <calendar-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></calendar-icon>
                                            </div>
                                            </div>
                                            <div class="media-body">
                                            <h6 class="transaction-title">Created Date</h6>
                                            <small>{{dateMonthFormat(this.course.createddate)}}</small>
                                            </div>
                                        </div>
                                    </div>
                                   <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-warning rounded">
                                            <div class="avatar-content">
                                                <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                                            </div>
                                            </div>
                                            <div class="media-body">
                                            <h6 class="transaction-title">Last Modified By</h6>
                                            <small>{{this.course.lastmodifiedby}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-warning rounded">
                                            <div class="avatar-content">
                                                <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                            </div>
                                            </div>
                                            <div class="media-body">
                                            <h6 class="transaction-title">Last Modified Date</h6>
                                            <small>{{dateMonthFormat(this.course.lastmodifieddate)}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="transaction-item" v-if="course.periodictype && course.periodictype != null">
                                        <div class="media">
                                            <div class="avatar bg-light-info rounded">
                                            <div class="avatar-content">
                                                <watch-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></watch-icon>
                                            </div>
                                            </div>
                                            <div class="media-body">
                                            <h6 class="transaction-title">Periodic Type</h6>
                                            <small>{{capitalletterfun(course.periodictype)}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12" v-if="(courseTestList.length>0)">
                                
                                <div class="card-body new_exam-header-padding-y6 " v-for="(row, index) in courseTestList" v-bind:key="index">
                                   <div class="card card-transaction card shadow-none border cursor-pointer p-1 my-0 new_exam-header-padding-y6" v-if="(parseInt(row.count)>=row.totalquestions)">
                                    <div class="transaction-item">
                                        <div class="media media_text-head_title">
                                            <div class="media-body">
                                                <h6 class="transaction-title">{{row.assessmenttitle}}</h6>
                                            </div>

                                            <div class="exam_start_view_Design" v-if="(parseInt(row.count)>=row.totalquestions && row.result == '')">
                                                <button class="btn btn-relief-primary mr-1 animated-button1">
                                                    <div @click="redirecttourl('starttest',row)" href="javascript:void(0);"> 
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    Start Test</div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="transaction-item mb-0" v-if="(row.result!='')">
                                        <div class="media">
                                            <div class="avatar bg-light-info rounded">
                                              <div class="avatar-content">
                                                <file-text-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></file-text-icon>
                                              </div>
                                            </div>
                                            <div class="media-body">
                                            <h6 class="transaction-title"> Result Status</h6>
                                            <small>{{row.result[0].results.toUpperCase()}}</small>
                                            </div>
                                        </div>   
                                    </div>
                                    <div class="card-body py-1 px-0" v-if="(row.result!='')">
                                        <div class="d-flex justify-content-between mb-1">
                                            <span class="text-truncate"> Total Marks</span>
                                            <small class="text-muted">{{row.result[0].totalcorrectans}}</small>
                                        </div>
                                        <div class="d-flex justify-content-between mb-1">
                                            <span class="text-truncate"> Total attempted</span>
                                            <small class="text-muted">{{(row.result[0].totalcorrectans+row.result[0].totalfailedans)}}</small>
                                        </div>
                                        <div class="d-flex justify-content-between mb-1">
                                            <span class="text-truncate"> Questions</span>
                                            <small class="text-muted">{{row.result[0].totalquestions}}</small>
                                        </div>

                                        <div v-if="(row.result!='' && row.result[0].results == 'fail')">
                                            <div class="exam_start_view_Design">
                                            <button class="btn btn-relief-primary mr-1 animated-button1">
                                                <div @click="redirecttourl('starttest',row)" href="javascript:void(0);"> 
                                                  <span></span>
                                                  <span></span>
                                                  <span></span>
                                                  <span></span>
                                                Re-Test</div>
                                            </button>
                                            </div>
                                        </div>
                                    </div>  
                                   </div>
                                </div>

                            </div>
                            <div class="col-12">
                                <div class="card-transaction">
                                    <div class="card-body mobile-padding-15-10">
                                        <div class="transaction-item" style="overflow:auto">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <monitor-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></monitor-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Course Description</h6>
                                                    <!-- <span v-html="formatData(this.course.coursedetails)"></span> -->
                                                    <div class="text-left tast_case_groupingview_readmore mx-0">
                                                    <div class="col-md-12 px-0  micro_case_groupingview_readmore mb-1">
                                                        <div class="custom-container case_bg_17">
                                                            <div class="outer" v-if="this.course.coursedetails" :class="{'readmore': readmore}" id="cycle_readmore5" >
                                                                <span class="fc_m16_btcode_text" v-html="formatData(this.course.coursedetails)"></span>
                                                                
                                                            </div>
                                                            
                                                            <div class="cycle_viewmoretext" v-if="this.course.coursedetails && this.course.coursedetails.length > 50">
                                                                <a class="btn">
                                                                <strong @click="toggleReadMore">
                                                                    {{ readmore ? 'Read Less' : 'Read More' }}
                                                               </strong>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">              
                        <div class="modal-body p-0">
                            <button type="button" class="close imageModalClose" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                            <img alt="" src="" class="imagepreview"  >
                        </div>
                        </div>
                    </div>
                </div>

                 <div class="modal fade" id="videomodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                        <div class="modal-content video__container_mobile_view_centers">              
                            <div class="modal-body p-0 ">
                                <div class="video__container" v-if="videoIsOpen">
                                    <button type="button" class="imageModalClose modal-video-close-btn" data-dismiss="modal" @click="stopVideoPlay"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                                    <video controls controlsList="nodownload" id="myVideoPlayer" :src="videoURL" width="100%" preload="metadata">
                                        <source :src="videoURL+'#t=0.0'" type="video/mp4">
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <div class="modal fade px-2" id="technologypopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-sm-min-width" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                    <check-circle-icon size="2.5x" class="font-medium-3 mr-1"></check-circle-icon>
                    <h4 class="modal-title">View Submitted Answers Details </h4>

                    
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                    
                    <section id="card-navigation modern-horizontal-wizard">
                        <div class="card border-warning-0 shadow-none" >
                        <div>
                            <div class="card-body p-0">
                            <div class="row match-height">
                            <div class="col-lg-4 col-md-6 col-12 mb-1">
                                <div class="transaction-item p-1 rounded business_dis_rows b_bg-dis_row1">
                                    <div class="media">
                                        <div class="avatar bg-light-danger rounded">
                                        <div class="avatar-content">
                                            <airplay-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></airplay-icon>

                                        </div>
                                        </div>
                                        <div class="media-body">
                                        <h6 class="transaction-title" style="margin-left: 7px">Course Name</h6>
                                        <small class ="font-weight-bold" style="margin-left: 9px; color:green;">{{course.coursename}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12 mb-1">
                                <div class="transaction-item p-1 rounded business_dis_rows b_bg-dis_row2">
                                    <div class="media">
                                        <div class="avatar bg-light-primary rounded">
                                        <div class="avatar-content">
                                            <paperclip-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></paperclip-icon>

                                        </div>
                                        </div>
                                        <div class="media-body">
                                        <h6 class="transaction-title" style="margin-left: 7px">Assessment Title</h6>
                                        <small class ="font-weight-bold" style="margin-left: 9px;color:green;">{{currAssTitle}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12 mb-1">
                                <div class="transaction-item p-1 rounded business_dis_rows b_bg-dis_row3">
                                    <div class="media">
                                        <div class="avatar bg-light-info rounded">
                                        <div class="avatar-content">
                                            <award-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></award-icon>
                                        </div>
                                        </div>
                                        <div class="media-body">
                                        <h6 class="transaction-title" style="margin-left: 7px">Obtained Marks</h6>
                                        <small class ="font-weight-bold" style="margin-left: 9px;color:green;">{{ currObMarks }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>              
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    <form class="form form-vertical">
                        <div class="row">
                        <div class="col-md-12">
                            <div class="card text-center mb-3">
                            <div class="">
                                <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">                                
                                    <div class="row" id="table-small">
                                        <div class="col-12">
                                            <div class="card">
                                                <div class="">
                                                    <div class="row">
                                                    <div class="col-md-10">
                                                        <div class="input-group-append"></div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table table-sm dataTableListing table-hover table-bordered" aria-describedby="mydesc">
                                                        
                                                        <thead>                     
                                                        <tr>
                                                        <th scope="col" class="srno align-middle" style="width: 40px;">Sr#</th>
                                                        <th scope="col" class="align-middle" style="width: 200px;">Question Title</th>
                                                        <th scope="col" class="align-middle">Option A</th>
                                                        <th scope="col" class="align-middle">Option B</th>
                                                        <th scope="col" class="align-middle">Option C</th>
                                                        <th scope="col" class="align-middle">Option D</th>
                                                        <th scope="col" class="align-middle" style="width: 90px;">Given Answer</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <template  v-for="(data, index) in ansList">
                                                        <tr v-bind:key="index+Math.floor(Math.random() * 1000000000)">
                                                            <td class="srno">{{ index+1+currPageNum*pagelimit }}</td>
                                                            <td>
                                                            <span class="font-weight-bold capitalisetext" >{{data.questiontitle}}</span>
                                                            </td>
                                                            <td>{{ data.optiona }}</td>
                                                            <td>{{ data.optionb }}</td>
                                                            <td>{{ data.optionc }}</td>
                                                            <td>{{ data.optiond }}</td>                                                                               
                                                            <td class="text-nowrap text-center ">{{data.answergiven ? data.answergiven: '-'}}</td>
                                                            
                                                        </tr>
                                                        </template>
                                                    </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </div> 

            <div v-if="(examlist.length != 0 && course.periodictype && course.periodictype != null)" class="tab-content mb-2 d-inline-block w-100" >
                <div role="tabpanel" class="tab-pane active" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
                    <div class="collapse-margin collapse-icon mt-1" id="faq-approvaldetails">
                        <div class="card card resolved rounded">
                            <div class="card-header" id="approvaldetailsrn" data-toggle="collapse" role="button" data-target="#approval-details" aria-expanded="true" aria-controls="approval-details">
                                <span class="lead collapse-title">Periodic Course Details</span>
                            </div>

                            <div id="approval-details" class="collapse show" aria-labelledby="approvaldetailsrn" data-parent="#faq-approvaldetails">
                                <div class="card-body">
                                    <div class="mb-1 px-0" >
                                        <div class="table-responsive">
                                            <table class="
                                                table table-sm
                                                dataTableListing
                                                table-hover table-striped
                                            " aria-describedby="mydesc"
                                            >
                                            <thead>
                                                <tr>
                                                <th scope="col" class="srno align-middle">Sr#</th>
                                                <th scope="col" class="align-middle text-left">Assignment Title</th>
                                                <th scope="col" class="align-middle text-left">Periodic Type</th>
                                                <th scope="col" class="align-middle text-left">Periodic Name</th>
                                                <th scope="col" class="align-middle">Course Status</th>
                                                <th scope="col" class="align-middle">Exam Attempted</th>
                                                <th scope="col" class="align-middle">Result</th>
                                                <th scope="col" class="align-middle">Marks</th>
                                                <th scope="col" class="align-middle">View Submitted Answers</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template  v-for="(dataas, i) in examlist">
                                                <tr  v-for="(data, index) in dataas" v-bind:key="index+Math.floor(Math.random() * 1000000000)">
                                                    <td class="srno">{{ index+1+currPageNum*pagelimit }}</td>
                                                    
                                                        <td class="text-left">
                                                            <span v-if="data.assessmenttitle" class="font-weight-normal capitalisetext">{{data.assessmenttitle}}</span>
                                                            <span v-else class="font-weight-normal capitalisetext">NA</span>
                                                        </td>

                                                        <td class="text-left">
                                                            <span v-if="data.periodictype" class="font-weight-normal capitalisetext">{{data.periodictype}}</span>
                                                        </td>

                                                        <td class="text-left">
                                                            <span v-if="data.periodicname" class="font-weight-normal capitalisetext">{{data.periodicname}} </span>
                                                        </td>

                                                        <td class="text-center">
                                                            <div v-if="data.iscompleted==true" class="badge badge-pill badge-light-success mr-50"> Completed</div>
                                                            <div v-if="data.iscompleted==false" class="badge badge-pill badge-light-warning mr-50"> Incomplete</div>
                                                        </td>

                                                        <td class="text-nowrap text-center">
                                                            <div v-if="data.results" class="badge badge-pill badge-light-success mr-50">Yes</div>
                                                            <div v-if="!data.results" class="badge badge-pill badge-light-warning mr-50">No</div>
                                                        </td>

                                                        <td class="text-center">
                                                            <div v-if="data.results=='pass'" class="capitalisetext  badge badge-pill badge-light-success mr-50"> Pass</div>
                                                            <div v-else-if="data.results=='fail'" class="capitalisetext  badge badge-pill badge-light-warning mr-50"> Failed</div>
                                                            <div v-else-if="data.results=='not attempted'" class="capitalisetext  badge badge-pill badge-light-danger mr-50"> Not Attempted</div>
                                                            <div v-else class="capitalisetext  badge badge-pill badge-light-warning mr-50"> NA</div>
                                                        </td>

                                                        <td v-if="data.totalcorrectans != null" class="text-nowrap text-center">{{data.totalcorrectans}}</td>
                                                        <td v-else class="text-nowrap text-center">NA</td>

                                                        <td v-if="data.courseassessmentmasterid && data.courseassessmentmasterid != null && data.results !='not attempted'">
                                                            <div class="d-flex align-items-center">
                                                                <button type="button" clsss="btn btn-primary mr-1 mt-2" style="background: transparent;;border-color: #4839EB;color: #7367f0 !important;box-shadow: none;border: none;margin:0px auto;">
                                                                    <eye-icon size="1.5x" class="custom-class" v-on:click="getSubmittedAnsList(data)" ></eye-icon>
                                                                </button>                                                                                        
                                                            </div>
                                                        </td>
                                                        <td v-else class="text-nowrap text-center">NA</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="10"></td>
                                                </tr>
                                                </template>
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="customTimeline col-sm-12 col-md-12 col-lg-12" v-for="(data,index) in traininghistory" v-bind:key="index">
                <div class="card">
                    <div class="card-header px-2 py-1 border-bottom custom_traning_view_bg_card_head">
                        <h4 class="card-title">Course Name: <span class="text-primary" v-html="formatData(data.trainingname)"></span></h4>
                    </div>
                    <div class="card-body pt-1 pb-0">
                    <div class="media-body">
                        <h6 class="transaction-title">Course Description: </h6>
                        <div class="text-left tast_case_groupingview_readmore mx-0">
                            <div class="col-md-12 px-0  micro_case_groupingview_readmore mb-1">
                                <div class="custom-container case_bg_17">
                                    <div class="outer" v-if="data.trdetails" v-bind:class="[readmore]" :id="'cycle_readmore6'+index">
                                        <span class="fc_m16_btcode_text" v-html="formatData(data.trdetails)"></span>
                                        
                                    </div>
                                    
                                    <div class="cycle_viewmoretext" v-if="data.trdetails && data.trdetails.length > 50">
                                        <a class="btn">
                                        <strong :id="'id6'+index" @click="idclick6(index)">
                                Read More
                            </strong>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>

                    <div v-if="trainingArr[index].documentlist && trainingArr[index].documentlist.length > 0" class="row documentGrid match-height custom_grid_watch_bt">
                              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 mb-1" v-for="(res,i) in trainingArr[index].documentlist" v-bind:key="i+'doc'">
                                <div v-if="res.link!=null" class="card card-bordered mb-0"> 
                                    <div class="text-center py-2" style="background:#F8F8F8;">
                                        <a v-if="res.link && res.link.substring(res.link.lastIndexOf('.')+1) == 'pdf'" target="_blank" v-on:click="previewpdf(res.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop" title="Click to Preview"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                                        <a v-on:click="getLogtimeDownloadLink(res.link, 'download-doc', res.id, res.trainingid, res.isdownloaded, index, i)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                        <a v-if="imgExt.includes(res.link && res.link.substring(res.link.lastIndexOf('.')+1).toLowerCase()) " target="_blank" v-on:click="onZoom(res.link, 'zoom', res.id, res.trainingid, res.iswatched, index, i)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                                        <a v-if="vidExt.includes(res.link && res.link.substring(res.link.lastIndexOf('.')+1).toLowerCase())" v-on:click="vlc(res.link, 'vidzoom', res.id, res.trainingid, res.iswatched, index, i)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop">  <play-circle-icon size="1.5x" class="custom-class"></play-circle-icon></a>
                                        <div v-if="trainingArr[index].documentlist[i].isdownloaded" class="success btn-br">&#10003;</div>
                                        <a v-if="trainingArr[index].documentlist[i].iswatched" class="success btn-watched btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect"><eye-icon  size="1.5x" class="custom-class avatar-icon"></eye-icon></a>
                                        <div class="traning_image_listing_st">
                                        <img alt="" v-if="imgExt.includes(res.link && res.link.substring(res.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />                               
                                        <img alt="" v-else-if="res.link && res.link.substring(res.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                                                    
                                        <img alt="" v-else-if="res.link && res.link.substring(res.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                                        <img alt="" v-else-if="res.link && res.link.substring(res.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                                        <img alt="" v-else-if="vidExt.includes(res.link && res.link.substring(res.link.lastIndexOf('.')+1).toLowerCase())" src="../../../public/images/icons/ic-video.png" height="35"  />
                                        <img alt="" v-else-if="res.link && res.link.substring(res.link.lastIndexOf('.')+1) == 'xls' || 'xlxs'" src="../../../public/images/icons/ic-xls.png" height="35" />
                                      </div>
                                    </div>
                                        <p class="py-50 px-50 border-top " style="font-size:0.85rem; color:#888;">
                                            <span v-if="res.info">
                                                {{res.info}}
                                            </span>
                                            <span v-else-if="!res.info">
                                                {{res.link | basename}}
                                            </span>
                                        </p>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        </div>           
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { AwardIcon,AirplayIcon,CheckCircleIcon,MonitorIcon,PlusIcon,PlusSquareIcon,MinusSquareIcon,DownloadIcon,EditIcon,SearchIcon,MoreVerticalIcon,Edit2Icon,PackageIcon,XIcon, ZoomInIcon,
ClipboardIcon,EyeIcon,UploadCloudIcon,CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,CodesandboxIcon,PenToolIcon, PaperclipIcon, WatchIcon,FileTextIcon, CheckIcon, PlayCircleIcon, MailIcon } from 'vue-feather-icons'
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import presignurl from '../getPresignurl.vue';
import profilepic from '../profilepic.vue';
import commonMethods from '../../utils/commonMethods';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    name:'BTMView',
    components:{
        VueTimepicker,
        Loading,PlusSquareIcon,DownloadIcon,EyeIcon,UploadCloudIcon,CodesandboxIcon,PenToolIcon ,PackageIcon,
        VueElementLoading,MoreVerticalIcon,Edit2Icon,MinusSquareIcon,ClipboardIcon,
        vSelect,PlusIcon,CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,MonitorIcon,CheckIcon,XIcon,ZoomInIcon,MailIcon,
        EditIcon,
        Treeselect,
        DatePicker,
        SearchIcon,
        Pagination,
        PaperclipIcon, WatchIcon,
        ckeditor: CKEditor.component,presignurl,
        FileTextIcon,
        profilepic,
        PlayCircleIcon,
        CheckCircleIcon,
        AwardIcon,
        AirplayIcon
    },
    mixins: [ commonMethods ],
    data() {
        return {
            downloadcheck: false,
            videoURL: '',
            videoIsOpen: false,
            actionlable: 'Update to completed',
            readmore: false,
            readmore1:'outer',
            training:{
                courselist:[],
                courseid:null,
                trainingname:null,
                createdby:null,
                trdetails:null
            },
            courseTestList: [],
            course:{
                courseid: null,
                coursename: null,
                filedescp:[],
                courseduration: null,
                attachmentnew:null,
                coursedetails: null,
                nooftraining: null,
                disblesavebtn:false,
                iseditstate:false,
                createdby:null,
                coursecode:null,
                description:null,
                createddate:null,
                lastmodifiedby:null,
                lastmodifieddate:null,
                trainingname:null,
                ischecked: false,
                iscompleted: null,
                startdate: null,
                enddate: null,
                dessubslist: [],
                coursetype: null,
                isdeptsubscribed: null,
                ispublished: null,
                publishedby: null,
                periodictype:null,
                periodicid:null,
            },
            trainingArr: [
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
                {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, documentlist: []},
            ],
            // courseduration:null,
            componentKey: 0,
            isSearchRequestActive:false,
            istrue:false,
            show:'display',
            isdisabled:false,
            isActive:true,
            isLoading: false,
            fullPage: true,
            showAddForm:false,
            selected: null,
            glbMdl:'',
            glbst:'',
            TimeFormat: apiUrl.TimeFormat,
            glbMdlLbl:'company',
            valid : false, 
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            traininghistory: [],
            imgExt:['jpeg','jpg','png'],
            vidExt: ['mov', 'movie', 'mp4', 'wmv', 'avi', 'mkv'],
            tokendata: null,
            iscoursemailremindersenttoday: false,
            isassessmentmailremindersenttoday: [],
            trdocs: '',
            trainingArrLength:null,
            examlist: [],
            ansList: [],
            currAssTitle: null,
            currObMarks: null,
        }
    },  
    created() {
        this.pagefeatures = {};
        if(window.localStorage.getItem("pagefeatures")){
        var pagefeatures1 = JSON.parse(
            window.atob(window.localStorage.getItem("pagefeatures"))
        );
            pagefeatures1.forEach((element) => {
                if (element.page === "/training/list") {
                    this.pagefeatures[element.featurename] = element.featureaccess;
                }
            });
        }
    },
    mounted(){
        var userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.loginusername=userdata.username
            this.loginuscondition = userdata.userid
            this.empid = userdata.userid
            this.pagefeatures = {}
            var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
                if (element.page === '/training/list') {
                    this.pagefeatures[element.featurename] = element.featureaccess
                }
            })
            this.tokendata = window.localStorage.getItem('token');
            this.getCoursedetailsbyID(this.$route.query.courseid)
        }
    },
    methods:{
        toggleReadMore() {
        this.readmore = !this.readmore;
    },
    saveIscomplet(courseid){
        this.isLoading = true;
        this.input = {
            lastmodifiedby: this.undt.username,
            useremail: this.undt.username,
            empcode: this.undt.userid,
        }
        let apiURL='api/coursemaster/saveIscomplete'
        this.input.courseid=courseid
        this.input.iscompleted=true
        this.input.periodicid=this.course.periodicid
        axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {     
            this.isLoading = false;   
        }).catch(e => {
          this.displayError(e)
        })
    },
    isMailReminderSentToday() {
        // this.isLoading = true;
        let url = 'api/coursemaster/mailer/isCourseMailReminderSentToday';
        this.input = {
            courseid: this.course.courseid,
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
        };
        axios({
            method: 'POST', 
            url,
            data: this.input,
            'headers':{ 'authorization' :this.tokendata }
        }).then(result => {
            // this.isLoading = false;
            if(result.data.errorCode == 0) {
                this.iscoursemailremindersenttoday = true
            }else{
                this.iscoursemailremindersenttoday = false
            }
        }).catch(e => {
          this.displayError(e)
        })
    },
    capitalletterfun(value) {
      if (!value) return "NA";
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      return arr.join(" ");
    },
    isAssessmentMailReminderSentToday(assessmentid=null) {
        // this.isLoading = true;
        if(!assessmentid || (assessmentid && !assessmentid.length)) {
            return
        }
        let url = 'api/coursemaster/mailer/isAssessmentMailReminderSentToday';
        this.input = {
            courseid: this.course.courseid,
            empid: parseInt(this.undt.userid),
            assessmentid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
        };
        axios({
            method: 'POST',
            url,
            data: this.input,
            'headers':{ 'authorization' :this.tokendata }
        }).then(result => {
            // this.isLoading = false;
            if(result.data.errorCode == 0) {
                this.isassessmentmailremindersenttoday = result.data.data
            }else{
                this.isassessmentmailremindersenttoday = []
            }
        }).catch(e => {
          this.displayError(e)
        })
    },
    idclick5(){
            
            if ($("#cycle_readmore5").hasClass("readmore")) {
                $("#id5").html("Read More");
                $("#cycle_readmore5").removeClass("readmore");
                
            } else {
                $("#id5").html("Read Less");
                $("#cycle_readmore5").addClass("readmore");
            }
        },
        
        idclick6(indexx){
            
            if ($("#cycle_readmore6"+indexx).hasClass("readmore")) {
                $("#id6"+indexx).html("Read More");
                $("#cycle_readmore6"+indexx).removeClass("readmore");
                this.readmore1 = 'outer'
            } else {
                $("#id6"+indexx).html("Read Less");
                $("#cycle_readmore6"+indexx).addClass("readmore");
                this.readmore1 = 'outer readmore'
            }
        },
    sendCourseCompletionReminder() {
        this.isLoading = true;
        let url = 'api/coursemaster/mailer/sendCourseCompletionReminders';
        this.input = {
            courseid: this.course.courseid,
            sender: this.undt.username,
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
        };
        axios({
            method: 'POST',
            url,
            data: this.input,
            'headers':{ 'authorization' :this.tokendata }
        }).then(result => {
            this.isMailReminderSentToday()
            this.isLoading = false;
            if(result.data.errorCode == 0) {
                Swal.fire({
                    title: "Success!",
                    text: result.data.msg,
                    icon: 'success',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }else{
                Swal.fire({
                    title: "",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
        }).catch(e => {
          this.displayError(e)
        })
    },
    redirecttourl:function(btnstate,rowdata){
        if(btnstate=="starttest"){
            localStorage.removeItem('assessmentTime');
            localStorage.removeItem('target_date');
            let filterRowdata = {
                'courseid': rowdata.courseid,
                'testid': rowdata.courseassessmentmasterid,
                'resultid': (rowdata.result[0])?rowdata.result[0].id: null,
                'periodicid': (this.course.periodicid && this.course.periodicid != null) ? this.course.periodicid : null,
            }
            window.localStorage.setItem('localcourse',this.$route.query.courseid)
            let rowws = this.$router.push({ 
              path: '/assessment/assessmentstart?testid='+rowdata.courseassessmentmasterid,
              params:rowdata,
              query: filterRowdata,
            })
         // window.open(rowws.href, '_blank');
        }     
    },
    getCourseTestsList(courseid) {
        {{this.course.periodicid}}
      this.isLoading = true;
      let url = 'api/assessment/getCourseTestsList';
      this.input = {
            cid:courseid,
            useremail: this.undt.username,
            empid: parseInt(this.undt.userid),
            empcode: this.undt.userid,
            periodicid: (this.course.periodicid && this.course.periodicid != null) ? this.course.periodicid : null,
        };
       axios({
        method: 'POST',
        url,
        data: this.input,
        'headers':{ 'authorization' :this.tokendata }
      }).then(result => {
        this.isLoading = false;
        if(result.data.errorCode == 0) {
            this.courseTestList = result.data.data.rows;
            // this.isAssessmentMailReminderSentToday(this.courseTestList.map(i => i.courseassessmentmasterid))
        }else{
            this.courseTestList = [];
        }
      }).catch(e => {
                this.displayError(e)
        })
    },
    getCoursedetailsbyID(courseid) {
      this.isLoading = true;
      let url = 'api/coursemaster/getCoursedetailsbyId';
      this.input = {
            courseid:courseid,
            // useremail: this.undt.username,
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
        };
       axios({
        method: 'POST',
        url,
        data: this.input,
        'headers':{ 'authorization' :this.tokendata }
      }).then(result => {
        this.isLoading = false;
        if(result.data.errorCode == 0) {
          this.editRowData = result.data.data;
          this.openEditModule(this.editRowData,0)
          this.getExamsettingList()
        } else {
          this.$router.push({ name: 'TrainingList', params: { search: true }})
          Swal.fire({
              title: "Failed!",
              text: "Your are not authorised user to update this Course",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          })
        }
      }).catch(e => {
                this.displayError(e)
        })
    },
    openEditModule: function(currRowData, flag){
        this.editRowData = currRowData
        this.traininghistory = this.editRowData
        this.course.courseid = currRowData[0].courseid
        this.course.coursecode = currRowData[0].coursecode
        this.course.coursename = currRowData[0].coursename
        this.course.iscompleted = currRowData[0].iscompleted
        this.course.courseduration = currRowData[0].courseduration
        //this.course.coursedetails = currRowData[0].description
        this.filterHtML(currRowData[0].description);
        this.course.nooftraining = currRowData[0].nooftraining
        this.course.createdby = currRowData[0].createdby
        this.course.createddate = currRowData[0].createddate
        this.course.lastmodifiedby = currRowData[0].lastmodifiedby
        this.course.lastmodifieddate = currRowData[0].lastmodifieddate
        this.course.startdate = currRowData[0].startdate
        this.course.enddate = currRowData[0].enddate
        this.course.coursetype = currRowData[0].coursetype
        this.course.isdeptsubscribed = currRowData[0].isdeptsubscribed
        this.course.ispublished = currRowData[0].ispublished
        this.course.publishedby = currRowData[0].publishedby
        this.course.periodictype = currRowData[0].periodictype
        this.course.periodicid = currRowData[0].periodicid
        this.getCourseTestsList(this.$route.query.courseid)

        if(this.course.isdeptsubscribed) {
            this.getSubscribedDesignations(currRowData[0].courseid)
        }
        if(this.course.publishedby === this.undt.username) {
            this.isMailReminderSentToday('coursereminder')
        }
        // this.getTrainingHistory(currRowData[0].courseid)
        if(currRowData && currRowData.length) {          
            for(let i=0;i<currRowData.length;i++){
                let training = currRowData[i]
                this.trainingArr[i].trainingname = training.trainingname
                //this.trainingArr[i].description = training.trdetails
                this.filterHtMLTraining(currRowData);
                this.getDocumetList(parseInt(training.trainingid), i)
                //   if(training.documents && training.documents.hasOwnProperty('data')) {
                    //   this.trainingArr[i].attachmentview =[];
                    //     // this.getDownloadLink(training.documents);
                    //     }
                }
            }
        
        this.course.iseditstate = true
        this.isAddState=true
        if(flag==0){
            this.isedit=true
            this.isRequestActive=true
            // this.mydetailscollapse()
        }
    },
    filterHtMLTraining(currRowData){
      for(let i=0;i<currRowData.length;i++){
        let training = currRowData[i]
        this.trainingArr[i].id = training.trainingid
        this.trainingArr[i].trainingname = training.trainingname
        this.getDocumetList(this.trainingArr[i].id, i)

        const parser = new DOMParser();
        const doc = parser.parseFromString(training.trdetails, 'text/html');
        const imgElements = doc.querySelectorAll('img');
        // Create an array to hold all Axios requests
        const requests = [];
        
        imgElements.forEach((img) => {
            if (img.src) {
                let omgsrc = img.src.slice(10);
                let regex = /training\/[^?]+/;
                omgsrc = regex.exec(omgsrc);

                if(omgsrc) {    
                    const request = axios({
                        method: 'POST',
                        url: `api/training/getpresignurl`,
                        data: {
                            useremail: this.undt.username,
                            empcode: this.undt.userid,
                            imgfilename: omgsrc[0]
                        },
                        headers: { authorization: this.tokendata }
                    }).then((result) => {
                        if (result.data.status) {
                            img.src = result.data.url;
                        }
                    }).catch(e => {
                        this.displayError(e)
                    });
                    requests.push(request);
                }
            }
        });

        // Wait for all requests to complete
        Promise.all(requests).then(() => {
            // Update coursedeatils after all requests are completed
            this.trainingArr[i].description = doc.documentElement.outerHTML;
        });
      }
    },
    filterHtML(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const imgElements = doc.querySelectorAll('img');

      // Create an array to hold all Axios requests
      const requests = [];

      imgElements.forEach((img) => {
          if (img.src) {
            let omgsrc = img.src.slice(10);
            let regex = /training\/[^?]+/;
            omgsrc = regex.exec(omgsrc);

            if(omgsrc) {
                const request = axios({
                    method: 'POST',
                    url: `api/training/getpresignurl`,
                    data: {
                        useremail: this.undt.username,
                        empcode: this.undt.userid,
                        imgfilename: omgsrc[0]
                    },
                    headers: { authorization: this.tokendata }
                }).then((result) => {
                    if (result.data.status) {
                        img.src = result.data.url;
                    }
                }).catch(e => {
                    this.displayError(e)
                });
                requests.push(request);
            }
          }
      });

      // Wait for all requests to complete
      Promise.all(requests).then(() => {
          // Update coursedeatils after all requests are completed
          this.course.coursedetails = doc.documentElement.outerHTML;
      });
    },
    getSubscribedDesignations(courseid) {
        let url = 'api/coursemaster/getSubscribedDesignations'
        axios({
            method: 'POST',
            url,
            data: { courseid,useremail: this.undt.username,empcode: this.undt.userid },
            headers: { authorization: this.tokendata }
        }).then(result => {
            if(result.data.errorCode == 0) {
                this.course.dessubslist = result.data.data.rows
            } else {
                this.course.dessubslist = []
            }
        }).catch(e => {
          this.displayError(e)
        })
    },
    getSubmittedAnsList(data) {
        console.log(data)
        this.currAssTitle  = data.assessmenttitle
                this.currObMarks  = data.totalcorrectans
        this.isLoading = true
        let url = "api/profile/getSubmittedAnsById"
        this.input = {
            empid : parseInt(this.undt.userid),
            courseassessmentmasterid:data.courseassessmentmasterid,
            resid:data.id,
            useremail: this.undt.username,
            empcode: this.undt.userid
        };
        axios({
            method: 'POST',
            url,
            'headers':{ 'authorization' :this.tokendata },
            data:this.input
        }).then(result => {
            this.isLoading = false
            $('#technologypopup').modal('show');
            if(result.data.errorCode == 0) {
                this.ansList = result.data.data
               
            } else if(result.data.errorCode == 3){
                Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                    window.location.href = "/#/login";
                })
            }
            else {
                this.ansList = result.data.data
                let tempdata = this.ansList[0]
                this.ansList= tempdata
                this.ansList = []
            }
        })
    },
    getExamsettingList() {
        this.isLoading = true
        let url = 'api/profile/getUserdashboardassessmentdup'
        axios({
            method: 'POST',
            url,
            data: { 
                limit: apiUrl.LIMIT,
                offset: this.pageoffset,
                empid: parseInt(this.undt.userid),
                courseid: parseInt(this.$route.query.courseid),
                useremail: this.undt.username,empcode: this.undt.userid
            },
            headers:{ authorization :this.tokendata }
        }).then(result => {
            this.isLoading = false
            if(result.data.errorCode == 0) {
            this.examlist = result.data.data.rows
            this.examlist = this.examlist.reduce((group, product) => {
                const { periodicid } = product;
                group[periodicid] = group[periodicid] ? group[periodicid] : [];
                group[periodicid].push(product);
                return group;
                }, {});
            console.log(this.examlist)
            this.totalcount = result.data.data.count
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
            } else if(result.data.errorCode == 3){
                Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                    window.location.href = "/#/login";
                })
            } else {
            this.examlist = []
            this.totalcount = 0
            }
        }).catch(e => {
            this.displayError(e)
        });
    },
    getTrainingHistory(courseid){
      this.isLoading = true;
      let url = "api/coursemaster/getTraininghistory";
      this.input = {
        courseid:courseid,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        this.forceRerender()
        if (result.data.errorCode == 0) {
          this.traininghistory = result.data.data;
        } else {
          this.traininghistory=[]
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
    forceRerender() {
      this.componentKey += 1;
    },

    getCourseData() {
      this.isLoading = true
      let url = 'api/coursemaster/list'
       axios({
        method: 'POST',
        url,
        data: { 
        useremail: this.undt.username,
        empcode: this.undt.userid,
          // limit: apiUrl.LIMIT,
          // offset: this.pageoffset 
        },
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          let d = result.data.data
          this.training.courselist = d
          this.totalcount = result.data.data.count
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
        } else {
          this.training.courselist = []
          this.totalcount = 0
        }
      }).catch(e => {
          this.displayError(e)
        })
    },

    getLogtimeDownloadLink(data, curr=null, trdocid, trainingid, isdownloaded, index, idx) {
        var trdocs = null
        if(curr === 'download-doc' && !isdownloaded) {
            trdocs = {
                moduletype: 'trainingdoc',
                empid: parseInt(this.undt.userid),
                trdocid,
                trainingid,
                courseid: parseInt(this.course.courseid),
                downloadedby: this.undt.username,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                periodicid: (this.course.periodicid && this.course.periodicid != null) ? this.course.periodicid : null
            }
        }  
        let courseid = this.course.courseid
        let empid = this.undt.userid
        let watchedby = this.undt.username
        let tokendata = this.tokendata
        let isdownloadedflag = isdownloaded
        let trainingArrDup = this.trainingArr
        let nooftraining = this.course.nooftraining
        let iscompleted = false
        let didAllFilesDownloaded = this.didAllFilesDownloaded
        let markCourseAsCompleted = this.markCourseAsCompleted
        let useremail = this.undt.username
        let empcode = this.undt.userid

      if(data) {
        this.isLoading = true
        var input =[]
        input.push({link:data,empcode: this.undt.userid,useremail: this.undt.username})
        let payload = {
          documents:input,
          trdocs,
          useremail: this.undt.username,
          empcode: this.undt.userid

        };
        axios({
                method: "POST",
                url: '/api/imageupload/getdownloadlink',
                data: payload,
                headers: { authorization: tokendata }
            })
            .then((result) => {
                this.isLoading = false
                if(result.data.status) {
                    if(result.data.data[0].presignurl){
                        function markAsViewed(isdownloadedflag2, trdocs, trainingArrDup) {
                            if(!isdownloadedflag2) {
                                axios({
                                    method: "POST",
                                    url: 'api/coursemaster/markVideoAsWatched',
                                    data: trdocs,
                                    headers: { authorization: tokendata }
                                }).then(result => {
                                    if(result.data.errorCode == 0) {        
                                        //mark completed done
                                        trainingArrDup[index].documentlist[idx].iswatched = true
                                        let c = trainingArrDup.filter((i,index2)=> index2 < nooftraining)
                                        let d = c.map(tr => tr.documentlist)
                                        let sdk = []
                                        d.forEach(arr => {
                                            arr.forEach(itm => sdk.push(itm))
                                        })
                                        iscompleted = didAllFilesDownloaded(sdk)
                                        if(iscompleted) {
                                            markCourseAsCompleted()
                                        }
                                    }
                                })
                            }
                        }
                        if(curr=='zoom'){
                            $('.imagepreview').attr('src', result.data.data[0].presignurl);
                            $('#imagemodal').modal('show');
                            if(!isdownloadedflag) {
                                trdocs = { empid, trdocid, trainingid, courseid, watchedby,useremail,empcode }
                                markAsViewed(isdownloadedflag, trdocs, trainingArrDup)
                            }
                            this.trainingArr = trainingArrDup
                            return
                        } else if(curr == 'vidzoom') {
                            this.videoURL = result.data.data[0].presignurl
                            this.videoIsOpen = true
                            $('#videomodal').modal('show');
                            setTimeout(func, 5000)
                            function func() {
                                var vid = document.getElementById('myVideoPlayer')
                                vid.addEventListener('ended', videoPlayer, false);
                                function videoPlayer(event) {
                                    if(event.type=='ended') {
                                        trdocs = { empid, trdocid, trainingid, courseid, watchedby,empcode,useremail }
                                        markAsViewed(isdownloadedflag, trdocs, trainingArrDup)
                                    }
                                }
                            }
                            this.trainingArr = trainingArrDup
                            return
                        }
                        window.location.href=result.data.data[0].presignurl;   
                        if(curr === 'download-doc' && !isdownloaded) {
                            this.trainingArr[index].documentlist[idx].isdownloaded = true
                            let c =this.trainingArr.filter((i,index)=> index < this.course.nooftraining)
                            let d = c.map(tr => tr.documentlist)
                            let sdk = []
                            d.forEach(arr => {
                                arr.forEach(itm => sdk.push(itm))
                            })
                            this.course.iscompleted = this.didAllFilesDownloaded(sdk)
                        }
                    }
                }
            })
            .catch(e => {
          this.displayError(e)
        })
        }
    },
    markCourseAsCompleted() {
        this.course.iscompleted = true
    },
    onZoom(e,  action, id, trainingid, isdownloaded, index, i){
      this.getLogtimeDownloadLink(e, action, id, trainingid, isdownloaded, index, i);
    },
    vlc(e, action, id, trainingid, isdownloaded, index, i) {
        // $('.videopreview').attr('src', result.data.data[0].presignurl);
        this.getLogtimeDownloadLink(e, action, id, trainingid, isdownloaded, index, i);
    },
    stopVideoPlay() {
        var video = document.getElementById("myVideoPlayer");
        video.pause();
        video.currentTime = 0;
    },
    getDocumetList(moduleid, idx){
        this.isLoading = true;
        let url = "api/s3upload/getDocumentPresignedUrl";
        this.input = { moduleid, moduletype: 'training', empid: parseInt(this.undt.userid),useremail: this.undt.username, empcode: this.undt.userid, periodicid: (this.course.periodicid && this.course.periodicid != null) ? this.course.periodicid : null };
        axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.trainingArr[idx].documentlist = result.data.data.map((doc, i) => {
                    return {
                        info: doc.description,
                        link: doc.documentname,
                        id: doc.id,
                        trainingid: doc.trainingid,
                        isdownloaded: doc.isdownloaded,
                        iswatched: doc.iswatched,
                        useremail: this.undt.username,
                        empcode: this.undt.userid,
                    }
                })                
                this.trainingArrLength = this.trainingArr.filter(el => el.documentlist.length).length
                
                if(this.trainingArrLength == 0 && this.course.ispublished && this.course.iscompleted == null){
                    this.saveIscomplet(this.$route.query.courseid);
                }


                // this.course.iscompleted = this.didAllFilesDownloaded(this.trainingArr[idx].documentlist)
                // let idata = { data: docs }
                // this.getLogtimeDownloadLink(idata, idx)
            } else {
                this.trainingArr[idx].documentlist = [];
            }
        });
    },
        didAllFilesDownloaded(docs) {
            if(docs.length) {
                this.getCourseTestsList(this.$route.query.courseid)
                return docs.every(doc => doc.isdownloaded === true || doc.iswatched === true)
            }
            return false
        },
        formatName(authorstring) {
            let author=authorstring.replace('@firstcry.com','')
            if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
            author = author.replace('.',' ')
            author = author.charAt(0).toUpperCase() + author.slice(1);
            return author
        },

        formatData(data) {
            return data
        },
        handleCancel() {
            this.myrequestollapse()
            this.isdisabled = false
            $('html, body').animate({ scrollTop: 0 }, 1200);
        },
        handleLogCancel() {
            this.dislogActive=true
            this.isLogActive=false
            this.isdisabled = false
            this.logtime.comment = ''
            $('html, body').animate({ scrollTop: 0 }, 1200);
        },
        dateFormatterSales: function(dt) {
            let dateoptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric"
            };
            return dt.toLocaleDateString("en-GB", dateoptions);
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        },
        dateFormat2(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY');
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },

        previewpdf(getlink)
        {
            let input = [];
            let geturl;

            input.push({
                link: getlink,
                empcode: this.undt.userid,
                useremail: this.undt.username
            })

            let payload = {
                documents: input,
                trdocs: {},
                useremail: this.undt.username,
                empcode: this.undt.userid
            };

            axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    headers: { authorization: this.tokendata }
                })
                .then((result) => {
                    geturl = result.data.data[0].presignurl;
                    window.open(geturl, "_blank");
                })
        }
  }, 
  filters: {
        basename: function (value) {
        if (!value) return ''
        //value = value.toString()
        return value.substr(value.lastIndexOf("/") + 1)
    }
  }
}
</script>
<style>
/* #videomodal {
    min-width: 600px;
    min-height: 340px;
    margin: 0 auto;
} */
.ck-content{
    min-height: 150px !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
.display{
    display: none !important;
}

.pop-up {
    width: 70% !important;
}
.click-zoom input[type=checkbox] {
  display: none;
}

.click-zoom img {
  /* margin: 100px; */
  transition: transform 0.25s ease;
  cursor: zoom-in;
}

.click-zoom input[type=checkbox]:checked~img {
  transform: scale(10);
  cursor: zoom-out;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

.video__container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #000;
  min-width: 950px;
  min-height: 475px;
  margin-left: -4rem;
}

.modal-video-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  color: black;
  background-color: #fff;
  opacity: 0.9;
  z-index: 9;
 
}

.modal-video-close-btn:hover {
  color: #fff;
  background-color: #9d9d9db5 ;
  opacity: 0.8;
}
.traning_image_listing_st{
    display: inline-block;
    margin: 8px;
    position: relative;
    top: 10px;   
}
.btn-br{
    position: absolute;
    top: 6px;
    right: 40px;
    color: #28C76F;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
}
@media (max-width:767px){
    .video__container_mobile_view_centers{
        top: -20px;
    }
    .modal-video-close-btn {
      z-index: 999;
    }
}

.modal-sm-min-width {
    min-width: 200px;
    max-width: 100%!important;
}



</style>