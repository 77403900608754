<template>
    <div class="app-content content ">
        <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header-left col-md-9 col-12 mb-1 px-0 pad_top_mar_bot-5">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h3 class="content-header-title float-left mb-0">Application Module</h3>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Wizard',path:'project',btnstate:'view',rowdata:backpagedata})">Projects</li>
                                    <li class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Application',path:'application',btnstate:'view',rowdata:backpagedata})">Applications</li>
                                    <li class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Module',path:'projectmodule',btnstate:'view',rowdata:backpagedata})">Modules</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            <section id="card-navigation">
                <div class="file-manager-content-body">
                    <div class="drives">
                        <div class="row">
                            <div class="col-12">
                                <div v-if="pagefeatures.searchapplication" class="card card-body" style="margin-top: 10px">
                                    <div class="row breadcrumbs-top">
                                    <div class="col-12 title">
                                        <h4 class="card-title">SEARCH APPLICATION DETAILS</h4>
                                    </div>
                                    </div>
                                    <div class="row" >
                                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1 " >
                                                        <label class="form-label lable-left">Project</label>
                                                        <treeselect
                                                        class="projectmdllistdd capitalisetext"
                                                        v-model="search.searchProject"
                                                        :options="search.projectlistopts"
                                                        @select="selectProjectid"
                                                        />
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label lable-left">Project Module</label>
                                                    <treeselect
                                                    class="projectmdllistdd capitalisetext"
                                                    v-model="search.searchProjectModule"
                                                    :options="search.projectmodulelistopts"
                                                    @input="selectProjectModuleid"
                                                    />
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                                        <label class="form-label lable-left">Application</label>
                                                        <treeselect
                                                        class="projectmdllistdd capitalisetext"
                                                        v-model="search.searchApplication"
                                                        :options="search.applicationlistopts"
                                                        
                                                        />
                                        </div>
                                                    
                                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                                        <button
                                                        type="submit"
                                                        class="btn btn-primary mt-2 mr-1"
                                                        @click="getfilterapplicationmodlist()"
                                                        >
                                                        Search
                                                        </button>
                                                        <button
                                                        type="reset"
                                                        class="btn btn-outline-secondary mt-2"
                                                        @click="resetRecords()"
                                                        >
                                                        Cancel
                                                        </button>
                                        </div>
                                    </div>
                                </div> 
                                <div v-if="pagefeatures.applcationdetails" class="card border-warning filter_card_searchbgst" >
                                    <div class="card-header" v-on:click="mysearchrequestollapse">
                                        <div class="avatar bg-light-info rounded" style="margin-left:29%">
                                            <div class="avatar-content">
                                                <grid-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></grid-icon>
                                            </div>
                                        </div>
                                        <h4 class="card-title" style="margin-left:-449px;">APPLICATION DETAILS</h4>
                                        <div class="heading-elements" style="color:blue;">
                                            <ul class="list-inline mb-0">
                                                <li>
                                                    <a data-action="collapse"><plus-icon size="4x" class="custom-class"></plus-icon></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" style="margin-top:10px">
                                        <div class="card-body">
                                            <div class="row">
                                            <div  v-for="(data,index1) in modulelist" v-bind:key="data.moduleid" class="col-lg-3 col-md-6 col-12">
                                                <div v-bind:key="data.moduleid" class="card border cursor-pointer" @click="getprojectapplicationmodulelist(data)" :class="[index1 < 9 ? stylevariation[index1].label : 'bg-secondary']">
                                                    <div class="card-body">
                                                        <div class="d-flex justify-content-between text-white">
                                                            <box-icon size="40x" class="custom-class"></box-icon>
                                                        </div>
                                                        <div class="my-1">
                                                            <h4 class="capitalisetext text-white">{{data.applicationname}}</h4>
                                                            <h5 class="capitalisetext text-white">{{dateFormat(data.createddate)}}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border-warning filter_card_searchbgst" >
                            <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                                <div class="container-fluid">
                                <div class="row fc_my-05" >
                                    <div class="w-auto px-1 mobile-padding-0-2 mobile_w_calc_38">
                                        <div class="avatar bg-light-secondary rounded d-inline-flex">
                                            <div class="avatar-content">
                                                <search-icon
                                                size="1.5x"
                                                class="custom-class avatar-icon font-medium-3"
                                                ></search-icon>
                                            </div>
                                            </div>
                                        <h4 class="card-title d-inline-flex ml-1 mobile_width-86">Search Application Module Details</h4>
                                    </div>
                                    <div class="ml-auto mr-lg-1 d-flex">
                                    <div class=" px-1 text-right mobile-padding-0-2" v-if="showaddmodulebtn">
                                    <div class="input-group-append displayblk">
                                        <button id="commonaddmodule" class="btn btn-sm btn-relief-success"  data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#applicationmodulemaster"  type="button" data-backdrop="static" data-keyboard="false" @click="addmodule"><span class="mobile_hide_bt">Create Application Module</span> <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon> </button>
                                    </div>
                                    </div>

                                    <span class="rotetions_view_arrow"> 
                                      <em class="las la-angle-down"></em>
                                    </span>
                                </div>
                                    
                                    
                                </div>
                                </div>
                            </div>
                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                    <div class="card-body pt-2 px-sm-mobile-10">
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                        <label class="form-label lable-left">Project Name</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name"  v-on:search-change="projectChange" v-model="search.searchProject" :options="projectnameList" @select="selectProjectid"/>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                        <label class="form-label lable-left">Application Name</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project application name" v-model="search.searchApplication" :options="search.applicationlistopts" @select="selectApplicationid"/>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                        <label class="form-label lable-left">Project Module Name</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project module name" v-model="search.searchProjectModule" :options="search.projectmodulelistopts" @select="selectModuleid"  />
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                        <label class="form-label lable-left">Application Module Name</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search application module name" v-on:search-change="applicationmoduleChange" v-model="search.searchApplicationModule" :options="search.applicationmodulelistopts"/>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <label class="form-label lable-left">Application Module Status</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Application Module Status" v-model="search.appmodulestatus" :options="search.appmodulestatuslist"/>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 mb-0 pull-right text-right">
                        <button type="submit" class="btn btn-relief-primary mt-1 mr-1" @click="getprojectapplicationmodulelist(search)">
                            <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                        <button type="reset" class="btn btn-outline-secondary mt-1 mr-1 " @click="resetRecords()"> <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span> </button>
                        <button type="move" class="btn btn-relief-primary mt-1" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="row" v-if="showproject">
                    <div class="col-md-12">
                        <div class="card mb-3">
                            <div class="">
                                <div class="tab-content" id="proAppModuleList">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="row" id="table-small">
                                            <div class="col-12">
                                                <div class="card data_table_padding_ziro_mobile">
                                                    <div class="" style="padding-left:0px !important;padding-top:0px !important;">
                                                        <div class="row">
                                                            <div class="col-md-9">
                                                                <div class="input-group-append" v-if="pagefeatures.createappmodule">
                                                                    <h4 class="card-title ">Project Application: <strong class="capitalisetext">{{applicationmodule.applicationname}}</strong></h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="table-responsive datatableview" style="">
                                                        <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" class="srno align-middle first-col-sticky" style="min-width: 45px;">Sr#</th>
                                                                    <th scope="col" class="align-middle second-col-sticky">Application Module <br/>Name</th>
                                                                    <th scope="col" class="align-middle">Project Name</th>
                                                                    <th scope="col" class="align-middle">Module Name</th>
                                                                    <th scope="col" class="align-middle">Application Name</th>
                                                                    <th scope="col" class="align-middle text-center">Total Sections</th>
                                                                    <th scope="col" class="align-middle text-center">Total Functionality</th>
                                                                    <th scope="col" class="align-middle" style="min-width:90px;">Created Date</th>
                                                                    <th scope="col" class="clsaction align-middle">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <template v-for="(data,index) in companymasterlist">        
                                                                    <tr v-bind:key="data.moduleid">
                                                                        <td data-column="Sr#" class="srno first-col-sticky">{{index+1+currPageNum*pagelimit}}</td>
                                                                        <td data-column="Application Name" class="second-col-sticky">
                                                                            <span class="font-weight-normal cursorpointercls sitebluecolor" @click="redirecttourl('view',data)">{{data.applicationmodulename}}</span>
                                                                            <span class="mx-1 ">
                                                                            <span v-if="data.isactive == 1" class="badge badge-pill badge-light-success mr-50 mb-25 capitalisetext">Active</span>
                                                                            <span v-else class="badge badge-pill badge-light-danger mr-50 mb-25 capitalisetext">Inactive</span>
                                                                            </span>
                                                                        </td>
                                                                        
                                                                        <td data-column="Project Name">
                                                                            <span class="font-weight-normal capitalisetext cursorpointercls sitebluecolor" @click="redirecttourl('projectId',data)">{{data.projectname}}</span>
                                                                        </td>
                                                                        <td data-column="Module Name">
                                                                            <span class="font-weight-normal capitalisetext">{{data.projectmodulename}}</span>
                                                                        </td>
                                                                        <td data-column="Application Name">
                                                                            <span class="font-weight-normal">{{data.applicationname}}</span>
                                                                        </td>
                                                                         <td data-column="Total Sections" class="centeralign"> 
                                                                            <span class="font-weight-normal cursorpointercls sitebluecolor capitalisetext" style="cursor:pointer;" @click="redirecttourl('view', data)">{{data.totalsections}}</span>
                                                                        </td>
                                                                         <td data-column="Total Functionality" class="centeralign">
                                                                                <span
                                                                                  class="font-weight-normal cursorpointercls sitebluecolor capitalisetext" style="cursor:pointer;" @click="redirecttourl('viewfun', data)">
                                                                                   {{data.totalfunctionality}}
                                                                                   </span>
                                                                        </td>
                                                                        <td data-column="Created Date" class="centeralign font-weight-normal">{{dateFormat(data.createddate)}}</td>
                                                                        
                                                                        <td data-column="Actions" class="clsaction">
                                                                            <div class="dropdown">
                                                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                    <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                </button>
                                                                                <div class="dropdown-menu">
                                                                                    <div v-if="data.projectlevel == 4">
                                                                                        <a class="dropdown-item devEditEle" href="javascript:void(0);" @click="redirecttowsurl('view',data)">
                                                                                            <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                            <span>View Workstep</span>
                                                                                        </a>
                                                                                        <a class="dropdown-item" @click="redirecttowsurl('edit',data)" href="javascript:void(0);" v-if="data.isactive ==1 && (data.projectisactive ==1 && data.projectisapproved ==1)">
                                                                                            <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                                                            <span>Add Workstep</span>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div v-else>
                                                                                        <a class="dropdown-item" @click="redirecttourl('view',data)" href="javascript:void(0);">
                                                                                            <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                            <span>View Section</span>
                                                                                        </a>
                                                                                        <a class="dropdown-item" @click="redirecttourl('edit',data)" href="javascript:void(0);" v-if="data.isactive ==1 && (data.projectisactive ==1 && data.projectisapproved ==1)">
                                                                                            <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                                                            <span>Add Section</span>
                                                                                        </a>
                                                                                    </div>
                                                                                    <a class="dropdown-item devEditEle" href="javascript:void(0);"   data-toggle="modal" data-target="#applicationmodulemaster" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false">
                                                                                        <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                        <span>Edit</span>
                                                                                    </a>
                                                                                    <a
                                                                                        class="dropdown-item devEditEle"
                                                                                        v-if="data.isactive == 0 || data.isactive ==null || data.isactive ==''"
                                                                                        type="submit"
                                                                                        href="javascript:void(0);"
                                                                                        @click="appmoduleisactive(data,1)"
                                                                                        id="submitbtnt18"
                                                                                        data-backdrop="static" data-keyboard="false"
                                                                                    >
                                                                                        <check-icon size="1.5x" class="custom-class mr-50"></check-icon>
                                                                                        <span> Active Application module</span> 
                                                                                    </a>
                                                                                    <a class="dropdown-item devEditEle"
                                                                                        v-else-if="data.isactive ==1"
                                                                                        type="submit"
                                                                                        href="javascript:void(0);"
                                                                                        @click="appmoduleisactive(data,0)"
                                                                                        id="submitbtnt18"
                                                                                        data-backdrop="static" data-keyboard="false"
                                                                                    >
                                                                                    <i class="custom-class mr-50 las las la-ban"></i>
                                                                                        <span> Deactivate Application module</span>
                                                                                    </a>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-if="companymasterlist.length==0">
                                                                    <tr><td colspan="14" style="text-align:center;">No record found</td></tr>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="pageCenter text-center">
                                        <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show" id="applicationmodulemaster">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color:#ffff !important">×</button>
                    <div class="modal-header mb-1" style="background-color:#2ca02c !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                            <span class="align-middle ml-50" >Add/Update Application Module Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                    <form class="form form-vertical">
                        <div class="row">
                            <div class="col-12 mb-1">
                                <div class="table-responsive">
                                  <table class="table table-sm dataTableListing" aria-describedby="mydesc">
                                    <thead>
                                      <tr>
                                        <th scope="col">Project Name</th>
                                        <th scope="col" v-if="this.backpagedata && this.backpagedata.backbtnstate == 'pathApp'">Application Name</th>
                                        <th scope="col" v-else>Project Module</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                        <td class="font-weight-normal ">
                                            {{this.applicationmodule.projectname ? this.applicationmodule.projectname:'NA'}}
                                            </td>
                                        <td v-if="this.backpagedata && this.backpagedata.backbtnstate == 'pathApp'" class="font-weight-normal ">
                                            {{this.applicationmodule.applicationname ? this.applicationmodule.applicationname:'NA'}}
                                        </td>
                                        <td v-else>{{this.applicationmodule.projectmodulename ? this.applicationmodule.projectmodulename:'NA'}}</td>
                                    </tbody>
                                  </table>
                                </div>
                            </div>
                            <div class="col-12" v-if="this.backpagedata && this.backpagedata.backbtnstate == 'pathApp'">
                                <div class="form-group mg-bot0">
                                    <label for="appmdllistdd">Project Module</label>
                                    <treeselect  class="appmdllistdd capitalisetext" v-model="applicationmodule.projectmoduleid" :options=" applicationmodule.projectmodulelist" @input="selprojmodid" @close="validateForm('t34',$event)"/>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.applicationmodule.projectmoduleid">{{ this.errors.applicationmodule.projectmoduleid }}</div>
                            </div>
                            <div class="col-12" v-else>
                                <div class="form-group mg-bot0">
                                    <label for="appmdllistdd">Application Name</label>
                                    <treeselect  class="appmdllistdd capitalisetext" placeholder="Select Application Name" v-model="applicationmodule.applicationid" :options="applicationmodule.applicationlist"  @input="selamappmdldata" @close="validateForm('t34',$event)"/>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.applicationmodule.applicationid">{{ this.errors.applicationmodule.applicationid }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="appmdllistdd">Application Infra Module</label>
                                    <treeselect class="appmdllistdd capitalisetext" placeholder="Select Application Infra Module" v-model="applicationmodule.applicationenvid" :options="applicationmodule.applicationenvlit"  @input="selamappenvdata" @close="validateForm('t34',$event)"/>
                                <div class="errorinputmsg" v-if="this.errors.applicationmodule.applicationenvid">{{ this.errors.applicationmodule.applicationenvid }}</div>
                            </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="appmdlnm">Application Module Name</label>
                                   <input :disabled="isAppenvSelected" type="text" id="modulenamet22" class="form-control capitalisetext" name="" placeholder="Application Module Name" v-model="applicationmodule.applicationmodulename"
                                    v-bind:class="{ 
                                            'form-control': true,
                                            '': !valid2Text(applicationmodule.applicationmodulename) && appmoduleBlured && !applicationmodule.applicationenvid, }"
                                            v-on:blur="appmoduleBlured = true; errors.applicationmodule.applicationenvid=null"/>
                                    <div class="errorinputmsg" v-if="!valid2Text(applicationmodule.applicationmodulename) && appmoduleBlured && !applicationmodule.applicationenvid">Application module name is required</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="appmdldesc">Application Module Description</label>
                                    <textarea class="form-control appmdldesc" v-model="applicationmodule.applicationmoduledescription" id="appmddescription" rows="5" placeholder="Application Module Description "
                                     v-bind:class="{ 
                                            'form-control': true,
                                            '': !valid2Text(applicationmodule.applicationmoduledescription) && descriptionBlured, }"
                                            v-on:blur="descriptionBlured = true"/>
                                    <div class="errorinputmsg" v-if="!valid2Text(applicationmodule.applicationmoduledescription) && descriptionBlured">Application module description is required</div>
                                </div>
                            </div>
                            <div class="col-12 mg-top1">
                                <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard('t34',$event)" id="submitbtnt34">Submit</button>
                                <button type="reset" class="btn btn-outline-secondary" @click="resetFormData()">Clear</button>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>         
    </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import { ExternalLinkIcon,XIcon,PlusCircleIcon,PlusSquareIcon,PlusIcon,Edit2Icon,MoreVerticalIcon,PackageIcon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon,Link2Icon,CheckIcon } from 'vue-feather-icons'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import commonMethods from '../../utils/commonMethods';

export default {
    name:'ProjectApplication',
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,
        PlusCircleIcon,
        XIcon,
        ExternalLinkIcon,CheckIcon
    },    
    mixins: [ commonMethods ],
    data() {
        return {
            isAppenvSelected: false,
            search: {
            status: false,
            searchProject:null,
            searchProjectModule: null,
            searchApplication: null,
            searchApplicationModule: null,
            projectlistopts:null,
            projectmodulelistopts:null,
            applicationlistopts:null,
            applicationmodulelistopts:null,
            appmodulestatus: null,
            appmodulestatuslist:[
                { label: "Active", id: 1 },
                { label: "Inactive", id: 0 }
            ]
        },
            projectnameList:[],        
            isLoading: false,
            fullPage: true,
            islistLoading:false,
            listfullPage:true,
            stylevariation:apiUrl.stylevariation,
            companymasterlist: [],
            selected: null,
            editRowData:[],
            glbMdl:'',
            TimeFormat: apiUrl.TimeFormat,
            application:{
                applicationname:null
            },
            applicationmodule:{
                applicationname:null,
                projectid:null,
                projectname:null,
                projectlist:null,
                projectmoduleid:null,
                projectmodulelist:null,
                applicationid:null,
                applicationlist:[],
                applicationmodulename:null,
                applicationmoduledescription:null,
                iseditstate:false,
                disableelements:true,
                projecttypelist:null,
                projecttypeid:null,
                businesslist:null,
                businessverticleid:null,
                companyid:null,
                companylist:null,
                applicationenvid:null,
                applicationenvlit:null,
                projectmodulename:null
            },
            applinameBlured:false,
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            showtabledata:true,
            showproject:false,
            activeprojectid:'',
            modulelist:[],
            isSearchRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            errors:{
                applicationmodule:{
                    projectid:null,
                    projectmoduleid:null,
                    projectmodulelist:null,
                    applicationid:null,
                    applicationmodulename:null,
                    applicationmoduledescription:null,
                    projectname:null,
                    projecttypename:null
                }
            },
            showaddmodulebtn:false,
            backpagedata:null,
            appmoduleBlured:false,
            descriptionBlured:false,
            tokendata:null,
            extradata: null
        }
    },
    created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
            if (element.page === '/applicationmodule') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
        })
    },
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
            }
            this.getApplicationdata('t11','applicationmodule')
            let rowdata={}
            if (this.$route.params.backbtnstate != undefined) {
                if (this.$route.params.backbtnstate == 'edit' || this.$route.params.backbtnstate == 'view' || this.$route.params.backbtnstate == 'viewapp') {
                    let search = JSON.parse(localStorage.getItem('appmodulesearchItems'))
                    if(search && (search.hasOwnProperty("searchProject") || search.hasOwnProperty('projectid') || search.hasOwnProperty('projectname'))) {
                        if(!this.projectnamelist){
                            this.projectChange(search.projectname)
                        }
                        this.backpagedata=this.$route.params.clickeddata
                        if(this.$route.params && this.$route.params.clickeddata && (this.$route.params.clickeddata.searchProject || this.$route.params.clickeddata.projectid)){
                            this.search.searchProject = this.$route.params.clickeddata.searchProject ? this.$route.params.clickeddata.searchProject:this.$route.params.clickeddata.projectid ? this.$route.params.clickeddata.projectid : this.$route.params.clickeddata.searchProject

                            rowdata.searchProject = this.search.searchProject

                            if(this.$route.params.backbtnstate != 'viewapp'){
                                this.search.searchProjectModule = this.$route.params.clickeddata.projectmoduleid ? this.$route.params.clickeddata.projectmoduleid :null;
                            }
                        }
                        // this.getprojectapplicationmodulelist(search)
                        // this.getprojectmoduleapplications(search);
                        this.getSearchItems(search);
                    } else {
                        localStorage.removeItem('appmodulesearchItems');
                        this.backpagedata=this.$route.params.clickeddata;
                        this.getprojectapplicationmodulelist(this.$route.params.clickeddata);
                        this.getprojectmoduleapplications(this.$route.params.clickeddata);
                    }
                    if(this.$route.params.backbtnstate == 'edit'){
                        this.backpagedata=this.$route.params.clickeddata;
                        this.applicationmodule.projectname = this.$route.params.clickeddata.projectname;
                        this.applicationmodule.projectid = this.backpagedata.projectid;
                        this.applicationmodule.projectmodulename = this.$route.params.clickeddata.projectmodulename;
                        this.applicationmodule.projectmoduleid = this.backpagedata.projectmoduleid
                        this.showaddmodulebtn=true;
                        setTimeout(function(){
                            $('#commonaddmodule').click();
                        }, 1000);
                    }
                } else if(this.$route.params.backbtnstate == 'pathApp' || this.$route.params.backbtnstate == 'pathAppAdd') {
                    this.backpagedata=this.$route.params.clickeddata
                    this.getprojectapplicationmodulelist(this.$route.params.clickeddata)
                    this.getProjectModuleListByAppId(this.backpagedata.applicationid)
                    this.getAppEnvByApplicationId(this.backpagedata.applicationid)
                    this.applicationmodule.applicationid = this.backpagedata.applicationid
                    this.showaddmodulebtn=true
                    if(this.$route.params.backbtnstate == 'pathAppAdd') {
                        this.backpagedata.backbtnstate = 'pathApp'
                        setTimeout(function(){
                            $('#commonaddmodule').click();
                        }, 1000);
                    }
                }
            }else{
                localStorage.removeItem('appmodulesearchItems');
                this.getprojectapplicationmodulelist([])
            }
            
        }
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        }); 
    },
    methods:{
        redirecttobreadkumb:function(objThis){
            if(objThis.rowdata){
                objThis.rowdata.backbtnstate=objThis.btnstate
            }else{
                objThis.rowdata={}
                objThis.rowdata.backbtnstate=objThis.btnstate
            }
            this.$router.push({ 
                name: objThis.name,
                path: '/'+objThis.path,
                params: {
                    backbtnstate:objThis.btnstate,
                    clickeddata:objThis.rowdata
                }
            })
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(rowdata.projectlevel == 4) {
                this.$router.push({ 
                    name: 'Work Step',
                    path: '/workstep',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
            else if (btnstate == 'view' || btnstate == 'edit') {
                this.$router.push({ 
                    name: 'SectionModule',
                    path: '/sectionmodule',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
            else if (btnstate == 'viewfun') {
            this.$router.push({ 
                name: 'FuntionalityModule',
                path: '/functionalitymodule',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
            } else if (btnstate == 'projectId') {
				this.$router.push({
					path: '/project/summary?pid='+rowdata.projectid,
				})
			}
        },
        redirecttowsurl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            this.$router.push({ 
                name: 'Work Step',
                path: '/workstep',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },

    projectChange: function (node){
      if(node && node.length>1){
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          empid: this.undt.userid,
          isfrompage: 'listingpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
            localStorage.setItem('appmoduleprojectnameList', JSON.stringify(result.data.data))
          } else {
            this.projectnameList = [];
            localStorage.removeItem('appmoduleprojectnameList')
          }
        }).catch(e => {
            this.displayError(e)
        });
      }
    },
    projectChangebyid: function (projectidarr){
      if(projectidarr && projectidarr.length>0){
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
            projectidarr: projectidarr,
            useremail: this.undt.username,
            empcode: this.undt.userid,
            empid: this.undt.userid,
            isfrompage: 'listingpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
            this.displayError(e)
        });
      }
    },
    applicationmoduleChange:function (node, instanceId){
      if(node && node.length>1){
        this.isLoading = true;
        let url = "api/master/commonmaster/getModuleDropdownOption";
        this.input = {
            tbl:'t34',
            applicationmodulename:node.toLowerCase(),
            projectModuleid: this.search.searchProjectModule,
            projectMasterid: this.search.searchProject,
            useremail: this.undt.username,
            empcode: this.undt.userid

        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.applicationmodulelistopts = result.data.data;
            localStorage.setItem('applicationmodulelistopts', JSON.stringify(result.data.data))
          } else {
            this.search.applicationmodulelistopts = [];
            localStorage.removeItem('applicationmodulelistopts')
          }
        }).catch(e => {
            this.displayError(e)
        });;
      }
    },
        getfilterapplicationmodlist:function(){
          this.isLoading=true
          this.isSearchRequestActive=true
          this.showproject=false
          let url = 'api/projectapplication/getAplicationlist'
          this.input = {
            "tbl": "t36",
            "offset":1,
            "limit":1000,
            "isSearch":false,
            "section":"assignment",
            "isFilterSelect":1,
            "isFilterPayload":{},
           "useremail":this.undt.username,
           "empcode": this.undt.userid
          }
            if(this.search.searchProject != null ) {
            this.input.isFilterPayload.projectid = this.search.searchProject
           }
           if(this.search.searchProjectModule != null ) {
            this.input.isFilterPayload.projectmoduleid = this.search.searchProjectModule
           }
           if(this.search.searchApplication != null ) {
            this.input.isFilterPayload.applicationid = this.search.searchApplication
           }
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
          })
            .then(result => {  
                this.isLoading=false;           
                if(result.data.errorCode == 0) {            
                 this.companymasterlist=result.data.data;
                    this.totalcount = result.data.data.length
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                      }else {
                    this.companymasterlist=[]
                    this.totalcount=0
                }
                })            
            
        },
        resetRecords: function () {
            this.projectnameList = null;   
            this.search.applicationmodulelistopts= null;       
            this.search.searchProject = null;
            this.search.searchProjectModule= null;
            this.search.searchApplication = null;
            this.search.searchApplicationModule = null;
            this.search.appmodulestatus = null;
            localStorage.removeItem("appmodulesearchItems");
            localStorage.removeItem("appmoduleprojectnameList");
            localStorage.removeItem("appmoduleapplicationlistopts");
            localStorage.removeItem("appmoduleprojectmodulelistopts");
            localStorage.removeItem("applicationmodulelistopts");
            let rowdata={}
            this.getprojectapplicationmodulelist(rowdata) 
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        selappapltype(state,value){
            this.application.applicationtype=null
            this.application.applicationtype=state
            if(!state && !this.isAddState){
                this.application.errorapplicationtype='Application type required'
            }else{                
                this.application.errorapplicationtype=null
            }
        },
        selappaplaccess(state,value){
            this.application.applicationaccess=null
            this.application.applicationaccess=state
            if(!state && !this.isAddState){
                this.application.errorapplicationaccess ='Application access required'
            }else{                
                this.application.errorapplicationaccess =null
            }
        },
        getaccessofapplicationlist:function(){
            this.isLoading=true
            let url = 'api/projectapplication/getaccessofapplicationlist'
            this.input = {
                "accessofapplicationid":0,
                empcode: this.undt.userid,
                useremail: this.undt.username
            }
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading=false;             
                if(result.data.errorCode == 0){                    
                    this.application.applicationaccesslist=result.data.data;
                }else{
                    this.application.applicationaccesslist=[]
                }
            }).catch(e => {
          this.displayError(e)
        })
        },
        getapplicationtypelist:function(){
            let url = 'api/projectapplication/getapplicationtypelist'
            this.input = {
                "applicationtypeid":0,
                useremail: this.undt.username,
                empcode: this.undt.userid

            }
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){                    
                    this.application.applicationtypelist=result.data.data;
                }else{
                    this.application.applicationtypelist=[]
                }
            }).catch(e => {
            this.displayError(e)
            });
        },
        getapplicationlist:function(){
            this.isLoading=true
            let url = 'api/projectapplication/getAplicationlist'
            this.input = {
                "projectmoduleid":0,
                "empcode": this.undt.userid,
                "useremail":this.undt.username,
            }
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading=false;           
                if(result.data.errorCode == 0){                    
                    this.modulelist=result.data.data;
                }else{
                    this.modulelist=[]
                }
            }).catch(e => {
            this.displayError(e)
        });
        },
        getSearchItems(search) {
            this.isSearchRequestActive = true
            this.projectnameList = JSON.parse(localStorage.getItem('appmoduleprojectnameList'))
            this.search.applicationlistopts = JSON.parse(localStorage.getItem('appmoduleapplicationlistopts'))
            this.search.projectmodulelistopts = JSON.parse(localStorage.getItem('appmoduleprojectmodulelistopts'))
            this.search.applicationmodulelistopts = JSON.parse(localStorage.getItem('applicationmodulelistopts'))
            this.search.searchProject  = this.search.searchProject ? this.search.searchProject : search.projectid ? search.projectid: search.searchProject ? search.searchProject: search.searchProjectname ? search.searchProjectname : null;
            this.search.searchApplication  =  search.applicationid ? search.applicationid : search.searchApplication ? search.searchApplication : null;
            this.search.searchProjectModule  = this.search.searchProjectModule ? this.search.searchProjectModule : search.projectmoduleid ? search.projectmoduleid : search.searchProjectModule ? search.searchProjectModule : null;
            this.search.searchApplicationModule  = this.search.searchApplicationModule ? this.search.searchApplicationModule : search.applicationmoduleid ? search.applicationmoduleid : search.searchApplicationModule ? search.searchApplicationModule : null;
            this.search.appmodulestatus = this.search.appmodulestatus ? this.search.appmodulestatus : search.appmodulestatus ? search.appmodulestatus : null;
            this.extradata = 1
            if(this.search.searchProject && this.search.searchProject !=null){
                this.projectChangebyid([this.search.searchProject]);
                this.getDropdownApplicationdata("t12");
                this.getDropdownApplicationdata("t36");
                this.getDropdownApplicationdata("t34");
            }
            this.getprojectmoduleapplications(this.search);
            this.getprojectapplicationmodulelist(this.search);
        },
        getprojectapplicationmodulelist(rowdata){ 
            this.islistLoading=true;           
            this.clickedprojdata=rowdata ;
            if(rowdata) {
                localStorage.setItem('appmodulesearchItems', JSON.stringify(rowdata))
            }
            this.input = {
                projectid:rowdata.searchProject || rowdata.projectid || rowdata.searchProjectname,
                applicationMasterid:rowdata.searchApplication,
                projectmoduleid:rowdata.projectmoduleid,
                applicationModuleid :rowdata.searchApplicationModule,
                limit: apiUrl.LIMIT,
                offset: this.pageoffset,
                uniqueid: this.undt.userid,
                useremail: this.undt.username,empcode: this.undt.userid,
                appmodulestatus: rowdata.appmodulestatus
            }
            if(rowdata.searchProjectModule){
                this.input.projectmoduleid = rowdata.searchProjectModule
            }else{
                this.input.projectmoduleid =rowdata.projectmoduleid
            }
            let url = 'api/applicationmodule/list'
            this.applicationmodule.applicationname=rowdata.applicationname
            this.applicationmodule.projecttypeid=this.clickedprojdata.projecttypeid
            this.applicationmodule.businessverticleid=this.clickedprojdata.businessverticleid
            this.applicationmodule.companyid=this.clickedprojdata.companyid
            this.applicationmodule.projectid = this.clickedprojdata.projectid
            this.applicationmodule.projectname = this.clickedprojdata.projectname
            this.applicationmodule.projectmodulename = this.clickedprojdata.projectmodulename
            this.applicationmodule.projectmoduleid = this.clickedprojdata.projectmoduleid
            this.getApplicationdata('t11','applicationmodule')
            this.getapplictionlist(this.clickedprojdata);
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.islistLoading=false;
                if(result.data.errorCode == 0){
                    this.showproject=true;
                    this.companymasterlist=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }else{
                    this.companymasterlist=[]
                    this.showproject=true;
                    this.totalcount = 0
                }
                setTimeout(function(){
                    if(rowdata.backbtnstate == 'edit'){
                        $('#commonaddmodule').click();
                    }
                }, 1000);
            }).catch(e => {
            this.displayError(e)
         });
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle == 't22'){
                this.customlisting(inputEle,'')
            }else{
                this.getprojectapplicationmodulelist(this.clickedprojdata)
            }
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#proAppModuleList").offset().top -70
                });
            }, 500);
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        valid2Text : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validate : function(tblNm){
            this.projectBlured=true
            this.projectdescBlured=true
            this.projectcountryBlured=true
            this.appenvhosturlBlured=true
            this.appenvhostdetailspathBlured=true
            this.appenvhostpathBlured=true
            this.appenvhostBlured=true
            this.appenvnameBlured=true
            this.appenvtypeBlured=true
            this.applinameBlured=true
            if(tblNm=="t11" && (this.validText(this.project.name) && this.validText(this.project.desc))){
                this.valid = true;
            }
            if(tblNm=="t12" && (this.validText(this.application.name))){
                this.valid = true;
            }
            if(tblNm=="t13" && (this.validText(this.appenv.name) && this.validText(this.appenv.host) && this.validText(this.appenv.hostpath) && this.validText(this.appenv.hostdetails)  && this.validText(this.appenv.hosturl) && this.valid2Text(this.appenv.type) )){
                this.valid = true;
            }
        },
        savewizard(mdl,objThis){
            if(mdl=="t36"){
                this.validate(mdl)
            }else{
                this.valid=true;
            }            
            this.valid = this.validateForm(mdl,objThis);
            if (this.valid) {
                let nameobj=document.getElementById('modulename'+mdl)
                let tblNm='t34'
                if(mdl) tblNm=mdl
                this.input = {
                    tbl: tblNm,
                    section: 'assignment',
                    createdby: this.undt.username,
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                }
                if(nameobj != null && nameobj != 'undefined')
                {
                    this.input.name=nameobj.value
                }
                let apiURL='api/applicationmodule/create'
                this.input.projectid=this.applicationmodule.projectid
                this.input.projectmoduleid=this.applicationmodule.projectmoduleid
                this.input.applicationid=this.applicationmodule.applicationid
                this.input.applicationmodulename=this.applicationmodule.applicationmodulename
                this.input.applicationmoduledescription=this.applicationmodule.applicationmoduledescription
                if(this.applicationmodule.applicationenvid) {
                    this.input.applcationenvironmentdetailsid = this.applicationmodule.applicationenvid
                }
                if(this.applicationmodule.iseditstate == true){
                    apiURL='api/master/commonmaster/update'
                    if(tblNm == 't34'){
                        this.input.id=this.editRowData.applicationmoduleid
                        apiURL='api/applicationmodule/update'
                    }
                    this.input.lastmodifiedby=this.undt.username
                    delete this.input.createdby;
                }
                this.isAddState=false
                this.input.useremail= this.undt.username,
                this.input.empcode= this.undt.userid
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.editRowData=this.applicationmodule
                        this.getprojectapplicationmodulelist(this.search);
                        this.resetFormData();
                        this.isLoading = false;
                        $('.close').click();
                    } else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        validateForm(mdl,objThis){
            this.clearerrorForm();
            this.isAddState=false
            var isValid=true;
            this.appmoduleBlured = true;
            this.descriptionBlured = true;           
            if(mdl=="t34"){
                if(!this.applicationmodule.projectid){
                    this.errors.applicationmodule.projectid="Project required";
                    isValid= false;
                }
                if(!this.applicationmodule.projectmoduleid){
                    this.errors.applicationmodule.projectmoduleid="Project Module required";
                    isValid= false;
                }
                if(!this.applicationmodule.applicationid){
                    this.errors.applicationmodule.applicationid="Application name is required";
                    isValid= false;
                } 
                if(!this.applicationmodule.applicationmodulename && !this.applicationmodule.applicationenvid){
                    this.errors.applicationmodule.applicationmodulename="Application module name required";
                    isValid= false;
                }
                if(this.applicationmodule.applicationenvid && !this.applicationmodule.applicationmodulename) {
                    this.errors.applicationmodule.applicationmodulename="Application module name required";
                    isValid = false
                }
                if(!this.applicationmodule.applicationmoduledescription){
                    this.errors.applicationmodule.applicationmoduledescription="Application module description required";
                    isValid= false;
                }
            }
            return isValid
        },
        resetFormData() {
            if(this.backpagedata && this.backpagedata.backbtnstate !== 'pathApp') {
                this.applicationmodule.applicationid = null
            } else {
                this.applicationmodule.projectmoduleid = null
            }
            this.applicationmodule.applicationmodulename = null
            this.applicationmodule.applicationenvid = null
            this.applicationmodule.applicationmoduledescription = null
        },
        clearerrorForm(){
            this.errors.applicationmodule.projectid = null
            this.errors.applicationmodule.projectmoduleid = null
            this.errors.applicationmodule.projectmodulelist = null
            this.errors.applicationmodule.applicationid = null
            this.errors.applicationmodule.applicationmodulename = null
            this.errors.applicationmodule.applicationmoduledescription = null        
        },
        openEditModule(currRowData){
            this.editRowData = currRowData
            this.applicationmodule.projectid = currRowData.projectid
            this.getapplictionlist(currRowData);
            this.applicationmodule.projectmoduleid = currRowData.projectmoduleid
            this.applicationmodule.applicationid = currRowData.applicationid
            this.getprojectmoduleapplications(currRowData)
            this.applicationmodule.applicationenvid = currRowData.applcationenvironmentdetailsid
            if(this.applicationmodule.applicationenvid){
                this.isAppenvSelected = true
            }
            this.applicationmodule.applicationmodulename = currRowData.applicationmodulename
            this.applicationmodule.applicationmoduledescription = currRowData.applicationmoduledescription
            this.applicationmodule.projecttypeid=currRowData.projecttypeid
            this.applicationmodule.businessverticleid=currRowData.businessverticleid
            this.applicationmodule.companyid=currRowData.companyid
            this.applicationmodule.projectname=currRowData.projectname
            this.applicationmodule.projectmodulename=currRowData.projectmodulename
            this.applicationmodule.iseditstate = true
            this.isAddState=true
        },
        getProjectModuleListByAppId(applicationid) {
            let url = 'api/projectmodule/getProjectModuleListByAppId'
            axios({
                method: 'POST',
                url,
                data: { applicationid,useremail: this.undt.username,empcode: this.undt.userid },
                headers: { 'authorization':this.tokendata }
            }).then(result => {
                if(result.data.errorCode == 0) {
                    this.applicationmodule.projectmodulelist = result.data.data.rows
                   
                } else {
                    this.applicationmodule.projectmodulelist = []
                }
            })
        },
        getprojectmoduleapplications: function(rowdata){
            if(!rowdata.projectmoduleid || !rowdata.searchProjectModule) return;
            this.inputprojappl = {
                id:rowdata.projectmoduleid ? rowdata.projectmoduleid : rowdata.searchProjectModule ? rowdata.searchProjectModule : rowdata.projectmoduleid,
                useremail: this.undt.username,empcode: this.undt.userid
            }
            let apiURL='api/projectmodule/getapplications'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputprojappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.applicationmodule.applicationlist=result.data.data.data
                    result.data.data.data.forEach(element => {
                        if(element.applicationid>0){
                        }
                    });
                }else{
                    this.applicationmodule.applicationid=null
                }
            }).catch(e => {
          this.displayError(e)
        })
        },
        getapplictionlist:function(currRowData){
            let url = 'api/projectapplication/getAplicationlist'
            this.inputappllist = {
               projectid: currRowData.projectid ? currRowData.projectid : currRowData.searchProject ? currRowData.searchProject :null,
               useremail: this.undt.username,
               empcode: this.undt.userid
            }
            axios({
            'method': 'POST',
            'url': url,
            'data': this.inputappllist,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    this.applicationmodule.applicationlist=result.data.data.rows
                    if(currRowData.projectmoduleid){
                        this.getprojectmoduleapplications(currRowData)
                    }
                }else{
                    this.applicationmodule.applicationid=null
                    this.applicationmodule.applicationlist=[]
                }
            }).catch(e => {
          this.displayError(e)
        })
        },
        dateFormat(value) {
            if (!value){ 
              return "";
            }else{
              return moment(String(value)).format('DD-MM-YYYY');
            }
        },
        dateMONFormat(value){
            if (!value){
              return "";
            }else{
              return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
            }
        },
        selamprojectdata(state,value){
            this.applicationmodule.projectid=null
            this.applicationmodule.projectid=state
            if(!state && !this.isAddState){
                this.errors.applicationmodule.projectid="Project Required"
            }else{                
                this.errors.applicationmodule.projectid=null
                this.getfiltereddata('t36');
            }
        },
        selamprojectmdldata(state,value){
            this.applicationmodule.projectmoduleid=null
            this.applicationmodule.projectmoduleid=state
            if(!state && !this.isAddState){
                this.errors.applicationmodule.projectmoduleid='Project Module required'
            }else{                
                this.errors.applicationmodule.projectmoduleid=null
            }
        },
        getAppEnvByApplicationId(applicationid) {
            this.isLoading = true
            let url = "api/appenv/getAppEnvByApplicationId";
            this.input = { applicationid,useremail: this.undt.username,empcode: this.undt.userid };

            axios({
                method: "POST",
                url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then(result => {
                this.isLoading = false
                if (result.data.errorCode == 0) {
                    this.applicationmodule.applicationenvlit = result.data.data;
                }
            }).catch(e => {
          this.displayError(e)
        })
        },
        selprojmodid(state) {
            if(!state) {
                this.errors.applicationmodule.projectmoduleid = 'Project Module required'
            } else {
                this.errors.applicationmodule.projectmoduleid = null
            }
        },
        selamappmdldata(state,value){
            this.applicationmodule.applicationid=null
            this.applicationmodule.applicationid=state
            if(!this.isAddState) {
                this.applicationmodule.applicationenvid=null
                this.applicationmodule.applicationenvlit = null
            }
            if(!state){
                this.errors.applicationmodule.applicationid='Application name is required'
                this.applicationmodule.applicationenvid=null
                this.applicationmodule.applicationenvlit = null
            }else{                
                this.errors.applicationmodule.applicationid=null
                this.getAppEnvByApplicationId(state)
            }
        },
        selamappenvdata(state,value){
            this.applicationmodule.applicationenvid=null
            this.applicationmodule.applicationenvid=state
            if(!state){
                this.errors.applicationmodule.applicationenvid='Application infra module is required'
                this.isAppenvSelected = false
                this.applicationmodule.applicationmodulename = null
            }else{                
                this.errors.applicationmodule.applicationenvid=null
                if(this.applicationmodule.applicationenvlit) {
                    var placa = this.applicationmodule.applicationenvlit.filter(item => item.id == state)
                    this.applicationmodule.applicationmodulename = placa[0].label
                    this.isAppenvSelected = true
                }
            }
        },
        getfiltereddata(tblNm){
            this.isLoading=true
            this.pageoffset=1
            var url = 'api/master/commonmaster/customselect'
            this.input = {
                tbl:tblNm,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            this.input.isSearch=true
            this.input.section='assignment'
            this.input.isFilterSelect=1
            this.input.isFilterPayload={}
            if(tblNm == 't36'){
                this.input.projectid=this.applicationmodule.projectid
            }
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false;          
                if(result.data.errorCode == 0){
                    if(tblNm == 't36'){
                        this.applicationmodule.projectmodulelist=result.data.data.rows
                    }
                }else{
                    if(tblNm == 't36'){
                        this.errors.applicationmodule.projectmoduleid="no project module found"
                    }
                }
            }).catch(e => {
          this.displayError(e)
        })
        },
        getApplicationdata(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT
            }
            let apiURL='api/master/commonmaster/dropdownlist'
            if(curracttab == 'applicationmodule'){
                this.inputappl = {
                    tbl:mdl
                }
            }
            this.inputappl.useremail= this.undt.username,
            this.inputappl.empcode= this.undt.userid
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    if(mdl == 't11'){
                        if(curracttab == 'applicationmodule'){
                            this.applicationmodule.projectlist=result.data.data.rows
                             this.search.projectlistopts=result.data.data.rows
                            return true
                        }
                    }else if(mdl == 't12'){
                        if(curracttab == 'applicationmodule'){
                            return true
                        }
                    }else if(mdl == 't36'){
                        if(curracttab == 'applicationmodule'){
                            this.applicationmodule.projectmodulelist=result.data.data.rows
                            return true
                        }
                    }else if(mdl == 't2'){
                        this.applicationmodule.businesslist=result.data.data.rows
                        return true;                            
                    }else if(mdl == 't3'){
                        if(curracttab == 'applicationmodule'){
                            this.applicationmodule.projecttypelist=result.data.data.rows
                            return true;
                        }
                    }else if(mdl == 't1'){
                        this.applicationmodule.companylist=result.data.data.rows
                        return true;
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
         selectProjectid:function(state, value) {
            this.search.searchProject = state.id;
            if (!state.id) {
                this.search.searchApplication = null;
                this.search.searchProjectModule = null;
                this.search.searchApplicationModule = null;
                this.search.applicationlistopts = [];
            } else {
                this.search.searchApplication = null;
                this.search.searchProjectModule = null;
                this.search.searchApplicationModule = null;
                this.search.applicationlistopts = [];
                this.getDropdownApplicationdata("t12");
                this.getDropdownApplicationdata("t36");
            }
        },
        selectApplicationid(state, value) {
            this.search.searchApplication = state.id;
            if (!state.id ) {
                this.search.searchApplicationModule = null;
                this.search.searchApplication = null;
                this.search.projectmodulelistopts = null;
            } else {
                this.search.searchProjectModule = null;
                this.search.searchApplicationModule = null;
                this.getDropdownApplicationdata("t36");
            }
        },
        selectModuleid(state, value) {
            this.search.searchProjectModule = state.id;
            if (!state.id ) {
                this.search.searchApplicationModule = null;
                this.search.applicationmodulelistopts = [];
            } else {
                this.search.searchApplicationModule = null;
                this.search.applicationmodulelistopts = [];
                this.getDropdownApplicationdata("t34");
            }
        },
         getDropdownApplicationdata(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail:this.undt.username
            }
            if(mdl === "t12") {
                this.input.projectMasterid = this.search.searchProject
            } 
            if(mdl === "t36") {
                this.input.projectMasterid = this.search.searchProject
            } 
            if(mdl === "t34") {
                this.input.projectModuleid = this.search.searchProjectModule
            } 
            this.input.useremail= this.undt.username,
            this.input.empcode= this.undt.userid
            axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        if(mdl === "t12") {
                            this.search.applicationlistopts = result.data.data
                            localStorage.setItem('appmoduleapplicationlistopts', JSON.stringify(result.data.data))
                          }
                        else if(mdl === "t36") {
                            this.search.projectmodulelistopts = result.data.data
                            localStorage.setItem('appmoduleprojectmodulelistopts', JSON.stringify(result.data.data))
                          }
                        else if(mdl === "t34") {
                            this.search.applicationmodulelistopts = result.data.data
                            localStorage.setItem('applicationmodulelistopts', JSON.stringify(result.data.data))
                          }
                    }
                    else{
                         this.search.applicationlistopts = []
                         localStorage.removeItem('appmoduleapplicationlistopts')
                         this.search.projectmodulelistopts= []
                         localStorage.removeItem('appmoduleprojectmodulelistopts')
                         this.search.applicationmodulelistopts = []
                         localStorage.removeItem('applicationmodulelistopts')
                    }
                }).catch(e => {
                this.displayError(e)
            })
        },
        loadApplicationModulename(mdl){
            let apiUrls = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail:this.undt.username,
                projectMasterid:this.search.searchProject,
                applicationMasterid:this.search.searchApplication,
                projectmoduleid:this.search.projectmoduleid,
                applicationModuleid :this.search.searchApplicationModule,
                empcode: this.undt.userid
            }
            axios({
                'method': 'POST',
                'url': apiUrls,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode==0){
                        this.search.applicationmodulelistopts = result.data.data
                        localStorage.setItem('applicationmodulelistopts', JSON.stringify(result.data.data))
                    }else{
                        this.search.applicationmodulelistopts= []
                        localStorage.removeItem('applicationmodulelistopts')
                    }
                }).catch(e => {
          this.displayError(e)
        })
        },
        addmodule(){         
            this.applicationmodule.applicationenvid = null
            this.applicationmodule.applicationmodulename = null
            this.applicationmodule.applicationmoduledescription = null
            this.applicationmodule.iseditstate = false
            this.isSearchRequestActive = true
        },
        appmoduleisactive:function(isdata,isactive) {
            this.isLoading = true;
            this.input = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                applicationmoduleid: isdata.id,
                isactive: isactive
            };
            axios({
            method: "POST",
            url: `api/applicationmodule/activeinactiveapplicationmodule`,
            data: this.input,
            'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if(result.data.errorCode == 0) {
                    this.getprojectapplicationmodulelist(this.search);
                    Swal.fire({
                    title: "Success!",
                    text: result.data.msg,
                    icon: 'success',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                    })
                }else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                    Swal.fire({
                        title: "",
                        text: result.data.msg,
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            });
        },
        ExportXL() {
            this.islistLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input = {
                limit:null,
                offset:null,
                projectid:this.search.searchProject,
                applicationMasterid:this.search.searchApplication,
                projectmoduleid:this.search.projectmoduleid,
                applicationModuleid:this.search.searchApplicationModule,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                appmodulestatus: this.search.appmodulestatus,
                uniqueid: this.undt.userid,
            }; 
            axios({
                method: "POST",
                url: `api/applicationmodule/list`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.islistLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    var filename = "";
                    this.download(result.data.data.rows,filename)
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
            }).catch(e => {
            this.displayError(e)
        });
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
                var ShowLabel = 'ApplicationmoduleReport_'+filename
                var JSONData = readExportdata
                var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
                var CSV = ''
                if (ShowLabel) {
                    let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    row = row.slice(0, -1)
                    CSV = 'Sr#,Application Module Name,Project Name,Module Name,Application Name,Total Sections,Total Functionality,Application Module Status,Created Date' + '\r\n'
                }
                var row = ''
                for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1
                let applicationmodulename = index.hasOwnProperty('applicationmodulename') ? index.applicationmodulename : '';
                let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
                let projectmodulename = index.hasOwnProperty('projectmodulename') ? index.projectmodulename : '';
                let applicationname = index.hasOwnProperty('applicationname') ? index.applicationname : '';
                let totalsections = index.hasOwnProperty('totalsections') ? index.totalsections : '';
                if(totalsections==null){
                    totalsections=''
                }
                let totalfunctionality = index.hasOwnProperty('totalfunctionality') ? index.totalfunctionality : '';
                if(totalfunctionality==null){
                    totalfunctionality=''
                }
                let isactive = index.hasOwnProperty('isactive') ? index.isactive ==null || index.isactive == ''? 'Inactive' : index.isactive ==0 ? 'Inactive' : index.isactive ==1 ? 'Active' :'Inactive' : 'Inactive';
                let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
                createddate = this.dateMONFormat(createddate);
                    row += '"' + srno + '",'
                    row += '"' + applicationmodulename + '",'
                    row += '"' + projectname + '",'
                    row += '"' + projectmodulename + '",'
                    row += '"' + applicationname + '",'
                    row += '"' + totalsections + '",'
                    row += '"' + totalfunctionality + '",'
                    row += '"' + isactive + '",'
                    row += '"' + createddate + '",'
                    row += '\r\n'
                }
                CSV += row
                if (CSV === '') {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                return;
                }
                var fileName = 'ApplicationmoduleReport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                var link = document.createElement('a')
                link.href = uri
                link.style = 'visibility:hidden'
                link.download = fileName + '.csv'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                Swal.fire({
                title: "Success!",
                text: "Export Report successful",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                });
            } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            }
        },
        export (data, filename, mime) {
            var data
            let blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
        },
    }
}
</script>
